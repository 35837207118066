import {apiClient, FetcherSettings} from './apiClient';
import {dynamicRoute} from './utils';
import {GetGuestSchemaPayload, GuestSchemaResponse} from '../services';
import {Guest} from '../utils/types';

type CreateGuestWithMinDataPayload = Pick<Guest, 'name' | 'surname'> & {
  reservation_id?: string;
};

class GuestApi {
  ENDPOINTS = {
    oneV4: dynamicRoute({
      regular: (id: string, params = '') => `api/v4/guestapp/guests/${id}/${params}`,
      sdk: (id: string, params = '') => `sdk/api/v1/guestapp/guests/${id}/${params}`,
    }),
    getSchema: dynamicRoute({
      regular: () => `api/v4/guestapp/guests/schema/`,
      sdk: () => `sdk/api/v1/guestapp/guests/schema/`,
    }),
    delete: dynamicRoute({
      regular: (id: string) => `api/v3/guests/${id}/`,
      sdk: (id: string) => `sdk/api/v1/guests/${id}/`,
    }),
    create: dynamicRoute({
      regular: (params = '') => `api/v4/guestapp/guests/${params}`,
      sdk: (params = '') => `sdk/api/v1/guestapp/guests/${params}`,
    }),
    patch: dynamicRoute({
      regular: (id: string) => `api/v4/guestapp/guests/${id}/`,
      sdk: (id: string) => `sdk/api/v1/guestapp/guests/${id}/`,
    }),
  };

  async getOne(id: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<Guest>(
      this.ENDPOINTS.oneV4(id),
      fetcherSettings,
    );
    return response.body;
  }

  async create(payload: FormData, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.post<Guest>(this.ENDPOINTS.create(), {
      body: payload,
      headers: {},
      ...fetcherSettings,
    });
    return response.body;
  }

  async createMin(
    payload: CreateGuestWithMinDataPayload,
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient.post<Guest>(this.ENDPOINTS.create('?min=true'), {
      json: payload,
      ...fetcherSettings,
    });

    return response.body;
  }

  async patch(id: string, payload: FormData, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.patch<Guest>(this.ENDPOINTS.patch(id), {
      body: payload,
      headers: {},
      ...fetcherSettings,
    });
    return response.body;
  }

  async delete(id: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.delete(this.ENDPOINTS.delete(id), fetcherSettings);
    return response.body;
  }

  async getSchema(payload: GetGuestSchemaPayload, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.post<GuestSchemaResponse>(
      this.ENDPOINTS.getSchema(),
      {json: payload, ...fetcherSettings},
    );
    return response.body;
  }
}

export {GuestApi};
export type {CreateGuestWithMinDataPayload};
