import styled from 'styled-components';
import {device} from 'styled/device';
import ModalSheet from 'components/common/ModalSheet';

export const StyledModalSheet = styled(ModalSheet)`
  &-content {
    @media (min-width: ${device.mobileL}) {
      padding: 48px 20px 0;
      max-width: 375px;
    }
  }
`;
