import {CoreError, FieldError} from '@guestapp/sdk';
import {TFunction} from 'i18next';
import {StripeError} from '@stripe/stripe-js';

type ErrorType = CoreError | Error | StripeError | string;

function getErrorMessage(error: ErrorType, t?: TFunction): string {
  let message = '';

  if (!error) {
    return message;
  }

  if (typeof error === 'string') {
    return error;
  }

  if ('details' in error && typeof error.details === 'string') {
    return error?.details;
  }
  if (isObject(error) && 'errors' in error && error?.errors?.length) {
    const nonFieldErrors = 'non_field_errors';
    Object.values(error.errors).forEach((err: FieldError) => {
      const translatedFieldName = t ? t(err.field) : err.field;
      message += ` ${
        err?.field && err?.field !== nonFieldErrors ? `${translatedFieldName}: ` : ''
      } ${err.message};`;
    });
  } else if ('message' in error && error?.message) {
    message = error.message;
  } else {
    if (typeof error === 'object') {
      Object.values(error).forEach(err => {
        message += ` ${err}\n`;
      });
    }
  }

  if (typeof message === 'object') {
    return '';
  }

  if (message?.startsWith('Unexpected JSON')) {
    return 'Server error.';
  }

  return message;
}

function checkIsInternetConnectionFast(): boolean {
  try {
    // @ts-ignore
    const deviceNetworkConnection = navigator?.connection;

    return false;
    return ['4g', '5g'].includes(deviceNetworkConnection?.effectiveType);
  } catch {
    return false;
  }
}

function getCorrectUrl(url: string) {
  if (url.includes('http')) {
    try {
      const urlObj = new URL(url);
      return urlObj.search;
    } catch (e) {
      return url;
    }
  } else {
    return url;
  }
}

function getSearchParamFromUrl<T = string>(param = '', url = window.location.search) {
  if (!url) return null;

  const searchString = getCorrectUrl(url);
  const searchParams = new URLSearchParams(searchString);
  return searchParams.get(param) as T | null;
}

const isNumeric = (n: string | number) => !isNaN(n as number);

const getRegExpFromString = (value: string) => {
  return new RegExp(value || '');
};

function isBrowser(): boolean {
  return typeof window !== 'undefined';
}

const isObject = (value: unknown) => {
  return value !== null && typeof value === 'object';
};

export {
  getErrorMessage,
  checkIsInternetConnectionFast,
  getSearchParamFromUrl,
  isNumeric,
  getRegExpFromString,
  isBrowser,
  isObject,
};
