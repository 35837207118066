import styled from 'styled-components';
import Lottie from 'lottie-react';
import {device} from '../../styled/device';
import Modal from '../Modal';
import StatusModal from '../StatusModal';
import {ModalTwoButtonsWrapper, Row} from '../../styled/common';

export const StyledModal = styled(StatusModal)``;

export const StyledFailedModal = styled(Modal)`
  &-content {
    .modal__icon {
      margin-bottom: 10px;
    }
  }

  @media (min-width: ${device.mobileXL}) {
    &-content {
      max-width: 487px;
      max-height: 790px;
      width: 100%;
      height: auto;

      & .modal__title {
        flex: auto;
      }
    }
  }
`;

export const ModalTwoButtons = styled(ModalTwoButtonsWrapper)`
  margin-top: 48px;
  margin-bottom: 15px;

  @media (min-width: ${device.mobileXL}) {
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 295px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${device.mobileXL}) {
    margin: auto auto 0;
    display: block;
  }
`;

export const ReasonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  text-align: left;
`;

export const ReasonItem = styled(Row)`
  font-size: 14px;

  & > p {
    margin: 0 0 0 10px;
  }
`;

export const FailedGifContainer = styled.img`
  max-width: 224px;
  margin-top: 40px;

  @media screen and (max-height: 740px) {
    max-width: 140px;
    margin-top: 0;
  }

  @media (min-width: ${device.mobileXL}) {
    margin-top: 0;
  }
`;

export const FailedModalButtonsContainer = styled.div``;

export const StyledLottie = styled(Lottie)`
  display: block;
  margin: 0 auto;
  max-width: 224px;
  margin-top: 40px;
  @media screen and (max-height: 740px) {
    max-width: 140px;
    margin-top: 0;
  }

  @media (min-width: ${device.mobileXL}) {
    margin-top: 0;
  }
`;
