import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AllianzPlan} from 'hooks/allianz/types';

type allianzInsuranceState = {
  legalConfirmation: {
    allowUsePersonalData: boolean;
    prevAccessToInsuranceDocuments: boolean;
  };
  plans: AllianzPlan[];
  dealIds: string[];
  confirmEmail: string;
};

const initialState: allianzInsuranceState = {
  legalConfirmation: {
    allowUsePersonalData: false,
    prevAccessToInsuranceDocuments: false,
  },
  plans: [],
  dealIds: [],
  confirmEmail: '',
};

const AllianzInsuranceSlice = createSlice({
  name: 'AllianzInsuranceSlice',
  initialState,
  reducers: {
    setPlans(state, action: PayloadAction<AllianzPlan[]>) {
      state.plans = action.payload;
    },
    setLegals(
      state,
      action: PayloadAction<{
        allowUsePersonalData: boolean;
        prevAccessToInsuranceDocuments: boolean;
      }>,
    ) {
      state.legalConfirmation = action.payload;
    },
    setSelectedPlan(state, action: PayloadAction<number>) {
      state.plans.forEach(plan => (plan.selected = false));
      state.plans[action.payload].selected = true;
    },
    setDealIds(state, action: PayloadAction<string[]>) {
      state.dealIds = action.payload;
    },
    setConfirmEmail(state, action: PayloadAction<string>) {
      state.confirmEmail = action.payload;
    },
  },
});

export {AllianzInsuranceSlice};
export const allianzInsuranceActions = AllianzInsuranceSlice.actions;
export const allianzInsuranceReducer = AllianzInsuranceSlice.reducer;
