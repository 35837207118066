import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {ModalSheet} from '../../../../components/common/ModalSheet/ModalSheet';
import {ConfirmButton} from '../styled';
import {ContentButtonModal, TitleModal, WrapperText} from './styled';

type SuccessModalProps = {
  isOpen: boolean;
  close: () => void;
  confirmUrlTo: string;
  onBackClick: () => void;
};
const SuccessModal = memo(
  ({isOpen, close, confirmUrlTo, onBackClick}: SuccessModalProps) => {
    const {t} = useTranslation();

    return (
      <ModalSheet
        isOpenModalSheet={isOpen}
        handleCloseModal={close}
        title={t('tourist_taxes_setup_complete_exclamation')}
        className="tax-setup-success-modal"
      >
        <WrapperText>
          <TitleModal className="tax-setup-success-modal__title">
            {t('you_can_continue_directly_to_payment')}
          </TitleModal>

          <ContentButtonModal className="tax-setup-success-modal__btns">
            <ConfirmButton label={t('continue_to_payment')} to={confirmUrlTo} />
            <ConfirmButton label={t('back_to_home')} link onClick={onBackClick} />
          </ContentButtonModal>
        </WrapperText>
      </ModalSheet>
    );
  },
);

export {SuccessModal};
