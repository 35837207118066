import styled, {css} from 'styled-components';
import SearchingSelect from '../../SearchingSelect';

type SelectedCountryCodeContainerProps = {
  disabled?: boolean;
  readOnly?: boolean;
  error?: string;
  isEmpty?: boolean;
};

export const SelectedCountryCodeContainer = styled.button<SelectedCountryCodeContainerProps>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 60px;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 7px 0 16px;
  cursor: pointer;
  z-index: 1;
  text-align: left;
  font-weight: ${props => props.theme.fonts.fontWeight.regular};
  font-size: ${props => props.theme.fonts.sizes.medium};
  color: ${props => props.theme.colors.branding.fontPrimary};
  user-select: none;
  outline: none;
  margin-top: 25px;
  background: transparent;

  ${props =>
    props.error &&
    css`
      border-color: ${props => props.theme.colors.$s1};
    `}

  ${props =>
    props.isEmpty &&
    css`
      font-size: ${props => props.theme.fonts.sizes.small};
      color: ${props => props.theme.colors.branding.fontSecondary};
    `};

  ${props =>
    props.readOnly &&
    css`
      cursor: default;
    `};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `};
`;

export const StyledSearchingSelect = styled(SearchingSelect)`
  position: initial;

  & .dropdown-select__menu {
    top: 48px;
    z-index: 5;
  }
` as typeof SearchingSelect;
