import React from 'react';
import {useModalControls} from './common';
import {useActions} from './redux';

function useDiscardIVModal() {
  const {resetAllIVState, resetPhotoBoxUploadedImage, resetIVProcess} = useActions();

  const {
    isOpen: isOpenDiscardIVModal,
    closeModal: closeDiscardIVModal,
    openModal: openDiscardIVModal,
  } = useModalControls();

  const resetAllIVStates = React.useCallback(() => {
    resetPhotoBoxUploadedImage();
    resetIVProcess();
    resetAllIVState();
    closeDiscardIVModal();
  }, [resetPhotoBoxUploadedImage, resetIVProcess, resetAllIVState, closeDiscardIVModal]);

  return {
    isOpenDiscardIVModal,
    closeDiscardIVModal,
    openDiscardIVModal,
    resetIVProcess: resetAllIVStates,
  };
}

export {useDiscardIVModal};
