import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Controller, useFormContext} from 'react-hook-form';
import {useQuery} from '@tanstack/react-query';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {EXTRA_FORM_NAMES, FORM_NAMES, FormTypes} from '../../utils/guestForm';
import {PrivacyPolicySettings, Contract} from '@guestapp/sdk';
import {useChekinSDK} from '../../context/ChekinSDK';
import {useErrorModal} from '../../hooks';
import {useReservation} from '../../context/reservation';
import {useSchema} from '../../context/guest';
import {getRegExpFromString} from '@guestapp/core';
import {downloadFromLink, getDocumentDownloadByLanguage} from '../../utils/common';
import Link from '../common/Link';
import {GuestEmailField} from '../GuestFormFields';
import {
  AcceptWarning,
  CheckboxContainer,
  ContractTitleLine,
  LinkInText,
  StyledCheckbox,
  StyledComputedBox,
  StyledLoader,
  Title,
} from './styled';

type ContractBoxProps = {
  contracts?: Contract[];
  hasError?: boolean;
  isLoading?: boolean;
  isAutomatedSignature?: boolean;
};
const ContractBox = React.memo(
  ({contracts, hasError, isLoading, isAutomatedSignature}: ContractBoxProps) => {
    const {t, i18n} = useTranslation();
    const {ErrorModal: ErrorModalContract, displayError} = useErrorModal();
    const {housing, housingId} = useReservation();
    const {getPrivacyPolicy} = useChekinSDK();
    const {control, watch, resetField, getValues} = useFormContext<FormTypes>();
    const isContractEnabled = housing?.is_contract_enabled;
    const {formFields} = useSchema();
    const hasAlreadyEmail =
      formFields?.[FORM_NAMES.email]?.active && formFields?.[FORM_NAMES.email]?.required;

    const shouldSendCopyContractToEmail = watch(EXTRA_FORM_NAMES.sendCopyContractEmail);
    const defaultEmail = watch(FORM_NAMES.email);
    const needToShowAdditionalEmailField =
      !defaultEmail && !hasAlreadyEmail && shouldSendCopyContractToEmail;

    const {data: privacyPolicySettings, error} = useQuery<PrivacyPolicySettings>(
      [QUERY_CACHE_KEYS.privacyPolicySettings, housingId],
      () => getPrivacyPolicy(housingId || ''),
      {
        enabled: !!housingId,
        refetchOnWindowFocus: false,
      },
    );
    const hasCustomPrivacyPolicy = Boolean(
      privacyPolicySettings?.has_custom_privacy_policy,
    );

    const documentDownload = React.useMemo(() => {
      if (privacyPolicySettings) {
        return getDocumentDownloadByLanguage(privacyPolicySettings, i18n.language);
      }

      return '';
    }, [i18n.language, privacyPolicySettings]);

    React.useEffect(() => {
      if (error) {
        displayError(error);
      }
    }, [displayError, error]);

    React.useEffect(
      function resetInactiveContractEmailField() {
        const value = getValues()[FORM_NAMES.contract_email];
        if (!needToShowAdditionalEmailField && value) {
          resetField(FORM_NAMES.contract_email);
        }
      },
      [getValues, needToShowAdditionalEmailField, resetField],
    );

    const viewContract = () => {
      const contract = contracts?.[0];

      if (hasError || !contract?.file) {
        displayError();
        return;
      }

      downloadFromLink(contract?.file);
    };

    return (
      <>
        {isLoading && <StyledLoader width={60} />}
        {isContractEnabled && !isLoading && (
          <>
            <ContractTitleLine>
              <Title>{t('contract')}</Title>
            </ContractTitleLine>
            <CheckboxContainer className="sign-block__checkbox-container">
              <Controller
                name={EXTRA_FORM_NAMES.sendCopyContractEmail}
                control={control}
                render={({field, fieldState: {error}}) => (
                  <StyledCheckbox
                    label={<Trans i18nKey="send_copy_contract_email" />}
                    error={error?.message}
                    className="sign-block__checkbox"
                    {...field}
                  />
                )}
              />
              <StyledComputedBox expanded={needToShowAdditionalEmailField}>
                <GuestEmailField
                  name={FORM_NAMES.contract_email}
                  required={t('required')}
                  label={t('email')}
                  format={getRegExpFromString(formFields?.[FORM_NAMES.email]?.format)}
                />
              </StyledComputedBox>
            </CheckboxContainer>
          </>
        )}
        <AcceptWarning className="sign-block__warning">
          {/* {!showSignArea && <>{t('by_clicking_next_you_accept_the')} </>} */}
          {!isContractEnabled && !isAutomatedSignature && (
            <Trans i18nKey="by_clicking_next_you_accept_the">
              By clicking <b>‘Sign form’</b> you accept to sign the form with your name
              and the Privacy Policy
            </Trans>
          )}
          {!isContractEnabled && isAutomatedSignature && (
            <Trans i18nKey="by_clicking_signt_now_you_accept_the">
              By clicking <b>‘Sign now’ </b>you accept to sign the form with your name,
              the short term
            </Trans>
          )}
          {isContractEnabled && (
            <>
              {!isAutomatedSignature ? (
                <Trans i18nKey="by_clicking_next_you_accept_the">
                  By clicking <b>‘Sign form’</b> you accept to sign the form with your
                  name and the
                </Trans>
              ) : (
                <Trans i18nKey="by_clicking_signt_now_you_accept_the">
                  By clicking <b>‘Sign now’ </b>you accept to sign the form with your
                  name, the short term
                </Trans>
              )}{' '}
              <LinkInText data-testid="contract-btn" onClick={viewContract}>
                {t('contract')}
              </LinkInText>
            </>
          )}
          {/* {isContractEnabled && !isLoadingContracts &&  original condition*/}
          {isContractEnabled && ` ${t('and_you_accept_our')} `}

          {hasCustomPrivacyPolicy ? (
            <Link download href={documentDownload}>
              {t('privacy_policy')}
            </Link>
          ) : (
            <Link target="_blank" rel="noreferrer" href={t('privacy_policy_link')}>
              {t('privacy_policy')}
            </Link>
          )}
        </AcceptWarning>
        <ErrorModalContract
          title={t('contract_not_available')}
          message={t('contract_not_available_description')}
        />
      </>
    );
  },
);

export {ContractBox};
