import React, {
  ChangeEvent,
  FocusEvent,
  InputHTMLAttributes,
  useState,
  useCallback,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import clsx from 'clsx';
import {ErrorType} from 'utils/types';
import {useScreenResize} from '../../../hooks';
import {device} from '../../../styled/device';
import {Fieldset} from '@guestapp/ui';
import {
  FieldQuestionMarkButton,
  RelativeWrapper,
  StyledInput,
  WrapperInput,
  InputErrorMessage,
  TooltipStyled,
} from './styled';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  invalid?: boolean;
  error?: ErrorType;
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  tooltipContent?: string;
};

const defaultProps: InputProps = {
  onChange: undefined,
  label: '',
  invalid: undefined,
  error: '',
  inputMode: undefined,
  autoComplete: 'on',
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      onChange,
      className,
      label,
      invalid,
      defaultValue,
      disabled,
      name,
      type,
      max,
      error,
      autoComplete,
      inputMode,
      children,
      tooltipContent,
      onBlur,
      onFocus,
      ...props
    },
    ref,
  ) => {
    const {isMatch: isMobileMode} = useScreenResize(device.tablet);
    const position = isMobileMode ? 'top center' : 'top right';
    const [currentValue, setCurrentValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const handleLabelClick = useCallback(() => {
      inputRef.current?.focus();
      setIsFocused(true);
    }, [setIsFocused]);

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(event.target.value);
        onChange?.(event);
      },
      [setCurrentValue, onChange],
    );

    const handleFocus = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        onFocus?.(event);
        setIsFocused(true);
      },
      [setIsFocused, onFocus],
    );

    const handleBlur = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        onBlur?.(event);
        setIsFocused(false);
      },
      [onBlur, setIsFocused],
    );
    const [isEmpty, setIsEmpty] = useState(true);

    useEffect(() => {
      const formValue = inputRef.current?.value;
      setIsEmpty(!Boolean(currentValue) && !Boolean(formValue));
    }, [currentValue, inputRef.current?.value]);

    // const isEmpty = React.useMemo(() => {
    //   const formValue = inputRef.current?.value;
    //   return !Boolean(currentValue) && !Boolean(formValue);
    // }, [currentValue]);

    return (
      <WrapperInput disabled={disabled} className={clsx(className, 'input-wrapper')}>
        <RelativeWrapper className="input">
          {children || (
            <StyledInput
              className="input__input"
              ref={inputRef}
              type={type}
              data-testid="input"
              value={value}
              isFocused={isFocused}
              autoFocus={isFocused}
              onChange={handleChange}
              disabled={disabled}
              defaultValue={defaultValue}
              aria-label={name}
              name={name}
              max={max}
              invalid={!!error}
              autoComplete={autoComplete}
              inputMode={inputMode}
              isEmpty={isEmpty}
              placeholder={''}
              onFocus={handleFocus}
              onBlur={handleBlur}
              {...props}
            />
          )}
          {tooltipContent && (
            <TooltipStyled position={position} label={tooltipContent}>
              <FieldQuestionMarkButton />
            </TooltipStyled>
          )}
          <Fieldset
            invalid={!!error}
            isFocused={isFocused}
            isEmpty={isEmpty}
            isActivated={!isEmpty || isFocused}
            onClick={handleLabelClick}
            htmlFor={props.placeholder}
            legend={label}
            label={label}
          />
        </RelativeWrapper>

        {error && <InputErrorMessage>{error as string}</InputErrorMessage>}
      </WrapperInput>
    );
  },
);

Input.defaultProps = defaultProps;
export {Input};
