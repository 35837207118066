import styled from 'styled-components';
import {device} from '../../../../styled/device';
import ModalSheet from '../../../../components/common/ModalSheet';
import {Button} from '@guestapp/ui';
import {ConfirmButton} from '../styled';

export const ExemptionAddedModalSheet = styled(ModalSheet)`
  &-content {
    min-height: auto;
    max-width: 375px;
  }
`;

export const ExemptionAddedConfirmButton = styled(ConfirmButton)`
  margin: 48px auto 28px;

  @media (min-width: ${device.mobileXL}) {
    margin: 48px auto 0;
  }
`;

export const TitleModal = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #152946;
  margin: 0;
`;

export const WrapperText = styled.div`
  h3 {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
  }
  ul {
    margin: 5px 0 0;
    font-weight: 400;
    padding-left: 20px;
  }
`;

export const RemoveExemptionModalSheet = styled(ModalSheet)`
  &-content {
    min-height: auto;
  }

  &-content > .modal__title,
  & .modal-sheet__title {
    margin-bottom: 48px;
  }

  &-content .standard-modal-sheet__button:first-child,
  & .standard-modal-sheet__button:first-child {
    margin-bottom: 36px;
    max-width: 294px;
  }

  &-content .standard-modal-sheet__button:last-child,
  & .standard-modal-sheet__button:last-child {
    margin-bottom: 0;
  }
`;

export const ListExemption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: -20px;
  margin-top: 24px;
  max-height: 300px;
  overflow-y: scroll;

  & li {
    margin-bottom: 16px;
    text-align: left;
    color: ${props => props.theme.colors.branding.fontSecondary};
  }
`;

export const PrivacyButton = styled(Button)`
  width: 100%;
  max-width: 295px;
  margin: 60px auto 24px;

  .buttonLabel {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ContentText = styled.div`
  margin: 0 24px;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: start;
    margin-bottom: 20px;

    @media (min-width: ${device.mobileXL}) {
      font-size: 16px;
    }
  }
`;

export const ContentButtonModal = styled.div`
  margin: 0 24px;
`;
