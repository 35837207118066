import styled, {css} from 'styled-components';
import {device} from '../../../styled/device';

type ModalWrapperProps = {
  $slideMode?: boolean;
  $unmounted?: boolean;
};
export const ModalWrapper = styled.div<ModalWrapperProps>`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 32px 16px;
  background: rgba(22, 22, 67, 0.15);
  animation: showModal 0.3s;
  -webkit-animation: showModal 0.3s;
  transition: transform 0.3s;
  overflow: auto;
  z-index: 999;

  ${props =>
    props.$slideMode &&
    css`
      align-items: flex-end;
      padding: 0;
    `};

  ${props =>
    props.$unmounted &&
    css`
      animation: hideModal 0.3s forwards;
      -webkit-animation: hideModal 0.3s forwards;
      transition: transform 0.3s;
    `};

  @media (min-width: ${device.tablet}) {
    padding: 48px;
  }

  @keyframes showModal {
    0% {
      will-change: opacity;
      opacity: 0;
    }
    100% {
      will-change: unset;
      opacity: 1;
    }
  }
  @keyframes hideModal {
    0% {
      will-change: opacity;
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    100% {
      will-change: unset;
      opacity: 0;
    }
  }
`;

type OuterProps = {
  $densed?: boolean;
  $slideMode?: boolean;
  $unmounted?: boolean;
};
export const Outer = styled.div<OuterProps>`
  position: relative;
  width: 100%;
  max-width: 544px;
  margin: auto;
  background: ${props => props.theme.colors.$p6};
  border-radius: 16px;
  box-shadow: 0 64px 64px #0f0f0f14;
  z-index: 2;

  ${props =>
    props.$densed &&
    css`
      & > div {
        padding: 20px 20px 16px;
      }
    `};

  ${props => {
    return (
      props.$slideMode &&
      css`
        border-radius: 0;
        bottom: 0;
        max-height: 100%;
        height: auto;
        margin: 40px 0 0;
        opacity: 1;
        animation: showModalFromDown 0.3s;
        -webkit-animation: showModalFromDown 0.3s;

        ${props.$unmounted &&
        css`
          animation: hideModalToDown 0.25s forwards;
          -webkit-animation: hideModalToDown 0.25s forwards;
          transition: transform 0.25s ease-in;
        `};
      `
    );
  }};

  & > div {
    display: flex;
    flex-direction: column;
    min-height: unquote('min(550px, 100vh - 96px)');
    padding: 20px 22px;

    @media (min-width: ${device.tablet}) {
      padding: 30px 40px;
    }
  }

  @keyframes showModalFromDown {
    from {
      will-change: transform;
      transform: translateY(100%);
    }
    to {
      will-change: unset;
      transform: translateY(0);
    }
  }

  @keyframes hideModalToDown {
    to {
      transform: translateY(100%);
    }
  }
`;

export const CloseSlideButton = styled.button`
  display: block;
  width: 56px;
  height: 6px;
  border-radius: 13px;
  background-color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.primary
      : props.theme.colors.$n7};
  margin: 12px auto;

  @media (min-width: ${device.tablet}) {
    display: none;
  }
`;

export const Content = styled.div`
  max-height: calc(100vh - 100px);
  overflow: auto;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -18px;
  right: -14px;
  width: 48px;
  height: 48px;
  background: ${props => props.theme.colors.$p6};
  border-radius: 50%;
  box-shadow: 0 8px 16px rgba(15, 15, 15, 0.1);
  font-size: 0;
  padding: 0;

  @media (min-width: ${device.tablet}) {
    top: -16px;
    right: -16px;
    width: 32px;
    height: 32px;
  }

  & > svg {
    fill: ${props => props.theme.colors.$n4};
    transition: transform 0.2s;
  }

  &:hover {
    & > svg {
      transform: rotate(90deg);
    }
  }
`;

export const ContentCloseMobileButton = styled.div`
  display: flex;
  padding: 16px 16px 0px !important;
  align-items: end;
`;

export const CloseMobileButton = styled.button``;
