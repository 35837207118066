import styled, {DefaultTheme, css} from 'styled-components';
import {device} from 'styled/device';
import {CustomIcon} from '@guestapp/ui';

type NavigationStatusTextProps = {
  completed: boolean;
};

const getBgColor = (theme: DefaultTheme, isCompleted?: boolean, isFlat?: boolean) => {
  if (!isFlat) {
    return theme.hasTemplate ? theme.colors.branding.boxesModals : '#ffffff';
  }
  if (isCompleted) {
    return '#e8fbf9';
  }
  return theme.colors.branding.backgroundMain;
};

const getBoxBorderColor = (isCompleted?: boolean) => {
  if (isCompleted) {
    return '#32d7be';
  }
  return 'rgba(0, 0, 0, 0.1)';
};

export const NavigationContainer = styled.div<{
  maxWidth?: string;
  flat?: boolean;
  completed?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  background: ${props => getBgColor(props.theme, props.completed, props.flat)};
  border: 1px solid ${props => getBoxBorderColor(props.completed)};
  border-radius: 12px;
  margin-bottom: 8px;
  box-shadow: ${props => (props.flat ? 'none' : '0 3px 6px rgba(0, 0, 0, 0.07)')};
  cursor: pointer;

  @media (min-width: ${device.laptop}) {
    max-width: ${props => props.maxWidth || '580px'};
  }

  & .custom-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const NavigationItem = styled.div<{isClicked?: boolean}>`
  cursor: ${props => (props.isClicked ? 'pointer' : 'default')};
  display: flex;
  align-items: center;
  /* padding-bottom: 25px; */
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.09); */

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export const NavigationIconContainer = styled.div`
  margin-right: 16px;
`;

export const NavigationTexts = styled.div`
  display: block;
`;

export const NavigationTitle = styled.div`
  display: block;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.branding.fontPrimary};
  font-family: ${props => props.theme.fonts.fontFamily.titles.font_name};
`;

export const NavigationStatusText = styled.div<NavigationStatusTextProps>`
  display: flex;
  align-items: center;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #ffb27d;
  ${props =>
    props.completed &&
    css`
      color: #32d7be;
    `}
`;

export const NextArrow = styled(CustomIcon).attrs({className: 'nav-item__next-arrow'})`
  margin-left: auto;
  width: 16px;
  height: 16px;

  path {
    stroke: ${props =>
      props.theme.hasTemplate
        ? props.theme.colors.branding.primary
        : props.theme.colors.buttons.mainButtonBackground};
  }
`;

export const NavigationStatusIcon = styled.img`
  margin-right: 5px;
`;

export const NavigationDescription = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 199px;
  color: ${props => props.theme.colors.branding.fontPrimary};

  @media (min-width: ${device.tablet}) {
    max-width: 100%;
  }
`;
