import styled, {css} from 'styled-components';
import {device} from '../../../styled/device';
import {getBackgroundColor} from 'styled/common';
import Tooltip from '../Tooltip';
import QuestionMarkButton from '../QuestionMarkButton';
import {ErrorMessage} from '@guestapp/ui';

type WrapperProps = {
  disabled?: boolean;
};

type StyledInputProps = {
  invalid?: boolean;
  disabled?: boolean;
  isEmpty?: boolean;
  isFocused?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: 288px;
  min-height: 44px;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `};

  @media (max-width: ${device.mobileS}) {
    width: 293px;
  }
`;

export const TooltipStyled = styled(Tooltip)`
  position: absolute;
  right: -26px;
  top: 12px;
`;

export const FieldQuestionMarkButton = styled(QuestionMarkButton)`
  height: 22px;
  width: 22px;

  & > img {
    width: 100%;
    height: 100%;
  }

  &:active {
    opacity: 0.8;
  }
`;

export const WrapperInput = styled.div`
  min-height: 44px;
  display: flex;
  flex-direction: column;
  position: relative;

  ${(props: WrapperProps) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

export const RelativeWrapper = styled.div`
  position: relative;
`;

export const StyledInput = styled.input<StyledInputProps>`
  width: 100%;
  outline: none;
  margin: 0;
  font-size: ${props => props.theme.fonts.sizes.medium};
  transition: background-color 0.25s ease-in-out;
  padding: 13px 16px 13px 9px;
  box-sizing: border-box;
  border-radius: 7px;
  border: none;
  text-overflow: ellipsis;
  height: 48px;
  font-family: inherit;
  font-weight: ${props => props.theme.fonts.fontWeight.regular};
  line-height: 24px;
  color: ${props => props.theme.colors.branding.fontPrimary};
  background-color: ${({isEmpty, isFocused, theme}) =>
    getBackgroundColor(isEmpty, isFocused, theme)};

  &::placeholder {
    font-weight: ${props => props.theme.fonts.fontWeight.regular};
    color: ${props => props.theme.colors.branding.fontPrimary};
    opacity: 1;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 1000px white inset !important;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }
`;

export const InputErrorMessage = ErrorMessage;
