import React from 'react';
import {useTranslation} from 'react-i18next';
import {LANGUAGE_CODES} from '@guestapp/core';
import {IV_DOCUMENT_IMAGE_TYPES} from 'utils/constants';
import {getDocumentType} from 'store/slices/identityVerification';
import {useAppSelector} from 'hooks';
import {IV_DOCUMENT_ANIMATIONS} from 'pages/IdentityVerification/types';

const getLanguage = (lang: string) => {
  const mappedLang = lang as LANGUAGE_CODES;
  return ![LANGUAGE_CODES.en, LANGUAGE_CODES.it, LANGUAGE_CODES.es].includes(mappedLang)
    ? LANGUAGE_CODES.en
    : mappedLang;
};

const useFetchIVLottiesDefault = {
  type: IV_DOCUMENT_IMAGE_TYPES.front,
  isOrc: false,
};

type useFetchIVLottieProps = {
  type?: IV_DOCUMENT_IMAGE_TYPES;
  isOrc?: boolean;
};
function useFetchIVLottie({
  type,
  isOrc,
}: useFetchIVLottieProps = useFetchIVLottiesDefault) {
  const {i18n} = useTranslation();
  const documentType = useAppSelector(getDocumentType);
  const [animationData, setAnimationData] = React.useState<object | null>(null);

  const getLottieUrl = React.useCallback(() => {
    const language = getLanguage(i18n.language);

    const IV_LOTTIES_BY_LANGUAGE: Record<IV_DOCUMENT_ANIMATIONS, string> = {
      [IV_DOCUMENT_ANIMATIONS.fontside]: `${language}/instructions-id-front.json`,
      [IV_DOCUMENT_ANIMATIONS.backside]: `${language}/instructions-id-back.json`,
      [IV_DOCUMENT_ANIMATIONS.passport]: `${language}/instructions-passport.json`,
      [IV_DOCUMENT_ANIMATIONS.selfie]: `${language}/instructions-selfie.json`,
      [IV_DOCUMENT_ANIMATIONS.ocr]: `${language}/instructions-orc.json`,
    };

    if (isOrc) {
      return IV_LOTTIES_BY_LANGUAGE[IV_DOCUMENT_ANIMATIONS.ocr];
    }

    switch (type) {
      case IV_DOCUMENT_IMAGE_TYPES.front:
        return documentType?.two_sides
          ? IV_LOTTIES_BY_LANGUAGE[IV_DOCUMENT_ANIMATIONS.fontside]
          : IV_LOTTIES_BY_LANGUAGE[IV_DOCUMENT_ANIMATIONS.passport];
      case IV_DOCUMENT_IMAGE_TYPES.back:
        return IV_LOTTIES_BY_LANGUAGE[IV_DOCUMENT_ANIMATIONS.backside];
      case IV_DOCUMENT_IMAGE_TYPES.selfie:
        return IV_LOTTIES_BY_LANGUAGE[IV_DOCUMENT_ANIMATIONS.selfie];
      default:
        return IV_LOTTIES_BY_LANGUAGE[IV_DOCUMENT_ANIMATIONS.fontside];
    }
  }, [i18n.language, type, documentType?.two_sides, isOrc]);

  React.useEffect(
    function lazyPreloadLottie() {
      const lottieUrl = getLottieUrl();
      import(`assets/lotties/IV/${lottieUrl}`).then(data => {
        setAnimationData(data?.default);
      });
    },
    [getLottieUrl],
  );

  return {animationData};
}

export {useFetchIVLottie};
