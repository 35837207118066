import {useEffect, useRef} from 'react';
import {useSummary} from '../context/summary';
import {ShortReservation} from '@guestapp/sdk';
import ChekinPro from '../ChekinPro';

type UseReservationFetchListenerProps = {
  status: 'loading' | 'error' | 'success';
  reservation?: ShortReservation;
};
function useReservationFetchListener({
  status,
  reservation,
}: UseReservationFetchListenerProps) {
  useEffect(() => {
    if (status === 'success' || status === 'error') {
      const isSuccessfullyFound = Boolean(status === 'success' && reservation?.id);
      if (isSuccessfullyFound) {
        ChekinPro.events.onReservationFound();
      }

      ChekinPro.events.onReservationFetched({isSuccess: isSuccessfullyFound});
    }
  }, [reservation?.id, status]);
}

type UseOnScreenChanged<MetaData> = {
  type: 'PAYMENTS_CART' | 'ORDER_HISTORY';
  meta?: MetaData;
  enabled?: boolean;
};
function useOnScreenChanged<MetaData = unknown>({
  type,
  meta,
  enabled = true,
}: UseOnScreenChanged<MetaData>) {
  const {data} = useSummary();
  const hasBeenCalledRef = useRef(false);

  useEffect(() => {
    if (hasBeenCalledRef.current || !data?.id || !enabled) return;

    ChekinPro.events.onScreenChanged({
      type,
      meta,
      reservationId: data.id,
    });

    hasBeenCalledRef.current = true;
  }, [data?.id, type, meta, enabled]);
}

export {useReservationFetchListener, useOnScreenChanged};
