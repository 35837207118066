import {CalculateGuestTaxPayload, TaxesApi} from '../api';
import {GuestsTaxes, Season, TaxExemption} from '../utils/types';
import {FetcherSettings} from '../api/apiClient';

class TaxesService {
  private readonly taxesApi: TaxesApi;

  constructor() {
    this.taxesApi = new TaxesApi();
  }

  getAllExemptions<T = TaxExemption[]>(params = '', resolverSettings?: FetcherSettings) {
    return this.taxesApi.getAllExemptions<T>(params, resolverSettings);
  }

  getSeason<T = Season>(id: string, resolverSettings?: FetcherSettings) {
    return this.taxesApi.getSeason<T>(id, resolverSettings);
  }

  getSeasonGuests<T = GuestsTaxes>(params = '', resolverSettings?: FetcherSettings) {
    return this.taxesApi.getSeasonGuests<T>(params, resolverSettings);
  }

  initGuestTaxes<T = GuestsTaxes>(payload: {reservation_id: string}): Promise<T>;
  initGuestTaxes<T = GuestsTaxes>(
    payload: {reservation_id: string},
    resolverSettings?: FetcherSettings,
  ) {
    return this.taxesApi.initGuestTaxes<T>(payload, resolverSettings);
  }

  calculateGuestTax<T = GuestsTaxes>(
    payload: CalculateGuestTaxPayload,
    params = '',
    resolverSettings?: FetcherSettings,
  ) {
    return this.taxesApi.calculateGuestTax<T>(payload, params, resolverSettings);
  }
}

export {TaxesService};
