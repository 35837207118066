import {Offer, DEAL_STATUSES} from '@guestapp/sdk';
import {Tag} from 'components/common/Tag';
import {useScreenResize, usePaymentSettings} from 'hooks';
import {device} from '@guestapp/ui/src/utils/device';
import {CategoryTranslation} from 'components/upselling/CategoryTranslation';
import {usePathToOfferView} from 'components/upselling/usePathToOfferView';
import {OfferCardMark} from 'components/upselling/OfferCardMark';
import {formatPrice} from 'components/upselling/price';
import {
  Container,
  Image,
  TextWrapper,
  Header,
  Info,
  Price,
  TagWrapper,
  Title,
  Highlight,
} from './styled';

type Props = {
  offer: Offer;
  className?: string;
  status?: DEAL_STATUSES;
};
const OfferCard = ({className, offer, status}: Props) => {
  const {getFullPathToOfferView} = usePathToOfferView({backToOfferList: true});
  const {isMatch: isMobileMode} = useScreenResize(device.tabletSubHeader);
  const {currencySymbol} = usePaymentSettings();

  return (
    <Container to={getFullPathToOfferView(offer.id)} className={className}>
      <Image src={offer.image_url} />
      <TextWrapper>
        <Header>
          <Title>{offer.title}</Title>
          {isMobileMode && status ? (
            <OfferCardMark status={status} />
          ) : (
            <Price>
              {formatPrice(currencySymbol)({
                priceType: offer.price_type,
                items: offer.items,
              })}
            </Price>
          )}
        </Header>
        <Info>
          <Highlight>{offer.highlight}</Highlight>
          {!isMobileMode && (
            <TagWrapper>
              {offer.category && (
                <Tag>
                  <CategoryTranslation category={offer.category} />
                </Tag>
              )}
              {status && <OfferCardMark status={status} />}
            </TagWrapper>
          )}
        </Info>
      </TextWrapper>
    </Container>
  );
};

export {OfferCard};
