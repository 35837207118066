import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useMutation} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {PATHS} from 'Routes';
import {
  FormTypes,
  getNewToken,
  OPTION_VALUES,
  SEARCH_RESERVATION_FORM_NAMES,
} from 'pages/SearchReservation/utils';
import {RESERVATION_ID_KEY, SHARE_LINK, STATUSES} from 'utils/constants';
import {useErrorModal, useModalControls, usePath, useTimeoutRef} from 'hooks';
import {LocalStorage, useStatus} from '@guestapp/core';
import {useChekinSDK} from 'context/ChekinSDK';
import StatusModal, {StatusModalStates} from 'components/StatusModal';
import {InputController as Input} from '@guestapp/ui';
import {ReservationNotFoundModal} from 'pages/SearchReservation/Components';
import PageUniversalLink from 'components/PageUniversalLink';
import {
  ContentButton,
  CustomBackButton,
  StatusTitleModal,
  StyledBackIcon,
  StyledButton,
  StyledFormFieldWrapper,
  SubTitleWrapper,
  TitleWrapper,
  Wrapper,
} from './styled';

function BookingCodeView() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {getFullPath} = usePath();
  const {isLoading, setStatus} = useStatus();
  const {getBookingReference} = useChekinSDK();
  const {state} = useLocation();
  const {ErrorModal, displayError} = useErrorModal();
  const timeoutRef = useTimeoutRef();
  const [modalRegisterGuestStatus, setModalRegisterGuestStatus] = React.useState(
    StatusModalStates.IDLE,
  );

  const {
    isOpen: isOpenReservationNotFoundModal,
    openModal: openReservationNotFoundModal,
    closeModal: closeReservationNotFoundModal,
  } = useModalControls();

  const {
    isOpen: isOpenReservationStatusModal,
    openModal: openReservationStatusModal,
    closeModal: closeReservationStatusModal,
  } = useModalControls();

  const {
    register,
    control,
    handleSubmit,
    trigger,
    formState: {errors, isSubmitted},
    watch,
  } = useForm<FormTypes>();
  const bookingReference = watch(SEARCH_RESERVATION_FORM_NAMES.booking_reference);

  const {mutate} = useMutation(
    (formData: FormTypes) => {
      const booking_reference = formData[SEARCH_RESERVATION_FORM_NAMES.booking_reference];

      const params = {
        id: state.housingId,
        booking_reference,
      };

      return getBookingReference(params);
    },
    {
      onSuccess: data => {
        if (data) {
          const reservationId = data?.results?.[0]?.id;
          const reservationSigUpFormLink = data?.results?.[0]?.signup_form_link;

          if (reservationId) {
            LocalStorage.set(RESERVATION_ID_KEY, reservationId);
            LocalStorage.set(SHARE_LINK, reservationSigUpFormLink);
            const tokenFromUrl = getNewToken(reservationSigUpFormLink);
            window.location.href = `${process.env.REACT_APP_PUBLIC_URL}/${tokenFromUrl}`;
            setModalRegisterGuestStatus(StatusModalStates.SUCCESS);
          } else {
            setModalRegisterGuestStatus(StatusModalStates.IDLE);
            closeReservationStatusModal();
            openReservationNotFoundModal();
            return setStatus(STATUSES.idle);
          }
        }
      },
      onError: error => {
        setStatus(STATUSES.error);
        closeReservationStatusModal();
        displayError(error);
      },
    },
  );

  const onSubmit = (formData: FormTypes) => {
    setStatus(STATUSES.loading);
    setModalRegisterGuestStatus(StatusModalStates.LOADING);
    openReservationStatusModal();
    mutate(formData);
  };

  const handleNextFromNotFoundModal = () => {
    navigate(getFullPath(PATHS.searchReservation.newReservation), {
      replace: true,
      state: {
        housingId: state.housingId,
        country: state.housingCountry,
      },
    });
  };

  React.useEffect(
    function changeTranslationErrorMessages() {
      if (isSubmitted) {
        trigger();
      }
    },
    [isSubmitted, trigger],
  );

  React.useEffect(() => {
    if (modalRegisterGuestStatus === StatusModalStates.SUCCESS) {
      timeoutRef.current = setTimeout(() => {
        closeReservationStatusModal();
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [closeReservationStatusModal, modalRegisterGuestStatus, timeoutRef]);
  return (
    <PageUniversalLink className="booking-code-view">
      <Wrapper>
        <TitleWrapper>{t('find_your_booking')}</TitleWrapper>
        <SubTitleWrapper>
          {t('enter_the_reference_code_assigned_to_your_booking')}
        </SubTitleWrapper>

        <CustomBackButton
          label={t('back')}
          customIcon={<StyledBackIcon name="backArrow" size={15} />}
          iconProps={{position: 'left'}}
          onClick={() =>
            navigate(getFullPath(PATHS.searchReservation.main), {
              state: OPTION_VALUES.booking_reference,
            })
          }
          width="auto"
          className="summary-view__back-btn"
          link
        />

        <StyledFormFieldWrapper>
          <Input
            {...register(SEARCH_RESERVATION_FORM_NAMES.booking_reference, {
              required: t('required'),
            })}
            label={t('booking_reference_code')}
            control={control}
            error={errors[SEARCH_RESERVATION_FORM_NAMES.booking_reference]?.message}
            autoCorrect="off"
            spellCheck={false}
          />
        </StyledFormFieldWrapper>

        <ContentButton>
          <StyledButton
            label={t('confirm')}
            disabled={isLoading || !Boolean(bookingReference)}
            onClick={handleSubmit(onSubmit)}
          />
        </ContentButton>
      </Wrapper>
      <StatusModal
        open={isOpenReservationStatusModal}
        state={modalRegisterGuestStatus}
        maxWidth="375px"
      >
        {modalRegisterGuestStatus === StatusModalStates.LOADING && (
          <StatusTitleModal>{t('searching_booking')}</StatusTitleModal>
        )}
        {modalRegisterGuestStatus === StatusModalStates.SUCCESS && (
          <StatusTitleModal>{t('booking_found')}</StatusTitleModal>
        )}
      </StatusModal>
      <ReservationNotFoundModal
        isOpen={isOpenReservationNotFoundModal}
        onClose={closeReservationNotFoundModal}
        onNext={handleNextFromNotFoundModal}
      />
      <ErrorModal />
    </PageUniversalLink>
  );
}

export {BookingCodeView};
