import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {RESERVATION_PAYMENT_TYPES, CartPayment} from '@guestapp/sdk';
import {APP_PAGES} from 'utils/pages';
import {DisplayedPayment, UPSELLING_ORIGINS} from './types';
import {PATHS} from 'Routes';
import {usePath} from '../../hooks';

const PAYMENTS_TRANSLATION_TITLES: {
  [key in RESERVATION_PAYMENT_TYPES]?: string;
} = {
  [RESERVATION_PAYMENT_TYPES.booking]: 'booking_stay',
};

const getCommission = (payment: CartPayment) => {
  return Boolean(parseFloat(payment.commission_amount || '0'))
    ? payment.commission_amount
    : null;
};

function useBuildDisplayedPayments() {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {getFullPath} = usePath();

  const handlePaymentEdit = React.useCallback(
    (path: string) => () => {
      navigate(getFullPath(path), {
        state: {edit: true, callbackUrl: location.pathname, from: APP_PAGES.payments},
      });
    },
    [getFullPath, location.pathname, navigate],
  );

  const buildTouristTaxes = React.useCallback(
    (payment: CartPayment): DisplayedPayment => {
      return {
        id: payment.id,
        price: payment.amount_with_commission,
        fee: getCommission(payment),
        isEditable: true,
        title: t('tourist_taxes'),
        label: payment.label_text,
        onEdit: handlePaymentEdit(PATHS.taxes.main),
        type: payment.payment_type,
        objType: payment.obj_type,
      };
    },
    [handlePaymentEdit, t],
  );

  const buildSecurityDepositOrDamageProtection = React.useCallback(
    (payment: CartPayment): DisplayedPayment => {
      return {
        id: payment.id,
        price: payment.amount,
        fee: getCommission(payment),
        label: payment.label_text,
        isEditable: true,
        title: payment.name,
        onEdit: handlePaymentEdit(PATHS.deposits),
        type: payment.payment_type,
        objType: payment.obj_type,
      };
    },
    [handlePaymentEdit],
  );

  const buildUpselling = React.useCallback(
    (payment: CartPayment): DisplayedPayment => {
      const isThirdPartyType =
        payment.obj_type === RESERVATION_PAYMENT_TYPES.upsellingThirdParty;
      const isAllianz = payment.upselling_origin === UPSELLING_ORIGINS.allianz;
      return {
        id: payment.id,
        price: isThirdPartyType ? payment.amount : payment.amount_with_commission,
        fee: isAllianz ? null : isThirdPartyType ? payment.commission_amount : null,
        isEditable: isAllianz,
        isRemovable: !isThirdPartyType,
        label: payment.label_text,
        title: payment.name,
        type: payment.payment_type,
        onEdit: () =>
          navigate(
            `../../${PATHS.travelInsurances.main}/${PATHS.travelInsurances.allianz.main}/${PATHS.travelInsurances.allianz.selectGuests}`,
            {state: {from: PATHS.payments.main}},
          ),
        // externalId: upsellingPayment.upselling_deal_id,
      };
    },
    [navigate],
  );

  const buildOther = React.useCallback(
    (payment: CartPayment): DisplayedPayment => {
      const title = i18n.exists(PAYMENTS_TRANSLATION_TITLES[payment.obj_type] || '')
        ? t(PAYMENTS_TRANSLATION_TITLES[payment.obj_type] || '')
        : payment.name;

      return {
        title,
        id: payment.id,
        price: payment.amount_with_commission,
        fee: getCommission(payment),
        isEditable: false,
        isRemovable: false,
        label: payment.label_text,
        type: payment.payment_type,
      };
    },
    [i18n, t],
  );

  const getPayment = React.useCallback(
    (payment: CartPayment) => {
      const builders: {
        [key in RESERVATION_PAYMENT_TYPES]?: (payment: CartPayment) => DisplayedPayment;
      } = {
        [RESERVATION_PAYMENT_TYPES.touristTaxes]: buildTouristTaxes,
        [RESERVATION_PAYMENT_TYPES.upselling]: buildUpselling,
        [RESERVATION_PAYMENT_TYPES.upsellingThirdParty]: buildUpselling,
        [RESERVATION_PAYMENT_TYPES.securityDeposit]:
          buildSecurityDepositOrDamageProtection,
        [RESERVATION_PAYMENT_TYPES.damageProtection]:
          buildSecurityDepositOrDamageProtection,
      };

      const currentBuilder = builders[payment.obj_type];

      return currentBuilder ? currentBuilder(payment) : buildOther(payment);
    },
    [
      buildOther,
      buildUpselling,
      buildTouristTaxes,
      buildSecurityDepositOrDamageProtection,
    ],
  );

  return {getPayment};
}

export {useBuildDisplayedPayments};
