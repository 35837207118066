import {useChekinSDK} from 'context/ChekinSDK';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {PATHS} from 'Routes';

function usePathFromOrigin() {
  const {mainToken} = useChekinSDK();
  const navigate = useNavigate();

  //Esta funcion retorna el la vista de Home
  const sendMainPath = React.useCallback(() => {
    return navigate(PATHS.home);
  }, [navigate]);

  // Esta funcion captura el path de redireccion y el origen
  const getFromOriginPath = React.useCallback(
    (to: string, origin: string) => {
      const toString = to ? `/${to}` : '';
      return navigate(`/${mainToken}${toString}`, {
        state: {origin: origin},
      });
    },
    [navigate, mainToken],
  );

  return {getFromOriginPath, sendMainPath};
}

export {usePathFromOrigin};
