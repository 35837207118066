import React from 'react';
import {useWebsocket} from '../context/websocket';

function useWebsocketListener({
  time,
  autoReset = true,
  once = true,
}: {
  time: number;
  autoReset?: boolean;
  once?: boolean;
}) {
  const websocket = useWebsocket();
  const fallbackCalledTimes = React.useRef<number>(0);
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();
  const handlersRef = React.useRef<
    {[key in string]: (ws: typeof websocket) => void} | null
  >();
  const fallBackRef = React.useRef<(retries: number, reset: () => void) => void>();

  const reset = React.useCallback(() => {
    clearInterval(timeoutRef.current);
    handlersRef.current = null;
    fallbackCalledTimes.current = 0;
  }, []);

  const start = React.useCallback(() => {
    timeoutRef.current = setInterval(() => {
      fallBackRef.current?.(fallbackCalledTimes.current, reset);
      fallbackCalledTimes.current = fallbackCalledTimes.current + 1;
      if (once) reset();
    }, time);
  }, [reset, time, once]);

  React.useEffect(() => {
    const event = websocket.message?.event_type;
    if (!event) return;
    const handlers = handlersRef.current || {};

    handlers[event]?.(websocket);

    return () => {
      websocket.clearMessage();
    };
  }, [websocket]);

  React.useEffect(() => {
    return () => {
      clearInterval(timeoutRef.current);
    };
  }, []);

  const setListeningHandlers = React.useCallback(
    (
      handlers: {[key in string]: (ws: typeof websocket) => void},
      fallBack: (retries: number, reset: () => void) => void,
    ) => {
      if (handlersRef.current) return;
      reset();
      handlersRef.current = new Proxy(handlers, {
        get(target, prop, receiver) {
          if (prop in target && autoReset) reset();
          return Reflect.get(target, prop, receiver);
        },
      });
      fallBackRef.current = fallBack;
    },
    [autoReset, reset],
  );

  return {start, reset, setListeningHandlers};
}

export {useWebsocketListener};
