import {isMobile} from 'react-device-detect';
import {CURRENCIES} from '@guestapp/sdk';

const MAX_RETRIES_REQUESTS_NUMBER = 2;

const QUERY_CACHE_KEYS = {
  formFields: 'formFields',
  reservation: 'reservation',
  apiClient: 'apiClient',
  locations: 'locations',
  phoneCodes: 'phoneCodes',
  summary: 'summary',
  housing: 'housing',
  customForm: 'customForm',
  customFormById: 'customFormById',
  guestGroup: 'guestGroup',
  guest: 'guest',
  paymentSettings: 'paymentSettings',
  reservationPayments: 'reservationPayments',
  paymentsCart: 'paymentsCart',
  taxExemptions: 'taxExemptions',
  season: 'season',
  seasonGuests: 'seasonGuests',
  propertyProtections: 'propertyProtections',
  insuranceSetup: 'insuranceSetup',
  paginatedSpanishResidenceCities: 'paginatedSpanishResidenceCities',
  paginatedCountriesList: 'paginatedCountriesList',
  paginatedProvincesList: 'paginatedProvincesList',
  paginatedCitiesList: 'paginatedCitiesList',
  paginatedDistrictsList: 'paginatedDistrictsList',
  paginatedMunicipalitiesList: 'paginatedMunicipalitiesList',
  paginatedPhoneCodesList: 'paginatedPhoneCodesList',
  orderHistory: 'orderHistory',
  allianzPlans: 'allianzPlans',
  allianzOrders: 'allianzOrders',
  calculateTotalPricesForAllianz: 'calculateTotalPricesForAllianz',
  deleteDeal: 'deleteDeal',
  createOrUpdateBulkLock: 'createOrUpdateBulkLock',
  allianzDocuments: 'allianzDocuments',
  privacyPolicySettings: 'privacyPolicySettings',
  locks: 'locks',
  lockConditions: 'lockConditions',
  unlockDoor: 'unlockDoor',
  downloadFileDocuments: 'downloadFileDocuments',
  template: 'template',
  contracts: 'contracts',
  reservationBookingReference: 'reservationBookingReference',
  housingLocation: 'housingLocation',
  offers: 'offers',
  offer: 'offer',
  deals: 'deals',
};

const RESERVATION_ID_KEY = 'chekin-reservation-id';
const HOUSING_ID_KEY = 'chekin-housing-id';
const MAIN_TOKEN_ID_KEY = 'main-token';
const SHARE_LINK = 'share-link';
const IDENTITY_VERIFICATION_PHOTOS_QUALITY = 0.7;
const SIGNATURES_QUALITY = 0.8;
const IDENTITY_VERIFICATION_PHOTOS_MAX_SIZE_MB = 6;
const SHOWED_GUEST_GROUP_MODAL = 'showed_guest_group_modal';
const SHOWED_SUCCESS_REGISTER_COMPLETE_MODAL = 'showed_succes_register_complete_complete';
const IS_LEADER_GUEST = 'is_leader_guest';

const COUNTRY_CODES = {
  spain: 'ES',
  italy: 'IT',
  portugal: 'PT',
  germany: 'DE',
  uae: 'AE',
  netherlands: 'NL',
  austria: 'AT',
  uk: 'GB',
  france: 'FR',
  belgium: 'BE',
  hungary: 'HU',
  czech: 'CZ',
  colombia: 'CO',
  thailand: 'TH',
  romania: 'RO',
  greece: 'GR',
  slovenia: 'SI',
  croatia: 'HR',
  usa: 'US',
  sweden: 'SE',
};

enum IV_DOCUMENT_IMAGE_TYPES {
  front = 'front',
  back = 'back',
  selfie = 'selfie',
}

enum IDENTITY_VERIFICATION_TYPES {
  ocr = 'OCR',
  biomatch = 'BIOMATCH',
  document = 'DOCUMENT',
}

enum STORAGE_KEYS {
  personalFormData = 'PERSONAL_FORM_DATA',
  personalSignatureFormData = 'PERSONAL_SIGNATURE_FORM_DATA',
  allianzTotalPlanPrice = 'ALLIANZ_TOTAL_PLANS_PRICE',
}

enum STATUSES {
  loading = 'loading',
  error = 'error',
  success = 'success',
  idle = 'idle',
}

const OCR_CHECKING_STATUSES = {
  error: 'ERR',
  complete: 'COM',
  processing: 'PRO',
  new: 'NEW',
};

const WHATSAPP_SHARE_LINK = isMobile
  ? 'whatsapp://send/'
  : 'https://api.whatsapp.com/send';
const SMS_SHARE_LINK = 'sms://';
const EMAIL_SHARE_LINK = 'mailto:%20';

const CURRENCIES_SIGNS: Record<CURRENCIES, string> = {
  [CURRENCIES.eur]: '€',
  [CURRENCIES.usd]: '$',
  [CURRENCIES.gbp]: '£',
  [CURRENCIES.aed]: 'د.إ',
  [CURRENCIES.czk]: 'Kč',
  [CURRENCIES.chf]: '₣',
};

enum GUESTAPP_NAVIGATION_MODE {
  property_name = 'PROPERTY_NAME',
  logo = 'LOGO',
  property_name_and_logo = 'PROPERTY_NAME_AND_LOGO',
}

enum SHORT_LINK_TYPE {
  main = 'RESERVATION_SIGN_UP_FORM',
  payment = 'RESERVATION_PAYMENT',
  upselling = 'RESERVATION_UPSELLING',
  lock = 'RESERVATION_LOCK',
}

export {
  MAX_RETRIES_REQUESTS_NUMBER,
  QUERY_CACHE_KEYS,
  RESERVATION_ID_KEY,
  COUNTRY_CODES,
  MAIN_TOKEN_ID_KEY,
  IV_DOCUMENT_IMAGE_TYPES,
  IDENTITY_VERIFICATION_TYPES,
  STORAGE_KEYS,
  IDENTITY_VERIFICATION_PHOTOS_QUALITY,
  IDENTITY_VERIFICATION_PHOTOS_MAX_SIZE_MB,
  SIGNATURES_QUALITY,
  STATUSES,
  OCR_CHECKING_STATUSES,
  WHATSAPP_SHARE_LINK,
  SMS_SHARE_LINK,
  EMAIL_SHARE_LINK,
  SHARE_LINK,
  SHOWED_GUEST_GROUP_MODAL,
  SHOWED_SUCCESS_REGISTER_COMPLETE_MODAL,
  CURRENCIES_SIGNS,
  GUESTAPP_NAVIGATION_MODE,
  SHORT_LINK_TYPE,
  HOUSING_ID_KEY,
  IS_LEADER_GUEST,
};
