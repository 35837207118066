import React from 'react';
import {useTranslation} from 'react-i18next';
import warning from '../../assets/icons/warning-icon.svg';
import Button from '../common/Button';
import {ModalTwoButtons, StyledModal} from './styled';

type IVRetryModalProps = {
  open: boolean;
  onClickTryAgain?: () => void;
  onCancel?: () => void;
};
function IVRetryModal({open, onClickTryAgain, onCancel}: IVRetryModalProps) {
  const {t} = useTranslation();

  return (
    <StyledModal
      open={open}
      title={t('request_timed_out')}
      text={t('try_again_or_contact')}
      iconAlt={t('warning')}
      iconSrc={warning}
      iconProps={{width: 50, height: 50}}
    >
      <ModalTwoButtons>
        <Button label={t('try_again')} onClick={onClickTryAgain} />
        <Button label={t('upload_another_picture')} onClick={onCancel} link />
      </ModalTwoButtons>
    </StyledModal>
  );
}

export {IVRetryModal};
