import React from 'react';
import {createRoot} from 'react-dom/client';
import 'configs/i18n';
import reportWebVitals from './reportWebVitals';
import {setupSentry} from 'setupSentry';
import App from './App';

const IS_PRODUCTION_BUILD = process.env.NODE_ENV === 'production';
if (IS_PRODUCTION_BUILD) {
  setupSentry();
}

const container = document.getElementById('root');

if (!container) {
  throw new Error('The root element is not found');
}

const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
