import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSummary} from 'context/summary';
import {useReservation} from 'context/reservation';
import {useCustomLotties, useModalControls, useModalOncePerSession} from 'hooks';
import {SHOWED_SUCCESS_REGISTER_COMPLETE_MODAL} from 'utils/constants';
import {ModalSheet} from 'components/common/ModalSheet/ModalSheet';
import {
  ConfirmButton,
  Container,
  ContainerButton,
  ContainerInformation,
  Subtitle,
  SyledLottie,
  Title,
} from './styled';

function CheckInCompleteModal() {
  const {t} = useTranslation();
  const {isGuestappComplete} = useSummary();
  const {data: reservation} = useReservation();
  const {checkinCompletedAnimation} = useCustomLotties();
  const {
    isOpen: isCompleteModalOpen,
    closeModal: closeCompleteModal,
    openModal: openCompleteModal,
  } = useModalControls();

  const {shouldOpenModal, setStorageKey} = useModalOncePerSession({
    id: reservation?.id,
    storageType: 'Session',
  });

  React.useEffect(() => {
    if (isGuestappComplete) setStorageKey(SHOWED_SUCCESS_REGISTER_COMPLETE_MODAL);
    if (shouldOpenModal && isGuestappComplete) openCompleteModal();
  }, [shouldOpenModal, openCompleteModal, isGuestappComplete, setStorageKey]);

  return (
    <ModalSheet
      isOpenModalSheet={isCompleteModalOpen}
      handleCloseModal={closeCompleteModal}
      withCloseButton
      className="checkin-completed-modal"
      closeButtonType="click"
    >
      <Container className="checkin-completed-modal__container">
        <SyledLottie animationData={checkinCompletedAnimation} />
        <ContainerInformation>
          <Title>{t('online_checkin_is_completed')}</Title>
          <Subtitle>
            {t('you_can_keep_using_this_site_to_view_your_information')}
          </Subtitle>
          <Subtitle>{t('enjoy_your_stay')}</Subtitle>
        </ContainerInformation>
        <ContainerButton>
          <ConfirmButton
            className="checkin-completed-modal__confirm-btn"
            label={t('ok')}
            onClick={closeCompleteModal}
          />
        </ContainerButton>
      </Container>
    </ModalSheet>
  );
}

export {CheckInCompleteModal};
