import React from 'react';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {FormProvider, useForm} from 'react-hook-form';
import {PATHS} from '../../Routes';
import {
  useGuest,
  useModalControls,
  usePath,
  useScreenResize,
  useFetchIVLottie,
} from '../../hooks';
import {device} from '@guestapp/ui/src/utils/device';
import {SessionStorage} from '@guestapp/core';
import {STORAGE_KEYS} from 'utils/constants';
import {EMBEDDED_MODE} from '../../utils/embedded';
import {FORM_NAMES, FormTypes} from 'utils/guestForm';
import {ButtonProps} from 'components/common/Button/Button';
import GuestFormFields from '../../components/GuestFormFields';
import QuestionMarkAndText from 'components/common/QuestionMarkAndText';
import ModalSheet from 'components/common/ModalSheet';
import StandardModalSheet from 'components/common/StandardModalSheet';
import {Page} from '../../components/Page/Page';
import {
  AutoFill,
  AutoFillSubTitle,
  AutoFillText,
  AutoFillTitle,
  FormWrapper,
  IconWrapper,
  ModalSheetContainer,
  StyledIcon,
  StyledArrowIcon,
  Title,
  ViewPrivacyPolicyLink,
  WhyDataRequired,
  WhyDataRequiredText,
  Wrapper,
} from './styled';

function AddPersonalDataForm() {
  const {t} = useTranslation();
  const {isMatch: isMobileMode} = useScreenResize(device.tabletSubHeader);
  const [errorModal, setErrorModal] = React.useState(false);
  const isValidateKeyboard = isMobileMode && errorModal;
  const {id: guestId} = useParams<{id: string}>();
  const {guest} = useGuest({id: guestId});
  const {getFullPath} = usePath();
  const {animationData: ocrAnimationLottie} = useFetchIVLottie({isOrc: true});
  const methods = useForm({
    shouldFocusError: !isValidateKeyboard,
    defaultValues: {
      [FORM_NAMES.residence_city]: null,
      [FORM_NAMES.birth_place_city]: null,
      [FORM_NAMES.document_expedition_city]: null,
    },
  });
  const hasPersistedData = Boolean(
    SessionStorage.get<FormTypes>(STORAGE_KEYS.personalFormData),
  );
  const isAutoFillingAvailable = !hasPersistedData && !Boolean(guest?.id);
  const theme = useTheme();

  const {
    isOpen: isSignatureModal,
    openModal: openSignatureModal,
    closeModal: closeSignatureModal,
  } = useModalControls();

  const {
    isOpen: isOpenConfirmAutoFillModal,
    openModal: openConfirmAutoFillModal,
    closeModal: closeConfirmAutoFillModal,
  } = useModalControls();

  const modalSheetButtons: ButtonProps[] = React.useMemo(() => {
    const primaryButton = {
      label: t('scan_your_document'),
      primary: true,
      to: getFullPath(PATHS.documentMRZ),
    };
    const secondaryButton: ButtonProps = {
      link: true,
      label: t('complete_the_form_manually'),
      onClick: closeConfirmAutoFillModal,
    };

    return [primaryButton, secondaryButton];
  }, [t, closeConfirmAutoFillModal, getFullPath]);

  return (
    <Page
      isSubHeaderVisible={window.ChekinProSettings?.mode !== EMBEDDED_MODE.onlyGuestForm}
      title={t('guest_data')}
      isBackButtonDisabled
      navBarTitle={isMobileMode ? t('guest_data') : t('online_chekin')}
    >
      <Wrapper>
        <Title>{t('enter_your_personal_data')}</Title>
        <WhyDataRequired>
          <QuestionMarkAndText
            text={t('why_all_this_data_is_required')}
            onClick={openSignatureModal}
          />
        </WhyDataRequired>
        {isAutoFillingAvailable && (
          <AutoFill onClick={openConfirmAutoFillModal}>
            <AutoFillText>
              <AutoFillTitle>
                <StyledIcon name={'thunder'} color={theme.colors.branding.primary} />
                <span>{t('auto_fill_form')}</span>
              </AutoFillTitle>
              <AutoFillSubTitle>{t('save_time_scanning')}</AutoFillSubTitle>
            </AutoFillText>
            <IconWrapper>
              <StyledArrowIcon name={'arrowRightBlue'} />
            </IconWrapper>
          </AutoFill>
        )}
      </Wrapper>
      <FormProvider {...methods}>
        <FormWrapper>
          <GuestFormFields setErrorModal={setErrorModal} />
        </FormWrapper>
      </FormProvider>
      <ModalSheet
        isOpenModalSheet={isSignatureModal}
        handleCloseModal={closeSignatureModal}
        title={t('why_all_this_data_is_required')}
        closeOnDocumentClick
        withCloseButton
        padding
        closeButtonType="click"
      >
        <ModalSheetContainer>
          <WhyDataRequiredText>{t('according_to_the_local_law')}</WhyDataRequiredText>
          <WhyDataRequiredText>
            {t('complete_online_chekin_before_arrival')}
          </WhyDataRequiredText>
          <WhyDataRequiredText>
            {t('all_data_processed_according_to_general')}
            {t('learn_more_privacy_policy')}
          </WhyDataRequiredText>
          <ViewPrivacyPolicyLink href={t('privacy_policy_link')} target="_blank">
            {t('view_privacy_policy')}
          </ViewPrivacyPolicyLink>
        </ModalSheetContainer>
      </ModalSheet>

      <ModalSheet
        isOpenModalSheet={isOpenConfirmAutoFillModal}
        handleCloseModal={closeConfirmAutoFillModal}
        title={t('do_you_want_auto_fill_form')}
        withCloseButton
        closeButtonType="click"
        pressToTheTop
      >
        <StandardModalSheet
          buttons={modalSheetButtons}
          text={t('auto_fill_form_modal_text')}
          lottie={ocrAnimationLottie}
        />
      </ModalSheet>
    </Page>
  );
}

export {AddPersonalDataForm};
