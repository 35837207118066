import React from 'react';

function useTimeoutRef() {
  const timeoutIdRef = React.useRef<ReturnType<typeof setTimeout>>();

  const resetTimeout = React.useCallback(() => {
    clearTimeout(timeoutIdRef.current);
  }, []);

  React.useEffect(() => {
    return () => {
      resetTimeout();
    };
  }, [resetTimeout]);

  return timeoutIdRef;
}

function useDebounce<ValueType = string>(
  value: ValueType,
  delayMs = 1000,
): [ValueType, (value: ValueType) => void] {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delayMs);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delayMs]);

  return [debouncedValue, setDebouncedValue];
}

function usePrevious<T>(value: T) {
  const ref = React.useRef<T>();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function useCompare<T>(val: T): boolean {
  const prevVal = usePrevious<T>(val);
  return prevVal !== val;
}

const useLatest = <T>(current: T) => {
  const storedValue = React.useRef(current);

  React.useEffect(() => {
    storedValue.current = current;
  });

  return storedValue;
};

const useRepositionOnResize = (
  handler: (window: Window) => void,
  active = true,
  win: Window = window,
) => {
  React.useEffect(() => {
    if (!active) return;
    const listener = () => {
      handler(win);
    };

    win.addEventListener('resize', listener);

    return () => {
      if (!active) return;
      win.removeEventListener('resize', listener);
    };
  }, [win, handler, active]);
};

type RefItem<T> =
  | ((element: T | null) => void)
  | React.MutableRefObject<T | null>
  | null
  | undefined;

function useCombinedRef<T>(...refs: RefItem<T>[]) {
  const refCb = React.useCallback((element: T | null) => {
    refs.forEach(ref => {
      if (!ref) {
        return;
      }

      if (typeof ref === 'function') {
        ref(element);
      } else {
        ref.current = element;
      }
    });
  }, refs);

  return refCb;
}

export {
  useTimeoutRef,
  useDebounce,
  usePrevious,
  useCompare,
  useLatest,
  useRepositionOnResize,
  useCombinedRef,
};
