import {CartPayment} from '@guestapp/sdk';

export enum UPSELLING_ORIGINS {
  allianz = 'ALLIANZ',
  booking = 'BOOKING',
}

type DisplayedPayment = {
  id: string;
  price: number | string;
  isEditable: boolean;
  title: string;
  onEdit?: () => void;
  isRemovable?: boolean;
  fee?: string | number | null;
  label?: string;
  type?: CartPayment['payment_type'];
  externalId?: string;
  upselling_origin?: UPSELLING_ORIGINS;
  objType?: CartPayment['obj_type'];
};

export type {DisplayedPayment};
