import styled from 'styled-components';

export const Wrapper = styled.button`
  margin-left: auto;
  padding: 6px;
  max-width: 100px;
  width: fit-content;
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  transition: opacity 0.1s;
  z-index: 3;

  &:hover {
    opacity: 0.8;
  }

  @media (min-width: 500px) {
    right: 20px;
    top: 14px;
  }
`;
