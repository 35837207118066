import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {PATHS} from 'Routes';
import {MutateOptions} from '@tanstack/query-core';
import {
  useErrorModal,
  useMutateDeals,
  usePaymentsCart,
  usePaymentSettings,
} from '../../hooks';
import {useOnScreenChanged} from '../../hooks/embedded';
import {useBuildDisplayedPayments} from './useBuildDisplayedPayments';
import {checkIfTotalAmountToPayZero} from '../../utils/payments';
import {checkIsInternetConnectionFast} from '@guestapp/core';
import {CustomIcon} from '@guestapp/ui';
// import info from 'assets/icons/info.svg';
// import emptyStateCart from '../../assets/img/empty-state-cart.svg';
import PaymentItem from './PaymentItem';
import {EmptyState} from '../EmptyState/EmptyState';
import {PayLater} from './PayLater/PayLater';
import {
  PaymentSummaryItemTitle,
  ContentCart,
  Wrapper,
  ConfirmButton,
  Container,
  TotalPayment,
  Summary,
  PaymentSummaryItemWrapper,
  PaymentSummaryItemDescription,
  StyledLoader,
} from './styled';

type PaymentSummaryItemProps = {
  title: string;
  amount: string | number;
  description?: string;
};
function PaymentSummaryItem({title, description, amount}: PaymentSummaryItemProps) {
  return (
    <PaymentSummaryItemWrapper className="payment-summary__item">
      <PaymentSummaryItemTitle className="payment-summary__title">
        <p>{title}</p>
        <p>{amount}</p>
      </PaymentSummaryItemTitle>
      {description && (
        <PaymentSummaryItemDescription className="payment-summary__description">
          <CustomIcon name="information" size={20} />
          {/* <img width={20} height={20} src={info} alt="" /> */}
          <p>{description}</p>
        </PaymentSummaryItemDescription>
      )}
    </PaymentSummaryItemWrapper>
  );
}

function MyCart() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {displayError, ErrorModal} = useErrorModal();
  const {currencySymbol, currency} = usePaymentSettings();
  const {deleteDealMutation} = useMutateDeals({onError: displayError});

  const {getPayment} = useBuildDisplayedPayments();
  const {
    paymentsCart,
    isPaymentsCartLoading,
    isPaymentsCartFetching,
    isPaymentsCartEmpty,
    addDeferredPayments,
    removeDeferredPayment,
  } = usePaymentsCart({refetchOnWindowFocus: true});
  const isTotalAmountToPayZero = checkIfTotalAmountToPayZero(paymentsCart);

  useOnScreenChanged({
    type: 'PAYMENTS_CART',
    enabled: !!paymentsCart,
    meta: {
      currency,
      total_amount_to_pay: paymentsCart?.total_amount_to_pay,
      pre_auth_payments_total: paymentsCart?.pre_auth_payments_total,
      retention_payments_total: paymentsCart?.retention_payments_total,
    },
  });

  const payNowPayments = React.useMemo(() => {
    return (paymentsCart?.pay_now_payments || []).map(getPayment);
  }, [getPayment, paymentsCart?.pay_now_payments]);

  const payLaterPayments = React.useMemo(() => {
    return (paymentsCart?.pay_later_payments || []).map(getPayment);
  }, [getPayment, paymentsCart?.pay_later_payments]);

  const handleRemove = React.useCallback(
    (id?: string, options?: MutateOptions<unknown, unknown, unknown>) => {
      if (id) deleteDealMutation(id, options);
    },
    [deleteDealMutation],
  );

  const handlePayLater = React.useCallback(
    (id?: string) => {
      const addingPayment = paymentsCart?.pay_now_payments?.find(
        payment => payment.id === id,
      );

      if (addingPayment) {
        addDeferredPayments(addingPayment);
      }
    },
    [addDeferredPayments, paymentsCart?.pay_now_payments],
  );

  const handlePayNow = () => {
    if (isTotalAmountToPayZero) return;
    navigate(PATHS.payments.form, {
      state: {
        payments: paymentsCart?.pay_now_payments,
        pay_later_payments: paymentsCart?.pay_later_payments,
        total_amount_to_pay: paymentsCart?.total_amount_to_pay,
      },
    });
  };

  return (
    <Wrapper
      isLoading={!isPaymentsCartLoading && isPaymentsCartFetching}
      className="my-cart"
    >
      <StyledLoader
        visible={isPaymentsCartLoading && !checkIsInternetConnectionFast()}
        width={80}
      />
      {isPaymentsCartEmpty && (
        <EmptyState
          imageSrc="emptyStateCart"
          title={t('the_cart_is_empty')}
          description={t('the_cart_is_empty_description')}
        />
      )}
      {
        <ContentCart isInitialized={!!payNowPayments.length} className="my-cart__list">
          {payNowPayments.map(item => {
            return (
              <PaymentItem
                payment={item}
                key={item.id}
                onRemove={handleRemove}
                onPayLater={handlePayLater}
                currency={currencySymbol}
              />
            );
          })}
        </ContentCart>
      }
      {!isPaymentsCartEmpty && (
        <Container isInitialized={!isPaymentsCartLoading}>
          <Summary className="payment-summary">
            <TotalPayment
              isEmpty={isTotalAmountToPayZero}
              className="payment-summary__total"
            >
              <div>{t('to_pay_now')}</div>
              <div>
                {paymentsCart?.total_amount_to_pay || 0}
                {currencySymbol}
              </div>
            </TotalPayment>
            {!!parseFloat(paymentsCart?.online_payments_total || '0') && (
              <PaymentSummaryItem
                title={t('online_payment') + ':'}
                amount={paymentsCart?.online_payments_total + currencySymbol}
              />
            )}
            {!!parseFloat(paymentsCart?.retention_payments_total || '0') && (
              <PaymentSummaryItem
                title={t('retention')}
                amount={paymentsCart?.retention_payments_total + currencySymbol}
                description={t('my_card_retention_explanation')}
              />
            )}
            {!!parseFloat(paymentsCart?.pre_auth_payments_total || '0') && (
              <PaymentSummaryItem
                title={t(`pre_authorizations`) + ':'}
                amount={paymentsCart?.pre_auth_payments_total + currencySymbol}
                description={t('my_card_pre_auth_explanation')}
              />
            )}
          </Summary>

          <ConfirmButton
            label={t('confirm_transaction')}
            disabled={
              isPaymentsCartLoading || isPaymentsCartEmpty || isTotalAmountToPayZero
            }
            onClick={handlePayNow}
          />
          {!!payLaterPayments.length && (
            <PayLater
              payments={payLaterPayments}
              currency={currencySymbol}
              onSendBackToCart={removeDeferredPayment}
            />
          )}
        </Container>
      )}
      <ErrorModal />
    </Wrapper>
  );
}

export {MyCart};
