import styled from 'styled-components';
import Lottie from 'lottie-react';
import {device} from 'styled/device';
import {ModalTwoButtonsWrapper, Row} from '../../../../styled/common';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 20px;
  height: auto;
`;

export const Text = styled.div`
  margin: 0 auto 48px;
  text-align: center;
  max-width: 430px;
  font-size: 14px;
  color: ${props =>
    props.theme.hasTemplate ? props.theme.colors.branding.fontSecondary : '#152946'};

  @media (min-width: ${device.tablet}) {
    font-size: 16px;
  }
`;

export const ButtonsContainer = styled(ModalTwoButtonsWrapper)``;

export const BottomBlock = styled.div`
  /* margin: auto auto 0; */
  display: flex;
  flex-direction: column;
  grid-row-gap: 48px;
  width: 100%;
`;

export const HelpersBlock = styled.div.attrs({className: 'iv-upload-type-view__helpers'})`
  display: flex;
  flex-direction: column;
  grid-row-gap: 15px;
  margin: 0 auto 0;
  text-align: left;
`;

export const HelpersItem = styled(Row).attrs({
  className: 'iv-upload-type-view__helper-item',
})`
  font-size: 12px;
  color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.fontSecondary
      : props.theme.colors.$p10};

  & > p {
    margin: 0 0 0 10px;
  }
`;

export const GifContainer = styled.img`
  width: 225px;
  margin: 0 auto 68px;
  min-height: 225px;
`;

export const StyledLottieIVDocumentsAnimations = styled(Lottie)`
  width: 300px;
  margin: 0 auto 48px;
  min-height: 225px;
`;
