import React from 'react';
import {useLocation} from 'react-router-dom';
import {useFrame} from '../context/frame';

export default function ScrollToTop() {
  const {pathname} = useLocation();
  const {document} = useFrame();

  React.useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname, document]);

  return null;
}
