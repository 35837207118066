import React, {useState, useEffect, useRef, memo} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {
  CardCvcElement,
  CardCvcElementComponent,
  CardElementProps,
  CardExpiryElement,
  CardExpiryElementComponent,
  CardNumberElement,
  CardNumberElementComponent,
  useElements,
} from '@stripe/react-stripe-js';
import {
  StripeCardCvcElement,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElement,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
  StripeCardNumberElementOptions,
  StripeElementLocale,
} from '@stripe/stripe-js';
import {PATTERNS} from '@guestapp/core';
import {FORM_NAMES, FormTypes} from './PaymentForm';
import {useStripeFieldsState} from './useStripeFieldsState';
import {getStringWithoutNumbers} from '../../utils/common';
import redXIcon from 'assets/icons/credit-card-red-x.svg';
import {Input, Fieldset} from '@guestapp/ui';
import {InputErrorMessage} from '../common/Input/styled';
import {
  FieldsColumn,
  FieldsRow,
  StatusCustomIcon,
  StatusIcon,
  StripeFieldContainer,
} from './styled';

const MaxNameValueLength = 100;
const cardNumberElementOptions: StripeCardNumberElementOptions = {
  placeholder: '',
  style: {
    base: {
      color: '#161643',
      fontSize: '16px',
    },
  },
};

type StripeCardElement =
  | StripeCardNumberElement
  | StripeCardCvcElement
  | StripeCardExpiryElement;

type StripeFieldChangeEvent =
  | StripeCardNumberElementChangeEvent
  | StripeCardCvcElementChangeEvent
  | StripeCardExpiryElementChangeEvent;

type StripeFieldWrapperProps = {
  label: string;
  Field:
    | CardNumberElementComponent
    | CardCvcElementComponent
    | CardExpiryElementComponent;
  disabled: boolean;
  onChange?: (e: StripeFieldChangeEvent) => void;
  disableErrors?: boolean;
};

function StripeFieldWrapper({
  Field,
  label,
  disabled,
  onChange,
  disableErrors,
}: StripeFieldWrapperProps) {
  const theme = useTheme();
  const elementRef = useRef<StripeCardElement>();
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const handleOnChange = (event: StripeFieldChangeEvent) => {
    setIsEmpty(event.empty);
    setError(event.error?.message || null);
    setIsCompleted(event.complete);
    onChange?.(event);
  };
  const handleOnReady = (element: StripeCardElement) => {
    setIsReady(true);
    elementRef.current = element;
  };

  return (
    <StripeFieldContainer
      disabled={!isReady || disabled}
      isEmpty={isEmpty}
      isFocused={isFocused}
      onClick={() => {
        elementRef.current?.focus();
      }}
    >
      {error && (
        <StatusIcon
          src={redXIcon}
          alt=""
          width={24}
          height={24}
          error={!!error}
          onClick={() => {
            if (error) elementRef.current?.clear();
          }}
        />
      )}
      <Field
        options={{
          ...cardNumberElementOptions,
          style: {
            base: {
              color: theme.colors.branding.fontPrimary,
            },
          },
          disabled: disabled || !isReady,
        }}
        onReady={handleOnReady}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={handleOnChange}
      />

      {isCompleted && (
        <StatusCustomIcon name={'blueTickIcon'} size={24} error={!!error} />
      )}
      {Boolean(error && !disableErrors) && <InputErrorMessage>{error}</InputErrorMessage>}
      <Fieldset
        isActivated={isFocused || !isEmpty}
        isEmpty={isEmpty}
        isFocused={isFocused}
        invalid={!!error}
        label={label}
      />
    </StripeFieldContainer>
  );
}

type StripeExpiryAndCVCFieldsGroupProps = {
  disabled: boolean;
};
const StripeExpiryAndCVCFieldsGroup = ({
  disabled,
}: StripeExpiryAndCVCFieldsGroupProps) => {
  const {t} = useTranslation();
  const [expiryError, setExpiryError] = useState<string | null>(null);
  const [cvcError, setCVCError] = useState<string | null>(null);

  return (
    <FieldsRow>
      <StripeFieldWrapper
        label={t('expiry_date')}
        Field={CardExpiryElement}
        onChange={event => setExpiryError(event.error?.message || null)}
        disabled={disabled}
        disableErrors
      />
      <StripeFieldWrapper
        label="CVV"
        Field={CardCvcElement}
        onChange={event => setCVCError(event.error?.message || null)}
        disabled={disabled}
        disableErrors
      />
      {Boolean(cvcError || expiryError) && (
        <InputErrorMessage>{expiryError || cvcError}</InputErrorMessage>
      )}
    </FieldsRow>
  );
};

const handleChangeHolderName =
  (callBack: (value: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const transformedValue = getStringWithoutNumbers(event?.target?.value);
    return callBack(transformedValue);
  };

type StripePaymentFormProps = {
  splitCardElementsProps?: CardElementProps | null;
  onFormReady?: () => void;
  onFormComplete?: (state: boolean) => void;
  disabledFields?: boolean;
};
const StripePaymentForm = memo(function StripePaymentForm({
  onFormReady,
  onFormComplete,
  disabledFields,
}: StripePaymentFormProps) {
  const {resetField, reset} = useFormContext<FormTypes>();
  const elements = useElements();
  const {t, i18n} = useTranslation();
  useStripeFieldsState({onFormComplete, onFormReady});

  useEffect(
    function clearStripeFormWhenChangedLanguage() {
      elements?.getElement(CardNumberElement)?.clear();
      elements?.getElement(CardExpiryElement)?.clear();
      elements?.getElement(CardCvcElement)?.clear();
      reset();
    },
    [elements, i18n.language, reset],
  );

  useEffect(() => {
    const newLocale = i18n.language as StripeElementLocale;
    elements?.update({locale: newLocale});
  }, [elements, i18n.language]);

  return (
    <FieldsColumn>
      <Controller
        name={FORM_NAMES.holderName}
        rules={{
          pattern: {
            value: PATTERNS.name,
            message: t('cant_contain_number_and_symbols'),
          },
          maxLength: {
            value: MaxNameValueLength,
            message: t('max_length', {length: MaxNameValueLength}),
          },
        }}
        defaultValue=""
        render={({field: {onChange, ...field}, fieldState: {error}}) => (
          <Input
            autoFocus
            autoCorrect="off"
            spellCheck={false}
            label={t('card_holder')}
            error={error?.message}
            disabled={disabledFields}
            empty={!field.value}
            onChange={handleChangeHolderName(onChange)}
            onReset={() => resetField(field.name, {keepTouched: true})}
            showStatusIcon
            {...field}
          />
        )}
      />

      <StripeFieldWrapper
        label={t('card_number')}
        Field={CardNumberElement}
        disabled={!!disabledFields}
      />
      <StripeExpiryAndCVCFieldsGroup disabled={!!disabledFields} />
    </FieldsColumn>
  );
});

export {StripePaymentForm};
