import {useTranslation} from 'react-i18next';
import {useBuildNavigationItems, usePath} from 'hooks';
import NavigationItem from 'components/NavigationItem';
import {NavigationPanelsContainer} from './styled';

type NavigationTasksItemsProps = {
  showNavigationPanels: boolean;
};

const NavigationTasksItems = ({showNavigationPanels}: NavigationTasksItemsProps) => {
  const {displayedNavigationItems} = useBuildNavigationItems();
  const {t} = useTranslation();
  const {getFullPath} = usePath();
  return (
    <NavigationPanelsContainer
      isOpen={showNavigationPanels}
      className="chekin-boxes__tasks-items-container"
    >
      {displayedNavigationItems.map((navItem, index) => {
        return (
          <NavigationItem
            title={t(navItem.title)}
            completed={navItem.completed}
            key={index}
            path={getFullPath(String(navItem.path))}
            icon={navItem.icon}
            flat
            notification={navItem.notification}
          />
        );
      })}
    </NavigationPanelsContainer>
  );
};

export {NavigationTasksItems};
