import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {PATHS} from 'Routes';
import {usePath} from 'hooks';
import {useDiscardIVModal} from 'hooks/useDiscardIVModal';
import ModalSheet from '../ModalSheet';
import DiscardLastPhoto from 'components/ModalSheets/DiscardLastPhoto';

export type DiscardLastPhotoModalRef = {
  open: (link?: string) => void;
};
const DiscardLastPhotoModal = React.forwardRef<DiscardLastPhotoModalRef>((_, ref) => {
  const {t} = useTranslation();
  const {isOpenDiscardIVModal, closeDiscardIVModal, openDiscardIVModal} =
    useDiscardIVModal();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const {getFullPath} = usePath();

  const discardFunction = React.useCallback(
    (link?: string) => {
      const verificationViewsToOpenDiscardModal = [
        PATHS.verification.front,
        PATHS.verification.back,
        PATHS.verification.selfie,
        PATHS.verification.uploadingType,
        PATHS.verification.documentSelection,
        'upload',
        'take',
      ];
      const [currentVerificationView] = pathname.split('/').slice(-1);
      const isVerificationViewToOpenDiscardModal =
        verificationViewsToOpenDiscardModal.includes(currentVerificationView);

      if (isVerificationViewToOpenDiscardModal) {
        openDiscardIVModal();
        return;
      }
      navigate(getFullPath(link ?? PATHS.home));
    },
    [pathname, openDiscardIVModal, getFullPath, navigate],
  );

  React.useImperativeHandle(
    ref,
    () => {
      return {
        open: discardFunction,
      };
    },
    [discardFunction],
  );

  return (
    <ModalSheet
      isOpenModalSheet={isOpenDiscardIVModal}
      handleCloseModal={closeDiscardIVModal}
      title={t('discard_last_photo')}
      closeOnDocumentClick={false}
    >
      <DiscardLastPhoto onClose={closeDiscardIVModal} />
    </ModalSheet>
  );
});

export {DiscardLastPhotoModal};
