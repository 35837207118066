import {useQuery} from '@tanstack/react-query';
import {useChekinSDK} from 'context/ChekinSDK';
import {QUERY_CACHE_KEYS} from 'utils/constants';

type GuestProps = {
  id?: string;
};

function useGuest({id}: GuestProps) {
  const {getGuest, isSuccessSDK} = useChekinSDK();

  const {
    data,
    isLoading: isLoadingInternal,
    isError,
  } = useQuery([QUERY_CACHE_KEYS.guest, id], () => getGuest(id || ''), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: isSuccessSDK && Boolean(id),
  });

  const isGuestLoaded = Boolean(data?.id) && !isLoadingInternal;
  const isLoading = Boolean(id) && isLoadingInternal;

  return {
    guest: data,
    isLoading,
    isError,
    isGuestLoaded,
  };
}

export {useGuest};
