import styled, {css} from 'styled-components';
import {device} from '@guestapp/ui/src/utils/device';
import {CustomIcon} from '@guestapp/ui';

export const BoxTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${props => props.theme.fonts.fontFamily.titles.font_name};
  font-size: 24px;
  font-style: normal;
  font-weight: ${props => props.theme.fonts.fontWeight.medium};
  line-height: 24px;
  letter-spacing: 0.36px;
  color: ${props => props.theme.colors.branding.fontPrimary};
  margin-bottom: 16px;
`;

export const ShareBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  path {
    fill: ${({theme}) =>
      theme.hasTemplate
        ? theme.colors.branding.primary
        : theme.colors.buttons.mainButtonBackground};
  }
  gap: 8px;
  @media (min-width: ${device.tablet}) {
    top: 24px;
  }
`;

export const ShareText = styled.div`
  color: ${({theme}) =>
    theme.hasTemplate
      ? theme.colors.branding.primary
      : theme.colors.buttons.mainButtonBackground};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

export const Description = styled.div`
  color: ${props => props.theme.colors.branding.fontSecondary};
  line-height: 24px; /* 171.429% */
  font-weight: ${props => props.theme.fonts.fontWeight.regular};
  font-size: ${props => props.theme.fonts.sizes.small};
  line-height: 24px;
  margin-bottom: 24px;
  @media (min-width: ${device.laptopL}) {
    margin-bottom: 30px;
  }
`;

export const TaskText = styled.div<{marginBottom?: string}>`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.branding.fontPrimary};
  font-size: ${props => props.theme.fonts.sizes.medium};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: ${props => props.marginBottom ?? '0px'};
  & span {
    color: ${props => props.theme.colors.branding.fontSecondary};
  }

  & .custom-icon {
    margin-right: 8px;
  }
`;

export const NavigationPanelsContainer = styled.div<{isOpen: boolean}>`
  .navigation-item-container {
    max-width: 100%;
    margin-bottom: 16px;
  }
  height: ${props => (props.isOpen ? 'auto' : '0')};
  overflow: hidden;
  transition: height 0.3s;
  @media (min-width: ${device.laptopM}) {
    height: auto;
    display: flex;
    gap: 24px;
  }
`;

export const StyledSuccessIcon = styled(CustomIcon)`
  display: block;
  path {
    fill: ${({theme}) => (theme.hasTemplate ? theme.colors.branding.primary : '#32D7BE')};
  }
`;

export const NavigationPanelsArrow = styled(CustomIcon).attrs({
  className: 'nav-panel-item__down-arrow',
})<{isOpen: boolean}>`
  margin-left: auto;
  transform: rotate(90deg);
  transition: transform 0.3s;
  path {
    stroke: ${props => props.theme.colors.branding.icons};
  }

  ${props =>
    props.isOpen &&
    css`
      transform: rotate(-90deg);
      transition: transform 0.3s;
    `}

  @media (min-width: ${device.laptopM}) {
    display: none;
  }
`;

export const TextAndArrowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
