import styled, {css} from 'styled-components';
import {CustomIcon} from '@guestapp/ui';
import {device} from 'styled/device';
import {Link} from 'react-router-dom';
import IconButton from '../common/IconButton';

export type NavigationStatusTextProps = {
  $completed: boolean;
};

const getItemStyles = (props: NavigationStatusTextProps) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  padding: 15px 18px 15px;
  background-color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.boxesModals : '#fff'};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  max-height: 126px;

  &:last-child {
    border-bottom: none;
  }
  ${!props.$completed &&
  css`
    cursor: pointer;
  `}
`;

export const Item = styled.div<NavigationStatusTextProps>`
  ${props => getItemStyles(props)};
`;

export const LinkItem = styled(Link)<NavigationStatusTextProps>`
  ${props => getItemStyles(props)};
  cursor: pointer;
`;

export const Icon = styled.img``;

export const Texts = styled.div`
  display: block;
`;

export const Title = styled.div`
  display: block;
  width: 100%;
  font-weight: ${props => props.theme.fonts.fontWeight.bold};
  font-size: ${props => props.theme.fonts.sizes.medium};
  line-height: 24px;
  color: ${props => props.theme.colors.branding.fontPrimary};

  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StatusText = styled.div<NavigationStatusTextProps>`
  display: flex;
  width: 100%;
  font-style: normal;
  font-weight: ${props => props.theme.fonts.fontWeight.bold};
  font-size: ${props => props.theme.fonts.sizes.small};
  line-height: 24px;
  color: ${props => props.theme.colors.$p8};
  user-select: none;

  ${props =>
    props.$completed &&
    css`
      color: ${props => props.theme.colors.$s2};
    `}
`;

export const DeleteGuestIcon = styled(IconButton)`
  margin-left: auto;

  path {
    stroke: ${props => props.theme.colors.branding.icons};
  }

  @media (min-width: ${device.tablet}) {
    margin-right: 20px;
  }
`;

export const StatusIcon = styled.img`
  margin-right: 6px;
`;

export const IconCustom = styled(CustomIcon)`
  path {
    stroke: ${props => props.theme.colors.branding.icons};
  }
`;

export const NextArrow = styled(CustomIcon).attrs({className: 'guests-panel__edit-btn'})`
  margin-left: auto;

  path {
    stroke: ${props => props.theme.colors.branding.icons};
  }
`;

export const ButtonBox = styled(CustomIcon).attrs({className: 'guests-panel__add-btn'})`
  path {
    stroke: ${({theme}) =>
      theme.hasTemplate ? theme.colors.branding.boxesModals : '#fff'};
  }
`;

export const AddPanelText = styled.div`
  color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.fontSecondary : '#777e91'};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const EmptyUserPanel = styled.div`
  display: flex;
  max-height: 80px;
  cursor: pointer;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.backgroundSecondary : '#eaecef'};

  @media (min-width: ${device.tablet}) {
    width: 100%;
  }

  @media (min-width: ${device.laptopM}) {
    max-width: 416px;
  }
`;

export const PanelGroupTitle = styled.div`
  color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.fontPrimary : '#152946'}
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
  align-items: center;

  & img {
    margin-top: -3px;
  }
`;

export const PanelsGroupWrapper = styled.div`
  margin-bottom: 24px;
  width: 280px;

  @media (min-width: ${device.mobileM}) {
    width: 325px;
  }

  @media (min-width: ${device.tablet}) {
    width: 100%;
  }
`;

export const PanelsGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${device.tablet}) {
    display: grid;
    gap: 16px;
    width: 100%;
    grid-template-columns: 1fr;
  }

  @media (min-width: ${device.laptopM}) {
    display: grid;
    gap: 16px;
    width: 856px;
    grid-template-columns: 1fr 1fr;
  }
`;
