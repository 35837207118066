import styled from 'styled-components';
import {device} from 'styled/device';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  /* height: 100%; */
  padding: 0 20px 100px;
  min-height: calc(100% - 64px);
  z-index: 1;
  align-items: center;

  @media (min-width: ${device.tablet}) {
    padding: 0 64px 100px;
    height: auto;
    min-height: auto;
  }
`;

export const PageWrapper = styled.div`
  display: flex;
`;

export const SideBarWrapper = styled.div`
  width: 284px;
`;

export const PageContainer = styled.div`
  width: 100%;
`;

export const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  & .pay-now-total-items {
    width: 25px;
    height: 22px;
    background: ${props => props.theme.colors.$s1};
    border-radius: 11px;
    color: rgb(255, 255, 255);
    text-align: center;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    /* margin-right: -10px; */
    font-size: ${props => props.theme.fonts.sizes.small};
    font-weight: ${props => props.theme.fonts.fontWeight.bold};
  }
`;

export const Label = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
