import React from 'react';
import styled from 'styled-components';
import SVG, {Props as SVGProps} from 'react-inlinesvg';

import addGuest from '../../../assets/icons/add-guest.svg';
import avatarAccount from '../../../assets/icons/avatarAccountIcon.svg';
import accountMenuKey from '../../../assets/icons/accountMenuKey.svg';
import accountMenuBookings from '../../../assets/icons/accountMenuBookings.svg';
import accountMenuStar from '../../../assets/icons/accountMenuStar.svg';
import accountMenuAccount from '../../../assets/icons/accountMenuAccount.svg';
import accountMenuHome from '../../../assets/icons/accountMenuHome.svg';
import arrowNext from '../../../assets/icons/arrowNext.svg';
import backArrow from '../../../assets/icons/back-arrow.svg';
import blackArrow from '../../../assets/icons/blackArrowIcon.svg';
import whiteArrow from '../../../assets/icons/whiteArrowIcon.svg';
import webcam from '../../../assets/icons/webcam.svg';
import mapnemu from '../../../assets/icons/map-menu.svg';
import smallClock from '../../../assets/icons/smallClockIcon.svg';
import smallGuest from '../../../assets/icons/smallGuestIcon.svg';
import share from '../../../assets/icons/share.svg';
import shareLink from '../../../assets/icons/shareLink.svg';
import shareFinishLater from '../../../assets/icons/shareFinishLater.svg';
import shareGuests from '../../../assets/icons/shareGuests.svg';
import success from '../../../assets/icons/successIcon.svg';
import payment from '../../../assets/icons/payment.svg';
import edit from '../../../assets/icons/edit-guest-icon.svg';
import removeguest from '../../../assets/icons/remove-guest-icon.svg';
import date from '../../../assets/icons/dates-icon.svg';
import guests from '../../../assets/icons/guests-icon.svg';
import displayArrow from '../../../assets/icons/display-blue-icn.svg';
import magnifier from '../../../assets/icons/magnifier.svg';
import magnifierGray from '../../../assets/icons/magnifier-gray.svg';
import mapMark from '../../../assets/icons/mapMark.svg';
import menuVerification from '../../../assets/icons/menuVerificationIcon.svg';
import menuNotifications from '../../../assets/icons/menuNotificationsIcon.svg';
import menuPayments from '../../../assets/icons/menuPaymentsIcon.svg';
import menuPersonal from '../../../assets/icons/menuPersonalIcon.svg';
import paymentMastercard from '../../../assets/icons/paymentMastercard.svg';
import paymentVisa from '../../../assets/icons/paymentVisa.svg';
import close from '../../../assets/icons/close-icon.svg';
import calendar from '../../../assets/icons/calendar-icon.svg';
import shoppingBag from '../../../assets/icons/shoppingBagIcon.svg';
import circleLanguage from '../../../assets/icons/circleLanguageIcon.svg';
import circlePeople from '../../../assets/icons/circlePeopleIcon.svg';
import circleTime from '../../../assets/icons/circleTimeIcon.svg';
import circleGlass from '../../../assets/icons/circleGlassIcon.svg';
import like from '../../../assets/icons/likeIcon.svg';
import location from '../../../assets/icons/locationIcon.svg';
import shareCircle from '../../../assets/icons/shareIcon.svg';
import includedHealth from '../../../assets/icons/includedHealth.svg';
import includedTicket from '../../../assets/icons/includedTicket.svg';
import includedDrinks from '../../../assets/icons/includedDrinks.svg';
import includedFood from '../../../assets/icons/includedFood.svg';
import map from '../../../assets/icons/map-icon.svg';
import language from '../../../assets/icons/language-icon.svg';
import price from '../../../assets/icons/price-icon.svg';
import arrowLeft from '../../../assets/icons/icon-arrow-left.svg';
import arrowRight from '../../../assets/icons/icon-arrow-right.svg';
import questionCircle from '../../../assets/icons/icon-question-circle.svg';
import lock from '../../../assets/icons/lock-icon.svg';
import star from '../../../assets/icons/yellow-star-icon.svg';
import checkFilled from '../../../assets/icons/check-filled.svg';
import errorCompleted from '../../../assets/icons/error-completed-icon.svg';
import artCult from '../../../assets/icons/art-cult-icon.svg';
import filter from '../../../assets/icons/filter-icon.svg';
import filterGray from '../../../assets/icons/filter-icon-gray.svg';
import arrowTop from '../../../assets/icons/arrow-top-icon.svg';
import arrowBottom from '../../../assets/icons/arrow-bottom-blue-icon.svg';
import shareDowload from '../../../assets/icons/share-dowload.svg';
import backBlueArrow from 'assets/icons/back-blue-arrow.svg';

const icons = {
  addGuest,
  avatarAccount,
  accountMenuKey,
  accountMenuBookings,
  accountMenuStar,
  accountMenuAccount,
  accountMenuHome,
  artCult,
  arrowNext,
  arrowRight,
  arrowLeft,
  arrowTop,
  arrowBottom,
  backArrow,
  blackArrow,
  webcam,
  mapnemu,
  share,
  shareLink,
  shareFinishLater,
  shareGuests,
  success,
  shoppingBag,
  smallClock,
  smallGuest,
  payment,
  edit,
  removeguest,
  date,
  includedHealth,
  includedTicket,
  includedDrinks,
  includedFood,
  guests,
  displayArrow,
  magnifier,
  magnifierGray,
  mapMark,
  menuVerification,
  menuNotifications,
  menuPayments,
  menuPersonal,
  lock,
  paymentMastercard,
  paymentVisa,
  whiteArrow,
  close,
  calendar,
  circleLanguage,
  circlePeople,
  circleTime,
  circleGlass,
  like,
  location,
  shareCircle,
  map,
  language,
  price,
  questionCircle,
  star,
  checkFilled,
  errorCompleted,
  filter,
  filterGray,
  shareDowload,
  backBlueArrow,
};

type IconNames = keyof typeof icons;

const transformSizePropToSize = (size?: string | number) => {
  if (!size) {
    return null;
  } else if (typeof size === 'number') {
    return `${size}px`;
  } else if (size.slice(-2) === 'px') {
    return size;
  } else {
    return `${size}px`;
  }
};

type IconProps = {
  name: IconNames;
  src?: string;
  title?: string;
  className?: string;
  size?: string | number;
  transform?: string | number;
  color?: string;
} & React.HTMLAttributes<SVGElement> &
  React.HTMLAttributes<SVGProps>;

const StyledSVGIcon = styled(SVG)<{
  src: string;
  color?: string;
  size?: string | number;
}>`
  width: ${props => transformSizePropToSize(props.size) || '24px'};
  height: ${props => transformSizePropToSize(props.size) || '24px'};
  fill: ${props => props.color || 'none'};
  color: ${props => props.color || 'none'};
  flex-shrink: 0;

  path {
    fill: ${props => props.color || 'currentColor'};
  }
`;

const StyledIcon = React.memo((props: IconProps) => {
  return (
    <StyledSVGIcon
      src={icons[props.name] || props.src || ''}
      size={props.size}
      title={props.title || props.name}
      color={props.color}
      className={props.className}
    />
  );
});

export default StyledIcon;
export {icons};
export type {IconNames, IconProps};
