import {ThemeApi} from '../api';
import {FetcherSettings} from '../api/apiClient';

class ThemeServices {
  ThemeApi: ThemeApi;

  constructor() {
    this.ThemeApi = new ThemeApi();
  }

  async getCustomTheme(id: string, resolverSettings?: FetcherSettings) {
    const response = await this.ThemeApi.getCustomTheme(id, resolverSettings);
    return response.body;
  }
}

export {ThemeServices};
