import {
  type ChangeEvent,
  type KeyboardEvent,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ReactElement,
  useLayoutEffect,
} from 'react';
import dayjs from 'dayjs';
import {Trans, useTranslation} from 'react-i18next';
import {Controller, useFormContext, UseFormGetValues} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {TFunction} from 'i18next';
import ChekinPro from '../ChekinPro';
import {
  LocalStorage,
  getRegExpFromString,
  PATTERNS,
  useResetFieldIfTypeChanged,
} from '@guestapp/core';
import {usePreloadGuestData} from './usePreloadGuestData';
import {useReservation} from 'context/reservation';
import {
  IdentityVerificationPayload,
  useAllianzPlans,
  useAllianzUsers,
  useBuildIVPayload,
  useGuest,
  useGuestGroup,
  useGuestMutation,
  useFirstGuestMustBeAdult,
} from 'hooks';
import {useOpenModals} from 'context/openModals';
import {AddFormType, useSchema} from '../context/guest';
import {
  CreateGuestPayload,
  CustomField,
  CustomForm,
  DATE_FORMAT,
  FIELD_TYPES,
  FieldsErrorResponse,
  getPhoneNumberString,
  Guest,
  PAYLOAD_FIELDS_NAMES,
  POLICE_CODES,
  SelectOptionType,
  STANDARD_FIELD_TYPES,
  Validators,
} from '@guestapp/sdk';
import {
  COUNTRY_CODES,
  QUERY_CACHE_KEYS,
  SHOWED_SUCCESS_REGISTER_COMPLETE_MODAL,
  IS_LEADER_GUEST,
} from '../utils/constants';
import {
  EXTRA_FORM_NAMES,
  FORM_NAMES,
  FORM_NAMES_DTO,
  FormNamesOldType,
  FormTypes,
  IV_FIELDS,
  SIGNATURE_FIELDS,
} from '../utils/guestForm';
import {useActions} from './redux';
import {useErrorModal, useIsMounted, useModalControls} from './common';
import {useChekinSDK} from '../context/ChekinSDK';
import {useSummary} from 'context/summary';
import {getPayloadDate, getSignaturePayLoadValue} from '../utils/common';
import {useErrorHandler} from '../components/GuestFormFields/useErrorHandler';
import {usePreloadPoliceData} from '../components/GuestFormFields/usePreloadPoliceData';
import {useUpsellingFlow} from 'components/upselling/useUpsellingFlow';
import {
  getCustomFieldData,
  getDocNumberPattern,
  maxBirthPlaceAddressLength,
  maxLength,
  maxNamesInputLength,
  minLength,
  validateBirthDate,
  validateDocNumberByDocumentType,
  validateExpirationDate,
  validateIssueDate,
  validateResidencePhoneNumber,
  validateGuestMustBeAdult,
  validatePostalCode,
  validateDocNumberUniqueness,
} from '../pages/AddPersonalDataForm/utils';
import Select from '../components/common/Select';
import Datepicker from '../components/common/Datepicker';
import PhoneInput from '../components/common/PhoneInput';
import ModalGuestRegistered from '../components/ModalGuestRegistered';
import SignBlock from 'components/SignBlock';
import TimePicker from 'components/common/FieldTimePicker';
import FileInput from 'components/common/FieldFileInput';
import IncompleteRegisterModal from 'components/IncompleteRegisterModal';
import AdaptiveQueriedInfiniteScrollSelect from '../components/common/QueriedInfiniteScrollSelect';
import DiscardChangesModalSheet from 'components/ModalSheets/DiscardChanges';
import StatusModal, {StatusModalStates} from 'components/StatusModal';
import {GuestEmailField} from 'components/GuestFormFields';
import {InputController as Input} from '@guestapp/ui';
import {FormFieldWrapper, InvalidFileTypeError} from '../styled/common';

export const maxSupportNumberInputLength = 9;
const AcceptableDocuments = '.jpg, .png, .pdf, .jpeg';
const ValidFileTypes = ['image/jpeg', 'application/pdf', 'image/png'];

const getIsShowInvoiceFields = (): boolean | undefined => {
  const showInvoiceFields = LocalStorage.get(IS_LEADER_GUEST);
  if (showInvoiceFields === null || showInvoiceFields === 'undefined') {
    return undefined;
  }
  return JSON.parse(showInvoiceFields);
};

const getPhoneFieldLabel = (t: TFunction, policeType?: string) => {
  if (policeType && [POLICE_CODES.uhh, POLICE_CODES.uhh2].includes(policeType)) {
    return t('residence_country_phone');
  }

  return t('phone_number');
};

const getNoOptionsMessageForDocTypeField =
  (getValues: UseFormGetValues<FormTypes>, t: TFunction) => () => {
    const isNationalityFilled = Boolean(getValues()[FORM_NAMES.nationality]?.value);

    return !isNationalityFilled ? t('please_fill_nationality_before') : undefined;
  };

const filterGenderOptions = (
  options?: SelectOptionType[],
): SelectOptionType[] | undefined => {
  return options?.filter(opt => opt?.value !== 'N');
};

const sortFieldsByOrder = (arr: FORM_NAMES[], formFields: AddFormType) =>
  arr.sort((a, b) => formFields[a]?.order - formFields[b]?.order);

type UseResetCityIfCountryChangedProps = {
  cityFieldName: FORM_NAMES;
  countryFieldName: FORM_NAMES;
};
const useResetCityIfCountryChanged = ({
  cityFieldName,
  countryFieldName,
}: UseResetCityIfCountryChangedProps) => {
  const {watch, getValues, resetField} = useFormContext<FormTypes>();
  const countryValue = watch(countryFieldName)?.value;

  useEffect(() => {
    const city = getValues(cityFieldName);

    if (city?.value) {
      resetField(cityFieldName);
    }
  }, [countryValue, cityFieldName, resetField, getValues]);
};

type BuildGuestPayloadArgs = {
  reservationId?: string;
  data: FormTypes;
  signature: string;
  customForm: CustomForm;
  identityVerificationPayload?: IdentityVerificationPayload;
};

const buildCustomFieldsPayload = (customForm: CustomForm, data: FormTypes) => {
  const customFieldsPayload: {[key: string]: string | null | Blob} = {};

  const customFields = customForm.fields_set
    .filter(field => field.field.is_custom)
    .map(customField => {
      return {name: customField.field.name, type: customField.field.field_type};
    });
  for (const customFieldName of customFields) {
    // if (customFieldName.type === FieldTypes.file) {
    //   const base64 = await toBase64(data[customFieldName.name])
    //   const blob = dataURIToBlob(base64);
    //   customFieldsPayload[customFieldName.name] = blob;
    // } else {
    // customFieldsPayload[customFieldName.name] = data[customFieldName.name];
    customFieldsPayload[customFieldName.name] =
      data[customFieldName.name]?.value || data[customFieldName.name];
    // }
  }

  return customFieldsPayload;
};

const clearValue = <T, D>(value: T, transformedValue?: D) => {
  if (!value) {
    return;
  }

  return transformedValue || value;
};

const buildExtraFormFields = (data: FormTypes) => {
  const extraFormFields: {[key: string]: EXTRA_FORM_NAMES} = {};
  if (data[EXTRA_FORM_NAMES.sendCopyContractEmail]) {
    extraFormFields[EXTRA_FORM_NAMES.sendCopyContractEmail] =
      data[EXTRA_FORM_NAMES.sendCopyContractEmail];
  }
  return extraFormFields;
};

const getNameAndCodeOfCities = (formValue?: SelectOptionType | string | null) => {
  const name = typeof formValue === 'string' ? formValue : formValue?.label;
  const code = typeof formValue === 'string' ? undefined : formValue?.value;

  return {name, code};
};

const buildGuestPayload = ({
  data,
  signature,
  customForm,
  identityVerificationPayload,
}: BuildGuestPayloadArgs): Omit<CreateGuestPayload, 'reservation_id'> => {
  const birthDate = getPayloadDate(data[FORM_NAMES.birth_date]);
  const issueDate = getPayloadDate(data[FORM_NAMES.document_issue_date]);
  const documentExpirationDate = getPayloadDate(
    data[FORM_NAMES.document_expiration_date],
  );
  const signatureValue = getSignaturePayLoadValue(signature);
  const customFields = customForm ? buildCustomFieldsPayload(customForm, data) : {};
  const extraFormFields = buildExtraFormFields(data);

  const {name: birthPlaceCityName, code: birthPlaceCityCode} = getNameAndCodeOfCities(
    data[FORM_NAMES.birth_place_city],
  );

  const {name: residenceCityName, code: residenceCityCode} = getNameAndCodeOfCities(
    data[FORM_NAMES.residence_city],
  );

  const {name: documentExpeditionCityName, code: documentExpeditionCityCode} =
    getNameAndCodeOfCities(data[FORM_NAMES.document_expedition_city]);

  const {name: arrivalFromMunicipalityName, code: arrivalFromMunicipalityCode} =
    getNameAndCodeOfCities(data[FORM_NAMES.arrived_from_municipality]);

  const phone = getPhoneNumberString(data[FORM_NAMES.phone]);
  const secondPhone = getPhoneNumberString(data[FORM_NAMES.second_phone]);
  const email = data[FORM_NAMES.email] || data[FORM_NAMES.contract_email];

  return {
    [PAYLOAD_FIELDS_NAMES.name]: clearValue(data[FORM_NAMES.name]) as string,
    [PAYLOAD_FIELDS_NAMES.surname]: clearValue(data[FORM_NAMES.surname]) as string,
    [PAYLOAD_FIELDS_NAMES.gender]: clearValue(data[FORM_NAMES.gender]?.value),
    [PAYLOAD_FIELDS_NAMES.second_surname]: clearValue(data[FORM_NAMES.second_surname]),
    [PAYLOAD_FIELDS_NAMES.birth_date]: clearValue(
      data[FORM_NAMES.birth_date],
      birthDate,
    ) as string,
    [PAYLOAD_FIELDS_NAMES.document_expiration_date]: clearValue(
      data[FORM_NAMES.document_expiration_date],
      documentExpirationDate,
    ) as string,
    [PAYLOAD_FIELDS_NAMES.nationality]: clearValue(data[FORM_NAMES.nationality]?.value),
    [PAYLOAD_FIELDS_NAMES.document_type]: clearValue(
      data[FORM_NAMES.document_type]?.value,
    ),
    [PAYLOAD_FIELDS_NAMES.document_number]: clearValue(data[FORM_NAMES.document_number]),
    [PAYLOAD_FIELDS_NAMES.visa_number]: clearValue(data[FORM_NAMES.visa_number]),
    [PAYLOAD_FIELDS_NAMES.document_issue_date]: clearValue(
      data[FORM_NAMES.document_issue_date],
      issueDate,
    ),
    [PAYLOAD_FIELDS_NAMES.birth_place_city]: clearValue(birthPlaceCityName),
    [PAYLOAD_FIELDS_NAMES.birth_place_municipality]: clearValue(birthPlaceCityCode),
    [PAYLOAD_FIELDS_NAMES.birth_place_country]: clearValue(
      data[FORM_NAMES.birth_place_country]?.value,
    ),
    [PAYLOAD_FIELDS_NAMES.birth_place_address]: clearValue(
      data[FORM_NAMES.birth_place_address],
    ),
    [PAYLOAD_FIELDS_NAMES.residence_province]: clearValue(
      data[FORM_NAMES.residence_province]?.value,
    ),
    [PAYLOAD_FIELDS_NAMES.residence_country]: clearValue(
      data[FORM_NAMES.residence_country]?.value,
    ),
    [PAYLOAD_FIELDS_NAMES.residence_community]: clearValue(
      data[FORM_NAMES.residence_community]?.value,
    ),
    [PAYLOAD_FIELDS_NAMES.residence_city]: clearValue(residenceCityName),
    [PAYLOAD_FIELDS_NAMES.residence_municipality]: clearValue(residenceCityCode),
    [PAYLOAD_FIELDS_NAMES.document_expedition_city]: clearValue(
      documentExpeditionCityName,
    ),
    [PAYLOAD_FIELDS_NAMES.document_expedition_municipality]: clearValue(
      documentExpeditionCityCode,
    ),
    [PAYLOAD_FIELDS_NAMES.document_expedition_country]: clearValue(
      data[FORM_NAMES.document_expedition_country]?.value,
    ),
    [PAYLOAD_FIELDS_NAMES.residence_address]: clearValue(
      data[FORM_NAMES.residence_address],
    ),
    [PAYLOAD_FIELDS_NAMES.next_destination_country]: clearValue(
      data[FORM_NAMES.next_destination_country]?.value,
    ),
    [PAYLOAD_FIELDS_NAMES.next_destination_district]: clearValue(
      data[FORM_NAMES.next_destination_district]?.value,
    ),
    [PAYLOAD_FIELDS_NAMES.next_destination_municipality]: clearValue(
      data[FORM_NAMES.next_destination_municipality]?.value,
    ),
    [PAYLOAD_FIELDS_NAMES.residence_postal_code]: clearValue(
      data[FORM_NAMES.residence_postal_code],
    ),
    [PAYLOAD_FIELDS_NAMES.citizenship]: clearValue(data[FORM_NAMES.citizenship]?.value),
    [PAYLOAD_FIELDS_NAMES.fiscal_code]: clearValue(data[FORM_NAMES.fiscal_code]),
    [PAYLOAD_FIELDS_NAMES.purpose_of_stay]: clearValue(
      data[FORM_NAMES.purpose_of_stay]?.value,
    ),
    [PAYLOAD_FIELDS_NAMES.arrived_from_country]: clearValue(
      data[FORM_NAMES.arrived_from_country]?.value,
    ),
    [PAYLOAD_FIELDS_NAMES.arrived_from_district]: clearValue(
      data[FORM_NAMES.arrived_from_district]?.value,
    ),
    [PAYLOAD_FIELDS_NAMES.arrived_from_municipality]: clearValue(
      arrivalFromMunicipalityName,
      arrivalFromMunicipalityCode,
    ),
    [PAYLOAD_FIELDS_NAMES.document_support_number]: clearValue(
      data[FORM_NAMES.document_support_number],
    ),
    [PAYLOAD_FIELDS_NAMES.kinship_relationship]: clearValue(
      data[FORM_NAMES.kinship_relationship]?.value,
    ),
    [PAYLOAD_FIELDS_NAMES.phone]: clearValue(phone),
    [PAYLOAD_FIELDS_NAMES.second_phone]: clearValue(secondPhone),
    [PAYLOAD_FIELDS_NAMES.email]: clearValue(email),
    [PAYLOAD_FIELDS_NAMES.tax_exemption]: clearValue(
      data[FORM_NAMES.tax_exemption]?.value,
    ),
    [PAYLOAD_FIELDS_NAMES.full_tourist_tax]: clearValue(
      data[FORM_NAMES.full_tourist_tax],
    ),
    [PAYLOAD_FIELDS_NAMES.next_destination_address]: clearValue(
      data[FORM_NAMES.next_destination_address],
    ),
    [PAYLOAD_FIELDS_NAMES.signature]: clearValue(signatureValue),
    [PAYLOAD_FIELDS_NAMES.external_category_id]: clearValue(
      data[FORM_NAMES.external_category_id]?.value,
    ),
    [PAYLOAD_FIELDS_NAMES.is_leader]: data?.is_leader,
    ...identityVerificationPayload,
    ...customFields,
    ...extraFormFields,
  };
};

type GuestFormFieldsProps = {
  housingCountry: string;
  id?: string;
};

function useGuestFormFields({id: guestId, housingCountry}: GuestFormFieldsProps) {
  const isMounted = useIsMounted();
  const isEdition = Boolean(guestId);
  const {id} = useParams<{id: string}>();
  const {guest} = useGuest({id});
  const {
    register,
    formState,
    control,
    watch,
    handleSubmit,
    getValues,
    setError,
    setFocus,
    trigger,
    reset,
  } = useFormContext<FormTypes>();

  const canRevalidateForm =
    formState.isSubmitted && !formState.isSubmitSuccessful && !formState.isSubmitting;
  const defaultSecondPhoneDetails = getValues()?.[EXTRA_FORM_NAMES.secondPhoneDetails];

  const {
    getSpanishResidenceCitiesList,
    getCountriesList,
    getDistrictsList,
    getMunicipalitiesList,
    getCitiesList,
    getProvincesList,
  } = useChekinSDK();
  const {setIsFormTouched} = useOpenModals();
  const {ErrorModal, displayError, closeErrorModal} = useErrorModal();
  const {data: reservation, reservationId, housing} = useReservation();
  const {data: summary} = useSummary();

  const policeType = housing?.police_account_type;
  const isPoliceActivated = housing?.is_auto_police_registration_enabled;

  useEffect(() => {
    const isThereDirtyField = Boolean(Object.keys(formState.dirtyFields || {})?.length);
    setIsFormTouched(isThereDirtyField);
  }, [setIsFormTouched, formState]);

  const fullNameCurrentGuestRef = useRef('');
  const [isSignModalOpen, setIsSignModalOpen] = useState(false);
  const [isInvalidFileType, setIsInvalidFileType] = useState(false);
  const [guestRegistrationStatus, setGuestRegistrationStatus] = useState(
    StatusModalStates.IDLE,
  );
  const {resetAllIVState} = useActions();
  const {t} = useTranslation();

  const nationality = watch(FORM_NAMES.nationality)?.value;
  const documentType = watch(FORM_NAMES.document_type)?.value;
  const birthPlaceCountry = watch(FORM_NAMES.birth_place_country)?.value;
  const arrivedFromCountry = watch(FORM_NAMES.arrived_from_country)?.value;
  const nextDestinationCountry = watch(FORM_NAMES.next_destination_country)?.value;
  const documentExpeditionCountry = watch(FORM_NAMES.document_expedition_country)?.value;
  const arrivedFromDistrict = watch(FORM_NAMES.arrived_from_district)?.value;
  const nextDestinationDistrict = watch(FORM_NAMES.next_destination_district)?.value;
  const residenceCountry = watch(FORM_NAMES.residence_country)?.value;
  const citizenship = watch(FORM_NAMES.citizenship)?.value;
  const birthDate = watch(FORM_NAMES.birth_date);

  const {
    formFields,
    setBirthCountry,
    setArrivalCountry,
    setArrivalDistrict,
    setNextDestinationCountry,
    setNationality,
    setDocumentExpeditionCountry,
    setResidenceCountry,
    setCitizenship,
    setDocumentType,
    setBirthDate,
    setHasPhone,
    setShowInvoiceField,
    isGuestSchemaLoading,
    isGuestSchemaLoaded,
    isGuestSchemaFetching,
    customForm,
  } = useSchema();

  const {clearPersistedStorageFormData} = usePreloadGuestData();
  const {preloadPoliceData} = usePreloadPoliceData({
    getValues,
    reset,
    disabled: isEdition,
  });
  const {isLeaderGuest, guestGroup} = useGuestGroup();

  useLayoutEffect(() => {
    preloadPoliceData();
  }, [preloadPoliceData]);

  const isLoadingForm = isGuestSchemaLoading && !isGuestSchemaLoaded;

  const isDisabledField = isGuestSchemaLoading || isGuestSchemaFetching;

  const closeGuestRegistrationStatusModal = useCallback(() => {
    setGuestRegistrationStatus(StatusModalStates.IDLE);
  }, []);

  const {
    isOpen: isDataIncompleteModalOpen,
    openModal: openDataIncompleteModalOpen,
    closeModal: closeDataIncompleteModalOpen,
  } = useModalControls();

  useEffect(() => {
    setShowInvoiceField?.(getIsShowInvoiceFields());
  }, [setShowInvoiceField]);

  useEffect(() => {
    setNationality?.(nationality);
  }, [nationality, setNationality]);
  useEffect(() => {
    setDocumentType?.(documentType);
  }, [documentType, setDocumentType]);
  useEffect(() => {
    setArrivalCountry?.(arrivedFromCountry);
  }, [arrivedFromCountry, setArrivalCountry]);
  useEffect(() => {
    setArrivalDistrict?.(arrivedFromDistrict);
  }, [arrivedFromDistrict, setArrivalDistrict]);
  useEffect(() => {
    setBirthCountry?.(birthPlaceCountry);
  }, [birthPlaceCountry, setBirthCountry]);
  useEffect(() => {
    setDocumentExpeditionCountry?.(documentExpeditionCountry);
  }, [documentExpeditionCountry, setDocumentExpeditionCountry]);
  useEffect(() => {
    setNextDestinationCountry?.(nextDestinationCountry);
  }, [nextDestinationCountry, setNextDestinationCountry]);
  useEffect(() => {
    setResidenceCountry?.(residenceCountry);
  }, [residenceCountry, setResidenceCountry]);
  useEffect(() => {
    setCitizenship?.(citizenship);
  }, [citizenship, setCitizenship]);
  useEffect(() => {
    const dateString = getPayloadDate(birthDate);
    setBirthDate?.(dateString);
  }, [birthDate, setBirthDate]);

  useEffect(
    function revalidateFormOnDisplayFieldsChange() {
      if (canRevalidateForm) {
        void trigger();
      }
    },
    [trigger, formFields, canRevalidateForm],
  );

  useResetFieldIfTypeChanged<FormTypes>({
    fieldName: FORM_NAMES.birth_place_city,
    type: formFields?.[FORM_NAMES.birth_place_city]?.type,
  });

  useResetFieldIfTypeChanged<FormTypes>({
    fieldName: FORM_NAMES.arrived_from_municipality,
    type: formFields?.[FORM_NAMES.arrived_from_municipality]?.type,
  });

  useResetCityIfCountryChanged({
    cityFieldName: FORM_NAMES.birth_place_city,
    countryFieldName: FORM_NAMES.birth_place_country,
  });

  useResetFieldIfTypeChanged<FormTypes>({
    fieldName: FORM_NAMES.residence_city,
    type: formFields?.[FORM_NAMES.residence_city]?.type,
  });
  useResetCityIfCountryChanged({
    cityFieldName: FORM_NAMES.residence_city,
    countryFieldName: FORM_NAMES.residence_country,
  });

  useResetFieldIfTypeChanged({
    fieldName: FORM_NAMES.document_expedition_city,
    type: formFields?.[FORM_NAMES.document_expedition_city]?.type,
  });
  useResetCityIfCountryChanged({
    cityFieldName: FORM_NAMES.document_expedition_city,
    countryFieldName: FORM_NAMES.document_expedition_country,
  });

  const {
    redirect: redirectToAllianz,
    checkAllianzFlow,
    LoadingModal: AllianzLoadingModal,
    openAllianzModalWithTimeout,
  } = useAllianzPlans();
  const {checkUpsellingFlow, isUpsellingFlowWorking} = useUpsellingFlow(
    housing?.id,
    summary,
  );

  const isRegisteredModalOpen =
    guestRegistrationStatus !== StatusModalStates.IDLE && !isUpsellingFlowWorking;
  const closeRegisteredModal = useCallback(() => {
    resetAllIVState();
    closeGuestRegistrationStatusModal();

    if (redirectToAllianz) {
      openAllianzModalWithTimeout();
    }
  }, [
    closeGuestRegistrationStatusModal,
    redirectToAllianz,
    openAllianzModalWithTimeout,
    resetAllIVState,
  ]);

  const {getIsAllianzUser} = useAllianzUsers();

  const isAllianzUser = guestId ? getIsAllianzUser(guestId) : false; //IMPROVE

  const {GuestErrorModal, displayFieldErrors} = useErrorHandler({
    setError,
    fields: formFields,
    onDataIncomplete: openDataIncompleteModalOpen,
  });

  const handleError = (error: FieldsErrorResponse) => {
    if (!isMounted.current) {
      return;
    }

    if (error.status_code !== 400) {
      closeGuestRegistrationStatusModal();
      displayError(error);
      return;
    }

    if ((error as FieldsErrorResponse).errors) {
      displayFieldErrors(error as FieldsErrorResponse);
    } else {
      displayFieldErrors(error);
    }
    closeGuestRegistrationStatusModal();
  };

  const guestMutation = useGuestMutation({
    onSuccess: (data: Guest) => {
      const checkAllianzParams = {
        // @ts-ignore
        residence_country: data?.residence_country?.code ?? '',
        //nationality: data?.nationality?.code ?? '',
        guestId: data?.id ?? '',
        chekinDate: reservation?.check_in_date,
        allianzStatus: housing?.allianz_status,
        housingCountry: summary?.country_code,
        nights: summary?.nights_of_stay,
        hasAllianzActive: guest?.has_allianz_insurance,
        isAllianzUser,
      };
      checkUpsellingFlow(data);
      checkAllianzFlow(checkAllianzParams);
      setGuestRegistrationStatus(StatusModalStates.SUCCESS);
      ChekinPro.events.onGuestRegistered(data);
      clearPersistedStorageFormData();
    },
    onError: handleError,
  });

  const {getIVPayload} = useBuildIVPayload();

  const buildDataToPatchGuest = useCallback(
    (formData: FormTypes) => {
      const dirtyFieldsNames = Object.keys(formState.dirtyFields);
      const dirtyFields = {} as FormTypes;

      dirtyFieldsNames.forEach(fieldName => {
        dirtyFields[fieldName] = formData[fieldName];
      });
      if (formData[FORM_NAMES.residence_country]) {
        dirtyFields[FORM_NAMES.residence_country] =
          formData[FORM_NAMES.residence_country];
      }
      return dirtyFields;
    },
    [formState.dirtyFields],
  );

  const {firstGuestMustBeAdult: shouldFirstGuestBeAdult} = useFirstGuestMustBeAdult();

  const onSubmit = useCallback(
    async (formData: FormTypes, signature: string, guestId?: string) => {
      closeErrorModal();

      fullNameCurrentGuestRef.current = `${formData[FORM_NAMES.name]} ${
        formData[FORM_NAMES.surname]
      }`;
      setGuestRegistrationStatus(StatusModalStates.LOADING);

      const dataToSave = guestId ? buildDataToPatchGuest(formData) : formData;
      const identityVerificationPayload = getIVPayload();
      const isLeader = LocalStorage.get(IS_LEADER_GUEST);

      const payload = buildGuestPayload({
        signature,
        data: {...dataToSave, is_leader: isLeader},
        reservationId: reservationId,
        identityVerificationPayload,
        customForm: customForm as CustomForm,
      });
      if (guestMutation && reservationId) {
        guestMutation.mutate({
          id: guestId,
          payload,
        });
      }

      LocalStorage.remove(SHOWED_SUCCESS_REGISTER_COMPLETE_MODAL);
    },
    [
      closeErrorModal,
      buildDataToPatchGuest,
      getIVPayload,
      reservationId,
      customForm,
      guestMutation,
    ],
  );

  const handleSubmitForm = useCallback(
    (signature: string) => {
      handleSubmit(
        (data: FormTypes) => onSubmit(data, signature, guestId),
        openDataIncompleteModalOpen,
      )();
    },
    [onSubmit, handleSubmit, guestId, openDataIncompleteModalOpen],
  );

  const getRequiredOrOptionalFieldLabel = useCallback(
    (label: string, required: string | boolean | undefined) => {
      if (required) {
        return label;
      }
      return `${label} (${t('optional')})`;
    },
    [t],
  );

  const errors = useMemo(() => {
    return formState.errors;
  }, [formState]);

  const handleFileChange = useCallback(
    (onChange: (value: File | string) => void) =>
      (event: ChangeEvent<HTMLInputElement> | string) => {
        if (!event || typeof event === 'string') {
          return onChange(event);
        }

        const {target} = event;
        if (target?.files?.length) {
          if (!ValidFileTypes.includes(target?.files[0].type)) {
            setIsInvalidFileType(true);
            return;
          }
          setIsInvalidFileType(false);
          return onChange(target.files[0]);
        }
      },
    [],
  );

  const getCustomField = useCallback(
    (field: CustomField, required: boolean | string) => {
      const {name, placeholder, label} = getCustomFieldData(field);

      switch (field.field_type) {
        case FIELD_TYPES.date:
          return (
            <FormFieldWrapper>
              <Controller
                name={name}
                rules={{required}}
                control={control}
                render={({field: {onChange}, fieldState: {error}}) => (
                  <Datepicker
                    label={getRequiredOrOptionalFieldLabel(label, Boolean(required))}
                    error={error?.message}
                    onChange={value => {
                      return onChange(dayjs(value?.toString()).format(DATE_FORMAT));
                    }}
                    disabled={isDisabledField}
                    {...field}
                  />
                )}
              />
            </FormFieldWrapper>
          );
        case FIELD_TYPES.time:
          return (
            <FormFieldWrapper>
              <Controller
                name={name}
                rules={{required}}
                control={control}
                render={({field, fieldState: {error}}) => (
                  <TimePicker
                    label={getRequiredOrOptionalFieldLabel(label, Boolean(required))}
                    error={error?.message}
                    disabled={isDisabledField}
                    placeholder={label}
                    {...field}
                  />
                )}
              />
            </FormFieldWrapper>
          );
        case FIELD_TYPES.file:
          return (
            <FormFieldWrapper>
              <Controller
                name={name}
                rules={{
                  required,
                }}
                control={control}
                render={({field: {onChange, ...restField}, fieldState: {error}}) => (
                  <FileInput
                    placeholder={placeholder}
                    onChange={handleFileChange(onChange)}
                    label={getRequiredOrOptionalFieldLabel(label, Boolean(required))}
                    error={error?.message}
                    accept={AcceptableDocuments}
                    invalid={isInvalidFileType}
                    disabled={isDisabledField}
                    {...restField}
                  />
                )}
              />
              {isInvalidFileType && (
                <InvalidFileTypeError>{t('only_doc_file_types')}</InvalidFileTypeError>
              )}
            </FormFieldWrapper>
          );
        case FIELD_TYPES.text:
          return (
            <FormFieldWrapper>
              <Input
                id={name}
                label={getRequiredOrOptionalFieldLabel(label, Boolean(required))}
                {...register(name, {
                  required,
                })}
                control={control}
                error={errors[name]?.message as string}
                disabled={isDisabledField}
              />
            </FormFieldWrapper>
          );
      }
    },
    [
      getRequiredOrOptionalFieldLabel,
      register,
      control,
      errors,
      handleFileChange,
      isInvalidFileType,
      isDisabledField,
      t,
    ],
  );

  const names = useMemo(() => {
    const names: FORM_NAMES[] = [];
    if (Object.values(formFields).length > 0) {
      Object.entries(formFields).forEach(([name, value]) => {
        if (
          value.active &&
          !IV_FIELDS.includes(name) &&
          !SIGNATURE_FIELDS.includes(name as FORM_NAMES)
        ) {
          names.push(name as FORM_NAMES);
        }
      });
      return sortFieldsByOrder(names, formFields);
    } else {
      return [];
    }
  }, [formFields]);

  const getFormFieldDestinations = useMemo(() => {
    return names.map((formName, index) => {
      const destination = index < names.length - 1 ? names[index + 1] : '';
      return {
        state: formName,
        destination,
      };
    });
  }, [names]);

  const getNextField = useCallback(
    (nextFieldName: FORM_NAMES) => {
      return getFormFieldDestinations.find(item => item.state === nextFieldName);
    },
    [getFormFieldDestinations],
  );

  const handleKeyDown = useCallback(
    (nextFieldName: FORM_NAMES) => (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        const next = getNextField(nextFieldName);
        next && setFocus(next?.destination);
      }
    },
    [setFocus, getNextField],
  );

  type FormFieldProps = {
    format: RegExp;
    required?: boolean | string;
    options?: SelectOptionType[];
  };
  const defaultFormFields = useMemo<{
    [key in FORM_NAMES]?: (props: FormFieldProps) => false | ReactElement | undefined;
  }>(
    () => ({
      [FORM_NAMES.name]: ({required, format}) => (
        <FormFieldWrapper>
          <Input
            label={getRequiredOrOptionalFieldLabel(t('name'), required)}
            {...register(FORM_NAMES.name, {
              required,
              pattern: {
                value: format,
                message: t('cant_contain_number_and_symbols'),
              },
              maxLength: {
                value: maxNamesInputLength,
                message: t('max_length', {length: maxNamesInputLength}),
              },
            })}
            control={control}
            error={errors[FORM_NAMES.name]?.message}
            disabled={isDisabledField}
            autoCorrect="off"
            spellCheck={false}
            onKeyDown={handleKeyDown(FORM_NAMES.name)}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.surname]: ({required, format}) => (
        <FormFieldWrapper>
          <Input
            label={getRequiredOrOptionalFieldLabel(t('surname'), required)}
            {...register(FORM_NAMES.surname, {
              required,
              pattern: {
                value: format,
                message: t('cant_contain_number_and_symbols'),
              },
              maxLength: {
                value: maxNamesInputLength,
                message: t('max_length', {length: maxNamesInputLength}),
              },
            })}
            control={control}
            error={errors[FORM_NAMES.surname]?.message}
            disabled={isDisabledField}
            autoCorrect="off"
            spellCheck={false}
            onKeyDown={handleKeyDown(FORM_NAMES.surname)}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.second_surname]: ({required, format}) => (
        <FormFieldWrapper>
          <Input
            label={getRequiredOrOptionalFieldLabel(t('second_surname'), required)}
            {...register(FORM_NAMES.second_surname, {
              required,
              pattern: {
                value: format,
                message: t('cant_contain_number_and_symbols'),
              },
              maxLength: {
                value: maxNamesInputLength,
                message: t('max_length', {length: maxNamesInputLength}),
              },
            })}
            control={control}
            error={errors[FORM_NAMES.second_surname]?.message}
            disabled={isDisabledField}
            autoCorrect="off"
            spellCheck={false}
            onKeyDown={handleKeyDown(FORM_NAMES.second_surname)}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.nationality]: ({required}) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.nationality}
            rules={{required}}
            control={control}
            render={({field, fieldState: {error}}) => (
              <AdaptiveQueriedInfiniteScrollSelect
                label={getRequiredOrOptionalFieldLabel(t('nationality'), required)}
                placeholder={t('nationality')}
                queryKey={QUERY_CACHE_KEYS.paginatedCountriesList}
                fetcher={(key: string, page: number, searchQuery: string) =>
                  getCountriesList({key, page, searchQuery})
                }
                blockQuery={isDisabledField}
                disabled={isDisabledField}
                onKeyDown={handleKeyDown(FORM_NAMES.nationality)}
                error={error?.message}
                openMenuOnFocus={false}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.birth_place_country]: ({required}) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.birth_place_country}
            rules={{required}}
            control={control}
            render={({field, fieldState: {error}}) => (
              <AdaptiveQueriedInfiniteScrollSelect
                label={getRequiredOrOptionalFieldLabel(
                  t('birth_place_country'),
                  required,
                )}
                placeholder={t('birth_place_country')}
                queryKey={QUERY_CACHE_KEYS.paginatedCountriesList}
                fetcher={(key: string, page: number, searchQuery: string) =>
                  getCountriesList({key, page, searchQuery})
                }
                blockQuery={isDisabledField}
                disabled={isDisabledField}
                error={error?.message}
                onKeyDown={handleKeyDown(FORM_NAMES.birth_place_country)}
                openMenuOnFocus={false}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.birth_place_city]: ({required, format}) => {
        switch (formFields?.[FORM_NAMES.birth_place_city]?.type) {
          case STANDARD_FIELD_TYPES.select:
            return (
              <FormFieldWrapper>
                <Controller
                  name={FORM_NAMES.birth_place_city}
                  rules={{required}}
                  control={control}
                  render={({field: {value, ...restField}, fieldState: {error}}) => (
                    <AdaptiveQueriedInfiniteScrollSelect
                      label={getRequiredOrOptionalFieldLabel(
                        t('city_of_birth'),
                        required,
                      )}
                      placeholder={t('city_of_birth')}
                      queryKey={[QUERY_CACHE_KEYS.paginatedCitiesList]}
                      fetcher={(key: string, page: number, searchQuery: string) =>
                        getCitiesList({
                          key,
                          page,
                          searchQuery,
                          countryCode: housingCountry,
                        })
                      }
                      blockQuery={isDisabledField}
                      disabled={isDisabledField}
                      value={value as SelectOptionType}
                      error={error?.message}
                      openMenuOnFocus={false}
                      {...restField}
                    />
                  )}
                />
              </FormFieldWrapper>
            );
          default:
            return (
              <FormFieldWrapper>
                <Input
                  label={getRequiredOrOptionalFieldLabel(t('city_of_birth'), required)}
                  {...register(FORM_NAMES.birth_place_city, {
                    required,
                    pattern: {
                      value: format,
                      message: t('cant_contain_number_and_symbols'),
                    },
                    maxLength: {
                      value: maxNamesInputLength,
                      message: t('max_length', {length: maxNamesInputLength}),
                    },
                  })}
                  control={control}
                  error={errors[FORM_NAMES.birth_place_city]?.message}
                  disabled={isDisabledField}
                  autoCorrect="off"
                  spellCheck={false}
                  onKeyDown={handleKeyDown(FORM_NAMES.birth_place_city)}
                />
              </FormFieldWrapper>
            );
        }
      },
      [FORM_NAMES.birth_place_address]: ({required, format}) => (
        <FormFieldWrapper>
          <Input
            label={getRequiredOrOptionalFieldLabel(t('birth_place_address'), required)}
            {...register(FORM_NAMES.birth_place_address, {
              required,
              pattern: {
                value: format,
                message: t('cant_contain_number_and_symbols'),
              },
              maxLength: {
                value: maxBirthPlaceAddressLength,
                message: t('max_length', {length: maxBirthPlaceAddressLength}),
              },
            })}
            control={control}
            error={errors[FORM_NAMES.birth_place_address]?.message}
            disabled={isDisabledField}
            autoCorrect="off"
            spellCheck={false}
            onKeyDown={handleKeyDown(FORM_NAMES.birth_place_address)}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.tax_exemption]: ({
        required,
        options = formFields?.[FORM_NAMES.tax_exemption]?.options,
      }) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.tax_exemption}
            rules={{
              required,
            }}
            control={control}
            render={({field, fieldState: {error}}) => (
              <Select
                placeholder={t('tax_exemption')}
                label={getRequiredOrOptionalFieldLabel(t('tax_exemption'), required)}
                options={options}
                error={error?.message}
                disabled={isDisabledField}
                loading={isDisabledField}
                onKeyDown={handleKeyDown(FORM_NAMES.tax_exemption)}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.email]: ({required, format}) => (
        <FormFieldWrapper>
          <GuestEmailField
            name={FORM_NAMES.email}
            required={required}
            format={format}
            label={getRequiredOrOptionalFieldLabel(t('email'), required)}
            onKeyDown={handleKeyDown(FORM_NAMES.email)}
            disabled={isDisabledField}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.phone]: ({required}) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.phone}
            rules={{
              required,
              validate: {
                length: validateResidencePhoneNumber(t, policeType),
                full: Validators.phoneValidateService,
              },
            }}
            control={control}
            render={({field: {onBlur, onChange, ...restField}, fieldState: {error}}) => (
              <PhoneInput
                label={getRequiredOrOptionalFieldLabel(
                  getPhoneFieldLabel(t, policeType),
                  required,
                )}
                error={error?.message}
                onBlur={() => {
                  const phoneValue = getValues()[FORM_NAMES.phone];
                  const hasNumber = !!getPhoneNumberString(phoneValue);
                  setHasPhone?.(hasNumber);
                  onBlur();
                }}
                onChange={(_, __, details) => onChange(details)}
                disabled={isDisabledField}
                onKeyDown={handleKeyDown(FORM_NAMES.phone)}
                {...restField}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.second_phone]: ({required, format}) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.second_phone}
            rules={{
              required,
              pattern: {
                value: format,
                message: t('invalid_phone_number'),
              },
              maxLength: {
                value: 16,
                message: t('invalid_phone_number'),
              },
              minLength: {
                value: 12,
                message: t('invalid_phone_number'),
              },
            }}
            control={control}
            render={({field: {onBlur, ...restField}, fieldState: {error}}) => (
              <PhoneInput
                defaultCode="+971"
                defaultInputValue={defaultSecondPhoneDetails?.number}
                label={getRequiredOrOptionalFieldLabel(t('uae_phone_number'), required)}
                error={error?.message}
                disabled={isDisabledField}
                tooltipContent={t('uae_phone_number_tooltip')}
                codeReadOnly
                {...restField}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.purpose_of_stay]: ({
        required,
        options = formFields?.[FORM_NAMES.purpose_of_stay]?.options,
      }) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.purpose_of_stay}
            rules={{
              required,
            }}
            control={control}
            render={({field, fieldState: {error}}) => (
              <Select
                placeholder={t('purpose_of_stay')}
                label={getRequiredOrOptionalFieldLabel(t('purpose_of_stay'), required)}
                options={options}
                loading={isGuestSchemaLoading}
                error={error?.message}
                disabled={isDisabledField}
                onKeyDown={handleKeyDown(FORM_NAMES.purpose_of_stay)}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.document_type]: ({
        required,
        options = formFields?.[FORM_NAMES.document_type]?.options,
      }) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.document_type}
            rules={{
              required,
            }}
            control={control}
            render={({field, fieldState: {error}}) => (
              <Select
                placeholder={t('document_type')}
                options={options}
                label={getRequiredOrOptionalFieldLabel(t('document_type'), required)}
                error={error?.message}
                disabled={isDisabledField}
                onKeyDown={handleKeyDown(FORM_NAMES.document_type)}
                noOptionsMessage={getNoOptionsMessageForDocTypeField(getValues, t)}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.document_number]: ({required}) => (
        <FormFieldWrapper>
          <Input
            label={getRequiredOrOptionalFieldLabel(t('document_number'), required)}
            {...register(FORM_NAMES.document_number, {
              required,
              pattern: {
                value: getDocNumberPattern(housingCountry, isPoliceActivated),
                message: t('only_basic_letters_are_allowed'),
              },
              maxLength: {
                value: maxLength,
                message: t('max_length', {length: maxLength}),
              },
              minLength: {
                value: minLength,
                message: t('min_length', {length: minLength}),
              },
              validate: {
                byDocumentType: value =>
                  validateDocNumberByDocumentType(value, documentType),
                uniqueness: value => validateDocNumberUniqueness(value, guestGroup),
              },
            })}
            control={control}
            error={errors[FORM_NAMES.document_number]?.message}
            disabled={isDisabledField}
            autoCorrect="off"
            spellCheck={false}
            onKeyDown={handleKeyDown(FORM_NAMES.document_number)}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.document_issue_date]: ({required}) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.document_issue_date}
            rules={{
              required,
              validate: value => {
                if (!value) return true;

                const birthDate = getValues()[FORM_NAMES.birth_date];
                const expirationDate = getValues()[FORM_NAMES.document_expiration_date];
                return validateIssueDate(value, birthDate, expirationDate);
              },
            }}
            control={control}
            render={({field, fieldState: {error}}) => (
              <Datepicker
                withTooltip
                tooltipContent={
                  <Trans i18nKey="date_when_the_doc_created">
                    Date when the document was created. (It is <b>not</b> the expiration
                    date)
                  </Trans>
                }
                error={error?.message}
                disabled={isDisabledField}
                label={getRequiredOrOptionalFieldLabel(t('date_of_issue'), required)}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.document_expiration_date]: ({required}) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.document_expiration_date}
            rules={{
              required,
              validate: value => {
                if (!value) return true;

                const birthDate = getValues()[FORM_NAMES.birth_date];
                const issueDate = getValues()[FORM_NAMES.document_issue_date];
                return validateExpirationDate({
                  expirationDate: value,
                  birthDate,
                  issueDate,
                  checkInDate: reservation?.check_in_date,
                });
              },
            }}
            control={control}
            render={({field, fieldState: {error}}) => (
              <Datepicker
                error={error?.message}
                disabled={isDisabledField}
                label={getRequiredOrOptionalFieldLabel(
                  t('document_expiration_date'),
                  required,
                )}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.birth_date]: ({required}) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.birth_date}
            rules={{
              required,
              validate: {
                validateGuestMustBeAdult: value => {
                  if (!shouldFirstGuestBeAdult) {
                    return true;
                  }
                  return validateGuestMustBeAdult(value);
                },
                common: value => {
                  if (!value) {
                    return true;
                  }
                  const docDateOfIssue = getValues()[FORM_NAMES.document_issue_date];
                  return validateBirthDate(value, docDateOfIssue);
                },
              },
            }}
            control={control}
            render={({field, fieldState: {error}}) => {
              return (
                <Datepicker
                  error={error?.message}
                  label={getRequiredOrOptionalFieldLabel(t('birth_date'), required)}
                  disabled={isDisabledField}
                  onHandleKeyDown={handleKeyDown(FORM_NAMES.birth_date)}
                  {...field}
                />
              );
            }}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.gender]: ({
        required,
        options = formFields?.[FORM_NAMES.gender]?.options,
      }) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.gender}
            rules={{required}}
            control={control}
            render={({field, fieldState: {error}}) => (
              <Select
                placeholder={t('sex')}
                label={getRequiredOrOptionalFieldLabel(t('sex'), required)}
                options={filterGenderOptions(options)}
                error={error?.message}
                disabled={isDisabledField}
                onKeyDown={handleKeyDown(FORM_NAMES.gender)}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.citizenship]: ({required}) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.citizenship}
            rules={{required}}
            control={control}
            render={({field, fieldState: {error}}) => (
              <AdaptiveQueriedInfiniteScrollSelect
                label={getRequiredOrOptionalFieldLabel(t('citizenship'), required)}
                placeholder={t('citizenship')}
                queryKey={QUERY_CACHE_KEYS.paginatedCountriesList}
                fetcher={(key: string, page: number, searchQuery: string) =>
                  getCountriesList({key, page, searchQuery})
                }
                blockQuery={isDisabledField}
                disabled={isDisabledField}
                error={error?.message}
                openMenuOnFocus={false}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.document_expedition_country]: ({required}) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.document_expedition_country}
            rules={{required}}
            control={control}
            render={({field, fieldState: {error}}) => (
              <AdaptiveQueriedInfiniteScrollSelect
                label={getRequiredOrOptionalFieldLabel(t('country_of_issue'), required)}
                placeholder={t('country_of_issue')}
                queryKey={QUERY_CACHE_KEYS.paginatedCountriesList}
                fetcher={(key: string, page: number, searchQuery: string) =>
                  getCountriesList({key, page, searchQuery})
                }
                blockQuery={isDisabledField}
                disabled={isDisabledField}
                error={error?.message}
                openMenuOnFocus={false}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.document_expedition_city]: ({required, format}) => {
        switch (formFields?.[FORM_NAMES.document_expedition_city]?.type) {
          case STANDARD_FIELD_TYPES.select:
            return (
              <FormFieldWrapper>
                <Controller
                  name={FORM_NAMES.document_expedition_city}
                  rules={{required}}
                  control={control}
                  render={({field: {value, ...restField}, fieldState: {error}}) => (
                    <AdaptiveQueriedInfiniteScrollSelect
                      label={getRequiredOrOptionalFieldLabel(
                        t('city_of_issue'),
                        required,
                      )}
                      placeholder={t('city_of_issue')}
                      queryKey={QUERY_CACHE_KEYS.paginatedCitiesList}
                      fetcher={(key: string, page: number, searchQuery: string) =>
                        getCitiesList({
                          key,
                          page,
                          searchQuery,
                          countryCode: housingCountry,
                        })
                      }
                      blockQuery={isDisabledField}
                      disabled={isDisabledField}
                      value={value as SelectOptionType}
                      error={error?.message}
                      openMenuOnFocus={false}
                      {...restField}
                    />
                  )}
                />
              </FormFieldWrapper>
            );
          default:
            return (
              <FormFieldWrapper>
                <Input
                  label={getRequiredOrOptionalFieldLabel(t('city_of_issue'), required)}
                  {...register(FORM_NAMES.document_expedition_city, {
                    required,
                    pattern: {
                      value: format,
                      message: t('cant_contain_number_and_symbols'),
                    },
                    maxLength: {
                      value: maxNamesInputLength,
                      message: t('max_length', {length: maxNamesInputLength}),
                    },
                  })}
                  control={control}
                  disabled={isDisabledField}
                  autoCorrect="off"
                  spellCheck={false}
                />
              </FormFieldWrapper>
            );
        }
      },
      [FORM_NAMES.full_tourist_tax]: ({required}) => (
        <FormFieldWrapper>
          <Input
            {...register(FORM_NAMES.full_tourist_tax, {
              required,
            })}
            control={control}
            label={getRequiredOrOptionalFieldLabel(t('full_tourist_tax'), required)}
            inputMode="numeric"
            error={errors[FORM_NAMES.full_tourist_tax]?.message}
            disabled={isDisabledField}
            autoCorrect="off"
            spellCheck={false}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.residence_country]: ({required}) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.residence_country}
            rules={{
              required,
            }}
            control={control}
            render={({field, fieldState: {error}}) => (
              <AdaptiveQueriedInfiniteScrollSelect
                label={getRequiredOrOptionalFieldLabel(t('residence_country'), required)}
                placeholder={t('residence_country')}
                queryKey={QUERY_CACHE_KEYS.paginatedCountriesList}
                fetcher={(key: string, page: number, searchQuery: string) =>
                  getCountriesList({key, page, searchQuery})
                }
                blockQuery={isDisabledField}
                disabled={isDisabledField || guest?.has_allianz_insurance}
                error={error?.message}
                openMenuOnFocus={false}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.residence_community]: ({
        required,
        options = formFields?.[FORM_NAMES.residence_community]?.options,
      }) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.residence_community}
            rules={{required}}
            control={control}
            render={({field, fieldState: {error}}) => (
              <Select
                label={getRequiredOrOptionalFieldLabel(t('residence_state'), required)}
                placeholder={t('residence_state')}
                options={options}
                disabled={isDisabledField}
                error={error?.message}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.residence_province]: ({required}) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.residence_province}
            rules={{
              required,
            }}
            control={control}
            render={({field, fieldState: {error}}) => (
              <AdaptiveQueriedInfiniteScrollSelect
                label={getRequiredOrOptionalFieldLabel(t('residence_province'), required)}
                placeholder={t('select')}
                queryKey={[QUERY_CACHE_KEYS.paginatedProvincesList, housingCountry]}
                fetcher={(key: string, page: number, searchQuery: string) =>
                  getProvincesList({key, page, searchQuery, countryCode: housingCountry})
                }
                blockQuery={isDisabledField}
                disabled={isDisabledField}
                error={error?.message}
                openMenuOnFocus={false}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.residence_city]: ({required, format}) => {
        const isSelectType =
          formFields?.[FORM_NAMES.residence_city]?.type === STANDARD_FIELD_TYPES.select;

        if (isSelectType) {
          const isSpain = residenceCountry === COUNTRY_CODES.spain;
          const label = isSpain ? t('city') : t('residence_city');
          const queryKey = isSpain
            ? [QUERY_CACHE_KEYS.paginatedSpanishResidenceCities]
            : [QUERY_CACHE_KEYS.paginatedCitiesList, residenceCountry];

          return (
            <FormFieldWrapper>
              <Controller
                name={FORM_NAMES.residence_city}
                rules={{required}}
                control={control}
                render={({field: {value, ...field}, fieldState: {error}}) => (
                  <AdaptiveQueriedInfiniteScrollSelect
                    label={getRequiredOrOptionalFieldLabel(label, required)}
                    placeholder={label}
                    queryKey={queryKey}
                    fetcher={(key: string, page: number, searchQuery: string) =>
                      isSpain
                        ? getSpanishResidenceCitiesList({key, page, searchQuery})
                        : getCitiesList({
                            key,
                            page,
                            searchQuery,
                            countryCode: residenceCountry || '',
                          })
                    }
                    blockQuery={isDisabledField}
                    disabled={isDisabledField}
                    error={error?.message}
                    openMenuOnFocus={false}
                    value={value as SelectOptionType}
                    {...field}
                  />
                )}
              />
            </FormFieldWrapper>
          );
        }

        return (
          <FormFieldWrapper>
            <Input
              label={getRequiredOrOptionalFieldLabel(t('residence_city'), required)}
              {...register(FORM_NAMES.residence_city, {
                required,
                pattern: {
                  value: format,
                  message: t('cant_contain_number_and_symbols'),
                },
                maxLength: {
                  value: maxNamesInputLength,
                  message: t('max_length', {length: maxNamesInputLength}),
                },
              })}
              control={control}
              error={errors[FORM_NAMES.residence_city]?.message}
              disabled={isDisabledField}
              autoCorrect="off"
              spellCheck={false}
            />
          </FormFieldWrapper>
        );
      },
      [FORM_NAMES.arrived_from_country]: ({required}) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.arrived_from_country}
            rules={{required}}
            control={control}
            render={({field, fieldState: {error}}) => (
              <AdaptiveQueriedInfiniteScrollSelect
                label={getRequiredOrOptionalFieldLabel(
                  t('arrived_from_country'),
                  required,
                )}
                placeholder={t('arrived_from_country')}
                queryKey={QUERY_CACHE_KEYS.paginatedCountriesList}
                fetcher={(key: string, page: number, searchQuery: string) =>
                  getCountriesList({key, page, searchQuery})
                }
                blockQuery={isDisabledField}
                disabled={isDisabledField}
                error={error?.message}
                openMenuOnFocus={false}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.arrived_from_district]: ({required}) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.arrived_from_district}
            rules={{required}}
            control={control}
            render={({field, fieldState: {error}}) => (
              <AdaptiveQueriedInfiniteScrollSelect
                label={getRequiredOrOptionalFieldLabel(
                  t('arrived_from_district'),
                  required,
                )}
                placeholder={t('arrived_from_district')}
                queryKey={QUERY_CACHE_KEYS.paginatedDistrictsList}
                fetcher={(key: string, page: number, searchQuery: string) =>
                  getDistrictsList({key, page, searchQuery, countryCode: housingCountry})
                }
                blockQuery={isDisabledField}
                disabled={isDisabledField}
                error={error?.message}
                openMenuOnFocus={false}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.arrived_from_municipality]: ({required, format}) => {
        switch (formFields?.[FORM_NAMES.arrived_from_municipality]?.type) {
          case STANDARD_FIELD_TYPES.select:
            return (
              <FormFieldWrapper>
                <Controller
                  name={FORM_NAMES.arrived_from_municipality}
                  rules={{required}}
                  control={control}
                  render={({field: {value, ...restField}, fieldState: {error}}) => (
                    <AdaptiveQueriedInfiniteScrollSelect
                      label={getRequiredOrOptionalFieldLabel(
                        t('arrived_from_municipality'),
                        required,
                      )}
                      placeholder={t('arrived_from_municipality')}
                      queryKey={[
                        QUERY_CACHE_KEYS.paginatedMunicipalitiesList,
                        arrivedFromDistrict,
                      ]}
                      fetcher={(key: string, page: number, searchQuery: string) =>
                        getMunicipalitiesList({
                          key,
                          page,
                          searchQuery,
                          countryCode: housingCountry,
                          district: arrivedFromDistrict,
                        })
                      }
                      blockQuery={isDisabledField}
                      disabled={isDisabledField}
                      value={value as SelectOptionType}
                      error={error?.message}
                      openMenuOnFocus={false}
                      {...restField}
                    />
                  )}
                />
              </FormFieldWrapper>
            );
          default:
            return (
              <FormFieldWrapper>
                <Input
                  label={getRequiredOrOptionalFieldLabel(
                    t('arrived_from_municipality'),
                    required,
                  )}
                  {...register(FORM_NAMES.arrived_from_municipality, {
                    required,
                    pattern: {
                      value: format,
                      message: t('cant_contain_number_and_symbols'),
                    },
                    maxLength: {
                      value: maxNamesInputLength,
                      message: t('max_length', {length: maxNamesInputLength}),
                    },
                  })}
                  control={control}
                  error={errors[FORM_NAMES.arrived_from_municipality]?.message}
                  disabled={isDisabledField}
                  autoCorrect="off"
                  spellCheck={false}
                  onKeyDown={handleKeyDown(FORM_NAMES.arrived_from_municipality)}
                />
              </FormFieldWrapper>
            );
        }
      },
      [FORM_NAMES.next_destination_country]: ({required}) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.next_destination_country}
            rules={{required}}
            control={control}
            render={({field, fieldState: {error}}) => (
              <AdaptiveQueriedInfiniteScrollSelect
                label={getRequiredOrOptionalFieldLabel(
                  t('next_destination_country'),
                  required,
                )}
                placeholder={t('next_destination_country')}
                queryKey={QUERY_CACHE_KEYS.paginatedCountriesList}
                fetcher={(key: string, page: number, searchQuery: string) =>
                  getCountriesList({key, page, searchQuery})
                }
                blockQuery={isDisabledField}
                disabled={isDisabledField}
                error={error?.message}
                openMenuOnFocus={false}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.next_destination_district]: ({required}) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.next_destination_district}
            rules={{required}}
            control={control}
            render={({field, fieldState: {error}}) => (
              <AdaptiveQueriedInfiniteScrollSelect
                label={getRequiredOrOptionalFieldLabel(
                  t('next_destination_district'),
                  required,
                )}
                placeholder={t('next_destination_district')}
                queryKey={QUERY_CACHE_KEYS.paginatedDistrictsList}
                fetcher={(key: string, page: number, searchQuery: string) =>
                  getDistrictsList({key, page, searchQuery, countryCode: housingCountry})
                }
                blockQuery={isDisabledField}
                disabled={isDisabledField}
                error={error?.message}
                openMenuOnFocus={false}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.next_destination_municipality]: ({required}) =>
        Boolean(nextDestinationDistrict) && (
          <FormFieldWrapper>
            <Controller
              name={FORM_NAMES.next_destination_municipality}
              rules={{
                required,
              }}
              control={control}
              render={({field, fieldState: {error}}) => (
                <AdaptiveQueriedInfiniteScrollSelect
                  placeholder={t('next_destination_municipality')}
                  label={getRequiredOrOptionalFieldLabel(
                    t('next_destination_municipality'),
                    required,
                  )}
                  queryKey={[
                    QUERY_CACHE_KEYS.paginatedMunicipalitiesList,
                    nextDestinationDistrict,
                  ]}
                  fetcher={(key: string, page: number, searchQuery: string) =>
                    getMunicipalitiesList({
                      key,
                      page,
                      searchQuery,
                      countryCode: housingCountry,
                      district: nextDestinationDistrict,
                    })
                  }
                  blockQuery={isDisabledField}
                  disabled={isDisabledField}
                  error={error?.message}
                  openMenuOnFocus={false}
                  {...field}
                />
              )}
            />
          </FormFieldWrapper>
        ),
      [FORM_NAMES.residence_address]: ({required, format}) => (
        <FormFieldWrapper>
          <Input
            {...register(FORM_NAMES.residence_address, {
              pattern: {
                value: format,
                message: t('cant_contain_number_and_symbols'),
              },
              required,
            })}
            control={control}
            label={getRequiredOrOptionalFieldLabel(t('residence_address'), required)}
            error={errors[FORM_NAMES.residence_address]?.message}
            disabled={isDisabledField}
            autoCorrect="off"
            spellCheck={false}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.residence_postal_code]: ({required}) => (
        <FormFieldWrapper>
          <Input
            label={getRequiredOrOptionalFieldLabel(t('residence_postal_code'), required)}
            {...register(FORM_NAMES.residence_postal_code, {
              required,
              validate: validatePostalCode(t, residenceCountry),
            })}
            control={control}
            error={errors[FORM_NAMES.residence_postal_code]?.message}
            disabled={isDisabledField}
            autoCorrect="off"
            spellCheck={false}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.next_destination_address]: ({required, format}) => (
        <FormFieldWrapper>
          <Input
            {...register(FORM_NAMES.next_destination_address, {
              pattern: {
                value: format,
                message: t('cant_contain_number_and_symbols'),
              },
              required,
            })}
            control={control}
            label={getRequiredOrOptionalFieldLabel(
              t('next_destination_address'),
              required,
            )}
            error={errors[FORM_NAMES.next_destination_address]?.message}
            disabled={isDisabledField}
            autoCorrect="off"
            spellCheck={false}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.fiscal_code]: ({required}) => (
        <FormFieldWrapper>
          <Input
            {...register(FORM_NAMES.fiscal_code, {
              required,
              pattern: {
                value: PATTERNS.fiscalCode,
                message: t('invalid_fiscal_code'),
              },
            })}
            control={control}
            label={getRequiredOrOptionalFieldLabel(t('fiscal_code'), required)}
            error={errors[FORM_NAMES.fiscal_code]?.message}
            disabled={isDisabledField}
            autoCorrect="off"
            spellCheck={false}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.document_support_number]: ({required}) => (
        <FormFieldWrapper>
          <Input
            {...register(FORM_NAMES.document_support_number, {
              required,
              maxLength: {
                value: maxSupportNumberInputLength,
                message: t('max_length', {length: maxSupportNumberInputLength}),
              },
            })}
            control={control}
            label={getRequiredOrOptionalFieldLabel(
              t('document_support_number'),
              required,
            )}
            error={errors[FORM_NAMES.document_support_number]?.message}
            disabled={isDisabledField}
            autoCorrect="off"
            spellCheck={false}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.visa_number]: ({required}) => (
        <FormFieldWrapper>
          <Input
            {...register(FORM_NAMES.visa_number, {required})}
            control={control}
            label={getRequiredOrOptionalFieldLabel(t('visa_number'), required)}
            error={errors[FORM_NAMES.visa_number]?.message}
            disabled={isDisabledField}
            autoCorrect="off"
            spellCheck={false}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.kinship_relationship]: ({
        required,
        options = formFields?.[FORM_NAMES.kinship_relationship]?.options,
      }) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.kinship_relationship}
            rules={{
              required,
            }}
            control={control}
            render={({field, fieldState: {error}}) => (
              <Select
                label={getRequiredOrOptionalFieldLabel(t('relationship'), required)}
                placeholder={getRequiredOrOptionalFieldLabel(t('relationship'), required)}
                options={options}
                error={error?.message}
                disabled={isDisabledField}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
      [FORM_NAMES.external_category_id]: ({
        required,
        options = formFields?.[FORM_NAMES.external_category_id]?.options,
      }) => (
        <FormFieldWrapper>
          <Controller
            name={FORM_NAMES.external_category_id}
            rules={{
              required,
            }}
            control={control}
            render={({field, fieldState: {error}}) => (
              <Select
                label={getRequiredOrOptionalFieldLabel(t('category'), required)}
                placeholder={getRequiredOrOptionalFieldLabel(t('category'), required)}
                options={options}
                error={error?.message}
                disabled={isDisabledField}
                {...field}
              />
            )}
          />
        </FormFieldWrapper>
      ),
    }),
    [
      getRequiredOrOptionalFieldLabel,
      t,
      register,
      control,
      errors,
      isDisabledField,
      handleKeyDown,
      getCountriesList,
      formFields,
      getCitiesList,
      housingCountry,
      policeType,
      getValues,
      setHasPhone,
      defaultSecondPhoneDetails?.number,
      isGuestSchemaLoading,
      isPoliceActivated,
      documentType,
      reservation?.check_in_date,
      guest?.has_allianz_insurance,
      getProvincesList,
      residenceCountry,
      getSpanishResidenceCitiesList,
      getDistrictsList,
      arrivedFromDistrict,
      getMunicipalitiesList,
      nextDestinationDistrict,
      shouldFirstGuestBeAdult,
      guestGroup,
    ],
  );

  const displayingFieldNames = useMemo(() => {
    if (Object.values(formFields).length > 0) {
      const filteredFieldNames = (Object.keys(defaultFormFields) as FORM_NAMES[]).filter(
        fieldName => {
          return formFields?.[fieldName]?.active;
        },
      );

      return sortFieldsByOrder(filteredFieldNames, formFields);
    } else {
      return [];
    }
  }, [defaultFormFields, formFields]);

  const renderDefaultFormFields = () => {
    const isAddingGuestLeader = isLeaderGuest;
    const customFields = hasCustomForm
      ? customForm?.fields_set.filter(customField => customField.field.is_custom)
      : [];

    const customFieldsJsx = customFields?.map(
      ({field, is_required, is_leaders_field}) => {
        const fieldName = FORM_NAMES_DTO[field.name as FormNamesOldType];

        const required = Boolean(is_required || formFields?.[fieldName]?.required)
          ? t('this_field_is_required')
          : false;
        const hidden = formFields?.[fieldName]?.active === false;

        if (hidden || (!isAddingGuestLeader && is_leaders_field)) {
          return null;
        }
        const customField = getCustomField(field, required);
        return <Fragment key={field.id}>{customField}</Fragment>;
      },
    );

    const defaultFieldsJsx = displayingFieldNames.map(fieldName => {
      const format = getRegExpFromString(formFields?.[fieldName]?.format);
      const required = formFields?.[fieldName]?.required
        ? t('this_field_is_required')
        : false;

      return (
        <Fragment key={fieldName}>
          {defaultFormFields[fieldName]?.({required, format})}
        </Fragment>
      );
    });

    return customFieldsJsx ? [...defaultFieldsJsx, ...customFieldsJsx] : defaultFieldsJsx;
  };

  const hasCustomForm = Boolean(customForm?.id);
  // const customFormFields = customForm?.fields_set || [];
  // const renderCustomFormFields = () => {
  //   const isAddingGuestLeader = isLeaderGuest;

  //   if (!customForm) {
  //     return null;
  //   }
  //   const customFields = customFormFields.map(
  //     ({field, is_required, is_leaders_field}) => {
  //       const fieldName = FORM_NAMES_DTO[field.name as FORM_NAMES_OLD_TYPE];

  //       const required = Boolean(is_required || formFields?.[fieldName]?.required);
  //       const hidden = formFields?.[fieldName]?.active === false;

  //       if (hidden || (!isAddingGuestLeader && is_leaders_field)) {
  //         return null;
  //       }

  //       if (!field.is_custom) {
  //         const defaultField = defaultFormFields?.[fieldName];
  //         console.log('fieldName', fieldName);
  //         return (
  //           <Fragment key={field.id}>{defaultField?.(required)}</Fragment>
  //         );
  //       }
  //       debugger;
  //       const customField = getCustomField(field, required);
  //       return <Fragment key={field.id}>{customField}</Fragment>;
  //     },
  //   );

  //   return customFields;
  // };

  // const renderFormFields = hasCustomForm
  //   ? renderCustomFormFields
  //   : renderDefaultFormFields;
  const renderFormFields = renderDefaultFormFields;

  const hasSignature = useMemo(() => {
    return formFields?.[FORM_NAMES.signature]?.active;
  }, [formFields]);

  const SignatureField = useMemo(() => {
    return (
      <>
        <StatusModal
          open={isRegisteredModalOpen}
          state={guestRegistrationStatus}
          maxWidth="350px"
        >
          <ModalGuestRegistered
            name={fullNameCurrentGuestRef.current}
            onClose={closeRegisteredModal}
            state={guestRegistrationStatus}
            isEdition={isEdition}
            hasAllianzInsurance={redirectToAllianz}
          />
        </StatusModal>
        <AllianzLoadingModal />
        <SignBlock
          onSubmit={handleSubmitForm}
          automatedSignature={reservation?.auto_signature}
          hasSignature={hasSignature}
          isSignatureRequired={formFields?.[FORM_NAMES.signature]?.required}
          setIsSignModalOpen={setIsSignModalOpen}
          isSignModalOpen={isSignModalOpen}
        />
      </>
    );
  }, [
    guestRegistrationStatus,
    closeRegisteredModal,
    isEdition,
    redirectToAllianz,
    AllianzLoadingModal,
    hasSignature,
    handleSubmitForm,
    reservation?.auto_signature,
    formFields,
    isSignModalOpen,
    isRegisteredModalOpen,
  ]);

  const FormModals = useCallback(() => {
    return (
      <>
        <IncompleteRegisterModal
          open={isDataIncompleteModalOpen}
          onClose={closeDataIncompleteModalOpen}
        />
        <DiscardChangesModalSheet />
        <ErrorModal />
        <GuestErrorModal />
      </>
    );
  }, [
    isDataIncompleteModalOpen,
    closeDataIncompleteModalOpen,
    ErrorModal,
    GuestErrorModal,
  ]);

  return {
    isLoadingForm,
    renderFormFields,
    FormModals,
    SignatureField,
    hasSignature,
    isDataIncompleteModalOpen,
    isLoadedForm: isGuestSchemaLoaded,
  };
}

export {useGuestFormFields};
