import AbstractStorage from './AbstractStorage';

let memoryStorage = {} as Record<string, string | Record<string, string>>;

class MemoryStorage extends AbstractStorage {
  static get<T = string>(key: string): T | null {
    const data = memoryStorage[key];
    if (!data) return null;
    return data as T;
  }

  static set<T>(key: string, value: T): void {
    if (value) {
      memoryStorage[key] = value;
    }
  }

  static remove(key: string): void {
    delete memoryStorage[key];
  }

  static clear(): void {
    memoryStorage = {};
  }
}

export {MemoryStorage};
