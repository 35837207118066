import {forwardRef} from 'react';
import {
  Popup as MainPopup,
  PopupActions,
  PopupProps as MainPopupProps,
} from '@guestapp/ui';
import {useFrame} from '../../../context/frame';

type PopupProps = Omit<MainPopupProps, 'customDocument' | 'customWindow'>;
const Popup = forwardRef<PopupActions, PopupProps>((props, ref) => {
  const {document, window} = useFrame();

  return (
    <MainPopup ref={ref} {...props} customDocument={document} customWindow={window} />
  );
});

export {Popup};
export type {PopupProps, PopupActions};
