import styled from 'styled-components';

export const MarkBtn = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }

  & > img {
    width: 13px;
    height: 21px;
  }
`;
