import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ButtonProps} from 'components/common/Button/Button';
import {useOpenModals} from 'context/openModals';
import ModalSheet from 'components/common/ModalSheet';
import StandardModalSheet from 'components/common/StandardModalSheet';

export enum DiscardChangesType {
  iv = 'IV',
  complete = 'COMPLETE',
}
function DiscardChangesModalSheet() {
  const {t} = useTranslation();

  const {isOpenDiscardModal, closeDiscardModal, discardChangesAndRestart} =
    useOpenModals();
  const discardCompleteChangesText = React.useMemo(
    () => (
      <Trans i18nKey="discard_data_leaving_the_process">
        Leaving the process before completing the registration will
        <b>discard your personal data</b> so you will have to complete the from again.
      </Trans>
    ),
    [],
  );

  const discardChangeButtons: ButtonProps[] = React.useMemo(() => {
    const primaryButton = {
      label: t('discard_changes_leaving'),
      primary: true,
      onClick: discardChangesAndRestart,
    };
    const secondaryButton: ButtonProps = {
      link: true,
      label: t('continue_process'),
      onClick: closeDiscardModal,
    };
    return [primaryButton, secondaryButton];
  }, [t, closeDiscardModal, discardChangesAndRestart]);

  return (
    <ModalSheet
      isOpenModalSheet={isOpenDiscardModal}
      handleCloseModal={closeDiscardModal}
      title={t('discard_data')}
      closeOnDocumentClick={false}
      className="discard-changes-modal"
      closeButtonType="click"
    >
      <StandardModalSheet
        buttons={discardChangeButtons}
        text={discardCompleteChangesText}
      />
    </ModalSheet>
  );
}

export {DiscardChangesModalSheet};
