import {apiClient, FetcherSettings} from './apiClient';
import {GuestTaxExemption} from '../utils/types';
import {dynamicRoute} from './utils';

type CalculateGuestTaxPayload = {
  id?: string;
  calculate_only?: boolean;
  guest_list?: Partial<GuestTaxExemption>[];
  reservation_id: string;
  status?: 'PENDING' | 'COMPLETE';
};

class TaxesApi {
  ENDPOINTS = {
    allExemptions: (params = '') => `api/v3/stat-tax-exemptions/?${params}`,
    season: dynamicRoute({
      regular: (id: string) => `api/v3/seasons/${id}/`,
      sdk: (id: string) => `sdk/api/v1/seasons/${id}/`,
    }),
    seasonGuests: dynamicRoute({
      regular: (params = '') => `api/v3/season-guests/?${params}`,
      sdk: (params = '') => `sdk/api/v1/season-guests/?${params}`,
    }),
    guestTaxes: dynamicRoute({
      regular: (id = '', params = '') => `api/v3/season-guests/${id}/?${params}`,
      sdk: (id = '', params = '') => `sdk/api/v1/season-guests/${id}/?${params}`,
    }),
    initGuestTaxes: dynamicRoute({
      regular: () => `api/v3/season-guests/init/`,
      sdk: () => `sdk/api/v1/season-guests/init/`,
    }),
  };

  async getAllExemptions<T>(params = '', fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<T>(
      this.ENDPOINTS.allExemptions(params),
      fetcherSettings,
    );
    return response.body;
  }

  async getSeason<T>(id: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<T>(this.ENDPOINTS.season(id), fetcherSettings);

    return response.body;
  }

  async getSeasonGuests<T>(params = '', fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<T>(
      this.ENDPOINTS.seasonGuests(params),
      fetcherSettings,
    );
    return response.body;
  }

  async initGuestTaxes<T>(
    payload: {reservation_id: string},
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient.post<T>(this.ENDPOINTS.initGuestTaxes(), {
      json: payload,
      ...fetcherSettings,
    });
    return response.body;
  }

  async calculateGuestTax<T>(
    {id, ...payload}: CalculateGuestTaxPayload,
    params?: string,
    fetcherSettings?: FetcherSettings,
  ) {
    if (id) {
      const response = await apiClient.patch<T>(this.ENDPOINTS.guestTaxes(id, params), {
        json: payload,
        ...fetcherSettings,
      });

      return response.body;
    } else {
      const response = await apiClient.post<T>(this.ENDPOINTS.seasonGuests(params), {
        json: payload,
        ...fetcherSettings,
      });

      return response.body;
    }
  }
}

export {TaxesApi};
export type {CalculateGuestTaxPayload};
