import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import SignaturePad from 'react-signature-pad-wrapper';
import clsx from 'clsx';
import {useScreenResize} from 'hooks';
import {useCombinedRef} from '@guestapp/core';
import {useFrame} from '../../../context/frame';
import {device} from 'styled/device';
import {
  ClearSignatureButton,
  SignCanvas,
  SignPlaceholder,
  SignPlaceholderText,
  SignWrapper,
} from './styled';

const SIGNATURE_PROPS = {
  penColor: '#161643',
  minWidth: 1.5,
  maxWidth: 1.5,
  dotSize: 1,
};

type SignatureCanvasProps = {
  onClear?: () => void;
  onEnable?: () => void;
  hasSignature?: boolean;
  onEnd?: () => void;
  onStart?: () => void;
  className?: string;
  enabled?: boolean;
};

const defaultProps: SignatureCanvasProps = {
  onEnd: () => {},
  onEnable: () => {},
  onStart: () => {},
  onClear: () => {},
  hasSignature: false,
  className: '',
  enabled: false,
};

const SignatureCanvas = React.forwardRef<SignaturePad, SignatureCanvasProps>(
  ({onClear, hasSignature, onEnd, onStart, onEnable, className, enabled}, ref) => {
    const {t} = useTranslation();
    const internalRef = useRef<SignaturePad | null>(null);
    const combinedInputRef = useCombinedRef(ref, internalRef);

    const {isMatch: isMobile} = useScreenResize(device.tablet);
    const {window} = useFrame();
    const CANVAS_DESKTOP_PROPS = {
      width: window.innerHeight < 600 ? 319 : 646,
      height: window.innerHeight < 600 ? 182 : 302,
    };
    const CANVAS_PROPS: Record<string, string> = {
      width: isMobile ? '319px' : CANVAS_DESKTOP_PROPS.width + 'px',
      height: isMobile ? '182px' : CANVAS_DESKTOP_PROPS.height + 'px',
    };

    const enableSignature = () => {
      if (onEnable) {
        onEnable();
      }
    };

    React.useEffect(() => {
      if (!onEnd) return;
      const signPad = internalRef.current?.instance;
      signPad?.addEventListener('endStroke', onEnd);

      return () => {
        signPad?.removeEventListener('endStroke', onEnd);
      };
    }, [onEnd]);

    return (
      <SignWrapper className={clsx(className, 'signature')}>
        {!enabled && (
          <SignPlaceholder
            data-testid="signature-placeholder"
            onClick={enableSignature}
            className="signature__placeholder"
          >
            <SignPlaceholderText>{t('tap_here_to_sign')}</SignPlaceholderText>
          </SignPlaceholder>
        )}
        <SignCanvas hidden={!enabled} className="signature__canvas">
          <SignaturePad
            ref={combinedInputRef}
            data-testid="canvas"
            options={SIGNATURE_PROPS}
            canvasProps={CANVAS_PROPS}
          />
          {hasSignature && (
            <ClearSignatureButton onClick={onClear} className="signature__clear-btn">
              {t('clear')}
            </ClearSignatureButton>
          )}
        </SignCanvas>
      </SignWrapper>
    );
  },
);

SignatureCanvas.defaultProps = defaultProps;
export {SignatureCanvas};
