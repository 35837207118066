import React from 'react';
import dayjs from 'dayjs';
import {PATTERNS} from '@guestapp/core';
import {useFetchLocations} from '../hooks';
import {useSchema} from '../context/guest';
import {getRemappedDocType} from '../utils/docTypes';
import {OCRDetectedData} from '@guestapp/sdk';
import {FORM_NAMES} from '../utils/guestForm';
import {useSummary} from '../context/summary';

const getValidName = (name?: string | null) => {
  if (!name) {
    return undefined;
  }
  return PATTERNS.name.test(name) ? name : undefined;
};

function useBuildOCRDetectedData() {
  const {locationsAsOptions} = useFetchLocations();
  const {countryCode} = useSummary();
  const {formFields} = useSchema();
  const docTypesOptions = formFields[FORM_NAMES.document_type]?.options;
  const genderOptions = formFields[FORM_NAMES.gender]?.options;

  const buildFormData = React.useCallback(
    (detectedData?: OCRDetectedData | null) => {
      if (!detectedData) {
        return {};
      }

      const isDateOfBirthValid =
        detectedData?.valid_date_of_birth && detectedData?.date_of_birth?.includes('-');
      const birthDate = isDateOfBirthValid
        ? dayjs(detectedData.date_of_birth).toDate()
        : undefined;

      const isIssueDateOfDocValid =
        detectedData?.valid_issue_date && detectedData?.issue_date?.includes('-');
      const issueDateOfDoc = isIssueDateOfDocValid
        ? dayjs(detectedData.issue_date).toDate()
        : undefined;

      const isExpirationDateOfDocValid =
        detectedData?.valid_expiration_date &&
        detectedData?.expiration_date?.includes('-');
      const expirationDateOfDoc = isExpirationDateOfDocValid
        ? dayjs(detectedData.expiration_date).toDate()
        : undefined;

      const nationality = locationsAsOptions.find(l => {
        return l?.value === detectedData?.nationality;
      });

      const remappedDocType = getRemappedDocType({
        docType: detectedData?.type_doc,
        countryCode: countryCode,
        nationality: nationality?.value,
      });

      const docTypeOption = docTypesOptions?.find(document => {
        return document?.value === remappedDocType;
      });

      const genderOption = genderOptions?.find(gender => {
        return gender?.value === detectedData?.sex;
      });

      return {
        [FORM_NAMES.gender]: genderOption,
        [FORM_NAMES.document_issue_date]: issueDateOfDoc,
        [FORM_NAMES.document_expiration_date]: expirationDateOfDoc,
        [FORM_NAMES.birth_date]: birthDate,
        [FORM_NAMES.nationality]: nationality,
        [FORM_NAMES.document_type]: docTypeOption,
        [FORM_NAMES.document_number]: detectedData?.number,
        [FORM_NAMES.name]: getValidName(detectedData?.names),
        [FORM_NAMES.surname]: getValidName(detectedData?.first_surname),
        [FORM_NAMES.second_surname]: getValidName(detectedData?.second_surname),
      };
    },
    [countryCode, docTypesOptions, genderOptions, locationsAsOptions],
  );

  return {buildFormData};
}

export default useBuildOCRDetectedData;
