import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Navigate, useParams} from 'react-router-dom';
import {IV_DOCUMENT_IMAGE_TYPES} from '../../../../utils/constants';
import {PATHS} from '../../../../Routes';
import {useAppSelector, useFetchIVLottie} from '../../../../hooks';
import {getDocumentType} from '../../../../store/slices/identityVerification';
import {getHelperTextKey} from 'pages/IdentityVerification/utils';
import checkIcon from '../../../../assets/icons/check.svg';
import Button from '../../../../components/common/Button';
import {
  BottomBlock,
  ButtonsContainer,
  // GifContainer,
  HelpersBlock,
  HelpersItem,
  StyledLottieIVDocumentsAnimations,
  Text,
  Wrapper,
} from './styled';

// const getIvGif = (type?: IV_DOCUMENT_IMAGE_TYPES, hasTwoSides?: boolean) => {
//   switch (type) {
//     case IV_DOCUMENT_IMAGE_TYPES.front:
//       return hasTwoSides ? frontside : passport;
//     case IV_DOCUMENT_IMAGE_TYPES.back:
//       return backside;
//     case IV_DOCUMENT_IMAGE_TYPES.selfie:
//       return selfie;
//     default:
//       return '';
//   }
// };

const ParamTypes = Object.values(IV_DOCUMENT_IMAGE_TYPES);

function UploadingTypeView() {
  const {t} = useTranslation();
  // const {hasTemplate} = useCustomTheme();
  const {type} = useParams<{type: IV_DOCUMENT_IMAGE_TYPES}>();
  const documentType = useAppSelector(getDocumentType);
  const {animationData: ivLottieAnimation} = useFetchIVLottie({type});

  const Message = React.useMemo(() => {
    const textKey = getHelperTextKey(type, documentType?.two_sides);

    if (!textKey) {
      return null;
    }

    return <Trans i18nKey={textKey} />;
  }, [documentType?.two_sides, type]);
  if (!type || !ParamTypes.includes(type)) {
    return <Navigate to={IV_DOCUMENT_IMAGE_TYPES.front} />;
  }

  return (
    // <IVSteps>
    <Wrapper className="iv-upload-type-view">
      <Text className="iv-upload-type-view__text">{Message}</Text>
      {/* <GifContainer src={getIvGif(type, documentType?.two_sides)} /> */}
      <StyledLottieIVDocumentsAnimations loop animationData={ivLottieAnimation} />
      <BottomBlock className="iv-upload-type-view__bottom">
        {type === IV_DOCUMENT_IMAGE_TYPES.selfie && (
          <HelpersBlock>
            <HelpersItem>
              <img src={checkIcon} width={24} height={24} alt="" />
              <p>{t('make_sure_your_face_is_uncovered')}</p>
            </HelpersItem>
            <HelpersItem>
              <img src={checkIcon} width={24} height={24} alt="" />
              <p>{t('use_a_good_lighting')}</p>
            </HelpersItem>
          </HelpersBlock>
        )}
        <ButtonsContainer className="iv-upload-type-view__buttons">
          {type === IV_DOCUMENT_IMAGE_TYPES.selfie ? (
            <Button
              label={t('understood')}
              to={`../${PATHS.verification.selfie}`}
              outlined
            />
          ) : (
            <>
              <Button
                label={t('take_a_photo')}
                to={`../${PATHS.verification?.[type]}/take`}
                outlined
              />
              <Button
                label={t('upload_file')}
                to={`../${PATHS.verification?.[type]}/upload`}
                outlined
              />
            </>
          )}
        </ButtonsContainer>
      </BottomBlock>
    </Wrapper>
    // </IVSteps>
  );
}

export {UploadingTypeView};
