import styled from 'styled-components';
import {device} from '@guestapp/ui/src/utils/device';
import {Button, CustomIcon} from '@guestapp/ui';
import {FormFieldWrapper} from 'styled/common';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  width: 100%;

  @media (min-width: ${device.tablet}) {
    margin-top: 48px;
  }
`;

export const TitleWrapper = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.36px;
  text-align: left;
  margin-bottom: 16px;
  color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.fontPrimary : theme.colors.$p10};

  @media (min-width: ${device.tablet}) {
    font-size: 30px;
    font-weight: 500;
  }
`;

export const SubTitleWrapper = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.fontSecondary : theme.colors.$p10};
  margin-bottom: 24px;
  @media (min-width: ${device.tablet}) {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 32px;
  }
`;

export const CustomBackButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 32px;

  & .button__left-icon {
    padding-right: 10px;
  }
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  margin: 48px auto;
  @media (min-width: ${device.tablet}) {
    margin: 24px 0 48px 0;
  }
`;

export const StatusTitleModal = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.36px;
  text-align: center;
  color: #152946;
`;

export const StyledBackIcon = styled(CustomIcon)`
  display: flex;
  justify-content: center;
  align-items: center;

  path {
    fill: ${({theme}) =>
      theme.hasTemplate
        ? theme.colors.branding.icons
        : theme.colors.navigationMenu.selectedItemText};
  }
`;

export const StyledButton = styled(Button)`
  min-width: 295px;
  @media (min-width: ${device.tablet}) {
    min-width: 205px;
  }
`;

export const StyledFormFieldWrapper = styled(FormFieldWrapper)`
  & .fieldset__label {
    text-transform: none;
  }
`;
