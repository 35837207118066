import React from 'react';
import questionMarkIcon from '../../assets/icons/icon-question-circle.svg';
import {MRZModalTrigger} from './styled';

type QuestionMarkButtonProps = {
  onClick?: () => void;
  className?: string;
};

const defaultProps: Partial<QuestionMarkButtonProps> = {
  className: undefined,
};

const QuestionMarkButton = React.forwardRef<HTMLInputElement, QuestionMarkButtonProps>(
  ({onClick, className}, ref) => {
    return (
      <div ref={ref} className="question-mark-button">
        <MRZModalTrigger
          type="button"
          className={className}
          onClick={onClick}
          tabIndex={-1}
        >
          <img src={questionMarkIcon} alt="Question mark" />
        </MRZModalTrigger>
      </div>
    );
  },
);
QuestionMarkButton.defaultProps = defaultProps;

export {QuestionMarkButton};
