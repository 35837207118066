import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {addPersonalDataFormRoute, PATHS} from '../Routes';
import AddPersonalDataForm from '../pages/AddPersonalDataForm';
import MRZView, {MRZScanView, MRZStartView} from '../pages/MRZView';

function OnlyGuestFormRoutes() {
  return (
    <Routes>
      {addPersonalDataFormRoute.map((route, index) => {
        return <Route key={index} path={route} element={<AddPersonalDataForm />} />;
      })}
      <Route path={PATHS.documentMRZ} element={<MRZView />}>
        <Route index element={<MRZStartView />} />
        <Route path={':uploadType'} element={<MRZScanView />} />
      </Route>
      <Route path="*" element={<Navigate to={PATHS.addPersonalDataForm} replace />} />
    </Routes>
  );
}

export {OnlyGuestFormRoutes};
