import styled from 'styled-components';
import {device} from '@guestapp/ui/src/utils/device';
import {MultiRadio} from 'components/common/MultiRadio/MultiRadio';
import {Button} from '@guestapp/ui';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  width: 100%;
  gap: 16px;

  @media (min-width: ${device.tablet}) {
    margin-top: 48px;
  }
`;

export const TitleWrapper = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.36px;
  text-align: left;
  color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.fontPrimary : theme.colors.$p10};

  @media (min-width: ${device.tablet}) {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 16px;
  }
`;

export const SubTitleWrapper = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 24px;
  color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.fontSecondary : theme.colors.$p10};

  @media (min-width: ${device.tablet}) {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
  }
`;

export const ContentSelectOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const MultiRadioStyled = styled(MultiRadio)`
  gap: 24px;

  & .multi-radio__label {
    color: ${({theme}) =>
      theme.hasTemplate ? theme.colors.branding.fontSecondary : theme.colors.$p10};
    font-size: ${({theme}) => theme.fonts.sizes.large};
    font-weight: ${({theme}) => theme.fonts.fontWeight.mediumL};
    padding: 8px 0;

    &:before {
      margin-right: 16px;
    }
  }
`;

export const ContentButton = styled.div`
  margin: 48px auto;
  @media (min-width: ${device.tablet}) {
    margin: 48px 0;
  }
`;

export const StyledButton = styled(Button)`
  min-width: 295px;
  @media (min-width: ${device.tablet}) {
    min-width: 205px;
  }
`;
