import i18next from 'i18next';
import Compressor from 'compressorjs';
import dayjs from 'dayjs';
import {ALLIANZ_STATUSES} from '@guestapp/sdk/src/utils/allianz/constanst';
import {DATE_FORMAT, PrivacyPolicySettings} from '@guestapp/sdk';
import {COUNTRY_CODES} from 'utils/constants';
import {LANGUAGES_ISO_3} from '@guestapp/core';
import * as timezone from 'dayjs/plugin/timezone';

const transformSizePropToSize = (size?: string | number) => {
  if (!size) {
    return;
  } else if (typeof size === 'number') {
    return `${size}px`;
  } else if (size === 'auto') {
    return size;
  } else if (size.slice(-2) === 'px') {
    return size;
  } else {
    return `${size}px`;
  }
};

function isBrowser(): boolean {
  return typeof window !== 'undefined';
}

function copyToClipboard(value: string) {
  const el = document.createElement('textarea');
  el.value = value;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

function dataURIToBlob(dataURI?: string | null) {
  if (!dataURI) {
    return;
  }
  const splitDataURI = dataURI.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? window.atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], {type: mimeString});
}

function getSignaturePayLoadValue(
  signature: string | null,
): string | Blob | undefined | null {
  if (signature) {
    return dataURIToBlob(signature);
  }
  if (!signature && typeof signature === 'string') {
    return '';
  }

  return;
}

const getPayloadDate = (date: Date | null) => {
  return date ? dayjs(new Date(date)).format(DATE_FORMAT) : undefined;
};

function downloadFromLink(link?: string, blank: '_blank' | '_no-blank' = '_blank') {
  if (!link) return;
  if (blank === '_no-blank') {
    window.location.href = link;
    return;
  }

  const isSwitched = window.open(link, blank);

  if (!isSwitched) {
    const anchorLink = document.createElement('a');
    anchorLink.href = link;
    anchorLink.click();
    anchorLink.remove();
  }
}

function getCurrentLocale() {
  try {
    return i18next.language.slice(0, 2);
  } catch (e) {
    return 'en';
  }
}

function toBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(String(reader.result));
    reader.onerror = error => reject(error);
  });
}

function getFileSizeMB(file?: File | Blob) {
  if (!file) return;
  return file.size / Math.pow(1024, 2);
}

function getCanvasBlob(canvas: HTMLCanvasElement) {
  return new Promise<Blob | null>(function (resolve) {
    canvas.toBlob(function (blob) {
      resolve(blob);
    });
  });
}

type CompressFileOptions = {convertSize?: number};
function compressFile(file: Blob, {convertSize}: CompressFileOptions) {
  return new Promise<File>((resolve, reject) => {
    new Compressor(file, {
      convertSize,
      success: (file: File) => resolve(file),
      error: reject,
    });
  });
}

function getStringWithoutNumbers(string = '') {
  return string.replace(/\d/g, '');
}

function isAllianzFeatureActivated(
  chekinDate?: string,
  allianzStatus?: ALLIANZ_STATUSES,
  housingCountry?: string,
  nights?: number,
): boolean {
  if (!chekinDate || !housingCountry || !nights) return false;

  const maxDaysByCountry = [COUNTRY_CODES.spain].includes(housingCountry) ? 30 : 90;
  const checkinParseDate = new Date(chekinDate);
  const checkinDateWithoutTZ = new Date(checkinParseDate.toISOString().slice(0, -1));
  const today = dayjs(new Date());
  return (
    nights <= maxDaysByCountry &&
    dayjs(today).isBefore(dayjs(checkinDateWithoutTZ)) &&
    allianzStatus === ALLIANZ_STATUSES.active
  );
}

const getDocumentDownloadByLanguage = (data: PrivacyPolicySettings, language: string) => {
  const defaultLanguage = data.default_language;
  const documents = data.documents;

  const documentInCurrentLanguage = documents.find(document => {
    return document.language === LANGUAGES_ISO_3[language] || false;
  });

  if (documentInCurrentLanguage) {
    return documentInCurrentLanguage.document_download;
  }

  const documentInDefaultLanguage = documents.find(
    document => document.language === defaultLanguage,
  );

  if (documentInDefaultLanguage) {
    return documentInDefaultLanguage.document_download;
  }

  return '';
};

function checkIfCurrentDayBetween(checkin?: string, checkout?: string): boolean {
  dayjs.extend(timezone.default);
  if (!checkin || !checkout) return false;
  const now = dayjs.utc(new Date());
  const checkinDate = dayjs(checkin);
  const checkoutDate = dayjs(checkout);
  const localTimezone = dayjs.tz.guess();
  const offset = dayjs().tz(localTimezone).utcOffset();
  const offsetInHours = offset / 60;
  const adjustedDate = now.add(offsetInHours, 'hour');
  return dayjs(adjustedDate).isBetween(checkinDate, checkoutDate);
}

function checkIsCurrentTimeBetween(checkin?: string, checkout?: string) {
  if (!checkin || !checkout) return false;

  const currentTime = dayjs();
  const checkinTime = dayjs.utc(checkin);
  const checkoutTime = dayjs.utc(checkout);
  const currentHour = currentTime.hour();
  const checkinHour = checkinTime.hour();
  const checkoutHour = checkoutTime.hour();
  return currentHour >= checkinHour && currentHour <= checkoutHour;
}

function getCheckinAndCheckoutDateWithTimes(
  checkinDate: string,
  checkoutDate: string,
  accessStartTime?: string,
  accessEndTime?: string,
) {
  const defaultDates = {
    checkin: dayjs.utc(checkinDate).format('DD MMM (HH:mm)'),
    checkout: dayjs.utc(checkoutDate).format('DD MMM (HH:mm)'),
  };

  if (!accessStartTime || !accessEndTime) return defaultDates;
  const [startHour, startMinute, startSecond] = accessStartTime.split(':').map(Number);
  const [endHour, endMinute, endSecond] = accessEndTime.split(':').map(Number);

  const checkinTimeDate = dayjs
    .utc(checkinDate)
    .set('hour', startHour)
    .set('minute', startMinute)
    .set('second', startSecond);
  const checkoutTimeDate = dayjs
    .utc(checkoutDate)
    .set('hour', endHour)
    .set('minute', endMinute)
    .set('second', endSecond);

  return {
    checkin: checkinTimeDate.format(),
    checkout: checkoutTimeDate.format(),
  };
}

export {
  transformSizePropToSize,
  isBrowser,
  copyToClipboard,
  dataURIToBlob,
  getSignaturePayLoadValue,
  getPayloadDate,
  downloadFromLink,
  getCurrentLocale,
  getFileSizeMB,
  toBase64,
  getCanvasBlob,
  compressFile,
  getStringWithoutNumbers,
  getDocumentDownloadByLanguage,
  isAllianzFeatureActivated,
  checkIfCurrentDayBetween,
  checkIsCurrentTimeBetween,
  getCheckinAndCheckoutDateWithTimes,
};
