import {useQuery} from '@tanstack/react-query';
import {AllianzOrder} from '@guestapp/sdk/src/utils/allianz/';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {useChekinSDK} from 'context/ChekinSDK';
import {useReservation} from 'context/reservation';

type UseFetchAllianzPlansProps = {
  onSuccess?: (data: AllianzOrder[]) => void;
  onError?: (e: Error) => void;
  enabled?: boolean;
};
function useFetchAllianzOrders({onSuccess, onError, enabled}: UseFetchAllianzPlansProps) {
  const {data: reservation} = useReservation();
  const {getOrders} = useChekinSDK();
  const enabledRequest =
    Boolean(reservation?.id) && Boolean(reservation?.housing_id) && enabled;

  const queryData = useQuery<AllianzOrder[], Error>(
    [QUERY_CACHE_KEYS.allianzOrders],
    () => getOrders(`reservation=${reservation?.id}&housing=${reservation?.housing_id}`), // for dev
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: enabledRequest,
      onSuccess,
      onError,
    },
  );

  return {...queryData};
}

export {useFetchAllianzOrders};
