import {apiClient, FetcherSettings} from './apiClient';
import {Paginated, UnlockResponse, LockConditions, LockDetail} from '../utils/types';
import {dynamicRoute} from './utils';

class LocksApi {
  ENDPOINTS = {
    unlock: dynamicRoute({
      regular: (params = '') => `api/v3/locks/unlock/?${params}`,
      sdk: (params = '') => `sdk/api/v1/locks/unlock/?${params}`,
    }),
    getLocks: dynamicRoute({
      regular: (params = '') => `api/v4/guestapp/locks/?${params}`,
      sdk: (params = '') => `sdk/api/v1/guestapp/locks/?${params}`,
    }),
    getLockConditions: dynamicRoute({
      regular: (params = '') => `api/v4/guestapp/reservation-conditions/?${params}`,
      sdk: (params = '') => `sdk/api/v1/guestapp/reservation-conditions/?${params}`,
    }),
  };

  async getLocks(params = '', fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<Paginated<{unlock_links: LockDetail[]}>>(
      this.ENDPOINTS.getLocks(params),
      fetcherSettings,
    );
    return response.body.results?.[0]?.unlock_links ?? [];
  }

  async getLockConditions(params = '', fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<LockConditions>(
      this.ENDPOINTS.getLockConditions(params),
      fetcherSettings,
    );
    return response.body;
  }

  async unlock(params = '', fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<UnlockResponse>(
      this.ENDPOINTS.unlock(params),
      fetcherSettings,
    );

    return response.body;
  }
}

export {LocksApi};
