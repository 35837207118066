import {useMemo} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {EMBEDDED_MODE} from './utils/embedded';
import {
  useHousingUniversalLink,
  useInitGuestTaxes,
  usePath,
  useInitPropertyProtection,
} from './hooks';
import {useCustomTheme} from 'context/customTheme';
import {environments} from './configs';
import AddPersonalDataForm from 'pages/AddPersonalDataForm';
import GuestRegistrationView from 'pages/GuestRegistrationView';
import IdentityVerification from 'pages/IdentityVerification';
import FAQView from './pages/FAQView';
import DepositView from './pages/DepositView';
import HomeView from 'pages/HomeView';
import TravelInsurances from 'pages/TravelInsurances';
import Allianz from 'pages/Allianz';
import RemoteAccessView from 'pages/RemoteAccess';
import RootLayout from './components/RootLayout';
import Payments, {PaymentFormView, PaymentsView} from './pages/Payments';
import MRZView, {MRZScanView, MRZStartView} from './pages/MRZView';
import {OnlyGuestFormRoutes} from './routes/OnlyGuestFormRoutes';
import {TaxesExemptionView, TaxesTouristView} from './pages/Taxes';
import {
  BackScanView,
  DocumentSelectionView,
  FrontScanView,
  SelfieScanView,
  StartView,
  UploadingTypeView,
} from './pages/IdentityVerification/views';
import {
  AddDeals,
  CreateGuest,
  PreviousProductInformationNote,
  SetPlanView,
} from 'pages/Allianz/views';
import SearchReservation from 'pages/SearchReservation';
import {
  BookingCodeView,
  CheckInDateView,
  NewReservationView,
} from 'pages/SearchReservation/Views';
import {OfferView} from 'pages/OfferView';
import {BookExperienceView} from 'pages/BookExperienceView';
import {EnterContactEmailView} from 'pages/EnterContactEmailView';
import {OfferListView} from 'pages/OfferListView';

const IS_EMBEDDED_MODE = environments.REACT_APP_IS_EMBEDDED;

const PATHS = {
  home: IS_EMBEDDED_MODE ? '/' : '/:token/',
  summary: 'summary',
  guestRegistration: 'guest/registration',
  addPersonalDataForm: 'add/guest',
  documentMRZ: 'document-mrz',
  faq: 'faq',
  notFount: 'not-found',
  taxes: {
    main: 'taxes',
    exemptions: 'exemptions',
  },
  verification: {
    main: 'verification',
    documentSelection: 'document-selection',
    uploadingType: 'uploading-type',
    front: 'front',
    back: 'back',
    selfie: 'selfie',
  },
  deposits: 'deposit',
  payments: {
    main: 'payments',
    form: 'form',
    ordersHistory: 'order-history',
  },
  recommendations: {
    main: 'recommendations',
    details: {
      main: ':id',
      bookExperience: 'book-experience',
      confirmEmail: 'confirm-email',
    },
  },
  travelInsurances: {
    main: 'travel-insurances',
    allianz: {
      main: 'allianz',
      selectPlan: 'select-plan',
      selectGuests: 'select-guests',
      createGuest: 'add-ensurance-unregister-guest',
      previousProductInformationNote: 'previously-note-information-product',
    },
  },
  remoteAccess: 'remote-access',
  searchReservation: {
    main: 'search-reservation',
    bookingCode: 'booking-code',
    checkingDate: 'checking-date',
    newReservation: 'new-reservation',
  },
};

const verificationTypesRoutes = [
  PATHS.verification.main,
  `${PATHS.verification.main}/:id`,
];
const addPersonalDataFormRoute = [
  PATHS.addPersonalDataForm,
  `${PATHS.addPersonalDataForm}/:id`,
];

function AppRouter() {
  useHousingUniversalLink();

  useInitGuestTaxes();
  useInitPropertyProtection();
  const {isTemplateInitialized} = useCustomTheme();
  const {getFullPath} = usePath();

  const routes = useMemo(() => {
    if (IS_EMBEDDED_MODE && window.ChekinProSettings?.mode) {
      const mode = window.ChekinProSettings.mode;

      if (mode === EMBEDDED_MODE.onlyGuestForm) {
        return <Route path="/*" element={<OnlyGuestFormRoutes />} />;
      }
    }

    return (
      <Route element={<RootLayout />}>
        <Route index element={<HomeView />} />
        <Route path={PATHS.guestRegistration} element={<GuestRegistrationView />} />
        {addPersonalDataFormRoute.map((route, index) => {
          return <Route key={index} path={route} element={<AddPersonalDataForm />} />;
        })}
        <Route path={PATHS.deposits} element={<DepositView />}>
          <Route path=":id" element={<DepositView />} />
        </Route>
        <Route path={PATHS.payments.main} element={<Payments />}>
          <Route index path="*" element={<PaymentsView />} />
          <Route path={PATHS.payments.form} element={<PaymentFormView />} />
        </Route>
        <Route path={PATHS.travelInsurances.main} element={<TravelInsurances />}>
          <Route path={PATHS.travelInsurances.allianz.main} element={<Allianz />}>
            <Route
              path={PATHS.travelInsurances.allianz.selectPlan}
              element={<SetPlanView />}
            />
            <Route
              path={PATHS.travelInsurances.allianz.selectGuests}
              element={<AddDeals />}
            />
            <Route
              path={PATHS.travelInsurances.allianz.createGuest}
              element={<CreateGuest />}
            />
            <Route
              path={PATHS.travelInsurances.allianz.previousProductInformationNote}
              element={<PreviousProductInformationNote />}
            />
          </Route>
        </Route>
        <Route path={PATHS.taxes.main}>
          <Route index element={<TaxesTouristView />} />
          <Route path={PATHS.taxes.exemptions} element={<TaxesExemptionView />}>
            <Route path=":id" element={<TaxesExemptionView />} />
          </Route>
        </Route>

        {verificationTypesRoutes.map((route, index) => {
          return (
            <Route key={index} path={route} element={<IdentityVerification />}>
              <Route index element={<StartView />} />
              <Route
                path={PATHS.verification.documentSelection}
                element={<DocumentSelectionView />}
              />
              <Route
                path={PATHS.verification.uploadingType}
                element={<UploadingTypeView />}
              >
                <Route path={':type'} element={<UploadingTypeView />} />
              </Route>
              <Route path={PATHS.verification.front} element={<FrontScanView />}>
                <Route path={':uploadType'} element={<FrontScanView />} />
              </Route>
              <Route path={PATHS.verification.back} element={<BackScanView />}>
                <Route path={':uploadType'} element={<BackScanView />} />
              </Route>
              <Route path={PATHS.verification.selfie} element={<SelfieScanView />} />
              {/* <Route
                path="*"
                element={<Navigate to={`./../${PATHS.verification.main}`} replace />}
              /> */}
            </Route>
          );
        })}
        <Route path={PATHS.documentMRZ} element={<MRZView />}>
          <Route index element={<MRZStartView />} />
          <Route path={':uploadType'} element={<MRZScanView />} />
        </Route>
        <Route path={PATHS.remoteAccess} element={<RemoteAccessView />} />
        <Route path="*" element={<Navigate to={getFullPath(PATHS.home)} />} />
        <Route path={PATHS.faq} element={<FAQView />} />
        <Route path={PATHS.searchReservation.main}>
          <Route index element={<SearchReservation />} />
        </Route>
        <Route path={PATHS.searchReservation.bookingCode} element={<BookingCodeView />} />
        <Route
          path={PATHS.searchReservation.checkingDate}
          element={<CheckInDateView />}
        />
        <Route
          path={PATHS.searchReservation.newReservation}
          element={<NewReservationView />}
        />
        <Route path={PATHS.recommendations.main}>
          <Route index element={<OfferListView />} />
          <Route path={PATHS.recommendations.details.main}>
            <Route
              path={PATHS.recommendations.details.bookExperience}
              element={<BookExperienceView />}
            />
            <Route
              path={PATHS.recommendations.details.confirmEmail}
              element={<EnterContactEmailView />}
            />
            <Route index element={<OfferView />} />
          </Route>
        </Route>
      </Route>
    );
  }, [getFullPath]);

  if (!isTemplateInitialized) {
    return null;
  }

  return (
    <Routes>
      {IS_EMBEDDED_MODE ? routes : <Route path={PATHS.home}>{routes}</Route>}
    </Routes>
  );
}

export default AppRouter;
export {PATHS, addPersonalDataFormRoute};
