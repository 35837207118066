import styled, {css} from 'styled-components';
import {device} from 'styled/device';
import Button from 'components/common/Button/Button';
import {MultiRadio} from 'components/common/MultiRadio/MultiRadio';
import {MainLoader} from '../../components/common/loaders';

export const Wrapper = styled.div<{isLoaded?: boolean; isLoading?: boolean}>`
  display: block;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  ${props =>
    props.isLoaded &&
    css`
      opacity: 1;
    `}

  ${props =>
    props.isLoading &&
    css`
      opacity: 0.6;
      cursor: progress;
    `}
`;

export const PaymentTypeBox = styled.div<{isMultipleDeposits?: boolean}>`
  max-width: 416px;
  margin: 24px auto 0;

  ${props =>
    props.isMultipleDeposits &&
    css`
      margin-top: 16px;
    `};

  @media (min-width: ${device.tablet}) {
    margin-top: 24px;
  }
`;

export const TitleRadio = styled.div`
  margin: 0 auto 16px;
  font-style: normal;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: ${props => props.theme.colors.branding.fontPrimary};

  @media (min-width: ${device.tablet}) {
    font-size: 16px;
    margin-bottom: 26px;
  }
`;

export const ContainerRadioButtons = styled.div`
  margin: 0 auto 32px;

  @media (max-width: ${device.tablet}) {
    max-width: 630px;
  }
`;

export const MultiRadioStyled = styled(MultiRadio)`
  margin: 0 auto;
  justify-content: center;
  grid-row-gap: 16px;

  & .multi-radio__label {
    letter-spacing: 0;
    color: ${props =>
      props.theme.hasTemplate
        ? props.theme.colors.branding.fontPrimary
        : props.theme.colors.$p10};
    opacity: 1;
    font-size: 14px;
    font-weight: 400;

    @media (min-width: ${device.tablet}) {
      font-size: 16px;
    }
  }
`;

export const ConfirmButton = styled(Button)`
  width: 100%;
  margin: 48px auto 0;
`;

export const SuccessModalButton = styled(Button)`
  width: 100%;
`;

export const TitleModal = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.fontSecondary
      : props.theme.colors.$p10};
  margin: 0;
`;

export const ContentButtonModal = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin: 48px auto 28px;
  justify-content: center;
  align-items: center;
`;

export const StyledLoader = styled(MainLoader)`
  margin: 200px auto 0;
`;

export const SubTitleOption = styled.div`
  color: ${({theme}) => theme.colors.branding.fontPrimary};
  font-size: ${({theme}) => theme.fonts.sizes.medium};
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 16px;
`;
