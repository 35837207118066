import React from 'react';
import styled, {css} from 'styled-components';
import SVG, {Props as SVGProps} from 'react-inlinesvg';
import {transformSizePropToSize} from '../../utils/common';
import {BaseIconContainer} from './styled';
import questionCircle from '../../assets/icons/icon-question-circle.svg';
import whiteArrowRight from '../../assets/icons/arrowNext.svg';
import trash from '../../assets/icons/rubbish-bin.svg';
import camera from '../../assets/icons/camera.svg';
import picture from '../../assets/icons/picture-icon.svg';
import close from '../../assets/icons/close-icon.svg';
import padlock from '../../assets/icons/padlock.svg';
import backArrow from '../../assets/icons/back-arrow.svg';
import arrowRightBlue from '../../assets/icons/arrow-right-icon-blue.svg';
import thunder from '../../assets/icons/tunder_icon.svg';
import editIcon from '../../assets/icons/edit-icon.svg';
import questionCircleBlue from '../../assets/icons/question-icon.svg';
import warningSecondary from '../../assets/icons/warning-secondary.svg';
import checkSecondary from '../../assets/icons/check-secondary.svg';
import creditCardBlue from '../../assets/icons/credit-card-blue2.svg';
import checkOutCalendar from '../../assets/icons/check-out-calendar.svg';
import checkInCalendar from '../../assets/icons/check-in-calendar.svg';
import multiGuest from '../../assets/icons/multi-guest.svg';
import backBlueArrow from '../../assets/icons/back-blue-arrow.svg';
import information from '../../assets/icons/information.svg';
import plusBlueIcon from 'assets/icons/plus-blue-icon.svg';
import languageIcon from 'assets/icons/language-icon.svg';
import lockIcon from 'assets/icons/lock-icon.svg';
import homeSidebar from 'assets/icons/home-sidebar.svg';
import keySidebar from 'assets/icons/key-sidebar.svg';
import languageSidebar from 'assets/icons/language-sidebar.svg';
import locationSidebar from 'assets/icons/location-sidebar.svg';
import lockSidebar from 'assets/icons/lock-sidebar.svg';
import faqSidebar from 'assets/icons/faq-sidebar.svg';
import onlineChekinSidebar from 'assets/icons/online-chekin-sidebar.svg';
import checkFill from 'assets/icons/check-fill.svg';
import shoppingCart from 'assets/icons/shopping-cart.svg';
import blackMenuIcon from 'assets/icons/black-menu-icon.svg';
import closeIcon from 'assets/icons/close-black-icon.svg';
import allianzShieldIcon from 'assets/icons/allianz-shield-icon.svg';
import checkedBlack from 'assets/icons/checked.svg';
import informationGray from 'assets/icons/information-gray.svg';
import allianzBlockShieldIcon from 'assets/icons/allianz-block-shield-icon.svg';
import helpBlueIcon from 'assets/icons/help-icon.svg';
import threeDots from '../../assets/icons/three-dots.svg';
import allianzLogoMini from 'assets/icons/allianz-logo-mini.svg';
import allianzCwdIcon from '../../assets/icons/allianz-cwd-icon.svg';
import insuranceCwdIcon from '../../assets/icons/insurance-cwd.svg';
import insuranceCwdSmallIcon from '../../assets/icons/insurance-cwd-small.svg';
import remoteAccessBuilding from '../../assets/icons/remote-access-building.svg';
import remoteAccessApartment from '../../assets/icons/remote-access-apartment.svg';
import doubleArrow from '../../assets/icons/double-arrow.svg';
import warningErrorIcon from '../../assets/icons/warning-error-icon.svg';
import notFoundHouse from 'assets/img/not-found-house.svg';
import iconRegister from 'assets/icons/empty-state.svg';
import errorModalIcon from 'assets/icons/error.svg';
import dots from 'assets/icons/dots.svg';
import autoFillIcon from 'assets/icons/new-auto-filled-icon.svg';
import arrowLeft from 'assets/icons/arrow-left.svg';
import questionMarkIcon from 'assets/icons/question-blue-circle.svg';
import incompleteGuestIcon from 'assets/icons/guest-panel-incomplete-icon.svg';
import completeGuestIcon from 'assets/icons/guest-panel-complete-icon.svg';
import incompleteDepositIcon from 'assets/icons/deposit-incomplete.svg';
import completeDepositIcon from 'assets/icons/deposit-complete.svg';
import grayRightArrow from 'assets/icons/gray-right-arrow.svg';
import keyIconCircle from 'assets/icons/remote-access-key-icon-circle.svg';
import shareArrowIcon from 'assets/icons/share-arrow.svg';
import shoppingCartCircleIcon from 'assets/icons/shopping-car-cicrle.svg';
import arrowDates from 'assets/icons/arrow-dates.svg';
import statusSuccessIcon from 'assets/icons/status-success-icon.svg';
import imageIcon from 'assets/icons/image-icon.svg';
import blueTickIcon from 'assets/icons/credit-card-blue-tick.svg';
import redXIcon from 'assets/icons/credit-card-red-x.svg';
import copyLink from 'assets/icons/copy-link.svg';
import addItemIcon from 'assets/icons/add-item-icon.svg';

export type IconNames =
  | 'questionCircle'
  | 'whiteArrowRight'
  | 'trash'
  | 'camera'
  | 'picture'
  | 'close'
  | 'padlock'
  | 'backArrow'
  | 'arrowRightBlue'
  | 'editIcon'
  | 'thunder'
  | 'questionCircleBlue'
  | 'warningSecondary'
  | 'checkSecondary'
  | 'creditCardBlue'
  | 'checkOutCalendar'
  | 'checkInCalendar'
  | 'multiGuest'
  | 'backBlueArrow'
  | 'plusBlueIcon'
  | 'information'
  | 'languageIcon'
  | 'lockIcon'
  | 'homeSidebar'
  | 'keySidebar'
  | 'languageSidebar'
  | 'locationSidebar'
  | 'lockSidebar'
  | 'faqSidebar'
  | 'onlineChekinSidebar'
  | 'checkFill'
  | 'shoppingCart'
  | 'blackMenuIcon'
  | 'closeIcon'
  | 'allianzShieldIcon'
  | 'checkedBlack'
  | 'informationGray'
  | 'allianzBlockShieldIcon'
  | 'helpBlueIcon'
  | 'threeDots'
  | 'allianzLogoMini'
  | 'allianzCwdIcon'
  | 'insuranceCwdIcon'
  | 'insuranceCwdSmallIcon'
  | 'notFoundHouse'
  | 'iconRegister'
  | 'errorModalIcon'
  | 'dots'
  | 'autoFillIcon'
  | 'arrowLeft'
  | 'remoteAccessBuilding'
  | 'remoteAccessApartment'
  | 'doubleArrow'
  | 'warningErrorIcon'
  | 'questionMarkIcon'
  | 'incompleteGuestIcon'
  | 'completeGuestIcon'
  | 'incompleteDepositIcon'
  | 'completeDepositIcon'
  | 'grayRightArrow'
  | 'keyIconCircle'
  | 'shareArrowIcon'
  | 'shoppingCartCircleIcon'
  | 'arrowDates'
  | 'statusSuccessIcon'
  | 'imageIcon'
  | 'blueTickIcon'
  | 'redXIcon'
  | 'copyLink'
  | 'addItemIcon';

export type IconsHash = {
  [K in IconNames]: string;
};

const icons: IconsHash = {
  questionCircle,
  whiteArrowRight,
  trash,
  camera,
  picture,
  close,
  padlock,
  backArrow,
  arrowRightBlue,
  editIcon,
  thunder,
  questionCircleBlue,
  warningSecondary,
  checkSecondary,
  creditCardBlue,
  checkOutCalendar,
  checkInCalendar,
  multiGuest,
  backBlueArrow,
  information,
  plusBlueIcon,
  languageIcon,
  lockIcon,
  homeSidebar,
  keySidebar,
  languageSidebar,
  locationSidebar,
  lockSidebar,
  faqSidebar,
  onlineChekinSidebar,
  checkFill,
  shoppingCart,
  blackMenuIcon,
  closeIcon,
  allianzShieldIcon,
  checkedBlack,
  informationGray,
  allianzBlockShieldIcon,
  helpBlueIcon,
  threeDots,
  allianzLogoMini,
  allianzCwdIcon,
  insuranceCwdIcon,
  insuranceCwdSmallIcon,
  remoteAccessBuilding,
  remoteAccessApartment,
  doubleArrow,
  warningErrorIcon,
  notFoundHouse,
  iconRegister,
  errorModalIcon,
  dots,
  autoFillIcon,
  arrowLeft,
  questionMarkIcon,
  incompleteGuestIcon,
  completeGuestIcon,
  incompleteDepositIcon,
  completeDepositIcon,
  grayRightArrow,
  keyIconCircle,
  shareArrowIcon,
  shoppingCartCircleIcon,
  arrowDates,
  statusSuccessIcon,
  imageIcon,
  blueTickIcon,
  redXIcon,
  copyLink,
  addItemIcon,
};

export type IconProps = {
  name: IconNames;
  title?: string;
  className?: string;
  size?: string | number;
  color?: string;
  custom?: boolean;
} & React.HTMLAttributes<SVGElement> &
  React.HTMLAttributes<SVGProps>;

const StyledSVGIcon = styled(SVG)<{
  src: string;
  color?: string;
  size?: string | number;
  $custom?: string;
}>`
  width: ${props => transformSizePropToSize(props.size) || '24px'};
  height: ${props => transformSizePropToSize(props.size) || '24px'};
  fill: ${props => props.color || 'none'};
  color: ${props => props.color || 'none'};

  ${props => {
    return props.$custom
      ? css`
          .system-path {
            fill: ${props.color || props.theme.colors.branding.icons};
          }
          #path-group {
            path,
            rect {
              fill: ${props.color || props.theme.colors.branding.icons};
            }
          }
          // styles for No register Guest svg AND 3 dots menu icon svg
          #empty-state-register-guest-linear-1,
          #empty-state-register-guest-linear-2,
          #sigle-dot-icon-linear-1,
          #sigle-dot-icon-linear-2,
          #sigle-dot-icon-linear-3,
          .auto-fill-icon-linear,
          .linear-svg-group {
            .stop-one {
              stop-color: ${props.color || props.theme.colors.branding.icons};
            }

            .stop-two {
              stop-color: ${props.color || props.theme.colors.branding.icons};
              opacity: 0.3;
            }
          }
          // end styles for No register Guest svg
          // styles for Error modal svg
          .primary-svg-color {
            fill: ${props.color || props.theme.colors.branding.icons};
            stroke: ${props.color || props.theme.colors.branding.icons};
            opacity: 0.3;
          }
          .secondary-svg-color {
            fill: ${props.color || props.theme.colors.branding.icons};
            stroke: ${props.color || props.theme.colors.branding.icons};
          }
          //end styles for Error modal svg

          .circle-background {
            fill: ${props.theme.colors.branding.backgroundSecondary};
          }
        `
      : css`
          .system-path,
          path {
            fill: ${props.color || props.theme.colors.branding.icons};
          }
        `;
  }}
`;

const CustomIcon = (props: IconProps) => {
  return (
    <BaseIconContainer size={props.size} className="custom-icon">
      <StyledSVGIcon
        src={icons[props.name]}
        size={props.size}
        width={props.size}
        height={props.size}
        title={props.title || props.name}
        color={props.color || 'transparent'}
        className={'custom-icon__icon ' + props.className}
        $custom={props.custom ? 'true' : 'false'}
      />
    </BaseIconContainer>
  );
};

export {CustomIcon};
