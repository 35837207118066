import React from 'react';
import * as Sentry from '@sentry/react';
import {
  CartPayment,
  CoreError,
  CreateMultiPaymentsPayload,
  MULTI_PAYMENT_STATUSES,
  MULTI_PAYMENT_STEP_STATUSES,
  MULTI_PAYMENT_STEP_TYPES,
  MultiPayment,
  PAYMENT_PROVIDERS,
  RESERVATION_PAYMENT_TYPES,
  ReservationPayment,
  WS_EVENT_TYPES,
} from '@guestapp/sdk';
import {useTranslation} from 'react-i18next';
import {BillingDetails, StripeError} from '@stripe/stripe-js';
import fetcher from '../../utils/fetcher';
import {i18n} from '../../configs/i18n/i18n';
import {usePaymentSettings} from '../usePaymentSettings';
import {getErrorMessage, STATUSES, useStatus} from '@guestapp/core';
import {useChekinSDK} from '../../context/ChekinSDK';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useCheckExpiredPayments} from './useCheckExpiredPayments';
import {getSessionIdSelector} from '../../store/slices/session';
import {useAppSelector} from '../redux';
import {useStripeCardElement} from './useStripeCardElement';
import {useWebsocketListener} from '../useWebsocketListener';
import {UPSELLING_ORIGINS} from 'components/MyCart/types';
import {QUERY_CACHE_KEYS} from '../../utils/constants';
import {useEvent} from '../useEvent';
import {ContextProps} from '../../context/websocket';
import {useReservation} from '../../context/reservation';

const trimClearMessage = (message: string) => {
  if (message?.includes('Request')) {
    return message.substring(message.indexOf(':') + 2);
  }

  return message;
};

const RetryCheckManualPaymentStatusesMs = 2000;
const maxFallbackRetries = 20;

const getMultiPaymentPayload = (
  reservation_id = '',
  payments: CartPayment[],
  session_id?: string,
): CreateMultiPaymentsPayload => {
  const paymentsIds = payments
    .filter(singleItem => {
      return (
        ![
          RESERVATION_PAYMENT_TYPES.damageProtection,
          RESERVATION_PAYMENT_TYPES.securityDeposit,
        ].includes(singleItem.obj_type) &&
        singleItem.upselling_origin !== UPSELLING_ORIGINS.allianz
      );
    })
    .map(singlePayment => singlePayment.id);

  const allianzDealsIds = payments.find(
    singlePayment => singlePayment.upselling_origin === UPSELLING_ORIGINS.allianz,
  );
  const paymentsAndDealsPaymentsIds = allianzDealsIds
    ? [...allianzDealsIds.items_ids, ...paymentsIds]
    : paymentsIds;

  const hasSecurityDeposit = payments.some(
    singlePayment => singlePayment.obj_type === RESERVATION_PAYMENT_TYPES.securityDeposit,
  );
  const hasDamageProtection = payments.some(
    singlePayment =>
      singlePayment.obj_type === RESERVATION_PAYMENT_TYPES.damageProtection,
  );
  const hasTaxes = payments.some(
    singlePayment => singlePayment.obj_type === RESERVATION_PAYMENT_TYPES.touristTaxes,
  );

  if (hasSecurityDeposit && hasDamageProtection) {
    throw new Error(i18n.t('cannot_choose_both_deposits_error'));
  }

  return {
    session_id,
    reservation: reservation_id,
    requested_specification: {
      // is_ready_to_pay_for_all: false,
      requested_reservation_payment_ids: paymentsAndDealsPaymentsIds,
      is_deposit_payment_requested: hasSecurityDeposit,
      is_damage_protection_payment_requested: hasDamageProtection,
      is_taxes_payment_requested: hasTaxes,
    },
  };
};

type AdditionalFields = Partial<BillingDetails>;
type PayProps = {
  additionalData: AdditionalFields;
  payments: CartPayment[];
};

type UseMultiPaymentsProps = Pick<UsePaymentProps, 'onPaymentExpired'> & {
  onPaymentError: ErrorPaymentHandler;
  onPaymentCreated: (multiPayment: MultiPayment) => void;
};
function useMultiPayments({
  onPaymentError,
  onPaymentExpired,
  onPaymentCreated,
}: UseMultiPaymentsProps) {
  const {createMultiPayment} = useChekinSDK();
  const {reservationId} = useReservation();
  const sessionId = useAppSelector(getSessionIdSelector);

  const {mutate: mutateMultiPayment} = useMutation<
    MultiPayment,
    StripeError,
    CreateMultiPaymentsPayload
  >(createMultiPayment, {
    onError: e => onPaymentError(e),
    onSuccess: onPaymentCreated,
  });

  const {checkExpiredPayments} = useCheckExpiredPayments({
    onExpired: onPaymentExpired,
    onError: onPaymentError,
  });

  const createPayment = React.useCallback(
    async (payments: CartPayment[]) => {
      const payload = getMultiPaymentPayload(reservationId, payments, sessionId);
      const hasExpiredPayments = await fetcher(checkExpiredPayments);
      if (hasExpiredPayments) return;
      mutateMultiPayment(payload);
    },
    [checkExpiredPayments, mutateMultiPayment, reservationId, sessionId],
  );

  return {createPayment};
}

type ErrorPaymentHandler = (
  error: Error | CoreError | StripeError | string,
  multiPayment?: MultiPayment,
) => void;
export type UsePaymentProps = {
  onPaymentSuccess: (multiPayment: MultiPayment) => void;
  onPaymentError: (
    error: Error | CoreError | StripeError | string,
    errorMsg?: string,
    multiPayment?: MultiPayment,
  ) => void;
  onStripeSubmit?: (payment: MultiPayment) => void;
  onPaymentExpired?: (expiredPayments: ReservationPayment[]) => void;
};

type UsePaymentReturnType = {
  isLoading: boolean;
  pay: (details: PayProps) => void;
};

function usePayment(props: UsePaymentProps): UsePaymentReturnType {
  const {t} = useTranslation();
  const queryClient = useQueryClient();
  const onPaymentSuccess = useEvent(props.onPaymentSuccess);
  const onPaymentError = useEvent(props.onPaymentError);
  const onPaymentExpired = useEvent(props.onPaymentExpired);
  const onStripeSubmit = useEvent(props.onStripeSubmit);
  const additionalDataRef = React.useRef<AdditionalFields>();
  const fallbackCalledTimes = React.useRef(0);
  const isSubmitDisabledRef = React.useRef<boolean>(false);
  const {isLoading, setStatus} = useStatus();
  const {getMultiPayment} = useChekinSDK();
  const {paymentProvider} = usePaymentSettings();

  const {setListeningHandlers, start, reset} = useWebsocketListener({
    time: RetryCheckManualPaymentStatusesMs,
    autoReset: false,
    once: false,
  });

  const handleSuccess = React.useCallback(
    async (multiPayment: MultiPayment) => {
      additionalDataRef.current = undefined;
      await queryClient.refetchQueries([QUERY_CACHE_KEYS.paymentsCart]);
      void queryClient.refetchQueries([QUERY_CACHE_KEYS.summary]);
      void queryClient.invalidateQueries([QUERY_CACHE_KEYS.reservation]);
      void queryClient.invalidateQueries([QUERY_CACHE_KEYS.insuranceSetup]);
      void queryClient.invalidateQueries([QUERY_CACHE_KEYS.orderHistory]);

      setStatus(STATUSES.success);
      onPaymentSuccess(multiPayment);
    },
    [onPaymentSuccess, queryClient, setStatus],
  );

  const handlePaymentExpired = async (expiredPayments: ReservationPayment[]) => {
    setStatus(STATUSES.idle);
    await queryClient.refetchQueries([QUERY_CACHE_KEYS.paymentsCart]);
    void queryClient.refetchQueries([QUERY_CACHE_KEYS.summary]);
    onPaymentExpired?.(expiredPayments);
  };

  const handlePaymentError = React.useCallback(
    async (
      error: StripeError | Error | CoreError | string,
      multiPayment?: MultiPayment,
    ) => {
      reset();
      const defaultErrorMessage = t('bank_authentication_process_has_failed');
      await queryClient.refetchQueries([QUERY_CACHE_KEYS.paymentsCart]);
      void queryClient.invalidateQueries([QUERY_CACHE_KEYS.insuranceSetup]);
      // Sentry.captureMessage(
      //   error.message || t('bank_authentication_process_has_failed'),
      //   {
      //     user: {error},
      //   },
      // );
      const errorMessage = getErrorMessage(error);
      onPaymentError(
        error || defaultErrorMessage,
        errorMessage || defaultErrorMessage,
        multiPayment,
      );

      setStatus(STATUSES.idle);
    },
    [onPaymentError, queryClient, reset, setStatus, t],
  );

  const {confirmPaymentIntent} = useStripeCardElement(handlePaymentError);

  const listenMultiPaymentWSEvents = React.useCallback(
    (
      paymentId: string,
      statusesHandler: (
        multiPayment: MultiPayment | null,
        error?: Error | StripeError,
      ) => void,
    ) => {
      setListeningHandlers(
        {
          [WS_EVENT_TYPES.multiPaymentUpdated]: (ws: ContextProps<MultiPayment>) => {
            const instanceId = ws?.message?.instance_id;

            if (!paymentId || paymentId !== instanceId) {
              return;
            }

            const eventMsg = ws.message;
            statusesHandler(eventMsg);
          },
        },
        async () => {
          if (paymentId) {
            try {
              const data = await fetcher(getMultiPayment, paymentId);
              fallbackCalledTimes.current = fallbackCalledTimes.current + 1;
              statusesHandler(data);
            } catch (error) {
              Sentry.captureException(error);
              statusesHandler(null, error as Error);
            }
          }
        },
      );

      start();
    },
    [getMultiPayment, setListeningHandlers, start],
  );

  const handleWsStatuses = React.useCallback(
    async (multiPayment: MultiPayment | null, error?: Error | StripeError) => {
      if (error) {
        void handlePaymentError(error);
        reset();
        return;
      }
      if (!multiPayment) {
        return;
      }

      if (fallbackCalledTimes.current > maxFallbackRetries) {
        reset();
        void handlePaymentError(t('number_of_calls_limited'));
        return;
      }

      switch (multiPayment.status) {
        case MULTI_PAYMENT_STATUSES.complete:
          void handleSuccess(multiPayment);
          reset();
          return;
        case MULTI_PAYMENT_STATUSES.failed:
          const statusDetails =
            multiPayment.current_step?.status === MULTI_PAYMENT_STEP_STATUSES.error
              ? trimClearMessage(multiPayment.current_step?.status_details)
              : multiPayment.status_details;
          void handlePaymentError(statusDetails, multiPayment);
          reset();
          return;
      }

      if (multiPayment.current_step.status === MULTI_PAYMENT_STEP_STATUSES.dataRequired) {
        const foundStepWithClientSecret = multiPayment.steps.find(
          step => step.type === MULTI_PAYMENT_STEP_TYPES.paymentSetupInit,
        );
        const clientSecret = foundStepWithClientSecret?.metadata.external_client_secret;
        if (clientSecret) {
          reset();
          const intent = await confirmPaymentIntent(
            clientSecret,
            additionalDataRef.current,
          );
          if (intent?.error) {
            return;
          }

          listenMultiPaymentWSEvents(multiPayment.id, handleWsStatuses);
          return;
        }
      }
    },
    [
      confirmPaymentIntent,
      handlePaymentError,
      listenMultiPaymentWSEvents,
      handleSuccess,
      reset,
      t,
    ],
  );

  const handlePaymentCreated = React.useCallback(
    (payment: MultiPayment) => {
      const paymentId = payment?.id;
      if (!paymentId) return;
      listenMultiPaymentWSEvents(paymentId, handleWsStatuses);
      onStripeSubmit?.(payment);
      // Waiting for WS_EVENT_TYPES.multiPayment or results of the pulling
    },
    [handleWsStatuses, listenMultiPaymentWSEvents, onStripeSubmit],
  );

  const {createPayment} = useMultiPayments({
    onPaymentError: handlePaymentError,
    onPaymentExpired: handlePaymentExpired,
    onPaymentCreated: handlePaymentCreated,
  });

  const handleStripeSubmit = React.useCallback(
    async ({additionalData, payments}: PayProps) => {
      fallbackCalledTimes.current = 0;
      setStatus(STATUSES.loading);
      additionalDataRef.current = additionalData;
      void createPayment(payments);
      // Next handlePaymentCreated
    },
    [createPayment, setStatus],
  );

  const singleton = React.useCallback(
    (wrapper: (props: PayProps) => void) => (props: PayProps) => {
      if (isSubmitDisabledRef.current) return;
      isSubmitDisabledRef.current = true;
      wrapper(props);
      isSubmitDisabledRef.current = false;
    },
    [],
  );

  const providersEventHandlersMapping = React.useMemo(
    () => ({
      [PAYMENT_PROVIDERS.stripe]: singleton(handleStripeSubmit),
      [PAYMENT_PROVIDERS.paycomet]: singleton(() => {}),
    }),
    [handleStripeSubmit, singleton],
  );

  const pay = React.useCallback(
    (data: PayProps) => providersEventHandlersMapping[paymentProvider](data),
    [paymentProvider, providersEventHandlersMapping],
  );

  return {isLoading, pay};
}

export {usePayment};
