import {apiClient, FetcherSettings} from './apiClient';

class ValidatorApi {
  ENDPOINTS = {
    phoneNumber: () => `api/v3/validators/phone-number`,
  };

  async validatePhoneNumber(payload: {phone: string}, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.post(this.ENDPOINTS.phoneNumber(), {
      json: payload,
      ...fetcherSettings,
    });
    return response.body;
  }
}

export {ValidatorApi};
