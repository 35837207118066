import {createGlobalStyle} from 'styled-components';
import {environments} from '../configs';
import bg from 'assets/img/world-map-bg.png';
import Fonts from './fonts';

const injectFonts = () => {
  if (environments.REACT_APP_IS_EMBEDDED) {
    return Fonts;
  }
};

const GlobalStyle = createGlobalStyle<{fontFaces?: string}>`
  ${injectFonts}
  ${props => props.fontFaces || ''}

  html,
  body,
  #root {
    /* margin: 0; */
    height: 100%;
    font-family: ${props => props.theme.fonts.fontFamily.body.font_name}, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    box-sizing: border-box;
    background-color: ${props => props.theme.colors.branding.backgroundMain};
    color: #152946;
    margin: 0;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  a {
    text-decoration: none;
  }

  button {
    font-family: inherit;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  input {
    color: inherit;
  }

  .active-nav-link div {
    color: #23262F;
    font-weight: 600;
  }

  .active-nav-link div::before {
    content: '';
    position: absolute;
    width: 100%;
    display: block;
    border-bottom: 2px solid #23262F;
    bottom: -4px;
  }

  .disabled-swipe {
    overscroll-behavior: contain;
    overflow: hidden !important;
  }

  .summary-background {
    background-image: url(${bg});
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
  }

  .popup-arrow  {
    position: absolute !important;
    width: 17px !important;
    left: 3px !important;
  }
`;

export {GlobalStyle};
