import {FieldError} from '@guestapp/sdk';
import {ReactElement, ReactNode} from 'react';

export type Country = {
  name: string;
  code: string;
  alpha_3: string;
};

export type ErrorType =
  | {
      message: string;
      errors: FieldError[];
      details: string;
    }
  | string;

export type GlobalLocationTypes = {
  // THIS THIPES IdentityLocationStateType & LocationStateTaxes & WHERE REMOVE FRO THE OLD GUESAPP

  formData: Record<string, string>;
  number_of_guests?: number;
  children?: number;
  ocrWasUsed?: boolean;
  backSideScan: string;
  frontSideScan?: string;
  flowLink?: string;
  customFormId?: string;
  signature?: string;
  biomatch?: {
    biomatch_passed: boolean;
    biomatch_doc: string;
    biomatch_selfie: string;
  };
};

export type LocationType = {
  country: {
    code: string;
    name: string;
  };
};

export enum IVSteps {
  select = 'select',
  upload = 'upload',
  selfie = 'selfie',
}

export type ErrorModalProps = {
  onClose?: () => void;
  open?: boolean;
  message?: string;
  children?: ReactNode | ReactElement | string | null;
  iconSrc?: string;
  iconAlt?: string;
  title?: string | ReactNode;
  description?: string | ReactNode;
  iconProps?: {width: number; height: number};
};

export type DatepickerDateType = Date | number | null | undefined;
export type InputEventType = React.ChangeEvent<HTMLInputElement>;
export type valueof<T> = T[keyof T];

export type SessionDetails = {
  id: string;
  reservationId: string;
  email?: string;
  full_name?: string;
};

export type LottieAnimation = {
  v: string;
};

// export type ErrorApiResponseField = {
//   code: string;
//   field: string;
//   message: string;
// };

// export type ErrorApiResponse = {
//   code: string;
//   message: string;
//   status_code: number;
//   errors: ErrorApiResponseField[];
// };

//THIS TWO LAST TYPES MUST BE THE STANDAR IN RESPONSE IN ERROR CASES FOR V4 API
