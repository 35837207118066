import React, {ReactNode} from 'react';
import {CustomIcon, IconNames} from '@guestapp/ui';
import {
  BodyContainer,
  HeaderImg,
  HeaderText,
  HeaderWrapper,
  IconBadge,
  ItemContainer,
  SideBarContainer,
  Skeleton,
} from './styled';

export type SideBarProps = {
  children?: React.ReactElement[];
};

function SideBar({children}: SideBarProps) {
  return (
    <SideBarContainer className="sidebar">
      <Children>{children}</Children>
    </SideBarContainer>
  );
}

type HeaderProps = {
  children: React.ReactElement;
  show: boolean;
  onlyLogo?: boolean;
  imgSrc?: string;
  isLoading?: boolean;
};
const Header = ({children, imgSrc, show, isLoading, onlyLogo}: HeaderProps) => {
  if (!show) return null;
  return (
    <HeaderWrapper className="sidebar__header">
      {isLoading ? (
        <Skeleton className="sidebar__skeleton" />
      ) : (
        <>
          {imgSrc && (
            <HeaderImg
              src={imgSrc}
              height={48}
              big={onlyLogo}
              className="sidebar__header-img"
            />
          )}
          <HeaderText isImageHeader={Boolean(imgSrc)} className="sidebar__header-text">
            {children}
          </HeaderText>
        </>
      )}
    </HeaderWrapper>
  );
};

const Body = ({children}: {children: React.ReactElement[]}) => {
  return (
    <BodyContainer className="sidebar__body">
      <Children>{children}</Children>
    </BodyContainer>
  );
};

type ItemProps = {
  children: ReactNode;
  isActive: boolean;
  isLoading: boolean;
  icon: IconNames;
  onClick?: () => void;
  bagdeData?: string;
};
const Item = ({children, isActive, icon, onClick, isLoading, bagdeData}: ItemProps) => {
  const iconColor = isActive ? '#385BF8' : '#152946';
  return (
    <ItemContainer
      isActive={isActive}
      onClick={onClick}
      className="sidebar__item"
      isLoading={isLoading}
    >
      {isLoading ? (
        <Skeleton className="sidebar__skeleton" />
      ) : (
        <>
          <div className="sidebar__icon">
            <CustomIcon size={24} name={icon} color={iconColor} />
            {bagdeData && (
              <IconBadge className="sidebar__icon-badge">{bagdeData}</IconBadge>
            )}
          </div>

          {children}
        </>
      )}
    </ItemContainer>
  );
};

type ContainerElementsProps = {
  children: JSX.Element[];
  footer?: boolean;
};
const ContainerElements = ({children, footer}: ContainerElementsProps) => {
  const mainClassName = footer ? 'sidebar__footer' : 'sidebar__items-list';

  return (
    <div className={mainClassName}>
      <Children>{children}</Children>
    </div>
  );
};

const Children = ({children}: {children?: JSX.Element[]}) => {
  if (children && children.length === 0) {
    return null;
  }
  return (
    <>
      {children &&
        React.Children.map(children, child => {
          return React.cloneElement(child);
        })}
    </>
  );
};

SideBar.Header = Header;
SideBar.Body = Body;
SideBar.Items = ContainerElements;
SideBar.Item = Item;
export {SideBar};
