import React from 'react';
import {useParams} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {useSummary} from '../../../../context/summary';
import {
  useDocumentSelfieDetection,
  useModalControls,
  useScreenResize,
} from '../../../../hooks';
import {IV_DOCUMENT_IMAGE_TYPES} from '../../../../utils/constants';
import frontSidePlaceholder from '../../../../assets/img/frontside-placeholder.svg';
import PhotoBox from '../../../../components/PhotoBox';
import MRZPageLayout from '../../MRZPageLayout';
import passportGif from 'assets/img/ocr-passport-info.gif';
import {HelperText, QuestionButton, ModalContent} from './styled';
import ModalSheet from 'components/common/ModalSheet';
import StandardModalSheet from 'components/common/StandardModalSheet';
import {ButtonProps} from 'components/common/Button/Button';
import {device} from 'styled/device';

function MRZScanView() {
  const {t} = useTranslation();
  const theme = useTheme();
  const {uploadType} = useParams();
  const {reservationId, countryCode} = useSummary();
  const {isMatch: isMobileMode} = useScreenResize(device.mobileXL);
  const {ProcessModal, ErrorModal, RetryModal, sendAndReadImageForOCR} =
    useDocumentSelfieDetection({
      docType: IV_DOCUMENT_IMAGE_TYPES.back,
    });

  const isUpload = uploadType === 'upload';
  const mobilePageTitle = isUpload && isMobileMode ? 'guest_data' : 'scan_your_document';
  const {
    isOpen: isOpenNeedHelpModal,
    openModal: openNeedHelpModal,
    closeModal: closeNeedHelpModal,
  } = useModalControls();

  const modalSheetButtons: ButtonProps[] = React.useMemo(() => {
    const primaryButton = {
      label: t('understood'),
      primary: true,
      onClick: closeNeedHelpModal,
    };
    return isMobileMode ? [] : [primaryButton];
  }, [t, closeNeedHelpModal, isMobileMode]);

  const handleChangePhoto = React.useCallback(
    (image: string) => {
      if (countryCode) {
        sendAndReadImageForOCR(image, countryCode, reservationId);
      }
    },
    [countryCode, reservationId, sendAndReadImageForOCR],
  );

  const modalContent = (
    <ModalContent>
      <div>
        <Trans i18nKey="tool_tip_mrz_text_one">
          Make sure the photo of your document has enough quality to detect the{' '}
          <b>MRZ code</b> of the document.
        </Trans>
      </div>
      <div>{t('tool_tip_mrz_text_two')}</div>
    </ModalContent>
  );

  return (
    <MRZPageLayout title={t(mobilePageTitle)}>
      <HelperText className="mrz-scan-view__helper">
        {t('upload_mrz_helper_text')}
      </HelperText>
      <QuestionButton
        iconProps={{
          position: 'left',
          color: theme.colors.buttons.textButton,
          size: 16,
        }}
        iconName="questionCircleBlue"
        // customIcon={<img width={16} height={16} src={questionIcon} alt="Need help?" />}
        label={t('need_help_question')}
        onClick={openNeedHelpModal}
        className="mrz-scan-view__question-btn"
        link
      />
      <PhotoBox
        mobileHelperTitle={t('scan_your_document')}
        mobileHelperText={t('code_mrz_helper_text')}
        onUploadPhoto={handleChangePhoto}
        preloadedScreenshot={''}
        isUpload={isUpload}
        placeholderImage={frontSidePlaceholder}
      />
      <ProcessModal />
      <ErrorModal />
      <RetryModal />

      <ModalSheet
        isOpenModalSheet={isOpenNeedHelpModal}
        handleCloseModal={closeNeedHelpModal}
      >
        <StandardModalSheet
          buttons={modalSheetButtons}
          text={modalContent}
          img={passportGif}
        />
      </ModalSheet>
    </MRZPageLayout>
  );
}

export {MRZScanView};
