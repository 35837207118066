import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type BookExperienceSliceState = {
  offerQuantity: {
    [key in string]: number;
  };
  email: string;
};

type OfferPriceItemQuantity = {
  quantity: number;
  offerId: string;
};

const initialState: BookExperienceSliceState = {
  offerQuantity: {},
  email: '',
};

const BookExperienceSlice = createSlice({
  name: 'BookExperienceSlice',
  initialState,
  reducers: {
    setBookExperienceEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setBookExperienceQuantity(state, action: PayloadAction<OfferPriceItemQuantity>) {
      state.offerQuantity[action.payload.offerId] = action.payload.quantity;
    },
    resetBookExperienceQuantity(state, action: PayloadAction<string>) {
      state.offerQuantity[action.payload] = 0;
    },
  },
});

export {BookExperienceSlice};
export const bookExperienceActions = BookExperienceSlice.actions;
export const bookExperienceReducer = BookExperienceSlice.reducer;
