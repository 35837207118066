import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {PATHS} from '../../Routes';
import {IV_DOCUMENT_IMAGE_TYPES, STATUSES} from '../../utils/constants';
import {StatusModalStates} from '../StatusModal';
import {ModalProps} from '../Modal';
import passportGif from 'assets/img/passport.gif';
import selfieGif from 'assets/img/selfie.gif';
import {FailedModal} from './FailedModal';
import {StyledModal} from './styled';

const IconProps = {width: 50, height: 50};

const StatusesDTO: {[key in STATUSES]: StatusModalStates} = {
  [STATUSES.success]: StatusModalStates.SUCCESS,
  [STATUSES.loading]: StatusModalStates.LOADING,
  [STATUSES.idle]: StatusModalStates.IDLE,
  [STATUSES.error]: StatusModalStates.IDLE,
};

type IVProgressModalProps = ModalProps & {
  state: STATUSES;
  onTryAgain: () => void;
  reasons?: string[];
  errorMessage?: string;
  docType?: IV_DOCUMENT_IMAGE_TYPES;
};
function IVProcessModal({
  open,
  state,
  reasons,
  errorMessage,
  onTryAgain,
  onClose,
  docType,
}: IVProgressModalProps) {
  const {t} = useTranslation();
  const {pathname} = useLocation();
  // const isBackView = pathname.includes(PATHS.verification.back);
  const isSelfie = pathname.includes(PATHS.verification.selfie);
  // const isMRZ = pathname.includes(PATHS.documentMRZ);

  const failedModalImage = isSelfie ? selfieGif : passportGif;

  const getTitle = () => {
    switch (state) {
      case STATUSES.success:
        return docType === IV_DOCUMENT_IMAGE_TYPES.selfie
          ? t('identity_verified_exclamation')
          : t('document_scanned_exclamation');
      default:
        return docType === IV_DOCUMENT_IMAGE_TYPES.selfie
          ? `${t('verification_in_progress')}`
          : `${t('scanning')}...`;
    }
  };

  const getDescription = () => {
    switch (state) {
      case STATUSES.loading:
        return t('this_could_take_a_couple_of_seconds');
      default:
        return '';
    }
  };

  if (state === STATUSES.error) {
    return (
      <FailedModal
        open={open}
        reasons={reasons}
        title={errorMessage}
        onTryAgain={onTryAgain}
        onClose={onClose}
        image={failedModalImage}
      />
    );
  }

  return (
    <StyledModal
      open={open}
      title={getTitle()}
      text={getDescription()}
      state={StatusesDTO[state]}
      iconProps={IconProps}
    />
  );
}

export {IVProcessModal};
