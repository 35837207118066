import {useState, useCallback, useMemo, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {FormProvider, useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {StripeError} from '@stripe/stripe-js';
import {useTimeoutRef} from '@guestapp/core';
import {usePath, usePayment, usePaymentsCart, usePaymentSettings} from '../../hooks';
import {checkIfTotalAmountToPayZero} from '../../utils/payments';
import {PATHS} from 'Routes';
import {StatusModalStates} from '../StatusModal';
import {CoreError, PAYMENT_PROVIDERS} from '@guestapp/sdk';
import visaIcon from 'assets/icons/visa-icon.svg';
import mastercardIcon from 'assets/icons/mastercard-icon.svg';
import ProcessingModal from './ProcessingModal';
import SafeAndSecurePaymentInfo from '../common/SafeAndSecurePaymentInfo';
import {StripePaymentForm} from './StripePaymentForm';
import {Container, Form, Header, Hr, Price, StyledButton, TotalBox} from './styled';

const SuccessModalTimeoutMs = 2800;

enum FORM_NAMES {
  holderName = 'name',
}

type FormTypes = {
  [FORM_NAMES.holderName]: string;
};

const ProviderForms = {
  [PAYMENT_PROVIDERS.stripe]: StripePaymentForm,
  [PAYMENT_PROVIDERS.paycomet]: () => <></>,
};

function PaymentForm() {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const {getFullPath} = usePath();
  const [processState, setProcessState] = useState(StatusModalStates.IDLE);
  const [isFormReady, setIsFormReady] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [error, setError] = useState<string>();
  const successModalTimeoutRef = useTimeoutRef();
  const {paymentProvider} = usePaymentSettings();
  const methods = useForm<FormTypes>({mode: 'onTouched'});
  const {handleSubmit} = methods;
  const {currencySymbol} = usePaymentSettings();

  const {paymentsCart, isPaymentsCartLoading} = usePaymentsCart({
    defaultDeferredPayments: location.state?.pay_later_payments,
  });
  const payments = paymentsCart?.pay_now_payments;
  const totalAmountToPay = paymentsCart?.total_amount_to_pay;
  const isPaymentsAmountZero = checkIfTotalAmountToPayZero(paymentsCart);
  const isPayProcessDisabled =
    !isFormReady ||
    !isFormComplete ||
    !payments?.length ||
    isPaymentsAmountZero ||
    processState !== StatusModalStates.IDLE;

  const closeProcessingModal = useCallback(() => {
    setProcessState(StatusModalStates.IDLE);
    setError('');
  }, []);

  const onPaymentSuccess = () => {
    setProcessState(StatusModalStates.SUCCESS);

    successModalTimeoutRef.current = setTimeout(() => {
      closeProcessingModal();
      navigate(getFullPath(PATHS.payments.ordersHistory));
    }, SuccessModalTimeoutMs);
  };

  const onPaymentError = (
    _: string | Error | CoreError | StripeError,
    errorMsg?: string,
  ) => {
    setError(errorMsg);
    setProcessState(StatusModalStates.ERROR);
  };

  const {pay} = usePayment({
    onPaymentExpired: closeProcessingModal,
    onPaymentSuccess,
    onPaymentError,
  });

  const ProviderForm = useMemo(() => {
    return ProviderForms[paymentProvider];
  }, [paymentProvider]);

  const onSubmit = (data: FormTypes) => {
    if (isPayProcessDisabled) {
      return;
    }
    setProcessState(StatusModalStates.LOADING);
    pay({additionalData: data, payments});
  };

  useEffect(() => {
    if (!location.state?.payments?.length) {
      navigate(getFullPath(PATHS.payments.main));
    }
  }, [getFullPath, location.state?.payments?.length, navigate]);

  return (
    <Container className="payment-form">
      <Header className="payment-form__header">
        <img width={40} height={24} src={mastercardIcon} alt="" />
        <img width={40} height={24} src={visaIcon} alt="" />
        <span>{t('credit_or_debit_card')}</span>
      </Header>
      <FormProvider {...methods}>
        <Form className="payment-form__form">
          <ProviderForm
            onFormReady={() => setIsFormReady(true)}
            onFormComplete={setIsFormComplete}
          />
        </Form>
      </FormProvider>
      <Hr />
      <TotalBox className="payment-form__total-box">
        <span>{t('total_payment')}</span>
        <Price isLoaded={!isPaymentsCartLoading} className="payment-form__price">
          {totalAmountToPay ?? 0}
          {currencySymbol}
        </Price>
      </TotalBox>
      <SafeAndSecurePaymentInfo />

      <StyledButton
        label={t('confirm')}
        disabled={isPayProcessDisabled}
        onClick={handleSubmit(onSubmit)}
      />
      <ProcessingModal
        isOpen={processState !== StatusModalStates.IDLE}
        close={closeProcessingModal}
        state={processState}
        error={error}
      />
    </Container>
  );
}

export {PaymentForm, FORM_NAMES};
export type {FormTypes};
