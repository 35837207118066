import React from 'react';
import {Control, useWatch} from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EmptyDetectionProps = {name: string; control: Control<any>};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withEmptyDetection = <TElement, TProps extends Record<string, any>>(
  Component: React.ElementType,
) =>
  React.forwardRef<TElement, TProps & EmptyDetectionProps>(
    (props: TProps & EmptyDetectionProps, ref) => {
      const value = useWatch({
        name: props.name,
        control: props.control,
      });

      const isEmpty = typeof props.empty === 'undefined' ? !value : props.empty;

      return <Component empty={isEmpty ?? props.readOnly} {...props} ref={ref} />;
    },
  );

export {withEmptyDetection};
