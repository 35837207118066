import styled from 'styled-components';
import {device} from '@guestapp/ui/src/utils/device';
import {Link} from 'react-router-dom';

export const Container = styled(Link)`
  display: flex;
  position: relative;
  flex-basis: 192px;
  border-radius: 16px;
  box-shadow: 0px 3px 6px 0px #00000012;
  background: ${props => props.theme.colors.branding.boxesModals};

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
    height: unset;
  }
`;

export const Image = styled.img`
  width: 224px;
  max-width: 40%;
  border-radius: 16px 0 0 16px;
  object-fit: cover;
  aspect-ratio: 1.167;

  @media (max-width: ${device.tablet}) {
    height: 138px;
    width: 100%;
    border-radius: 16px 16px 0 0;
    max-width: unset;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  flex-grow: 1;
  color: ${({theme}) => theme.colors.branding.fontPrimary};
  gap: 16px;
  min-width: 0;

  @media (max-width: ${device.tablet}) {
    padding: 16px;
    gap: 8px;
  }
`;

export const Header = styled.div`
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${device.tablet}) {
    font-size: 18px;
    font-weight: 600;
    gap: 8px;
  }
`;

export const Price = styled.div`
  font-size: 20px;

  @media (max-width: ${device.tablet}) {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  gap: 16px;
  font-size: 14px;
`;

export const TagWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
`;

export const Title = styled.div`
  max-width: 100%;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const Highlight = styled.div`
  text-overflow: ellipsis;
  text-wrap: nowrap;

  @media (max-width: ${device.tablet}) {
    display: -webkit-box;
    text-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  word-wrap: break-word;
  overflow: hidden;
`;
