import styled, {css} from 'styled-components';
import {device} from 'styled/device';

type MenuProps = {
  show: boolean;
};

export const MenuContainer = styled.div``;
export const MenuItems = styled.ul<MenuProps>`
  right: -100%;
  position: fixed;
  top: 0;
  height: 100vh;
  background: ${props => props.theme.colors.navigationMenu.backgroundMenu};
  box-shadow: -10px 4px 24px rgb(0 0 0 / 10%);
  margin-top: 0;
  list-style: none;
  padding: 24px;
  width: 100%;
  transition: all 0.5s ease;
  z-index: 301;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  ${props =>
    props.show &&
    css`
      right: 0;
    `};

  @media (min-width: ${device.tablet}) {
    right: 0;
    position: absolute;
    width: 260px;
    top: 40px;
    -webkit-transition: none;
    transition: none;
    display: none;
    border-radius: 15px;
    padding: 16px 40px 16px 40px;
    height: auto;
    ${props =>
      props.show &&
      css`
        display: block;
      `}
  }
`;

export const MenuIconButton = styled.button`
  all: unset;
  display: block;
  transition: opacity 0.1s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  path {
    fill: transparent;
  }

  rect {
    fill: ${props => props.theme.colors.navigationMenu.menuItemsText};
  }
`;

export const MenuFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: ${device.tablet}) {
    display: none;
  }
`;

export const IconBrand = styled.img`
  margin-bottom: 22px;
  width: 60%;
`;
export const PoweredByImg = styled.img``;
export const MenuBurgerIcon = styled.img`
  cursor: pointer;
`;

export const CloseIconButton = styled.button<{showLogo?: boolean}>`
  all: unset;
  position: absolute;
  z-index: 2;
  top: 63px;
  right: 24px;
  cursor: pointer;
  transition: opacity 0.1s;
  padding: 6px;

  &:hover {
    opacity: 0.8;
  }

  ${props =>
    props.showLogo &&
    css`
      top: 75px;
    `}

  path {
    fill: ${props => props.theme.colors.navigationMenu.menuItemsText};
    stroke: ${props => props.theme.colors.navigationMenu.menuItemsText};
  }
`;
