import {useQuery} from '@tanstack/react-query';
import {HousingLocation} from '@guestapp/sdk';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {useChekinSDK} from 'context/ChekinSDK';

function useFetchHousingLocation({housingLocation}: {housingLocation?: string}) {
  const {getLocation} = useChekinSDK();
  return useQuery<HousingLocation, Error>(
    [QUERY_CACHE_KEYS.housingLocation],
    () => getLocation({externalURL: housingLocation}),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(housingLocation),
    },
  );
}

export {useFetchHousingLocation};
