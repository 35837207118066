import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {ModalSheet} from '../../../../components/common/ModalSheet/ModalSheet';
import {ContentText, PrivacyButton} from './styled';

type TaxesInformationModalProps = {
  isOpen: boolean;
  close: () => void;
};
const TaxesInformationModal = memo(({isOpen, close}: TaxesInformationModalProps) => {
  const {t} = useTranslation();

  return (
    <ModalSheet
      isOpenModalSheet={isOpen}
      handleCloseModal={close}
      title={t('tourist_taxes')}
      className="tax-info-modal"
      withCloseButton
    >
      <ContentText className="tax-info-modal__content">
        <p>{t('please_be_advised_that_in_this_territory')}</p>
        <p>{t('thank_you_for_your_understanding')}</p>

        <PrivacyButton
          label={t('view_privacy_policy')}
          to={t('privacy_policy_link')}
          openNewTab
          link
        />
      </ContentText>
    </ModalSheet>
  );
});

export {TaxesInformationModal};
