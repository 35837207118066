import {apiClient, FetcherSettings} from './apiClient';
import {InsuranceSetup} from '../utils';
import {dynamicRoute} from './utils';

class PropertyProtectionApi {
  ENDPOINTS = {
    getPropertyProtectionsByReservation: dynamicRoute({
      regular: (reservationId: string) =>
        `api/v3/insurance/property-protection-for-reservation/${reservationId}/`,
      sdk: (reservationId: string) =>
        `sdk/api/v1/insurance/property-protection-for-reservation/${reservationId}/`,
    }),
    insuranceSetup: dynamicRoute({
      regular: (id = '') => `api/v4/insurance-setup/${id ? id + '/' : ''}`,
      sdk: (id = '') => `sdk/api/v2/insurance-setup/${id ? id + '/' : ''}`,
    }),
  };

  async getPropertyProtectionsByReservation<T>(
    reservationId: string,
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient.get<T>(
      this.ENDPOINTS.getPropertyProtectionsByReservation(reservationId),
      fetcherSettings,
    );
    return response.body;
  }

  async mutateInsuranceSetup<T>(
    {id, ...payload}: Partial<InsuranceSetup>,
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient[id ? 'patch' : 'post']<T>(
      this.ENDPOINTS.insuranceSetup(id),
      {json: payload, ...fetcherSettings},
    );
    return response.body;
  }

  async getInsuranceSetup<T>(id: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<T>(
      this.ENDPOINTS.insuranceSetup(id),
      fetcherSettings,
    );

    return response.body;
  }
}

export {PropertyProtectionApi};
