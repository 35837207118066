import i18next from 'i18next';
import debounce from 'awesome-debounce-promise';
import {getPhoneNumberString, PhoneDetails, ValidatorApi} from '@guestapp/sdk';

const QueryDebounceMS = 500;

const validatePhone = async (phoneNumber: string) => {
  try {
    if (!phoneNumber || !phoneNumber.trim()) return true;

    await new ValidatorApi().validatePhoneNumber(
      {
        phone: phoneNumber,
      },
      {errorRetryLimit: 0},
    );

    return true;
  } catch (error) {
    return 'invalid_phone_number';
  }
};

const cache = {} as Record<string, string | boolean>;
const debouncedValidatePhone = debounce(async (phoneNumber: string) => {
  const result = await validatePhone(phoneNumber);
  cache[phoneNumber] = result;
  return typeof result === 'string' ? i18next.t<string>(result) : result;
}, QueryDebounceMS);

const Validators = {
  phoneValidateService: (value?: PhoneDetails | string | null) => {
    const phoneString = getPhoneNumberString(value);
    if (!phoneString || !phoneString.trim()) return;
    if (cache[phoneString]) {
      const cachedResult = cache[phoneString];
      return typeof cachedResult === 'string'
        ? i18next.t<string>(cachedResult)
        : cachedResult;
    }
    return debouncedValidatePhone(phoneString);
  },
};

export {Validators};
