import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useEvent, useIsMounted} from '../hooks';
import {QUERY_CACHE_KEYS} from '../utils/constants';
import {useChekinSDK} from '../context/ChekinSDK';

type UseGuestMutation = {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
  onSettled?: () => void;
};
function useDeleteGuest(props: UseGuestMutation = {}) {
  const {deleteGuest} = useChekinSDK();
  const isMounted = useIsMounted();
  const queryClient = useQueryClient();
  const onSuccess = useEvent(props.onSuccess);
  const onError = useEvent(props.onError);
  const onSettled = useEvent(props.onSettled);

  return useMutation<unknown, Error, string>(deleteGuest, {
    onSuccess: async () => {
      onSuccess();
      void queryClient.refetchQueries([QUERY_CACHE_KEYS.reservation]);
      void queryClient.refetchQueries([QUERY_CACHE_KEYS.summary]);
      void queryClient.resetQueries([QUERY_CACHE_KEYS.formFields]);
      void queryClient.refetchQueries([QUERY_CACHE_KEYS.guestGroup]);
    },
    onError: error => {
      if (!isMounted) return;
      onError(error);
    },
    onSettled,
  });
}

export {useDeleteGuest};
