import {Container, HeaderTitle, HeaderWrapper, LogoContainer, Title} from '../styled';
import usePropertyLinkInfo from 'components/PageSideBarUniversalLink/usePropertyLinkInfo';

function Navbar() {
  //TODO refactor this whole implementation implementing this component on a higher level
  const {logo, title} = usePropertyLinkInfo();

  return (
    <HeaderWrapper visible className="navbar">
      <Container>
        <LogoContainer className="navbar__logo">
          {logo && <img src={logo} alt="housing Logo" className="navbar__logo-img" />}
        </LogoContainer>
        <HeaderTitle className="navbar__title">
          <Title className="navbar__title-text">{title}</Title>
        </HeaderTitle>
      </Container>
    </HeaderWrapper>
  );
}

export {Navbar};
