import React from 'react';
import {useModalControls} from 'hooks';
import ModalSheet from '../ModalSheet';

type HousingLongNameModalProps = {
  name: string;
};

export type HousingLongNameModalPropsRef = {
  open: () => void;
};

const HousingLongNameModal = React.forwardRef<
  HousingLongNameModalPropsRef,
  HousingLongNameModalProps
>(({name}, ref) => {
  const {isOpen, closeModal, openModal} = useModalControls();

  React.useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  return (
    <ModalSheet isOpenModalSheet={isOpen} handleCloseModal={closeModal}>
      {name}
    </ModalSheet>
  );
});

export {HousingLongNameModal};
