import {ReactElement} from 'react';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Wrapper, NotFoundIcon, Title, SecondText, Text} from './styled';

type ErrorViewProps = {
  title?: string;
  text?: string | ReactElement;
  secondText?: string;
};
function ErrorView({title, text, secondText}: Readonly<ErrorViewProps>) {
  const {t} = useTranslation();
  const theme = useTheme();
  return (
    <Wrapper className="error-view">
      <NotFoundIcon
        name={'notFoundHouse'}
        color={theme.colors.branding.primary}
        size={100}
        custom
      />
      <Title className="error-view__title">{title ?? t('link_not_valid')}</Title>
      <Text className="error-view__text">{text ?? t('not_found_has_missing')}</Text>
      <SecondText className="error-view__second-text">
        {secondText ?? t('not_found_make_sure')}
      </SecondText>
    </Wrapper>
  );
}

export {ErrorView};
