import {AuthApi} from '../api';
import {persistTokenToLocalStorage} from '../utils';
import {InitParams, OldShortLink, ShortLink} from '../utils/types';
import {FetcherSettings} from '../api/apiClient';

function isShortLink(data?: OldShortLink | ShortLink): data is ShortLink {
  return Boolean((data as ShortLink)?.instance_id);
}

class AuthService {
  authApi: AuthApi;

  constructor() {
    this.authApi = new AuthApi();
  }

  getAuthToken(
    payload: {apikey: string},
    resolverSettings?: FetcherSettings,
  ): Promise<{
    [key: string]: string;
  }> {
    return this.authApi.getAuthToken(payload, resolverSettings);
  }

  async init(params: InitParams): Promise<string>;
  async init(params: InitParams, resolverSettings?: FetcherSettings): Promise<string> {
    try {
      const payload = {
        apikey: params.apikey,
        resource_id: params.resourceId,
        external_resource_id: params.externalResourceId,
        resource_type: params.resourceType,
      };
      const {access_token: data} = await this.getAuthToken(payload, resolverSettings);

      if (data) {
        persistTokenToLocalStorage(data);
      }
      return data;
    } catch (error) {
      throw error;
    }
  }

  async initChekin(token: string, resolverSettings?: FetcherSettings) {
    try {
      const {body: data, headers} = await this.authApi.getChekinToken(
        {token},
        resolverSettings,
      );
      const headerToken = headers.get('Authorization');

      if (data) {
        persistTokenToLocalStorage(isShortLink(data) ? headerToken : data.user);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
}

export {AuthService};
