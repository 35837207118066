import {useState, useEffect} from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
} from '@stripe/react-stripe-js';
import {useEvent} from '../../hooks';

type UseStripeFieldsStateProps = {
  onFormReady?: () => void;
  onFormComplete?: (state: boolean) => void;
};
function useStripeFieldsState(props: UseStripeFieldsStateProps = {}) {
  const elements = useElements();
  const [isCVCComplete, setIsCVCComplete] = useState(false);
  const [isExpiryComplete, setIsExpiryComplete] = useState(false);
  const [isNumberComplete, setIsNumberComplete] = useState(false);
  const [isCVCReady, setIsCVCReady] = useState(false);
  const [isExpiryReady, setIsExpiryReady] = useState(false);
  const [isNumberReady, setIsNumberReady] = useState(false);
  const isStripeFormComplete = isCVCComplete && isExpiryComplete && isNumberComplete;
  const isStripeFormReady = isCVCReady && isExpiryReady && isNumberReady;
  const {onFormReady, onFormComplete} = props;
  const formReadyHandler = useEvent(onFormReady);
  const formCompleteHandler = useEvent(onFormComplete);

  useEffect(() => {
    const eventName = 'change';
    const cardNumberElement = elements?.getElement(CardNumberElement);
    const cardCVCElement = elements?.getElement(CardCvcElement);
    const cardExpiryElement = elements?.getElement(CardExpiryElement);

    cardNumberElement?.on(eventName, state => {
      setIsNumberComplete(state.complete);
    });
    cardCVCElement?.on(eventName, state => {
      setIsCVCComplete(state.complete);
    });
    cardExpiryElement?.on(eventName, state => {
      setIsExpiryComplete(state.complete);
    });

    return () => {
      elements?.getElement(CardNumberElement)?.off(eventName);
      elements?.getElement(CardCvcElement)?.off(eventName);
      elements?.getElement(CardExpiryElement)?.off(eventName);
    };
  }, [elements]);

  useEffect(() => {
    const eventName = 'ready';
    const cardNumberElement = elements?.getElement(CardNumberElement);
    const cardCVCElement = elements?.getElement(CardCvcElement);
    const cardExpiryElement = elements?.getElement(CardExpiryElement);

    cardNumberElement?.on(eventName, () => {
      setIsNumberReady(true);
    });
    cardCVCElement?.on(eventName, () => {
      setIsCVCReady(true);
    });
    cardExpiryElement?.on(eventName, () => {
      setIsExpiryReady(true);
    });

    return () => {
      elements?.getElement(CardNumberElement)?.off(eventName);
      elements?.getElement(CardCvcElement)?.off(eventName);
      elements?.getElement(CardExpiryElement)?.off(eventName);
    };
  }, [elements]);

  useEffect(
    function handleOnStripeFormReady() {
      if (isStripeFormReady && formReadyHandler) {
        formReadyHandler();
      }
    },
    [isStripeFormReady, formReadyHandler],
  );

  useEffect(
    function handleOnStripeFormComplete() {
      if (isStripeFormComplete && formCompleteHandler) {
        formCompleteHandler(isStripeFormComplete);
      }
    },
    [formCompleteHandler, isStripeFormComplete],
  );

  return {isStripeFormComplete, isStripeFormReady};
}

export {useStripeFieldsState};
