import React from 'react';
import {useTranslation} from 'react-i18next';
import {components, GroupBase, MenuListProps} from 'react-select';
import {SelectOptionType} from '@guestapp/sdk';
import {MenuItemLoadingMessage} from './styled';

const isArray = (children: React.ReactNode): children is [] => {
  return !!(children && typeof children === 'object' && 'length' in children);
};

const MenuList = <T, V, L>(
  props: MenuListProps<
    SelectOptionType<T, V, L>,
    false,
    GroupBase<SelectOptionType<T, V, L>>
  > & {
    children: React.ReactNode;
  },
) => {
  const {t} = useTranslation();
  const {
    children,
    selectProps: {trimFilterBy, isLoading},
  } = props;

  const getFilteredChildren = () => {
    if (isArray(children) && trimFilterBy) {
      return children?.slice(0, trimFilterBy);
    } else {
      return children;
    }
  };

  const filteredChildren = getFilteredChildren();

  if (isLoading) {
    return (
      <MenuItemLoadingMessage className="select__loading-message">
        {t('loading')}...
      </MenuItemLoadingMessage>
    );
  }
  return <components.MenuList {...props} children={filteredChildren} />;
};

export {MenuList};
