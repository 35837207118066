import React from 'react';
import {ThreeDots} from 'react-loader-spinner';
import clsx from 'clsx';
import {Wrapper} from './styled';

type LoaderProps = {
  height?: number;
  width?: number;
  color?: string;
  label?: string;
  strokeWidth?: string;
  className?: string;
};

function Loader({
  height = 30,
  width = 30,
  color = 'rgb(194, 194, 194)',
  label = '',
  className,
  ...props
}: LoaderProps) {
  const classes = clsx(className, 'loader');

  if (label) {
    return (
      <Wrapper className={classes}>
        <ThreeDots color={color} height={height} width={width} {...props} />
        <div className="loader-label">{label}</div>
      </Wrapper>
    );
  }

  return (
    <ThreeDots
      wrapperClass={classes}
      color={color}
      height={height}
      width={width}
      {...props}
    />
  );
}
export {Loader};
