import {createFilter} from 'react-select';
import {SelectOptionType} from '@guestapp/sdk';
// eslint-disable-next-line import/no-unresolved
import {FilterOptionOption} from 'react-select/dist/declarations/src/filters';

const customFilter = <T, V, L>(
  option: FilterOptionOption<SelectOptionType<T, V, L>>,
  inputValue: string,
) => {
  const filter = createFilter<SelectOptionType<T, V, L>>({
    ignoreAccents: true,
    ignoreCase: true,
    matchFrom: 'any',
  });
  return filter(option, inputValue);
};

export {customFilter};
