import React from 'react';
import {useTranslation} from 'react-i18next';
import {ActionMeta} from 'react-select';
import {useLockScrollBody, useModalWithHistoryControls} from '../../../hooks';
import {SelectRefType} from '../Select';
import {SearchingSelectProps} from './SearchingSelect';
import {SelectOptionType} from '@guestapp/sdk';
import StyledIcon from '../StyledIcon';
import {QueriedInfiniteScrollSelect} from '../QueriedInfiniteScrollSelect';
import {IconWrapper, MobileSelectModal} from '../Select/styled';

const ListSizes = {
  height: 400,
  itemSize: 46,
};

const MobileSearchingSelectInternal = <T, V, L>(
  {trigger, onChange, ...props}: SearchingSelectProps<T, V, L>,
  ref: React.ForwardedRef<SelectRefType<T, V, L>>,
) => {
  const {t} = useTranslation();
  const {isOpen, closeModal, toggleModal} = useModalWithHistoryControls({
    key: props.name || '',
  });
  useLockScrollBody(isOpen);

  const handleChange = (
    option: SelectOptionType<T, V, L> | null,
    info: ActionMeta<SelectOptionType<T, V, L>>,
  ) => {
    closeModal();
    onChange?.(option, info);
  };

  return (
    <>
      {trigger?.(isOpen, toggleModal)}
      <MobileSelectModal
        visible={isOpen}
        onClose={closeModal}
        mode="mobile-slide"
        pressToTheTop
      >
        {isOpen && (
          <QueriedInfiniteScrollSelect
            ref={ref}
            menuIsOpen
            isMenuOpen
            isSearchable
            mobileVersion
            listAutoSizer
            forcedSearchMode
            defaultMenuIsOpen
            label={null}
            components={{LoadingIndicator: undefined, DropdownIndicator: null}}
            noOptionsMessage={() => t('no_matches_found')}
            listSizes={ListSizes}
            onChange={handleChange}
            minSearchQueryLength={0}
            controlShouldRenderValue={false}
            backspaceRemovesValue={false}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            tabSelectsValue={false}
            isClearable={false}
            leftIcon={
              <IconWrapper>
                <StyledIcon size={16} color={'#9F9FBF'} name={'magnifierGray'} />
              </IconWrapper>
            }
            {...props}
          />
        )}
      </MobileSelectModal>
    </>
  );
};

const MobileSearchingSelect = React.forwardRef(MobileSearchingSelectInternal) as <
  T,
  V,
  L,
>(
  props: SearchingSelectProps<T, V, L> & {
    ref?: React.ForwardedRef<SelectRefType<T, V, L>>;
  },
) => ReturnType<typeof MobileSearchingSelectInternal>;

export {MobileSearchingSelect};
