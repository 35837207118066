import React from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useTimeoutRef} from '@guestapp/core';
import {
  CalculateGuestTaxPayload,
  GuestsTaxes,
  GuestTaxExemption,
  Housing,
} from '@guestapp/sdk';
import {QUERY_CACHE_KEYS} from '../../utils/constants';
import {useSummary} from '../../context/summary';
import {useReservation} from '../../context/reservation';
import {useChekinSDK} from '../../context/ChekinSDK';
import {useIsMounted} from '../common';

const CACHE_TIME = 60;
const STALE_TIME = 30;

function getSeasonsIds(housing?: Housing) {
  if (!housing) {
    return [];
  }

  return housing?.seasons || [];
}

function useFetchSeason() {
  const {getSeason, isTokenValid} = useChekinSDK();
  const {housing} = useReservation();
  const highSeasonId = getSeasonsIds(housing)[0];

  const queryData = useQuery(
    [QUERY_CACHE_KEYS.season, highSeasonId],
    () => getSeason(highSeasonId),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * CACHE_TIME,
      staleTime: 1000 * 60 * STALE_TIME,
      enabled: Boolean(isTokenValid && highSeasonId),
    },
  );

  const isLoadingSeason =
    queryData.isFetching ||
    Boolean(queryData.isLoading && !housing?.id) ||
    Boolean(queryData.isLoading && highSeasonId);

  return {queryData, isLoadingSeason};
}

function useInitGuestTaxes() {
  const {initializeGuestTaxes} = useChekinSDK();
  const {reservationId} = useReservation();
  const queryClient = useQueryClient();

  const {mutate} = useMutation<GuestsTaxes, Error, {reservation_id: string}>(
    initializeGuestTaxes,
    {
      onSuccess: data => {
        queryClient.setQueryData([QUERY_CACHE_KEYS.seasonGuests], [data]);
        void queryClient.refetchQueries([QUERY_CACHE_KEYS.paymentsCart]);
      },
    },
  );

  const initGuestTaxes = React.useCallback(
    (data: GuestsTaxes) => {
      if (data?.id || !reservationId) return;
      return mutate({reservation_id: reservationId});
    },
    [mutate, reservationId],
  );

  useFetchGuestsTaxes({onSuccess: initGuestTaxes});

  return {initGuestTaxes};
}

type UseFetchGuestsTaxesProps = {
  onSuccess?: (data: GuestsTaxes) => void;
  onError?: (error: Error) => void;
  disabled?: boolean;
};
function useFetchGuestsTaxes({onSuccess, onError}: UseFetchGuestsTaxesProps = {}) {
  const {getSeasonGuests, isTokenValid} = useChekinSDK();
  const {data: reservation} = useReservation();
  const isMounted = useIsMounted();
  const {data} = useSummary();
  const reservationId = reservation?.id;

  return useQuery<GuestsTaxes[], Error, GuestsTaxes>(
    [QUERY_CACHE_KEYS.seasonGuests],
    () => getSeasonGuests(`reservation_id=${reservationId}`),
    {
      select: data => data?.[0],
      enabled: Boolean(data?.taxes?.active && isTokenValid && reservationId),
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * CACHE_TIME,
      staleTime: 1000 * 60 * STALE_TIME,
      onSuccess,
      onError: error => {
        if (!isMounted.current) {
          return;
        }

        if (error && onError) {
          onError(error);
          return null;
        }
      },
    },
  );
}

type MutateOptions = {
  successDelay?: number;
};

type UseMutateGuestTaxProps = {
  guestsTaxesId?: string;
  onError?: (error: Error) => void;
};
function useMutateGuestTax({guestsTaxesId, onError}: UseMutateGuestTaxProps) {
  const {calculateGuestTax} = useChekinSDK();
  const {data: reservation} = useReservation();
  const queryClient = useQueryClient();
  const isMounted = useIsMounted();
  const delayTimeoutRef = useTimeoutRef();

  const {mutate: guestTaxesMutate, isLoading} = useMutation<
    GuestsTaxes,
    Error,
    CalculateGuestTaxPayload & {options?: MutateOptions},
    MutateOptions
  >(({options, ...payload}) => calculateGuestTax(payload), {
    onMutate: ({options}) => {
      return options;
    },
    onSuccess: (data, _, context) => {
      if (context?.successDelay) {
        delayTimeoutRef.current = setTimeout(() => {
          queryClient.setQueryData([QUERY_CACHE_KEYS.seasonGuests], [data]);
        }, context?.successDelay);
        return;
      }
      queryClient.setQueryData([QUERY_CACHE_KEYS.seasonGuests], [data]);
    },
    onError: error => {
      if (!isMounted.current) {
        return;
      }

      if (error && onError) {
        onError(error);
      }
    },
  });

  const getTaxCalculationPayload = React.useCallback(
    (guestList: GuestTaxExemption[]) => {
      return {
        reservation_id: reservation?.id || '',
        guest_list: guestList,
      };
    },
    [reservation?.id],
  );

  const mutateGuestTax = React.useCallback(
    async (
      guestList: GuestTaxExemption[],
      {onSuccess, successDelay}: {onSuccess?: () => void; successDelay?: number},
    ) => {
      const payload = getTaxCalculationPayload(guestList);

      if (guestsTaxesId) {
        guestTaxesMutate(
          {...payload, id: guestsTaxesId, options: {successDelay}},
          {
            onSuccess,
          },
        );
      } else {
        guestTaxesMutate(
          {...payload, options: {successDelay}},
          {
            onSuccess,
          },
        );
      }

      // if (result.data?.amount === 0) {
      //   markReservationAsPaid();
      //   return;
      // }

      // await refreshReservation();
      // await refreshGuestsTaxesList();

      // setStatus(STATUSES.idle);

      // if (!result.data?.amount) {
      //   onError(t('calced_price_not_found'));
      //   return;
      // }
      //
      // if (result?.data?.amount !== Number(totalPrice.toFixed(2))) {
      //   onError(t('calced_price_is_incorrect'));
      //   return;
      // }

      // onSuccess?.();
    },
    [getTaxCalculationPayload, guestTaxesMutate, guestsTaxesId],
  );

  const confirmTaxesSetup = React.useCallback(
    (options?: Parameters<typeof mutateGuestTax>[1]) => {
      if (!reservation?.id) return;
      guestTaxesMutate(
        {status: 'COMPLETE', id: guestsTaxesId, reservation_id: reservation.id},
        {
          ...options,
          onSuccess: () => {
            void queryClient.invalidateQueries([QUERY_CACHE_KEYS.paymentsCart]);
            void queryClient.invalidateQueries([QUERY_CACHE_KEYS.summary]);
            options?.onSuccess?.();
          },
        },
      );
    },
    [guestTaxesMutate, guestsTaxesId, queryClient, reservation?.id],
  );

  return {mutateGuestTax, confirmTaxesSetup, isLoading};
}

export {useFetchSeason, useFetchGuestsTaxes, useMutateGuestTax, useInitGuestTaxes};
export type {MutateOptions};
