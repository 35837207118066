import React from 'react';
import ReactDOM from 'react-dom';
import {createGlobalStyle} from 'styled-components';

type Props = {
  target: HTMLElement;
  children: React.ReactNode;
  stylesLink?: string;
  styles?: string;
};

const GlobalStyle = createGlobalStyle<{customStyles?: string}>`
  ${({customStyles}) => customStyles}
`;

function InjectingCustomStylesProvider({
  children,
  styles,
  stylesLink,
  target,
}: Props): React.ReactElement {
  return (
    <React.Fragment>
      {stylesLink &&
        ReactDOM.createPortal(
          <link type="text/css" rel="stylesheet" href={stylesLink} />,
          target,
        )}
      {styles && <GlobalStyle customStyles={styles} />}
      {children}
    </React.Fragment>
  );
}

export {InjectingCustomStylesProvider};
