import React from 'react';
import {usePath} from 'hooks';

export const BACK_TO_OFFER_LIST = 'backToOfferList';

type Props = {
  backToOfferList?: boolean;
};
export const usePathToOfferView = ({backToOfferList}: Props = {}) => {
  const {getFullPath} = usePath();
  const getFullPathToOfferView = React.useCallback(
    (id: string) =>
      getFullPath(
        `recommendations/${id}${backToOfferList ? `?${BACK_TO_OFFER_LIST}=true` : ''}`,
      ),
    [getFullPath, backToOfferList],
  );
  return {getFullPathToOfferView};
};
