import styled, {css} from 'styled-components';
import {device} from '../../utils/device';
import {NavigationStatus} from './BoxInfo';

type WrapperProps = {
  status: NavigationStatus;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 16px;
  /* justify-content: space-between; */
  margin-bottom: 8px;
  /* height: 63px; */
  gap: 9px;
  ${props =>
    props.status === NavigationStatus.success &&
    css`
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.9)
        ),
        #32d7be;
      border: 1.5px solid #32d7be;
      color: #32d7be;
    `}

  ${props =>
    props.status === NavigationStatus.warning &&
    css`
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.9)
        ),
        #ffb27d;

      border: 1.5px solid #ffb27d;
      color: #ffb27d;
    `}

      ${props =>
    props.status === NavigationStatus.information &&
    css`
      background: none;
      border: 1.5px solid
        ${props =>
          props.theme.hasTemplate
            ? props.theme.colors.branding.fontSecondary
            : '#777E91'};
      color: ${props => props.theme.colors.branding.fontSecondary};

      path,
      circle {
        stroke: ${props => props.theme.colors.branding.fontSecondary};
      }
    `}
  
    @media (min-width: ${device.tablet}) {
    width: 327px;
    height: 63px;
    margin-bottom: 16px;
  }

  @media (min-width: ${device.laptop}) {
    width: 327px;
    height: 63px;
    margin: 0 auto 24px;
  }
`;

export const Message = styled.div`
  font-family: ${props => props.theme.fonts.fontFamily.body.font_name};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
`;
