const RESERVATION_ID_KEY = 'chekin-reservation-id';
const EXTERNAL_ID_KEY = 'chekin-external-id';
const RESERVATION_DETAILS = 'chekin-can-edit-reservation-details';
const EMBEDDED_STYLES = 'embedded-chekin-styles';
const EMBEDDED_STYLES_LINK = 'embedded-chekin-styles-link';
const CHEKIN_ROOT_IFRAME_ID = 'chekin-root-iframe';

enum EMBEDDED_MODE {
  all = 'ALL',
  onlyGuestForm = 'ONLY_GUEST_FORM',
}

export {
  RESERVATION_ID_KEY,
  EXTERNAL_ID_KEY,
  RESERVATION_DETAILS,
  EMBEDDED_STYLES,
  EMBEDDED_STYLES_LINK,
  CHEKIN_ROOT_IFRAME_ID,
  EMBEDDED_MODE,
};
