import React from 'react';
import i18n from 'i18next';
import {useTranslation} from 'react-i18next';
import {useModalControls} from 'hooks';
import {preloadAndSetDayJsLocale, LANGUAGE_CODES} from '@guestapp/core';
import ModalSheet from 'components/common/ModalSheet';
import {LanguageItemButton, List, Title} from './styled';

export type LanguageItem = {
  value: string;
  label: string;
  country: string;
};

const languageOptions: LanguageItem[] = [
  {
    value: LANGUAGE_CODES.en,
    label: 'English',
    country: 'United States',
  },
  {
    value: LANGUAGE_CODES.es,
    label: 'Español',
    country: 'Spain',
  },
  {
    value: LANGUAGE_CODES.it,
    label: 'Italiano',
    country: 'Italy',
  },
  {
    value: LANGUAGE_CODES.de,
    label: 'Deutsch',
    country: 'Germany',
  },
  {
    value: LANGUAGE_CODES.fr,
    label: 'Français',
    country: 'France',
  },
  {
    value: LANGUAGE_CODES.hu,
    label: 'Magyar',
    country: 'Magyar',
  },
  {
    value: LANGUAGE_CODES.ru,
    label: 'Русский',
    country: 'Russia',
  },
  {
    value: LANGUAGE_CODES.cs,
    label: 'Čeština',
    country: 'Čeština',
  },
  {
    value: LANGUAGE_CODES.bg,
    label: 'Български',
    country: 'Български',
  },
  {
    value: LANGUAGE_CODES.pt,
    label: 'Português',
    country: 'Português',
  },
  {
    value: LANGUAGE_CODES.ro,
    label: 'Română',
    country: 'Română',
  },
  {
    value: LANGUAGE_CODES.et,
    label: 'Eestlane',
    country: 'Eestlane',
  },
  {
    value: LANGUAGE_CODES.ca,
    label: 'Català',
    country: 'Catalunya',
  },
  {
    value: LANGUAGE_CODES.pl,
    label: 'Polski',
    country: 'Polska',
  },
  {
    value: LANGUAGE_CODES.el,
    label: 'Ελληνικά',
    country: 'Greece',
  },
  {
    value: LANGUAGE_CODES.uk,
    label: 'Українська',
    country: 'Ukraine',
  },
];

export type LanguageSelectionRef = {
  open: () => void;
};

const LanguageSelection = React.forwardRef<LanguageSelectionRef, unknown>((_, ref) => {
  const {t} = useTranslation();
  const {
    isOpen: isOpenLanguageSelection,
    closeModal: closeLanguageSelection,
    openModal: openLanguageSelection,
  } = useModalControls();

  const [activeLanguageOption, setActiveLanguageOption] = React.useState(
    languageOptions[0],
  );

  React.useLayoutEffect(function updateLanguageOption() {
    const nextOption = languageOptions.find(option => {
      return option.value === i18n.language.slice(0, 2);
    });
    if (nextOption) {
      setActiveLanguageOption(nextOption);
    }
  }, []);

  const changeLanguage = React.useCallback(
    async (language: LanguageItem) => {
      setActiveLanguageOption(language);
      await preloadAndSetDayJsLocale(language.value);
      void i18n.changeLanguage(language.value);
      closeLanguageSelection();
    },
    [setActiveLanguageOption, closeLanguageSelection],
  );

  React.useImperativeHandle(
    ref,
    () => {
      return {
        open: openLanguageSelection,
      };
    },
    [openLanguageSelection],
  );

  return (
    <ModalSheet
      isOpenModalSheet={isOpenLanguageSelection}
      handleCloseModal={closeLanguageSelection}
      // title={t('select_your_language')}
      closeOnDocumentClick
      className="language-modal"
      withCloseButton
    >
      <Title className="language-modal__title">{t('select_your_language')}</Title>
      <List className="language-modal__list">
        {languageOptions.map((x, index) => (
          <LanguageItemButton
            selected={activeLanguageOption.value === x.value}
            key={index}
            onClick={() => changeLanguage(x)}
            className="language-modal__option"
          >
            <div>{x.label}</div>
          </LanguageItemButton>
        ))}
      </List>
    </ModalSheet>
  );
});

export {LanguageSelection};
