import styled from 'styled-components';
import Button from 'components/common/Button';

export const ConfirmForm = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({theme}) => theme.colors.branding.fontPrimary};
  width: 100%;
  gap: 16px;
`;

export const MobileActionPanel = styled.div`
  display: flex;
  color: ${({theme}) => theme.colors.branding.fontPrimary};
  gap: 16px;
  background: ${props => props.theme.colors.branding.boxesModals};
  box-shadow: 0 2px 10px rgba(147, 150, 174, 0.25);
  padding: 24px;
  align-items: center;
`;

export const ConfirmButton = styled(Button)`
  min-width: unset;
  flex-grow: 1;
  flex-basis: 0;
`;
