import {useCallback, useRef} from 'react';
import {UseFormGetValues, UseFormReset} from 'react-hook-form';
import {EXTRA_FORM_NAMES, FORM_NAMES, FormTypes} from '../../utils/guestForm';
import {useReservation} from '../../context/reservation';
import {PhoneDetails} from '@guestapp/sdk';

function buildPhoneNumberString(phoneNumber?: PhoneDetails) {
  if (!phoneNumber?.number || !phoneNumber?.code) {
    return undefined;
  }

  return `${phoneNumber.code}${phoneNumber.number}`;
}

type UsePreloadPoliceDataProps = {
  reset: UseFormReset<FormTypes>;
  getValues: UseFormGetValues<FormTypes>;
  disabled?: boolean;
};
function usePreloadPoliceData({getValues, disabled, reset}: UsePreloadPoliceDataProps) {
  const isPreloaded = useRef(false);
  const {housing} = useReservation();
  const builtPhoneNumberString = buildPhoneNumberString(
    housing?.police_account_phone_details,
  );
  const preloadPoliceData = useCallback(
    function () {
      if (disabled || isPreloaded.current || !housing?.police_account_phone_details) {
        return;
      }

      const formData = {
        [FORM_NAMES.second_phone]: builtPhoneNumberString,
        [EXTRA_FORM_NAMES.secondPhoneDetails]: housing?.police_account_phone_details,
      };

      reset({...getValues(), ...formData});
      isPreloaded.current = true;
    },
    [
      getValues,
      reset,
      disabled,
      housing?.police_account_phone_details,
      builtPhoneNumberString,
    ],
  );

  return {preloadPoliceData, builtPhoneNumberString};
}

export {usePreloadPoliceData};
