import {
  DEAL_STATUSES,
  ShortReservation,
  ShortGuest,
  Offer,
  OFFER_CONFIRMATION_TYPES,
} from '@guestapp/sdk';
import {
  getBookExperienceEmail,
  getBookExperienceQuantity,
} from 'store/slices/bookExperience';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {
  useActions,
  useAppSelector,
  useGuestGroup,
  useFetchOffer,
  useCreateBulkDealMutation,
  usePath,
} from 'hooks';
import {useReservation} from 'context/reservation';
import {PATHS} from 'Routes';
import {BACK_TO_OFFER_LIST} from 'components/upselling/usePathToOfferView';

type BuildPayloadProps = {
  reservation: ShortReservation;
  offer: Offer;
  email?: string;
  coreGuest?: ShortGuest;
  quantity: number;
};

const buildPayload = ({
  reservation,
  email,
  offer,
  coreGuest,
  quantity,
}: BuildPayloadProps) => {
  const requestedItemId = offer.items[0].id;

  return {
    deals: [
      {
        core_housing_id: reservation?.housing_id,
        core_reservation_id: reservation?.id,
        core_guest_name: coreGuest?.full_name,
        core_housing_name: reservation.housing_name,
        guest_email: email,
        core_guest_id: coreGuest?.id,
        offer_id: offer.id,
        offerName: offer.title,
        quantity: 1,
        number_of_people: quantity,
        requested_items: [
          {
            item: requestedItemId,
            quantity,
          },
        ],
        status:
          offer.confirmation_type === OFFER_CONFIRMATION_TYPES.auto
            ? DEAL_STATUSES.approved
            : DEAL_STATUSES.requested,
      },
    ],
  };
};

type Props = {
  offerId?: string;
  onSuccess?: () => void;
  onError?: (errors: Error[]) => void;
};
export const useBookDeal = ({offerId, onSuccess, onError}: Props) => {
  const [searchParams] = useSearchParams();
  const bookExperienceEmail = useAppSelector(getBookExperienceEmail);
  const {data: reservation, isLoading: isLoadingReservation} = useReservation();
  const {data: offer, isLoading: isLoadingOffer} = useFetchOffer({id: offerId || ''});
  const {guestGroup} = useGuestGroup();
  const quantity = useAppSelector(getBookExperienceQuantity(offerId));
  const {setBookExperienceEmail, resetBookExperienceQuantity} = useActions();
  const {getFullPath} = usePath();
  const navigate = useNavigate();

  const isBackToOfferList = searchParams.has(BACK_TO_OFFER_LIST);

  const {mutate, isLoading: isCreating} = useCreateBulkDealMutation({
    onSuccess() {
      onSuccess?.();
      if (offerId) {
        resetBookExperienceQuantity(offerId);
      }
    },
    onError,
  });

  const createDeal = (email?: string) => {
    if (email) {
      setBookExperienceEmail(email);
    }
    const coreGuest = guestGroup?.members.find(({id}) => id === guestGroup?.leader_id);

    if (!isCreating && reservation && offer && quantity) {
      mutate(
        buildPayload({
          reservation,
          offer,
          coreGuest,
          email,
          quantity,
        }),
      );
    }
  };

  const getEmail = () => {
    return (
      guestGroup?.members.find(({id}) => id === guestGroup?.leader_id)?.email ||
      reservation?.default_invite_email
    );
  };

  const navigateToOffer = () => {
    if (isBackToOfferList) {
      navigate(getFullPath(`recommendations/${offerId}?${BACK_TO_OFFER_LIST}=true`));
    } else {
      navigate(getFullPath(`recommendations/${offerId}`));
    }
  };

  const navigateToConfirmEmail = () => {
    if (isBackToOfferList) {
      navigate(
        getFullPath(
          `recommendations/${offerId}/confirm-email?${BACK_TO_OFFER_LIST}=true`,
        ),
      );
    } else {
      navigate(getFullPath(`recommendations/${offerId}/confirm-email`));
    }
  };

  const navigateToBookExperience = (options?: {replace: boolean}) => {
    if (isBackToOfferList) {
      navigate(
        getFullPath(
          `recommendations/${offerId}/${PATHS.recommendations.details.bookExperience}?${BACK_TO_OFFER_LIST}=true`,
        ),
        {
          replace: options?.replace,
        },
      );
    } else {
      navigate(
        getFullPath(
          `recommendations/${offerId}/${PATHS.recommendations.details.bookExperience}`,
        ),
        {
          replace: options?.replace,
        },
      );
    }
  };

  const navigateAfterSuccess = () => {
    if (isBackToOfferList) {
      navigate(getFullPath(PATHS.recommendations.main));
    } else {
      navigate(getFullPath(PATHS.home));
    }
  };

  return {
    createDeal,
    getEmail,
    navigateToConfirmEmail,
    navigateAfterSuccess,
    navigateToOffer,
    navigateToBookExperience,
    bookExperienceEmail,
    offer,
    quantity,
    isLoading: isLoadingReservation || isLoadingOffer,
  };
};
