import styled from 'styled-components';
import {device} from 'styled/device';
import {ShortOfferCard} from 'components/upselling/ShortOfferCard/ShortOfferCard';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${props => props.theme.colors.branding.boxesModals};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
`;

export const List = styled.div`
  --home-upselling-list-gap: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: var(--home-upselling-list-gap);
  padding: 16px;
`;

export const StyledOfferCard = styled(ShortOfferCard)`
  flex-grow: 1;
  flex-basis: calc(calc(100% - var(--home-upselling-list-gap, 0) * 3) / 4);
  max-width: calc(50% - var(--home-upselling-list-gap, 0) / 2);

  &:nth-child(7n),
  &:nth-child(7n - 1),
  &:nth-child(7n - 2) {
    flex-basis: calc(calc(100% - var(--home-upselling-list-gap, 0) * 2) / 3);

    @media (max-width: ${device.laptopM}) {
      flex-basis: calc(calc(100% - var(--home-upselling-list-gap, 0)) / 2);
      max-width: 100%;
    }
  }

  @media (max-width: ${device.laptopM}) {
    flex-basis: calc(calc(100% - var(--home-upselling-list-gap, 0)) / 2);
    max-width: 100%;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 16px;
  width: 100%;
`;

export const HederText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  display: block;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.branding.fontPrimary};
  font-family: ${props => props.theme.fonts.fontFamily.titles.font_name};
`;

export const Description = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${props => props.theme.colors.branding.fontSecondary};
  font-family: ${props => props.theme.fonts.fontFamily.titles.font_name};
`;
