import {TFunction} from 'i18next';
import {FORM_NAMES} from './constants';
import {GROUP_TYPES, SeasonRule} from '@guestapp/sdk';

type GetFieldsProps = {
  betweenRule?: SeasonRule | null;
  lessThanRule?: SeasonRule | null;
  moreThanRule?: SeasonRule | null;
};
function getFields({lessThanRule, betweenRule, moreThanRule}: GetFieldsProps) {
  const fields = {} as Record<FORM_NAMES, boolean>;
  const hasLessThanRange = Boolean(lessThanRule?.id);
  const hasBetweenRange = Boolean(betweenRule?.id);
  const hasMoreThanRange = Boolean(moreThanRule?.id);
  const hasAtLeastOneGroup = [
    hasLessThanRange,
    hasBetweenRange,
    hasMoreThanRange,
  ].includes(true);

  if (hasAtLeastOneGroup) {
    fields[FORM_NAMES.junior] = hasAtLeastOneGroup;

    const needToFillBetweenTwoGroups =
      hasLessThanRange &&
      hasMoreThanRange &&
      (moreThanRule?.up_to ?? 0) - (lessThanRule?.less_than ?? 0) > 1;

    const isThereOnlyLessThanGroup = hasLessThanRange && !hasMoreThanRange;

    fields[FORM_NAMES.middle] =
      hasBetweenRange || needToFillBetweenTwoGroups || isThereOnlyLessThanGroup;

    fields[FORM_NAMES.senior] = hasBetweenRange || hasMoreThanRange;
  }

  fields[FORM_NAMES.numberOfGuests] = !hasAtLeastOneGroup;

  return fields;
}

type GetLabelsProps = {
  visibleFields: Record<FORM_NAMES, boolean>;
  betweenRule?: SeasonRule | null;
  lessThanRule?: SeasonRule | null;
  moreThanRule?: SeasonRule | null;
};
function getLabels({
  moreThanRule,
  betweenRule,
  lessThanRule,
  visibleFields,
  t,
}: GetLabelsProps & {t: TFunction}) {
  const labels = {} as Record<FORM_NAMES, string>;
  const isAdultTheOldestGroup =
    visibleFields.junior && visibleFields.middle && !visibleFields.senior;

  labels[FORM_NAMES.junior] = t('under_age_years_old', {
    age: lessThanRule?.less_than ?? betweenRule?.up_to ?? moreThanRule?.up_to,
  });
  labels[FORM_NAMES.middle] = isAdultTheOldestGroup
    ? t('seniors_counter_label', {
        age: (lessThanRule?.less_than ?? 0) + 1,
      })
    : t('from_to_years_old', {
        from: betweenRule?.up_to ?? lessThanRule?.less_than,
        to: betweenRule?.less_than ?? moreThanRule?.up_to,
      });
  labels[FORM_NAMES.senior] = t('seniors_counter_label', {
    age: moreThanRule?.up_to ?? betweenRule?.less_than ?? lessThanRule?.less_than,
  });

  return labels;
}

const getReservationTypeOptions = (t: TFunction) => {
  return [
    {label: t('family'), value: GROUP_TYPES.family},
    {label: t('group'), value: GROUP_TYPES.group},
    {label: t('tourist_group'), value: GROUP_TYPES.tourist},
  ];
};

const getGroupType = (numberOfGuests: number, value?: GROUP_TYPES) => {
  if (!numberOfGuests) {
    return;
  }

  if (numberOfGuests > 1) {
    return value;
  }

  return GROUP_TYPES.single;
};

export {getFields, getLabels, getReservationTypeOptions, getGroupType};
