import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {SHOWED_GUEST_GROUP_MODAL, COUNTRY_CODES, IS_LEADER_GUEST} from 'utils/constants';
import {PATHS} from 'Routes';
import {
  useActions,
  useGuestGroup,
  useModalOncePerSession,
  usePath,
  useScreenResize,
} from 'hooks';
import {device} from '@guestapp/ui/src/utils/device';
import {LocalStorage} from '@guestapp/core';
import {isAllianzFeatureActivated} from 'utils/common';
import {useSchema} from 'context/guest';
import {useSummary} from 'context/summary';
import {useReservation} from 'context/reservation';
import {useReservationSettingsModal} from 'components/ReservationSettingsModal';
import allianzBannerImg from 'assets/img/allianz-banner.png';
import Page from '../../components/Page';
import GuestsPanel from 'components/GuestsPanel';
import {
  AllianzInsurancesInCarButton,
  InformationText,
  ModalTitle,
  GuestLists,
} from './components';
import {PmsGuestPanelContainer, Wrapper} from './styled';

function GuestRegistrationView() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {housing, data: reservation, hasAvantio} = useReservation();
  const {isGuestRegistrationOnly} = useSummary();
  const {guestGroup, isLoading} = useGuestGroup();
  const {getFullPath} = usePath();
  const {data: summary} = useSummary();
  const {resetAllIVState} = useActions();
  const {isMatch: isMobile} = useScreenResize(device.tabletSubHeader);

  const isAllianzActive = isAllianzFeatureActivated(
    reservation?.check_in_date,
    housing?.allianz_status,
    summary?.country_code,
    summary?.nights_of_stay,
  );

  const {
    identityVerificationDetails: {enabled: ivEnabled},
  } = useSchema();
  const {isBiometricMatchForAll} = useReservation();

  const {shouldOpenModal} = useModalOncePerSession({
    id: reservation?.id,
    storageKey: SHOWED_GUEST_GROUP_MODAL,
  });

  const {
    Modal: WhoIsComingModal,
    openModal,
    ageLimit,
  } = useReservationSettingsModal({
    openModalPerSession: shouldOpenModal,
    showFirstTime: isGuestRegistrationOnly,
  });

  const hasNoGuests = guestGroup?.added_number_of_guests === 0;
  const totalGuest = guestGroup?.known_number_of_guests || 5;
  const currentTotalGuest = guestGroup?.added_number_of_guests || 0;

  const nextPage = useMemo(() => {
    const goToIv =
      (ivEnabled && !isBiometricMatchForAll && hasNoGuests) || isBiometricMatchForAll;
    const goToForm = !ivEnabled || (ivEnabled && !isBiometricMatchForAll && !hasNoGuests);

    if (goToIv) {
      return getFullPath(PATHS.verification.main);
    }
    if (goToForm) {
      return getFullPath(PATHS.addPersonalDataForm);
    }
    return getFullPath(PATHS.verification.main);
  }, [ivEnabled, getFullPath, isBiometricMatchForAll, hasNoGuests]);

  const onBackClick = () => {
    navigate(getFullPath(PATHS.home), {replace: true});
  };

  const handleRegisterClick = () => {
    resetAllIVState();
    navigate(nextPage);
  };

  const handleBannerClick = useCallback(() => {
    const existGuestFromSpain = guestGroup?.members.some(
      member => member.residence_country === COUNTRY_CODES.spain,
    );
    const path = existGuestFromSpain
      ? `../${PATHS.travelInsurances.main}/${PATHS.travelInsurances.allianz.main}/${PATHS.travelInsurances.allianz.selectGuests}`
      : `../${PATHS.travelInsurances.main}/${PATHS.travelInsurances.allianz.main}/${PATHS.travelInsurances.allianz.createGuest}`;
    navigate(path);
  }, [guestGroup, navigate]);

  const banner = isAllianzActive
    ? {
        src: allianzBannerImg,
        onClick: handleBannerClick,
      }
    : undefined;

  const handleRegisterNewPmsGuest = (isLeader?: boolean) => {
    LocalStorage.set(IS_LEADER_GUEST, JSON.stringify(isLeader));
    handleRegisterClick();
  };
  return (
    <Page
      isSubHeaderVisible
      title={t('guest_summary')}
      isLoading={isLoading}
      onBackButtonClick={onBackClick}
      banner={banner}
      navBarTitle={isMobile ? t('guest_summary') : t('online_chekin')}
      className="guest-register-view"
    >
      <Wrapper>
        <ModalTitle
          currentTotalGuest={currentTotalGuest}
          totalGuest={totalGuest}
          onEditClick={openModal}
        />
        {Boolean(ageLimit) && <InformationText ageLimit={ageLimit} />}
        {hasAvantio ? (
          <PmsGuestPanelContainer className="guest-register-view__guest-panel">
            <GuestsPanel handleRegisterNewGuest={handleRegisterNewPmsGuest} />
          </PmsGuestPanelContainer>
        ) : (
          <GuestLists
            isAllianzActive={isAllianzActive}
            nextPage={nextPage}
            handleRegisterNewGuest={handleRegisterClick}
          />
        )}
        {isAllianzActive && <AllianzInsurancesInCarButton />}
      </Wrapper>
      {WhoIsComingModal}
    </Page>
  );
}

export {GuestRegistrationView};
