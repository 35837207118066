import {transformSizePropToSize} from '../../utils/common';
import styled from 'styled-components';

type BaseIconContainerProps = {
  size?: string | number;
};

export const BaseIconContainer = styled.div<BaseIconContainerProps>`
  width: ${props => transformSizePropToSize(props.size) || '24px'};
  height: ${props => transformSizePropToSize(props.size) || '24px'};
`;
