import styled from 'styled-components';
import {device} from 'styled/device';

export const Wrapper = styled.div`
  display: block;
  padding: 0 20px;
`;

export const PassportTextContainer = styled.div`
  margin-bottom: 48px;

  @media (min-width: ${device.tablet}) {
    display: flex;
    width: 380px;
    margin: 0 auto 48px;
    justify-content: space-between;
    grid-column-gap: 16px;
  }
`;

export const PassportIcon = styled.img`
  display: flex;
  margin: 0 auto 16px;
  justify-content: center;
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: ${props =>
    props.theme.hasTemplate ? props.theme.colors.branding.fontPrimary : '#152946'};

  @media (min-width: ${device.tablet}) {
    font-size: 16px;
  }
`;

export const SelectsContainer = styled.div`
  display: block;
  margin: 0 auto 48px;
  width: 295px;

  @media (max-width: ${device.mobileXL}) {
    width: 100%;
  }
`;
