import React, {PropsWithChildren} from 'react';
import {OpenModalsProvider} from './openModals';
import {ThemeProvider as BaseThemeProvider} from 'styled-components';
import {useCustomTheme} from './customTheme';
import {GlobalStyle} from 'styled/global';

function ThemeProvider({children}: PropsWithChildren) {
  const {finalTheme, customFontFaces} = useCustomTheme();
  return (
    <BaseThemeProvider theme={finalTheme}>
      <OpenModalsProvider>
        <GlobalStyle fontFaces={customFontFaces} />
        {children}
      </OpenModalsProvider>
    </BaseThemeProvider>
  );
}

export {ThemeProvider};
