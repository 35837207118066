import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useFormContext} from 'react-hook-form';
import {usePreloadLeaderData} from './usePreloadLeaderData';
import {useSummary} from '../../context/summary';
import {
  useGuestFormFields,
  useModalControls,
  useGuest,
  usePreloadGuestData,
} from '../../hooks';
import {useOpenModals} from 'context/openModals';
import {FormTypes} from '../../utils/guestForm';
import ModalSheet from '../common/ModalSheet';
import AutoFillModalSheet from '../ModalSheets/AutoFill';
import {
  Fields,
  FormWrapper,
  LoadingContainer,
  LoadingText,
  MainStyledLoader,
} from './styled';

type GuestFormFieldsProps = {
  setErrorModal: (value: boolean) => void;
};

function GuestFormFields({setErrorModal}: GuestFormFieldsProps) {
  const {t} = useTranslation();
  const {id: guestId} = useParams<{id: string}>();
  const {guest} = useGuest({id: guestId});
  const {countryCode} = useSummary();
  const {
    isLoadingForm,
    isLoadedForm,
    FormModals,
    renderFormFields,
    hasSignature,
    SignatureField,
    isDataIncompleteModalOpen,
  } = useGuestFormFields({id: guestId, housingCountry: countryCode});
  const {getValues} = useFormContext<FormTypes>();
  const {setIsFormTouched} = useOpenModals();
  const {
    isOpen: isAutoFillModalOpen,
    openModal: openAutoFillModal,
    closeModal: closeAutoFillModal,
  } = useModalControls();

  const {preloadPersistedFormData} = usePreloadGuestData({
    onPreloaded: openAutoFillModal,
    guestData: guest,
  });
  usePreloadLeaderData({countryCode});

  useEffect(() => {
    setErrorModal(isDataIncompleteModalOpen);
    if (isLoadedForm) {
      preloadPersistedFormData(guestId);
      setIsFormTouched(Boolean(Object.values(getValues()).length));
    }
  }, [
    getValues,
    isLoadedForm,
    preloadPersistedFormData,
    setIsFormTouched,
    isDataIncompleteModalOpen,
    setErrorModal,
    guestId,
  ]);

  return (
    <>
      {isLoadingForm ? (
        <LoadingContainer>
          <MainStyledLoader visible width={100} />
          <LoadingText>{`${t('loading_form')}...`}</LoadingText>
        </LoadingContainer>
      ) : (
        <FormWrapper className="guest-form-wrapper">
          <Fields className="guest-form-fields" rowsCount={1} hasSignature={hasSignature}>
            {renderFormFields()}
          </Fields>
          <div>{SignatureField}</div>
          {FormModals()}
        </FormWrapper>
      )}
      <ModalSheet
        isOpenModalSheet={isAutoFillModalOpen}
        handleCloseModal={closeAutoFillModal}
        withCloseButton
        closeButtonType="click"
      >
        <AutoFillModalSheet onClose={closeAutoFillModal} />
      </ModalSheet>
    </>
  );
}

export {GuestFormFields};
