import React from 'react';
import {ActionMeta} from 'react-select';
import {useModalControls, useOutsideClick} from '../../../hooks';
import {SelectRefType} from '../Select';
import {SelectOptionType} from '@guestapp/sdk';
import {Dropdown} from './components';
import {
  QueriedInfiniteScrollSelect,
  QueriedInfiniteScrollSelectProps,
} from '../QueriedInfiniteScrollSelect';

type SearchingSelectProps<T, V, L> = QueriedInfiniteScrollSelectProps<T, V, L> & {
  trigger?: (isOpen: boolean, toggle: () => void) => React.ReactNode;
};

const SearchingSelectInternal = <T, V, L>(
  {
    fetcher,
    queryKey,
    optionsFilter,
    onOptionsChange,
    blockQuery,
    onInputChange,
    minSearchQueryLength = 0,
    disabled,
    loading,
    menuIsOpen = true,
    trigger,
    className,
    components,
    onChange,
    ...props
  }: SearchingSelectProps<T, V, L>,
  ref: React.ForwardedRef<SelectRefType<T, V, L>>,
) => {
  const {isOpen, closeModal, toggleModal} = useModalControls();
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  useOutsideClick(wrapperRef, closeModal);

  return (
    <Dropdown
      ref={wrapperRef}
      isOpen={isOpen}
      className={className}
      target={trigger?.(isOpen, toggleModal)}
    >
      <QueriedInfiniteScrollSelect
        ref={ref}
        fetcher={fetcher}
        queryKey={queryKey}
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        isMenuOpen
        forcedSearchMode
        onChange={(
          option: SelectOptionType<T, V, L> | null,
          info: ActionMeta<SelectOptionType<T, V, L>>,
        ) => {
          onChange?.(option, info);
          closeModal();
        }}
        components={{LoadingIndicator: undefined, DropdownIndicator: null}}
        tabSelectsValue={false}
        listSizes={{height: 224}}
        isSearchable
        autoFocus
        {...props}
      />
    </Dropdown>
  );
};

const SearchingSelect = React.forwardRef(SearchingSelectInternal) as <T, V, L>(
  props: SearchingSelectProps<T, V, L> & {
    ref?: React.ForwardedRef<SelectRefType<T, V, L>>;
  },
) => ReturnType<typeof SearchingSelectInternal>;

export {SearchingSelect};
export type {SearchingSelectProps};
