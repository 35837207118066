import {useCallback, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {PATHS} from '../../../../Routes';
import {COUNTRY_CODES} from '../../../../utils/constants';
import {useGuest, useIVComputedDetails} from 'hooks';
import {SkipIVModalRef} from 'components/common/SkipIVModal/SkipIVModal';
import {useSummary} from '../../../../context/summary';
import selectIcon from '../../../../assets/icons/select-icon.svg';
import selfieIcon from '../../../../assets/icons/selfie.svg';
import {ReactComponent as PassportSvg} from 'assets/icons/passport-icon-standar.svg';
import {ReactComponent as VerticalLineIcon} from '../../../../assets/icons/vertical-line.svg';
import Button from '../../../../components/common/Button';
import SkipIVModal from 'components/common/SkipIVModal';
import {MainLoader} from '../../../../components/common/loaders';
import {BoxInfo, NavigationStatus} from '@guestapp/ui';
import {ModalTwoButtonsWrapper} from '../../../../styled/common';
import {Wrapper, Text, InfoBox, RowItem, FixedRowItem, ContentBoxInfo} from './styled';

function StartView() {
  const {t} = useTranslation();
  const {id} = useParams();
  const {isLoading} = useGuest({id});
  const {biomatch, required} = useIVComputedDetails();
  const skipIVModalRef = useRef<SkipIVModalRef>(null);
  const openSkipModal = () => skipIVModalRef.current?.open();
  const {countryCode} = useSummary();
  const isAgeInformationVisible = countryCode && countryCode !== COUNTRY_CODES.uae;

  const getDescriptionItems = useCallback(() => {
    if (biomatch) {
      return (
        <>
          <FixedRowItem className="iv-start-view__fixed-row">
            <PassportSvg />
            <span>{t('scan_document')}</span>
          </FixedRowItem>
          <VerticalLineIcon />
          <RowItem className="iv-start-view__row">
            <img src={selfieIcon} width={50} height={50} alt="Selfie" />
            <span>{t('take_selfie')}</span>
          </RowItem>
        </>
      );
    }

    return (
      <>
        <RowItem className="iv-start-view__row">
          <img src={selectIcon} width={50} height={50} alt="Select" />
          <span>{t('select_document')}</span>
        </RowItem>
        <VerticalLineIcon />
        <FixedRowItem className="iv-start-view__fixed-row">
          <PassportSvg />
          <span>{t('scan_document')}</span>
        </FixedRowItem>
      </>
    );
  }, [biomatch, t]);

  return (
    <Wrapper className="iv-start-view">
      <Text className="iv-start-view__text">{t('verify_you_identity_to_continue')}</Text>
      <InfoBox className="iv-start-view__info-box">
        {isLoading ? (
          <MainLoader visible width={60} />
        ) : (
          <div>{getDescriptionItems()}</div>
        )}
      </InfoBox>

      {isAgeInformationVisible && (
        <ContentBoxInfo className="iv-start-view__info-content">
          <BoxInfo text={t('minors_under_18_age')} type={NavigationStatus.information} />
        </ContentBoxInfo>
      )}
      <ModalTwoButtonsWrapper className="iv-start-view__buttons">
        <Button
          label={t('start_verification')}
          to={PATHS.verification.documentSelection}
          disabled={isLoading}
        />
        <Button
          label={t('skip_process')}
          onClick={openSkipModal}
          disabled={isLoading}
          link
        />
      </ModalTwoButtonsWrapper>
      <SkipIVModal ref={skipIVModalRef} required={required} />
    </Wrapper>
  );
}

export {StartView};
