const theme = {
  colors: {
    $p0: '#393abd',
    $p1: '#445CCC',
    $p2: '#777E91',
    $p3: '#B1B5C4',
    $p4: 'rgba(57, 58, 189, 0.1)',
    $p5: '#F4F6F9',
    $p6: '#FFFFFF', //looks like secondary background
    $p7: '#181A20',
    $p8: '#FFB27D',
    $p9: '#2148FF',
    $p10: '#152946',
    $s1: '#F84B7A',
    $s2: '#32D7BE',
    $b1: '#EAECEF',
    $n1: '#161643',
    $n3: '#353945',
    $n4: '#6B6B95',
    $n5: '#9696B9',
    $n6: '#B1B5C4',
    $n7: '#E6E8EC',
    $cp1: '#181A20',
    $cp2: 'rgba(9, 16, 29, 0.75)',
    $fontBlack: '#101828',
    $gray: '#A2A2B4',
    $n8: '#F0F0F8',
    $n9: '#ACACD5',
    $n10: '#b3d7ff',
    $n11: '#80bdff',
  },
  buttons: {
    round: '50px',
    square: '7px',
  },
  fontSizes: {
    $error: '11px',
    $smallX: '12px',
    $small: '14px',
    $medium: '16px',
    $large: '18px',
    $largeL: '20px',
    $largeXL: '22px',
    $big: '24px',
  },
  fontWight: {
    $thin: '100',
    $light: '300',
    $regular: '400',
    $medium: '500',
    $mediumL: '600',
    $bold: '700',
    $black: '900',
  },
  gradients: {
    $g0: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(50% 50% at 50% 50%, #5573FF 0%, #4441E9 100%)',
    $g1: 'radial-gradient(50% 50% at 50% 50%, #BEC2D6 0%, #A8ADC7 100%)',
    $g2: 'radial-gradient(50% 50% at 50% 50%, #878D9C 0%, #6A7287 100%)',
    $mainButton: 'linear-gradient(180deg, #385BF8 0%, #2148FF 100%)',
    $g3: 'linear-gradient(0deg,rgba(255,255,255,0.9),rgba(255,255,255,0.9))',
  },
  hasTemplate: false,
};

interface BrandingColors {
  primary: string;
  icons: string;
  fontPrimary: string;
  fontSecondary: string;
  backgroundMain: string;
  backgroundSecondary: string;
  boxesModals: string;
}

interface NavigationMenuColors {
  backgroundMenu: string;
  menuItemsText: string;
  selectedItemBackground: string;
  selectedItemText: string;
}

interface ButtonsColors {
  mainButtonBackground: string;
  mainButtonTextBackground: string;
  secondaryButtonTextAndBorder: string;
  textButton: string;
}

interface GradientsColors {
  [key: string]: string;
}

interface SplashColors {
  backgroundColor: string;
  animationColor: string;
}

interface Colors {
  branding: BrandingColors;
  navigationMenu: NavigationMenuColors;
  buttons: ButtonsColors;
  gradients: GradientsColors;
  splash: SplashColors;
  $p0: string;
  $p1: string;
  $p2: string;
  $p3: string;
  $p4: string;
  $p5: string;
  $p6: string;
  $p7: string;
  $p8: string;
  $p9: string;
  $p10: string;
  $s1: string;
  $s2: string;
  $b1: string;
  $n1: string;
  $n3: string;
  $n4: string;
  $n5: string;
  $n6: string;
  $n7: string;
  $cp1: string;
  $cp2: string;
  $fontBlack: string;
  $gray: string;
  $n8: string;
  $n9: string;
  $n10: string;
  $n11: string;
}

interface FontSizes {
  [key: string]: string;
}

export type FontsData = {
  font_family?: string;
  font_file?: string;
  font_file_download_link?: string;
  font_format: string;
  name: string;
  id?: string;
  system_font?: boolean;
  font_name?: string;
};

interface FontFamily {
  titles: FontsData;
  body: FontsData;
}

interface FontWeight {
  [key: string]: string;
}

interface Fonts {
  sizes: FontSizes;
  fontFamily: FontFamily;
  fontWeight: FontWeight;
}

export enum BUTTONS_STYLES_OPTIONS {
  round = 'ROUND',
  square = 'SQUARE',
}

interface Buttons {
  typeRadioButton: BUTTONS_STYLES_OPTIONS | string;
}

export interface GuestappThemeProps {
  colors: Colors;
  fonts: Fonts;
  buttons: Buttons;
  hasTemplate: boolean;
}

const guestappTheme: GuestappThemeProps = {
  colors: {
    branding: {
      primary: '#385BF8',
      icons: '#385BF8',
      fontPrimary: '#152946',
      fontSecondary: '#777E91',
      backgroundMain: '#F4F6F9',
      backgroundSecondary: '#EAECEF',
      boxesModals: '#FFFFFF',
    },
    navigationMenu: {
      backgroundMenu: '#FFFFFF',
      menuItemsText: '#152946',
      selectedItemBackground: '#EBEBF8',
      selectedItemText: '#385BF8',
    },
    buttons: {
      mainButtonBackground: '#385CF8',
      mainButtonTextBackground: '#FFFFFF',
      secondaryButtonTextAndBorder: '#385CF8',
      textButton: '#385CF8',
    },
    gradients: {
      g0: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), radial-gradient(50% 50% at 50% 50%, #5573FF 0%, #4441E9 100%)',
      g1: 'radial-gradient(50% 50% at 50% 50%, #BEC2D6 0%, #A8ADC7 100%)',
      g2: 'radial-gradient(50% 50% at 50% 50%, #878D9C 0%, #6A7287 100%)',
      mainButton: 'linear-gradient(180deg, #385BF8 0%, #2148FF 100%)',
      g3: 'linear-gradient(0deg,rgba(255,255,255,0.9),rgba(255,255,255,0.9))',
    },
    splash: {
      animationColor: '#385BF8',
      backgroundColor: '#F4F6F9',
    },
    $p0: '#393abd',
    $p1: '#445CCC',
    $p2: '#777E91',
    $p3: '#B1B5C4',
    $p4: 'rgba(57, 58, 189, 0.1)',
    $p5: '#F4F6F9',
    $p6: '#FFFFFF',
    $p7: '#181A20',
    $p8: '#FFB27D',
    $p9: '#2148FF',
    $p10: '#152946',
    $s1: '#F84B7A',
    $s2: '#32D7BE',
    $b1: '#EAECEF',
    $n1: '#161643',
    $n3: '#353945',
    $n4: '#6B6B95',
    $n5: '#9696B9',
    $n6: '#B1B5C4',
    $n7: '#E6E8EC',
    $cp1: '#181A20',
    $cp2: 'rgba(9, 16, 29, 0.75)',
    $fontBlack: '#101828',
    $gray: '#A2A2B4',
    $n8: '#F0F0F8',
    $n9: '#ACACD5',
    $n10: '#b3d7ff',
    $n11: '#80bdff',
  },
  buttons: {
    typeRadioButton: BUTTONS_STYLES_OPTIONS.round,
  },
  fonts: {
    sizes: {
      error: '11px',
      smallX: '12px',
      small: '14px',
      medium: '16px',
      large: '18px',
      largeL: '20px',
      largeXL: '22px',
    },
    fontFamily: {
      titles: {
        name: 'Poppins',
        font_name: 'Poppins',
        // url: 'https://fonts.gstatic.com/s/rubikburned/v1/Jqzk5TmOVOqQHihKqPpscqnSGgGb.woff2',
        font_format: 'ttf',
      },
      body: {
        name: 'Poppins',
        font_name: 'Poppins',
        // url: 'https://fonts.gstatic.com/s/rubikburned/v1/Jqzk5TmOVOqQHihKqPpscqnSGgGb.woff2',
        font_format: 'ttf',
      },
    },
    fontWeight: {
      thin: '100',
      light: '300',
      regular: '400',
      medium: '500',
      mediumL: '600',
      bold: '700',
      black: '900',
    },
  },
  hasTemplate: false,
};

export {theme, guestappTheme};
