import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CartPayment} from '@guestapp/sdk';

const sanitizeId = (id: string) => {
  return id.replaceAll('-', '');
};
const sanitizeIdInObj = (cartPayment: DeferredPayment): DeferredPayment => {
  const sanitizedId = sanitizeId(cartPayment.id);
  return {...cartPayment, id: sanitizedId};
};

export type DeferredPayment = Pick<CartPayment, 'id'> & Partial<Omit<CartPayment, 'id'>>;

export type PaymentsSliceState = {
  isPropertyProtectionPaymentPreloaded: boolean;
  deferredPayments: DeferredPayment[];
};

const initialState: PaymentsSliceState = {
  isPropertyProtectionPaymentPreloaded: false,
  deferredPayments: [],
};

const PaymentsSlice = createSlice({
  name: 'PaymentsSlice',
  initialState,
  reducers: {
    addDeferredPayments(
      state,
      action: PayloadAction<DeferredPayment | DeferredPayment[]>,
    ) {
      if ('id' in action.payload) {
        const sanitizedPayload = sanitizeIdInObj(action.payload);
        state.deferredPayments.unshift(sanitizedPayload);
      } else {
        state.deferredPayments = action.payload?.map(sanitizeIdInObj);
      }
    },
    removeDeferredPayment(state, action: PayloadAction<string>) {
      const sanitizedId = sanitizeId(action.payload);
      state.deferredPayments = state.deferredPayments?.filter(
        item => item.id !== sanitizedId,
      );
    },
    setProtectionPaymentPreloaded(state, action: PayloadAction<boolean>) {
      state.isPropertyProtectionPaymentPreloaded = action.payload;
    },
  },
});

export {PaymentsSlice};
export const paymentsActions = PaymentsSlice.actions;
export const paymentsReducer = PaymentsSlice.reducer;
