import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import incompleteIcon from '../../assets/icons/incomplete-icon.svg';
import completeIcon from '../../assets/icons/complete-icon.svg';
import {CustomIcon, IconNames} from '@guestapp/ui';
import ModalSheet from 'components/common/ModalSheet';
import StandardModalSheet from 'components/common/StandardModalSheet';
import {ButtonProps} from 'components/common/Button/Button';
import {useModalControls} from 'hooks';
import {
  NavigationTitle,
  NavigationContainer,
  NavigationItem as NavigationItemWrapper,
  NavigationStatusIcon,
  NavigationTexts,
  NavigationStatusText,
  NextArrow,
  NavigationDescription,
  NavigationIconContainer,
} from './styled';

type NavigationIconProps = {
  name: string;
  custom?: boolean;
  color?: string;
};

type NavigationNotificationProps = {
  title: string;
  message: string | JSX.Element;
};

type NavigationItemProps = {
  title: string;
  completed?: boolean;
  path: string | null;
  icon?: NavigationIconProps;
  isStatusBoxVisible?: boolean;
  description?: string;
  maxWidth?: string;
  flat?: boolean;
  notification?: NavigationNotificationProps;
};
const NavigationItem = ({
  title,
  icon,
  completed,
  path,
  isStatusBoxVisible = true,
  description,
  maxWidth,
  flat,
  notification,
}: NavigationItemProps) => {
  const {
    isOpen: isOpenNotificationModal,
    closeModal: closeNotificationModal,
    openModal: openNotificationModal,
  } = useModalControls();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const statusIcon = completed ? completeIcon : incompleteIcon;
  const statusText = completed ? t('completed') : t('incomplete');

  const goToView = React.useCallback(() => {
    if (path) navigate(`../${path}`);
  }, [navigate, path]);

  const clickHandler = React.useCallback(() => {
    if (notification) {
      openNotificationModal();
    } else {
      goToView();
    }
  }, [goToView, openNotificationModal, notification]);

  const buttons: ButtonProps[] = React.useMemo(() => {
    const understoodButton: ButtonProps = {
      label: t('understood'),
      primary: true,
      onClick: () => {
        closeNotificationModal();
        goToView();
      },
    };

    return [understoodButton];
  }, [t, goToView, closeNotificationModal]);

  return (
    <NavigationContainer
      maxWidth={maxWidth}
      flat={flat}
      className="nav-item navigation-item-container"
      onClick={clickHandler}
      completed={completed}
    >
      <NavigationItemWrapper isClicked={!!path}>
        <NavigationIconContainer>
          <CustomIcon
            name={icon?.name as IconNames}
            size={40}
            custom={icon?.custom}
            color={icon?.color}
          />
        </NavigationIconContainer>
        <NavigationTexts className="nav-item__content">
          <NavigationTitle className="nav-item__title">{title}</NavigationTitle>
          {isStatusBoxVisible && (
            <NavigationStatusText completed={!!completed} className="nav-item__status">
              <NavigationStatusIcon
                width={14}
                height={14}
                src={statusIcon}
                alt="Status Icon"
              />
              {statusText}
            </NavigationStatusText>
          )}
          {description && (
            <NavigationDescription className="nav-item__description">
              {description}
            </NavigationDescription>
          )}
        </NavigationTexts>
      </NavigationItemWrapper>
      <NextArrow name="grayRightArrow" />
      {notification && (
        <ModalSheet
          title={notification.title}
          isOpenModalSheet={isOpenNotificationModal}
          handleCloseModal={goToView}
          withModalMobileCloseButton={false}
        >
          <StandardModalSheet buttons={buttons} text={notification.message} />
        </ModalSheet>
      )}
    </NavigationContainer>
  );
};

export {NavigationItem};
