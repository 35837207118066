import {UpsellingApi, CreateBulkDealPayload} from '../api';
import {CreateOrUpdateBulkLockPayload, ThirdPartyDealPayload} from '../utils/types';
import {FetcherSettings} from '../api/apiClient';

class UpsellingService {
  private readonly upsellingApi: UpsellingApi;

  constructor() {
    this.upsellingApi = new UpsellingApi();
  }

  deleteDeal(id: string, resolverSettings?: FetcherSettings): Promise<void> {
    return this.upsellingApi.deleteDeal(id, resolverSettings);
  }

  getDeals(params?: string, resolverSettings?: FetcherSettings) {
    return this.upsellingApi.getDeals(params, resolverSettings);
  }

  getOffers(params?: string, resolverSettings?: FetcherSettings) {
    return this.upsellingApi.getOffers(params, resolverSettings);
  }

  getOffer(id: string, resolverSettings?: FetcherSettings) {
    return this.upsellingApi.getOffer(id, resolverSettings);
  }

  createOrUpdateThirdPartyDeal(
    payload: ThirdPartyDealPayload,
    resolverSettings?: FetcherSettings,
  ) {
    return this.upsellingApi.createOrUpdateThirdPartyDeal(payload, resolverSettings);
  }

  createOrUpdateBuildLock(
    payload: CreateOrUpdateBulkLockPayload,
    resolverSettings?: FetcherSettings,
  ) {
    return this.upsellingApi.createOrUpdateBuildLock(payload, resolverSettings);
  }

  createBulkDeal(payload: CreateBulkDealPayload, resolverSettings?: FetcherSettings) {
    return this.upsellingApi.createBulkDeal(payload, resolverSettings);
  }

  fetchCheckIfOffersExists(params?: string, resolverSettings?: FetcherSettings) {
    return this.upsellingApi.fetchCheckIfOffersExists(params, resolverSettings);
  }
}

export {UpsellingService};
