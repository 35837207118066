import styled from 'styled-components';
import {Button, CustomIcon} from '@guestapp/ui';
import {device} from 'styled/device';
import {FormFieldWrapper} from 'styled/common';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  width: 100%;

  @media (min-width: ${device.tablet}) {
    margin-top: 48px;
  }
`;

export const TitleWrapper = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.36px;
  text-align: left;
  margin-bottom: 16px;
  color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.fontPrimary : theme.colors.$p10};

  @media (min-width: ${device.tablet}) {
    font-size: 30px;
    font-weight: 500;
  }
`;

export const SubTitleWrapper = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 24px;
  color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.fontSecondary : theme.colors.$p10};

  @media (min-width: ${device.tablet}) {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 32px;
  }
`;

export const CustomBackButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  margin: 24px 0;

  & .button__left-icon {
    padding-right: 10px;
  }
`;

export const ContentForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: ${device.tablet}) {
    flex-direction: row;
    gap: 24px;
  }
`;

type FieldsProps = {
  rowsCount: number;
};

export const Fields = styled.div<FieldsProps>`
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  display: flex;

  @media (min-width: ${device.laptopL}) {
    flex-direction: row;
    width: 614px;
    justify-content: space-between;
  }
`;
export const FormWrapper = styled.div`
  margin: 0 auto;
  @media (min-width: ${device.tablet}) {
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 1280px;
  }
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  @media (min-width: ${device.tablet}) {
    justify-content: start;
  }
`;

export const SubmitButton = styled.div`
  margin: 0 auto;
  width: 100%;

  & .submit-btn {
    width: 100%;
    margin: 0 auto;
  }
`;

export const StatusTitleModal = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.36px;
  text-align: center;
  color: #152946;
`;

export const StyledBackIcon = styled(CustomIcon)`
  display: flex;
  justify-content: center;
  align-items: center;

  path {
    fill: ${({theme}) =>
      theme.hasTemplate
        ? theme.colors.branding.icons
        : theme.colors.navigationMenu.selectedItemText};
  }
`;

export const StyledButton = styled(Button)`
  min-width: 295px;
  @media (min-width: ${device.tablet}) {
    min-width: 205px;
  }
`;

export const StyledFormFieldWrapper = styled(FormFieldWrapper)`
  & .fieldset__label {
    text-transform: none;
  }

  width: 100%;
  @media (min-width: ${device.tablet}) {
    width: 295px;
  }
`;
