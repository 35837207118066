import {Offer} from '@guestapp/sdk';

const firstPrice = 0;
type FormatPriceProps = {
  priceType: Offer['price_type'];
  items: Offer['items'];
};
export const formatPrice =
  (currencySymbol: string) =>
  ({priceType, items}: FormatPriceProps) => {
    if (priceType === 'SINGLE_PRICE') {
      return `${currencySymbol}${items[firstPrice].price}`;
    }
    return '';
  };

export const getPrice = ({price_type, items}: Offer) => {
  if (price_type === 'SINGLE_PRICE') {
    return Number(items[firstPrice].price);
  }
  return 0;
};
