import React from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import {useSummary} from '../../../../context/summary';
import {getDocumentType} from '../../../../store/slices/identityVerification';
import {PATHS} from '../../../../Routes';
import {useAppSelector, useDocumentSelfieDetection} from '../../../../hooks';
import {IV_DOCUMENT_IMAGE_TYPES} from '../../../../utils/constants';
import frontSidePlaceholder from '../../../../assets/img/frontside-placeholder.svg';
import PhotoBox from '../../../../components/PhotoBox';
import {HelperText} from '../styled';

function FrontScanView() {
  const {uploadType, id} = useParams();
  const {t} = useTranslation();
  const {reservationId, countryCode} = useSummary();
  const {ProcessModal, ErrorModal, RetryModal, isProcessing, sendFrontSideDocument} =
    useDocumentSelfieDetection({
      docType: IV_DOCUMENT_IMAGE_TYPES.front,
    });

  const documentType = useAppSelector(getDocumentType);

  const isUpload = uploadType === 'upload';

  const getHelperText = () => {
    if (isUpload) {
      return documentType?.two_sides ? (
        <Trans i18nKey="upload_photo_of_front_side_of_your_document" />
      ) : (
        <Trans i18nKey="upload_photo_of_data_page_of_your_document" />
      );
    } else {
      return documentType?.two_sides ? (
        <Trans i18nKey="first_front_side_of_your_document_expanded" />
      ) : (
        t('place_document_inside_frame')
      );
    }
  };

  const getMobileHelperText = () => {
    return documentType?.two_sides ? (
      <Trans i18nKey="first_front_side_of_your_document" />
    ) : (
      ''
    );
  };

  const handleChangePhoto = React.useCallback(
    (image: string) => {
      if (countryCode) {
        sendFrontSideDocument(image, countryCode, reservationId);
      }
    },
    [countryCode, reservationId, sendFrontSideDocument],
  );

  if (!documentType) {
    return <Navigate to={`../../${PATHS.verification.main}/${id ?? ''}`} />;
  }

  return (
    <>
      <HelperText className="iv-front-view__helper">{getHelperText()}</HelperText>
      {!isProcessing && (
        <PhotoBox
          mobileHelperTitle={t('scan_your_document')}
          mobileHelperText={getMobileHelperText()}
          onUploadPhoto={handleChangePhoto}
          preloadedScreenshot={''}
          isUpload={isUpload}
          placeholderImage={frontSidePlaceholder}
        />
      )}
      <ProcessModal />
      <ErrorModal />
      <RetryModal />
    </>
  );
}

export {FrontScanView};
