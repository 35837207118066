import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const Container = styled(Link)`
  position: relative;
  height: 120px;
  border-radius: 12px;
  box-shadow: 0px 3px 6px 0px #00000012;
`;

export const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
`;

export const Highlight = styled.div`
  font-weight: 400;
  height: 0;
  opacity: 0;
  transition: all 600ms;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const Title = styled.div`
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const TextWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 16px;
  font-size: 14px;
  font-weight: 600;
  color: ${({theme}) => theme.colors.$p5};
  justify-content: end;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  line-height: 16px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.6) 100%);

  transition: background-color 600ms;
  &:hover:hover {
    @supports (
      background-color:
        color-mix(in hsl, ${({theme}) => theme.colors.branding.primary} 60%, transparent)
    ) {
      background-color: color-mix(
        in hsl,
        ${({theme}) => theme.colors.branding.primary} 60%,
        transparent
      );
    }

    ${Highlight} {
      flex-grow: 1;
      opacity: 1;
    }
  }
`;

export const Price = styled.div`
  line-height: 24px;
`;
