import Lottie from 'lottie-react';
import styled from 'styled-components';
import {device} from 'styled/device';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: auto;
`;

export const AutoFillText = styled.h2`
  margin: 0 auto 32px;
  text-align: center;
  max-width: 430px;
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.colors.branding.fontPrimary};
`;

export const HelperText = styled.div`
  font-size: 14px;
  text-align: center;
  max-width: 430px;
  margin: 0 auto 34px;
  color: ${props => props.theme.colors.branding.fontSecondary};

  @media (min-width: ${device.tablet}) {
    font-size: 16px;
  }
`;

export const BottomBlock = styled.div`
  /* margin: auto auto 0; */
  display: flex;
  flex-direction: column;
  grid-row-gap: 48px;
  width: 100%;
`;

export const BackIcon = styled.div`
  position: absolute;
  top: 3px;
`;
export const PassportGif = styled.img`
  max-width: 225px;
  margin: 0 auto 68px;
`;

export const StyledLottie = styled(Lottie)`
  max-width: 225px;
  margin: 0 auto 68px;
`;
