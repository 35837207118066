import {ListDeal, Paginated, DEAL_STATUSES} from '@guestapp/sdk';

const statusesToShow = [DEAL_STATUSES.approved, DEAL_STATUSES.requested];
const filterStatusesToShow = (deal: ListDeal) => statusesToShow.includes(deal.status);
const sortByStatus = (deal1: ListDeal, deal2: ListDeal) =>
  statusesToShow.indexOf(deal1.status) - statusesToShow.indexOf(deal2.status);

const createDealStatusMap = (paginatedDealsList?: Paginated<ListDeal>) => {
  if (!paginatedDealsList) {
    return new Map();
  }

  return new Map(
    paginatedDealsList.results
      ?.filter(filterStatusesToShow)
      .sort(sortByStatus)
      .map(({offer, status}) => [offer.id, status]),
  );
};

export {createDealStatusMap};
