import {ReactNode} from 'react';
import clsx from 'clsx';
import {checkIsInternetConnectionFast} from '@guestapp/core';
import {MainLoader} from '../loaders';
import {LoadingContainer, LoadingText, LoadingWrapper} from './styled';

export type LoadingProps = {
  children: ReactNode;
  isLoading?: boolean;
  loadingText?: string;
  loaderWidth?: number;
  className?: string;
};

function Loading({
  children,
  isLoading,
  loadingText,
  loaderWidth,
  className,
}: LoadingProps) {
  return (
    <>
      {isLoading && !checkIsInternetConnectionFast() ? (
        <LoadingWrapper className="loading-wrapper">
          <LoadingContainer className={clsx(className, 'loading-container')}>
            <MainLoader visible width={loaderWidth ?? 100} />
            {loadingText && <LoadingText>{loadingText}</LoadingText>}
          </LoadingContainer>
        </LoadingWrapper>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

export {Loading};
