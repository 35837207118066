import React, {PropsWithChildren} from 'react';
import {useQuery} from '@tanstack/react-query';
import {
  BookingSummary,
  ReservationPaymentSummary,
  SUMMARY_PAYMENTS_STATUSES,
} from '@guestapp/sdk';
import {useChekinSDK} from './ChekinSDK';
import {useReservation} from './reservation';
import {useCustomTheme} from './customTheme';
import {PATHS} from 'Routes';
import {QUERY_CACHE_KEYS, RESERVATION_ID_KEY} from '../utils/constants';
import {LocalStorage} from '@guestapp/core';

const checkIsPaymentNotComplete = (paymentSummary?: ReservationPaymentSummary) => {
  return paymentSummary?.status === SUMMARY_PAYMENTS_STATUSES.configurationPending;
};
enum BASE_PATHS {
  home = 'home',
  onlineChekin = 'onlineChekin',
}

type ContextProps = {
  housingName: string;
  countryCode: string;
  reservationId: string;
  isGuestappComplete: boolean;
  checkIfAnyPaymentNotComplete: (exemption?: 'insurance' | 'taxes') => boolean;
  data?: BookingSummary;
  error?: Error | null;
  arePaymentsAvailable?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  isLoadingError?: boolean;
  isSuccess?: boolean;
  isGuestRegistrationOnly: boolean;
  isGuestRegistrationComplete: boolean;
  basePaths: {[key in BASE_PATHS]: string};
  areAllPaymentsCompleted?: boolean;
  summaryStatus: 'error' | 'success' | 'loading';
  isSecurityDepositPending: boolean;
};

const SummaryContext = React.createContext<ContextProps>({
  data: undefined,
  isLoading: false,
  isFetching: false,
  countryCode: '',
  reservationId: '',
  housingName: '',
  isGuestappComplete: false,
  checkIfAnyPaymentNotComplete: () => true,
  isGuestRegistrationOnly: true,
  isGuestRegistrationComplete: false,
  basePaths: {[BASE_PATHS.home]: '', [BASE_PATHS.onlineChekin]: ''},
  summaryStatus: 'loading',
  isSecurityDepositPending: true,
});

function SummaryProvider({children}: Readonly<PropsWithChildren>) {
  const {getSummary, isTokenValid} = useChekinSDK();
  const {isTemplateInitialized} = useCustomTheme();
  const {data: reservation} = useReservation();
  const [isReadyToken, setIsReadyToken] = React.useState(false);
  const isValidTokenTimeoutRef = React.useRef<NodeJS.Timeout>();
  const reservationId = isTemplateInitialized
    ? reservation?.id ?? LocalStorage.get<string>(RESERVATION_ID_KEY) ?? ''
    : '';

  React.useEffect(() => {
    isValidTokenTimeoutRef.current = setTimeout(() => {
      if (isTokenValid) {
        setIsReadyToken(true);
      }
    }); // we have to improve this, the bug is in sdk, in AUTHSERVICE, in the init function, that is
    //the function who responde with the isValid token, but, currently, is giving us a isValidToken in TRUE
    // but the token is not jet in the localstorage, so, we make a couple a fails rquest without tokenn
    //so, with this timeout we aboid temporaly the race condition, but the true fix is, have this "isValidToken"
    //in true, at the moment when we are pretty sure that the token could be accesed from the localstorage/
  }, [isTokenValid, setIsReadyToken, getSummary, reservationId]);
  // React.useEffect(() => {
  //   return () => {
  //     clearTimeout(isValidTokenTimeoutRef.current);
  //   };
  // }, []);
  const enabled = Boolean(getSummary) && isReadyToken && Boolean(reservationId);
  const {data, error, isLoading, isFetching, isLoadingError, isSuccess, status} =
    useQuery<BookingSummary, Error>(
      [QUERY_CACHE_KEYS.summary],
      () => getSummary(reservationId || ''),
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled,
      },
    );

  const summary = React.useMemo(() => data || ({} as BookingSummary), [data]);
  const isSecurityDepositPending =
    data?.property_protection.status === SUMMARY_PAYMENTS_STATUSES.configurationPending;
  const isGuestRegistrationOnly = [
    summary?.property_protection,
    summary?.taxes,
    summary?.upselling,
  ].every(feature => !feature?.active);

  const countryCode = React.useMemo(
    function getHousingCountryCode(): string {
      const code = data?.country_code;

      if (!code) {
        return '';
      }

      return code;
    },
    [data?.country_code],
  );

  const arePaymentsAvailable = React.useMemo(() => {
    return [
      data?.property_protection,
      data?.taxes,
      data?.booking_payments,
      data?.upselling,
      data?.allianz,
    ].some(payment => payment?.active);
  }, [
    data?.booking_payments,
    data?.property_protection,
    data?.taxes,
    data?.upselling,
    data?.allianz,
  ]);

  const housingName = summary.property_name || '';
  const checkIfAnyPaymentNotComplete = React.useCallback(
    (exemption?: 'insurance' | 'taxes') => {
      return (
        (checkIsPaymentNotComplete(summary.property_protection) &&
          exemption !== 'insurance') ||
        (checkIsPaymentNotComplete(summary.taxes) && exemption !== 'taxes')
      );
    },
    [summary.property_protection, summary.taxes],
  );

  const basePaths = React.useMemo(() => {
    return {
      [BASE_PATHS.home]: PATHS.home,
      [BASE_PATHS.onlineChekin]: isGuestRegistrationOnly
        ? PATHS.guestRegistration
        : PATHS.home,
    };
  }, [isGuestRegistrationOnly]);

  const areAllPaymentsCompleted = React.useMemo(() => {
    return [data?.booking_payments, data?.property_protection, data?.taxes]
      .filter(payment => payment?.active)
      .every(payment => payment?.status === SUMMARY_PAYMENTS_STATUSES.complete);
  }, [data?.booking_payments, data?.property_protection, data?.taxes]);
  const isGuestRegistrationComplete = data?.guest_registration?.status === 'COMPLETE';
  const isGuestappComplete = arePaymentsAvailable
    ? areAllPaymentsCompleted && isGuestRegistrationComplete
    : isGuestRegistrationComplete;

  const defaultProps = React.useMemo<ContextProps>(
    () => ({
      data: summary,
      error,
      isLoading,
      isFetching,
      isLoadingError,
      countryCode,
      reservationId,
      isSuccess,
      housingName,
      isGuestRegistrationComplete,
      isGuestappComplete,
      arePaymentsAvailable,
      checkIfAnyPaymentNotComplete,
      isGuestRegistrationOnly,
      basePaths,
      areAllPaymentsCompleted,
      summaryStatus: status,
      isSecurityDepositPending,
    }),
    [
      isGuestappComplete,
      checkIfAnyPaymentNotComplete,
      countryCode,
      error,
      arePaymentsAvailable,
      housingName,
      isFetching,
      isLoading,
      isLoadingError,
      isSuccess,
      reservationId,
      summary,
      isGuestRegistrationOnly,
      basePaths,
      isGuestRegistrationComplete,
      areAllPaymentsCompleted,
      status,
      isSecurityDepositPending,
    ],
  );

  return <SummaryContext.Provider value={defaultProps} children={children} />;
}

function useSummary() {
  const context = React.useContext(SummaryContext);
  if (context === undefined) {
    throw new Error('useSummary must be used within a SummaryProvider');
  }
  return context;
}

export {SummaryContext, SummaryProvider, useSummary, checkIsPaymentNotComplete};
