import styled, {css} from 'styled-components';
import {device} from '@guestapp/ui/src/utils/device';
import {Button} from '@guestapp/ui';
import {MainLoader} from '../../../components/common/loaders';

export const TouristTaxesText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #777e91;
  margin-bottom: 6px;

  @media (min-width: ${device.tablet}) {
    font-size: 14px;
    /* width: 700px; */
    margin: 0 auto 24px auto;

    b {
      color: #152946;
    }
  }

  @media (min-width: ${device.laptop}) {
    width: 700px;
    margin: 0 auto 24px auto;
  }
`;

export const MoreInfoButton = styled(Button)`
  font-size: 14px;
  letter-spacing: -0.32px;
  margin: 0 auto 40px;

  @media (min-width: ${device.tablet}) {
    margin-bottom: 48px;
  }
`;

export const GuestsTaxes = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: center;
  max-width: 416px;
  margin: 0 auto;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 280px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #152946;
  margin-bottom: 8px;

  @media (min-width: ${device.mobileM}) {
    width: 327px;
  }

  @media (min-width: ${device.tablet}) {
    width: 416px;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 16px;
  }
`;

export const EditGuests = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 16px;
  width: 283px;
  height: 56px;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 32px;
  text-transform: capitalize;

  & div:first-child {
    color: #152946;
  }

  @media (min-width: ${device.mobileM}) {
    width: 327px;
  }

  @media (min-width: ${device.tablet}) {
    width: 416px;
    margin-bottom: 48px;
  }
`;

export const ExemptionUsersList = styled.div<{isRemoved: boolean}>`
  display: grid;
  //row-gap: 8px;
  margin-bottom: 16px;

  & > div {
    margin-bottom: 8px;
  }
  & > div:last-child {
    margin-bottom: 0;
  }

  @keyframes disappearingExemptionUsersList {
    to {
      margin: 0;
    }
  }

  ${props =>
    props.isRemoved &&
    css`
      animation: disappearingExemptionUsersList 0.2s forwards 0.8s;
      -webkit-animation: disappearingExemptionUsersList 0.2s forwards 0.8s;
      transition: disappearingExemptionUsersList 0.2s ease-in 0.8s;
    `}}
`;

export const ExemptionsBox = styled.div<{isInitialized: boolean; isLoading: boolean}>`
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  ${props =>
    props.isInitialized &&
    css`
      opacity: 1;
    `}

  ${props =>
    props.isLoading &&
    css`
      opacity: 0.6;
    `}
`;

export const Exemptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 280px;
  height: 24px;
  color: #152946;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 16px;

  & div:last-child {
    color: #393abd;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
  }

  @media (min-width: ${device.mobileM}) {
    width: 327px;
  }

  @media (min-width: ${device.tablet}) {
    width: 416px;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 18px;

    & div:last-child {
      font-size: 14px;
    }
  }
`;

export const ViewExemptionsButton = styled(Button)`
  font-size: 14px;
  letter-spacing: -0.3px;
`;

export const AddExemptionsButton = styled(Button)`
  font-size: 14px;
  margin: 16px auto 20px 16px;

  @media (min-width: ${device.tablet}) {
    margin-top: 0;
  }

  path {
    stroke: ${props => props.theme.colors.buttons.textButton};
  }
`;

export const TotalTax = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  width: 285px;
  height: 56px;
  background: #eaecef;
  border-radius: 16px;
  color: #152946;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 24px;

  & div:last-child {
    font-weight: 700;
  }

  @media (min-width: ${device.mobileM}) {
    width: 327px;
  }

  @media (min-width: ${device.tablet}) {
    width: 416px;
  }
  @media (min-width: ${device.laptop}) {
    width: 416px;
    margin-bottom: 144px;
  }
`;

export const EditIcon = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15%;
  width: 24px;
  cursor: pointer;

  @media (min-width: ${device.tablet}) {
    top: 66%;
    right: 15%;
  }
`;

export const ConfirmButton = styled(Button)`
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.15px;
  margin: 56px auto 0;
  width: auto;
  max-width: initial;
`;

export const ContentDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLoader = styled(MainLoader)`
  margin: 200px auto 0;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, 0);
`;
