import styled from 'styled-components';
import {AddToCartForm} from 'components/upselling/AddToCartForm/AddToCartForm';
import {AddToCartButton} from 'components/upselling/AddToCartButton';

export const StyledAddToCartForm = styled(AddToCartForm)`
  color: ${({theme}) => theme.colors.branding.fontPrimary};
  width: 100%;
`;

export const MobileActionPanel = styled.div`
  color: ${({theme}) => theme.colors.branding.fontPrimary};
  display: flex;
  gap: 16px;
  background: ${props => props.theme.colors.branding.boxesModals};
  box-shadow: 0 2px 10px rgba(147, 150, 174, 0.25);
  padding: 24px;
  align-items: center;
`;

export const ExperienceButton = styled(AddToCartButton)`
  min-width: unset;
  flex-grow: 1;
  flex-basis: 0;
`;
