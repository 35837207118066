import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useModalControls, usePaymentSettings} from '../../../hooks';
import {SecurityDepositInfoModal} from './SecurityDepositInfoModal';
import {DepositCard} from '../../../components/DepositCard/DepositCard';
import {INSURANCE_TYPES} from '@guestapp/sdk';

type SecurityDepositCardProps = {
  value: INSURANCE_TYPES | null;
  onChange: (value: INSURANCE_TYPES | null) => void;
  amount?: string;
  activated?: boolean;
  checkbox?: boolean;
  // isPreAuthActive true is Retention type, false is Pre-auth
  isPreAuthActive?: boolean;
  disabled?: boolean;
};
function SecurityDepositCard({
  value,
  amount,
  onChange,
  activated,
  checkbox,
  disabled,
  // isPreAuthActive true is Retention type, false is Pre-auth
  isPreAuthActive,
}: SecurityDepositCardProps) {
  const {t} = useTranslation();
  const {currencySymbol} = usePaymentSettings();
  const {
    isOpen: isInfoModalOpen,
    openModal: openInfoModal,
    closeModal: closeInfoModal,
  } = useModalControls();

  const handleOnChange = () => {
    if (!checkbox) return;
    const newValue =
      value === INSURANCE_TYPES.securityDeposit ? null : INSURANCE_TYPES.securityDeposit;

    onChange(newValue);
  };

  if (!activated) return null;
  return (
    <>
      <DepositCard
        title={t('security_deposit')}
        onMoreInfo={openInfoModal}
        selected={value === INSURANCE_TYPES.securityDeposit}
        onChange={handleOnChange}
        checkbox={checkbox}
        disabled={disabled}
        className="security-deposit-card"
        children={
          <>
            {isPreAuthActive ? (
              <Trans
                i18nKey="security_retention_box_text"
                tOptions={{
                  amount,
                  currencySymbol,
                }}
              />
            ) : (
              <Trans
                i18nKey="security_pre_auth_box_text"
                tOptions={{
                  amount,
                  currencySymbol,
                }}
              />
            )}
          </>
        }
      />
      <SecurityDepositInfoModal
        isOpen={isInfoModalOpen}
        close={closeInfoModal}
        isPreActivated={isPreAuthActive}
      />
    </>
  );
}

export {SecurityDepositCard};
