import {dynamicRoute} from './utils';
import {apiClient, FetcherSettings} from './apiClient';
import {
  BookingSummary,
  FetchBookingReferenceParams,
  FetchReservationParams,
  Paginated,
  ReservationPayload,
  ShortReservation,
} from '../utils/types';

class ReservationApi {
  ENDPOINTS = {
    all: (params = '') => `api/v3/reservations/?${params}`,
    getOne: dynamicRoute({
      regular: (id = '') => `api/v4/reservations/${id}/`,
      sdk: (id = '') => `sdk/api/v1/reservations/${id}/`,
    }),
    getOneV3: dynamicRoute({
      regular: (id = '') => `api/v3/reservations/${id}/`,
      sdk: (id = '') => `sdk/api/v1/reservations/${id}/`,
    }),
    getByExternalId: dynamicRoute({
      regular: (id = '') =>
        `api/v4/reservations/get-with-external-id/?external_id=${encodeURIComponent(id)}`,
      sdk: (id = '') =>
        `sdk/api/v1/reservations/get-with-external-id/?external_id=${encodeURIComponent(
          id,
        )}`,
    }),
    getSummary: dynamicRoute({
      regular: (id = '') => `api/v4/guestapp/reservations/${id}/summary/`,
      sdk: (id = '') => `sdk/api/v1/guestapp/reservations/${id}/summary/`,
    }),
  };

  async getOne(
    {id, externalId}: FetchReservationParams,
    fetcherSettings?: FetcherSettings,
  ) {
    const endpoint = externalId
      ? this.ENDPOINTS.getByExternalId(externalId)
      : this.ENDPOINTS.getOne(id);

    const response = await apiClient.get<ShortReservation>(endpoint, fetcherSettings);

    return response.body;
  }

  async summary(id: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<BookingSummary>(
      this.ENDPOINTS.getSummary(id),
      fetcherSettings,
    );
    return response.body;
  }

  async getBookingReference(
    {id, booking_reference}: FetchBookingReferenceParams,
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient.get<Paginated<ShortReservation>>(
      this.ENDPOINTS.all(
        `housing_id=${id}&booking_reference=${encodeURIComponent(booking_reference)}`,
      ),
      fetcherSettings,
    );
    return response.body;
  }

  async createReservation(
    payload: ReservationPayload,
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient.post<ShortReservation>(this.ENDPOINTS.all(), {
      json: payload,
      ...fetcherSettings,
    });
    return response.body;
  }

  async updateReservation(
    id: string,
    payload: ReservationPayload,
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient.patch<ShortReservation>(
      this.ENDPOINTS.getOneV3(id),
      {
        json: payload,
        ...fetcherSettings,
      },
    );
    return response.body;
  }
}

export {ReservationApi};
