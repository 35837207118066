import styled from 'styled-components';
import Page from 'components/Page';
import {Box} from 'styled/common';
import Button from 'components/common/Button';
import {device} from '@guestapp/ui/src/utils/device';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
`;

export const TitleText = styled.span`
  min-width: 0;
  font-weight: 500;
  word-wrap: break-word;
`;

export const MobileContentTitle = styled.div`
  word-wrap: break-word;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
`;

export const StyledPage = styled(Page)`
  color: ${({theme}) => theme.colors.branding.fontPrimary};
`;

export const ContentImg = styled.img`
  height: 395px;
  width: 100%;
  object-fit: cover;

  @media (max-width: ${device.tablet}) {
    height: 191px;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: center;
  gap: 32px;
`;

export const Description = styled.div`
  white-space: pre-line;
  min-width: 0;
  word-wrap: break-word;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;
export const Details = styled.div`
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  flex-grow: 1;
  gap: 48px;
`;

export const AddToCartFormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 380px;
  height: unset;
  flex-grow: 0;
  flex-shrink: 1;
  padding: 16px;
  gap: 48px;
`;

export const MobileActionPanel = styled.div`
  color: ${({theme}) => theme.colors.branding.fontPrimary};
  display: flex;
  gap: 16px;
  background: ${props => props.theme.colors.branding.boxesModals};
  box-shadow: 0 2px 10px rgba(147, 150, 174, 0.25);
  padding: 24px;
  align-items: center;
`;

export const MobilePrice = styled.div`
  flex-grow: 1;
  flex-basis: 0;

  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`;

export const ExperienceButton = styled(Button)`
  min-width: unset;
  flex-grow: 1;
  flex-basis: 0;
`;
