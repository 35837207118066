import styled, {css} from 'styled-components';
import {device} from 'styled/device';
import {Button, CustomIcon} from '@guestapp/ui';
import Input from 'components/common/Input';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.p`
  width: 300px;
`;

export const InputContainer = styled.div`
  position: relative;
  margin-bottom: 24px;
`;

export const ContentCustomIcon = styled.div`
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const StyledCustomIcon = styled(CustomIcon)`
  path,
  rect {
    fill: ${({theme}) => theme.colors.branding.primary};
  }
`;

export const CopyIcon = styled.img`
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const StyledButton = styled(Button).attrs({
  className: 'share-link-modal__btn',
})`
  margin-bottom: 18px;
  width: 100%;
  padding: 9px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  transition: all 0.4s;
  box-shadow: none;
`;

export const Wrapper = styled.div`
  text-align: center;
  width: 100%;
`;

export const OrShareWithText = styled.div`
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.15px;
  color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.fontPrimary
      : props.theme.colors.$p10};
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.36px;
  color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.fontPrimary
      : props.theme.colors.$p10};
  margin-bottom: 24px;

  @media (min-width: ${device.tablet}) {
    font-size: 18px;
  }
`;

export const LinkCopiedText = styled.div`
  font-size: 14px;
  margin-top: 5px;
  position: absolute;
  color: #15cd72;
  right: 0;
`;

export const StyledInput = styled(Input).attrs({
  className: 'share-link-modal__input',
})<{disabled: boolean}>`
  & fieldset {
    border-color: ${({theme}) => theme.colors.branding.primary};
  }
  ${props =>
    props.disabled &&
    css`
      opacity: 1;
      background-color: ${({theme}) => theme.colors.branding.boxesModals};
      color: ${({theme}) => theme.colors.branding.fontPrimary};
    `}
`;
