import {GuestTaxExemption, NONE_EXEMPTION} from '@guestapp/sdk';

const ChildrenExemptionsIDs = {
  childWithSpecialNeeds: '4',
};

class TaxGuestListEntity {
  list: GuestTaxExemption[];
  ids: string[];

  constructor(guestTaxesList: GuestTaxExemption[] = []) {
    this.list = [...guestTaxesList];
    this.ids = guestTaxesList.map(guest => guest.id);
  }

  private updatedIds() {
    this.ids = this.list.map(guest => guest.id);
  }

  public checkIfExistById(id?: string) {
    if (!id) return false;
    return this.ids.includes(id);
  }

  public checkIfExemptionExistsByGuestId(id?: string) {
    if (!id) return false;
    return !!this.list.find(
      item => item.guest_id === id && item.exemption !== NONE_EXEMPTION,
    );
  }

  public updateOne(id: string, data: Partial<GuestTaxExemption>) {
    if (!this.checkIfExistById(id)) return this;
    this.list = this.list.map(guest => {
      if (guest.id === id) return {...guest, ...data};
      return guest;
    });

    this.updatedIds();
    return this;
  }

  public addExemption(data: GuestTaxExemption) {
    const listWithoutExemptions = this.list.filter(
      guest => !guest.exemption || guest.exemption === NONE_EXEMPTION,
    );
    const childrenExemptionsIdsList = Object.values(ChildrenExemptionsIDs);

    if (this.checkIfExistById(data.id)) {
      this.updateOne(data.id, data);
    } else if (listWithoutExemptions.length) {
      const exemption = data.exemption ?? '';
      const isExemptionForChildren = childrenExemptionsIdsList.includes(exemption);
      const sortedListByAges = listWithoutExemptions.sort((a, b) => {
        return isExemptionForChildren ? b.age + a.age : b.age - a.age;
      });
      const id = sortedListByAges[0].id;

      this.updateOne(id, {exemption: data.exemption, guest_id: data.guest_id});
    }

    this.updatedIds();
    return this;
  }

  public removeExemption(id: string) {
    return this.updateOne(id, {exemption: null, guest_id: ''});
  }

  public exec() {
    return this.list;
  }
}

export default TaxGuestListEntity;
