import React from 'react';
import {useTranslation, Trans} from 'react-i18next';
import {useQueryClient} from '@tanstack/react-query';
import ChekinPro from 'ChekinPro';
import {BookingSummary} from '@guestapp/sdk';
import {useModalControls, useTimeoutRef} from 'hooks';
import {StatusModalStates} from 'components/StatusModal';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {ModalGuestRegisteredNextStepContent} from './ModalGuestRegisteredNextStep';
import {
  StyledModalGuestRegistered,
  ModalSubtitle,
  ModalHeader,
  Row,
  ModalBody,
  ModalTitleText,
  ErrorMessage,
  CloseButton,
  StyledModalSheet,
} from './styled';

const getTitle = (state?: StatusModalStates, defaultTitle?: string) => {
  if (state === StatusModalStates.LOADING) {
    return 'sending_your_data';
  } else if (defaultTitle) {
    return defaultTitle;
  } else if (state === StatusModalStates.ERROR) {
    return 'error';
  } else {
    return 'guest_registered';
  }
};

type ModalTypes = {
  resetForm?: () => void;
  onClose: () => void;
  name?: string;
  state?: StatusModalStates;
  errorMessage?: string;
  modalTitle?: string;
  isEdition?: boolean;
  hasAllianzInsurance?: boolean;
};

function ModalGuestRegistered({
  name,
  state,
  onClose,
  errorMessage,
  modalTitle,
  isEdition,
  hasAllianzInsurance = false,
}: ModalTypes) {
  const {t} = useTranslation();
  const title = getTitle(state, modalTitle);
  const timeoutRef = useTimeoutRef();
  const queryClient = useQueryClient();
  const {
    isOpen: isOpenNextStepModal,
    openModal: openNextStepModal,
    closeModal: closeNextStepModal,
  } = useModalControls();
  const finishProcess = React.useCallback(() => {
    const summary = queryClient.getQueryData<BookingSummary>([QUERY_CACHE_KEYS.summary]);
    const isAllRegistered = summary?.guest_registration?.status === 'COMPLETE';
    if (isAllRegistered) {
      ChekinPro.events.onAllGuestsRegistered();
    }
    if (hasAllianzInsurance) {
      onClose();
    } else {
      openNextStepModal();
    }
  }, [onClose, queryClient, hasAllianzInsurance, openNextStepModal]);

  React.useEffect(() => {
    if (state === StatusModalStates.SUCCESS) {
      timeoutRef.current = setTimeout(() => {
        finishProcess();
      }, 3000);
    }
  }, [finishProcess, state, timeoutRef]);

  return (
    <>
      <StyledModalGuestRegistered>
        {state !== StatusModalStates.IDLE && (
          <ModalHeader>
            <Row className="modalTitle">
              <ModalTitleText>{t(title)}</ModalTitleText>
            </Row>
          </ModalHeader>
        )}
        <ModalBody>
          {Boolean(openNextStepModal) && (
            <Row>
              <ModalSubtitle>
                <>
                  {state === StatusModalStates.LOADING && (
                    <>{t('it_could_take_seconds')}</>
                  )}
                  {state === StatusModalStates.SUCCESS && (
                    <>
                      {isEdition ? (
                        <Trans i18nKey="guest_updated_modal_description" values={{name}}>
                          <b>{name}</b> has been successfully updated.
                        </Trans>
                      ) : (
                        <Trans
                          i18nKey="guest_registered_modal_description"
                          values={{name}}
                        >
                          <b>{name}</b> has been successfully registered.
                        </Trans>
                      )}
                    </>
                  )}
                  {state === StatusModalStates.ERROR && (
                    <>
                      <ErrorMessage>{errorMessage}</ErrorMessage>
                      <CloseButton
                        width={195}
                        primary
                        label={t('ok')}
                        onClick={onClose}
                      />
                    </>
                  )}
                </>
              </ModalSubtitle>
            </Row>
          )}
        </ModalBody>
      </StyledModalGuestRegistered>
      <StyledModalSheet
        isOpenModalSheet={isOpenNextStepModal}
        handleCloseModal={closeNextStepModal}
        closeOnDocumentClick={false}
        withModalMobileCloseButton={false}
        closeButtonType="none"
      >
        <ModalGuestRegisteredNextStepContent />
      </StyledModalSheet>
    </>
  );
}

export {ModalGuestRegistered};
