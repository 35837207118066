import {useTranslation} from 'react-i18next';
import {Description, Wrapper} from './styled';

type Props = {
  error: unknown;
};
function ErrorFallback({error}: Props) {
  const {t} = useTranslation();

  return (
    <Wrapper role="alert">
      <Description>⚠ {t('something_went_wrong')}</Description>
      {typeof error === 'string' && (
        <Description>
          {error}. {t('please_contact_support')}
        </Description>
      )}
    </Wrapper>
  );
}

export {ErrorFallback};
