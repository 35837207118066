import styled from 'styled-components';

export const AllianzModalContent = styled.div`
  display: block;
  & .gif-container {
    width: 90px;
    height: 90px;
    margin: 0 auto 20px;
    & img {
      display: block;
      width: 100%;
    }
  }
`;

export const AllianzModalBoldText = styled.div`
  font-family: Poppins;
  font-size: ${props => props.theme.fonts.sizes.large};
  font-weight: ${props => props.theme.fonts.fontWeight.mediumL};
  line-height: 24px;
  letter-spacing: 0.36000001430511475px;
  text-align: center;
  color: ${props => props.theme.colors.$p10};
  margin-bottom: 40px;
`;

export const AllianzModalSubText = styled.div`
  font-family: Poppins;
  font-size: ${props => props.theme.fonts.sizes.$medium};
  font-weight: ${props => props.theme.fonts.fontWeight.$regular};
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
`;
