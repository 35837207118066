import React, {useCallback, useState} from 'react';
import {UseFormSetError} from 'react-hook-form';
import {getErrorMessage} from '@guestapp/core';
import {FormTypes} from '../../utils/guestForm';
import {AddFormType} from '../../context/guest';
import {FieldsErrorResponse} from '@guestapp/sdk';
import {GuestFormErrorModal} from '../GuestFormErrorModal';

type UseErrorHandlerProps = {
  setError: UseFormSetError<FormTypes>;
  onDataIncomplete: () => void;
  fields?: AddFormType;
};
export function useErrorHandler({
  setError,
  fields,
  onDataIncomplete,
}: UseErrorHandlerProps) {
  const [fieldErrors, setFieldErrors] = useState<string | null>(null);

  const GuestErrorModal = useCallback(
    () => (
      <GuestFormErrorModal
        isOpen={Boolean(fieldErrors)}
        onClose={() => setFieldErrors(null)}
        fieldErrors={fieldErrors}
      />
    ),
    [fieldErrors],
  );

  const displayFieldErrors = useCallback(
    (error: FieldsErrorResponse) => {
      if (error?.errors?.length) {
        let isThereFieldError = false;
        error.errors
          .filter(error => fields?.[error.field]?.active)
          .forEach(error => {
            const fieldError = error.field;
            const messageError = error.message;
            isThereFieldError = true;
            setError(
              fieldError,
              {type: 'custom', message: messageError},
              {shouldFocus: true},
            );
          });

        const nonFieldsErrorsMessages = error.errors.filter(
          error => !fields?.[error.field]?.active || error.field === 'non_field_errors',
        );

        if (nonFieldsErrorsMessages.length) {
          const errorMessage = getErrorMessage({
            errors: nonFieldsErrorsMessages,
          });

          setFieldErrors(errorMessage);
          return;
        }

        if (isThereFieldError) {
          onDataIncomplete();
          return;
        }
      }
    },
    [fields, setError, onDataIncomplete],
  );

  return {GuestErrorModal, displayFieldErrors};
}
