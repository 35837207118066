import styled, {css} from 'styled-components';
import {device} from 'styled/device';
import Button from '../common/Button';
import Loading from '../common/Loading';
import ModalSheet from 'components/common/ModalSheet';
import {MODAL_FORMS} from './constants';

export const ButtonConfirm = styled(Button)`
  margin-top: 30px;
  max-width: none;

  width: 100%;
  margin-bottom: 28px;
  @media (min-width: ${device.mobileXL}) {
    margin-bottom: 0;
  }
`;

export const Error = styled.div`
  align-self: baseline;
`;

type ContentProps = {
  isLoading?: boolean;
};

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  height: ${props => (props.isLoading ? '110px' : 'auto')};
  transition: opacity 0.3s ease-in-out;
  width: 100%;

  .loading-spinner {
    @media (min-width: ${device.tablet}) {
      margin-top: 25px;
      padding: 0;
    }
  }

  @media (min-width: ${device.tablet}) {
    padding: 0;
  }
`;

export const ErrorContainer = styled.div``;
export const ErrorMessage = styled.div`
  font-family: inherit;
  text-align: left;
  color: #f84b7a;
  font-size: 11px;
`;

export const StyledLoader = styled(Loading)`
  margin-top: 0;

  @media (max-width: ${device.mobileXL}) {
    margin-top: 25px;
    svg {
      width: 70px;
    }
  }
`;

export const StyledModalSheet = styled(ModalSheet)<{
  modalSection: MODAL_FORMS;
}>`
  ${props =>
    props.modalSection === MODAL_FORMS.reservationType &&
    css`
      &-content {
        width: 404px;
        & .modal__title {
          margin-bottom: 32px;
        }
      }
    `}

  ${props =>
    props.modalSection === MODAL_FORMS.confirmLeaderGuest &&
    css`
      &-content {
        width: 375px;
      }
    `}
`;

export const RadiosContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
  & .multi-radio {
    gap: 16px;
  }

  & .multi-radio__label {
    color: #152946;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const ConfirmGuestText = styled.div`
  margin-bottom: 40px;
  margin-top: 28px;
  color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.fontPrimary : '#152946'};
  @media (min-width: ${device.mobileXL}) {
    margin-top: 0;
  }
`;

export const RadiosContent = styled.div`
  & .multi-radio {
    gap: 16px;
  }
  & .multi-radio__option {
    border-radius: 12px;
    border: 1px solid
      ${({theme}) =>
        theme.hasTemplate ? theme.colors.branding.backgroundMain : 'rgba(0, 0, 0, 0.1)'};
    background: ${({theme}) =>
      theme.hasTemplate ? theme.colors.branding.boxesModals : '#fff'};
    display: block;
    padding: 16px 24px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    & .multi-radio__label {
      &:before {
        margin-right: 1em;
      }
    }
    &.multi-radio-option_checked {
      /* border: 1px solid var(--Brand-color-Primary, #385BF8); */
      border: 1px solid #385bf8;
    }
  }
`;
