import ChekinAPI from '../../ChekinAPI';
import {getTokenFromStorage, LS_I18NEXT_LNG_KEY, ORIGINS} from '../../utils';
import {
  BASE_API_URL,
  CORS_ERRORS,
  ERR_INTERNET_DISCONNECTED_MESSAGE,
  FAILURE_RESPONSE_STATUSES,
  MAX_RETRIES_NUMBER,
  RETRY_RESPONSE_STATUSES,
} from './constants';
import {RequestOptions, ResponseError} from './types';
import {LocalStorage} from '@guestapp/core';

function getURL(endpoint = '') {
  return `${BASE_API_URL}/${endpoint}`;
}

function getAndHandleResponseError<T>(response?: Response, body?: T, message?: string) {
  const status = response?.status;

  if (message && CORS_ERRORS.includes(message)) {
    return {
      message: `You have not internet connection.`,
      status: 1001,
      body,
      name: ERR_INTERNET_DISCONNECTED_MESSAGE,
    };
  }

  if (!response) {
    return null;
  }

  if (response && FAILURE_RESPONSE_STATUSES.includes(response.status)) {
    if (body) {
      return {...body, status};
    }

    return body;
  }

  if (response?.status === 401) {
    // Sentry.captureMessage(
    //   `Error 401: ${response.url}. Headers: ${JSON.stringify(
    //     getAuthAndBaseHeaders(),
    //   )}. Request init: ${stringifySentryRequestInit(options)}`,
    // );
  }

  if (response?.status === 404) {
    // Sentry.captureMessage(
    //   `Error 404: ${response.url}. Request init: ${stringifySentryRequestInit(options)}`,
    // );

    return {
      message: `404 Not found. Please contact support.`,
      status,
      body,
    };
  }

  if (response?.status === 405) {
    // Sentry.captureMessage(
    //   `Error 405: ${response.url}. Request init: ${stringifySentryRequestInit(options)}`,
    // );

    return {
      message: `405 Method not allowed. Please contact support.`,
      status,
      body,
    };
  }

  if (response?.status === 500) {
    // Sentry.captureMessage(
    //   `Error 500: ${response.url}. Request init: ${stringifySentryRequestInit(options)}`,
    // );

    return {
      message: '500 Internal Server error. Please contact support.',
      status,
      body,
    };
  }

  if (response?.status === 502) {
    // Sentry.captureMessage(
    //   `Error 502: ${response.url}. Request init: ${stringifySentryRequestInit(options)}`,
    // );

    return {
      message: '502 Bad Gateway. Please contact support.',
      status,
      body,
    };
  }

  if (response?.status === 503) {
    // Sentry.captureMessage(
    //   `Error 503: ${response.url}. Request init: ${stringifySentryRequestInit(options)}`,
    // );

    return {
      message: '503 Service Unavailable. Please contact support.',
      status,
      body,
    };
  }

  if (response?.status === 504) {
    // Sentry.captureMessage(
    //   `Error 504: ${response.url}. Request init: ${stringifySentryRequestInit(options)}`,
    // );

    return {
      message: '504 Gateway Timeout. Please contact support.',
      status,
      body,
    };
  }

  return null;
}

function defaultRetryDelay(failureCount: number) {
  return Math.min(1000 * 2 ** failureCount, 30000);
}

function sleep(timeout: number): Promise<void> {
  return new Promise(resolve => {
    setTimeout(resolve, timeout);
  });
}

const checkIsResponseError = <T>(
  error: unknown | ResponseError<T>,
): error is ResponseError<T> => {
  return 'response' in (error as ResponseError<T>);
};

const checkIsPlainError = (error: unknown | Error): error is Error => {
  return 'message' in (error as Error);
};

const buildRequestHeaders = (
  initHeaders?: RequestInit['headers'] | null,
  publicAccess?: boolean,
) => {
  const storedApiKey = ChekinAPI.initParams?.apikey;
  const isChekinConsumer = ChekinAPI.initParams?.isChekinConsumer;
  const headers = new Headers(initHeaders || {'Content-Type': 'application/json'});

  headers.set(
    'X-source',
    isChekinConsumer ? ORIGINS.guestAppV2 : ORIGINS.embeddedGuestApp,
  );

  if (!isChekinConsumer) {
    headers.set('embedded-sdk-app', storedApiKey);
  }

  if (publicAccess) {
    if (!headers.has('Content-Type')) headers.set('Content-Type', 'application/json');
    return headers;
  }

  const token = getTokenFromStorage();
  const language = LocalStorage.get<string>(LS_I18NEXT_LNG_KEY);

  const languageCode = language?.slice(0, 2);

  if (languageCode) headers.set('Accept-Language', languageCode);
  if (token) headers.set('Authorization', `JWT ${token}`);

  return headers;
};

function shouldRetry(
  {retryCount = 0, errorRetryLimit}: RequestOptions,
  error: {response: Response} | {message: string},
) {
  const globalMaxRequestsRetriesCount = ChekinAPI.initParams?.maxRequestsRetriesCount;
  const maxRetryCount =
    errorRetryLimit ?? globalMaxRequestsRetriesCount ?? MAX_RETRIES_NUMBER;
  const isPlainError = checkIsPlainError(error) && CORS_ERRORS.includes(error?.message);
  const isResponseError =
    checkIsResponseError(error) &&
    RETRY_RESPONSE_STATUSES.includes(error?.response?.status);

  return (isPlainError || isResponseError) && retryCount < maxRetryCount;
}

export {
  getURL,
  getAndHandleResponseError,
  defaultRetryDelay,
  sleep,
  checkIsResponseError,
  checkIsPlainError,
  buildRequestHeaders,
  shouldRetry,
  CORS_ERRORS,
};
