import {useQuery} from '@tanstack/react-query';
import {AllianzDocument} from '@guestapp/sdk/src/utils/allianz/';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {useChekinSDK} from 'context/ChekinSDK';

type UseFetchAllianzPlansProps = {
  params: string;
  onSuccess?: (document: AllianzDocument[]) => void;
  onError?: (e: Error) => void;
  enabled?: boolean;
};
function useFetchAllianzDocuments(
  {params, onSuccess, onError, enabled}: UseFetchAllianzPlansProps = {params: ''},
) {
  const {getAllianzDocuments} = useChekinSDK();
  const queryData = useQuery<AllianzDocument[], Error>(
    [QUERY_CACHE_KEYS.allianzDocuments, params],
    () => getAllianzDocuments(params), // for dev
    {
      enabled: enabled,
      onError,
      onSuccess,
      refetchOnWindowFocus: false,
    },
  );
  return {...queryData};
}

export {useFetchAllianzDocuments};
