import i18n from 'i18next';
import {AllianzPlan} from './types';
import {ShortGuest, AllianzOrder, AllianzPlanFromDb} from '@guestapp/sdk';
import {AllianzPlansTypes} from 'pages/Allianz/constants';

type BuildPlanDataProps = {
  plan: AllianzPlanFromDb;
  currencySymbol?: string;
};

const getFeatures = (planFeatures: Record<string, string[]>) => {
  const currentLanguage = i18n.language;
  return planFeatures[currentLanguage.toUpperCase()] || planFeatures['ES'];
};

const getTranslatedPlanName = (names?: Record<string, string>, language?: string) => {
  const currentLanguage = language ?? 'EN';
  return names?.[currentLanguage.toUpperCase()] || names?.['ES'] || '';
};

const buildPlanData = ({plan, currencySymbol}: BuildPlanDataProps): AllianzPlan[] => {
  const addon = Boolean(plan.addons.length)
    ? {...plan.addons[0], currencySymbol: currencySymbol}
    : undefined;
  return [
    {
      id: plan?.id,
      type: plan?.plan_type,
      title: plan?.name,
      price: plan?.total_price_for_guest,
      currency: currencySymbol,
      marketplace_item: plan?.marketplace_item,
      featureLists: getFeatures(plan.features),
      selected: true,
      addon,
    },
    {
      id: '3',
      title: {EN: 'No Plan', ES: 'Sin Plan'},
      type: AllianzPlansTypes.noPlan,
      selected: false,
    },
  ];
};

const getCreateOrUpdateBulkLockPayload = (
  dealIds: string[],
  metadata: {personal_data_accepted: boolean; email?: string},
) => {
  return {
    third_party_deals: dealIds,
    trigger: 'GUEST',
    metadata,
  };
};

const getUnPaidOrders = (orders?: AllianzOrder[]) => {
  return (orders ?? []).filter(order => !order.paid);
};

const getOrdersToPay = (orders: AllianzOrder[]) => {
  return orders.map(order => {
    if (Boolean(order?.plan_order?.addons.length)) {
      return [order.plan_order?.id, order.plan_order?.addons[0].id];
    }
    return order.plan_order.id;
  });
};

const getValidOrder = (
  members: ShortGuest[] = [],
  allianzOrders: AllianzOrder[] = [],
) => {
  const order = members
    .filter(member => member.residence_country === 'ES')
    .map(member => allianzOrders.find(order => order.guest_id === member.id))
    .find(order => order !== undefined);
  return order;
};

export {
  buildPlanData,
  getCreateOrUpdateBulkLockPayload,
  getUnPaidOrders,
  getOrdersToPay,
  getTranslatedPlanName,
  getValidOrder,
};
