import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonProps} from '../../../../components/common/Button/Button';
import StandardModalSheet from '../../../../components/common/StandardModalSheet';
import {RemoveExemptionModalSheet} from './styled';

type RemoveExemptionModalProps = {
  isOpen: boolean;
  onConfirm: () => void;
  close: () => void;
};
const RemoveExemptionModal = memo(
  ({onConfirm, isOpen, close}: RemoveExemptionModalProps) => {
    const {t} = useTranslation();

    const buttons: ButtonProps[] = React.useMemo(() => {
      const deleteButton: ButtonProps = {
        label: t('remove_exemption'),
        primary: true,
        onClick: onConfirm,
      };

      const cancelButton: ButtonProps = {
        label: t('cancel'),
        link: true,
        onClick: close,
      };

      return [deleteButton, cancelButton];
    }, [t, onConfirm, close]);

    return (
      <RemoveExemptionModalSheet
        title={t('do_you_want_to_remove_this_exemption')}
        isOpenModalSheet={isOpen}
        handleCloseModal={close}
        maxWidth={'375px'}
        className="remove-exemp-modal"
      >
        <StandardModalSheet buttons={buttons} />
      </RemoveExemptionModalSheet>
    );
  },
);

export {RemoveExemptionModal};
