import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-row-gap: 4px;
`;

export const ContentIconStripe = styled.div`
  width: 90px;
  height: 20px;
`;
