import {useTranslation} from 'react-i18next';
import dataIncompleteIcon from 'assets/icons/incomplete.svg';
import Button from '../common/Button';
import {ModalSheet} from '../common/ModalSheet/ModalSheet';
import {ContentModal} from './styled';

type GuestFormErrorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  fieldErrors: null | string;
};

export function GuestFormErrorModal({
  isOpen,
  onClose,
  fieldErrors,
}: GuestFormErrorModalProps) {
  const {t} = useTranslation();

  return (
    <ModalSheet isOpenModalSheet={isOpen} handleCloseModal={onClose} withCloseButton>
      <ContentModal className="guest-error-modal">
        <img
          src={dataIncompleteIcon}
          width={50}
          height={50}
          alt={t('form_with_red_field')}
        />
        <div className="guest-error-modal__title">{t('error')}</div>
        <div className="guest-error-modal__text">
          {fieldErrors?.split(';').map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
        <div className="guest-error-modal__buttons">
          <Button onClick={onClose} label={t('ok')} width={270} />
        </div>
      </ContentModal>
    </ModalSheet>
  );
}
