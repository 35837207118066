import {isBrowser} from '../utils/common';
import {LocalStorage} from './LocalStorage';
import {MemoryStorage} from './MemoryStorage';

class StorageFactory {
  create() {
    if (isBrowser()) {
      return LocalStorage;
    } else {
      return MemoryStorage;
    }
  }
}

const factory = new StorageFactory();
const Storage = factory.create();
export {Storage};
