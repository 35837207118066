import {useQuery} from '@tanstack/react-query';
import {OrderHistory} from '@guestapp/sdk';
import {useChekinSDK} from '../../context/ChekinSDK';
import {QUERY_CACHE_KEYS} from '../../utils/constants';
import {useReservation} from '../../context/reservation';

const CACHE_TIME = 10_000;
const STALE_TIME = 10_000;

type UseOrderHistoryProps = {
  refetchOnWindowFocus?: boolean;
};
function useOrderHistory({refetchOnWindowFocus = false}: UseOrderHistoryProps = {}) {
  const {getOrderHistory, isTokenValid} = useChekinSDK();
  const {reservationId} = useReservation();

  const {
    data: orderHistory,
    isLoading: isOrderHistoryLoading,
    isFetching: isOrderHistoryFetching,
  } = useQuery<OrderHistory>(
    [QUERY_CACHE_KEYS.orderHistory],
    () => getOrderHistory(`reservation_id=${reservationId}`),
    {
      keepPreviousData: true,
      enabled: Boolean(reservationId && isTokenValid),
      refetchOnWindowFocus,
      cacheTime: CACHE_TIME,
      staleTime: STALE_TIME,
    },
  );

  return {
    orderHistory,
    isOrderHistoryLoading,
    isOrderHistoryFetching,
  };
}

export {useOrderHistory};
