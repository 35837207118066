import {apiClient, FetcherSettings} from './apiClient';
import {Housing, HousingLocation} from '../utils/types';
import {SearchReservationPayload} from '../services';
import {dynamicRoute} from './utils';

class HousingApi {
  ENDPOINTS = {
    getOne: dynamicRoute({
      regular: (id = '') => `api/v4/housings/${id}/`,
      sdk: (id = '') => `sdk/api/v2/housings/${id}/`,
    }),
    searchReservation: (id: string) =>
      `api/v3/housings/${id}/search-existing-reservation/`,
  };

  async getOne(id: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<Housing>(
      this.ENDPOINTS.getOne(id),
      fetcherSettings,
    );
    return response.body;
  }

  async searchReservation(
    id: string,
    payload: SearchReservationPayload,
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient.post<{
      reservation_id?: string;
      signup_form_link: string;
    }>(this.ENDPOINTS.searchReservation(id), {
      json: payload,
      ...fetcherSettings,
    });

    return response.body;
  }

  // TODO check the access
  async getLocation(fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<HousingLocation>('', fetcherSettings);
    return response.body;
  }
}

export {HousingApi};
