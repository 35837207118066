import {useCallback, useMemo} from 'react';
import {DefaultTheme, useTheme} from 'styled-components';
import {Trans, useTranslation} from 'react-i18next';
import {useSummary} from 'context/summary';
import {usePropertyProtection} from './usePropertyProtection';
import {PATHS} from 'Routes';
import {SUMMARY_PAYMENTS_STATUSES} from '@guestapp/sdk';
import {useGuestGroup} from './useGuestGroup';
import {useSchema} from 'context/guest';
import {useReservation} from 'context/reservation';
import {useFirstGuestMustBeAdult} from 'hooks/useFirstGuestMustBeAdult';

enum NAVIGATION_STATUSES {
  complete = 'COMPLETE',
  incomplete = 'INCOMPLETE',
}

const getBookingPaymentsStatus = (...statuses: Array<string | null | undefined>) =>
  statuses
    .filter(status => !!status && status !== SUMMARY_PAYMENTS_STATUSES.inactive)
    .every(status => status === SUMMARY_PAYMENTS_STATUSES.complete);

const getColor = (theme: DefaultTheme, defaultColor: string, isCompleted?: boolean) => {
  return isCompleted && theme.hasTemplate ? theme.colors.branding.icons : defaultColor;
};

function useBuildNavigationItems() {
  const {t} = useTranslation();
  const theme = useTheme();
  const {data, isSuccess} = useSummary();
  const {guestGroup} = useGuestGroup();
  const {hasAvantio} = useReservation();
  const {
    isMultipleDeposits,
    isSecurityDepositOptional,
    isWaivoProtectionMandatory,
    isInsuranceSetupInit,
    isSecurityDeposit,
    isWaivoProtection,
    insuranceSetup,
  } = usePropertyProtection();
  const {
    identityVerificationDetails: {enabled: ivEnabled},
  } = useSchema();
  const {firstGuestMustBeAdult: shouldFirstGuestBeAdult} = useFirstGuestMustBeAdult();
  const hasNoGuests = guestGroup?.added_number_of_guests === 0;
  const isBookingPaymentsCompleted = getBookingPaymentsStatus(
    data?.taxes?.status,
    data?.property_protection?.status,
    data?.booking_payments?.status,
  );

  const getRegistrationPath = useCallback(() => {
    if (hasAvantio) {
      return PATHS.guestRegistration;
    }
    if (ivEnabled) {
      if (hasNoGuests) {
        return PATHS.verification.main;
      } else {
        return PATHS.guestRegistration;
      }
    } else {
      if (hasNoGuests) {
        return PATHS.addPersonalDataForm;
      } else {
        return PATHS.guestRegistration;
      }
    }
  }, [ivEnabled, hasNoGuests, hasAvantio]);
  const getPaymentPath = useCallback(() => {
    if (isBookingPaymentsCompleted) {
      return PATHS.payments.ordersHistory;
    }

    if (Boolean(insuranceSetup)) {
      return PATHS.payments.main;
    }
    const isCurrentSecurityDepositOptional =
      isSecurityDeposit && isSecurityDepositOptional;
    const isWaivoOptional = isWaivoProtection && !isWaivoProtectionMandatory;

    if (
      isInsuranceSetupInit &&
      (isMultipleDeposits || isCurrentSecurityDepositOptional || isWaivoOptional)
    ) {
      return PATHS.deposits;
    } else {
      return PATHS.payments.main;
    }
  }, [
    isMultipleDeposits,
    isBookingPaymentsCompleted,
    isSecurityDepositOptional,
    isWaivoProtectionMandatory,
    isInsuranceSetupInit,
    isSecurityDeposit,
    isWaivoProtection,
    insuranceSetup,
  ]);
  const incompleteIconColor = theme.hasTemplate ? theme.colors.branding.icons : '#777E91';

  const navigationItems = useMemo(() => {
    if (!isSuccess) return [];
    const isCompletedGuestRegistration =
      data?.guest_registration?.status === NAVIGATION_STATUSES.complete;

    const nameCompleteGuestIcon =
      isCompletedGuestRegistration && theme.hasTemplate
        ? 'completeGuestIcon'
        : 'incompleteGuestIcon';

    const colorGuestRegistrationComplete = getColor(
      theme,
      incompleteIconColor,
      isCompletedGuestRegistration,
    );

    const colorBookingPaymentsComplete = getColor(
      theme,
      incompleteIconColor,
      isBookingPaymentsCompleted,
    );

    return [
      {
        title: 'guest_registration',
        completed: isCompletedGuestRegistration,
        show: /*data?.guest_registration?.editable*/ true, // PREGUNTAR QUE ONDA PORQUE ALGUNAS RESERVAS SE CREAN CON ESTO EN FALSE
        path: getRegistrationPath(),
        icon: {
          name: nameCompleteGuestIcon,
          color: colorGuestRegistrationComplete,
          custom: true,
        },
        notification: shouldFirstGuestBeAdult
          ? {
              title: t('first_guest_must_be_an_adult_title'),
              message: <Trans i18nKey="first_guest_must_be_an_adult_message" />,
            }
          : undefined,
      },
      {
        title: 'booking_payments',
        completed: isBookingPaymentsCompleted,
        show:
          data?.property_protection?.active ||
          data?.taxes.active ||
          data?.booking_payments.active,
        path: getPaymentPath(),
        icon: {
          name: 'shoppingCartCircleIcon',
          color: colorBookingPaymentsComplete,
          custom: true,
        },
      },
    ];
  }, [
    data,
    theme,
    incompleteIconColor,
    isSuccess,
    getPaymentPath,
    isBookingPaymentsCompleted,
    getRegistrationPath,
    shouldFirstGuestBeAdult,
    t,
  ]);

  const displayedNavigationItems = useMemo(() => {
    return navigationItems.filter(navItem => navItem.show);
  }, [navigationItems]);

  return {navigationItems, displayedNavigationItems, getPaymentPath};
}

export {useBuildNavigationItems};
export {NAVIGATION_STATUSES};
