import styled from 'styled-components';
import {device} from '@guestapp/ui/src/utils/device';

export const HeaderWrapper = styled.div<{visible?: boolean}>`
  width: 100%;
  z-index: 5;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: ${props => (props.visible ? 'sticky' : 'initial')};
  top: 0;
  height: 64px;
  background: ${({theme}) => theme.colors.branding.boxesModals};
  box-shadow: 0px 2px 10px 0px rgba(147, 150, 174, 0.25);

  @media (min-width: ${device.tablet}) {
    display: ${props => (props.visible ? 'block' : 'none')};
    margin-bottom: 32px;
    height: 94px;
    box-shadow: none;
    position: relative;
  }
`;

export const Container = styled.div`
  margin: auto;
  display: flex;
  width: 100%;
  align-items: center;
  height: 100%;
  padding: 0 24px;
  justify-content: space-between;
  @media (min-width: ${device.tablet}) {
    padding: 0 32px;
    height: 94px;
    background-color: ${({theme}) => theme.colors.branding.backgroundMain};
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const ContentIcons = styled.div`
  display: flex;
  gap: 4px;
`;

export const HeaderTitle = styled.div`
  font-weight: ${props => props.theme.fonts.fontWeight.bold};
  font-size: 24px;

  @media (max-width: ${device.tabletSubHeader}) {
    font-size: ${props => props.theme.fonts.sizes.medium};
    width: 100%;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  margin-right: 16px;
  width: 70px;
  & img {
    background-size: contain;
    width: auto;
    max-height: 40px;
    object-fit: cover;
  }

  @media (min-width: ${device.tablet}) {
    display: none;
  }
`;

export const Title = styled.div`
  width: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: ${props => props.theme.colors.navigationMenu.menuItemsText};
  font-family: ${props => props.theme.fonts.fontFamily.titles.font_name};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  @media (min-width: ${device.tablet}) {
    width: 100%;
    color: ${props => props.theme.colors.branding.fontPrimary};
    font-size: 28px;
  }
`;
