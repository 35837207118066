abstract class AbstractStorage {
  static get<T>(key: string): T | null {
    if (!key) {
      throw new Error('The key is not valid');
    }
    return null;
  }

  static set<T>(key: string, value: T): void {
    if (!key) {
      throw new Error('The key is not valid');
    }
    if (!value) {
      throw new Error('The value not passed');
    }
  }

  static remove(key: string): void {
    if (!key) {
      throw new Error('The key is not valid');
    }
  }

  static clear(): void {}
}

export default AbstractStorage;
