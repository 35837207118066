import React from 'react';
import createCache from '@emotion/cache';
import {CacheProvider, EmotionCache} from '@emotion/react';

type Props = {
  target?: HTMLElement;
  stylesRoot?: HTMLElement;
  children: React.ReactNode;
};

function EmotionStyleSheetProvider(props: Props): React.ReactElement {
  const [cache, setCache] = React.useState<EmotionCache | null>(null);
  const [isWithCache, setIsWithCache] = React.useState(true);

  React.useEffect(() => {
    const isAlreadyExist = Boolean(
      props.target?.querySelectorAll('[data-emotion="select-components-cache"]')?.length,
    );

    if (!props.target) {
      setIsWithCache(false);
      return;
    }

    if (isAlreadyExist) {
      return;
    }

    setCache(
      createCache({
        key: 'select-components-cache',
        container: props.target,
      }),
    );
  }, [props.target]);

  if (!isWithCache) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  return (
    <React.Fragment>
      {cache != null && <CacheProvider value={cache}>{props.children}</CacheProvider>}
    </React.Fragment>
  );
}

export {EmotionStyleSheetProvider};
