import styled, {css} from 'styled-components';
import {device} from '../../../styled/device';

type RepeatButtonProps = {
  show: boolean;
};

export const SignWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 17px;
`;

export const SignPlaceholder = styled.div`
  display: flex;
  width: 330px;
  height: 190px;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid ${props => props.theme.colors.$n7};
  border-radius: 12px;
  position: relative;
  @media (max-width: ${device.mobileS}) {
    width: 270px;
  }
  &:before {
    /* content: '';
    position: absolute;
    width: calc(100% - 14px);
    height: calc(100% - 16px);
    top: 8px;
    border: 2px dashed #6b6b95;
    border-radius: 6px;
    pointer-events: none; */
  }
`;
export const SignPlaceholderText = styled.div`
  color: ${props => props.theme.colors.$p2};
  font-style: normal;
  font-weight: ${props => props.theme.fonts.fontWeight.medium};
  font-size: ${props => props.theme.fonts.sizes.medium};
  line-height: 24px;
  margin: auto;
`;

export const SignCanvas = styled.div`
  position: relative;
  width: 330px;
  height: 190px;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.$p6};
  border: 2px solid ${props => props.theme.colors.$n7};

  @media (max-width: ${device.mobileS}) {
    width: 270px;
  }

  @media (min-width: ${device.tablet}) {
    width: 646px;
    height: 302px;
    margin: 0 auto;
    @media only screen and (min-width: ${device.tablet}) and (max-height: 601px) {
      width: auto;
      height: auto;
    }
  }
`;

export const RepeatButton = styled.button<RepeatButtonProps>`
  display: flex;
  align-items: center;
  background: transparent linear-gradient(151deg, #385cf8 0%, #2148ff 100%) 0 0 no-repeat;
  box-shadow: 0 3px 4px #00020333;
  border-radius: 6px;
  margin-top: 15px;
  font-family: inherit;
  font-size: 14px;
  text-transform: capitalize;
  color: white;
  cursor: pointer;
  padding: 0 15px;
  height: 30px;
  visibility: hidden;
  align-self: center;
  ${props =>
    props.show &&
    css`
      visibility: visible;
    `};
`;

export const ClearSignatureButton = styled.div`
  margin-bottom: 15px;
  font-style: normal;
  font-weight: ${props => props.theme.fonts.fontWeight.mediumL};
  font-size: ${props => props.theme.fonts.sizes.medium};
  line-height: 24px;
  letter-spacing: -0.15px;
  color: ${props => props.theme.colors.buttons.textButton};
  position: absolute;
  top: 25px;
  right: 35px;
  z-index: 1;
  cursor: pointer;
  @media (min-width: ${device.tablet}) {
    top: 5px;
    right: 10px;
  }
`;
