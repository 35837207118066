import React from 'react';
import i18n from 'i18next';
import {Trans, useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {useSummary} from 'context/summary';
import {SessionStorage} from '@guestapp/core';
import {STORAGE_KEYS} from 'utils/constants';
import {PATHS} from 'Routes';
import {getCreateOrUpdateBulkLockPayload} from 'hooks/allianz/utils';
import {
  useAppSelector,
  useBulkLock,
  useCalculateTotalPrices,
  useModalControls,
  usePaymentSettings,
  useTimeoutRef,
} from 'hooks';
import Loading from 'components/common/Loading';
import LoadingToast from 'components/common/LoadingToast';
import {ConfirmLegalModal} from './ConfirmLegalModal';
import {
  Hr,
  InsuranceApplyText,
  ItemPrice,
  StyledButton,
  TotalAmountBox,
  TotalBoxAndButtonContainer,
} from 'pages/Allianz/components/styled';

type TotalPriceBoxComponentProps = {
  showLoading?: boolean;
  onCalculatingPrice?: (isCalculating: boolean) => void;
  hide?: boolean;
  isOneAddonSelected: boolean;
};
function TotalPriceBoxComponent(
  {
    showLoading: isLoadingParent,
    onCalculatingPrice,
    hide,
    isOneAddonSelected,
  }: TotalPriceBoxComponentProps = {isOneAddonSelected: false},
) {
  const {t} = useTranslation();
  const {data: summary} = useSummary();
  const {currencySymbol} = usePaymentSettings();
  const {state} = useLocation();
  const dealIds = useAppSelector(state => state.alliaznInsurance.dealIds);
  const allowUsePersonalData = useAppSelector(
    state => state.alliaznInsurance.legalConfirmation.allowUsePersonalData,
  );
  const [showBulkLoadingToast, setShowBulkLoadingToast] = React.useState(false);
  const from = state?.from ?? false;
  const closeToastTimeoutRef = useTimeoutRef();
  const navigate = useNavigate();
  const days = summary?.nights_of_stay;
  const {
    data,
    isLoading: isLoadingPriceCalculation,
    isFetching,
    enabledPaid,
  } = useCalculateTotalPrices();
  const isLoading = isLoadingPriceCalculation || isLoadingParent;
  const {
    isOpen: isOpenConfirmLegalInforationModal,
    openModal: openConfirmLegalInforationModal,
    closeModal: closeConfirmLegalInforationModal,
  } = useModalControls();

  const handleOnSuccess = () => {
    closeToastTimeoutRef.current = setTimeout(() => {
      setShowBulkLoadingToast(false);
      navigate(`../../../${from}`, {
        state: {from: PATHS.travelInsurances.allianz.selectGuests},
      });
    }, 2000);
  };

  const {mutateBulkLock, isLoading: isLoadingMutationBulkLock} = useBulkLock({
    onSuccessCreateOrUpdateBulkLock: handleOnSuccess,
  });

  React.useEffect(() => {
    if (isLoadingMutationBulkLock) {
      setShowBulkLoadingToast(isLoadingMutationBulkLock);
    }
  }, [isLoadingMutationBulkLock]);

  const onOpenModalClick = React.useCallback(() => {
    if (from) {
      if (!dealIds?.length) {
        navigate(`../../../${from}`, {
          state: {from: PATHS.travelInsurances.allianz.selectGuests},
        });
        return;
      }
      const payload = getCreateOrUpdateBulkLockPayload(dealIds, {
        personal_data_accepted: allowUsePersonalData,
      });
      mutateBulkLock(payload);
      return;
    }
    openConfirmLegalInforationModal();
  }, [
    from,
    dealIds,
    mutateBulkLock,
    openConfirmLegalInforationModal,
    allowUsePersonalData,
    navigate,
  ]);

  const handleOnLoadingConfirm = (isLoading: boolean) => {
    setShowBulkLoadingToast(isLoading);
  };

  React.useEffect(
    function setTotalPriceInStorage() {
      if (!data) return;
      const total = data.reduce((acc, {price_total}) => acc + parseFloat(price_total), 0);
      SessionStorage.set(STORAGE_KEYS.allianzTotalPlanPrice, total.toFixed(2));
    },
    [data],
  );

  React.useEffect(() => {
    onCalculatingPrice?.(showBulkLoadingToast || isLoadingPriceCalculation);
  }, [showBulkLoadingToast, isLoadingPriceCalculation, onCalculatingPrice]);

  if (hide) return null;

  return (
    <>
      <TotalBoxAndButtonContainer
        disabled={!isLoadingPriceCalculation && isFetching}
        isLoading={isLoadingParent}
        className="allianz-total-price"
      >
        <TotalAmountBox className="allianz-total-price__amount">
          <Loading
            isLoading={isLoadingParent && !isFetching}
            className="total-box-loading"
          >
            <InsuranceApplyText className="allianz-total-price__apply-text">
              <Trans i18nKey="choose_plan_text" values={{days}}>
                Este seguro se aplicará en tu viaje de {{days}} días.
              </Trans>
            </InsuranceApplyText>
            <Hr />
            {data?.map(({name, price_total, amount}, index) => {
              return (
                <ItemPrice key={index} className="allianz-total-price__item">
                  <div className="item-price">
                    {'x'}
                    {amount} {name[i18n.language.toUpperCase()] || name['ES']}
                  </div>
                  <div>
                    {currencySymbol}
                    {price_total}
                  </div>
                </ItemPrice>
              );
            })}
          </Loading>
        </TotalAmountBox>
        <StyledButton
          label={!from ? t('confirm') : t('confirm_and_back_to_payments')}
          width={'250px'}
          onClick={onOpenModalClick}
          type="submit"
          className="confirm-plans-btn"
          disabled={isLoading || showBulkLoadingToast || (!from && enabledPaid)}
        />
        <ConfirmLegalModal
          isOpenModalSheet={isOpenConfirmLegalInforationModal}
          handleCloseModal={closeConfirmLegalInforationModal}
          onLoadingConfirm={handleOnLoadingConfirm}
          isOneAddonSelected={isOneAddonSelected}
        />
      </TotalBoxAndButtonContainer>
      <LoadingToast show={showBulkLoadingToast} text={t('adding_to_payment_cart')} />
    </>
  );
}

export {TotalPriceBoxComponent};
