import React from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import {useAppSelector, useDocumentSelfieDetection} from '../../../../hooks';
import {IV_DOCUMENT_IMAGE_TYPES} from '../../../../utils/constants';
import {PATHS} from '../../../../Routes';
import {useSummary} from '../../../../context/summary';
import backSidePlaceholder from '../../../../assets/img/backside-placeholder.svg';
import PhotoBox from '../../../../components/PhotoBox';
import {HelperText} from '../styled';
import {getDocumentType} from '../../../../store/slices/identityVerification';

function BackScanView() {
  const {uploadType, id} = useParams();
  const {reservationId, countryCode} = useSummary();
  const {t} = useTranslation();
  const {ProcessModal, ErrorModal, RetryModal, sendBackSideDocument, isProcessing} =
    useDocumentSelfieDetection({
      docType: IV_DOCUMENT_IMAGE_TYPES.back,
    });

  const documentType = useAppSelector(getDocumentType);

  const isUpload = uploadType === 'upload';

  const getHelperText = () => {
    if (isUpload) {
      return <Trans i18nKey="upload_photo_of_back_side_of_your_document" />;
    } else {
      return <Trans i18nKey="now_back_side_of_your_document_expanded" />;
    }
  };

  const handleChangePhoto = React.useCallback(
    (image: string) => {
      if (countryCode) {
        sendBackSideDocument(image, countryCode, reservationId);
      }
    },
    [countryCode, reservationId, sendBackSideDocument],
  );

  if (!documentType) {
    return <Navigate to={`../../${PATHS.verification.main}/${id ?? ''}`} />;
  }

  return (
    // <IVSteps>
    <>
      <HelperText className="iv-back-view__helper">{getHelperText()}</HelperText>
      {!isProcessing && (
        <PhotoBox
          mobileHelperTitle={t('scan_your_document')}
          mobileHelperText={<Trans i18nKey="now_back_side_of_your_document" />}
          onUploadPhoto={handleChangePhoto}
          placeholderImage={backSidePlaceholder}
          isUpload={isUpload}
        />
      )}
      <ProcessModal />
      <ErrorModal />
      <RetryModal />
    </>
    // </IVSteps>
  );
}

export {BackScanView};
