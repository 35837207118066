import styled, {css} from 'styled-components';
import {NavLink} from 'react-router-dom';
import {device} from 'styled/device';

type TabProps = {
  selected?: boolean;
  disabled?: boolean;
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabsContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 0 auto 40px;
  width: 100%;
  position: absolute;
  background-color: white;
  left: 0;
  top: 0;
  box-shadow: 0px 2px 10px 0px rgba(147, 150, 174, 0.25);

  @media (min-width: ${device.tablet}) {
    box-shadow: 0 3px 0 rgba(0, 0, 0, 0.03);
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    max-width: 330px;
    margin: 0 auto 40px;
    position: relative;
    top: unset;
    background: transparent;
    left: unset;
  }
`;

const getTabStyles = (props: TabProps) => css`
  /* width: 100%; */
  width: 208px;
  height: 56px;
  cursor: ${props.disabled ? 'none' : 'pointer'};
  font-style: normal;
  font-weight: ${props => props.theme.fonts.fontWeight.mediumL};
  font-size: ${props => props.theme.fonts.sizes.medium};
  line-height: 24px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: ${props.disabled ? '#B1B5C4' : '#152946'};
  transition: background 0.5s ease;
  border-bottom: 2.5px solid transparent;
  color: ${props => props.theme.colors.branding.fontPrimary};

  ${props.selected &&
  css`
    border-bottom: 2.5px solid ${props => props.theme.colors.$p0};
  `}

  ${props.disabled &&
  css`
    pointer-events: none;
  `}
`;

export const TabItem = styled.div<TabProps>`
  ${props => getTabStyles(props)}
`;

export const NavLinkTab = styled(NavLink)<TabProps>`
  ${props => getTabStyles(props)}

  &.active {
    border-bottom: 2.5px solid ${props => props.theme.colors.$p0};
  }
`;

export const ContainerChildren = styled.div`
  display: flex;
  justify-content: center;
`;
