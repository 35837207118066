import styled, {css} from 'styled-components';
import IconButton from '../IconButton';

export const StyledButton = styled(IconButton)<{$expanded: boolean}>`
  width: 24px;
  height: 24px;

  & > img {
    transition: transform 0.2s;
    transform: rotate(0deg);
  }

  ${props => {
    return (
      props.$expanded &&
      css`
        & > img {
          transform: rotate(180deg);
        }
      `
    );
  }}
`;
