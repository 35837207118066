import React from 'react';
import {useChekinSDK} from 'context/ChekinSDK';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {getOrdersToPay, getUnPaidOrders} from './utils';
import {AllianzOrder, TotalPrice} from '@guestapp/sdk/src/utils/allianz';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {useSummary} from 'context/summary';

const defaultQueryValues: TotalPrice[] = [
  {
    name: {ES: ''},
    price_total: '0',
    amount: 0,
    id: '',
    price_per_item: '0',
  },
];

function useCalculateTotalPrices() {
  const queryClient = useQueryClient();
  const {data} = useSummary();
  const {calculateTotalPrice} = useChekinSDK();
  const ordersQuery = queryClient.getQueriesData<AllianzOrder[]>([
    QUERY_CACHE_KEYS.allianzOrders,
  ]);
  const priceIds = React.useMemo(() => {
    if (ordersQuery) {
      const orders = ordersQuery[0][1];
      if (orders?.length === 0 || orders?.every(order => order.paid)) {
        return [];
      }

      const unpaidOrders = getUnPaidOrders(orders);
      return getOrdersToPay(unpaidOrders);
    }
  }, [ordersQuery]);

  const params = `plans=${priceIds}&nights=${data?.nights_of_stay}`;
  const queryResult = useQuery<TotalPrice[], Error>(
    [QUERY_CACHE_KEYS.calculateTotalPricesForAllianz, priceIds],
    () => calculateTotalPrice(params),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: Boolean(priceIds?.length),
      initialData: defaultQueryValues,
    },
  );

  return {...queryResult, enabledPaid: !Boolean(priceIds?.length)};
}

export {useCalculateTotalPrices};
