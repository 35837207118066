import React from 'react';
import {useNavigate} from 'react-router-dom';
import {DiscardChangesModalRef} from 'components/common/DiscardChangesModal/DiscardChangesModal';
import {
  APP_FLOWS,
  APP_PAGES,
  FEATURE_PAGES_X_ICON_FLOW,
  VIEWS_BACK_ARROW_NO_MENU_TO_BACK,
  VIEWS_HOME_ICON_NO_MENU_TO_HOME,
  VIEWS_HOME_ICON_WITH_MENU_TO_HOME,
  VIEWS_WITH_BACK_ARROW,
  VIEWS_X_ICON_NO_MENU,
  VIEWS_X_ICON_NO_MENU_TO_REGISTER,
  VIEW_X_ICON_DISCHARD_MODAL_CHANGES,
  VIEWS_BACK_ARROW_WITH_MENU_TO_BACK,
} from 'utils/pages';
import {PATHS} from 'Routes';
import {useGuestGroup, useNavigationCustom, usePath} from 'hooks';

type UseSubHeaderConfigProps = {
  discardChangesModalRef?: React.RefObject<DiscardChangesModalRef>;
};

function useSubHeaderConfig({discardChangesModalRef}: UseSubHeaderConfigProps = {}) {
  const {getFullPath} = usePath();
  const navigate = useNavigate();
  const {guestGroup} = useGuestGroup();
  const hasNoGuests = guestGroup?.added_number_of_guests === 0;
  const {currentFlowAndPage, currentPage} = useNavigationCustom();

  const openDiscardIVChangesModal = React.useCallback(
    (path?: string) => {
      discardChangesModalRef?.current?.open(path);
    },
    [discardChangesModalRef],
  );
  const noMenu = VIEWS_BACK_ARROW_NO_MENU_TO_BACK.includes(currentFlowAndPage);
  const navigateToBack = [
    ...VIEWS_BACK_ARROW_WITH_MENU_TO_BACK,
    ...VIEWS_BACK_ARROW_NO_MENU_TO_BACK,
  ].includes(currentFlowAndPage);

  const isFromPayments =
    currentFlowAndPage ===
    `${APP_FLOWS.allFeatures}_${APP_PAGES.taxes}_${APP_PAGES.payments}`;

  const closeRedirectPath = hasNoGuests ? PATHS.home : PATHS.guestRegistration;

  const getBackMethod = React.useCallback(() => {
    if (navigateToBack) {
      return navigate(-1);
    }

    if (isFromPayments) {
      return navigate(getFullPath(PATHS.payments.main));
    }

    return navigate(getFullPath(PATHS.home));
  }, [isFromPayments, navigate, getFullPath, navigateToBack]);

  const config = React.useMemo(() => {
    const defaultConfig = {
      icon: 'homeSidebar',
      size: 32,
      onClick: () => navigate(getFullPath(PATHS.home)),
      hideMenu: false,
    };
    if (VIEWS_HOME_ICON_WITH_MENU_TO_HOME.includes(currentFlowAndPage)) {
      return defaultConfig;
    }
    if (VIEWS_HOME_ICON_NO_MENU_TO_HOME.includes(currentFlowAndPage)) {
      return {...defaultConfig, hideMenu: true};
    }
    if (VIEWS_X_ICON_NO_MENU.includes(currentFlowAndPage)) {
      if (VIEW_X_ICON_DISCHARD_MODAL_CHANGES.includes(currentFlowAndPage)) {
        const path = VIEWS_X_ICON_NO_MENU_TO_REGISTER.includes(currentFlowAndPage)
          ? closeRedirectPath
          : PATHS.home;

        return {
          icon: 'closeIcon',
          size: 18,
          onClick: () => openDiscardIVChangesModal(path),
          hideMenu: true,
        };
      }

      return {
        icon: 'closeIcon',
        size: 18,
        onClick: () => navigate(getFullPath(FEATURE_PAGES_X_ICON_FLOW[currentPage])),
        hideMenu: true,
      };
    }

    if (VIEWS_WITH_BACK_ARROW.includes(currentFlowAndPage)) {
      return {
        icon: 'backBlueArrow',
        size: 15,
        onClick: getBackMethod,
        hideMenu: noMenu,
      };
    }

    return defaultConfig;
  }, [
    openDiscardIVChangesModal,
    getFullPath,
    navigate,
    currentFlowAndPage,
    currentPage,
    getBackMethod,
    noMenu,
    closeRedirectPath,
  ]);

  return {config};
}

export {useSubHeaderConfig};
