import {useTranslation} from 'react-i18next';
import dataIncompleteIcon from 'assets/icons/incomplete.svg';
import ModalSheet from '../common/ModalSheet';
import {OkButton, Content} from './styled';

type ModalTypes = {
  open: boolean;
  onClose: () => void;
};

function IncompleteRegisterModal({open, onClose}: ModalTypes) {
  const {t} = useTranslation();

  return (
    <ModalSheet isOpenModalSheet={open} handleCloseModal={onClose} maxWidth={375}>
      <Content className="incomplete-register-modal">
        <img
          src={dataIncompleteIcon}
          alt={t('form_with_red_field')}
          width={50}
          height={50}
          className="incomplete-register-modal__img"
        />
        <h1 className="incomplete-register-modal__title">
          {t('incomplete_personal_data')}
        </h1>
        <p className="incomplete-register-modal__text">
          {t('at_least_one_field_is_missing')}
        </p>
        <OkButton onClick={onClose} label={t('ok')} width={295} />
      </Content>
    </ModalSheet>
  );
}

export {IncompleteRegisterModal};
