import {UseMutationOptions, useMutation, useQueryClient} from '@tanstack/react-query';
import {CreateGuestWithMinDataPayload, Guest} from '@guestapp/sdk';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {useIsMounted} from './common';
import {useChekinSDK} from 'context/ChekinSDK';
import {useReservation} from '../context/reservation';

type UseMutateShortGuestProps = UseMutationOptions<
  Guest,
  Error,
  CreateGuestWithMinDataPayload
>;
function useMutateShortGuest({
  onError,
  onMutate,
  onSettled,
  onSuccess,
}: UseMutateShortGuestProps = {}) {
  const {createGuestWithMinData} = useChekinSDK();
  const {reservationId} = useReservation();
  const queryClient = useQueryClient();
  const isMounted = useIsMounted();

  return useMutation<Guest, Error, CreateGuestWithMinDataPayload>(
    payload => createGuestWithMinData({...payload, reservation_id: reservationId}),
    {
      onSuccess: (data, variables, context) => {
        onSuccess?.(data, variables, context);
        queryClient.refetchQueries([QUERY_CACHE_KEYS.reservation]);
        queryClient.refetchQueries([QUERY_CACHE_KEYS.guestGroup]);
        queryClient.resetQueries([QUERY_CACHE_KEYS.formFields]);
      },
      onError: (error, data, variables) => {
        if (!isMounted.current) {
          return;
        }

        onError?.(error, data, variables);
      },
      onMutate,
      onSettled,
    },
  );
}

export {useMutateShortGuest};
