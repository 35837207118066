import React from 'react';
import * as Sentry from '@sentry/react';
import {WebcamRefTypes, VideoConstraints} from '../Webcam';
import {useActions, useAppSelector, useErrorModal} from '../../hooks';
import {Buttons} from './components/Buttons';
import {UploadPhoto} from './components/UploadPhoto';
import {TakePhoto} from './components/TakePhoto';
import {Wrapper} from './styled';

enum UPLOAD_PHOTO_STATES {
  take = 'TAKE',
  confirmTaking = 'CONFIRM_TAKING',
  upload = 'UPLOAD',
}

enum PHOTO_SIDES {
  front = 'FRONT',
  back = 'BACK',
  selfie = 'SELFIE',
}

type PhotoBoxProps = {
  mobileHelperText?: React.ReactNode | string;
  mobileHelperTitle?: React.ReactNode | string;
  preloadedScreenshot?: string;
  placeholderImage?: string;
  onUploadPhoto?: (screenshot: string) => void;
  isUpload?: boolean;
  isOneSide?: boolean;
  roundedScanArea?: boolean;
  videoConstraints?: VideoConstraints;
  additionalConstraints?: VideoConstraints;
};
const PhotoBox = ({
  isUpload,
  preloadedScreenshot,
  onUploadPhoto,
  placeholderImage,
  videoConstraints,
  additionalConstraints,
  mobileHelperText,
  mobileHelperTitle,
  roundedScanArea,
}: PhotoBoxProps) => {
  const webcamRef = React.useRef<WebcamRefTypes>(null);
  const [uploadPhotoState, setUploadPhotoState] = React.useState<UPLOAD_PHOTO_STATES>(
    UPLOAD_PHOTO_STATES.take,
  );
  const uploadedImage = useAppSelector(state => state.photoBox.uploadedImage);
  const {setPhotoBoxUploadedImage, resetPhotoBoxUploadedImage} = useActions();
  const [validationError, setValidationError] = React.useState<string | null>(null);
  const {displayError, ErrorModal} = useErrorModal();

  React.useEffect(() => {
    if (isUpload) {
      setUploadPhotoState(UPLOAD_PHOTO_STATES.upload);
    } else if (uploadedImage && !isUpload) {
      setUploadPhotoState(UPLOAD_PHOTO_STATES.confirmTaking);
    } else {
      setUploadPhotoState(UPLOAD_PHOTO_STATES.take);
    }
  }, [isUpload, uploadedImage]);

  React.useEffect(
    function resetImageWhenLeavePage() {
      return () => {
        resetPhotoBoxUploadedImage();
      };
    },
    [resetPhotoBoxUploadedImage],
  );

  // React.useEffect(
  //   function preloadImage() {
  //     if (!preloadedScreenshot) return;
  //     setUploadedImage(preloadedScreenshot);
  //   },
  //   [setUploadedImage, preloadedScreenshot],
  // );

  const handleTakePhoto = React.useCallback(async () => {
    try {
      const photo = await webcamRef?.current?.takePhoto();
      if (photo) onUploadPhoto?.(photo);
    } catch (err) {
      Sentry.captureException(err);
      displayError(err);
    }
  }, [displayError, onUploadPhoto]);

  const handleUploadPhoto = React.useCallback(
    (image: string) => {
      setPhotoBoxUploadedImage(image);
    },
    [setPhotoBoxUploadedImage],
  );

  const handleChangePhoto = React.useCallback(() => {
    resetPhotoBoxUploadedImage();
  }, [resetPhotoBoxUploadedImage]);

  const handleFinishUploadPhoto = React.useCallback(() => {
    if (!uploadedImage) return;
    onUploadPhoto?.(uploadedImage);
  }, [onUploadPhoto, uploadedImage]);

  const getContent = React.useCallback(() => {
    if (isUpload) {
      return (
        <UploadPhoto
          onUpload={handleUploadPhoto}
          setValidationError={setValidationError}
          validationError={validationError}
          image={uploadedImage}
        />
      );
    }

    return (
      <TakePhoto
        ref={webcamRef}
        roundedScanArea={roundedScanArea}
        videoConstraints={videoConstraints}
        additionalConstraints={additionalConstraints}
        onScreenshot={handleTakePhoto}
        placeholderImage={placeholderImage}
        mobileHelperText={mobileHelperText}
        mobileHelperTitle={mobileHelperTitle}
        mirrored={roundedScanArea}
      />
    );
  }, [
    isUpload,
    roundedScanArea,
    additionalConstraints,
    videoConstraints,
    handleTakePhoto,
    placeholderImage,
    mobileHelperText,
    mobileHelperTitle,
    handleUploadPhoto,
    validationError,
    uploadedImage,
  ]);

  return (
    <Wrapper className="photo-box">
      {getContent()}
      <Buttons
        state={uploadPhotoState}
        onCapture={handleTakePhoto}
        onChange={handleChangePhoto}
        disabled={!!validationError || !uploadedImage}
        onFinishUploadPhoto={handleFinishUploadPhoto}
      />
      <ErrorModal />
    </Wrapper>
  );
};

export {PhotoBox, UPLOAD_PHOTO_STATES, PHOTO_SIDES};
