import styled from 'styled-components';
import {transformSizePropToSize} from '../../../../utils/common';

export const Wrapper = styled.div<{size?: number | string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  & > div {
    height: ${props => transformSizePropToSize(props.size) || '20px'};
    width: ${props => transformSizePropToSize(props.size) || '20px'};
    margin-right: 10px;
    border-radius: 10px;
    background-color: ${props => props.theme.colors.$s1};
    animation: pulse 2s infinite ease-in-out;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.8);
      background-color: ${props => props.theme.colors.$s1};
      box-shadow: 0 0 0 0 rgba(248, 75, 122, 0.7);
    }

    35% {
      transform: scale(1);
    }

    50% {
      transform: scale(1);
      background-color: ${props => props.theme.colors.$s1};
      box-shadow: 0 0 0 10px rgba(248, 75, 122, 0);
    }

    100% {
      transform: scale(1);
      background-color: ${props => props.theme.colors.$s1};
    }
  }
`;
