import {useNavigate, useLocation, useSearchParams} from 'react-router-dom';

export function useLocks() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const unlockLinkToken = searchParams.get('unlock-link-token');

  const addLockTokenToUrl = (token: string) => {
    searchParams.set('unlock-link-token', token || '');
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const removeLockTokenFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('unlock-link-token');
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return {addLockTokenToUrl, unlockLinkToken, removeLockTokenFromUrl};
}
