import {LocksApi} from '../api';
import {FetcherSettings} from '../api/apiClient';

class LocksService {
  private readonly looksApi: LocksApi;

  constructor() {
    this.looksApi = new LocksApi();
  }

  getLooks(params = '', resolverSettings?: FetcherSettings) {
    return this.looksApi.getLocks(params, resolverSettings);
  }

  getLockConditions(params = '', resolverSettings?: FetcherSettings) {
    return this.looksApi.getLockConditions(params, resolverSettings);
  }

  unlockDoor(params = '', resolverSettings?: FetcherSettings) {
    return this.looksApi.unlock(params, resolverSettings);
  }
}

export {LocksService};
