import styled from 'styled-components';
import {device} from '../../styled/device';
import Button from '../common/Button';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  line-height: 24px;
  color: #152946;
  text-align: center;
  gap: 30px;

  .incomplete-register-modal__title {
    font-weight: 600;
    font-size: 18px;
    margin: 0;
  }

  .incomplete-register-modal__text {
    font-weight: 400;
    font-size: 16px;
    margin: 0;
  }
`;

export const OkButton = styled(Button)`
  margin-top: 24px;

  @media (max-width: ${device.mobileXL}) {
    margin-bottom: 30px;
  }
`;
