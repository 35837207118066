import {
  CompareBiomatchPayload,
  IdentityVerificationApi,
  IVPayload,
  DocumentsAndSelfieVerificationPayload,
} from '../api';
import {ImagesFields} from '../utils';
import {FetcherSettings} from '../api/apiClient';

class IdentityVerificationService {
  private readonly identityVerificationApi: IdentityVerificationApi;

  constructor() {
    this.identityVerificationApi = new IdentityVerificationApi();
  }

  getFileName(
    name: keyof DocumentsAndSelfieVerificationPayload,
    payload: DocumentsAndSelfieVerificationPayload,
  ): string {
    return `${name}.${(payload[name] as Blob)?.['type']}`;
  }

  transformPayloadToFormData(payload: DocumentsAndSelfieVerificationPayload): FormData {
    const formData = new FormData();
    for (const name in payload) {
      const fieldName = name as keyof DocumentsAndSelfieVerificationPayload;
      const field = payload?.[fieldName];
      if (field) {
        const value = field.toString() || '';
        if (ImagesFields.includes(fieldName)) {
          formData.append(fieldName, field as Blob, this.getFileName(fieldName, payload));
        } else {
          formData.set(fieldName, value);
        }
      }
    }

    return formData;
  }

  sendDocumentForFaceDetection(payload: IVPayload, resolverSettings?: FetcherSettings) {
    return this.identityVerificationApi.sendDocumentForFaceDetection(
      payload,
      resolverSettings,
    );
  }

  checkDocumentFaceDetectionStatus(id: string, resolverSettings?: FetcherSettings) {
    return this.identityVerificationApi.checkDocumentFaceDetectionStatus(
      id,
      resolverSettings,
    );
  }

  sendSelfieForFaceDetection(payload: IVPayload, resolverSettings?: FetcherSettings) {
    return this.identityVerificationApi.sendSelfieForFaceDetection(
      payload,
      resolverSettings,
    );
  }

  checkSelfieFaceDetection(id: string, resolverSettings?: FetcherSettings) {
    if (!id) throw new Error('The comparing id is missing');
    return this.identityVerificationApi.checkSelfieFaceDetection(id, resolverSettings);
  }

  compareSelfieAndDocumentFaces(
    payload: CompareBiomatchPayload,
    resolverSettings?: FetcherSettings,
  ) {
    return this.identityVerificationApi.compareSelfieAndDocumentFaces(
      payload,
      resolverSettings,
    );
  }

  checkSelfieAndDocumentFacesComparingStatus(
    id: string,
    resolverSettings?: FetcherSettings,
  ) {
    if (!id) throw new Error('The comparing id is missing');
    return this.identityVerificationApi.checkSelfieAndDocumentFacesComparingStatus(
      id,
      resolverSettings,
    );
  }

  sendImageForOCR(payload: IVPayload, resolverSettings?: FetcherSettings) {
    return this.identityVerificationApi.sendImageForOCR(payload, resolverSettings);
  }

  checkImageOCRStatus(id: string, resolverSettings?: FetcherSettings) {
    if (!id) throw new Error('The comparing id is missing');
    return this.identityVerificationApi.checkImageOCRStatus(id, resolverSettings);
  }
}

export {IdentityVerificationService};
