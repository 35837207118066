import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {BUTTONS_STYLES_OPTIONS} from '@guestapp/ui/src/utils/types';
import {device} from 'styled/device';
import {CustomIcon} from '@guestapp/ui';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  padding: 0 20px;
  @media (min-width: ${device.mobileL}) {
    width: 500px;
  }
  @media (min-width: ${device.laptopM}) {
    width: 856px;
  }
`;

export const StyledIcon = styled(CustomIcon)`
  margin: 0 auto 25px;
  display: block;
  width: 200px;
  height: 200px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.36px;
  margin-bottom: 8px;
  font-family: ${props => props.theme.fonts.fontFamily.titles.font_name};
  color: ${props => props.theme.colors.branding.fontPrimary};
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin: 0 auto 72px;
  color: ${props => props.theme.colors.branding.fontSecondary};

  @media (min-width: ${device.tablet}) {
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 320px;
    margin: 0 auto 48px;
  }
`;

export const RegisterBottom = styled(Link)`
  background: ${props => props.theme.colors.buttons.mainButtonBackground};
  border-radius: ${props =>
    props.theme.buttons.typeRadioButton === BUTTONS_STYLES_OPTIONS.round
      ? '50px'
      : '7px'};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.buttons.mainButtonTextBackground};
  padding: 15px;
  text-align: center;
  letter-spacing: -0.15px;
  margin-bottom: 35px;
  display: block;
  @media (min-width: ${device.tablet}) {
    width: 295px;
    margin: 0 auto 46px;
  }
`;

export const ShareRegistrationLinkButton = styled.div`
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.15px;
  text-align: center;
  color: ${props => props.theme.colors.buttons.textButton};
  font-weight: 500;
  margin-bottom: 60px;
  cursor: pointer;
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 0.8;
    transition: opacity 0.2s ease;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 290px;
  margin-top: 24px;
`;

export const GuestPanelContainer = styled.div`
  margin-bottom: 48px;
  padding: 0;
  width: 280px;
  background: rgb(255, 255, 255);
  border-radius: 12px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out 0s;
  box-shadow: rgba(0, 0, 0, 0.07) 0 3px 6px 0;
  overflow: hidden;

  & .guests-panel__item {
    border-radius: 0;
  }

  @media (min-width: ${device.mobileM}) {
    width: 325px;
  }

  @media (min-width: ${device.tablet}) {
    width: 416px;
  }
`;

export const PmsGuestPanelContainer = styled.div`
  margin-bottom: 48px;
  @media (min-width: ${device.tablet}) {
    width: 100%;
  }
`;
