import {useState, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {usePath} from 'hooks';
import {BookingSummary, Guest} from '@guestapp/sdk';
import {PATHS} from 'Routes';
import {useFetchCheckIfOffersExists} from 'hooks/useFetchCheckIfOffersExists';

export const useUpsellingFlow = (housingId?: string, summary?: BookingSummary) => {
  const [isUpsellingFlowWorking, setUpsellingFlowWorking] = useState(false);
  const navigate = useNavigate();
  const {getFullPath} = usePath();
  const {data: checkIfOffersExistsResult} = useFetchCheckIfOffersExists({housingId});

  const checkUpsellingFlow = useCallback(
    (guest: Guest) => {
      if (
        guest.is_leader &&
        summary?.upselling?.active &&
        checkIfOffersExistsResult?.result
      ) {
        setUpsellingFlowWorking(true);
        navigate(getFullPath(PATHS.recommendations.main));
      }
    },
    [setUpsellingFlowWorking, navigate, getFullPath, checkIfOffersExistsResult, summary],
  );

  return {
    checkUpsellingFlow,
    isUpsellingFlowWorking,
  };
};
