import React from 'react';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import {useTheme} from 'styled-components';
import StyledIcon, {IconNames} from '../common/StyledIcon';
import {AccordionStyled, Icon} from './styled';

type ItemWithExpanded = {
  title: (expanded?: boolean) => React.ReactNode | string;
  content: (expanded?: boolean) => React.ReactNode | string;
};

type Item = {
  title: React.ReactNode | string;
  content: React.ReactNode | string;
};

type AccordionProps = {
  items: (Item | ItemWithExpanded)[];
  allowZeroExpanded?: boolean;
  allowMultipleExpanded?: boolean;
  indicatorIcon?: IconNames;
};

const Accordion = React.memo(
  ({
    items,
    allowZeroExpanded,
    allowMultipleExpanded,
    indicatorIcon = 'arrowBottom',
  }: AccordionProps) => {
    const theme = useTheme();
    return (
      <AccordionStyled
        allowZeroExpanded={allowZeroExpanded}
        allowMultipleExpanded={allowMultipleExpanded}
        className="accordion-wrapper"
      >
        {items.map((item, idx) => {
          return (
            <AccordionItem key={idx}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionItemState>
                    {({expanded}) => (
                      <>
                        {typeof item.title === 'function'
                          ? item.title(expanded)
                          : item.title}
                        <Icon shouldRotate={!!expanded}>
                          <StyledIcon
                            color={
                              theme.hasTemplate
                                ? theme.colors.branding.primary
                                : theme.colors.buttons.textButton
                            }
                            name={indicatorIcon}
                            size={15}
                          />
                        </Icon>
                      </>
                    )}
                  </AccordionItemState>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <AccordionItemState>
                  {({expanded}) =>
                    typeof item.content === 'function'
                      ? item.content(expanded)
                      : item.content
                  }
                </AccordionItemState>
              </AccordionItemPanel>
            </AccordionItem>
          );
        })}
      </AccordionStyled>
    );
  },
);

export {Accordion};
