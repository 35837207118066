import React from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import {
  Input,
  Wrapper,
  Title,
  ErrorMessage,
  StyledButton,
  HelperText,
  StyledIcon,
} from './styled';

export type UploadFileProps = {
  error?: string | null;
  helperText?: string;
  className?: string;
} & React.ComponentPropsWithRef<'input'>;

const UploadFile = React.forwardRef<HTMLInputElement, UploadFileProps>(
  ({className, onChange, helperText, type, error, ...props}, ref) => {
    const {t} = useTranslation();
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const handleChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event);
        event.target.value = '';
      },
      [onChange],
    );

    return (
      <Wrapper className={clsx(className, 'file-uploader')} hasError={!!error}>
        <Input
          type="file"
          ref={inputRef}
          onChange={handleChange}
          accept="image/*"
          {...props}
        />
        <StyledIcon name="imageIcon" size={33} />
        <Title className="file-uploader__title">
          {t('drag_and_drop_your_image_here')}
        </Title>
        <StyledButton
          onClick={() => inputRef.current?.click()}
          label={t('or_browse_files')}
          link
        />
        <HelperText className="file-uploader__helper">{helperText}</HelperText>
        {error && <ErrorMessage className="file-uploader__error">{error}</ErrorMessage>}
      </Wrapper>
    );
  },
);

export {UploadFile};
