import React from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {getURL, InsuranceSetup, SECURITY_DEPOSIT_STATUSES} from '@guestapp/sdk';
import {useActions} from './redux';
import {useChekinSDK} from '../context/ChekinSDK';
import {QUERY_CACHE_KEYS} from '../utils/constants';
import {useReservation} from '../context/reservation';

const CACHE_TIME = 60_000;
const STALE_TIME = 60_000;

type UsePropertyProtection = {
  onError?: (error: Error) => void;
};
function usePropertyProtection({onError}: UsePropertyProtection = {}) {
  const queryClient = useQueryClient();
  const {data: reservation, housing, reservationId} = useReservation();
  const {setProtectionPaymentPreloaded} = useActions();
  const {
    isTokenValid,
    getPropertyProtectionsByReservation,
    mutateInsuranceSetup,
    getInsuranceSetup,
  } = useChekinSDK();

  const {
    data,
    isLoading: isPropertyProtectionsLoading,
    isFetching: isPropertyProtectionsFetching,
    isInitialLoading: isPropertyProtectionsInitialLoading,
    isSuccess: isPropertyProtectionsSuccess,
  } = useQuery(
    [QUERY_CACHE_KEYS.propertyProtections],
    () => getPropertyProtectionsByReservation(reservationId),
    {
      onError,
      enabled: Boolean(reservationId && isTokenValid),
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
    },
  );

  const {
    data: insuranceSetup,
    isLoading: isInsuranceSetupLoading,
    isFetching: isInsuranceSetupFetching,
    isInitialLoading: isInsuranceSetupInitialLoading,
    isSuccess: isInsuranceSetupSuccess,
  } = useQuery(
    [QUERY_CACHE_KEYS.insuranceSetup],
    () => getInsuranceSetup(reservation?.insurance_setup_id || ''),
    {
      onError,
      enabled: Boolean(reservation?.insurance_setup_id && isTokenValid),
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
    },
  );

  const downloadDocumentsPrivacyPolicy = async () => {
    const typePlan = String(data?.waivo_damage_protection?.protection_limit.slice(0, -3));
    const userId = housing?.manager_id.replaceAll('-', '');
    const downloadDocumentUrl = getURL(
      `api/v3/documents/guest-waivo-terms/terms-link/?user_id=${userId}&type=${typePlan}`,
    );

    return (window.location.href = downloadDocumentUrl);
  };

  const isInsuranceFirstLoading =
    Boolean(isInsuranceSetupLoading && !reservation?.id) ||
    Boolean(
      isInsuranceSetupLoading && reservation?.id && reservation?.insurance_setup_id,
    ) ||
    isInsuranceSetupInitialLoading;

  const {mutate, isLoading: isInsuranceSetupMutation} = useMutation<
    InsuranceSetup,
    Error,
    Partial<InsuranceSetup>
  >(mutateInsuranceSetup, {
    onSuccess: mutatedInsuranceSetup => {
      setProtectionPaymentPreloaded(false);
      void queryClient.invalidateQueries([QUERY_CACHE_KEYS.paymentsCart]);
      void queryClient.invalidateQueries([QUERY_CACHE_KEYS.summary]);
      queryClient.setQueryData([QUERY_CACHE_KEYS.insuranceSetup], mutatedInsuranceSetup);
    },
    onError,
  });

  const createOrUpdateInsuranceSetup = React.useCallback(
    (
      payload: Pick<InsuranceSetup, 'insurance_payment_type' | 'insurance_type'>,
      options: Parameters<typeof mutate>[1],
    ) => {
      const builtPayload = {reservation: reservationId, ...payload};
      if (insuranceSetup?.id) {
        return mutate({id: insuranceSetup?.id, ...builtPayload}, options);
      }

      return mutate(builtPayload, options);
    },
    [insuranceSetup?.id, mutate, reservationId],
  );

  const isSecurityDepositOptional =
    data?.security_deposit?.security_deposit_status ===
    SECURITY_DEPOSIT_STATUSES.optional;

  const isWaivoProtectionMandatory =
    housing?.damage_protection_status === SECURITY_DEPOSIT_STATUSES.mandatory;

  const isMultipleDeposits =
    Boolean(data?.security_deposit) && Boolean(data?.waivo_damage_protection);
  const isLoading =
    isPropertyProtectionsLoading ||
    isPropertyProtectionsFetching ||
    isInsuranceSetupFetching ||
    isInsuranceSetupMutation;

  const isSecurityDeposit = Boolean(data?.security_deposit) ?? undefined;
  const isWaivoProtection = Boolean(data?.waivo_damage_protection) ?? undefined;

  return {
    data,
    isLoading,
    isFirstLoading: isPropertyProtectionsInitialLoading || isInsuranceFirstLoading,
    isFirstLoaded: isPropertyProtectionsSuccess || isInsuranceSetupSuccess,
    isInsuranceSetupInit: reservation?.insurance_setup_id
      ? isInsuranceSetupSuccess
      : !!reservation?.id,
    isInsuranceSetupLoading,
    insuranceSetup,
    isMultipleDeposits,
    isSecurityDepositOptional,
    isWaivoProtectionMandatory,
    isSecurityDeposit,
    isWaivoProtection,
    createOrUpdateInsuranceSetup,
    downloadDocumentsPrivacyPolicy,
  };
}

export {usePropertyProtection};
