import {useCallback, useEffect, useRef, useState} from 'react';
import dayjs from 'dayjs';
import {useMutation} from '@tanstack/react-query';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Controller, useForm} from 'react-hook-form';
import {
  ALL_GROUP_TYPES_OPTIONS,
  GROUP_TYPES,
  MaxNumberOfGuests,
  MinNumberOfGuests,
  OPTION_VALUES,
  formatOneDate,
  getMinCheckInDate,
  getNewToken,
  isSameOrAfterDay,
  parseDate,
  validateCheckOut,
} from 'pages/SearchReservation/utils';
import {SelectOptionType} from '@guestapp/sdk';
import {PATHS} from 'Routes';
import StatusModal, {StatusModalStates} from 'components/StatusModal';
import {COUNTRY_CODES, RESERVATION_ID_KEY, SHARE_LINK, STATUSES} from 'utils/constants';
import {useErrorModal, useModalControls, usePath, useTimeoutRef} from 'hooks';
import {useChekinSDK} from 'context/ChekinSDK';
import {useReservation} from 'context/reservation';
import {LocalStorage, useStatus} from '@guestapp/core';
import PageUniversalLink from 'components/PageUniversalLink';
import Datepicker from 'components/common/Datepicker';
import {Button, InputController as Input} from '@guestapp/ui';
import {FormFieldWrapper} from 'styled/common';
import Select from 'components/common/Select';
import {StyledBackIcon} from '../CheckInDateView/styled';
import {
  ContentButton,
  CustomBackButton,
  Fields,
  FormWrapper,
  StatusTitleModal,
  SubTitleWrapper,
  TitleWrapper,
  Wrapper,
} from './styled';

enum FORM_NAMES {
  lead_guest_name = 'default_leader_full_name',
  lead_email = 'default_invite_email',
  number_of_guests = 'number_of_guests',
  type_of_registration = 'type',
  check_in_date = 'check_in_date',
  check_out_date = 'check_out_date',
}

const buildPayload = (data: FormTypes, housingIdPropertyLink: string) => {
  const {number_of_guests, check_out_date, check_in_date, type, ...rest} = data;

  return {
    ...rest,
    [FORM_NAMES.check_in_date]: check_in_date
      ? dayjs(parseDate(check_in_date)).format(DATE_FNS_FORMAT)
      : undefined,
    [FORM_NAMES.check_out_date]: check_out_date
      ? dayjs(parseDate(check_out_date)).format(DATE_FNS_FORMAT)
      : undefined,
    guest_group: {
      number_of_guests,
      ...(type && {type: type.value}),
    },
    housing_id: housingIdPropertyLink,
    source_name: 'Property Link',
  };
};

type FormTypes = {
  [FORM_NAMES.lead_guest_name]: string;
  [FORM_NAMES.number_of_guests]: string | number;
  [FORM_NAMES.lead_email]?: string;
  [FORM_NAMES.type_of_registration]?: {label: string; value: string};
  [FORM_NAMES.check_in_date]?: Date;
  [FORM_NAMES.check_out_date]?: Date;
};

const DATE_FNS_FORMAT = 'YYYY-MM-DD';
function NewReservationView() {
  const {t} = useTranslation();
  const {createReservation} = useChekinSDK();
  const navigate = useNavigate();
  const {getFullPath} = usePath();
  const location = useLocation();
  const {isLoading, setStatus} = useStatus();
  const isLocationDataPreloaded = useRef(false);
  const {ErrorModal, displayError} = useErrorModal();
  const timeoutRef = useTimeoutRef();
  const {housingIdPropertyLink, countryCode} = useReservation();
  const [groupTypes, setGroupTypes] = useState<SelectOptionType[]>(
    ALL_GROUP_TYPES_OPTIONS(),
  );

  const [modalRegisterGuestStatus, setModalRegisterGuestStatus] = useState(
    StatusModalStates.IDLE,
  );

  const {
    isOpen: isOpenReservationStatusModal,
    openModal: openReservationStatusModal,
    closeModal: closeReservationStatusModal,
  } = useModalControls();

  const {
    setValue,
    getValues,
    register,
    formState: {errors, isSubmitted},
    trigger,
    watch,
    control,
    handleSubmit,
  } = useForm<FormTypes>();

  const isTypeShown =
    countryCode === COUNTRY_CODES.austria ||
    countryCode === COUNTRY_CODES.uae ||
    countryCode === COUNTRY_CODES.italy;

  const numberOfGuests = watch(FORM_NAMES.number_of_guests);
  const typeOfRegistration = watch(FORM_NAMES.type_of_registration);

  const {mutate} = useMutation(createReservation, {
    onSuccess: data => {
      const reservationId = data.id;
      const reservationSignupFormLink = data?.signup_form_link;

      if (reservationId) {
        setStatus(STATUSES.success);
        LocalStorage.set(RESERVATION_ID_KEY, reservationId);
        LocalStorage.set(SHARE_LINK, reservationSignupFormLink);
        const tokenFromUrl = getNewToken(reservationSignupFormLink);
        window.location.href = `${process.env.REACT_APP_PUBLIC_URL}/${tokenFromUrl}`;
        setModalRegisterGuestStatus(StatusModalStates.SUCCESS);
      }
    },
    onError: error => {
      setModalRegisterGuestStatus(StatusModalStates.IDLE);
      closeReservationStatusModal();
      setStatus(STATUSES.error);
      displayError(error);
    },
  });

  const onSubmit = (formData: FormTypes) => {
    setStatus(STATUSES.loading);
    setModalRegisterGuestStatus(StatusModalStates.LOADING);
    openReservationStatusModal();
    const payload = buildPayload(formData, housingIdPropertyLink);
    mutate(payload);
  };

  const validateCheckInDate = useCallback(
    (value?: string | Date) => {
      if (!value) return value;

      const minCheckInDate = getMinCheckInDate(countryCode);
      const isAfterMinCheckInDate = isSameOrAfterDay(value, minCheckInDate);
      if (isAfterMinCheckInDate) return true;

      const formatMinCheckInDate = formatOneDate({
        date: minCheckInDate,
        housingCountry: countryCode || '',
      });
      return t(`minimum_check_in_date_is`, {date: formatMinCheckInDate});
    },
    [countryCode, t],
  );

  const validateCheckOutDate = (value?: string | Date) => {
    if (!value) return value;
    const checkInDate = getValues()[FORM_NAMES.check_in_date];
    return validateCheckOut(value, checkInDate);
  };

  useEffect(
    function preloadKnowsFields() {
      const locationState = location?.state;
      if (isLocationDataPreloaded.current || !locationState) return;

      const preload = [
        {
          name: FORM_NAMES.check_in_date,
          value: locationState.startDate,
        },
        {
          name: FORM_NAMES.check_out_date,
          value: locationState.endDate,
        },
        {
          name: FORM_NAMES.lead_email,
          value: locationState.email,
        },
      ].filter(f => !!f.value);

      preload.forEach(field => {
        setValue(field.name, field.value);
      });
      isLocationDataPreloaded.current = true;
    },
    [location, setValue],
  );

  useEffect(
    function updateGroupTypesOnNumberOfGuestsChange() {
      const tourist_group_type_option = {
        value: GROUP_TYPES.tourist,
        label: t('tourist_group'),
      };
      const single_group_type_option = {
        value: GROUP_TYPES.single,
        label: t('single'),
      };
      const GROUP_GROUP_TYPES_OPTIONS = [
        {
          value: GROUP_TYPES.group,
          label: t('group'),
        },
        {
          value: GROUP_TYPES.family,
          label: t('family'),
        },
      ];
      const defaultAllGroupTypes = ALL_GROUP_TYPES_OPTIONS();
      if (countryCode === COUNTRY_CODES.austria) {
        defaultAllGroupTypes.push(tourist_group_type_option);
      }
      if (numberOfGuests === undefined || numberOfGuests === '') {
        setGroupTypes(defaultAllGroupTypes);
        return;
      }

      let totalGuests = Number(numberOfGuests);
      totalGuests = totalGuests < 0 ? 0 : totalGuests;

      if (totalGuests > 1) {
        if (countryCode === COUNTRY_CODES.austria) {
          setGroupTypes([...GROUP_GROUP_TYPES_OPTIONS, tourist_group_type_option]);
        } else {
          setGroupTypes(GROUP_GROUP_TYPES_OPTIONS);
        }
        return;
      }

      if (totalGuests > 0) {
        setGroupTypes([single_group_type_option]);
      }
    },
    [numberOfGuests, countryCode, t],
  );

  useEffect(
    function keepCorrectRegistrationType() {
      if (!typeOfRegistration || !groupTypes.length) {
        return;
      }

      const hasSelectedGroupTypeOption = groupTypes.find(
        g => g?.value === typeOfRegistration?.value,
      );
      if (!hasSelectedGroupTypeOption) {
        setValue(FORM_NAMES.type_of_registration, undefined);
      }
    },
    [typeOfRegistration, groupTypes, setValue, trigger],
  );

  useEffect(
    function changeTranslationErrorMessages() {
      if (isSubmitted) {
        trigger();
      }
    },
    [isSubmitted, trigger],
  );

  useEffect(() => {
    if (modalRegisterGuestStatus === StatusModalStates.SUCCESS) {
      timeoutRef.current = setTimeout(() => {
        closeReservationStatusModal();
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [closeReservationStatusModal, modalRegisterGuestStatus, timeoutRef]);

  return (
    <PageUniversalLink className="new-reservation-view">
      <Wrapper>
        <TitleWrapper>{t('find_your_booking')}</TitleWrapper>
        <SubTitleWrapper>
          {t('enter_the_check_in_date_and_the_lead_guest_email')}
        </SubTitleWrapper>

        <CustomBackButton
          label={t('back')}
          customIcon={<StyledBackIcon name="backArrow" size={15} />}
          iconProps={{position: 'left'}}
          onClick={() =>
            navigate(getFullPath(PATHS.searchReservation.checkingDate), {
              state: OPTION_VALUES.check_in_date_and_email,
            })
          }
          width="auto"
          className="summary-view__back-btn"
          link
        />

        <FormWrapper>
          <Fields className="guest-form-fields" rowsCount={1}>
            <FormFieldWrapper>
              <Controller
                name={FORM_NAMES.check_in_date}
                rules={{required: t('required'), validate: validateCheckInDate}}
                control={control}
                render={({field}) => {
                  return (
                    <Datepicker
                      error={errors[FORM_NAMES.check_in_date]?.message}
                      label={t('check_in_date')}
                      {...field}
                    />
                  );
                }}
              />
            </FormFieldWrapper>

            <FormFieldWrapper>
              <Controller
                name={FORM_NAMES.check_out_date}
                rules={{required: t('required'), validate: validateCheckOutDate}}
                control={control}
                render={({field}) => {
                  return (
                    <Datepicker
                      error={errors[FORM_NAMES.check_out_date]?.message}
                      label={t('check_out_date')}
                      {...field}
                    />
                  );
                }}
              />
            </FormFieldWrapper>

            <FormFieldWrapper>
              <Input
                {...register(FORM_NAMES.number_of_guests, {
                  required: t('required'),
                  min: {
                    value: MinNumberOfGuests,
                    message: t('min_number_is_short', {number: MinNumberOfGuests}),
                  },
                  max: {
                    value: MaxNumberOfGuests,
                    message: t('max_number_is_short', {number: MaxNumberOfGuests}),
                  },
                })}
                type="number"
                label={t('total_number_of_guests')}
                control={control}
                error={errors[FORM_NAMES.number_of_guests]?.message}
                inputMode="numeric"
                autoCorrect="off"
                spellCheck={false}
                autoCapitalize="none"
              />
            </FormFieldWrapper>

            <FormFieldWrapper>
              <Input
                {...register(FORM_NAMES.lead_guest_name, {
                  required: t('required'),
                })}
                label={t('lead_guest_name')}
                control={control}
                error={errors[FORM_NAMES.lead_guest_name]?.message}
                inputMode="email"
                autoCorrect="off"
                spellCheck={false}
                autoCapitalize="none"
              />
            </FormFieldWrapper>

            {isTypeShown && (
              <FormFieldWrapper>
                <Controller
                  name={FORM_NAMES.type_of_registration}
                  control={control}
                  render={({field, fieldState: {error}}) => (
                    <Select
                      options={groupTypes}
                      label={t('type_of_registration')}
                      disabled={isLoading}
                      error={error?.message}
                      {...field}
                    />
                  )}
                  rules={{required: `${t('required')}`}}
                />
              </FormFieldWrapper>
            )}

            <FormFieldWrapper>
              <Input
                {...register(FORM_NAMES.lead_email, {
                  required: t('required'),
                  pattern: {
                    value: new RegExp('^([\\w\\.\\-_]+)?\\w+@[\\w-_]+(\\.\\w+){1,}$'),
                    message: t('invalid_email'),
                  },
                })}
                type="email"
                label={t('lead_guest_email')}
                control={control}
                error={errors[FORM_NAMES.lead_email]?.message}
                inputMode="email"
                autoCorrect="off"
                spellCheck={false}
                autoCapitalize="none"
              />
            </FormFieldWrapper>
          </Fields>
        </FormWrapper>

        <ContentButton>
          <Button
            label={t('confirm')}
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
            className="submit-btn"
          />
        </ContentButton>
      </Wrapper>
      <StatusModal
        open={isOpenReservationStatusModal}
        state={modalRegisterGuestStatus}
        maxWidth="375px"
      >
        {modalRegisterGuestStatus === StatusModalStates.LOADING && (
          <StatusTitleModal>{t('searching_booking')}</StatusTitleModal>
        )}
        {modalRegisterGuestStatus === StatusModalStates.SUCCESS && (
          <StatusTitleModal>{t('booking_found')}</StatusTitleModal>
        )}
      </StatusModal>
      <ErrorModal />
    </PageUniversalLink>
  );
}

export {NewReservationView};
