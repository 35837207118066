import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useChekinSDK} from '../../../../context/ChekinSDK';
import {useActions} from 'hooks';
import {useSchema} from '../../../../context/guest';
import {getDocumentsListAsOptions} from 'utils/docTypes';
import {PATHS} from 'Routes';
import {FORM_NAMES} from 'utils/guestForm';
import {IV_DOCUMENT_IMAGE_TYPES, QUERY_CACHE_KEYS} from '../../../../utils/constants';
import {SelectOptionType} from '@guestapp/sdk';
import Select from 'components/common/Select';
import passportIcon from '../../../../assets/icons/passport-icon-standar.svg';
import AdaptiveQueriedInfiniteScrollSelect from '../../../../components/common/QueriedInfiniteScrollSelect';
import {MainButton} from 'styled/common';
import {
  PassportIcon,
  PassportTextContainer,
  SelectsContainer,
  Text,
  Wrapper,
} from './styled';

type FormTypes = {
  [FORM_NAMES.nationality]: SelectOptionType;
  [FORM_NAMES.document_type]: SelectOptionType | null;
};

function DocumentSelectionView() {
  const {t} = useTranslation();
  const {getCountriesList} = useChekinSDK();
  const {control, watch, handleSubmit, setValue} = useForm<FormTypes>();
  const {setDocumentType, setNationality, resetIVProcess} = useActions();

  const {
    reservationDocumentsList,
    setNationality: setGuestNationality,
    isGuestSchemaFetching,
  } = useSchema();

  const nationality = watch(FORM_NAMES.nationality);
  const documentType = watch(FORM_NAMES.document_type);
  const navigate = useNavigate();

  const documentOptions = getDocumentsListAsOptions(reservationDocumentsList);

  const onConfirm = React.useCallback(
    (data: FormTypes) => {
      if (data?.[FORM_NAMES.document_type]?.data) {
        setDocumentType(data[FORM_NAMES.document_type].data);
      }
      if (data[FORM_NAMES.nationality]?.value) {
        setNationality(data[FORM_NAMES.nationality]?.value);
      }

      navigate(`../${PATHS.verification.uploadingType}/${IV_DOCUMENT_IMAGE_TYPES.front}`);
    },
    [navigate, setDocumentType, setNationality],
  );

  React.useEffect(() => {
    setGuestNationality?.(nationality?.value);
  }, [nationality, setGuestNationality]);

  React.useEffect(() => {
    resetIVProcess();
  }, [resetIVProcess]);

  React.useEffect(() => {
    if (nationality) {
      setValue(FORM_NAMES.document_type, null);
    }
  }, [nationality, setValue]);

  return (
    // <IVSteps>
    <Wrapper className="iv-document-setup-view">
      <PassportTextContainer className="iv-document-setup-view__text">
        <PassportIcon src={passportIcon} width={34} height={48} />
        <Text>{t('the_document_select_will_be_used_for_check_in_form')}</Text>
      </PassportTextContainer>
      <SelectsContainer className="iv-document-setup-view__fields">
        <Controller
          name={FORM_NAMES.nationality}
          rules={{required: t('required')}}
          control={control}
          render={({field, fieldState: {error}}) => (
            <AdaptiveQueriedInfiniteScrollSelect
              label={t('country_of_nationality')}
              placeholder={t('country_of_nationality')}
              queryKey={QUERY_CACHE_KEYS.paginatedCountriesList}
              fetcher={(key: string, page: number, searchQuery: string) =>
                getCountriesList({key, page, searchQuery})
              }
              error={error?.message}
              openMenuOnFocus={false}
              {...field}
            />
          )}
        />
        <br />
        {nationality && (
          <Controller
            name={FORM_NAMES.document_type}
            rules={{required: t('required')}}
            control={control}
            render={({field, fieldState: {error}}) => (
              <Select
                label={t('select_document')}
                options={documentOptions}
                placeholder={t('select_document')}
                error={error?.message}
                loading={isGuestSchemaFetching}
                {...field}
              />
            )}
          />
        )}
      </SelectsContainer>
      {nationality && documentType && (
        <MainButton label={t('confirm')} onClick={handleSubmit(onConfirm)} />
      )}
    </Wrapper>
    // </IVSteps>
  );
}

export {DocumentSelectionView};
