import React from 'react';
import {ModalProps} from '../Modal';
import {useCustomLotties} from 'hooks';
import success from '../../assets/img/success.gif';
import warning from '../../assets/icons/warning-icon.svg';
import {MainLoader} from '../common/loaders';
import {StyledModal} from './styled';

enum StatusModalStates {
  SUCCESS = 'SUCCESS',
  LOADING = 'LOADING',
  IDLE = 'IDLE',
  ERROR = 'ERROR',
}

const AltText = {
  [StatusModalStates.SUCCESS]: 'Success',
  [StatusModalStates.LOADING]: '',
  [StatusModalStates.IDLE]: '',
  [StatusModalStates.ERROR]: 'Error',
};
const Icon = {
  [StatusModalStates.SUCCESS]: success,
  [StatusModalStates.LOADING]: '',
  [StatusModalStates.IDLE]: '',
  [StatusModalStates.ERROR]: warning,
};
const defaultIconProps = {width: 60, height: 60};

export type StatusModalProps = ModalProps & {state?: StatusModalStates};
const StatusModal = React.memo(
  ({
    state = StatusModalStates.LOADING,
    iconAlt: passedIconAlt,
    iconSrc: passedIconSrc,
    iconProps: passedIconProps,
    ...props
  }: StatusModalProps) => {
    const {successAnimationIcon} = useCustomLotties();
    const icon = React.useMemo(() => {
      if (state === StatusModalStates.LOADING) {
        return {
          element: <MainLoader visible width={60} />,
        };
      }
      if (state === StatusModalStates.SUCCESS) {
        return {
          lottie: successAnimationIcon,
        };
      }
      return {
        src: passedIconSrc || Icon[state],
        alt: passedIconAlt || AltText[state],
        props: passedIconProps || defaultIconProps,
      };
    }, [passedIconAlt, passedIconProps, passedIconSrc, state, successAnimationIcon]);

    return (
      <StyledModal
        iconElement={icon.element}
        iconSrc={icon.src}
        iconAlt={icon.alt}
        iconProps={icon.props}
        iconLottie={icon.lottie}
        {...props}
      />
    );
  },
);

export {StatusModal, StatusModalStates};
