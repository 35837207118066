import {GuestGroupApi} from '../api';
import {GuestGroup, UpdateGuestGroup} from '../utils/types';
import {FetcherSettings} from '../api/apiClient';

class GuestGroupService {
  private readonly guestGroupApi: GuestGroupApi;
  constructor() {
    this.guestGroupApi = new GuestGroupApi();
  }

  getGuestGroup(id: string, resolverSettings?: FetcherSettings): Promise<GuestGroup> {
    if (!id) {
      throw new Error('The guest-group id param is not found');
    }
    return this.guestGroupApi.getGuestGroup(id, resolverSettings);
  }

  patchGuestGroup(
    id: string,
    payload: UpdateGuestGroup,
    resolverSettings?: FetcherSettings,
  ) {
    if (!id || !payload) {
      throw new Error('The guest group id or payload argument is not found');
    }
    return this.guestGroupApi.patchGuestGroup(id, payload, resolverSettings);
  }
}

export {GuestGroupService};
