import React from 'react';
import {useTranslation} from 'react-i18next';
import ModalSheet from 'components/common/ModalSheet';
import {ModalSheetProps} from 'components/common/ModalSheet/ModalSheet';
import {ButtonProps} from 'components/common/Button/Button';
import {SpainResidenceOnlyModalContent, StyledStandardModalSheet} from './styled';

function SpainResidenceOnlyModal({isOpenModalSheet, handleCloseModal}: ModalSheetProps) {
  const {t} = useTranslation();

  const modalSheetButtons: ButtonProps[] = React.useMemo(() => {
    const primaryButton: ButtonProps = {
      label: t('understood'),
      primary: true,
      onClick: handleCloseModal,
    };
    return [primaryButton];
  }, [handleCloseModal, t]);

  const ModalContent = React.useMemo(() => {
    return (
      <SpainResidenceOnlyModalContent>
        <div>{t('this_ensurance_only_for_spain_residence')}</div>
        <br />
        <div>{t('fill_required_data_to_hire_ensurance')}</div>
      </SpainResidenceOnlyModalContent>
    );
  }, [t]);

  return (
    <ModalSheet
      isOpenModalSheet={isOpenModalSheet}
      handleCloseModal={handleCloseModal}
      title={t('spain_residence_only')}
      padding
      withCloseButton
    >
      <StyledStandardModalSheet buttons={modalSheetButtons} content={ModalContent} />
    </ModalSheet>
  );
}

export {SpainResidenceOnlyModal};
