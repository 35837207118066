import React from 'react';
import toast, {Toaster} from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import {MainLoader} from '../loaders';
import {MainLoaderWrapper} from './styled';

type LoadingToastProps = {
  show: boolean;
  text?: string;
};
function LoadingToast({show, text}: LoadingToastProps) {
  const {t} = useTranslation();

  React.useEffect(() => {
    if (show) {
      toast.success(text ?? `${t('loading')}`, {
        icon: (
          <MainLoaderWrapper>
            <MainLoader visible={show} width={24} className="fleeeeex" />
          </MainLoaderWrapper>
        ),
      });
    }
  }, [show, t, text]);

  return <Toaster position="bottom-right" containerClassName="tostada" />;
}

export {LoadingToast};
