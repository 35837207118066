import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {
  usePath,
  useFetchOffers,
  useFetchDeals,
  useModalControls,
  useScreenResize,
} from 'hooks';
import {PATHS} from 'Routes';
import Page from 'components/Page';
import Button from 'components/common/Button';
import {useReservation} from 'context/reservation';
import {OfferCard} from 'components/upselling/OfferCard';
import {ContinueModal} from './ContinueModal';
import {device} from '@guestapp/ui/src/utils/device';
import {createDealStatusMap} from './createDealStatusMap';
import {Content, ActionWrapper, ListWrapper, MobileActionPanel} from './styled';

function OfferListView() {
  const {t} = useTranslation();
  const {getFullPath} = usePath();
  const {data: reservation} = useReservation();
  const {data: paginatedOfferList, isLoading} = useFetchOffers({
    housingId: reservation?.housing_id,
  });

  const {data: paginatedDealsList} = useFetchDeals({reservationId: reservation?.id});
  const dealStatusMap = React.useMemo(
    () => createDealStatusMap(paginatedDealsList),
    [paginatedDealsList],
  );
  const hasOffersWithDeal = dealStatusMap.size > 0;

  const navigate = useNavigate();
  const {isMatch: isMobileMode} = useScreenResize(device.tabletSubHeader);

  const {
    isOpen: isContinueModalOpen,
    openModal: openContinueModalModal,
    closeModal: closeContinueModalModal,
  } = useModalControls();

  return (
    <Page
      isLoading={isLoading}
      isSubHeaderVisible
      onBackButtonClick={() => navigate(getFullPath(PATHS.home))}
      title={t('offer_list_view.subtitle')}
      navBarTitle={t('offer_list_view.nav_bar_title')}
      bottomStickyPanel={
        isMobileMode && (
          <MobileActionPanel>
            <Button
              label={hasOffersWithDeal ? t('continue') : t('maybe_later')}
              onClick={openContinueModalModal}
            />
          </MobileActionPanel>
        )
      }
    >
      <Content>
        <ListWrapper>
          <div>{`${t('offer_list_view.info')}:`}</div>
          {paginatedOfferList?.results.map(offer => (
            <OfferCard
              key={offer.id}
              offer={offer}
              status={dealStatusMap.get(offer.id)}
            />
          ))}
        </ListWrapper>
        {!isMobileMode && (
          <ActionWrapper>
            <Button
              label={hasOffersWithDeal ? t('continue') : t('maybe_later')}
              onClick={openContinueModalModal}
            />
          </ActionWrapper>
        )}
      </Content>

      <ContinueModal isOpen={isContinueModalOpen} onClose={closeContinueModalModal} />
    </Page>
  );
}

export {OfferListView};
