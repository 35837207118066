import {apiClient, FetcherSettings} from './apiClient';
import {PrivacyPolicySettings, Contract} from '../utils/types';

class DocumentsApi {
  ENDPOINTS = {
    contracts: (params = '') => `api/v3/documents/contracts/?${params}`,
    documentsPrivacyPolicy: (housingId: string) =>
      `api/v3/documents/privacy-policy-settings/${housingId}/`,
  };

  async getContracts(params = '', fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<Contract[]>(
      this.ENDPOINTS.contracts(params),
      fetcherSettings,
    );
    return response.body;
  }

  async getPrivacyPolicy(id: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<PrivacyPolicySettings>(
      this.ENDPOINTS.documentsPrivacyPolicy(id),
      fetcherSettings,
    );
    return response.body;
  }
}

export {DocumentsApi};
