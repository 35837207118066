import styled from 'styled-components';
import {Icon} from '../Icon';

export const Label = styled.div`
  display: flex;
  align-items: center;
  grid-column-gap: 5px;
  color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.fontSecondary
      : props.theme.colors.$p3};
  user-select: none;
  font-size: ${props => props.theme.fonts.sizes.smallX};
  font-weight: ${props => props.theme.fonts.fontWeight.bold};
`;

export const StyledIcon = styled(Icon)`
  path {
    fill: ${({theme}) =>
      theme.hasTemplate ? theme.colors.branding.fontSecondary : theme.colors.$p3};
  }
`;
