import styled from 'styled-components';
import {device} from '../../../styled/device';

export const Row = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonsWrapper = styled(Row)`
  margin-top: 40px;
  flex-direction: column;
  grid-row-gap: 36px;
  align-items: center;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  .signature-modal__title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 24px;
    font-weight: 400;
    color: #23262f;
    padding: 20px;
  }

  @media (max-width: ${device.mobileL}) {
    & .signature-modal__title {
      gap: 0;
    }
  }
`;

export const ModalTitleText = styled.div`
  margin: 0;
  white-space: nowrap;
  font-size: inherit;
  font-family: Poppins, sans-serif;
  color: ${props => props.theme.colors.branding.fontPrimary};

  @media (max-width: ${device.mobileL}) {
    white-space: normal;
    /* padding-right: 15px; */
  }
`;

export const ModalWrapper = styled.div`
  /* width: 700px; */
  max-width: none;
  position: relative;
  @media only screen and (min-width: ${device.mobileXL}) and (max-height: 601px) {
    width: auto;
  }
`;

export const ModalSubTitle = styled.div`
  /* margin-bottom: 40px; */
  padding: 0px 24px 40px 24px;
  color: ${props => props.theme.colors.branding.fontSecondary};
`;
