import React, {useMemo} from 'react';
import {POLICE_CODES} from '@guestapp/sdk';
import {COUNTRY_CODES} from 'utils/constants';
import {
  NAVIGATION_STATUSES,
  useGuestGroup,
  useModalControls,
  useReservationSettings,
} from 'hooks';
import {MODAL_FORMS} from './constants';
import {useSummary} from 'context/summary';
import {useReservation} from 'context/reservation';
import {ReservationSettingsModal} from './ReservationSettingsModal';

const getSpainAge = (policeType?: string) => {
  if (policeType === POLICE_CODES.nationalPolice) {
    return 16;
  } else if (policeType === POLICE_CODES.civilGuard || policeType === POLICE_CODES.ert) {
    return 14;
  } else {
    return null;
  }
};

const getAgeLimitByCountry = (countryCode: string, policeType?: string) => {
  return (
    {
      [COUNTRY_CODES.spain]: {ageLimit: getSpainAge(policeType)},
      [COUNTRY_CODES.uk]: {ageLimit: 16},
      [COUNTRY_CODES.greece]: {ageLimit: 14},
      [COUNTRY_CODES.sweden]: {ageLimit: 18},
    }[countryCode] || {ageLimit: null}
  );
};

type UseReservationSettingsModalProps = {
  showFirstTime?: boolean;
  openModalPerSession?: boolean;
};

export function useReservationSettingsModal({
  openModalPerSession,
  showFirstTime,
}: UseReservationSettingsModalProps = {}) {
  const {data} = useSummary();
  const {housing, data: reservation, hasAvantio} = useReservation();
  const {checkCanEditReservationDetails} = useReservationSettings();

  const {guestGroup, isSuccess: isSuccessGuestGroup} = useGuestGroup();
  const {
    isOpen: isOpenConfirmModal,
    closeModal: closeConfirmModal,
    openModal: openConfirmModal,
  } = useModalControls();

  const countryCode = data?.country_code;
  const isActiveTaxes = data?.taxes?.active;
  const hasAvantioConfirmLeadGuestForm = Boolean(
    reservation &&
      reservation?.show_invoicing_fields === null &&
      isSuccessGuestGroup &&
      !reservation?.pms_client_data_is_completely_filled &&
      hasAvantio,
  );

  const availableForms = useMemo(() => {
    const isFeratelReservation = housing?.police_account_type === POLICE_CODES.feratel;
    const isNumberOfGuestsEditable = checkCanEditReservationDetails();

    return {
      [MODAL_FORMS.numberOfGuests]: !!isNumberOfGuestsEditable,
      [MODAL_FORMS.confirmLeaderGuest]: hasAvantioConfirmLeadGuestForm,
      [MODAL_FORMS.reservationType]: isNumberOfGuestsEditable
        ? isFeratelReservation
        : (guestGroup?.known_number_of_guests ?? 0) > 1 && isFeratelReservation,
    };
  }, [
    guestGroup?.known_number_of_guests,
    housing,
    checkCanEditReservationDetails,
    hasAvantioConfirmLeadGuestForm,
  ]);
  const isModalAvailable = Object.values(availableForms).some(Boolean);
  const leadGuestFormCompleted =
    reservation?.show_invoicing_fields !== null ||
    reservation?.show_invoicing_fields === undefined;
  // TODO i have to make this comparation, cuz from BE side i only have 3 states
  //true
  //false
  //null
  // and true and false means the form is completed, so i cant use !reservation?.show_invoicing_fields as a condition
  //this is a temporal fix, this must be fixed from BE side to return enum value
  const validateGuestAge = React.useMemo(() => {
    if (isActiveTaxes || !countryCode) {
      return {ageLimit: null};
    }

    return getAgeLimitByCountry(countryCode, housing?.police_account_type);
  }, [countryCode, isActiveTaxes, housing]);

  React.useEffect(() => {
    if (isSuccessGuestGroup) {
      const isProcessComplete =
        data?.guest_registration?.status === NAVIGATION_STATUSES.complete;

      if (hasAvantioConfirmLeadGuestForm) {
        openConfirmModal();

        return;
      }
      if (showFirstTime) {
        if (openModalPerSession && !isProcessComplete && !leadGuestFormCompleted) {
          openConfirmModal();
          return;
        }
      }
    }
  }, [
    openModalPerSession,
    openConfirmModal,
    data?.guest_registration?.status,
    showFirstTime,
    hasAvantioConfirmLeadGuestForm,
    isSuccessGuestGroup,
    leadGuestFormCompleted,
  ]);

  const Modal = React.useMemo(
    () => (
      <ReservationSettingsModal
        isOpen={isOpenConfirmModal && isModalAvailable}
        onClose={closeConfirmModal}
        availableForms={availableForms}
        ageLimit={validateGuestAge.ageLimit}
      />
    ),
    [
      isOpenConfirmModal,
      closeConfirmModal,
      availableForms,
      validateGuestAge.ageLimit,
      isModalAvailable,
    ],
  );

  return {
    isOpen: isOpenConfirmModal,
    closeModal: closeConfirmModal,
    openModal: openConfirmModal,
    ageLimit: validateGuestAge.ageLimit,
    isModalAvailable,
    Modal,
    hasAvantioConfirmLeadGuestForm,
  };
}
