import React from 'react';
import {AllianzOrder} from '@guestapp/sdk/src/utils/allianz/';
import {isAllianzFeatureActivated} from 'utils/common';
import {useReservation} from 'context/reservation';
import {useSummary} from 'context/summary';
import {useFetchAllianzOrders} from './useFetchAllianzOrders';

function useAllianzUsers() {
  const {housing, data: reservation} = useReservation();
  const {data: summary} = useSummary();
  const isAllianzActive = isAllianzFeatureActivated(
    reservation?.check_in_date,
    housing?.allianz_status,
    summary?.country_code,
    summary?.nights_of_stay,
  );
  const {data: orders} = useFetchAllianzOrders({enabled: isAllianzActive});
  const getIsAllianzUser = React.useCallback(
    (guestId: string) => {
      return orders?.some((order: AllianzOrder) => order.guest_id === guestId);
    },
    [orders],
  );

  return {getIsAllianzUser};
}

export {useAllianzUsers};
