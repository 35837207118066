import {Trans, useTranslation} from 'react-i18next';
import {ContentButtonModal, SuccessModalButton, TitleModal} from '../styled';
import {StyledDepositModal, WrapperText} from './styled';

type EnsureSecurityPropertyModalProps = {
  isOpen: boolean;
  onClose: () => void;
};
function EnsureSecurityPropertyModal({
  isOpen,
  onClose,
}: EnsureSecurityPropertyModalProps) {
  const {t} = useTranslation();

  return (
    <StyledDepositModal
      isOpenModalSheet={isOpen}
      handleCloseModal={onClose}
      title={t('property_protection')}
      className="deposit-ensure-selection-modal"
      closeButtonType="click"
    >
      <WrapperText>
        <TitleModal className="deposit-ensure-selection-modal__title">
          <Trans i18nKey="before_proceeding_please_ensure_the_security_of_the_property">
            Before proceeding to the payment, please select the way to{' '}
            <strong>ensure the security of the property</strong> during your stay.
          </Trans>
        </TitleModal>

        <ContentButtonModal className="deposit-ensure-selection-modal__buttons">
          <SuccessModalButton label={t('ok')} onClick={onClose} />
        </ContentButtonModal>
      </WrapperText>
    </StyledDepositModal>
  );
}

export {EnsureSecurityPropertyModal};
