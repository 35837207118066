import React from 'react';
import * as Sentry from '@sentry/react';
import {useTranslation} from 'react-i18next';
import fetcher from '../utils/fetcher';
import {OCRDetectedData} from '@guestapp/sdk';
import {useChekinSDK} from '../context/ChekinSDK';
import {OCR_CHECKING_STATUSES} from '../utils/constants';

const MaxRetriesNumber = 10;
const RetryTimeoutMs = 4000;

type Options = {
  retries?: number;
};
type checkOCRResponse =
  | {
      mrzDetected?: boolean;
      detectionFailed?: boolean;
    }
  | undefined;

type UseDocumentSelfieDetection = {
  onError?: (error: Error) => void;
  onFinish?: (image: string, data?: OCRDetectedData) => void;
  onBefore?: () => void;
  onDetectionFailed?: (
    errorMessage: string,
    data: OCRDetectedData & {image: string},
  ) => void;
  onRetriesFinish?: (message?: string) => void;
};

function useOCR({
  onFinish,
  onBefore,
  onError,
  onDetectionFailed,
  onRetriesFinish,
}: UseDocumentSelfieDetection) {
  const {t} = useTranslation();
  const {sendImageForOCR, checkImageOCRStatus} = useChekinSDK();
  const retryTimeoutRef = React.useRef<ReturnType<typeof setTimeout>>();
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();

  React.useEffect(() => {
    const retryTimeout = retryTimeoutRef.current;
    const timeout = timeoutRef.current;

    return () => {
      clearTimeout(retryTimeout);
      clearTimeout(timeout);
    };
  }, []);

  const handleOCRStatus = React.useCallback(
    (image: string, data: OCRDetectedData) => {
      const mrzDetected = data?.mrz_detected;
      if (!mrzDetected) {
        onDetectionFailed?.(t('the_code_of_the_document_is_not_detected'), {
          ...data,
          image,
        });
      } else {
        onFinish?.(image, data);
      }
    },
    [t, onFinish, onDetectionFailed],
  );

  const checkOCRProcessStatus = React.useCallback(
    async (
      processId: string,
      image: string,
      options?: Options,
    ): Promise<checkOCRResponse> => {
      try {
        const data = await fetcher(checkImageOCRStatus, processId);
        const isInProgress =
          data?.status === OCR_CHECKING_STATUSES.processing ||
          data?.status === OCR_CHECKING_STATUSES.new;

        if (isInProgress) {
          const retries = options?.retries || 0;
          if (retries >= MaxRetriesNumber) {
            onRetriesFinish?.(t<string>('try_again_or_contact'));
            return;
          }

          retryTimeoutRef.current = setTimeout(async () => {
            await checkOCRProcessStatus(processId, image, {retries: retries + 1});
          }, RetryTimeoutMs);

          return;
        }

        if (!data) {
          return;
        }
        handleOCRStatus(image, data);
      } catch (error) {
        Sentry.captureException(error);
        onError?.(error as Error);
      }
    },
    [checkImageOCRStatus, handleOCRStatus, onError, onRetriesFinish, t],
  );

  const sendAndVerifyImageForOCR = React.useCallback(
    async (image: string, countryCode: string, reservationId: string) => {
      onBefore?.();

      try {
        const data = await fetcher(sendImageForOCR, {
          picture_file: image,
          for_country: countryCode,
          reservation_id: reservationId,
        });

        if (!data?.id) return;

        await checkOCRProcessStatus(data?.id, image);
      } catch (error) {
        Sentry.captureException(error);
        onError?.(error as Error);
      }
    },
    [onBefore, sendImageForOCR, checkOCRProcessStatus, onError],
  );

  const sendToOCR = React.useCallback(
    (image: string, countryCode: string, reservationId: string) => {
      return sendImageForOCR({
        picture_file: image,
        for_country: countryCode,
        reservation_id: reservationId,
      });
    },
    [sendImageForOCR],
  );

  const checkOCRResult = React.useCallback(
    (sentImageId: string) => {
      return checkImageOCRStatus(sentImageId);
    },
    [checkImageOCRStatus],
  );

  return {
    sendAndVerifyImageForOCR,
    checkOCRResult,
    sendToOCR,
  };
}

export {useOCR};
