import dayjs from 'dayjs';
import {CustomForm} from './types';
import {PAYLOAD_FIELDS_NAMES} from './constants';
import {CustomFormApi} from '../api';
import {DATE_FORMAT, getBase64} from '../utils';
import {FetcherSettings} from '../api/apiClient';

class CustomFormService {
  customFormApi: CustomFormApi;

  constructor() {
    this.customFormApi = new CustomFormApi();
  }

  async buildCustomFields(
    formData: Record<string, string | boolean | Date | Blob | null | number>,
  ) {
    const formNames = Object.values(PAYLOAD_FIELDS_NAMES);
    const formDataNames = Object.keys(formData);
    const customFormNames = formDataNames.filter(name => {
      return !formNames.includes(name as PAYLOAD_FIELDS_NAMES);
    });

    const payload: {[key: string]: string | boolean | number} = {};

    for await (const formName of customFormNames) {
      const value = formData[formName as PAYLOAD_FIELDS_NAMES];

      if (value instanceof Blob) {
        payload[formName] = await getBase64(value as File);
        continue;
      }

      if (value instanceof Date) {
        payload[formName] = dayjs(new Date(value)).format(DATE_FORMAT);
        continue;
      }

      if (value) {
        payload[formName] = value;
      }
    }

    return payload;
  }

  async saveToFields(
    formId: string,
    guestId: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formData: {[key: string]: any},
    resolverSettings?: FetcherSettings,
  ) {
    const customFieldsPayload = await this.buildCustomFields(formData);
    const payload = {
      data: customFieldsPayload,
      guest_id: guestId,
      form_id: formId,
    };

    return this.customFormApi.createFields(payload, '', resolverSettings);
  }

  getCustomForm(
    params = '',
    resolverSettings?: FetcherSettings,
  ): Promise<{id: string}[]> {
    return this.customFormApi.getCustomForm(params, resolverSettings);
  }

  getCustomFormById(
    id: string,
    params = '',
    resolverSettings?: FetcherSettings,
  ): Promise<CustomForm> {
    return this.customFormApi.getCustomFormById(id, params, resolverSettings);
  }
}

export {CustomFormService};
