import React from 'react';
import {IV_DOCUMENT_IMAGE_TYPES} from '../utils/constants';
import {DOCUMENTS_TYPES} from '../utils/docTypes';
import {useAppSelector} from './redux';
import {useSchema} from '../context/guest';
import {getDocumentType, getImageDetails} from '../store/slices/identityVerification';

function getIVDocTypes(documentType: string, biomatch?: boolean) {
  const docTypes =
    documentType === DOCUMENTS_TYPES.passport
      ? [IV_DOCUMENT_IMAGE_TYPES.front]
      : [IV_DOCUMENT_IMAGE_TYPES.front, IV_DOCUMENT_IMAGE_TYPES.back];
  if (biomatch) {
    docTypes.push(IV_DOCUMENT_IMAGE_TYPES.selfie);
  }
  return docTypes;
}

function useIVComputedDetails() {
  const imagesDetails = useAppSelector(getImageDetails);
  const documentType = useAppSelector(getDocumentType);
  const {
    isGuestSchemaLoaded: isDataLoaded,
    identityVerificationDetails: {enabled: isIVEnabled, biomatch, required, ocr},
  } = useSchema();

  const isIVDocumentPhotosUploaded = React.useMemo(() => {
    if (!isIVEnabled || !documentType?.value) return true;
    const docTypes = getIVDocTypes(documentType.value);

    return docTypes.every(docType => imagesDetails[docType]?.uploaded);
  }, [isIVEnabled, imagesDetails, documentType]);

  const isIVCompleted = React.useMemo(() => {
    if (!isIVEnabled && !documentType?.value) return true;
    const docTypes = getIVDocTypes(documentType?.value as string, biomatch);
    return docTypes.every(docType => imagesDetails[docType]?.uploaded);
  }, [biomatch, isIVEnabled, imagesDetails, documentType]);

  return {
    isIVDocumentPhotosUploaded,
    isIVCompleted,
    isDataLoaded,
    isIVEnabled,
    biomatch,
    required,
    ocr,
  };
}

export {useIVComputedDetails};
