import React from 'react';
import {useTranslation} from 'react-i18next';
import {useErrorModal} from '../../../hooks';
import {PhoneDetails} from '@guestapp/sdk';
import Input from '../Input';
import PhoneCodesSelect from './PhoneCodesSelect';
import {ErrorMessage} from '@guestapp/ui';
import {Wrapper} from './styled';

type CountryCodeOption = {
  name: string;
  code: string;
};

const isPhoneNumberDetails = (
  value?: string | null | number | PhoneDetails,
): value is PhoneDetails => {
  if (typeof value === 'string' || typeof value === 'number') {
    return false;
  }

  return !!value?.code;
};

export type PhoneInputProps = {
  onChange: (value: string, name?: string, details?: PhoneDetails) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onBlur?: () => void;
  name?: string;
  label?: string;
  disabled?: boolean;
  defaultInputValue?: string;
  defaultCode?: string;
  placeholder?: string;
  value?: string | number | null | PhoneDetails; // NOTE: Use null to reset fields
  error?: string;
  tooltipContent?: string;
  codeReadOnly?: boolean;
  className?: string;
};

export const defaultProps: PhoneInputProps = {
  onChange: () => {},
  name: '',
  defaultInputValue: '',
  defaultCode: '',
  placeholder: '',
  disabled: false,
  error: '',
};

const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(
  (
    {
      onChange,
      onBlur,
      name,
      label,
      disabled,
      defaultCode,
      defaultInputValue,
      placeholder,
      value,
      error,
      codeReadOnly,
      tooltipContent,
      onKeyDown,
    },
    ref,
  ) => {
    const [selectedPhoneCodeOption, setSelectedPhoneCodeOption] =
      React.useState<CountryCodeOption | null>(null);
    const preloadedInputValue =
      defaultInputValue || (isPhoneNumberDetails(value) && value?.number);
    const preloadedCodeValue =
      defaultCode || (isPhoneNumberDetails(value) && value?.code);
    const [inputValue, setInputValue] = React.useState(preloadedCodeValue || '');

    const {ErrorModal, displayError} = useErrorModal();
    const {t} = useTranslation();

    React.useEffect(() => {
      setInputValue(preloadedInputValue || '');
    }, [preloadedInputValue]);

    React.useEffect(() => {
      if (preloadedCodeValue) {
        const option = {name: '', code: preloadedCodeValue};
        option && setSelectedPhoneCodeOption(option);
      }
    }, [preloadedCodeValue, setSelectedPhoneCodeOption]);

    React.useEffect(() => {
      if (value === null) {
        setInputValue('');
        setSelectedPhoneCodeOption(null);
      }
    }, [value]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const {target} = event;
      setInputValue(target.value);

      if (!target.value || !selectedPhoneCodeOption?.code) {
        onChange('', name, {code: '', number: ''});
        return;
      }

      const nextValue = `${selectedPhoneCodeOption.code}${target.value}`;
      onChange(nextValue, name, {
        code: selectedPhoneCodeOption.code,
        number: target.value,
      });
    };

    const handleCountryCodeChange = React.useCallback(
      (option: CountryCodeOption) => {
        setSelectedPhoneCodeOption(option);

        if (!inputValue) {
          onChange('', name, {code: '', number: ''});
          return;
        }

        const nextValue = `${option.code}${inputValue}`;
        onChange(nextValue, name, {
          code: option.code,
          number: inputValue,
        });
      },
      [inputValue, name, onChange],
    );

    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (disabled) return;
      onKeyDown?.(event);
    };

    return (
      <>
        <ErrorModal />
        <Wrapper error={error} className="phone-field">
          <PhoneCodesSelect
            onError={displayError}
            value={selectedPhoneCodeOption}
            defaultCode={defaultCode}
            disabled={disabled}
            readOnly={codeReadOnly}
            error={error}
            onChange={handleCountryCodeChange}
          />
          <Input
            error={error}
            ref={ref}
            onChange={handleInputChange}
            onBlur={onBlur}
            type="number"
            label={label}
            disabled={disabled}
            value={inputValue}
            tooltipContent={tooltipContent}
            placeholder={placeholder}
            inputMode="tel"
            aria-label={name}
            onKeyDown={handleKeyDown}
            className="phone-field__input"
          />
          <div>
            {!error && !selectedPhoneCodeOption && inputValue && (
              <ErrorMessage>{t('prefix_required')}</ErrorMessage>
            )}
          </div>
        </Wrapper>
      </>
    );
  },
);

PhoneInput.defaultProps = defaultProps;
export {PhoneInput};
