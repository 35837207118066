import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  Content,
  ProtectionModalText,
  ProtectionTermButton,
  StyledModalSheet,
  WrapperText,
} from './styled';

type DamageProtectionInfoModalProps = {
  isOpen: boolean;
  close: () => void;
  policyLink: () => void;
};
function DamageProtectionInfoModal({
  isOpen,
  close,
  policyLink,
}: DamageProtectionInfoModalProps) {
  const {t} = useTranslation();

  return (
    <StyledModalSheet
      isOpenModalSheet={isOpen}
      handleCloseModal={close}
      title={t('waivo_damage_protection_program')}
      className="damage-protection-info-modal"
      nested
      withCloseButton
      closeButtonType="click"
    >
      <Content>
        <WrapperText>
          <ProtectionModalText>
            {t('security_deposit_description_waivo_damage')}
          </ProtectionModalText>
          <br />
          <h3>{t('why_damage_protection')}</h3>
          <ProtectionModalText>
            • {t('save_valuable_time_with_this_convenient_alternative')}
          </ProtectionModalText>
          <ProtectionModalText>
            • {t('protect_yourself_against_unexpected')}
          </ProtectionModalText>
          <ProtectionModalText>• {t('hassle_free_protection')}</ProtectionModalText>
          <br />
          <h3>{t('how_it_works')}</h3>
          <ProtectionModalText>
            • {t('pay_a_small_non-refundable_damage_protection')}
          </ProtectionModalText>
          <ProtectionModalText>• {t('review_the_protection_terms')}</ProtectionModalText>
          <ProtectionModalText>• {t('relax_and_enjoy_your_stay')}</ProtectionModalText>
          <br />
          <h3>{t('what_if_damage_occurs')}</h3>
          <ProtectionModalText>• {t('notify_your_host_if_damage')}</ProtectionModalText>
          <ProtectionModalText>
            • {t('your_host_handles_all_communications')}
          </ProtectionModalText>
          <ProtectionModalText>
            • {t('the_damage_protection_does_not_negate')}
          </ProtectionModalText>

          <ProtectionTermButton
            label={t('view_protection_terms')}
            onClick={policyLink}
            outlined
          />
        </WrapperText>
      </Content>
    </StyledModalSheet>
  );
}

export {DamageProtectionInfoModal};
