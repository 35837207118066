import React from 'react';
import {LinkProps as RouterLinkProps, To} from 'react-router-dom';
import clsx from 'clsx';
import {ExternalLink, RouterLink} from './styled';

export type CommonLinkProps = Omit<RouterLinkProps, 'to'> & {
  label?: string;
  to?: RouterLinkProps['to'];
  className?: string;
  disabled?: boolean;
  action?: boolean;
  densed?: boolean;
  dark?: boolean;
  href?: string;
};

type LinkPropsWithChildren = CommonLinkProps & {
  children: React.ReactNode | JSX.Element | string;
  label?: never;
};

type LinkPropsWithLabel = CommonLinkProps & {
  label: string;
  children?: never;
};
type LinkProps = LinkPropsWithLabel | LinkPropsWithChildren;

function Link({
  children = '',
  label,
  to = '/',
  href,
  referrerPolicy,
  densed = false,
  dark,
  className = undefined,
  disabled = false,
  ...props
}: LinkProps) {
  const propsDto = {
    $dark: dark,
    $densed: densed,
  };
  const externalLinkClasses = clsx(className, 'external-link');
  const routerLinkClasses = clsx(className, 'router-link');

  return href ? (
    <ExternalLink className={externalLinkClasses} {...props} {...propsDto} href={href}>
      {children || label}
    </ExternalLink>
  ) : (
    <RouterLink className={routerLinkClasses} {...props} {...propsDto} to={to as To}>
      {children || label}
    </RouterLink>
  );
}

export {Link};
