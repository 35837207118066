import React from 'react';
import dayjs from 'dayjs';
import {useFormContext} from 'react-hook-form';
import {FIELD_TYPES, Guest, SelectOptionType} from '@guestapp/sdk';
import {
  DATE_FIELDS,
  FORM_NAMES,
  FormTypes,
  EXTRA_FORM_NAMES,
  PHONE_FIELDS,
} from '../utils/guestForm';
import {usePreloadPoliceData} from 'components/GuestFormFields/usePreloadPoliceData';
import {STORAGE_KEYS} from '../utils/constants';
import {SessionStorage} from '@guestapp/core';

const SELECT_OPTION_TYPES_FIELDS = [
  FORM_NAMES.document_type,
  FORM_NAMES.gender,
  FORM_NAMES.kinship_relationship,
  FORM_NAMES.purpose_of_stay,
  FORM_NAMES.tax_exemption,
];

const getIsValidSelectOptionType = (value: SelectOptionType) => {
  return Boolean(value?.value) && Boolean(value?.label);
};

const getIsEmptyPhoneValue = (value: Record<string, string>) => {
  return Object.values(value).some(val => !val);
};

const buildGuestFormDataFromDB = (guestData: Partial<Guest>) => {
  if (!guestData) return;
  const guestValues = Object.entries(guestData);
  const preloadedData: {[key: string]: string | SelectOptionType | Date} = {};

  // TODO Any type. Should be fixed
  // eslint-disable-next-line
  guestValues.forEach(([fieldName, fieldValue]: [string, any]) => {
    const isFieldExist = Object.values(FORM_NAMES).includes(fieldName as FORM_NAMES);

    if (isFieldExist && fieldValue) {
      const isDateValue = DATE_FIELDS.includes(fieldName as FORM_NAMES);
      const isLocationValue = Boolean(fieldValue?.code && fieldValue?.name);
      const isPhoneValue = PHONE_FIELDS.includes(fieldName as FORM_NAMES);

      if (isPhoneValue) {
        if (getIsEmptyPhoneValue(fieldValue)) return;
        preloadedData[fieldName] = fieldValue;
        return;
      }
      if (isLocationValue) {
        preloadedData[fieldName] = {
          label: fieldValue.name,
          value: fieldValue.code,
        };
        return;
      }

      if (isDateValue) {
        preloadedData[fieldName] = dayjs(fieldValue).toDate();
        return;
      }

      if (SELECT_OPTION_TYPES_FIELDS.includes(fieldName as FORM_NAMES)) {
        const value = fieldValue as SelectOptionType;
        if (!getIsValidSelectOptionType(value)) {
          return;
        }
      }
      preloadedData[fieldName] = fieldValue;
    }
  });

  return preloadedData;
};

type UsePreloadGuestDataProps = {
  onPreloaded?: () => void;
  guestData?: Partial<Guest>;
};
function usePreloadGuestData({onPreloaded, guestData}: UsePreloadGuestDataProps = {}) {
  const isDataFromStoragePreloadedRef = React.useRef(false);
  const {reset, setValue, getValues} = useFormContext<FormTypes>();
  const {builtPhoneNumberString} = usePreloadPoliceData({
    getValues,
    reset,
  });

  const finishPreloadData = React.useCallback(
    (formData?: {[p: string]: string | Date | SelectOptionType | undefined}) => {
      isDataFromStoragePreloadedRef.current = true;
      setValue(FORM_NAMES.name, formData?.[FORM_NAMES.name] as string, {
        shouldDirty: true,
      });
      reset(formData);
    },
    [reset, setValue],
  );

  const preloadPersistedFormData = React.useCallback(
    (guestId?: string) => {
      if (isDataFromStoragePreloadedRef.current) return;
      const storageFormData = SessionStorage.get<FormTypes>(
        STORAGE_KEYS.personalFormData,
      );

      if (guestId && guestData) {
        const formData = buildGuestFormDataFromDB(guestData);
        finishPreloadData(formData);
        SessionStorage.remove(STORAGE_KEYS.personalFormData);
        return;
      }
      if (!guestId && storageFormData && Object.keys(storageFormData)?.length) {
        const formData = Object.entries(storageFormData).reduce<
          Record<string, string | Date>
        >((acc, [fieldName, fieldValue]) => {
          if (fieldValue?.type === FIELD_TYPES.date) {
            acc[fieldName] = dayjs(fieldValue.value).toDate();
          } else {
            acc[fieldName] = fieldValue;
          }
          return acc;
        }, {});

        finishPreloadData({
          ...formData,
          [FORM_NAMES.second_phone]: builtPhoneNumberString,
          [EXTRA_FORM_NAMES.secondPhoneDetails]:
            getValues()?.[EXTRA_FORM_NAMES.secondPhoneDetails],
        });
        onPreloaded?.();
      }
    },
    [guestData, finishPreloadData, onPreloaded, getValues, builtPhoneNumberString],
  );

  const clearPersistedStorageFormData = () => {
    SessionStorage.clear();
  };

  const hasPersistedData = () => {
    const persistedData = SessionStorage.get<FormTypes>(STORAGE_KEYS.personalFormData);
    return Boolean(persistedData);
  };
  // const persistFormDataToStorage = React.useCallback(
  //   ({signature}: {signature: SignBlockRef | null}) => {
  //     const isSignatureTouched = signature?.isEnabled;
  //     const isFormTouched = formState.isDirty;
  //     const signatureUrl = signature?.canvas?.toDataURL();
  //
  //     if (formState.isSubmitSuccessful) return;
  //
  //     if (isSignatureTouched) {
  //       SessionStorage.set(STORAGE_KEYS.personalSignatureFormData, signatureUrl);
  //     }
  //     if (isFormTouched) {
  //       const formData = Object.entries(getValues()).reduce<Record<string, any>>(
  //         (acc, [fieldName, fieldValue]) => {
  //           if (fieldValue instanceof Date) {
  //             acc[fieldName] = {value: fieldValue, type: FIELD_TYPES.date};
  //           } else {
  //             acc[fieldName] = fieldValue;
  //           }
  //           return acc;
  //         },
  //         {},
  //       );
  //
  //       SessionStorage.set(STORAGE_KEYS.personalFormData, {
  //         ...formData,
  //       });
  //     }
  //   },
  //   [formState.isSubmitSuccessful, formState.isDirty, getValues],
  // );

  return {
    preloadPersistedFormData,
    clearPersistedStorageFormData,
    hasPersistedData,
    // persistFormDataToStorage,
  };
}

export {usePreloadGuestData};
