import React, {ButtonHTMLAttributes} from 'react';
import clsx from 'clsx';
import {Button} from './styled';

export type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon?: string | JSX.Element;
  iconSrc?: string;
  iconAlt?: string;
  iconProps?: {width?: number; height?: number};
  light?: boolean;
  className?: string;
};
function IconButton({
  icon,
  iconSrc,
  iconAlt = 'button',
  iconProps,
  light,
  className,
  ...props
}: IconButtonProps) {
  const propsDto = React.useMemo(
    () => ({
      $light: light,
    }),
    [light],
  );

  return (
    <Button className={clsx(className, 'icon-button')} {...propsDto} {...props}>
      {icon || <img src={iconSrc} alt={iconAlt} {...iconProps} />}
      {/*<Shadow />*/}
    </Button>
  );
}

export {IconButton};
