import React from 'react';
import {useTranslation} from 'react-i18next';
import {DisplayedPayment} from '../types';
import blueArrow from '../../../assets/icons/blue-arrow.svg';
import infoIcon from '../../../assets/icons/info.svg';
import RollUpButton from '../../common/RollUpButton';
import {
  Content,
  CounterText,
  DeferredPaymentRow,
  DeferredPaymentWrapper,
  Description,
  Header,
  Hr,
  MainBox,
  PaymentLabel,
  PaymentName,
  Price,
  Signal,
  StyledButton,
  Title,
  Vr,
  Wrapper,
} from './styled';

type DeferredPaymentProps = {
  id: string;
  name: string;
  price: string;
  onSendBackToCart: (paymentId: string) => void;
  label?: string;
};
const DeferredPayment = ({
  id,
  name,
  label,
  price,
  onSendBackToCart,
}: DeferredPaymentProps) => {
  const {t} = useTranslation();

  return (
    <DeferredPaymentWrapper className="deferred-payment-item">
      <DeferredPaymentRow className="deferred-payment-item__row">
        <div>
          <PaymentName className="deferred-payment-item__name">{name}</PaymentName>
          {!!label && (
            <PaymentLabel className="deferred-payment-item__label">{label}</PaymentLabel>
          )}
        </div>
        <div>
          <Vr />
          <Price className="deferred-payment-item__price">{price}</Price>
        </div>
      </DeferredPaymentRow>

      <StyledButton
        onClick={() => onSendBackToCart(id)}
        label={t('send_back_to_cart')}
        link
      />
    </DeferredPaymentWrapper>
  );
};

type PayLaterProps = {
  payments: DisplayedPayment[];
  currency: string;
  onSendBackToCart: (id: string) => void;
};
function PayLater({payments, currency, onSendBackToCart}: PayLaterProps) {
  const {t} = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const countOfPayments = payments?.length;
  const totalAmount = payments?.reduce<number>((sum, payment) => {
    return sum + Number(payment.price);
  }, 0);

  React.useEffect(() => {
    if (!payments.length) {
      setExpanded(false);
    }
  }, [payments.length]);

  return (
    <Wrapper className="pay-later">
      <MainBox
        onClick={() => setExpanded(prevState => !prevState)}
        className="pay-later__main-box"
      >
        <Header className="pay-later__header">
          <div>
            <Title className="pay-later__title">{t('pay_later')}</Title>
            <Signal size={12} />
            <CounterText className="pay-later__counter">
              {t('pay_later_info', {count: countOfPayments, totalAmount, currency})}
            </CounterText>
          </div>
          {Boolean(payments.length) && (
            <RollUpButton
              $expanded={expanded}
              icon={<img src={blueArrow} width={16} height={9} alt="" />}
            />
          )}
        </Header>
        <Description className="pay-later__description">
          <img src={infoIcon} width={21} height={21} alt="" />
          {t('pay_later_description')}
        </Description>
      </MainBox>
      <Content expanded={expanded} className="deferred-payments-list">
        <Hr />
        {payments.map(payment => (
          <DeferredPayment
            id={payment.id}
            key={payment.id}
            name={payment.title}
            label={payment.label}
            price={payment.price + currency}
            onSendBackToCart={onSendBackToCart}
          />
        ))}
      </Content>
    </Wrapper>
  );
}

export {PayLater};
