import React from 'react';
import {createRoot, Root} from 'react-dom/client';
import {
  RESERVATION_DETAILS,
  EXTERNAL_ID_KEY,
  RESERVATION_ID_KEY,
  EMBEDDED_STYLES,
  EMBEDDED_STYLES_LINK,
  EMBEDDED_MODE,
} from './utils/embedded';
import {LocalStorage} from '@guestapp/core';
import EmbeddedApp from './EmbeddedApp';

type InitialAppProps = Window['ChekinProSettings'];

type RenderAppProps = {
  targetNode: string;
};

type InitAndRenderProps = InitialAppProps & RenderAppProps;

const mockCallback = () => {};

class ChekinPro {
  private root: Root | null = null;
  private clearLocalStorage() {
    LocalStorage.remove(RESERVATION_ID_KEY);
    LocalStorage.remove(EXTERNAL_ID_KEY);
    LocalStorage.remove(RESERVATION_DETAILS);
    LocalStorage.remove(EMBEDDED_STYLES);
    LocalStorage.remove(EMBEDDED_STYLES_LINK);
  }

  initialize(props: InitialAppProps) {
    const {
      mode = EMBEDDED_MODE.all,
      apiKey,
      styles,
      stylesLink,
      externalId,
      reservationId,
      defaultLanguage,
      enableGuestsRemoval,
      onError,
      onGuestRegistered,
      onAllGuestsRegistered,
      onConnectionError,
      onReservationFound,
      onReservationFetched,
      onScreenChanged,
      canShareRegistrationLink,
      canEditReservationDetails = true,
    } = props;
    this.clearLocalStorage();
    LocalStorage.set(RESERVATION_ID_KEY, reservationId);
    LocalStorage.set(EXTERNAL_ID_KEY, externalId);
    LocalStorage.set(RESERVATION_DETAILS, canEditReservationDetails);
    LocalStorage.set(EMBEDDED_STYLES, styles || '');
    LocalStorage.set(EMBEDDED_STYLES_LINK, stylesLink || '');

    window.ChekinProSettings = {
      mode,
      apiKey,
      styles,
      stylesLink,
      externalId: externalId || undefined,
      reservationId: reservationId || undefined,
      defaultLanguage,
      onError,
      onScreenChanged,
      onConnectionError,
      onGuestRegistered,
      onReservationFound,
      onReservationFetched,
      onAllGuestsRegistered,
      enableGuestsRemoval,
      canShareRegistrationLink,
      canEditReservationDetails,
    };
    console.log('The Embedded Chekin initialized');
  }

  static getSettings() {
    const reservationId =
      window?.ChekinProSettings?.reservationId || LocalStorage.get(RESERVATION_ID_KEY);
    const apiKey = window?.ChekinProSettings?.apiKey;
    const externalId =
      window?.ChekinProSettings?.externalId || LocalStorage.get(EXTERNAL_ID_KEY);
    const defaultLanguage = window?.ChekinProSettings?.defaultLanguage;
    const enableGuestsRemoval = window?.ChekinProSettings?.enableGuestsRemoval;
    const mode = window?.ChekinProSettings?.mode;

    const canEditReservationDetails =
      window?.ChekinProSettings?.canEditReservationDetails ||
      LocalStorage.get(RESERVATION_DETAILS);

    const canShareRegistrationLink = window?.ChekinProSettings?.canShareRegistrationLink;

    const styles = window?.ChekinProSettings?.styles || LocalStorage.get(EMBEDDED_STYLES);
    const stylesLink =
      window?.ChekinProSettings?.stylesLink || LocalStorage.get(EMBEDDED_STYLES_LINK);

    return {
      mode,
      apiKey,
      styles,
      stylesLink,
      externalId,
      reservationId,
      defaultLanguage,
      enableGuestsRemoval,
      canShareRegistrationLink,
      canEditReservationDetails,
    };
  }

  static get events() {
    const settings = window.ChekinProSettings || {};

    return {
      onReservationFound: settings?.onReservationFound || mockCallback,
      onReservationFetched: settings?.onReservationFetched || mockCallback,
      onGuestRegistered: settings?.onGuestRegistered || mockCallback,
      onAllGuestsRegistered: settings?.onAllGuestsRegistered || mockCallback,
      onConnectionError: settings?.onConnectionError || mockCallback,
      onScreenChanged: settings?.onScreenChanged || mockCallback,
      onError: settings?.onError || mockCallback,
    };
  }

  renderApp({targetNode}: RenderAppProps) {
    const reactElement = React.createElement(EmbeddedApp, {}, 'Chekin-Pro');
    const container = document.getElementById(targetNode);

    if (!container) {
      throw new Error('The target element by id not found');
    }

    const root = createRoot(container);
    this.root = root;
    root.render(reactElement);
    return reactElement;
  }

  inject() {
    return EmbeddedApp;
  }

  unmount() {
    return this.root?.unmount();
  }

  initAndRender(props: InitAndRenderProps) {
    this.initialize(props);
    this.renderApp({targetNode: props.targetNode});
  }
}

export default ChekinPro;
