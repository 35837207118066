import React, {PropsWithChildren} from 'react';
import {Template} from '@guestapp/sdk';
import {STATUSES} from '@guestapp/core';
import {useChekinSDK} from './ChekinSDK';
import {FontsData, guestappTheme, GuestappThemeProps} from 'styled/theme';
import {buildCustomTheme, getTemplateFonts} from 'utils/customTheme';
import {useCustomFonts, useFetchTemplate} from 'hooks/customTheme';
import {useCustomizeHeadContent} from 'hooks';

type ContextProps = {
  data?: Template;
  finalTheme: GuestappThemeProps;
  customFontFaces: string;
  isTemplateInitialized: boolean;
  hasTemplate: boolean;
  isLoadedTemplate: boolean;
};

const CustomThemeContext = React.createContext<ContextProps>({
  data: undefined,
  finalTheme: guestappTheme,
  customFontFaces: '',
  isTemplateInitialized: false,
  hasTemplate: true,
  isLoadedTemplate: false,
});

function CustomThemeProvider({children}: Readonly<PropsWithChildren>) {
  const {templateId, isAuthInitialization} = useChekinSDK();
  const [dynamicStyles, setDynamicStyles] = React.useState<GuestappThemeProps | null>(
    null,
  );

  const updateFonts = React.useCallback((customFonts: {[key: string]: FontsData}) => {
    if (!customFonts) {
      return;
    }

    setDynamicStyles(prev => {
      const newData = getTemplateFonts(prev, customFonts);
      return newData;
    });
  }, []);

  const {fetchFonts, fontsStatus, fontFaces} = useCustomFonts({
    onSuccess: updateFonts,
  });

  const onTemplateSuccess = React.useCallback(
    (templateData: Template) => {
      void fetchFonts(templateData);
      const builtTheme = buildCustomTheme(templateData);
      setDynamicStyles(builtTheme);
    },
    [setDynamicStyles, fetchFonts],
  );

  const {data, isFetched} = useFetchTemplate({
    onSuccess: onTemplateSuccess,
    templateId: templateId,
    enabled: !!templateId,
  });

  const hasTemplate = !isAuthInitialization && !!templateId;
  const isLoadedTemplate =
    !isAuthInitialization && isFetched && fontsStatus === STATUSES.success;
  const isTemplateInitialized = hasTemplate ? isLoadedTemplate : !isAuthInitialization;

  const customTheme = React.useMemo(() => data || ({} as Template), [data]);
  useCustomizeHeadContent(customTheme?.favicon, customTheme?.site_title);

  const finalTheme: GuestappThemeProps = React.useMemo(() => {
    return dynamicStyles && templateId // templateData.is_published
      ? {...guestappTheme, ...dynamicStyles}
      : guestappTheme;
  }, [templateId, dynamicStyles]);

  const defaultProps = React.useMemo<ContextProps>(
    () => ({
      data: customTheme,
      finalTheme,
      customFontFaces: fontFaces,
      isTemplateInitialized,
      hasTemplate,
      isLoadedTemplate,
    }),
    [
      finalTheme,
      fontFaces,
      customTheme,
      isTemplateInitialized,
      hasTemplate,
      isLoadedTemplate,
    ],
  );

  return <CustomThemeContext.Provider value={defaultProps} children={children} />;
}

function useCustomTheme() {
  const context = React.useContext(CustomThemeContext);
  if (context === undefined) {
    throw new Error('useSummary must be used within a CustomThemeProvider');
  }
  return context;
}

export {CustomThemeContext, CustomThemeProvider, useCustomTheme};
