import styled from 'styled-components';
import {transformSizePropToSize} from '../../../../utils/common';

export const Wrapper = styled.div<{$width?: number | string}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  svg {
    width: ${props => transformSizePropToSize(props.$width)};
    transform-origin: center;
    animation: rotate4 2.5s linear infinite;
  }

  .circle {
    fill: none;
    stroke: ${props =>
      props.theme.hasTemplate
        ? props.theme.colors.branding.primary
        : props.theme.colors.buttons.mainButtonBackground};
    stroke-width: 7;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    z-index: 999;
    animation: dash4 2s ease-in-out infinite;
  }

  .cover {
    stroke-width: 7;
    stroke: ${props => props.theme.colors.$p9};
    opacity: 0.05;
    z-index: -1;
    fill: none;
  }

  @keyframes rotate4 {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash4 {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 90, 200;
      stroke-dashoffset: -35px;
    }

    100% {
      stroke-dashoffset: -125px;
    }
  }
`;
