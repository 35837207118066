import {apiClient, FetcherSettings} from './apiClient';
import {PAYLOAD_FIELDS_NAMES} from '../services';

export type IVPayload = {
  picture_file?: string;
  reservation_id?: string;
  for_country?: string;
  id?: string;
};

export type DocumentsAndSelfieVerificationPayload = {
  [PAYLOAD_FIELDS_NAMES.document_front_side_scan]?: Blob | null;
  [PAYLOAD_FIELDS_NAMES.document_back_side_scan]?: Blob | null;
  [PAYLOAD_FIELDS_NAMES.biomatch_selfie]?: Blob | null;
  [PAYLOAD_FIELDS_NAMES.reservation_id]: string;
  [PAYLOAD_FIELDS_NAMES.biomatch_doc_id]?: string;
};

enum IVStatus {
  complete = 'COM',
  error = 'ERR',
  new = 'NEW',
  process = 'PRO',
}

export type BiomatchResponse = {
  id: string;
  is_face_detected: boolean;
  picture_file: string;
  reservation_id: string;
  status: IVStatus;
  user_email: string;
};

export type OCRDetectedData = {
  barcode_detected: boolean;
  country: string | null;
  created_by: string;
  date_of_birth: string | null;
  do_face_extraction: boolean;
  expiration_date: string | null;
  extracted_face_file: string | null;
  first_surname: string;
  id: string;
  issue_date: string;
  mrz_detected: boolean;
  names: string | null;
  nationality: string | null;
  number: string | null;
  picture_file: string;
  picture_with_face: 1 | 0;
  place_of_birth: string | null;
  sex: 'M' | 'F' | null;
  status: IVStatus;
  surname: string | null;
  type_doc: string | null;
  valid_composite: boolean;
  valid_date_of_birth: boolean;
  valid_expiration_date: boolean;
  valid_issue_date: boolean;
  valid_number: boolean;
  valid_score: number | null;
  is_face_detected: boolean;
  second_surname: string;
};

export type CompareBiomatchResponse = {
  distance: number | null;
  guest_name: string | null;
  id: string;
  identification_picture: string;
  is_match: false;
  person_picture: string;
  reservation_creation_date: Date | string | null;
  reservation_id: string;
  status: IVStatus;
  user_email: string;
};

export type CompareBiomatchPayload = {
  reservation_id: string;
  identification_picture: string;
  person_picture: string;
};

class IdentityVerificationApi {
  private readonly ENDPOINTS = {
    biomatchIdentification: (id = '') =>
      `api/v4/biomatch/identification/${id ? `${id}/` : ``}`,
    biomatchPerson: (id = '') => `api/v4/biomatch/person/${id ? `${id}/` : ``}`,
    biomatchCompare: (id = '') => `api/v4/biomatch/compare/${id ? `${id}/` : ``}`,
    ocrPicture: () => `api/v3/ocr/picture/regula/`,
    ocrData: (id = '') => `api/v3/ocr/data/${id ? `${id}/` : ``}`,
  };

  async sendDocumentForFaceDetection(
    payload: IVPayload,
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient.post<BiomatchResponse>(
      this.ENDPOINTS.biomatchIdentification(),
      {
        json: payload,
        ...fetcherSettings,
      },
    );
    return response.body;
  }

  async checkDocumentFaceDetectionStatus(id: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<BiomatchResponse>(
      this.ENDPOINTS.biomatchIdentification(id),
      fetcherSettings,
    );
    return response.body;
  }

  async sendSelfieForFaceDetection(
    payload: IVPayload,
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient.post<BiomatchResponse>(
      this.ENDPOINTS.biomatchPerson(),
      {
        json: payload,
        ...fetcherSettings,
      },
    );
    return response.body;
  }

  async checkSelfieFaceDetection(id: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<BiomatchResponse>(
      this.ENDPOINTS.biomatchPerson(id),
      fetcherSettings,
    );
    return response.body;
  }

  async compareSelfieAndDocumentFaces(
    payload: CompareBiomatchPayload,
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient.post<CompareBiomatchResponse>(
      this.ENDPOINTS.biomatchCompare(),
      {
        json: payload,
        ...fetcherSettings,
      },
    );
    return response.body;
  }

  async checkSelfieAndDocumentFacesComparingStatus(
    id = '',
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient.get<CompareBiomatchResponse>(
      this.ENDPOINTS.biomatchCompare(id),
      fetcherSettings,
    );
    return response.body;
  }

  async sendImageForOCR(payload: IVPayload, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.post<OCRDetectedData>(this.ENDPOINTS.ocrPicture(), {
      json: payload,
      ...fetcherSettings,
    });
    return response.body;
  }

  async checkImageOCRStatus(id = '', fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<OCRDetectedData>(
      this.ENDPOINTS.ocrData(id),
      fetcherSettings,
    );
    return response.body;
  }
}

export {IdentityVerificationApi, IVStatus};
