import React from 'react';
import Compressor from 'compressorjs';
import {useTranslation} from 'react-i18next';
import {
  IDENTITY_VERIFICATION_PHOTOS_MAX_SIZE_MB,
  IDENTITY_VERIFICATION_PHOTOS_QUALITY,
} from '../../../utils/constants';
import {getFileSizeMB, toBase64} from '../../../utils/common';
import notIncludedIcon from '../../../assets/icons/not-included-icon.svg';
import checkIcon from '../../../assets/icons/check.svg';
import {IncludedRow, StyledUploadPhoto, Photo} from './styled';

function getAllowedFormats() {
  return {
    regExpFormats: '.jpg|.jpeg|.png',
    errorMessage: 'incorrect_file_type_only_file_types_allowed',
  };
}

type UploadPhotoProps = {
  image: string | null;
  onUpload: (screenshot: string) => void;
  validationError: string | null;
  setValidationError: (errorMessage: string | null) => void;
};
const UploadPhoto = React.memo(
  ({image, onUpload, validationError, setValidationError}: UploadPhotoProps) => {
    const {t} = useTranslation();
    const ref = React.useRef<HTMLInputElement>(null);

    const handleImage = (image?: File) => {
      if (!image) return;
      const parsedImage = new File([image], image.name, {type: image.type});
      return new Compressor(parsedImage, {
        convertSize: IDENTITY_VERIFICATION_PHOTOS_MAX_SIZE_MB,
        quality: IDENTITY_VERIFICATION_PHOTOS_QUALITY,
        success: (compressedResult: File) => {
          toBase64(compressedResult)
            .then(result => {
              console.log(
                'Before: ',
                getFileSizeMB(image)?.toFixed(2),
                'MB',
                'After: ',
                getFileSizeMB(compressedResult)?.toFixed(2),
                'MB',
              );
              onUpload?.(result);
            })
            .catch(console.error);
        },
      });
    };

    const validationImage = (image?: File) => {
      const fileSize = getFileSizeMB(image);

      if (!image) return false;

      const allowedFormats = getAllowedFormats();
      const regExpAllowedFormats = new RegExp(`(${allowedFormats.regExpFormats})`, 'i');
      const imageName = image?.name || '';

      if (!regExpAllowedFormats.test(imageName)) {
        setValidationError(t(allowedFormats.errorMessage));
        return false;
      }

      if (!fileSize || fileSize > IDENTITY_VERIFICATION_PHOTOS_MAX_SIZE_MB) {
        setValidationError(
          t('max_file_size_is_number_mb', {
            number: IDENTITY_VERIFICATION_PHOTOS_MAX_SIZE_MB,
          }),
        );
        return false;
      }

      setValidationError(null);
      return true;
    };

    const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
      const image = e?.currentTarget?.files?.[0];
      const isSuccessfullyValidated = validationImage(image);
      if (isSuccessfullyValidated) handleImage(image);
    };

    return (
      <>
        {image ? (
          <Photo
            src={image}
            alt="Preloaded"
            className="photo-box__uploaded-photo upload-box__row"
          />
        ) : (
          <StyledUploadPhoto
            ref={ref}
            error={validationError}
            onChange={handleUpload}
            helperText={t<string>('accepted_file_formats_and_max_size', {
              number: IDENTITY_VERIFICATION_PHOTOS_MAX_SIZE_MB,
            })}
          />
        )}
        <IncludedRow>
          <img src={notIncludedIcon} alt="Not included" width={24} height={24} />
          <p>{t('there_should_not_be_reflections')}</p>
        </IncludedRow>
        <IncludedRow>
          <img src={checkIcon} alt="Included" width={24} height={24} />
          <p>{t('use_clear_not_blurred_image')}</p>
        </IncludedRow>
      </>
    );
  },
);

export {UploadPhoto};
