import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const Message = styled.div`
  font-weight: 400;
  line-height: 24px;
  color: ${({theme}) => theme.colors.branding.fontSecondary};
`;
