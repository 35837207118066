import styled, {css} from 'styled-components';
import {device} from 'styled/device';
import Button from 'components/common/Button/Button';
import {MainLoader} from '../common/loaders';

export const Wrapper = styled.div<{isLoading?: boolean}>`
  display: flex;
  flex-direction: column;
  max-width: 752px;
  transition: opacity 0.3s ease-in-out;

  @media (min-width: ${device.laptop}) {
    display: flex;
    flex-direction: row;
  }

  ${props =>
    props.isLoading &&
    css`
      opacity: 0.5;
    `}
`;

export const ContentCart = styled.div<{isInitialized?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  ${props =>
    props.isInitialized &&
    css`
      opacity: 1;

      @media (min-width: ${device.laptop}) {
        border-right: 1px solid #00000017;
        padding-right: 48px;
        margin-right: 48px;
      }
    `}
`;

export const Summary = styled.div`
  padding: 16px 24px 24px;
  background: ${props => props.theme.colors.branding.backgroundSecondary};
  border-radius: 16px;
  max-width: 327px;
  width: 100%;

  @media (min-width: ${device.laptop}) {
    padding: 16px 24px 24px;
    background: ${props => props.theme.colors.branding.backgroundSecondary};
    border-radius: 16px;
  }
`;

export const PaymentSummaryItemWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const PaymentSummaryItemTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    color: ${props => props.theme.colors.branding.fontPrimary};
  }
`;
export const PaymentSummaryItemDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 12px;

  & > p {
    font-weight: 400;
    font-size: 12px;
    margin: 0;
    color: ${props => props.theme.colors.branding.fontSecondary};
  }
  path {
    stroke: ${props => props.theme.colors.branding.fontSecondary};
  }

  circle {
    fill: ${props => props.theme.colors.branding.fontSecondary};
  }
`;

export const TotalPayment = styled.div<{isEmpty: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -8px;
  padding-bottom: 16px;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: ${props => props.theme.colors.branding.fontPrimary};

  ${props =>
    props.isEmpty &&
    css`
      border: none;
    `}

  &:first-child {
    font-weight: 600;
  }

  &:last-child {
    font-weight: 700;
    text-align: right;
  }
`;

export const ConfirmButton = styled(Button)`
  width: 100%;
  margin: 24px auto;

  @media (min-width: ${device.laptop}) {
    max-width: 295px;
    margin: 48px auto;
  }

  .buttonLabel {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Container = styled.div<{isInitialized?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  @media (min-width: ${device.laptop}) {
    margin-top: 0;
  }

  ${props =>
    props.isInitialized &&
    css`
      opacity: 1;
    `}
`;

export const StyledLoader = styled(MainLoader)`
  margin: 100px auto 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;
