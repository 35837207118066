import styled from 'styled-components';
import {device} from '@guestapp/ui/src/utils/device';

export const ShoppingCartIconContainer = styled.button`
  all: unset;
  position: relative;
  cursor: pointer;
  transition: opacity 0.1s;

  &:hover {
    opacity: 0.8;
  }

  @media (min-width: ${device.tablet}) {
    display: none;
  }

  path {
    fill: ${props => props.theme.colors.navigationMenu.menuItemsText};
  }
`;

export const PaymentsBage = styled.div<{color: string}>`
  position: absolute;
  width: 13px;
  height: 13px;
  background-color: ${({color}) => color};
  color: rgb(255, 255, 255);
  font-size: ${props => props.theme.fonts.sizes.smallX};
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 50px;
  padding-top: 1px;
  top: 2px;
  right: -2px;
  font-weight: bold;
`;
