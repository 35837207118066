import {SHOWED_GUEST_GROUP_MODAL} from 'utils/constants';
import {useSummary} from 'context/summary';
import {useModalOncePerSession} from 'hooks';
import {useReservationSettingsModal} from 'components/ReservationSettingsModal';
import CheckInCompleteModal from 'components/CheckInCompleteModal';

function HomeViewModals() {
  const {reservationId} = useSummary();

  const {shouldOpenModal: openWhoIsComingModalPerSession} = useModalOncePerSession({
    id: reservationId,
    storageKey: SHOWED_GUEST_GROUP_MODAL,
  });

  const {Modal: WhoIsComingModal} = useReservationSettingsModal({
    openModalPerSession: openWhoIsComingModalPerSession,
    showFirstTime: true,
  });

  return (
    <>
      {WhoIsComingModal}
      <CheckInCompleteModal />
    </>
  );
}

export {HomeViewModals};
