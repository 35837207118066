import {CreateGuestWithMinDataPayload, GuestApi} from '../api';
import {CreateGuestPayload, GetGuestSchemaPayload, GuestSchemaResponse} from './types';
import {CustomFormService, PAYLOAD_FIELDS_NAMES, ValidatorService} from './index';
import {getHasSomeCustomFields, ImagesFields} from '../utils';
import {FetcherSettings} from '../api/apiClient';

type CreateGuestParams = {
  data: CreateGuestPayload;
  customFormId?: string;
};

class GuestService {
  private readonly guestApi: GuestApi;
  private readonly customFormService: CustomFormService;
  private readonly validatorService: ValidatorService;
  imagesFields = ImagesFields;

  constructor() {
    this.guestApi = new GuestApi();
    this.customFormService = new CustomFormService();
    this.validatorService = new ValidatorService();
  }

  getFileName(name: keyof CreateGuestPayload, payload: CreateGuestPayload): string {
    return `${name}-${PAYLOAD_FIELDS_NAMES[name]}.${(payload[name] as Blob)?.['type']}`;
  }

  transformPayloadToFormData(payload: CreateGuestPayload): FormData {
    const formData = new FormData();
    for (const name in payload) {
      const fieldName = name as keyof CreateGuestPayload;
      const field = payload?.[fieldName];
      const addEmptySignature = fieldName === PAYLOAD_FIELDS_NAMES.signature && !field;
      if (field) {
        const value = field.toString() || '';
        if (this.imagesFields.includes(fieldName)) {
          formData.append(fieldName, field as Blob, this.getFileName(fieldName, payload));
        } else {
          formData.set(fieldName, value);
        }
      }
      if (addEmptySignature) {
        formData.set(fieldName, '');
      }
    }

    return formData;
  }

  buildGuestPayload(data: CreateGuestPayload): CreateGuestPayload | null {
    if (!data || !data?.[PAYLOAD_FIELDS_NAMES.reservation_id]) {
      throw new Error('Reservation id not found');
    }
    return data;
  }

  async getOne(id: string) {
    return await this.guestApi.getOne(id);
  }

  async createOrUpdate(
    {data: {id, ...data}, customFormId}: CreateGuestParams,
    resolverSettings?: FetcherSettings,
  ) {
    const hasCustomFields = getHasSomeCustomFields(data) && customFormId;
    const payload = await this.buildGuestPayload(data);
    if (!payload) throw new Error('The payload or reservation id is missing');
    const payloadFormData = this.transformPayloadToFormData(payload);

    const guestResult = id
      ? await this.guestApi.patch(id, payloadFormData, resolverSettings)
      : await this.guestApi.create(payloadFormData, resolverSettings);

    if (!hasCustomFields) {
      return guestResult;
    }
    const guestId = guestResult?.id;

    if (customFormId && guestId) {
      await this.customFormService.saveToFields(
        customFormId,
        guestId,
        data,
        resolverSettings,
      );
    }

    return guestResult;
  }

  createWithMinData(
    payload: CreateGuestWithMinDataPayload,
    resolverSettings?: FetcherSettings,
  ) {
    return this.guestApi.createMin(payload, resolverSettings);
  }

  async delete(guestId: string, resolverSettings?: FetcherSettings) {
    if (!guestId) throw new Error('The guest id argument is not found');
    return await this.guestApi.delete(guestId, resolverSettings);
  }

  async getSchema(
    payload: GetGuestSchemaPayload,
    resolverSettings?: FetcherSettings,
  ): Promise<GuestSchemaResponse> {
    if (!payload?.reservation_id)
      throw new Error('The reservation id argument is not found');

    return await this.guestApi.getSchema(payload, resolverSettings);
  }
}

export {GuestService};
