import React, {Fragment} from 'react';
import {Wrapper, ContainerChildren, NavLinkTab, TabItem, TabsContainer} from './styled';

type PathType = string | {pathname: string; search?: string; hash?: string};

export enum TAB_TYPES {
  button = 'button',
  link = 'link',
}

export type LinkTabsItem = {
  path: PathType;
  end?: boolean;
  onClick?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pathName?: PathType,
  ) => void;
  type?: TAB_TYPES;
};

export type ButtonTabsItem = {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  type?: TAB_TYPES;
};

export type TabsItem = {
  id: number | string;
  label: string;
  selected?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
} & (ButtonTabsItem | LinkTabsItem);

const isLinkType = (tab: ButtonTabsItem | LinkTabsItem): tab is LinkTabsItem => {
  return tab.type === TAB_TYPES.link;
};

export type TabsProps = {
  items: TabsItem[];
  isMulti?: boolean;
};

function Tabs({items, isMulti}: TabsProps) {
  const [selectedTabs, setStateTabs] = React.useState<TabsItem[]>(items);

  const selectTab = React.useCallback(
    (tab: TabsItem) => {
      if (isMulti) {
        setStateTabs(prevState => {
          const isTabExisted = prevState.find(prevTab => prevTab.id === tab.id);
          if (isTabExisted) {
            return prevState.filter(prevTab => prevTab.id !== tab.id);
          } else {
            return [...prevState, tab];
          }
        });
      } else {
        setStateTabs([tab]);
      }
    },
    [isMulti],
  );

  return (
    <Wrapper className="tabs">
      <TabsContainer className="tabs__list">
        {items.map(tab => {
          const {id, disabled, label, selected} = tab;
          if (isLinkType(tab)) {
            return (
              <NavLinkTab
                relative="route"
                key={id}
                end={tab.end}
                to={tab.path}
                onClick={e => {
                  if (disabled) return e.preventDefault();
                  tab.onClick?.(e, tab.path);
                }}
                className="tabs__link-item"
              >
                {label}
              </NavLinkTab>
            );
          } else {
            return (
              <TabItem
                key={id}
                selected={selected}
                disabled={disabled}
                onClick={e => {
                  if (disabled) return e.preventDefault();
                  tab.onClick(e);
                  selectTab(tab);
                }}
                className="tabs__item"
              >
                {label}
              </TabItem>
            );
          }
        })}
      </TabsContainer>

      <ContainerChildren className="tabs__content">
        {selectedTabs.map(({children, selected}, key) => {
          return <Fragment key={key}>{selected ? children : null}</Fragment>;
        })}
      </ContainerChildren>
    </Wrapper>
  );
}

export {Tabs};
