import React from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../Modal';
import Button from '../../Button';
import {ButtonContinue, ButtonWrapper, Subtitle} from './styled';

export type WarningCloseModalProps = {
  visible: boolean;
  onClose: () => void;
  onContinue: () => void;
};

const WarningCloseModal = ({visible, onClose, onContinue}: WarningCloseModalProps) => {
  const {t} = useTranslation();

  return (
    <Modal
      zIndex={1200}
      closeOnDocumentClick
      open={visible}
      onClose={onContinue}
      className="warning-close-modal"
    >
      <h1 className="warning-close-modal__title">{t('discard_changes_question')}</h1>
      <Subtitle className="warning-close-modal__subtitle">
        {t('discard_changes_question_subtitle')}
      </Subtitle>
      <ButtonWrapper className="warning-close-modal__btn-wrapper">
        <ButtonContinue onClick={onClose} label={t('discard')} />
        <Button onClick={onContinue} label={t('cancel')} link />
      </ButtonWrapper>
    </Modal>
  );
};

export {WarningCloseModal};
