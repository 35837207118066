import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MobileActionPanel = styled.div`
  display: flex;
  gap: 16px;
  background: ${props => props.theme.colors.branding.boxesModals};
  box-shadow: 0 2px 10px rgba(147, 150, 174, 0.25);
  padding: 24px;
  align-items: center;
  & > * {
    flex-grow: 1;
  }
`;
