import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import ChekinProSettings from 'ChekinProSettings';
import {useGuestGroup, useModalControls, useReservationSettings} from 'hooks';
import ModalSheet from 'components/common/ModalSheet';
import ShareRegistrationLink from 'components/ModalSheets/ShareRegistrationLink';
import {GuestItem} from 'components/GuestsPanel/GuestsPanel';
import {AllianzInsurancesInCarButton} from './AllianzInsurancesInCarButton';
import {MainButton} from 'styled/common';
import {
  StyledIcon,
  Title,
  SubTitle,
  ButtonsWrapper,
  RegisterBottom,
  ShareRegistrationLinkButton,
  GuestPanelContainer,
} from '../styled';

type GuestListsProps = {
  isAllianzActive: boolean;
  handleRegisterNewGuest?: () => void;
  nextPage: string;
};
function GuestLists({
  isAllianzActive,
  handleRegisterNewGuest,
  nextPage,
}: GuestListsProps) {
  const {guestGroup} = useGuestGroup();
  const hasNoGuests = guestGroup?.added_number_of_guests === 0;
  const theme = useTheme();
  const {t} = useTranslation();
  const {checkCanShowRegisterButtton} = useReservationSettings();
  const isRegisterGuestButtonVisible = checkCanShowRegisterButtton();
  const members = guestGroup?.members;
  const {
    isOpen: isOpenShareRegistrationLink,
    closeModal: closeShareRegistrationLink,
    openModal: openShareRegistrationLink,
  } = useModalControls();

  return (
    <>
      {hasNoGuests ? (
        <>
          <StyledIcon
            name={'iconRegister'}
            color={theme.colors.branding.primary}
            custom
            size={200}
          />
          <Title className="guest-register-view__no-guests-title">
            {t('no_registered_guest')}
          </Title>
          <SubTitle className="guest-register-view__no-guests-subtitle">
            {t('click_register_guest')}
          </SubTitle>
          <ButtonsWrapper>
            <RegisterBottom to={nextPage}>{t('register_guest')}</RegisterBottom>
            {ChekinProSettings.canShareRegistrationLink && (
              <ShareRegistrationLinkButton onClick={openShareRegistrationLink}>
                {t('share_registration_link')}
              </ShareRegistrationLinkButton>
            )}
          </ButtonsWrapper>
        </>
      ) : (
        <>
          <GuestPanelContainer className="guest-register-view__guest-panel">
            {members?.map((member, index) => {
              return <GuestItem key={index} {...member} />;
            })}
            {isAllianzActive && <AllianzInsurancesInCarButton />}
          </GuestPanelContainer>
          {isRegisterGuestButtonVisible && (
            <MainButton
              to={nextPage}
              onClick={handleRegisterNewGuest}
              label={t('register_another_guest')}
              className="guest-register-view__register-btn"
            />
          )}
          {ChekinProSettings.canShareRegistrationLink && (
            <ShareRegistrationLinkButton
              onClick={openShareRegistrationLink}
              className="guest-register-view__share-btn"
            >
              {t('share_registration_link')}
            </ShareRegistrationLinkButton>
          )}
        </>
      )}
      <ModalSheet
        isOpenModalSheet={isOpenShareRegistrationLink}
        handleCloseModal={closeShareRegistrationLink}
        withCloseButton
        maxWidth={'375px'}
      >
        <ShareRegistrationLink />
      </ModalSheet>
    </>
  );
}

export {GuestLists};
