import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useScreenResize} from 'hooks';
import {device} from '@guestapp/ui/src/utils/device';
import arrowLeft from '../../assets/icons/arrow-left.svg';
import Page from 'components/Page';
import Accordion from 'components/Accordion';
import Questions from './Questions';
import {BackButton, Content} from './styled';

function FAQView() {
  const {t} = useTranslation();
  const {isMatch: isTablet} = useScreenResize(device.laptopM);
  const {isMatch: isMobile} = useScreenResize(device.tabletSubHeader);
  const navigate = useNavigate();
  return (
    <Page
      isSubHeaderVisible={!isTablet || isMobile}
      isBackButtonDisabled={isTablet}
      navBarTitle={t('frequently_asked_questions')}
      className="fag-page"
      mainHeader={!isMobile}
    >
      <BackButton
        label={t('back')}
        customIcon={<img width={19} height={17} src={arrowLeft} alt="Arrow left" />}
        iconProps={{position: 'left'}}
        onClick={() => {
          navigate(-1);
        }}
        width="auto"
        className="fag-page__back-btn"
        link
      />
      <Content className="fag-page__content">
        <Accordion items={Questions()} allowZeroExpanded allowMultipleExpanded />
      </Content>
    </Page>
  );
}

export {FAQView};
