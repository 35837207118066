import React from 'react';
import {useTheme} from 'styled-components';
import {AcceptIconImg, CheckedRoundCheckbox, Box, CheckboxWrapper, Label} from './styled';

export type CheckboxProps = {
  onChange?: (name?: string) => void;
  label?: string | React.ReactNode | JSX.Element;
  disabled?: boolean;
  checked?: boolean;
  round?: boolean;
  className?: string;
  name?: string;
  content?: string | React.ReactNode | JSX.Element;
  error?: string;
};

const defaultProps: CheckboxProps = {
  onChange: () => {},
  label: '',
  disabled: false,
  checked: false,
  round: false,
  className: undefined,
  name: '',
  error: '',
};

export function CustomCheckbox({
  onChange,
  label,
  disabled,
  checked,
  className,
  name,
  round,
  content,
}: CheckboxProps) {
  const onClickHandler = disabled ? undefined : onChange;
  const theme = useTheme();

  const handleCheckboxClick = (_: React.MouseEvent<HTMLDivElement>) => {
    if (onClickHandler) {
      onClickHandler(name);
    }
  };

  return (
    <CheckboxWrapper
      onClick={handleCheckboxClick}
      disabled={disabled}
      className={className}
      role="checkbox"
      aria-label={name}
    >
      {round ? (
        <Box disabled={disabled} checked={checked} round={round}>
          {checked && <CheckedRoundCheckbox data-testid={`${name}-checkmark`} />}
        </Box>
      ) : (
        <Box disabled={disabled} checked={checked}>
          {checked && (
            <AcceptIconImg
              name={'checkFill'}
              size={24}
              color={
                theme?.hasTemplate
                  ? theme?.colors?.branding?.primary
                  : theme?.colors?.buttons?.mainButtonBackground
              }
              // alt="Check mark"
              // data-testid={`${name}-checkmark`}
            />
          )}
        </Box>
      )}
      <div>
        {label && <Label className="checkbox_label">{label}</Label>}
        {content}
      </div>
    </CheckboxWrapper>
  );
}

CustomCheckbox.defaultProps = defaultProps;
// export {CustomCheckbox};
