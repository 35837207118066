import {Trans} from 'react-i18next';
import {OFFER_CATEGORIES} from '@guestapp/sdk';
import {valueof} from 'utils/types';

type Props = {
  category: valueof<OFFER_CATEGORIES>;
};
export const CategoryTranslation = ({category}: Props) => {
  const key = `offer_categories.${category.toString().toLocaleLowerCase()}`;

  return <Trans i18nKey={key} />;
};
