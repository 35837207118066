import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import {
  useScreenResize,
  usePath,
  usePaymentSettings,
  useBookDeal,
  useModalControls,
  useErrorModal,
  useIsMounted,
  useActions,
} from 'hooks';
import {OFFER_CONFIRMATION_TYPES} from '@guestapp/sdk';
import {device} from '@guestapp/ui/src/utils/device';
import {PATHS} from 'Routes';
import {BACK_TO_OFFER_LIST} from 'components/upselling/usePathToOfferView';
import {formatPrice, getPrice} from 'components/upselling/price';
import {Tag} from 'components/common/Tag';
import {CategoryTranslation} from 'components/upselling/CategoryTranslation';
import {AddToCartForm} from 'components/upselling/AddToCartForm/AddToCartForm';
import {CreateDealCompleteModal} from 'components/upselling/CreateDealCompleteModal';
import {EnterContactEmailModal} from 'components/upselling/EnterContactEmailModal';
import {AddToCartButton} from 'components/upselling/AddToCartButton';
import {CollapsableBox} from 'components/common/CollapsableBox';

import {
  StyledPage,
  Content,
  ContentImg,
  ContentTitle,
  MobileContentTitle,
  Main,
  Description,
  AddToCartFormWrapper,
  Details,
  MobileActionPanel,
  MobilePrice,
  ExperienceButton,
  TitleText,
  Header,
} from './styled';

const descriptionLimit = 192;

function OfferView() {
  const {id} = useParams();
  const [searchParams] = useSearchParams();
  const {t} = useTranslation();
  const {getFullPath} = usePath();
  const {currencySymbol} = usePaymentSettings();
  const {isMatch: isMobileMode} = useScreenResize(device.tabletSubHeader);
  const navigate = useNavigate();
  const {setBookExperienceQuantity} = useActions();
  const {ErrorModal, displayError} = useErrorModal();
  const isMounted = useIsMounted();

  const {
    isOpen: isCreateDealCompleteModalOpen,
    openModal: openCreateDealCompleteModal,
    closeModal: closeCreateDealCompleteModal,
  } = useModalControls();
  const {
    isOpen: isEnterContactEmailModalOpen,
    openModal: openEnterContactEmailModal,
    closeModal: closeEnterContactEmailModal,
  } = useModalControls();
  const {
    bookExperienceEmail,
    createDeal,
    getEmail,
    navigateAfterSuccess,
    navigateToBookExperience,
    offer,
    quantity,
    isLoading,
  } = useBookDeal({
    offerId: id,
    onSuccess: openCreateDealCompleteModal,
    onError: errors => {
      if (!isMounted.current) return;
      displayError(errors[0]);
    },
  });

  const quantityChangeHandler = (value: number) => {
    setBookExperienceQuantity({offerId: offer?.id || '', quantity: value});
  };

  const createHandler = () => {
    const email = getEmail();
    if (email || offer?.confirmation_type === OFFER_CONFIRMATION_TYPES.auto) {
      createDeal(email);
    } else {
      openEnterContactEmailModal();
    }
  };

  const closeCompleteModalHandler = () => {
    navigateAfterSuccess();
    closeCreateDealCompleteModal();
  };

  const confirmContactEmailHandler = (email: string) => {
    closeEnterContactEmailModal();
    createDeal(email);
  };

  return (
    <StyledPage
      isLoading={isLoading}
      isSubHeaderVisible
      onBackButtonClick={() =>
        searchParams.has(BACK_TO_OFFER_LIST)
          ? navigate(getFullPath(PATHS.recommendations.main))
          : navigate(getFullPath(PATHS.home))
      }
      title={t('offer_view.subtitle')}
      navBarTitle={t('offer_view.nav_bar_title')}
      bottomStickyPanel={
        isMobileMode &&
        offer && (
          <MobileActionPanel>
            <MobilePrice>
              {formatPrice(currencySymbol)({
                priceType: offer.price_type,
                items: offer.items,
              })}
            </MobilePrice>
            <ExperienceButton
              label={t('offer_view.book_experience_button')}
              onClick={() => navigateToBookExperience()}
            />
          </MobileActionPanel>
        )
      }
    >
      {offer && (
        <Content>
          <Header>
            {!isMobileMode && (
              <ContentTitle>
                <TitleText>{offer.title}</TitleText>
                <span>
                  {formatPrice(currencySymbol)({
                    priceType: offer.price_type,
                    items: offer.items,
                  })}
                </span>
              </ContentTitle>
            )}
            {offer.offer_image && <ContentImg src={offer.offer_image?.image} />}
          </Header>
          {isMobileMode && <MobileContentTitle>{offer.title}</MobileContentTitle>}
          <Main>
            <Details>
              <CollapsableBox heightLimit={descriptionLimit}>
                <Description>{offer.description}</Description>
              </CollapsableBox>
              <Tag>
                <CategoryTranslation category={offer.category} />
              </Tag>
            </Details>
            {!isMobileMode && (
              <AddToCartFormWrapper>
                <AddToCartForm
                  unitType={offer.items[0].unit_type}
                  price={offer ? getPrice(offer) : 0}
                  value={quantity || 0}
                  confirmationType={offer.confirmation_type}
                  onChange={quantityChangeHandler}
                />
                <AddToCartButton
                  disabled={!Boolean(quantity)}
                  onClick={createHandler}
                  type={offer.confirmation_type}
                />
              </AddToCartFormWrapper>
            )}
          </Main>
        </Content>
      )}
      {offer && (
        <CreateDealCompleteModal
          isOpen={isCreateDealCompleteModalOpen}
          onClose={closeCompleteModalHandler}
          confirmationType={offer?.confirmation_type}
        />
      )}
      <EnterContactEmailModal
        bookExperienceEmail={bookExperienceEmail}
        isOpen={isEnterContactEmailModalOpen}
        onClose={closeEnterContactEmailModal}
        onConfirm={confirmContactEmailHandler}
      />
      <ErrorModal />
    </StyledPage>
  );
}

export {OfferView};
