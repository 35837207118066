import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IV_DOCUMENT_IMAGE_TYPES} from '../../../utils/constants';
import {IMAGE_SERVICE_ORIGIN} from '@guestapp/sdk';

type ImageDetails = {
  image: string | null;
  uploaded: boolean;
  id?: string;
  mrzDetected?: boolean;
  faceDetected?: boolean;
  service?: IMAGE_SERVICE_ORIGIN;
  extraID?: string;
};

type DocumentType = {
  label: string;
  value: string;
  two_sides: boolean;
  without_mrz: boolean;
};

export type IdentityVerificationSchema = {
  setupFormDate: {
    documentType: DocumentType | null;
    nationality: string;
  };
  imagesDetails: {
    [key in IV_DOCUMENT_IMAGE_TYPES]: ImageDetails;
  };
  ocrResultData: {
    ocrPassed: boolean;
  };
  biomatchResultData: {
    biomatchPassed: boolean;
    distance: number | null;
  };
};

const initialState: IdentityVerificationSchema = {
  setupFormDate: {
    documentType: null,
    nationality: '',
  },
  imagesDetails: {
    [IV_DOCUMENT_IMAGE_TYPES.front]: {
      id: '',
      image: null,
      uploaded: false,
      mrzDetected: false,
      faceDetected: false,
    },
    [IV_DOCUMENT_IMAGE_TYPES.back]: {
      id: '',
      image: null,
      uploaded: false,
      mrzDetected: false,
      faceDetected: false,
      service: IMAGE_SERVICE_ORIGIN.ocr,
    },
    [IV_DOCUMENT_IMAGE_TYPES.selfie]: {
      id: '',
      image: null,
      uploaded: false,
      faceDetected: false,
      service: IMAGE_SERVICE_ORIGIN.biomatch,
    },
  },
  ocrResultData: {
    ocrPassed: false,
  },
  biomatchResultData: {
    biomatchPassed: false,
    distance: 0,
  },
};

const IdentityVerificationSlice = createSlice({
  name: 'IdentityVerificationSlice',
  initialState,
  reducers: {
    setDocumentType(state, action: PayloadAction<DocumentType>) {
      state.setupFormDate.documentType = action.payload;
    },
    setNationality(state, action: PayloadAction<string>) {
      state.setupFormDate.nationality = action.payload;
    },
    setOCRDocumentPassed(state, action: PayloadAction<boolean>) {
      state.ocrResultData.ocrPassed = action.payload;
    },
    setBiomatchPassed(state, action: PayloadAction<boolean>) {
      state.biomatchResultData.biomatchPassed = action.payload;
    },
    setDistanceBiomatchComparing(state, action: PayloadAction<number>) {
      state.biomatchResultData.distance = action.payload;
    },
    setImageDetails(
      state,
      action: PayloadAction<
        Partial<Omit<ImageDetails, 'uploaded'>> & {type: IV_DOCUMENT_IMAGE_TYPES} & {
          mrzDetected?: boolean;
        }
      >,
    ) {
      const {type, ...imageDetails} = action.payload;
      state.imagesDetails[type] = {
        ...state.imagesDetails[type],
        ...imageDetails,
        uploaded: Boolean(imageDetails.image),
      };
    },
    resetIVConfigState(state) {
      state.biomatchResultData = initialState.biomatchResultData;
      state.imagesDetails = initialState.imagesDetails;
      state.ocrResultData = initialState.ocrResultData;
    },
    resetIVSetupFormDate(state) {
      state.setupFormDate = initialState.setupFormDate;
    },
    resetAllIVState() {
      return initialState;
    },
  },
});

export {IdentityVerificationSlice};
export const identityVerificationActions = IdentityVerificationSlice.actions;
export const identityVerificationReducer = IdentityVerificationSlice.reducer;
