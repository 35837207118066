import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';
import {PATHS} from 'Routes';
import {OPTION_VALUES} from './utils';
import {useFetchHousingLocation, useFetchHousing, usePath} from 'hooks';
import {useReservation} from 'context/reservation';
import {useChekinSDK} from 'context/ChekinSDK';
import PageUniversalLink from 'components/PageUniversalLink';
import {RadioOptionType} from 'components/common/MultiRadio';
import {
  ContentSelectOption,
  SubTitleWrapper,
  TitleWrapper,
  Wrapper,
  MultiRadioStyled,
  ContentButton,
  StyledButton,
} from './styled';

function SearchReservation() {
  const {t} = useTranslation();

  const navigate = useNavigate();
  const {state} = useLocation();
  const {getFullPath} = usePath();
  const {objectId: housingId} = useChekinSDK();
  const {setCountryCode, setHousingIdPropertyLink} = useReservation();
  const [searchMethod, setSearchMethod] = useState<RadioOptionType<string> | undefined>(
    undefined,
  );

  const {data: housing, isSuccess: isSuccessLoadedHousing} = useFetchHousing(housingId, {
    refetchOnWindowFocus: false,
  });

  const {data: locationCountry} = useFetchHousingLocation({
    housingLocation: housing?.location,
  });

  useEffect(
    function setCountryCodeOnContext() {
      if (locationCountry?.country?.code) {
        setCountryCode?.(locationCountry?.country?.code);
      }
    },
    [locationCountry, setCountryCode],
  );
  useEffect(
    function setHousingIdPropertyLinkFromFetch() {
      if (housing?.id) {
        setHousingIdPropertyLink?.(housing?.id);
      }
    },
    [housing, setHousingIdPropertyLink],
  );

  const [prevSelection, setPrevSelection] = useState('');

  const RadioOptions = [
    {label: `${t('booking_reference')}`, value: OPTION_VALUES.booking_reference},
    {
      label: `${t('chekin_in_date_&_email')}`,
      value: OPTION_VALUES.check_in_date_and_email,
    },
  ];

  const handleSearchMethodChange = (selectedOption: RadioOptionType<string>) => {
    setSearchMethod(selectedOption);
  };

  const handleNavigationView = () => {
    const path =
      searchMethod?.value === OPTION_VALUES.booking_reference
        ? PATHS.searchReservation.bookingCode
        : PATHS.searchReservation.checkingDate;
    return navigate(getFullPath(path), {
      replace: true,
      state: {housingId: housing?.id, housingCountry: locationCountry?.country.code},
    });
  };

  useEffect(
    function preloadOption() {
      setPrevSelection(state);
    },
    [state],
  );

  return (
    <>
      <PageUniversalLink className="search-reservation-view">
        <Wrapper>
          <TitleWrapper>{t('find_your_booking')}</TitleWrapper>
          <SubTitleWrapper>
            {t('select_one_of_the_search_methods_to_locate_your_booking')}
          </SubTitleWrapper>

          <ContentSelectOption>
            <MultiRadioStyled
              className="multi-radio__option"
              options={RadioOptions}
              onChange={handleSearchMethodChange}
              defaultValue={prevSelection}
              value={searchMethod}
              isCustomBox
            />
          </ContentSelectOption>

          <ContentButton>
            <StyledButton
              label={t('continue')}
              onClick={handleNavigationView}
              disabled={!searchMethod && isSuccessLoadedHousing}
            />
          </ContentButton>
        </Wrapper>
      </PageUniversalLink>
    </>
  );
}

export {SearchReservation};
