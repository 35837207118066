import {device} from '@guestapp/ui/src/utils/device';
import styled from 'styled-components';

export const Wrapper = styled.div<{$hasTemplate: boolean}>`
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  background: ${props =>
    !props.$hasTemplate ? '#152946' : props.theme.colors.branding.fontPrimary};
  padding: 8px 0;
  @media (min-width: ${device.laptopML}) {
    padding: 0;
    height: 100%;
    border-radius: 12px;
    box-shadow: rgba(147, 150, 174, 0.25) 0px 2px 10px;
    overflow: hidden;
  }
`;

export const Header = styled.div<{$hasTemplate: boolean}>`
  display: none;
  @media (min-width: ${device.laptopML}) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    height: 64px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.36px;
    color: ${props => props.theme.colors.branding.boxesModals};
    background: ${props =>
      !props.$hasTemplate ? '#152946' : props.theme.colors.branding.fontPrimary};
    text-transform: uppercase;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 25px 1fr;
  -webkit-box-align: center;
  align-items: center;
  /* gap: 24px; */
  text-align: center;
  color: ${props => props.theme.colors.branding.boxesModals};

  @media (min-width: ${device.laptopML}) {
    height: calc(100% - 64px);
    color: ${props => props.theme.colors.branding.fontPrimary};
    background: ${props => props.theme.colors.branding.boxesModals};
    padding: 0 40px;
  }
`;

export const DateInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

export const DatesArrow = styled.div``;

export const Title = styled.div`
  font-size: ${props => props.theme.fonts.sizes.small};
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* margin-bottom: 3px; */
  @media (min-width: ${device.laptopML}) {
    font-size: 16px;
    text-transform: capitalize;
    /* margin-bottom: 12px;  */
  }
`;

export const MainDate = styled.div<{isNoHours: boolean}>`
  display: ${props => (props.isNoHours ? 'block' : 'none')};
  font-size: ${props => props.theme.fonts.sizes.largeL};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.36px;
  margin-bottom: 5px;
  @media (min-width: ${device.laptopML}) {
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    /* margin-bottom: 12px; */
  }
`;

export const FullDate = styled.div<{isNoHours: boolean}>`
  font-size: ${props =>
    props.isNoHours ? props.theme.fonts.sizes.medium : props.theme.fonts.sizes.smallX};
  font-style: normal;
  font-weight: ${props => (props.isNoHours ? 700 : 400)};
  line-height: ${props => (props.isNoHours ? '24px' : '16px')};

  @media (min-width: ${device.laptopML}) {
    font-size: ${props =>
      props.isNoHours ? props.theme.fonts.sizes.largeL : props.theme.fonts.sizes.medium};
  }
`;
