import {useQuery} from '@tanstack/react-query';
import {LockDetail} from '@guestapp/sdk';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {useChekinSDK} from 'context/ChekinSDK';
import {useReservation} from 'context/reservation';

type UseRemoteAccessProps = {
  enabled?: boolean;
};

function useFetchLocks({enabled = true}: UseRemoteAccessProps = {}) {
  const {data: reservation} = useReservation();
  const {getLocks} = useChekinSDK();
  const enabledFetching = Boolean(!!reservation?.id && enabled);

  const {data, isSuccess, isLoading, isFetching} = useQuery<LockDetail[], Error>(
    [QUERY_CACHE_KEYS.locks],
    () => getLocks(`reservation_id=${reservation?.id}`),
    {enabled: enabledFetching, refetchOnWindowFocus: false},
  );

  return {
    locks: data,
    areLocksSuccess: isSuccess,
    areLocksLoading: isLoading,
    areLocksFetching: isFetching,
  };
}

export {useFetchLocks};
