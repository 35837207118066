import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {PATHS} from 'Routes';
import {useSummary} from 'context/summary';
import {useBuildNavigationItems, useGuestGroup, usePath, usePaymentsCart} from 'hooks';
import {BookingPAymentRequiredText, CompletedGuestText, StyledButton} from './styled';

function CompletedGuestsStep() {
  const {allGuestsRegistered} = useGuestGroup();
  const {arePaymentsAvailable, areAllPaymentsCompleted} = useSummary();
  const {t} = useTranslation();
  const {paymentsCart} = usePaymentsCart({refetchOnMount: false});
  const navigate = useNavigate();
  const {getFullPath} = usePath();
  const {getPaymentPath} = useBuildNavigationItems();
  const goToPayments = React.useCallback(() => {
    if (arePaymentsAvailable && paymentsCart?.pay_now_payments) {
      navigate(getFullPath(getPaymentPath()));
      return;
    }
    navigate(getFullPath(PATHS.home));
  }, [
    arePaymentsAvailable,
    getFullPath,
    navigate,
    paymentsCart?.pay_now_payments,
    getPaymentPath,
  ]);
  const showPaymentsButton =
    allGuestsRegistered && arePaymentsAvailable && !areAllPaymentsCompleted;

  return (
    <>
      <CompletedGuestText className="chekin-next-step-modal__chekin-all-registered-tex">
        {t('all_guest_have_been_registered')}
      </CompletedGuestText>
      {arePaymentsAvailable && !areAllPaymentsCompleted && (
        <BookingPAymentRequiredText className="chekin-next-step-modal__chekin-booking-payment-required-text">
          {t('booking_payments_are_required_to_complete_complete_checkin')}
        </BookingPAymentRequiredText>
      )}

      {showPaymentsButton && (
        <StyledButton
          label={t('go_to_booking_payments')}
          primary
          onClick={goToPayments}
        />
      )}
    </>
  );
}

export {CompletedGuestsStep};
