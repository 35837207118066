import styled, {css} from 'styled-components';
import {device} from 'styled/device';
import {CustomCheckbox} from '@guestapp/ui';

export const Info = styled.div<{selected: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: column;

  background: ${props => props.theme.colors.branding.boxesModals};
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.07);
  border: 2px solid transparent;

  ${props =>
    props.selected &&
    css`
      border: 2px solid
        ${props.theme.hasTemplate
          ? props.theme.colors.branding.primary
          : props.theme.colors.buttons.mainButtonBackground};
    `};

  @media (min-width: ${device.mobileXL}) {
    max-width: 416px;
    margin: 0 auto 16px;
  }

  @media (min-width: ${device.tablet}) {
    margin: 0 auto 16px;
  }
`;

const getTitleStyles = () => css`
  font-weight: ${props => props.theme.fonts.fontWeight.bold};
  font-size: ${props => props.theme.fonts.sizes.medium};
  color: ${props => props.theme.colors.branding.fontPrimary};

  @media (max-width: ${device.mobileXL}) {
    font-weight: ${props => props.theme.fonts.fontWeight.bold};
    font-size: ${props => props.theme.fonts.sizes.medium};
  }
`;

export const InfoTitle = styled.div`
  margin-bottom: 16px;
  cursor: default;
  ${getTitleStyles()}
`;

export const StyledCheckbox = styled(CustomCheckbox)`
  margin-bottom: 16px;

  & .checkbox__label {
    ${getTitleStyles()}
  }
`;

export const Description = styled.div`
  font-weight: ${props => props.theme.fonts.fontWeight.regular};
  font-size: ${props => props.theme.fonts.sizes.medium};

  @media (max-width: ${device.mobileXL}) {
    display: none;
  }
`;
export const InfoDescription = styled.div`
  font-style: normal;
  font-size: ${props => props.theme.fonts.sizes.small};
  line-height: 24px;
  color: ${props => props.theme.colors.branding.fontPrimary};

  @media (max-width: ${device.mobileXL}) {
    font-style: normal;
    font-size: ${props => props.theme.fonts.sizes.small};
    line-height: 24px;
  }
`;

export const ButtonStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: end;
  font-style: normal;
  letter-spacing: -0.15px;
  color: ${props => props.theme.colors.buttons.textButton};
  font-weight: ${props => props.theme.fonts.fontWeight.mediumL};
  font-size: ${props => props.theme.fonts.sizes.small};
  line-height: 24px;
  margin-top: 24px;

  cursor: pointer;
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 0.8;
    transition: opacity 0.2s ease;
  }

  p {
    margin: 0;
  }

  @media (max-width: ${device.mobileXL}) {
    font-weight: ${props => props.theme.fonts.fontWeight.mediumL};
    font-size: ${props => props.theme.fonts.sizes.small};
    width: 65px;
    height: 24px;
    p {
      margin: 0;
    }
  }
`;
