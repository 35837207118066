import styled from 'styled-components';
import {Button, ComputedBox} from '@guestapp/ui';
import {IntermittentDot} from '../../common/loaders';

export const Wrapper = styled.div`
  display: flex;
  width: 327px;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
`;

export const MainBox = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;
`;

export const CounterText = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #777e91;
`;

export const Title = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #777e91;
`;

export const Content = styled(ComputedBox)`
  width: 100%;
  margin-top: 24px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
  }
`;

export const Hr = styled.div`
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 1px;
`;

export const Vr = styled.div`
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 1px;
  margin-right: 21px;
  margin-left: 16px;
`;

export const DeferredPaymentWrapper = styled.div`
  width: 100%;
`;

export const DeferredPaymentRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div:nth-child(2) {
    display: flex;
  }
`;

export const PaymentName = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const PaymentLabel = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #777e91;
`;

export const Price = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

export const StyledButton = styled(Button)`
  margin-top: 16px;
  font-size: 14px;
  font-weight: 500;
  margin-left: auto;
`;

export const Signal = styled(IntermittentDot)`
  position: absolute;
  top: -15px;
`;
