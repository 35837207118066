import {useQuery} from '@tanstack/react-query';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {useChekinSDK} from 'context/ChekinSDK';

type Props = {
  housingId?: string;
};
export const useFetchCheckIfOffersExists = ({housingId}: Props) => {
  const {fetchCheckIfOffersExists} = useChekinSDK();
  const result = useQuery(
    [QUERY_CACHE_KEYS.deals],
    () => fetchCheckIfOffersExists(`housing_id=${housingId}&is_active=true`),
    {
      enabled: Boolean(housingId),
      refetchOnWindowFocus: false,
    },
  );

  return result;
};
