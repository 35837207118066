import React from 'react';
import {isMobileOnly} from 'react-device-detect';
import {WebcamProps, WebcamRefTypes} from '../../Webcam';
import {
  IDENTITY_VERIFICATION_PHOTOS_MAX_SIZE_MB,
  IDENTITY_VERIFICATION_PHOTOS_QUALITY,
} from '../../../utils/constants';
import MobileWebcam, {MobileCameraProps} from '../../MobileWebcam';
import {WebcamStyled} from '../styled';
import {PlaceholderImage} from './styled';

const VisibleHelperImageTimeoutMS = 1250;

type TakePhotoProps = WebcamProps &
  MobileCameraProps & {
    mobileHelperText?: React.ReactNode | string;
    mobileHelperTitle?: React.ReactNode | string;
    placeholderImage?: string;
  };
const TakePhoto = React.forwardRef<WebcamRefTypes, TakePhotoProps>(
  (
    {placeholderImage, onScreenshot, mobileHelperText, mobileHelperTitle, ...props},
    ref,
  ) => {
    const [isPlaceholderImageVisible, setIsPlaceholderImageVisible] =
      React.useState(false);

    const onUserMedia = React.useCallback(() => {
      setIsPlaceholderImageVisible(true);
    }, []);

    const webcamProps = React.useMemo(
      () => ({
        ...props,
        minScreenshotW: 800,
        minScreenshotH: 600,
        screenshotQuality: IDENTITY_VERIFICATION_PHOTOS_QUALITY,
        screenshotMaxSize: IDENTITY_VERIFICATION_PHOTOS_MAX_SIZE_MB,
        onUserMedia,
      }),
      [onUserMedia, props],
    );

    React.useEffect(() => {
      if (!isPlaceholderImageVisible) return;
      const timeout = setTimeout(() => {
        setIsPlaceholderImageVisible(false);
      }, VisibleHelperImageTimeoutMS);

      return () => {
        clearTimeout(timeout);
      };
    }, [isPlaceholderImageVisible]);

    if (isMobileOnly) {
      return (
        <MobileWebcam
          ref={ref}
          onScreenshot={onScreenshot}
          text={mobileHelperText}
          title={mobileHelperTitle}
          placeholderImage={
            placeholderImage ? (
              <PlaceholderImage
                $visible={isPlaceholderImageVisible}
                src={placeholderImage}
                alt="Placeholder"
              />
            ) : null
          }
          {...webcamProps}
        />
      );
    }

    return (
      <WebcamStyled
        ref={ref}
        placeholderImage={
          placeholderImage ? (
            <PlaceholderImage
              $visible={isPlaceholderImageVisible}
              src={placeholderImage}
              alt="Placeholder"
            />
          ) : null
        }
        {...webcamProps}
      />
    );
  },
);

export {TakePhoto};
