import styled, {css} from 'styled-components';
import {device} from '../../styled/device';
import {getBackgroundColor} from 'styled/common';
import StatusModal from '../StatusModal';
import {Button, CustomIcon} from '@guestapp/ui';

export const Container = styled.div`
  display: flex;
  max-width: 375px;
  width: 100%;
  padding: 16px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 6px;
  border: 1px solid var(--texts-borders-grey-border, rgba(0, 0, 0, 0.1));
  background: ${props => props.theme.colors.branding.boxesModals};
`;

export const Header = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.branding.fontPrimary};
  font-weight: 400;

  & > img:nth-child(2) {
    margin: 0 10px 0 8px;
  }
`;

export const Form = styled.form`
  width: 100%;
`;

export const Hr = styled.div`
  height: 1px;
  width: 100%;
  background: #0000001a;
`;

export const TotalBox = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 16px;
  background: ${props => props.theme.colors.branding.backgroundSecondary};
  color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.fontSecondary
      : props.theme.colors.$p10};

  & > span:first-child {
    font-weight: 600;
  }

  & > span:last-child {
    font-weight: 700;
  }
`;

export const Price = styled.span<{isLoaded?: boolean}>`
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;

  ${props =>
    props.isLoaded &&
    css`
      opacity: 1;
    `}
`;

type StyledInputProps = {
  disabled: boolean;
  isEmpty: boolean;
  isFocused: boolean;
};

export const StripeFieldContainer = styled.div<StyledInputProps>`
  display: flex;
  align-items: center;
  width: 100%;
  outline: none;
  margin: 0;
  font-size: 16px;
  transition: background-color 0.25s ease-in-out;
  padding: 13px 16px 13px 9px;
  box-sizing: border-box;
  border-radius: 7px;
  border: none;
  text-overflow: ellipsis;
  height: 48px;
  font-family: inherit;
  font-weight: 400;
  color: ${props => props.theme.colors.branding.fontPrimary};
  position: relative;
  cursor: text;
  background-color: ${({isEmpty, isFocused, theme}) =>
    getBackgroundColor(isEmpty, isFocused, theme)};

  & > label {
    z-index: 1;
  }

  & > input {
    padding: 13px 16px 13px 9px;
  }

  & .StripeElement {
    flex: 1;
  }

  ${props =>
    props.disabled &&
    css`
      background-color: #f4f6f9;
    `}

  &::placeholder {
    font-weight: 400;
    color: red;
    opacity: 1;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 1000px white inset !important;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }
`;

export const FieldsColumn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  & .input__input {
    font-weight: 500;
  }
`;

export const FieldsRow = styled.div`
  display: flex;
  column-gap: 8px;
  position: relative;
`;

export const StatusIcon = styled.img<{error: boolean}>`
  position: absolute;
  top: 12px;
  right: 16px;
  z-index: 2;
  cursor: ${props => (props.error ? 'pointer' : 'default')};
  outline: none;
`;

export const StatusCustomIcon = styled(CustomIcon)<{error: boolean}>`
  position: absolute;
  top: 12px;
  right: 16px;
  z-index: 2;
  cursor: ${props => (props.error ? 'pointer' : 'default')};
  outline: none;

  path,
  stop {
    stop-color: ${({theme}) => theme.colors.branding.primary};
  }
`;

export const StyledStatusModal = styled(StatusModal)`
  &-content {
    padding: 48px 24px;
    height: 100vh;
    & .modal__title {
      margin: 24px auto 0;
    }

    & .modal__description {
      margin: 24px auto 0;
    }
  }

  @media (min-width: ${device.mobileXL}) {
    &-content {
      max-width: 375px;
      height: auto;
    }
  }
`;

export const OkButton = styled(Button)`
  max-width: 294px;
  margin-top: 48px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;
