import styled from 'styled-components';
import Button from '../../Button';

export const Subtitle = styled.p`
  font-size: ${props => props.theme.fonts.sizes.medium};
  text-align: center;
  max-width: 312px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  margin-top: 42px;
`;

export const ButtonContinue = styled(Button)`
  justify-content: center;
`;
