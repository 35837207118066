import styled from 'styled-components';
import {CustomIcon} from '@guestapp/ui';
import {device} from '../../styled/device';

export const Wrapper = styled.div`
  margin-top: 145px;
  padding: 0 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${device.mobileXL}) {
    margin-top: 200px;
  }
`;
export const NotFoundIcon = styled(CustomIcon)`
  width: 147px;
  margin-bottom: 54px;
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.36px;
  color: #152946;
  margin-bottom: 8px;
  @media (min-width: ${device.mobileXL}) {
    margin-bottom: 24px;
  }
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #152946;
  margin-bottom: 56px;
  @media (min-width: ${device.mobileXL}) {
    width: 590px;
    margin-bottom: 48px;
  }
`;

export const SecondText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #777e91;

  @media (min-width: ${device.mobileXL}) {
    width: 650px;
    margin-bottom: 48px;
  }
`;
