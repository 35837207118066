import React from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import {useParams} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import {SessionStorage} from '@guestapp/core';
import {SIGNATURES_QUALITY, STORAGE_KEYS} from 'utils/constants';
import {useSchema} from 'context/guest';
import {useReservation} from 'context/reservation';
import {useContracts, useGuest} from 'hooks';
import {useModalControls, useScreenResize, useTimeoutRef} from 'hooks/common';
import {useSignatureState} from './useSignatureState';
import {trimCanvas} from '../../utils/trimCanvas';
import {device} from 'styled/device';
import {fakeSignature} from './index';
import signIcon from 'assets/icons/sign-icon.svg';
import Modal from 'components/Modal';
import Button from 'components/common/Button';
import SignatureModal from './SignatureModal';
import SignatureCanvas from 'components/common/SignatureCanvas';
import {ModalSheet} from 'components/common/ModalSheet/ModalSheet';
import {ContractBox} from './ContractBox';
import {
  Title,
  Wrapper,
  SignButton,
  SubmitButton,
  ErrorMessage,
  DesktopSignArea,
  PleaseSignModal,
  WrapperWithButton,
  ContractTitleLine,
  DesktopSignatureImage,
  RepeatSignatureButton,
  DesktopSignAreaContainer,
} from './styled';

export type SignBlockProps = {
  onSubmit: (signature: string) => void;
  isSignModalOpen: boolean;
  setIsSignModalOpen: (value: boolean) => void;
  hasSignature?: boolean;
  automatedSignature?: boolean;
  isSignatureRequired?: boolean;
};

const SignBlock = ({
  onSubmit,
  isSignModalOpen,
  setIsSignModalOpen,
  hasSignature,
  automatedSignature,
  isSignatureRequired,
}: SignBlockProps) => {
  const {t} = useTranslation();
  const signatureRef = React.useRef<SignaturePad | null>(null);
  const {housing, reservationId} = useReservation();
  const {id: guestId} = useParams<{id: string}>();
  const {guest} = useGuest({id: guestId});
  const {isGuestSchemaLoading, isGuestSchemaLoaded} = useSchema();
  const isContractEnabled = housing?.is_contract_enabled;
  const {isMatch: isMobile} = useScreenResize(device.tablet);
  const timeoutRef = useTimeoutRef();
  const {signature, setSignature, resetSignature} = useSignatureState();
  const [isSignatureEditEnabled, setIsSignatureEditEnabled] = React.useState(false);
  const [isSignatureRequiredError, setIsSignatureRequiredError] = React.useState(false);
  const [isSuperHogTermsModalOpen, setIsSuperHogTermsModalOpen] = React.useState(false);

  const {contracts, isContractsError, isLoadingContracts} = useContracts();

  const {
    isOpen: isSignatureModal,
    openModal: openSignatureModal,
    closeModal: closeSignatureModal,
  } = useModalControls();

  const handleOpenSignatureModal = React.useCallback(() => {
    resetSignature();
    setIsSignatureEditEnabled(true);
    setIsSignatureRequiredError(false);
    openSignatureModal();
  }, [openSignatureModal, resetSignature]);

  React.useEffect(
    function preloadPersistedLocationSignature() {
      const storageSignature = SessionStorage.get<string>(
        STORAGE_KEYS.personalSignatureFormData,
      );
      const loadedSignature = guest?.id ? guest?.signature : storageSignature;
      if (!isSignatureModal && loadedSignature && reservationId && isGuestSchemaLoaded) {
        timeoutRef.current = setTimeout(() => {
          signatureRef.current?.fromDataURL(loadedSignature, {
            width: 319,
            height: 182,
          });

          setIsSignatureEditEnabled(true);
          setSignature(loadedSignature);
        }, 100); // improve
      }
    },
    [
      setSignature,
      reservationId,
      isGuestSchemaLoaded,
      guest?.id,
      guest?.signature,
      isSignatureModal,
      timeoutRef,
    ],
  );

  const handleSignatureEnable = React.useCallback(() => {
    setIsSignatureEditEnabled(true);
  }, []);

  const handleChange = () => {
    const signatureInstance = signatureRef.current;
    const canvas = signatureInstance?.canvas?.current;
    if (!canvas) return;
    const signature = trimCanvas(canvas)?.toDataURL(undefined, SIGNATURES_QUALITY);
    const isEmpty = signatureInstance?.isEmpty();

    if (signature && !isEmpty) {
      setSignature(signature);
    }
  };

  const handleClear = () => {
    signatureRef.current?.clear();
    resetSignature();
  };

  const handleSignModalClose = () => {
    setIsSignModalOpen(false);
    handleSignatureEnable();
  };

  const handleSuperHogTermsModalClose = () => {
    setIsSuperHogTermsModalOpen(false);
  };

  const handleSubmit = () => {
    const showSignModal = isSignatureEditEnabled && !signature && hasSignature;

    if (!showSignModal && !hasSignature && !automatedSignature) {
      onSubmit(fakeSignature);
      return;
    }

    if (!signature && hasSignature && !automatedSignature) {
      setIsSignatureRequiredError(!!isSignatureRequired);
      onSubmit(signature);
      return;
    }

    if (!showSignModal && (Boolean(signature) || automatedSignature)) {
      onSubmit(signature);
    }
  };

  return (
    <>
      <Modal open={isSignModalOpen} onClose={handleSignModalClose}>
        <PleaseSignModal>
          <img src={signIcon} alt="" width="84" height="84" />
          <div className="title">{t('you_have_to_sign')}</div>
          <div className="text">
            {isContractEnabled ? (
              <Trans i18nKey="please_sign_and_accept_terms_and_contract">
                Please <b>sign</b> inside the box. You can use your finger.
              </Trans>
            ) : (
              <Trans i18nKey="please_sign_and_accept_terms">
                Please <b>sign</b> inside the box. You can use your finger.
              </Trans>
            )}
          </div>
          <Button label={t('sign_now')} onClick={handleSignModalClose} />
        </PleaseSignModal>
      </Modal>
      <Modal open={isSuperHogTermsModalOpen} onClose={handleSuperHogTermsModalClose}>
        <PleaseSignModal>
          <img src={signIcon} alt="" width="84" height="84" />
          <div className="title">{t('accept_superhog_terms')}</div>
          <div className="text">
            <Trans i18nKey="accept_superhog_terms_description">
              In order to continue you must accept the{' '}
              <b>Superhog data verification agreement.</b>
            </Trans>
          </div>
          <Button label={t('ok')} onClick={handleSuperHogTermsModalClose} />
        </PleaseSignModal>
      </Modal>
      <WrapperWithButton>
        <Wrapper>
          {hasSignature && !automatedSignature && (
            <>
              <ContractTitleLine>
                <Title>{t('signature')}</Title>
              </ContractTitleLine>
              <DesktopSignAreaContainer
                disabled={isGuestSchemaLoading}
                onClick={handleOpenSignatureModal}
                empty={Boolean(signature) && !isSignatureModal}
                required={isSignatureRequiredError}
                className="sign-block__area"
              >
                {!signature && !isSignatureModal && (
                  <DesktopSignArea>
                    {!isGuestSchemaLoading && (
                      <SignButton>
                        {isMobile ? t('tap_here_to_sign') : t('click_here_to_sign')}
                      </SignButton>
                    )}
                  </DesktopSignArea>
                )}
                {signature && !isSignatureModal && (
                  <DesktopSignatureImage src={signature} />
                )}
              </DesktopSignAreaContainer>

              {signature && !isSignatureModal && (
                <RepeatSignatureButton
                  className="sign-block__repeat-btn"
                  onClick={handleOpenSignatureModal}
                >
                  {t('repeat_signature')}
                </RepeatSignatureButton>
              )}
            </>
          )}
          {isSignatureRequiredError && (
            <ErrorMessage className="sign-block__error">
              {t('the_signature_is_required')}
            </ErrorMessage>
          )}
          <ContractBox
            contracts={contracts}
            hasError={isContractsError}
            isLoading={isLoadingContracts}
            isAutomatedSignature={hasSignature && automatedSignature}
          />
          <SubmitButton>
            <Button
              primary
              data-testid="submit-btn"
              onClick={handleSubmit}
              label={t('sign_form')}
              type="button"
              disabled={false}
              className="submit-btn"
            />
          </SubmitButton>
        </Wrapper>
      </WrapperWithButton>
      <ModalSheet
        isOpenModalSheet={isSignatureModal}
        handleCloseModal={closeSignatureModal}
        maxWidth={'900px'}
        withCloseButton
        closeButtonType="click"
      >
        <SignatureModal
          hasSignature={Boolean(signature)}
          onClear={handleClear}
          onChange={handleChange}
          onClose={closeSignatureModal}
          signatureArea={
            <SignatureCanvas
              ref={signatureRef}
              onEnd={handleChange}
              onClear={handleClear}
              enabled={isSignatureEditEnabled}
              onEnable={handleSignatureEnable}
              hasSignature={Boolean(signature)}
            />
          }
        />
      </ModalSheet>
    </>
  );
};

export {SignBlock};
