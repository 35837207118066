import styled, {css} from 'styled-components';
import ReactWebcam from 'react-webcam';
import {device} from '../../styled/device';

export const ScanArea = styled.div<{rounded?: boolean}>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 32px);
  height: 200px;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  box-shadow: 0 0 0 9999em rgb(0 0 0 / 60%);
  border-radius: 15px;

  & div {
    position: absolute;
    width: 45px;
    height: 45px;
    border: 4px solid white;

    &:nth-child(1) {
      top: -2px;
      left: -2px;
      border-right: none;
      border-bottom: none;
      border-top-left-radius: 15px;
    }

    &:nth-child(2) {
      top: -2px;
      right: -2px;
      border-left: none;
      border-bottom: none;
      border-top-right-radius: 15px;
    }

    &:nth-child(3) {
      bottom: -2px;
      right: -2px;
      border-left: none;
      border-top: none;
      border-bottom-right-radius: 15px;
    }

    &:nth-child(4) {
      bottom: -2px;
      left: -2px;
      border-right: none;
      border-top: none;
      border-bottom-left-radius: 15px;
    }
  }

  ${props =>
    props.rounded &&
    css`
      width: 165px;
      height: 197px;
      border: 6px solid #fff;
      border-radius: 45%;
      box-shadow: 0 0 13px #fff, inset 0 4px 14px #fff, 0 0 0 9999em rgb(0 0 0 / 60%);

      & div {
        display: none;
      }
    `};

  @media (min-width: ${device.mobileXL}) {
    height: calc(100% - 32px);
  }
`;

type WrapperProps = {
  isMirroredInCSS: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  position: relative;
  margin: 0 auto;
  width: 332px;
  height: 222px;
  overflow: hidden;
  text-align: center;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  z-index: 1;

  ${props =>
    props.isMirroredInCSS &&
    css`
      &,
      & ${ScanArea} {
        transform: rotateY(180deg);
      }
    `};

  @media (max-width: ${device.mobileS}) {
    width: 300px;
  }

  @media (max-width: ${device.mobileM}) {
    width: 300px;
  }

  @media (max-width: ${device.mobileL}) {
    width: 304px;
  }

  @media (max-width: ${device.mobileM}) {
    width: 245px;
    height: 175px;
  }
`;

export const StyledWebcam = styled(ReactWebcam)`
  z-index: 1;
  background-color: #e2e2e2;
  height: 100%;
  width: 100%;
  object-fit: cover;
  //transform: rotateY(180deg);
`;

export const Canvas = styled.canvas`
  display: none;
`;
