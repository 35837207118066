import React from 'react';
import clsx from 'clsx';
import {useTheme} from 'styled-components';
import {CustomIcon} from '@guestapp/ui';
import {MarkBtn} from './styled';

type QuestionMarkButtonProps = {
  onClick?: () => void;
  className?: string;
};

const defaultProps: Partial<QuestionMarkButtonProps> = {
  className: undefined,
};

const QuestionMarkButton = React.forwardRef<HTMLButtonElement, QuestionMarkButtonProps>(
  ({onClick, className}, ref) => {
    const theme = useTheme();
    const iconColor = theme.hasTemplate
      ? theme.colors.branding.primary
      : theme.colors.buttons.mainButtonBackground;

    return (
      <MarkBtn
        ref={ref}
        type="button"
        onClick={onClick}
        tabIndex={-1}
        className={clsx(className, 'question-mark-button')}
      >
        <CustomIcon name="questionMarkIcon" color={iconColor} size={18} />
      </MarkBtn>
    );
  },
);
QuestionMarkButton.defaultProps = defaultProps;

export {QuestionMarkButton};
