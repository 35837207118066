import styled from 'styled-components';
import {device} from '../../../styled/device';

export const HelperText = styled.p`
  font-size: 14px;
  text-align: center;
  margin: 0 auto 32px;
  color: ${props =>
    props.theme.hasTemplate ? props.theme.colors.branding.fontPrimary : '#152946'};

  @media (min-width: ${device.tablet}) {
    font-size: 16px;
  }
`;
