import {UseMutationOptions, useMutation, useQueryClient} from '@tanstack/react-query';
import {CreateBulkDealPayload, Deal} from '@guestapp/sdk';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {useIsMounted} from './common';
import {useChekinSDK} from 'context/ChekinSDK';

type UseCreateBulkDealMutationtProps = UseMutationOptions<
  Deal[],
  Error[],
  CreateBulkDealPayload
>;
function useCreateBulkDealMutation({
  onError,
  onMutate,
  onSettled,
  onSuccess,
}: UseCreateBulkDealMutationtProps = {}) {
  const {createBulkDeal} = useChekinSDK();
  const queryClient = useQueryClient();
  const isMounted = useIsMounted();

  return useMutation<Deal[], Error[], CreateBulkDealPayload>(
    payload => createBulkDeal({...payload}),
    {
      onSuccess: (data, variables, context) => {
        onSuccess?.(data, variables, context);
        queryClient.refetchQueries([QUERY_CACHE_KEYS.deals]);
      },
      onError: (error, data, variables) => {
        if (!isMounted.current) {
          return;
        }

        onError?.(error, data, variables);
      },
      onMutate,
      onSettled,
    },
  );
}

export {useCreateBulkDealMutation};
