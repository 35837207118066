import {FormNamesOldType} from './types';

enum FORM_NAMES {
  name = 'name',
  surname = 'surname',
  second_surname = 'second_surname',
  birth_date = 'birth_date',
  gender = 'gender',
  nationality = 'nationality',
  citizenship = 'citizenship',
  document_type = 'document_type',
  document_number = 'document_number',
  document_issue_date = 'document_issue_date',
  document_expiration_date = 'document_expiration_date',
  document_expedition_country = 'document_expedition_country',
  document_expedition_city = 'document_expedition_city',
  visa_number = 'visa_number',
  purpose_of_stay = 'purpose_of_stay',
  tax_exemption = 'tax_exemption',
  full_tourist_tax = 'full_tourist_tax',
  birth_place_country = 'birth_place_country',
  birth_place_city = 'birth_place_city',
  birth_place_address = 'birth_place_address',
  residence_country = 'residence_country',
  residence_community = 'residence_community',
  residence_city = 'residence_city',
  residence_province = 'residence_province',
  residence_address = 'residence_address',
  residence_postal_code = 'residence_postal_code',
  arrived_from_country = 'arrived_from_country',
  arrived_from_district = 'arrived_from_district',
  arrived_from_municipality = 'arrived_from_municipality',
  arrived_from_province = 'arrived_from_province',
  next_destination_country = 'next_destination_country',
  next_destination_district = 'next_destination_district',
  next_destination_province = 'next_destination_province',
  next_destination_address = 'next_destination_address',
  next_destination_municipality = 'next_destination_municipality',
  fiscal_code = 'fiscal_code',
  email = 'email',
  phone = 'phone',
  second_phone = 'second_phone',
  document_front_side_scan = 'document_front_side_scan',
  document_back_side_scan = 'document_back_side_scan',
  signature = 'signature',
  document_support_number = 'document_support_number',
  kinship_relationship = 'kinship_relationship',
  external_category_id = 'external_category_id',
  contract_email = 'contract_email',
}

enum EXTRA_FORM_NAMES {
  signature = 'signature',
  secondPhoneDetails = 'second_phone_details',
  sendCopyContractEmail = 'send_copy_contract_email',
}

enum FORM_NAMES_OLD {
  name = 'name',
  surname = 'surname',
  secondSurname = 'second_surname',
  birthDate = 'birthDate',
  sex = 'sex',
  nationality = 'nationality',
  docType = 'docType',
  docNumber = 'docNumber',
  docExpirationDate = 'document_expiration_date',
  countryOfIssue = 'countryOfIssue',
  italianCityOfIssue = 'italianCityOfIssue',
  docDateOfIssue = 'docDateOfIssue',
  birthCountry = 'birthCountry',
  italianCityOfBirth = 'italianCityOfBirth',
  residenceCountry = 'residenceCountry',
  residenceCity = 'residenceCity',
  italianResidenceCity = 'italianResidenceCity',
  residenceAddress = 'residenceAddress',
  cityOfBirth = 'cityOfBirth',
  cityOfIssue = 'cityOfIssue',
  nextDestinationCountry = 'nextDestinationCountry',
  nextDestinationCity = 'nextDestinationCity',
  nextDestinationAddress = 'nextDestinationAddress',
  nextDestinationDistrict = 'nextDestinationDistrict',
  nextDestinationMunicipality = 'nextDestinationMunicipality',
  residencePostalCode = 'residencePostalCode',
  residenceProvince = 'residenceProvince',
  residence_community = 'residence_community',
  citizenship = 'citizenship',
  purposeOfStay = 'purposeOfStay',
  visaNumber = 'visaNumber',
  arrivalCountry = 'arrivalCountry',
  arrivalDistrict = 'arrivalDistrict',
  arrivalMunicipality = 'arrivalMunicipality',
  taxExemption = 'taxExemption',
  fiscalCode = 'fiscalCode',
  email = 'email',
  phone = 'phone',
}

const FORM_NAMES_DTO: {
  [key in FormNamesOldType]: FORM_NAMES;
} = {
  [FORM_NAMES_OLD.name]: FORM_NAMES.name,
  [FORM_NAMES_OLD.surname]: FORM_NAMES.surname,
  [FORM_NAMES_OLD.secondSurname]: FORM_NAMES.second_surname,
  [FORM_NAMES_OLD.birthDate]: FORM_NAMES.birth_date,
  [FORM_NAMES_OLD.sex]: FORM_NAMES.gender,
  [FORM_NAMES_OLD.nationality]: FORM_NAMES.nationality,
  [FORM_NAMES_OLD.docType]: FORM_NAMES.document_type,
  [FORM_NAMES_OLD.docNumber]: FORM_NAMES.document_number,
  [FORM_NAMES_OLD.countryOfIssue]: FORM_NAMES.document_expedition_country,
  [FORM_NAMES_OLD.italianCityOfIssue]: FORM_NAMES.document_expedition_city,
  [FORM_NAMES_OLD.docDateOfIssue]: FORM_NAMES.document_issue_date,
  [FORM_NAMES_OLD.birthCountry]: FORM_NAMES.birth_place_country,
  [FORM_NAMES_OLD.italianCityOfBirth]: FORM_NAMES.birth_place_city,
  [FORM_NAMES_OLD.residenceCountry]: FORM_NAMES.residence_country,
  [FORM_NAMES_OLD.residence_community]: FORM_NAMES.residence_community,
  [FORM_NAMES_OLD.residenceCity]: FORM_NAMES.residence_city,
  [FORM_NAMES_OLD.italianResidenceCity]: FORM_NAMES.residence_city,
  [FORM_NAMES_OLD.residenceAddress]: FORM_NAMES.residence_address,
  [FORM_NAMES_OLD.cityOfBirth]: FORM_NAMES.birth_place_city,
  [FORM_NAMES_OLD.cityOfIssue]: FORM_NAMES.document_expedition_city,
  [FORM_NAMES_OLD.docExpirationDate]: FORM_NAMES.document_expiration_date,
  [FORM_NAMES_OLD.nextDestinationCountry]: FORM_NAMES.next_destination_country,
  [FORM_NAMES_OLD.nextDestinationAddress]: FORM_NAMES.next_destination_address,
  [FORM_NAMES_OLD.nextDestinationDistrict]: FORM_NAMES.next_destination_district,
  [FORM_NAMES_OLD.nextDestinationMunicipality]: FORM_NAMES.next_destination_municipality,
  [FORM_NAMES_OLD.residencePostalCode]: FORM_NAMES.residence_postal_code,
  [FORM_NAMES_OLD.residenceProvince]: FORM_NAMES.residence_province,
  [FORM_NAMES_OLD.citizenship]: FORM_NAMES.citizenship,
  [FORM_NAMES_OLD.purposeOfStay]: FORM_NAMES.purpose_of_stay,
  [FORM_NAMES_OLD.arrivalCountry]: FORM_NAMES.arrived_from_country,
  [FORM_NAMES_OLD.arrivalDistrict]: FORM_NAMES.arrived_from_district,
  [FORM_NAMES_OLD.arrivalMunicipality]: FORM_NAMES.arrived_from_municipality,
  [FORM_NAMES_OLD.taxExemption]: FORM_NAMES.tax_exemption,
  [FORM_NAMES_OLD.email]: FORM_NAMES.email,
  [FORM_NAMES_OLD.phone]: FORM_NAMES.phone,
  // [FORM_NAMES_OLD.nextDestinationCity]: FORM_NAMES.,
  // [FORM_NAMES_OLD.fiscalCode]: FORM_NAMES.,
  // [FORM_NAMES_OLD.visaNumber]: FORM_NAMES.,
};

const IV_FIELDS = [
  'o_c_r_passed',
  'biomatch_passed',
  'biomatch_percentage',
  'guest_biomatch_doc',
  'guest_biomatch_doc_service',
  'guest_biomatch_selfie',
  'guest_biomatch_selfie_service',
  'guest_front_side_scan',
  'guest_front_side_scan_service',
  'guest_back_side_scan',
  'guest_back_side_scan_service',
  'document_front_side_scan',
  'document_back_side_scan',
  'biomatch_selfie',
  'biomatch_doc',
];

const DATE_FIELDS = [
  FORM_NAMES.birth_date,
  FORM_NAMES.document_issue_date,
  FORM_NAMES.document_expiration_date,
];

const PHONE_FIELDS = [FORM_NAMES.phone, FORM_NAMES.second_phone];

const SIGNATURE_FIELDS = [FORM_NAMES.signature];

export {
  FORM_NAMES,
  EXTRA_FORM_NAMES,
  FORM_NAMES_OLD,
  FORM_NAMES_DTO,
  IV_FIELDS,
  DATE_FIELDS,
  SIGNATURE_FIELDS,
  PHONE_FIELDS,
};
