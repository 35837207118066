import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import {ButtonProps} from './Button';
import {BUTTONS_STYLES_OPTIONS} from '../../utils/types';

type ButtonStyledProps = Pick<
  ButtonProps,
  'onClick' | 'disabled' | 'type' | 'iconName'
> & {
  $width?: string | null;
  $primary?: boolean;
  $secondary?: boolean;
  $outlined?: boolean;
  $light?: boolean;
  $dark?: boolean;
  $link?: boolean;
  $active?: boolean;
  $hasIcon?: boolean;
  $hasLabel?: boolean;
  $position?: 'left' | 'center' | 'right';
};

export const Text = styled.span`
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const getButtonStyles = (props: ButtonStyledProps) => {
  return css`
    font-family: inherit;
    display: flex;
    align-items: center;
    height: 48px;
    position: relative;
    box-shadow: 0 3px 4px #00020334;
    background: ${props => props.theme.colors.buttons.mainButtonBackground};
    color: ${props => props.theme.colors.buttons.mainButtonTextBackground};
    border: 1px solid ${props => props.theme.colors.buttons.secondaryButtonTextAndBorder};

    outline: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    box-sizing: border-box;
    user-select: none;
    padding: 12px 25px;
    transition: all 0.1s;
    border-radius: ${props =>
      props.theme.buttons.typeRadioButton === BUTTONS_STYLES_OPTIONS.round
        ? '50px'
        : '7px'};
    // width: ${() => (props.$width ? props.$width : '100%')};
    // max-width: ${() => (props.$width ? props.$width : '295px')};
    width: auto;
    min-width: 275px;
    justify-content: ${() => (props.$hasIcon ? 'space-between' : 'center')};

    &:hover {
      box-shadow: none;
      opacity: 0.85;
    }

    ${() =>
      props.$primary &&
      props.$outlined &&
      css`
        font-weight: 600;
        background: transparent;
        color: ${props => props.theme.colors.buttons.textButton};

        &:hover {
          background: transparent;
          box-shadow: none;
          opacity: 0.85;
        }
      `};

    ${() =>
      props.$hasLabel &&
      css`
        .button__right-icon {
          padding-left: 8px;
        }

        .button__left-icon {
          padding-right: 8px;
        }
      `}

    ${() =>
      props.$dark &&
      css`
        font-weight: 600;
        font-size: 16px;
        height: 50px;
        background: #181a20;

        &:hover {
          box-shadow: none;
        }
      `};

    ${() =>
      props.$link &&
      css`
        background: transparent;
        min-width: min-content;
        box-shadow: none;
        padding: 0;
        border: none;
        height: auto;
        width: auto;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: ${props => props.theme.colors.buttons.textButton};

        &:hover {
          opacity: 0.7;
          background: transparent;
        }
        &:active {
          background: inherit;
          opacity: 1;
        }

        ${props.$active &&
        css`
          opacity: 0.7;
          background: transparent;
        `}
      `};

    ${() =>
      props.$light &&
      css`
        display: flex;
        flex-wrap: nowrap;
        min-width: min-content;
        background: white;
        box-shadow: 0 0 0 2px #e6e8ec inset;
        color: #23262f;
        border: none;

        &:hover {
          background: #23262f;
          box-shadow: 0 0 0 2px #23262f inset;
          color: white;

          & svg {
            fill: white;
            color: white;
            flex-shrink: 0;

            path {
              fill: white;
            }
          }
        }

        ${props.$active &&
        css`
          background: #23262f;
          box-shadow: 0 0 0 2px #23262f inset;
          color: white;

          & svg {
            fill: white;
            color: white;
            flex-shrink: 0;

            path {
              fill: white;
            }
          }
        `};
      `};

    ${() =>
      props.disabled &&
      css`
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      `};

    ${() => {
      switch (props.$position) {
        case 'left':
          return css`
            margin-right: auto;
          `;
        case 'center':
          return css`
            margin-right: auto;
            margin-left: auto;
          `;
        case 'right':
          return css`
            margin-left: auto;
          `;
      }
    }}
  `;
};

export const IconContainer = styled.span`
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled.button<ButtonStyledProps>`
  ${props => getButtonStyles(props)}
`;

export const NavButton = styled(Link)<ButtonStyledProps>`
  ${props => getButtonStyles(props)}
`;
