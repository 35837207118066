import styled from 'styled-components';
import Lottie from 'lottie-react';
import Button from 'components/common/Button';
import {device} from 'styled/device';

export const ButtonConfirm = styled(Button)`
  max-width: 327px;
  margin: 0 auto 24px;
  @media (min-width: ${device.tablet}) {
    width: 327px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Wrapper = styled.div`
  padding: 0 24px;
`;

export const Text = styled.div`
  text-align: center;
  margin-bottom: 48px;
  color: ${props =>
    props.theme.hasTemplate ? props.theme.colors.branding.fontPrimary : '#152946'};
`;

export const BodyImage = styled.img`
  display: block;
  max-width: 224px;
  margin: 0 auto 48px;
  min-height: 224px;
  @media (min-width: ${device.tablet}) {
    margin: 0 auto 24px;
  }
`;

export const StyledLottie = styled(Lottie)`
  display: block;
  max-width: 224px;
  margin: 0 auto 48px;
  min-height: 224px;
  @media (min-width: ${device.tablet}) {
    margin: 0 auto 24px;
  }
`;
