import {AllianzDocument} from '@guestapp/sdk/src/utils/allianz';
import {downloadFromLink} from 'utils/common';

const downloadAllianzDocuments = (
  documentType: string,
  documents: AllianzDocument[] = [],
) => {
  const url = documents?.find(doc => doc.document_type === documentType)?.document;
  downloadFromLink(url);
};

export {downloadAllianzDocuments};
