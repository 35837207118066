import styled from 'styled-components';
import {Counter} from 'components/common/Counter';

export const StyledCounter = styled(Counter)`
  min-width: 100px;
  width: unset;
  height: unset;
`;

export const Title = styled.div`
  display: flex;
  padding: 8px 0;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
`;

export const Main = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;

export const Price = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

export const PerLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.colors.branding.fontSecondary};
`;

export const PricePerLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const TotalPrice = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;

  justify-content: space-between;
  gap: 8px;
`;

export const CounterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.colors.branding.fontSecondary};
  gap: 8px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

export const TotalPriceWrapper = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.branding.backgroundSecondary};

  display: flex;
  flex-direction: column;
  gap: 16px;
`;
