import styled, {DefaultTheme, css} from 'styled-components';
import {device} from './device';
import {transformSizePropToSize} from 'utils/common';
import Button from '../components/common/Button';
import {MainLoader} from '../components/common/loaders';

type FormFieldWrapperProps = {
  hasError?: boolean;
};

type BaseIconContainerProps = {
  size?: string | number;
};

export const FormFieldWrapper = styled.div.attrs({
  className: 'form-field-wrapper',
})<FormFieldWrapperProps>`
  display: inline-block;
  text-align: left;
  margin: 0 0 24px 0;
  position: relative;
  width: 275px;

  ${props =>
    props.hasError &&
    css`
      margin-bottom: 3px;
    `};

  @media (max-width: ${device.tablet}) {
    width: 100%;
  }
`;

export const ModalButton = styled(Button)`
  margin: 0 auto;
  font-weight: bold;
  text-transform: capitalize;
  min-width: 140px;
`;

export const MainButton = styled(Button)`
  margin: 0 auto 24px;
  width: 100%;
  max-width: 295px;
  .buttonLabel {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Box = styled.div`
  width: 309px;
  height: 237px;
  background: ${props => props.theme.colors.branding.boxesModals};
  box-shadow: 0 2px 10px rgba(147, 150, 174, 0.25);
  border-radius: 15px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const InvalidFileTypeError = styled.div`
  font-family: inherit;
  text-align: right;
  color: #f84b7a;
  font-size: 12px;
`;

export const ModalTwoButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-row-gap: 30px;
`;

export const BaseIconContainer = styled.div<BaseIconContainerProps>`
  width: ${props => transformSizePropToSize(props.size) || '24px'};
  height: ${props => transformSizePropToSize(props.size) || '24px'};
`;

export const SuspenseLoader = styled(MainLoader)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;

  @media (min-width: ${device.mobileL}) {
    svg {
      width: 100px;
    }
  }
`;

export const Hr = styled.div`
  height: 1px;
  width: 100%;
  background: #0000001a;
  margin-bottom: 24px;
`;

export const PageWrapper = styled.div<{invalidLink?: boolean; isUniversalLink?: boolean}>`
  display: block;

  @media (min-width: ${device.tablet}) {
    display: grid;
    grid-template-columns: ${props =>
      props.invalidLink ? 'none' : props.isUniversalLink ? '352px auto' : '284px auto'};
  }

  @media (min-width: ${device.laptop}) {
    display: grid;
    grid-template-columns: ${props =>
      props.invalidLink ? 'none' : props.isUniversalLink ? '552px auto' : '284px auto'};
    ${props =>
      props.invalidLink &&
      css`
        height: 100vh;
      `};
  }
`;

// eslint-disable-next-line
export const subTitleStyles = ({props, color}: {props?: any; color?: string} = {}) => `
  color: ${color || props.theme.colors.$p10};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
`;

export const regulartTextStyles = ({
  props,
  color,
}: // eslint-disable-next-line
{props?: any; color?: string} = {}) => `
  color: ${color || props.theme.colors.$p10};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
// eslint-disable-next-line
export const titleStyles = ({props}: {props?: any} = {}) => `
  color: ${props.theme.colors.$p10};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
  letter-spacing: 1px;
`;

export const getMainColor = (theme: DefaultTheme) => {
  return theme.hasTemplate
    ? theme.colors.branding.icons
    : theme.colors.buttons.mainButtonBackground;
};

export const getBackgroundColor = (
  isEmpty?: boolean,
  isFocused?: boolean,
  theme?: DefaultTheme,
) => {
  if (isEmpty && !isFocused) {
    return theme?.colors?.branding?.backgroundSecondary;
  } else {
    return theme?.colors?.branding?.boxesModals;
  }
};
