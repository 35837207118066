import {MAX_RETRIES_REQUESTS_NUMBER} from './constants';
import {FetcherSettings} from '@guestapp/sdk';

const DefaultResolverSettings: FetcherSettings = {
  errorRetryLimit: MAX_RETRIES_REQUESTS_NUMBER,
};

function fetcher<F extends (...args: Parameters<F>) => ReturnType<F>>(
  fetcher: F,
  ...params: Parameters<F>
) {
  return fetcher.apply(fetcher, [...params, DefaultResolverSettings] as Parameters<F>);
}

export default fetcher;
