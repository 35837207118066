import React from 'react';
import * as Sentry from '@sentry/react';
import ReactWebcam from 'react-webcam';
import {useTranslation} from 'react-i18next';
import {isFirefox, isMobile} from 'react-device-detect';
import clsx from 'clsx';
import {FacingMode, VideoConstraints, WebcamRefTypes} from './types';
import {compressFile, getCanvasBlob, getFileSizeMB, toBase64} from '../../utils/common';
import {
  getMobileOrDesktopVideoConstraints,
  isMobileOrTablet,
  getPhotoCanvas,
  isDOMException,
} from './utils';
import {useErrorModal} from 'hooks';
import {Canvas, ScanArea, StyledWebcam, Wrapper} from './styled';

const SCREENSHOT_FORMAT = 'image/jpeg';
const UNAVAILABLE_CAMERA_ERRORS = ['AbortError', 'NotReadableError'];

export type WebcamProps = Partial<ReactWebcam> & {
  onUserMedia?: () => void;
  onUserMediaError?: () => void;
  audio?: boolean;
  facingMode?: FacingMode;
  imageSmoothing?: boolean;
  mirrored?: boolean;
  videoConstraints?: VideoConstraints;
  additionalConstraints?: VideoConstraints;
  children?: React.ReactNode | JSX.Element;
  hideScanArea?: boolean;
  roundedScanArea?: boolean;
  placeholderImage?: React.ReactNode;
  className?: string;
  minScreenshotH?: number;
  minScreenshotW?: number;
  width?: number;
  height?: number;
  screenshotQuality?: number;
  screenshotMaxSize?: number;
  videoSourceDeviceId?: string;
};

const Webcam = React.memo(
  React.forwardRef<WebcamRefTypes, WebcamProps>(
    (
      {
        onUserMedia,
        onUserMediaError,
        audio,
        facingMode,
        imageSmoothing,
        children,
        videoConstraints,
        additionalConstraints,
        hideScanArea,
        roundedScanArea,
        className,
        mirrored,
        placeholderImage,
        screenshotQuality = 1,
        screenshotMaxSize,
        minScreenshotH,
        minScreenshotW,
        videoSourceDeviceId,
        ...props
      },
      ref,
    ) => {
      const [minScreenshotHeight, setMinScreenshotHeight] = React.useState<
        undefined | number
      >();
      const [minScreenshotWidth, setMinScreenshotWidth] = React.useState<
        undefined | number
      >();
      const {ErrorModal, displayError} = useErrorModal();
      const {t} = useTranslation();
      const constraints = React.useMemo(() => {
        return (
          videoConstraints ||
          getMobileOrDesktopVideoConstraints({
            deviceId: videoSourceDeviceId,
            additional: additionalConstraints,
            facingMode,
          })
        );
      }, [videoConstraints, additionalConstraints, videoSourceDeviceId, facingMode]);
      const isMirroredInCSS = mirrored === undefined && !isMobileOrTablet();
      const webcamRef = React.useRef<ReactWebcam>(null);
      const canvas = React.useRef<HTMLCanvasElement>(null);
      const container = React.useRef<HTMLDivElement>(null);

      const [numberOfCameras, setNumberOfCameras] = React.useState<number>(0);
      const [, setNotSupported] = React.useState<boolean>(false);
      const [, setPermissionDenied] = React.useState<boolean>(false);

      const compressImage = async (canvas?: HTMLCanvasElement) => {
        if (!canvas) return;

        try {
          const blobImage = await getCanvasBlob(canvas);

          if (!blobImage) return;

          const compressedFile = await compressFile(blobImage, {
            convertSize: screenshotMaxSize,
          });

          console.log(
            'Before: ',
            getFileSizeMB(blobImage)?.toFixed(2),
            'MB',
            'After: ',
            getFileSizeMB(compressedFile)?.toFixed(2),
            'MB',
          );

          return await toBase64(compressedFile);
        } catch (err) {
          // displayError(err);
          Sentry.captureException(err);
          throw err;
        }
      };

      const handleTakePhoto = () => {
        const takenPhotoCanvas = getPhotoCanvas({
          player: webcamRef?.current?.video,
          container: container?.current,
          canvas: canvas?.current,
        });

        return compressImage(takenPhotoCanvas);
      };

      React.useImperativeHandle(
        ref,
        () =>
          ({
            takePhoto: handleTakePhoto,
            numberOfCameras: numberOfCameras,
            ...webcamRef.current,
          } as WebcamRefTypes),
      );

      const handleUserMediaSuccess = React.useCallback(
        (stream: MediaStream) => {
          if (!isMobile) {
            const track = stream.getVideoTracks()[0];
            const aspectRatio = !isFirefox && track?.getCapabilities();

            if (aspectRatio && !isMobile) {
              setMinScreenshotHeight(aspectRatio?.height?.max);
              setMinScreenshotWidth(aspectRatio?.width?.max);
            }
          } else {
            setMinScreenshotWidth(minScreenshotW);
            setMinScreenshotHeight(minScreenshotH);
          }

          navigator.mediaDevices
            .enumerateDevices()
            .then(r => setNumberOfCameras(r.filter(i => i.kind === 'videoinput').length));

          onUserMedia?.();
        },
        [minScreenshotH, minScreenshotW, onUserMedia],
      );

      const handleUserMediaError = React.useCallback(
        (error: string | DOMException) => {
          console.error(error);
          //https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
          if (isDOMException(error) && error.name === 'PermissionDeniedError') {
            setPermissionDenied(true);
          } else if (
            isDOMException(error) &&
            UNAVAILABLE_CAMERA_ERRORS.includes(error.name)
          ) {
            displayError(t('chekin_cant_use_your_camera'), {
              title: t('chekin_cant_use_your_camera'),
              message: `${t('close_other_application_that_may_be_using_your_camer')}. 
                ${t('if_you_use_external_camera_disconnect_and_reconnect')}.
                ${t('close_the_browser_re_open_it')}.`,
            });
          } else {
            setNotSupported(true);
          }
        },
        [displayError, t],
      );

      return (
        <Wrapper
          ref={container}
          className={clsx(className, 'webcam')}
          isMirroredInCSS={isMirroredInCSS}
        >
          {!hideScanArea && (
            <ScanArea className="webcam__scan-area" rounded={roundedScanArea}>
              <div />
              <div />
              <div />
              <div />
              {placeholderImage}
            </ScanArea>
          )}
          <Canvas ref={canvas} />
          <StyledWebcam
            ref={webcamRef}
            mirrored={mirrored}
            width={props.width}
            height={props.height}
            videoConstraints={constraints}
            imageSmoothing={imageSmoothing}
            screenshotFormat={SCREENSHOT_FORMAT}
            onUserMedia={handleUserMediaSuccess}
            screenshotQuality={screenshotQuality}
            onUserMediaError={handleUserMediaError}
            minScreenshotWidth={minScreenshotWidth}
            minScreenshotHeight={minScreenshotHeight}
            className="webcam__video"
          />
          <ErrorModal />
        </Wrapper>
      );
    },
  ),
);

export {Webcam};
