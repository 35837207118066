import React from 'react';
import {Outlet} from 'react-router-dom';
import {AUTH_INIT_MODELS} from '@guestapp/sdk';
import {device} from '@guestapp/ui/src/utils/device';
import {SHORT_LINK_TYPE} from '../../utils/constants';
import {useChekinSDK} from '../../context/ChekinSDK';
import ChekinPro from '../../ChekinPro';
import {useScreenResize} from '../../hooks';
import PageSideBar from '../PageSideBar';
import SideBarUniversalLink from 'components/PageSideBarUniversalLink/Components';
import {PageWrapper} from '../../styled/common';

const LinksWithoutSidebar: string[] = [SHORT_LINK_TYPE.lock];

function RootLayout() {
  const {linkType, isAuthInitialization, modelName} = useChekinSDK();

  const {isMatch: isMobile} = useScreenResize(device.tabletSubHeader);
  const [, , , token] = window.location.href.split('/');
  const isWrongPage = !token && !ChekinPro.getSettings().apiKey;
  const isLinkWithoutSidebar = LinksWithoutSidebar.includes(linkType ?? '');
  const isUniversalLink = modelName === AUTH_INIT_MODELS.housing;
  const isSideBarVisible =
    !isAuthInitialization &&
    !isMobile &&
    !isWrongPage &&
    !isLinkWithoutSidebar &&
    !isUniversalLink;

  return (
    <PageWrapper
      invalidLink={!isSideBarVisible && !isUniversalLink}
      isUniversalLink={isUniversalLink}
    >
      {isSideBarVisible && <PageSideBar />}
      {isUniversalLink && <SideBarUniversalLink />}
      {/* TODO at this level must bet the housing property link mobile navbar */}
      <Outlet />
    </PageWrapper>
  );
}

export {RootLayout};
