// eslint-disable-next-line
type ResponseBody = Record<string, any> | null | string;

// eslint-disable-next-line
const customFetch = async (url: string | Request, options: any) => {
  return new Promise<{
    body: ResponseBody;
    response: Response;
  }>(async (resolve, reject) => {
    try {
      const response = await fetch(url, options);
      const body = await getResponseBody(response);
      resolve(checkStatus({response, body}));
    } catch (error) {
      reject(error);
      // return (error as Error).message;
    }
  });
  //OLD WY WAITING FOR QA IF TASK IS CORRECT TO REMOVE
  // return fetch(url, options)
  //   .then((response: Response) => {
  //     return getResponseBody(response).then(body => ({
  //       response,
  //       body,
  //     }));
  //   })
  //   .then(checkStatus).catch(error => {
  //     return error
  //   })
};

const getResponseBody = (response: Response): Promise<ResponseBody> => {
  const contentType = response.headers.get('content-type');
  return contentType && contentType.indexOf('json') >= 0
    ? response.clone().text().then(tryParseJSON)
    : response.clone().text();
};

const tryParseJSON = (json: string): Record<string, unknown> | null => {
  if (!json) {
    return null;
  }

  try {
    return JSON.parse(json);
  } catch (e) {
    throw new Error(`Failed to parse unexpected JSON response: ${json}`);
  }
};

class ResponseError extends Error {
  status: number;
  response: Response;
  body: ResponseBody;

  constructor(status: number, response: Response, body: ResponseBody) {
    super();
    this.name = 'ResponseError';
    this.status = status;
    this.response = response;
    this.body = body;
  }
}

const checkStatus = ({response, body}: {response: Response; body: ResponseBody}) => {
  if (response.ok) {
    return {response, body};
  } else {
    throw new ResponseError(response.status, response, body);
  }
};

export default customFetch;
