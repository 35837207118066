import React from 'react';
import {useNavigate} from 'react-router-dom';
import {SHORT_LINK_TYPE} from '../utils/constants';
import {PATHS} from '../Routes';

const getUrlPathsFromShortLink = (type = '', token = '') => {
  return {
    [SHORT_LINK_TYPE.main]: '',
    [SHORT_LINK_TYPE.payment]: `${token}/${PATHS.payments.main}`,
    [SHORT_LINK_TYPE.upselling]: `${token}/${PATHS.recommendations.main}`,
    [SHORT_LINK_TYPE.lock]: `${token}/${PATHS.remoteAccess}`,
  }[type];
};

type UseRedirectToUrlFromShortLinkProps = {
  linkType?: string;
  token?: string;
};
function useRedirectToUrlFromShortLink({
  linkType,
  token,
}: UseRedirectToUrlFromShortLinkProps) {
  const navigate = useNavigate();

  React.useEffect(
    function redirectToUrlFromShortLink() {
      if (linkType) {
        const redirectUrl = getUrlPathsFromShortLink(linkType, token);
        if (redirectUrl) {
          navigate(redirectUrl);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, linkType],
  );
}

export {useRedirectToUrlFromShortLink};
