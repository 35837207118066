import styled, {css} from 'styled-components';
import {device} from '../../styled/device';
import Button from '../common/Button';
import {CustomIcon} from '@guestapp/ui';

export const Wrapper = styled.div<{hasError?: boolean}>`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 40px;
  background-color: #f4f6f9;
  gap: 16px;

  ${props =>
    props.hasError &&
    css`
      &&& {
        border-color: ${({theme}) => theme.colors.$s1};
      }
    `};

  @media (max-width: ${device.mobileXL}) {
    text-align: center;
    padding: 40px 10px 60px;
  }
`;

export const Input = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${({theme}) => theme.colors.branding.fontPrimary};

  @media (min-width: ${device.tablet}) {
    font-size: 18px;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 16px;
  height: 48px;
  /* margin-top: 28px; */
`;

export const ErrorMessage = styled.p`
  font-size: 14px;
  margin: 0 auto 12px;
  padding: 0 10px;
  color: ${({theme}) => theme.colors.$s1};
`;

export const HelperText = styled.p`
  /* margin: 30px 0 10px; */
  max-width: 223px;
  font-size: 14px;
  color: ${({theme}) => theme.colors.branding.fontSecondary};
  text-align: center;
`;

export const StyledIcon = styled(CustomIcon)`
  path {
    fill: ${({theme}) =>
      theme.hasTemplate
        ? theme.colors.branding.primary
        : theme.colors.buttons.mainButtonBackground};
  }
`;
