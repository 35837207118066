enum SECURITY_DEPOSIT_STATUSES {
  inactive = 'INACTIVE',
  optional = 'OPTIONAL',
  mandatory = 'MANDATORY',
}

enum INSURANCE_TYPES {
  securityDeposit = 'SECURITY_DEPOSIT',
  damageProtection = 'DAMAGE_PROTECTION',
}

export {SECURITY_DEPOSIT_STATUSES, INSURANCE_TYPES};
