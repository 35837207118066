import {ValidatorApi} from '../api';
import {FetcherSettings} from '../api/apiClient';

class ValidatorService {
  private readonly validatorApi: ValidatorApi;

  constructor() {
    this.validatorApi = new ValidatorApi();
  }

  validatePhoneNumber(phoneNumber: string, resolverSettings?: FetcherSettings) {
    return this.validatorApi.validatePhoneNumber({phone: phoneNumber}, resolverSettings);
  }

  async validatePurposeOfStay(
    v: string,
    data: {
      id: string;
      name: string;
    }[],
  ) {
    if (!data?.length) return false;
    const values = data.map(type => type?.id);
    return values.includes(v);
  }

  validateSex(sex: 'M' | 'F') {
    return ['M', 'F'].includes(sex);
  }
}

export {ValidatorService};
