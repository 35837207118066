import styled from 'styled-components';
import {device} from 'styled/device';
import Button from 'components/common/Button';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 0 24px;

  @media (min-width: ${device.tablet}) {
    padding: 0;
  }
`;

export const AutoFillIcon = styled.img``;
export const Title = styled.h1`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;
  text-align: center;
  color: ${props => props.theme.colors.branding.fontPrimary};
  margin-top: 48px;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.fontPrimary
      : props.theme.colors.$p10};
  margin-bottom: 32px;
`;

export const HelperText = styled(Text)`
  margin-bottom: 48px;
`;

export const UnderstoodButton = styled(Button)``;
