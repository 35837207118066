import {apiClient, FetcherSettings} from './apiClient';
import {dynamicRoute} from './utils';
import {ListDeal, Offer, Deal} from '../utils/upselling';
import {
  CreateOrUpdateBulkLockPayload,
  Paginated,
  ThirdPartyDealPayload,
} from '../utils/types';

type CheckIfOffersExistsResult = {result: boolean};

type CreateBulkDealPayload = {
  deals: Partial<Deal>[];
};

class UpsellingApi {
  ENDPOINTS = {
    offers: dynamicRoute({
      regular: (params = '') => `api/v3/upselling/offers/?${params}`,
      sdk: (params = '') => `sdk/api/v1/upselling/offers/?${params}`,
    }),
    deals: dynamicRoute({
      regular: (params = '') => `api/v3/upselling/deals/?${params}`,
      sdk: (params = '') => `sdk/api/v1/upselling/deals/?${params}`,
    }),
    createBulkDeal: dynamicRoute({
      regular: () => 'api/v3/upselling/deals/bulk-create/',
      sdk: () => 'sdk/api/v1/upselling/deals/bulk-create/',
    }),
    oneDeal: dynamicRoute({
      regular: (id: string) => `api/v3/upselling/third-party-deals/${id}/`,
      sdk: (id: string) => `sdk/api/v1/upselling/third-party-deals/${id}/`,
    }),
    oneOffer: dynamicRoute({
      regular: (id: string) => `api/v3/upselling/offers/${id}/`,
      sdk: (id: string) => `sdk/api/v1/upselling/offers/${id}/`,
    }),
    thirdPartyDeal: dynamicRoute({
      regular: (id?: string) => `api/v3/upselling/third-party-deals/${id}`,
      sdk: (id?: string) => `sdk/api/v1/upselling/third-party-deals/${id}`,
    }),
    bulkLock: dynamicRoute({
      regular: (id?: string) => `api/v3/upselling/third-party-deals/bulk-lock/${id}`,
      sdk: (id?: string) => `sdk/api/v1/upselling/third-party-deals/bulk-lock/${id}`,
    }),
    exists: dynamicRoute({
      regular: (params = '') => `api/v3/upselling/offers/exists/?${params}`,
      sdk: (params = '') => `sdk/api/v1/upselling/offers/exists/?${params}`,
    }),
  };

  async getOffers(params = '', fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<Paginated<Offer>>(
      this.ENDPOINTS.offers(params),
      fetcherSettings,
    );
    return response.body;
  }

  async getOffer(id: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<Offer>(
      this.ENDPOINTS.oneOffer(id),
      fetcherSettings,
    );
    return response.body;
  }

  async getDeals(params = '', fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<Paginated<ListDeal>>(
      this.ENDPOINTS.deals(params),
      fetcherSettings,
    );
    return response.body;
  }

  async deleteDeal<T>(id: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.delete<T>(
      this.ENDPOINTS.oneDeal(id),
      fetcherSettings,
    );
    return response.body;
  }

  async createBulkDeal(
    payload: CreateBulkDealPayload,
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient.post<Deal[]>(this.ENDPOINTS.createBulkDeal(), {
      json: payload,
      ...fetcherSettings,
    });
    return response.body;
  }

  async createOrUpdateThirdPartyDeal(
    payload: ThirdPartyDealPayload,
    fetcherSettings?: FetcherSettings,
  ) {
    const method = payload?.deal_id ? 'patch' : 'post';
    const response = await apiClient[method](
      this.ENDPOINTS.thirdPartyDeal(payload?.deal_id ?? ''),
      {json: payload, ...fetcherSettings},
    );
    return response.body;
  }

  async createOrUpdateBuildLock(
    payload: CreateOrUpdateBulkLockPayload,
    fetcherSettings?: FetcherSettings,
  ) {
    const method = payload?.bulk_lock_id ? 'patch' : 'post';
    const response = await apiClient[method]<unknown, CreateOrUpdateBulkLockPayload>(
      this.ENDPOINTS.bulkLock(payload?.bulk_lock_id ?? ''),
      {json: payload, ...fetcherSettings},
    );
    return response.body;
  }

  async fetchCheckIfOffersExists(params = '', fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<CheckIfOffersExistsResult>(
      this.ENDPOINTS.exists(params),
      fetcherSettings,
    );
    return response.body;
  }
}

export {UpsellingApi};
export type {CreateBulkDealPayload};
