import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {
  useScreenResize,
  useBookDeal,
  useModalControls,
  useActions,
  useErrorModal,
  useIsMounted,
} from 'hooks';
import {OFFER_CONFIRMATION_TYPES} from '@guestapp/sdk';
import {device} from '@guestapp/ui/src/utils/device';
import Page from 'components/Page';
import {OfferView} from 'pages/OfferView';
import {MobileActionPanel, StyledAddToCartForm, ExperienceButton} from './styled';
import {getPrice} from 'components/upselling/price';
import {CreateDealCompleteModal} from 'components/upselling/CreateDealCompleteModal';

function BookExperienceView() {
  const {isMatch: isMobileMode, isInitialized} = useScreenResize(device.tabletSubHeader);
  const {id: offerId} = useParams();
  const {t} = useTranslation();
  const {ErrorModal, displayError} = useErrorModal();
  const isMounted = useIsMounted();

  const {
    isOpen: isCreateDealCompleteModalOpen,
    openModal: openCreateDealCompleteModal,
    closeModal: closeCreateDealCompleteModal,
  } = useModalControls();
  const {
    createDeal,
    getEmail,
    navigateToConfirmEmail,
    navigateAfterSuccess,
    offer,
    quantity,
    isLoading,
  } = useBookDeal({
    offerId,
    onSuccess: openCreateDealCompleteModal,
    onError: errors => {
      if (!isMounted.current) return;
      displayError(errors[0]);
    },
  });

  const {setBookExperienceQuantity} = useActions();
  const quantityChangeHandler = (value: number) => {
    setBookExperienceQuantity({offerId: offerId || '', quantity: value});
  };

  const onCloseCompleteModal = () => {
    navigateAfterSuccess();
    closeCreateDealCompleteModal();
  };

  const addToCartHandler = () => {
    const email = getEmail();
    if (email || offer?.confirmation_type === OFFER_CONFIRMATION_TYPES.auto) {
      createDeal(email);
    } else {
      navigateToConfirmEmail();
    }
  };

  if (!isInitialized) {
    return null;
  }

  return isMobileMode ? (
    <Page
      isLoading={isLoading}
      isSubHeaderVisible
      title={t('book_experience_view.subtitle')}
      navBarTitle={t('book_experience_view.nav_bar_title')}
      bottomStickyPanel={
        isMobileMode &&
        offer && (
          <MobileActionPanel>
            <ExperienceButton
              disabled={!Boolean(quantity)}
              onClick={addToCartHandler}
              type={offer.confirmation_type}
            />
          </MobileActionPanel>
        )
      }
    >
      <StyledAddToCartForm
        price={offer ? getPrice(offer) : 0}
        value={quantity || 0}
        onChange={quantityChangeHandler}
        confirmationType={offer?.confirmation_type}
      />
      {offer && (
        <CreateDealCompleteModal
          isOpen={isCreateDealCompleteModalOpen}
          onClose={onCloseCompleteModal}
          confirmationType={offer?.confirmation_type}
        />
      )}
      <ErrorModal />
    </Page>
  ) : (
    <OfferView />
  );
}

export {BookExperienceView};
