import React from 'react';
import {NavigationPanelsArrow} from './styled';

type ToggleTasksArrowProps = {
  onClick: () => void;
  isOpenNavigationPanels: boolean;
};

const ToggleTasksArrow = ({onClick, isOpenNavigationPanels}: ToggleTasksArrowProps) => {
  return (
    <button onClick={onClick} className="chekin-boxes__tasks-mobile-open-arrow">
      <NavigationPanelsArrow
        name="grayRightArrow"
        isOpen={isOpenNavigationPanels}
        size={12}
      />
    </button>
  );
};

export {ToggleTasksArrow};
