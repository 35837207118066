import styled, {css} from 'styled-components';
import {device} from 'styled/device';
import Loader from '../common/Loader';
import {Checkbox, ComputedBox} from '@guestapp/ui';

export const Wrapper = styled.div`
  border: 0 solid #e6e8ec;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 365px;

  @media only screen and (max-width: ${device.tablet}) {
    width: 100%;
    margin-left: 0;
  }
`;

export const AcceptWarning = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 24px;
  color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.fontSecondary
      : props.theme.colors.$p10};

  & a {
    font-size: inherit;
    font-weight: 700;
  }
`;

export const LinkInText = styled.span`
  font-weight: bold;
  color: ${({theme}) => theme.colors.buttons.textButton};
  cursor: pointer;
`;

export const WrapperWithButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;

  @media (max-width: ${device.laptopL}) {
    margin-left: 0;
  }
`;

export const PleaseSignModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 400px;
  height: 400px;

  .title {
    font-size: 24px;
  }

  .text {
    font-size: 18px;
  }
`;

type DesktopSignAreaContainerProps = {
  disabled: boolean;
  empty: boolean;
  required?: boolean;
};

export const DesktopSignArea = styled.div`
  width: 300px;
  height: 179px;

  border-radius: 5px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DesktopSignAreaContainer = styled.div<DesktopSignAreaContainerProps>`
  height: 200px;
  background: #f4f6f9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 auto 12px;
  border: 2px solid #e6e8ec;
  width: 100%;

  @media only screen and (min-width: ${device.tablet}) {
    width: 300px;
  }

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      ${DesktopSignArea} {
        border: 2px dashed #b1b5c4;
      }
    `}

  ${props =>
    props.required &&
    css`
      border: 1px solid #f84b7a;
    `}
`;

export const SignButton = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.15px;
  color: ${({theme}) => theme.colors.buttons.textButton};

  @media (min-width: ${device.tablet}) {
    color: ${({theme}) => theme.colors.buttons.textButton};
  }
`;

export const DesktopSignatureImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
export const RepeatSignatureButton = styled.span`
  margin-bottom: 70px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.15px;
  color: ${({theme}) => theme.colors.buttons.textButton};
  cursor: pointer;

  @media (min-width: ${device.tablet}) {
    margin-bottom: 36px;
  }
`;

export const CheckboxContainer = styled.div`
  margin-bottom: 48px;
  width: 100%;

  @media (min-width: ${device.tablet}) {
    width: auto;
  }
`;

export const ContractTitleLine = styled.div.attrs({className: 'signature-block__title'})`
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
  margin-bottom: 16px;
  width: 100%;
`;

export const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.branding.fontPrimary};
`;

export const SubmitButton = styled.div`
  margin: 0 auto;
  width: 100%;

  & .submit-btn {
    width: 100%;
    margin: 0 auto;
  }
`;

export const ErrorMessage = styled.div`
  font-family: inherit;
  text-align: right;
  color: #f84b7a;
  font-size: 11px;
  margin-bottom: 15px;
`;

export const StyledCheckbox = styled(Checkbox)`
  font-size: 14px;
`;

export const StyledComputedBox = styled(ComputedBox)`
  padding-top: 20px;

  @media (min-width: ${device.tablet}) {
    padding-top: 16px;
  }
`;

export const StyledLoader = styled(Loader)`
  margin: 10px auto 35px;
`;
