import React, {useRef, useLayoutEffect, useState, useEffect, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import Button from 'components/common/Button';
import {throttle} from 'utils/throttle';
import {CollapsableArea, Wrapper} from './styled';

type Props = {
  children: React.ReactNode;
  heightLimit?: number;
};
const CollapsableBox = ({children, heightLimit}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isCollapsible, setCollapsible] = useState(false);
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();

  const checkCollapsible = useCallback((heightLimit?: number) => {
    const contentHeight = contentRef.current?.offsetHeight || 0;
    return Boolean(heightLimit && contentHeight > heightLimit);
  }, []);

  useLayoutEffect(() => {
    setCollapsible(checkCollapsible(heightLimit));
  }, [heightLimit, checkCollapsible]);

  useEffect(() => {
    let resizeObserver: ResizeObserver;
    if (contentRef.current) {
      resizeObserver = new ResizeObserver(
        throttle(
          () => {
            setCollapsible(checkCollapsible(heightLimit));
          },
          200,
          {trailing: true},
        ),
      );
      resizeObserver.observe(contentRef.current);
    }

    return () => resizeObserver?.disconnect();
  }, [heightLimit, checkCollapsible]);

  return (
    <Wrapper>
      <div>
        <CollapsableArea
          heightLimit={heightLimit}
          collapsible={isCollapsible}
          open={open}
        >
          <div ref={contentRef}>{children}</div>
        </CollapsableArea>
        {isCollapsible && !open && '...'}
      </div>

      {isCollapsible && (
        <div>
          <Button
            link
            label={open ? t('view_less') : t('view_complete_description')}
            onClick={() => setOpen(open => !open)}
          />
        </div>
      )}
    </Wrapper>
  );
};

export {CollapsableBox};
