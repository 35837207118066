import React, {ButtonHTMLAttributes, MouseEventHandler} from 'react';
import {To} from 'react-router-dom';
import clsx from 'clsx';
import StyledIcon, {IconNames} from '../CustomIcon/CustomIcon';
import {transformSizePropToSize} from '../../../utils/common';
import {IconContainer, NavButton, StyledButton, Text} from './styled';

export type ButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> & {
  label?: React.ReactNode | string;
  iconName?: IconNames;
  customIcon?: React.ReactNode;
  iconProps?: {size?: string | number; color?: string; position?: 'left' | 'right'};
  position?: 'center' | 'left' | 'right';
  width?: string | number;
  short?: boolean;
  primary?: boolean;
  outlined?: boolean;
  light?: boolean;
  dark?: boolean;
  link?: boolean;
  active?: boolean;
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | MouseEventHandler<HTMLAnchorElement>;
  to?: To;
  openNewTab?: boolean;
};

function Button({
  primary = true,
  outlined,
  light,
  dark,
  link,
  active,
  width,
  loading,
  openNewTab,
  ...propsWithoutExtra
}: ButtonProps) {
  const {
    customIcon,
    iconName,
    iconProps = {position: 'right'},
    label,
    disabled,
    className,
    position,
    type,
    to,
    onClick,
  } = propsWithoutExtra;

  const hasIcon = !!customIcon || !!iconName;
  const transformedWidth = transformSizePropToSize(width);

  const Icon = React.useMemo(
    () =>
      hasIcon && customIcon ? (
        customIcon
      ) : (
        <StyledIcon
          name={iconName as IconNames}
          size={iconProps?.size}
          color={iconProps?.color}
        />
      ),
    [customIcon, hasIcon, iconName, iconProps?.color, iconProps?.size],
  );

  const content = React.useMemo(() => {
    return (
      <>
        {hasIcon && iconProps?.position === 'left' && (
          <IconContainer className="button__left-icon">{Icon}</IconContainer>
        )}
        <Text className="buttonLabel">{label}</Text>
        {hasIcon && iconProps?.position !== 'left' && (
          <IconContainer className="button__right-icon">{Icon}</IconContainer>
        )}
      </>
    );
  }, [hasIcon, Icon, iconProps?.position, label]);

  const propsDto = {
    $primary: primary,
    $light: light,
    $dark: dark,
    $link: link,
    $outlined: outlined,
    $active: active,
    $loading: loading,
    $width: transformedWidth,
    $hasIcon: hasIcon,
    $position: position,
  };
  const targetBlank = openNewTab ? {target: '_blank'} : null;
  const classes = clsx(className, 'chekin-button', {
    'chekin-button_primary': !light && !dark && !link && !outlined && primary,
    'chekin-button_light': light,
    'chekin-button_dark': dark,
    'chekin-button_link': link,
    'chekin-button_outlined': outlined,
    'chekin-button_active': active,
  });
  const navLinkClasses = clsx(className, 'chekin-nav-link-button', {
    'chekin-nav-link-button_primary': !light && !dark && !link && !outlined && primary,
    'chekin-nav-link-button_light': light,
    'chekin-nav-link-button_dark': dark,
    'chekin-nav-link-button_link': link,
    'chekin-nav-link-button_outlined': outlined,
    'chekin-nav-link-button_active': active,
  });

  return to ? (
    <NavButton
      to={to}
      // disabled={disabled}
      className={navLinkClasses}
      type={type}
      onClick={onClick as MouseEventHandler<HTMLAnchorElement>}
      {...propsDto}
      {...targetBlank}
    >
      {content}
    </NavButton>
  ) : (
    <StyledButton
      disabled={disabled || loading}
      type={type}
      {...propsDto}
      {...propsWithoutExtra}
      className={classes}
      onClick={onClick as MouseEventHandler<HTMLButtonElement>}
    >
      {content}
    </StyledButton>
  );
}

export {Button};
export default Button;
