import {PhoneDetails} from './types';

function displayError(message: string) {
  throw new Error(message);
}

function jsonParse(data: string | null) {
  try {
    if (data) {
      return JSON.parse(data);
    }
    return null;
  } catch {
    return data;
  }
}

function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!file) {
      return reject('File is missing');
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
}

const getPhoneNumberString = (phone?: PhoneDetails | string | null) => {
  if (typeof phone === 'object') {
    return phone?.code ? `${phone?.code}${phone?.number}` : '';
  }

  return phone;
};

export {getBase64, displayError, jsonParse, getPhoneNumberString};
