import React from 'react';
import {useQuery} from '@tanstack/react-query';
import usePreloadDeferredPayments from './usePreloadDeferredPayments';
import {QUERY_CACHE_KEYS} from '../../utils/constants';
import {useChekinSDK} from '../../context/ChekinSDK';
import {useActions, useAppSelector} from '../redux';
import {useReservation} from '../../context/reservation';
import {CartPayment} from '@guestapp/sdk';

const CACHE_TIME = 5_000;
const STALE_TIME = 5_000;

function useDeferredPayments(defaultDeferredPayments?: CartPayment[]) {
  const deferredPayments = useAppSelector(state => state.payments.deferredPayments);
  const {addDeferredPayments, removeDeferredPayment} = useActions();

  const deferredPaymentsIds = React.useMemo(() => {
    return deferredPayments.map(p => p.id);
  }, [deferredPayments]);

  React.useEffect(
    function setDefaultPayments() {
      if (!deferredPayments?.length && defaultDeferredPayments?.length) {
        addDeferredPayments(defaultDeferredPayments);
      }
    },
    [addDeferredPayments, defaultDeferredPayments, deferredPayments?.length],
  );

  return {
    deferredPayments,
    deferredPaymentsIds,
    addDeferredPayments,
    removeDeferredPayment,
  };
}

type UsePaymentsCartProps = {
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
  defaultDeferredPayments?: CartPayment[];
};
function usePaymentsCart({
  refetchOnMount = true,
  refetchOnWindowFocus = false,
  defaultDeferredPayments,
}: UsePaymentsCartProps = {}) {
  const {getPaymentsCart, isTokenValid} = useChekinSDK();
  const {data} = useReservation();
  const {deferredPaymentsIds, addDeferredPayments, removeDeferredPayment} =
    useDeferredPayments(defaultDeferredPayments);
  const {isPreloading} = usePreloadDeferredPayments({
    addDeferredPayments,
    removeDeferredPayment,
  });

  const {
    data: paymentsCart,
    isLoading: isPaymentsCartLoading,
    isFetching: isPaymentsCartFetching,
  } = useQuery(
    [QUERY_CACHE_KEYS.paymentsCart, deferredPaymentsIds],
    () =>
      getPaymentsCart(
        `reservation=${data?.id}&pay_later=${deferredPaymentsIds?.toString()}`,
      ),
    {
      keepPreviousData: true,
      enabled: Boolean(!isPreloading && data?.id && isTokenValid),
      refetchOnWindowFocus,
      refetchOnMount,
      cacheTime: CACHE_TIME,
      staleTime: STALE_TIME,
    },
  );

  const selectedPayments = paymentsCart?.pay_now_payments || [];
  const isPaymentsCartEmpty =
    !paymentsCart?.pay_now_payments?.length &&
    !paymentsCart?.pay_later_payments?.length &&
    !isPaymentsCartLoading &&
    !isPaymentsCartFetching;

  return {
    paymentsCart,
    isPaymentsCartLoading,
    isPaymentsCartFetching,
    selectedPayments,
    isPaymentsCartEmpty,
    addDeferredPayments,
    removeDeferredPayment,
  };
}

export {usePaymentsCart};
