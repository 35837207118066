import {Trans, useTranslation} from 'react-i18next';
import ModalSheet from 'components/common/ModalSheet';
import allianzIcon from 'assets/img/allianz-logo.gif';
import {AllianzModalBoldText, AllianzModalContent, AllianzModalSubText} from './styled';

type AllianzLoadingModalProps = {
  isOpen: boolean;
  housingName?: string;
};

function AllianzLoadingModal({isOpen, housingName}: AllianzLoadingModalProps) {
  const {t} = useTranslation();

  return (
    <ModalSheet
      isOpenModalSheet={isOpen}
      withModalMobileCloseButton={false}
      modalMobileHeight="100vh"
      className="allianz-loading-modal"
      withCloseButton={false}
      closeOnDocumentClick={false}
    >
      <AllianzModalContent>
        <div className="gif-container">
          <img src={allianzIcon} alt="Allianz icon" />
        </div>
        <AllianzModalBoldText>
          <Trans
            i18nKey="we_found_some_allianz_insurance_plans_text"
            values={{housingName}}
          />
        </AllianzModalBoldText>
        <AllianzModalSubText>{t('loading_allianz_ensurance')}</AllianzModalSubText>
      </AllianzModalContent>
    </ModalSheet>
  );
}

export {AllianzLoadingModal};
