import {Season, SeasonRule} from '@guestapp/sdk';

class SeasonEntity implements Partial<Season> {
  rules?: SeasonRule[];

  constructor(season?: Season) {
    this.rules = season?.rules;
  }

  get lessThanRule() {
    if (!this.rules?.length) {
      return null;
    }

    return this.rules.find((rule: SeasonRule) => {
      return rule.less_than && !rule.up_to;
    });
  }

  get moreThanRule() {
    if (!this.rules?.length) {
      return null;
    }

    return this.rules.find((rule: SeasonRule) => {
      return rule.up_to && !rule.less_than;
    });
  }

  get betweenRule() {
    if (!this.rules?.length) {
      return null;
    }

    return this.rules.find((rule: SeasonRule) => {
      return rule.up_to && rule.less_than;
    });
  }
}

export default SeasonEntity;
