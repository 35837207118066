import {useCallback} from 'react';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useChekinSDK} from 'context/ChekinSDK';
import {useReservation} from 'context/reservation';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {GUEST_STATUSES, GuestGroup, UpdateGuestGroup} from '@guestapp/sdk';

export type ErrorRequest = {
  code: string;
  errors: {
    message: string;
  }[];
  message: string;
  status: number;
  status_code: number;
};

function useGuestGroup() {
  const {getGuestGroup, isSuccessSDK, patchGuestGroup} = useChekinSDK();
  const {data: reservation} = useReservation();
  const guestGroupId = reservation?.guest_group_id;
  const queryClient = useQueryClient();

  const {data, isLoading, isFetching, error, isError, isSuccess} = useQuery(
    [QUERY_CACHE_KEYS.guestGroup],
    () => getGuestGroup(guestGroupId || ''),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: isSuccessSDK && Boolean(guestGroupId),
    },
  );
  const isLeaderGuest = data?.added_number_of_guests === 0;
  const isLeaderGuestRegistered = Boolean(data?.added_number_of_guests);

  const allGuestsRegistered =
    (data?.known_number_of_guests ?? 0) <= (data?.added_number_of_guests ?? 0);

  const isCurrentRegisteredGuestsCompleted = !data?.members.length
    ? false
    : data?.members.every(member => member.guestapp_status === GUEST_STATUSES.complete);

  const isAllRegisteredGuestIdentified =
    allGuestsRegistered && isCurrentRegisteredGuestsCompleted;

  const missingGuests = !data
    ? 0
    : data.known_number_of_guests - data.added_number_of_guests;

  const guestGroupMutation = useMutation<
    GuestGroup,
    ErrorRequest,
    {guestGroupId: string; payload: UpdateGuestGroup}
  >(({payload, guestGroupId}) => patchGuestGroup(guestGroupId, payload), {
    onSuccess: () => {
      void queryClient.invalidateQueries([QUERY_CACHE_KEYS.seasonGuests]);
      void queryClient.refetchQueries([QUERY_CACHE_KEYS.summary]);
      void queryClient.refetchQueries([QUERY_CACHE_KEYS.guestGroup]);
    },
    onError: error => {
      if (error.status_code === 400) {
        console.error('error request 400');
        return;
      }
    },
  });

  const updateGuestGroup = useCallback(
    (
      payload: UpdateGuestGroup,
      options: Parameters<(typeof guestGroupMutation)['mutate']>[1],
    ) => {
      guestGroupMutation.mutate(
        {
          guestGroupId: guestGroupId as string,
          payload,
        },
        options,
      );
    },
    [guestGroupMutation, guestGroupId],
  );

  return {
    guestGroup: data,
    isLoading: isLoading,
    isFetching: isFetching || guestGroupMutation.isLoading,
    updateGuestGroup,
    guestGroupMutation,
    isLeaderGuest,
    error,
    isError,
    allGuestsRegistered,
    isLeaderGuestRegistered,
    isCurrentRegisteredGuestsCompleted,
    isAllRegisteredGuestIdentified,
    missingGuests,
    isSuccess,
  };
}

export {useGuestGroup};
