export enum APP_PAGES {
  home = 'HOME',
  summary = 'SUMMARY',
  taxes = 'TAXES',
  taxesAddExemption = 'TAXES_ADD_EXEMPTION',
  deposits = 'DEPOSITS',
  payments = 'PAYMENTS',
  paymentsForm = 'PAYMENTS_FORM',
  addPersonalData = 'ADD_PERSONAL_DATA',
  registerGuest = 'REGISTER_GUEST',
  autoFill = 'AUTO_FILL',
  autoFillUpload = 'AUTO_FILL_UPLOAD',
  verification = 'IV_MAIN',
  verificationChild = 'IV_CHILD',
  allianzSelectPlan = 'ALLIANZ_SELECT_PLAN',
  allianzSelectGuest = 'ALLIANZ_SELECT_GUEST',
  remoteAccess = 'REMOTE_ACCESS',
  bookExperience = 'BOOK_EXPERINCE',
  recommendationsConfirmEmail = 'RECOMMENDATIONS_CONFIRM_EMAIL',
  faq = 'FAQ',
}

export enum APP_FLOWS {
  grOnly = 'GR_ONLY',
  grIvOnly = 'GR_IV_ONLY',
  allFeatures = 'ALL_FEATURES',
}

const VIEWS_HOME_ICON_WITH_MENU_TO_HOME = [
  `${APP_FLOWS.grOnly}_${APP_PAGES.registerGuest}`,
  `${APP_FLOWS.grIvOnly}_${APP_PAGES.registerGuest}`,
  `${APP_FLOWS.allFeatures}_${APP_PAGES.summary}`,
  `${APP_FLOWS.allFeatures}_${APP_PAGES.payments}`,
  `${APP_FLOWS.allFeatures}_${APP_PAGES.payments}_${APP_PAGES.home}`,
];

const VIEWS_HOME_ICON_NO_MENU_TO_HOME = [
  `${APP_FLOWS.allFeatures}_${APP_PAGES.deposits}`,
  `${APP_FLOWS.grIvOnly}_${APP_PAGES.deposits}`,
  `${APP_FLOWS.grOnly}_${APP_PAGES.deposits}`,
];

const VIEWS_X_ICON_NO_MENU_TO_REGISTER = [
  `${APP_FLOWS.grOnly}_${APP_PAGES.addPersonalData}`,
  `${APP_FLOWS.grOnly}_${APP_PAGES.autoFill}`,
  `${APP_FLOWS.grIvOnly}_${APP_PAGES.autoFill}`,
  `${APP_FLOWS.allFeatures}_${APP_PAGES.autoFill}`,
  `${APP_FLOWS.grIvOnly}_${APP_PAGES.verification}`,
  `${APP_FLOWS.allFeatures}_${APP_PAGES.verification}`,
  `${APP_FLOWS.allFeatures}_${APP_PAGES.verificationChild}`,
  `${APP_FLOWS.grIvOnly}_${APP_PAGES.verificationChild}`,
];

const VIEWS_X_ICON_NO_MENU_TO_TAXES = [
  `${APP_FLOWS.allFeatures}_${APP_PAGES.taxesAddExemption}`,
];
const VIEWS_X_ICON_NO_MENU_TO_GUEST_REGISTRATION = [
  `${APP_FLOWS.allFeatures}_${APP_PAGES.allianzSelectPlan}`,
  `${APP_FLOWS.grIvOnly}_${APP_PAGES.allianzSelectPlan}`,
  `${APP_FLOWS.grOnly}_${APP_PAGES.allianzSelectPlan}`,
];

const VIEWS_X_ICON_NO_MENU_TO_PAYMENTS = [
  `${APP_FLOWS.allFeatures}_${APP_PAGES.paymentsForm}`,
];

const VIEWS_X_ICON_NO_MENU_TO_SUMMARY = [
  `${APP_FLOWS.allFeatures}_${APP_PAGES.addPersonalData}`,
];

const VIEWS_BACK_ARROW_WITH_MENU_TO_SUMMARY = [
  `${APP_FLOWS.allFeatures}_${APP_PAGES.registerGuest}`,
  `${APP_FLOWS.allFeatures}_${APP_PAGES.taxes}`,
  `${APP_FLOWS.allFeatures}_${APP_PAGES.deposits}`,
  `${APP_FLOWS.allFeatures}_${APP_PAGES.payments}_${APP_PAGES.summary}`,
  `${APP_FLOWS.allFeatures}_${APP_PAGES.taxes}_${APP_PAGES.payments}`,
];

const VIEWS_BACK_ARROW_WITH_MENU_TO_HOME = [
  `${APP_FLOWS.grIvOnly}_${APP_PAGES.remoteAccess}`,
  `${APP_FLOWS.grOnly}_${APP_PAGES.remoteAccess}`,
  `${APP_FLOWS.allFeatures}_${APP_PAGES.remoteAccess}`,
];

const VIEWS_BACK_ARROW_WITH_MENU_TO_BACK = [
  `${APP_FLOWS.allFeatures}_${APP_PAGES.bookExperience}`,
  `${APP_FLOWS.grIvOnly}_${APP_PAGES.bookExperience}`,
  `${APP_FLOWS.grOnly}_${APP_PAGES.bookExperience}`,

  `${APP_FLOWS.allFeatures}_${APP_PAGES.recommendationsConfirmEmail}`,
  `${APP_FLOWS.grIvOnly}_${APP_PAGES.recommendationsConfirmEmail}`,
  `${APP_FLOWS.grOnly}_${APP_PAGES.recommendationsConfirmEmail}`,
];

const VIEWS_BACK_ARROW_NO_MENU_TO_BACK = [
  `${APP_FLOWS.allFeatures}_${APP_PAGES.autoFillUpload}`,
  `${APP_FLOWS.grIvOnly}_${APP_PAGES.autoFillUpload}`,
  `${APP_FLOWS.grOnly}_${APP_PAGES.autoFillUpload}`,
];

const VIEW_X_ICON_DISCHARD_MODAL_CHANGES = [
  ...VIEWS_X_ICON_NO_MENU_TO_SUMMARY,
  ...VIEWS_X_ICON_NO_MENU_TO_REGISTER,
];

const VIEWS_X_ICON_NO_MENU = [
  ...VIEWS_X_ICON_NO_MENU_TO_TAXES,
  ...VIEWS_X_ICON_NO_MENU_TO_PAYMENTS,
  ...VIEW_X_ICON_DISCHARD_MODAL_CHANGES,
  ...VIEWS_X_ICON_NO_MENU_TO_GUEST_REGISTRATION,
  `${APP_FLOWS.allFeatures}_${APP_PAGES.faq}`,
];

const VIEWS_WITH_BACK_ARROW = [
  ...VIEWS_BACK_ARROW_WITH_MENU_TO_SUMMARY,
  ...VIEWS_BACK_ARROW_NO_MENU_TO_BACK,
  ...VIEWS_BACK_ARROW_WITH_MENU_TO_HOME,
  ...VIEWS_BACK_ARROW_WITH_MENU_TO_BACK,
];

const FEATURE_PAGES_X_ICON_FLOW: {
  [key: string]: string;
} = {
  [APP_PAGES.taxesAddExemption]: 'taxes',
  [APP_PAGES.paymentsForm]: 'payments',
  [APP_PAGES.allianzSelectPlan]: 'travel-insurances/allianz/select-plan',
};

export {
  VIEWS_HOME_ICON_WITH_MENU_TO_HOME,
  VIEWS_X_ICON_NO_MENU_TO_REGISTER,
  VIEWS_X_ICON_NO_MENU_TO_TAXES,
  VIEWS_X_ICON_NO_MENU_TO_PAYMENTS,
  VIEWS_X_ICON_NO_MENU_TO_SUMMARY,
  VIEWS_BACK_ARROW_WITH_MENU_TO_SUMMARY,
  VIEWS_BACK_ARROW_NO_MENU_TO_BACK,
  VIEWS_BACK_ARROW_WITH_MENU_TO_BACK,
  VIEWS_X_ICON_NO_MENU,
  VIEW_X_ICON_DISCHARD_MODAL_CHANGES,
  VIEWS_WITH_BACK_ARROW,
  FEATURE_PAGES_X_ICON_FLOW,
  VIEWS_HOME_ICON_NO_MENU_TO_HOME,
};
