import {Container, BackgroundImage, TextWrapper, Price, Highlight, Title} from './styled';
import {usePathToOfferView} from '../usePathToOfferView';

type Props = {
  id: string;
  title: string;
  price: string;
  highlight: string;
  imageUrl: string;
  className?: string;
};
const ShortOfferCard = ({className, id, title, price, highlight, imageUrl}: Props) => {
  const {getFullPathToOfferView} = usePathToOfferView();
  return (
    <Container to={getFullPathToOfferView(id)} className={className}>
      <TextWrapper>
        <Title>{title}</Title>
        <Price>{price}</Price>
        <Highlight>{highlight}</Highlight>
      </TextWrapper>
      <BackgroundImage src={imageUrl} />
    </Container>
  );
};

export {ShortOfferCard};
