import styled from 'styled-components';
import {Accordion} from 'react-accessible-accordion';
import {device} from '../../styled/device';
import {DisplayIcon} from 'components/common/Select';

export const AccordionStyled = styled(Accordion)`
  & .accordion__heading {
    display: flex;
    align-items: center;
    font-weight: ${props => props.theme.fonts.fontWeight.$mediumL};
    font-size: ${props => props.theme.fonts.sizes.small};
    cursor: pointer;
    border-top: 1px solid ${({theme}) => theme.colors.$n7};
    transition: height ease 0.2s;
  }

  & .accordion__item:first-child > .accordion__heading {
    border-top: none;
  }

  & .accordion__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 16px 0;

    @media (min-width: ${device.mobileXL}) {
      padding: 24px 0;
    }
  }

  [hidden] {
    display: none;
  }

  .accordion__panel {
    animation: fadein 0.35s ease-in;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const Icon = styled(DisplayIcon)`
  width: 12px;
  height: auto;
  margin: 0 0 0 auto;
  min-width: initial;
`;
