import {HousingApi} from '../api';
import {FetcherSettings} from '../api/apiClient';
import {SearchReservationPayload} from './types';

class HousingService {
  housingApi: HousingApi;

  constructor() {
    this.housingApi = new HousingApi();
  }

  getOne(id: string, resolverSettings?: FetcherSettings) {
    return this.housingApi.getOne(id, resolverSettings);
  }

  searchReservation(
    id: string,
    payload: SearchReservationPayload,
    resolverSettings?: FetcherSettings,
  ) {
    return this.housingApi.searchReservation(id, payload, resolverSettings);
  }

  getLocation(resolverSettings?: FetcherSettings) {
    return this.housingApi.getLocation(resolverSettings);
  }
}

export {HousingService};
