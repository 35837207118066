enum UPSELLING_LINKS {
  root = '/upselling',
  custom = '/upselling/custom',
  activities = '/upselling/activities',
}

enum UPSELLING_MARKETPLACE_ORIGINS {
  servantrip = 'SERVANTRIP',
}

enum UPSELLING_PAYMENTS_STATUSES {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
}

enum OFFER_TEMPLATES {
  custom = 'CUSTOM',
  checkIn = 'EARLY_CHECK_IN',
  checkOut = 'LATE_CHECK_OUT',
}

enum OFFER_PRICE_TYPES {
  single = 'SINGLE_PRICE',
  multiple = 'MULTIPLE_PRICE',
  // dateTime = 'DATA_AND_TIME_RANGE_PRICE',
}

enum OFFER_PRICES_FIELD {
  price = 'price',
  unit = 'unit_type',
  name = 'name',
  day = 'day',
}

enum UNIT_PRICE_TYPES {
  unit = 'PER_UNIT',
  day = 'PER_DAY',
  person = 'PER_PERSON',
  group = 'PER_GROUP',
}

enum DEAL_STATUSES {
  requested = 'REQUESTED',
  approved = 'APPROVED',
  rejected = 'REJECTED',
  paid = 'PAID',
}

enum OFFER_CATEGORIES {
  checkInCheckOut = 'CHECK_IN_CHECK_OUT',
  transportation = 'TRANSPORTATION',
  fAndB = 'F_AND_B',
  seasonal = 'SEASONAL',
  family = 'FAMILY',
  romantic = 'ROMANTIC',
  healthAndSpa = 'HEALTH_AND_SPA',
  artAndCulture = 'ART_AND_CULTURE',
  activities = 'ACTIVITIES',
  services = 'SERVICES',
  propertyProtection = 'PROPERTY_PROTECTION',
  others = 'OTHERS',
}

enum SERVANTRIP_CATEGORIES {
  ball_sport = 'ball_sport',
  combat_sport = 'combat_sport',
  sport_competition = 'sport_competition',
  extreme_sport = 'extreme_sport',
  mountain_sport = 'mountain_sport',
  water_sport = 'water_sport',
  other_sport = 'other_sport',
  city_tour = 'city_tour',
  extraordinary_tour = 'extraordinary_tour',
  history_nature_tour = 'history_nature_tour',
  tour_on_wheels = 'tour_on_wheels',
  walking_tour = 'walking_tour',
  tour_sport = 'tour_sport',
  gastronomic_experience = 'gastronomic_experience',
  nightlife = 'nightlife',
  performance = 'performance',
  them_park = 'them_park',
  travel_for_cause = 'travel_for_cause',
  unique_experience = 'unique_experience',
  attraction_monument = 'attraction_monument',
  excursion = 'excursion',
}

enum OFFER_CONFIRMATION_TYPES {
  auto = 'AUTO',
  manual = 'MANUAL',
}

enum OFFER_LABELS {
  selected = 'selected',
  requested = 'requested',
}

const CATEGORIES_WITH_ADDRESS = [OFFER_CATEGORIES.others];

enum THIRD_PARTY_ACTIONS {
  lockActivity = 'LOCK_ACTIVITY',
  refundPayment = 'REFUND_PAYMENT',
}

enum THIRD_PARTY_ACTION_STATUSES {
  new = 'NEW',
  processing = 'PROCESSING',
  performed = 'PERFORMED',
  failed = 'FAILED',
}

enum THIRD_PARTY_ACTION_INITIATORS {
  host = 'HOST',
  guest = 'GUEST',
  provider = 'PROVIDER',
}

enum THIRD_PARTY_ACTION_SUPPLIERS {
  servantrip = 'SERVANTRIP',
}

enum THIRD_PARTY_DEAL_STATUSES {
  new = 'NEW',
  locked = 'LOCKED',
  lockError = 'LOCK_ERROR',
  lockExpired = 'LOCK_EXPIRED',
  paid = 'PAID',
  booked = 'BOOKED',
  refundInProgress = 'REFUND_IN_PROGRESS',
  refundError = 'REFUND_ERROR',
  refunded = 'REFUNDED',
  cancelError = 'CANCEL_ERROR',
  canceled = 'CANCELED',
}

enum DISPLAYED_OFFER_CATEGORIES {
  sports = 'sports',
  tours = 'tours',
  foodAndDrink = 'food_and_drink',
  entertainment = 'entertainment',
  artAndCulture = 'art_and_culture',
  natureAndOutdoors = 'nature_and_outdoors',
  stayServices = 'stay_services',
  wellness = 'wellness',
  others = 'others',
}

export {
  UPSELLING_LINKS,
  CATEGORIES_WITH_ADDRESS,
  OFFER_LABELS,
  UPSELLING_PAYMENTS_STATUSES,
  OFFER_TEMPLATES,
  OFFER_PRICE_TYPES,
  OFFER_PRICES_FIELD,
  UNIT_PRICE_TYPES,
  DEAL_STATUSES,
  OFFER_CATEGORIES,
  SERVANTRIP_CATEGORIES,
  OFFER_CONFIRMATION_TYPES,
  THIRD_PARTY_ACTIONS,
  THIRD_PARTY_ACTION_STATUSES,
  UPSELLING_MARKETPLACE_ORIGINS,
  THIRD_PARTY_ACTION_INITIATORS,
  THIRD_PARTY_ACTION_SUPPLIERS,
  THIRD_PARTY_DEAL_STATUSES,
  DISPLAYED_OFFER_CATEGORIES,
};
