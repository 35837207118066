import styled, {css} from 'styled-components';
import {device} from 'styled/device';

export const Box = styled.div<{$readOnly?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 24px 0 16px;
  gap: 16px;

  :last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);

    @media (max-width: ${device.tablet}) {
      border-bottom: none;
    }
  }

  @media (max-width: ${device.tablet}) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: ${device.mobileXL}) {
    flex-direction: row;
    justify-content: space-between;
    /* padding: 0 30px;
    width: 80%; */
  }

  &&& {
    ${({$readOnly}) =>
      $readOnly &&
      css`
        justify-content: flex-start;
        gap: 8px;

        ${Details},
        ${Boxes} {
          width: auto;
          margin-bottom: 0;
        }

        ${Details} {
          padding: 0;
        }
      `};
  }
`;
export const Details = styled.div`
  align-items: center;
  justify-content: center;
  padding-right: 24px;
  /* width: 50%; */

  @media (max-width: ${device.tablet}) {
    padding-right: 0;
  }

  @media (min-width: ${device.tablet}) {
    text-align: left;
  }
`;
export const Title = styled.div`
  font-size: ${props => props.theme.fonts.sizes.medium};
  line-height: 24px;
  text-align: start;
  color: ${props =>
    props.theme.hasTemplate ? props.theme.colors.branding.fontPrimary : '#152946'};
  @media (max-width: ${device.tablet}) {
    text-align: center;
  }
  @media (max-width: ${device.mobileXL}) {
    text-align: start;
  }
`;
export const Subtitle = styled.div`
  font-size: ${props => props.theme.fonts.sizes.small};
  font-weight: ${props => props.theme.fonts.fontWeight.regular};
  line-height: 21px;
  color: ${props => props.theme.colors.branding.fontPrimary};
  @media (max-width: ${device.tablet}) {
    text-align: center;
  }
  @media (max-width: ${device.mobileXL}) {
    text-align: start;
  }
`;

export const Boxes = styled.div``;
