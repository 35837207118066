import {Trans, useTranslation} from 'react-i18next';
import {IV_DOCUMENT_IMAGE_TYPES} from 'utils/constants';
import {PATHS} from '../../../../Routes';
import Button from '../../../../components/common/Button';
import MRZPageLayout from '../../MRZPageLayout';
import {ModalTwoButtonsWrapper} from '../../../../styled/common';
import {BottomBlock, HelperText, StyledLottie, Wrapper} from './styled';
import {useFetchIVLottie} from 'hooks';
// import passportGif from 'assets/img/passport.gif';

function MRZStartView() {
  const {t} = useTranslation();
  const {animationData} = useFetchIVLottie({
    type: IV_DOCUMENT_IMAGE_TYPES.front,
    isOrc: false,
  });
  return (
    <MRZPageLayout title={t('guest_data')}>
      <Wrapper className="mrz-start-view">
        <HelperText className="mrz-start-view__helper">
          <Trans i18nKey="document_detection_helper_text" />
        </HelperText>
        <StyledLottie animationData={animationData} />
        <BottomBlock className="mrz-start-view__footer">
          <ModalTwoButtonsWrapper className="mrz-start-view__buttons">
            <Button
              label={t('take_a_photo')}
              to={`../${PATHS.documentMRZ}/take`}
              outlined
            />
            <Button
              label={t('upload_file')}
              to={`../${PATHS.documentMRZ}/upload`}
              outlined
            />
          </ModalTwoButtonsWrapper>
        </BottomBlock>
      </Wrapper>
    </MRZPageLayout>
  );
}

export {MRZStartView};
