import React, {ComponentType, CSSProperties} from 'react';
import {GroupBase, MenuListProps} from 'react-select';
import {useTranslation} from 'react-i18next';
import {ListChildComponentProps} from 'react-window';
import {SelectOptionType} from '@guestapp/sdk';
import {VirtualizedList} from './VirtualizedList';
import {MenuItem, MenuItemLoadingMessage, MenuMessage} from './styled';

const ROW_HEIGHT = 28;
const LIST_HEIGHT = 246;
const LIST_WIDTH = '100%';
const LIST_PADDING = 35;

const MenuList = <T, V, L>({
  options = [],
  ...props
}: MenuListProps<
  SelectOptionType<T, V, L>,
  false,
  GroupBase<SelectOptionType<T, V, L>>
> & {
  children: React.ReactNode;
}) => {
  const {t} = useTranslation();
  const {
    isLoading = false,
    loadMoreItems,
    canLoadMore,
    listSizes,
    noOptionsMessage,
    mobileVersion,
    listAutoSizer,
  } = props.selectProps;

  const getListSizes = React.useCallback(
    () => ({
      itemSize: listSizes?.itemSize ?? ROW_HEIGHT,
      width: listSizes?.width ?? LIST_WIDTH,
      height: listSizes?.height ?? LIST_HEIGHT,
    }),
    [listSizes?.height, listSizes?.itemSize, listSizes?.width],
  );

  const childrenArray = React.Children.toArray(props.children);
  const itemCount = canLoadMore ? Number(options?.length) + 1 : Number(options?.length);
  const realSize = itemCount * getListSizes().itemSize + LIST_PADDING;
  const dynamicalListHeight =
    getListSizes().height < realSize ? getListSizes().height : realSize;

  const Row: ComponentType<ListChildComponentProps> = React.useCallback(
    ({index, style, ...rest}) => {
      const child = childrenArray[index];
      const isLastChild = index + 1 === childrenArray.length;
      const menuStyles = style as CSSProperties;

      if (!isLoading && !child && childrenArray.length) {
        return (
          <MenuItemLoadingMessage {...rest} style={menuStyles}>
            {t('loading')}...
          </MenuItemLoadingMessage>
        );
      }

      return (
        <MenuItem $isLastChild={isLastChild} {...rest} style={menuStyles}>
          {child}
        </MenuItem>
      );
    },
    [childrenArray, isLoading, t],
  );

  if (isLoading) {
    return <MenuMessage>{t('loading')}...</MenuMessage>;
  }

  if (!itemCount) {
    const noMessageText = noOptionsMessage?.({inputValue: ''}) || t('no_matches_found');

    return <MenuMessage>{noMessageText}</MenuMessage>;
  }

  return (
    <VirtualizedList
      canLoadMore={canLoadMore}
      isMoreItemsLoading={isLoading}
      loadMoreItems={loadMoreItems}
      options={options}
      itemCount={itemCount}
      height={dynamicalListHeight}
      itemSize={getListSizes().itemSize}
      width={getListSizes().width}
      listAutoSizer={listAutoSizer}
      mobileVersion={mobileVersion}
    >
      {Row}
    </VirtualizedList>
  );
};

export {MenuList};
