import React from 'react';
import {useTranslation} from 'react-i18next';
import {ModalSheet} from 'components/common/ModalSheet/ModalSheet';
import {useForm} from 'react-hook-form';
import {PATTERNS} from '@guestapp/core';
import Input from 'components/common/Input';
import {
  ConfirmButton,
  Container,
  ContainerButton,
  ContainerInformation,
  Subtitle,
  Title,
  ConfirmForm,
  Info,
} from './styled';

type FormTypes = {
  email: string;
};
type Props = {
  bookExperienceEmail?: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (email: string) => void;
};
function EnterContactEmailModal({
  isOpen,
  onClose,
  onConfirm,
  bookExperienceEmail,
}: Props) {
  const {t} = useTranslation();

  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm<FormTypes>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      email: bookExperienceEmail,
    },
  });

  const currentEmail = watch('email');

  const onSubmit = ({email}: FormTypes) => {
    onConfirm(email);
  };

  return (
    <ModalSheet
      isOpenModalSheet={isOpen}
      handleCloseModal={onClose}
      withCloseButton
      closeButtonType="click"
    >
      <Container>
        <ContainerInformation>
          <Info>
            <Title>{t('enter_contact_email_modal.title')}</Title>
            <Subtitle>{t('enter_contact_email_modal.message')}</Subtitle>
          </Info>
          <ConfirmForm onSubmit={handleSubmit(onSubmit)}>
            <Input
              {...register('email', {
                required: t('required'),
                pattern: {
                  value: PATTERNS.email,
                  message: t('invalid_email'),
                },
              })}
              label={t('email')}
              inputMode="email"
              autoCorrect="off"
              spellCheck={false}
              autoCapitalize="none"
              error={errors['email']?.message}
            />
            <ContainerButton>
              <ConfirmButton
                disabled={!currentEmail}
                type="submit"
                label={t('confirm')}
              />
            </ContainerButton>
          </ConfirmForm>
        </ContainerInformation>
      </Container>
    </ModalSheet>
  );
}

export {EnterContactEmailModal};
