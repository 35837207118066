import {refreshFetch} from '../refreshFetch';
import {RequestOptions, ResponseError} from './types';
import {
  checkIsPlainError,
  checkIsResponseError,
  defaultRetryDelay,
  getAndHandleResponseError,
  getURL,
  shouldRetry,
  sleep,
} from './utils';

async function fetcher<T>(
  endpoint: string,
  options: RequestOptions = {},
): Promise<{body: T; headers: Headers; response: Response}> {
  const {externalURL, retryCount = 0} = options;

  try {
    const url = externalURL || getURL(endpoint);

    const {response, body} = await refreshFetch(url, options);

    return {body: body as T, headers: response.headers, response};
  } catch (error) {
    if (
      (checkIsResponseError(error) || checkIsPlainError(error)) &&
      shouldRetry(options, error)
    ) {
      await sleep(defaultRetryDelay(retryCount));
      return fetcher(endpoint, {
        ...options,
        retryCount: retryCount + 1,
      });
    }

    const responseError = getAndHandleResponseError(
      (error as ResponseError<T>).response,
      (error as ResponseError<T>).body,
      (error as Error)?.message,
    );

    if (responseError) {
      throw responseError;
    }

    throw (error as ResponseError<T>).body;
  }
}

type ApiLayerOptions<T> = RequestOptions & {
  json?: T;
};
export const apiClient = {
  get: <T>(route: string, options?: RequestOptions) => {
    return fetcher<T>(route, {
      method: 'GET',
      ...options,
    });
  },
  post: <T, P = unknown>(
    route: string,
    {body, json, ...options}: ApiLayerOptions<P> = {},
  ) => {
    return fetcher<T>(route, {
      method: 'POST',
      body: json ? JSON.stringify(json) : body,
      ...options,
    });
  },
  put: <T, P = unknown>(
    route: string,
    {body, json, ...options}: ApiLayerOptions<P> = {},
  ) => {
    return fetcher<T>(route, {
      method: 'PUT',
      body: json ? JSON.stringify(json) : body,
      ...options,
    });
  },
  patch: <T, P = unknown>(
    route: string,
    {body, json, ...options}: ApiLayerOptions<P> = {},
  ) => {
    return fetcher<T>(route, {
      method: 'PATCH',
      body: json ? JSON.stringify(json) : body,
      ...options,
    });
  },
  delete: <T>(route: string, {body, ...options}: RequestOptions = {}) => {
    return fetcher<T>(route, {
      method: 'DELETE',
      ...options,
    });
  },
};
