import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {useChekinSDK} from 'context/ChekinSDK';
import {useFrame} from '../context/frame';
import {useCustomTheme} from 'context/customTheme';
import {useSummary} from 'context/summary';
import {getErrorMessage, LocalStorage, SessionStorage} from '@guestapp/core';
import {PATHS} from '../Routes';
import {ERR_INTERNET_DISCONNECTED_MESSAGE} from '@guestapp/sdk';
import {environments} from '../configs';
import {ErrorModalProps} from 'utils/types';
import errorIcon from 'assets/icons/error.svg';
import Modal from 'components/Modal';
import {ModalButton} from 'styled/common';

function useModalControls(initState = false) {
  const [isOpen, setIsOpen] = React.useState(initState);

  const openModal = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggleModal = React.useCallback(() => {
    setIsOpen(prevState => !prevState);
  }, []);

  return {
    isOpen,
    openModal,
    closeModal,
    toggleModal,
    setIsOpen,
  };
}

function useTimeoutRef() {
  const timeoutIdRef = React.useRef<ReturnType<typeof setTimeout>>();

  const resetTimeout = React.useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
  }, []);

  React.useEffect(() => {
    return () => {
      resetTimeout();
    };
  }, [resetTimeout]);

  return timeoutIdRef;
}

const eventName = 'resize';
function useScreenResize(maxWidth: string) {
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [isMatch, setIsMatch] = React.useState(false);
  const {window} = useFrame();

  const handleResizeEvent = React.useCallback(() => {
    const media = window.matchMedia(`(max-width: ${maxWidth})`);

    setIsInitialized(true);
    setIsMatch(media.matches);
  }, [window, maxWidth]);

  React.useLayoutEffect(() => {
    handleResizeEvent();
    window.addEventListener(eventName, handleResizeEvent);
    return () => {
      window.removeEventListener(eventName, handleResizeEvent);
    };
  }, [window, handleResizeEvent]);

  return {isMatch, isInitialized};
}

function useHandlerDelay<T extends (...args: Parameters<T>) => void>(
  callback: T,
  active: boolean,
  delayMs: number,
) {
  const isHandlerAllowedRef = React.useRef(false);
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();

  React.useEffect(() => {
    if (!active) {
      isHandlerAllowedRef.current = false;
    } else {
      timeoutRef.current = setTimeout(() => {
        isHandlerAllowedRef.current = true;
        clearTimeout(timeoutRef.current);
      }, delayMs);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [active, delayMs]);

  const handler = React.useCallback(
    (...args: Parameters<T>) => {
      if (isHandlerAllowedRef.current) {
        isHandlerAllowedRef.current = false;
        callback(...args);
      }
    },
    [callback],
  );

  return handler;
}

function useLockScrollBody(needLock?: boolean) {
  const {document} = useFrame();

  const lockScroll = React.useCallback(() => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  }, [document]);

  const resetScroll = React.useCallback(() => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }, [document]);

  React.useLayoutEffect(() => {
    if (needLock) {
      lockScroll();
    } else {
      resetScroll();
    }
  }, [lockScroll, needLock, resetScroll]);

  return {resetScroll, lockScroll};
}

function useIsFirstRender(): boolean {
  const isFirst = React.useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}

type UseModalControlsOptions = {
  key: string;
  enabled?: boolean;
  initState?: boolean;
};
function useModalWithHistoryControls({
  key,
  initState = false,
  enabled = true,
}: UseModalControlsOptions) {
  const navigate = useNavigate();
  const location = useLocation();
  const isFirstRender = useIsFirstRender();
  const [isOpen, setIsOpen] = React.useState(initState);
  const {window: iframeWindow} = useFrame();
  const scrollRef = React.useRef(0);
  const resetDelayRef = React.useRef(false);
  const resetDelayTimeoutRef = useTimeoutRef();

  const addHash = React.useCallback(() => {
    if (enabled && location?.state?.popupKey !== key) {
      scrollRef.current = iframeWindow.scrollY;
      navigate(location.pathname, {
        ...location.state,
        popupKey: key,
        isPopupOpen: true,
      });
      iframeWindow.scrollTo(0, scrollRef.current);
    }
  }, [iframeWindow, enabled, location?.state, location?.pathname, key, navigate]);

  const openModal = React.useCallback(() => {
    setIsOpen(true);
    addHash();
  }, [addHash]);

  const resetHash = React.useCallback(() => {
    if (enabled && location.state?.popupKey === key && !resetDelayRef.current) {
      navigate(-1);

      resetDelayRef.current = true;

      resetDelayTimeoutRef.current = setTimeout(() => {
        resetDelayRef.current = false;
      }, 300);
    }
  }, [enabled, location.state?.popupKey, key, navigate, resetDelayTimeoutRef]);

  const closeModal = React.useCallback(() => {
    setIsOpen(false);
    resetHash();
  }, [resetHash]);

  const toggleModal = React.useCallback(() => {
    setIsOpen(!isOpen);
    !isOpen ? addHash() : resetHash();
  }, [addHash, isOpen, resetHash]);

  React.useEffect(() => {
    const handler = () => {
      if (enabled && isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener('popstate', handler, false);
    return () => {
      window.removeEventListener('popstate', handler, false);
    };
  }, [enabled, isOpen]);

  React.useEffect(
    function resetDeprecatedState() {
      if (isFirstRender && !isOpen && location.state?.popupKey === key) {
        navigate(-1);
      }
    },
    [navigate, isFirstRender, isOpen, key, location.state?.popupKey],
  );

  return {
    isOpen,
    openModal,
    closeModal,
    toggleModal,
    setIsOpen,
  };
}

type UseKeyDownHandlerProps = {
  keyCode: number;
  onKeyDown: () => void;
  enabled?: boolean;
};
function useKeyDownHandler({keyCode, onKeyDown, enabled}: UseKeyDownHandlerProps) {
  const {document} = useFrame();

  const handler = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === keyCode) {
        onKeyDown();
      }
    },
    [onKeyDown, keyCode],
  );

  React.useEffect(() => {
    if (!enabled) return;
    document.addEventListener('keydown', handler, false);
    return () => {
      document.removeEventListener('keydown', handler, false);
    };
  }, [document, enabled, handler]);
}

function useOutsideClick<T extends Element>(
  elementRef: React.RefObject<T> | null,
  onOutsideClick: ((event: Event) => void) | null,
  nested?: string[] | string,
) {
  const {document} = useFrame();
  const handleOutsideClick = React.useRef(onOutsideClick);
  handleOutsideClick.current = onOutsideClick;

  const checkNestedElements = React.useCallback(
    (event: Event) => {
      const checkIsElementClickedBySelector = (selector: string) => {
        const nestedElement = document.querySelector(selector);
        return nestedElement?.contains(event.target as Node);
      };

      if (!nested) return false;
      if (typeof nested === 'string') {
        return checkIsElementClickedBySelector(nested);
      }

      return nested.some(checkIsElementClickedBySelector);
    },
    [document, nested],
  );

  React.useEffect(() => {
    function handleClickOutside(event: Event) {
      const isNestedElement = checkNestedElements(event);

      if (
        elementRef?.current &&
        !elementRef.current.contains(event.target as Node) &&
        !isNestedElement
      ) {
        handleOutsideClick.current?.(event);
      }
    }

    document.addEventListener('mousedown', handleClickOutside, true);
    document.addEventListener('touchstart', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
      document.removeEventListener('touchstart', handleClickOutside, true);
    };
  }, [checkNestedElements, document, elementRef]);
}

export enum RouteType {
  iv = 'iv',
  general = 'general',
}

const makeToString = (to?: string) => {
  if (!to) {
    return '';
  }

  return to.startsWith('/') ? to : `/${to}`;
};

function usePath() {
  const {mainToken} = useChekinSDK();
  const mainPart = environments.REACT_APP_IS_EMBEDDED ? '' : `/${mainToken}`;

  const getIVPath = React.useCallback(
    (to: string) => {
      const ivRoute =
        to === PATHS.verification.main ? to : `${PATHS.verification.main}/${to}`;
      return `${mainPart}/${ivRoute}`;
    },
    [mainPart],
  );

  const getTaxesPath = React.useCallback(
    (to: string) => {
      const ivRoute = to === PATHS.taxes.main ? to : `${PATHS.taxes.main}/${to}`;
      return `${mainPart}/${ivRoute}`;
    },
    [mainPart],
  );

  const getMainPath = React.useCallback(
    (to = '') => {
      if (to.includes('token')) {
        return `/${mainToken || ''}`;
      }
      const toString = makeToString(to);

      return `${mainPart}${toString}`;
    },
    [mainPart, mainToken],
  );

  const getPaymentsPath = React.useCallback(
    (to: string) => {
      const paymentsRoute =
        to === PATHS.payments.main ? to : `${PATHS.payments.main}/${to}`;
      return `${mainPart}/${paymentsRoute}`;
    },
    [mainPart],
  );

  const getFullPath = React.useCallback(
    (to: string) => {
      const ivPathsArray = Object.values(PATHS.verification);
      const taxesPathsArray = Object.values(PATHS.taxes);
      const paymentsPathsArray = Object.values(PATHS.payments);
      const isPaymentsPath = paymentsPathsArray.includes(to);
      const isIVPath = ivPathsArray.includes(to);
      const isTaxesPath = taxesPathsArray.includes(to);

      if (isIVPath) {
        return getIVPath(to);
      } else if (isTaxesPath) {
        return getTaxesPath(to);
      } else if (isPaymentsPath) {
        return getPaymentsPath(to);
      } else {
        return getMainPath(to);
      }
    },
    [getIVPath, getMainPath, getTaxesPath, getPaymentsPath],
  );

  return {getIVPath, getMainPath, getFullPath};
}

function useIsMounted() {
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
}

type DisplayErrorDetails = {
  title?: string;
  message?: string;
  description?: string;
};

// function useInternetStatus() {
//   const [isOnline, setIsOnline] = React.useState(navigator.onLine);

//   React.useEffect(() => {
//     function handleOnlineStatus() {
//       setIsOnline(true);
//     }

//     function handleOfflineStatus() {
//       setIsOnline(false);
//     }

//     window.addEventListener('online', handleOnlineStatus);
//     window.addEventListener('offline', handleOfflineStatus);

//     return () => {
//       window.removeEventListener('online', handleOnlineStatus);
//       window.removeEventListener('offline', handleOfflineStatus);
//     };
//   }, []);

//   return {isOnline};
// }

function useErrorModal() {
  const {t} = useTranslation();
  const isMounted = useIsMounted();
  const {
    isOpen: isErrorModalOpen,
    openModal: openErrorModal,
    closeModal: closeErrorModal,
  } = useModalControls();
  // const {isOnline} = useInternetStatus();

  const [errorTitle, setErrorTitle] = React.useState(
    t('service_temporarily_unavailable'),
  );
  const [errorMessage, setErrorMessage] = React.useState<
    string | ReactElement | ReactElement[]
  >('');
  const [errorDescription, setErrorDescription] = React.useState<string | ReactElement>(
    '',
  );
  const [isConnectionIssue, setIsConnectionIssue] = React.useState(false);

  const displayError = React.useCallback(
    // TODO Any type. Should be fixed
    // eslint-disable-next-line
    (error: any = {}, {title, message, description}: DisplayErrorDetails = {}) => {
      const isConnectionIssue = error.name === ERR_INTERNET_DISCONNECTED_MESSAGE;
      setIsConnectionIssue(isConnectionIssue);
      if (!isMounted.current) {
        return;
      }

      const errorMessage = message || getErrorMessage(error);
      const errorTitle =
        title || error.status <= 500
          ? t(title ?? 'error')
          : isConnectionIssue
          ? t('no_internet_connection')
          : null;
      const errorDescription = isConnectionIssue
        ? t('no_internet_description')
        : description || '';

      if (errorMessage) setErrorMessage(message || errorMessage);
      if (errorDescription) setErrorDescription(errorDescription);
      if (errorTitle) setErrorTitle(errorTitle);
      openErrorModal();
    },
    [isMounted, openErrorModal, t, setIsConnectionIssue],
  );

  const ErrorModal = React.useCallback(
    ({
      open,
      onClose,
      title,
      message,
      description,
      children,
      iconSrc,
      iconAlt,
      iconProps,
    }: ErrorModalProps) => {
      const displayedTitle = errorTitle || title;
      const displayedMessage = errorMessage || message;
      const displayedDescription = errorDescription || description;

      const getTextContent = () => {
        if (displayedMessage) {
          return (
            <span>
              {displayedMessage}
              <p />
              {displayedDescription}
            </span>
          );
        }

        return (
          <span>
            {t('we_found_error')}
            <p />
            {t('try_again_or_contact')}
          </span>
        );
      };

      return (
        <Modal
          open={open !== undefined ? open : isErrorModalOpen}
          title={displayedTitle}
          text={getTextContent()}
          iconSrc={iconSrc || errorIcon}
          iconAlt={iconAlt || 'Exclamation error mark'}
          iconProps={iconProps || {width: 180, height: 180}}
          onClose={closeErrorModal}
          withCloseButton
          isConnectionIssue={isConnectionIssue}
          className="error-modal"
        >
          {children || (
            <ModalButton label={t('ok')} onClick={onClose || closeErrorModal} />
          )}
        </Modal>
      );
    },
    [
      isErrorModalOpen,
      errorDescription,
      closeErrorModal,
      errorMessage,
      errorTitle,
      t,
      isConnectionIssue,
    ],
  );

  return {
    displayError,
    closeErrorModal,
    openErrorModal,
    ErrorModal,
  };
}

const buildLocalStorageData = (id: string, showed: boolean) => ({id, showed});

type useModalOncePerSessionProps = {
  id?: string;
  storageKey?: string;
  storageType?: 'Local' | 'Session';
};
function useModalOncePerSession({
  id,
  storageKey,
  storageType = 'Local',
}: useModalOncePerSessionProps) {
  const [key, setKey] = React.useState(storageKey && id ? `${storageKey}-${id}` : '');
  const Storage = storageType === 'Local' ? LocalStorage : SessionStorage;
  const {isTemplateInitialized} = useCustomTheme();
  const {isSuccess} = useSummary();

  const shouldOpenModal = React.useMemo(() => {
    if (isTemplateInitialized && key && isSuccess) {
      if (id) {
        const alreadyOpenModal =
          Storage.get<{id?: string; showed?: boolean}>(key) ||
          buildLocalStorageData(id, false);

        if (alreadyOpenModal?.id !== id || !alreadyOpenModal?.showed) {
          LocalStorage.set(key, buildLocalStorageData(id, true));
          return true;
        }
      }
    }

    return false;
  }, [key, id, Storage, isTemplateInitialized, isSuccess]);

  const setStorageKey = React.useCallback((storageKey: string) => {
    setKey(storageKey);
  }, []);

  return {shouldOpenModal, setStorageKey};
}

export {
  useModalControls,
  useTimeoutRef,
  useScreenResize,
  useHandlerDelay,
  useLockScrollBody,
  useModalWithHistoryControls,
  useKeyDownHandler,
  useOutsideClick,
  usePath,
  useIsMounted,
  useErrorModal,
  // useInternetStatus,
  useModalOncePerSession,
};
