import {createContext, useContext} from 'react';

let doc;
let win;
if (typeof document !== 'undefined') {
  doc = document;
}
if (typeof window !== 'undefined') {
  win = window;
}

type ContextProps = {
  document: Document;
  window: Window;
  frame?: HTMLIFrameElement;
};
export const FrameContext = createContext<ContextProps>({
  document: doc || document,
  window: win || window,
  frame: undefined,
});

export const {Provider: FrameContextProvider, Consumer: FrameContextConsumer} =
  FrameContext;

export const useFrame = () => useContext(FrameContext);
