import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LocalStorage} from '@guestapp/core';
import {SHARE_LINK} from 'utils/constants';
import {shareByEmail, shareBySMS, shareByWhatsapp} from './utils';
import {copyToClipboard} from 'utils/common';
import {useSummary} from 'context/summary';
import {useTimeoutRef} from 'hooks';
import {
  Title,
  Wrapper,
  InputContainer,
  LinkCopiedText,
  OrShareWithText,
  StyledButton,
  StyledCustomIcon,
  ContentCustomIcon,
  StyledInput,
} from './styled';

const copiedLinkTimeoutS = 2;

function ShareRegistrationLink() {
  const {t} = useTranslation();
  const {housingName} = useSummary();
  const link = LocalStorage.get<string>(SHARE_LINK) || '';
  const timeoutRef = useTimeoutRef();
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const copyShareLinkToClipboard = () => {
    if (!link) return;
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    copyToClipboard(link);
    setIsLinkCopied(true);
    timeoutRef.current = setTimeout(() => {
      setIsLinkCopied(false);
    }, copiedLinkTimeoutS * 1000);
  };

  return (
    <Wrapper className="share-link-modal">
      <Title className="share-link-modal__title">
        {t('share_registration_link_title')}
      </Title>
      <InputContainer className="share-link-modal__link">
        <StyledInput
          autoCorrect="off"
          spellCheck={false}
          value={link}
          autoFocus={false}
          disabled
        />
        <ContentCustomIcon onClick={copyShareLinkToClipboard}>
          <StyledCustomIcon name="copyLink" />
        </ContentCustomIcon>
        {isLinkCopied && <LinkCopiedText>{t('link_copied')}</LinkCopiedText>}
      </InputContainer>
      <OrShareWithText>{t('or_share_by')}:</OrShareWithText>
      <StyledButton
        label={t('whatsapp')}
        onClick={() => shareByWhatsapp(housingName)}
        outlined
      />
      <StyledButton label={t('sms')} onClick={() => shareBySMS(housingName)} outlined />
      <StyledButton
        label={t('email')}
        onClick={() => shareByEmail(housingName)}
        outlined
      />
    </Wrapper>
  );
}

export {ShareRegistrationLink};
