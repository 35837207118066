import styled, {css} from 'styled-components';
import {device} from 'styled/device';
import Input from 'components/common/Input';
import Select, {SelectForwardType} from 'components/common/Select';
import Tooltip from '../Tooltip';
import QuestionMarkButton from 'components/common/QuestionMarkButton';
import {Fieldset} from '@guestapp/ui';

type DateInputsWrappers = {
  isUSAFormat?: boolean;
  isFocused?: boolean;
};

export const Icon = styled.img`
  width: 24px;
  margin-right: 15px;
`;

type WrapperProps = {
  disabled?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  cursor: text;

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    `};

  @media (max-width: ${device.mobileS}) {
    width: 100%;
  }
`;

export const DateInput = styled(Input)`
  & input {
    padding-right: 5px;
    &::placeholder {
      color: #777e91;
    }
  }

  @media (max-width: ${device.mobileS}) {
    width: 100%;
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: flex-end;
`;

export const DayInputWrapper = styled.div<DateInputsWrappers>`
  & ${DateInput} .input__input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  & fieldset {
    border: none;
    ${props =>
      !props.isUSAFormat &&
      css`
        margin-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      `};
    ${props =>
      props.isUSAFormat &&
      css`
        margin-right: 0;
        border-radius: 0;
      `};
  }
`;

export const YearInputWrapper = styled.div`
  & ${DateInput} .input__input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  & fieldset {
    border: none;
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  @media (max-width: ${device.mobileM}) {
    width: 100%;
  }
`;

export const DateSelectWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

export const DateSelect = styled(Select)`
  min-height: 36px;
  min-width: 0;

  & .select {
    height: 36px !important;
  }

  & .select__value-container {
    padding-bottom: 0 !important;
  }

  & .select__value-container > div {
    font-size: 16px;
    margin: 0 !important;
  }

  & .select__input input {
    width: 100px !important;
  }

  & .select__input {
    top: -4px !important;
  }

  & .select__menu,
  & .select__menu-list {
    width: 225px;
  }

  & .select__control {
    min-height: unset !important;
    border-radius: 0;

    ${(props: DateInputsWrappers) =>
      props.isUSAFormat &&
      css`
        margin-left: 0;
      `}
  }

  & fieldset {
    border: none;

    &:before {
      content: '';
      height: 24px;
      width: 1px;
      border-left: 1px solid rgb(177, 181, 196);
      position: absolute;
      left: 0;
      top: 6px;
    }

    &:after {
      content: '';
      height: 24px;
      width: 1px;
      border-right: 1px solid rgb(177, 181, 196);
      position: absolute;
      right: 0;
      top: 6px;
    }

    ${(props: DateInputsWrappers) =>
      props.isUSAFormat
        ? css`
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          `
        : css`
            border-radius: 0;
          `}

    ${(props: DateInputsWrappers) =>
      props.isFocused &&
      props.isUSAFormat &&
      css`
        border-color: ${props => props.theme.colors.$p0};
      `}
  }

  @media (max-width: ${device.mobileM}) {
    width: 120px;
  }

  @media (max-width: 335px) {
    width: 105px;
  }
` as SelectForwardType;

export const ErrorMessage = styled.div<{disabled: boolean}>`
  padding-top: 1px;
  bottom: initial;
  font-family: inherit;
  text-align: left;
  color: ${props => props.theme.colors.$s1};
  white-space: nowrap;
  font-size: ${props => props.theme.fonts.sizes.error};
  position: absolute;
  left: 0;
  bottom: -18px;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const FieldsetWrapperStyled = styled(Fieldset)`
  z-index: 4;
`;

type LabelProps = {
  isFocused?: boolean;
  isEmpty?: boolean;
  disabled?: boolean;
};

export const LabelWrapper = styled.div<LabelProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 48px;
  background-color: ${props => props.theme.colors.branding.backgroundSecondary};
  cursor: text;
  border-radius: 7px;
  border: none;

  ${(props: LabelProps) =>
    props.disabled &&
    css`
      background-color: ${props => props.theme.colors.branding.backgroundSecondary};
      border: 1px solid rgba(177, 181, 196, 0.4);
    `}

  ${(props: LabelProps) =>
    (props.isFocused || (!props.isEmpty && !props.isFocused)) &&
    css`
      width: auto;
      height: auto;
      background: transparent;
      border: none;
      transition: background-color 0.25s ease;
      padding: 0 2px;
      color: ${props => props.theme.colors.$p0};

      & .calendar-icon-picker {
        display: none;
      }
    `}

  ${(props: LabelProps) =>
    !props.isEmpty &&
    !props.isFocused &&
    css`
      color: red;
    `}
`;

export const TooltipStyled = styled(Tooltip)`
  position: absolute;
  right: -22px;
  top: 12px;
`;

export const FieldQuestionMarkButton = styled(QuestionMarkButton)`
  position: relative;
  top: 2px;
`;
