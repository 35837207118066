import i18n from 'i18next';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import {GROUP_TYPES} from '@guestapp/sdk';
import {COUNTRY_CODES} from 'utils/constants';

dayjs.extend(isSameOrAfter);

const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_FNS_FORMAT = 'yyyy-MM-dd';

const MinNumberOfGuests = 0;
const MaxNumberOfGuests = 100;

export enum SEARCH_RESERVATION_FORM_NAMES {
  booking_reference = 'booking_reference',
  lead_email = 'default_invite_email',
  check_in_date = 'check_in_date',
  check_out_date = 'check_out_date',
}

export type FormTypes = {
  [SEARCH_RESERVATION_FORM_NAMES.lead_email]: string;
  [SEARCH_RESERVATION_FORM_NAMES.check_in_date]: Date;
  [SEARCH_RESERVATION_FORM_NAMES.booking_reference]: string;
};

export enum OPTION_VALUES {
  booking_reference = 'booking_reference',
  check_in_date_and_email = 'check_in_date_and_email',
}

export type LocationType = {
  l?: string;
  user?: string;
  housingId?: string;
  logo?: string;
  title?: string;
  country?: string;
  startDate?: Date;
  endDate?: Date;
  email?: string;
};

const ALL_GROUP_TYPES_OPTIONS = () => [
  {value: GROUP_TYPES.single, label: i18n.t('single')},
  {
    value: GROUP_TYPES.group,
    label: i18n.t('group'),
  },
  {
    value: GROUP_TYPES.family,
    label: i18n.t('family'),
  },
];

const differenceInYears = (dateA: Date, dateB: Date): number => {
  const a = dayjs(dateA);
  const b = dayjs(dateB);
  return b.diff(a, 'year');
};

const startOfDay = (date: Date): Date => {
  return dayjs(date).startOf('day').toDate();
};

const subDays = (date: Date, days: number): Date => {
  return dayjs(date).subtract(days, 'day').toDate();
};

const isAfter = (dateA: Date, dateB: Date): boolean => {
  return dayjs(dateA).isAfter(dayjs(dateB));
};

const isEqual = (dateA: Date, dateB: Date): boolean => {
  return dayjs(dateA).isSame(dayjs(dateB));
};

const isBefore = (dateA: Date, dateB: Date): boolean => {
  return dayjs(dateA).isBefore(dayjs(dateB));
};

const isSameOrAfterDay = (
  value: Date | string | number,
  minCheckInDate: Date | string | number,
): boolean => {
  return dayjs(value).isSameOrAfter(dayjs(minCheckInDate));
};

function getMinCheckInDate(countryCode?: string) {
  let date;

  if (countryCode === COUNTRY_CODES.portugal) {
    date = subDays(new Date(), 4);
  } else {
    date = startOfDay(subDays(new Date(), 1));
  }

  return date;
}

function parseDate(date: number | string | Date, format = DATE_FNS_FORMAT): Date {
  if (date === null) return new Date(NaN);
  if (date instanceof Date) return date;
  if (typeof date === 'string') {
    try {
      const res = dayjs(date, format).toDate();
      if (dayjs(res).isValid()) {
        return res;
      }
    } catch (e) {
      console.error(e);
    }
  }

  return new Date(date);
}

type FormatOneDate = {
  date: Date | null;
  housingCountry?: string;
};

function formatOneDate({date, housingCountry}: FormatOneDate): string {
  if (!date) return '';
  const isUsaCountry = housingCountry === COUNTRY_CODES.usa;
  const formatTemplate = isUsaCountry ? 'MMM DD, YYYY' : 'DD MMM, YYYY';

  return `${dayjs(date).format(formatTemplate)}`;
}

function getIsCheckInDateBeforeCheckOutDate(
  checkInDate?: Date | null,
  checkOutDate?: string | Date | null,
) {
  if (checkInDate && checkOutDate) {
    return (
      isBefore(new Date(checkInDate), new Date(checkOutDate)) ||
      isEqual(new Date(checkInDate), new Date(checkOutDate))
    );
  }

  return true;
}

function validateCheckOut(issueDate: string | Date, birthDate?: Date | null) {
  let error = '';
  if (issueDate) {
    const isBeforeCheckInDate = getIsCheckInDateBeforeCheckOutDate(birthDate, issueDate);

    if (!isBeforeCheckInDate) {
      error = i18n.t('cant_be_earlier_than_or_equal_to_check_in_date');
    }
  }

  return error || true;
}

function getNewToken(url: string) {
  const parts = url.split('/');
  const token = parts[parts.length - 1];
  return token;
}

export {
  getMinCheckInDate,
  formatOneDate,
  isAfter,
  isEqual,
  DATE_FNS_FORMAT,
  DATE_FORMAT,
  differenceInYears,
  ALL_GROUP_TYPES_OPTIONS,
  getIsCheckInDateBeforeCheckOutDate,
  validateCheckOut,
  GROUP_TYPES,
  isSameOrAfterDay,
  MinNumberOfGuests,
  MaxNumberOfGuests,
  parseDate,
  getNewToken,
};
