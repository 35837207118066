import React, {Suspense} from 'react';
import * as Sentry from '@sentry/react';
import ChekinPro from './ChekinPro';
import AppRouter from './Routes';
import ScrollToTop from 'utils/scrollToTop';
import AppProviders from 'context';
import ConnectionErrorsHandler from './components/ConnectionErrorsHandler';
import {SplashScreen} from 'components/SplashScreen';
import {SuspenseLoader} from './styled/common';
import {ErrorFallback} from './components/ErrorFallback';
import {withProfiler} from '@sentry/react';

function AppTemplateWrapper() {
  return (
    <Suspense fallback={<SuspenseLoader width={80} visible />}>
      <ConnectionErrorsHandler>
        <AppRouter />
      </ConnectionErrorsHandler>
    </Suspense>
  );
}

function App() {
  return (
    <Sentry.ErrorBoundary
      fallback={({error}) => <ErrorFallback error={error} />}
      onError={ChekinPro.events.onError}
    >
      <AppProviders>
        <SplashScreen />
        <ScrollToTop />
        <AppTemplateWrapper />
      </AppProviders>
    </Sentry.ErrorBoundary>
  );
}

export default withProfiler(App);
