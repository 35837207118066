import React from 'react';
import cn from 'classnames';
import Icon from '../Icon';
import {ErrorMessage} from '@guestapp/ui';
import {ButtonStepper, Container, Number, CounterSize, getIconSize} from './styled';

type CounterProps = {
  value: number;
  onChange?: (value: number) => void;
  disabled?: boolean;
  readOnly?: boolean;
  error?: string | boolean;
  minNumber?: number;
  maxNumber?: number;
  size?: CounterSize;
  className?: string;
};

const Counter = ({
  value,
  error,
  onChange,
  disabled,
  readOnly,
  minNumber = 0,
  maxNumber = 20,
  size,
  className,
}: CounterProps) => {
  return (
    <Container className={cn('counter', className)} size={size}>
      {!readOnly && (
        <ButtonStepper
          disabled={disabled || value === minNumber}
          onClick={event => {
            event.stopPropagation();
            event.preventDefault();
            const checkedValue = value ?? 0;
            if (checkedValue < 1) return;

            !disabled && onChange?.(checkedValue - 1);
          }}
          type="button"
          hasError={!!error}
          aria-label="Decrease"
          className="counter__decrease-button"
        >
          <Icon name="minus" size={getIconSize(size)} />
        </ButtonStepper>
      )}
      <Number hasError={!!error} className="counter__value">
        {value}
      </Number>
      {!readOnly && (
        <ButtonStepper
          disabled={disabled || value === maxNumber}
          onClick={event => {
            event.stopPropagation();
            event.preventDefault();
            const checkedValue = value ?? 0;

            !disabled && onChange?.(checkedValue + 1);
          }}
          hasError={!!error}
          type="button"
          className="counter__increase-button"
          aria-label="Increase"
        >
          <Icon name="plus" size={getIconSize(size)} />
        </ButtonStepper>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export {Counter};
