import React from 'react';
import {STORAGE_KEYS} from '../../utils/constants';
import {SessionStorage} from '@guestapp/core';
import {useScreenResize} from '../../hooks';
import {device} from '../../styled/device';

export function useSignatureState() {
  const [signature, setInternalSignature] = React.useState('');
  const {isMatch: isMobile} = useScreenResize(device.tablet);

  const setSignature = React.useCallback((signatureDataURL: string) => {
    setInternalSignature(signatureDataURL);
    SessionStorage.set(STORAGE_KEYS.personalSignatureFormData, signatureDataURL);
  }, []);

  const resetSignature = React.useCallback(() => {
    setInternalSignature('');
    SessionStorage.remove(STORAGE_KEYS.personalSignatureFormData);
  }, []);

  React.useEffect(() => {
    if (isMobile) {
      SessionStorage.set(STORAGE_KEYS.personalSignatureFormData, signature);
    }
  }, [signature, isMobile]);

  return {
    signature,
    setSignature,
    resetSignature,
  };
}
