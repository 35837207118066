import React from 'react';
import clsx from 'clsx';
import {StyledButton} from './styled';
import {IconButtonProps} from '../IconButton/IconButton';

interface RollUpButtonProps extends IconButtonProps {
  $expanded: boolean;
}

function RollUpButton({className, ...props}: RollUpButtonProps) {
  return <StyledButton {...props} className={clsx(className, 'rollup-btn')} />;
}

export {RollUpButton};
