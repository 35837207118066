import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {goToExternalLink} from 'pages/AddPersonalDataForm/utils';
import {device} from 'styled/device';
import {useScreenResize} from './common';
import {useOpenModals} from 'context/openModals';
import {PATHS} from 'Routes';
import {IconNames} from '@guestapp/ui';
import {ReactComponent as FaqIcon} from '../assets/icons/faq-icon.svg';
import {ReactComponent as LanguageIcon} from '../assets/icons/language-icon.svg';
import {ReactComponent as LockIcon} from '../assets/icons/lock-icon.svg';

const StyledFaqIcon = styled(FaqIcon)`
  path {
    fill: ${props => props.color};
  }
`;

const StyledLanguageIcon = styled(LanguageIcon)`
  path {
    fill: ${props => props.color};
  }
`;

const StyledLockIcon = styled(LockIcon)`
  path {
    fill: ${props => props.color};
  }
`;

export enum NavLinks {
  home = 'home',
  onlineChekin = 'check_in_short',
  virtualKeys = 'virtual_keys',
  homebook = 'hoomebook',
  language = 'language',
  privacy = 'privacy_policy',
  faq = 'faqs',
  paymentCart = 'booking_payments',
}

type navigatorLinksProps = {
  languageClick?: () => void;
};
function useNavigationLinks({languageClick}: navigatorLinksProps) {
  const {t} = useTranslation();
  const {isMatch: isMobileMode} = useScreenResize(device.mobileXL);
  const {checkIfOpenDiscardModalAndRedirect} = useOpenModals();

  const navigatorLinks = React.useMemo(() => {
    const color = isMobileMode ? '#393ABD' : '#A8ADC7';

    const navigatorLinks = {
      [NavLinks.language]: {
        label: NavLinks.language,
        selected: false,
        disabled: false,
        Icon: (
          <StyledLanguageIcon color={color} width="16" height="16" viewBox="0 0 16 16" />
        ),
        iconName: 'languageSidebar' as IconNames,
        onclick: languageClick || (() => undefined),
      },
      [NavLinks.faq]: {
        label: NavLinks.faq,
        selected: false,
        disabled: false,
        Icon: <StyledFaqIcon color={color} width="16" height="16" viewBox="0 0 16 16" />,
        iconName: 'questionCircle' as IconNames,
        onclick: () => checkIfOpenDiscardModalAndRedirect(PATHS.faq),
      },
      [NavLinks.privacy]: {
        label: 'privacy_policy',
        selected: false,
        disabled: false,
        Icon: <StyledLockIcon color={color} width="14" height="16" viewBox="0 0 14 16" />,
        iconName: 'lockSidebar' as IconNames,
        onclick: () => goToExternalLink(t('privacy_policy_link')),
      },
    };

    return navigatorLinks;
  }, [isMobileMode, languageClick, checkIfOpenDiscardModalAndRedirect, t]);

  const navigatorLinksArray = React.useMemo(() => {
    return Object.values(navigatorLinks);
  }, [navigatorLinks]);

  return {navigatorLinksArray};
}

export {useNavigationLinks};
