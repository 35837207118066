import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  components,
  GroupBase,
  InputProps,
  LoadingIndicatorProps,
  MenuProps,
  PlaceholderProps,
} from 'react-select';
import {SelectOptionType} from '@guestapp/sdk';
import Loader from '../../Loader';
import {LoaderWrapper} from './styled';

const Input = <T, V, L>(
  props: InputProps<
    SelectOptionType<T, V, L>,
    false,
    GroupBase<SelectOptionType<T, V, L>>
  >,
) => {
  return <components.Input {...props} autoComplete="on" />;
};

const Placeholder = <T, V, L>({
  children,
  ...props
}: PlaceholderProps<
  SelectOptionType<T, V, L>,
  false,
  GroupBase<SelectOptionType<T, V, L>>
>) => {
  const {t} = useTranslation();
  const isSearchableMode =
    (props.isFocused && props.selectProps.isSearchable) ||
    props.selectProps.forcedSearchMode;
  const needToShow = !props.selectProps?.label || props.isFocused || props.hasValue;
  const transformedChildren = isSearchableMode
    ? `${t('type_to_search')}...`
    : needToShow
    ? children
    : null;

  return (
    <components.Placeholder {...props}>{transformedChildren}</components.Placeholder>
  );
};

const Menu = <T, V, L>(
  props: MenuProps<
    SelectOptionType<T, V, L>,
    false,
    GroupBase<SelectOptionType<T, V, L>>
  >,
) => {
  const selectProps = props?.selectProps;

  if (!selectProps.isMenuOpen) return null;
  return <components.Menu {...props}>{props.children}</components.Menu>;
};

const LoadingIndicator = <T, V, L>(
  props: LoadingIndicatorProps<
    SelectOptionType<T, V, L>,
    false,
    GroupBase<SelectOptionType<T, V, L>>
  >,
) => {
  const {isDisabled} = props.selectProps;

  return (
    <LoaderWrapper className="select__loading-indicator">
      <Loader height={18} width={30} color={isDisabled ? '#a5a5a5' : '#e7e7e7'} />
    </LoaderWrapper>
  );
};

export {Input, Menu, LoadingIndicator, Placeholder};
