import React from 'react';
import {useTranslation} from 'react-i18next';
import {device} from '@guestapp/ui/src/utils/device';
import {useSummary} from 'context/summary';
import {useScreenResize} from 'hooks';
import BookingDatesBox from 'components/BookingDatesBox';
import CheckInInfoBox from 'components/CheckInInfoBox';
import RemoteAccessNavigationItem from 'components/RemoteAccessNavigationItem';
import HomeViewModals from 'components/HomeViewModals';
import {HomeUpsellingList} from 'components/upselling/HomeUpsellingList/HomeUpsellingList';
import {
  StyledBox,
  Wrapper,
  BookingDatesBoxContainer,
  CheckinInfoContainer,
  StyledPage,
  ContentItemElement,
} from './styled';

function HomeView() {
  const {t} = useTranslation();
  const {isLoading, data: summary} = useSummary();
  const {isMatch: isMobileMode} = useScreenResize(device.tabletSubHeader);

  return (
    <StyledPage
      mainHeader={isMobileMode}
      title={t('confirm_booking_details')}
      isLoading={isLoading}
      className="home-view"
    >
      <Wrapper>
        <CheckinInfoContainer className="chekin-boxes__container">
          <StyledBox className="chekin-boxes__tasks-container">
            <CheckInInfoBox />
          </StyledBox>
          <BookingDatesBoxContainer>
            <BookingDatesBox />
          </BookingDatesBoxContainer>
        </CheckinInfoContainer>
      </Wrapper>

      <ContentItemElement>
        <RemoteAccessNavigationItem />
      </ContentItemElement>

      {summary?.upselling?.active && (
        <ContentItemElement>
          <HomeUpsellingList />
        </ContentItemElement>
      )}

      <HomeViewModals />
    </StyledPage>
  );
}

export {HomeView};
