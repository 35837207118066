import {useQuery} from '@tanstack/react-query';
import {useChekinSDK} from 'context/ChekinSDK';
import {useReservation} from 'context/reservation';
import {Contract} from '@guestapp/sdk';
import {QUERY_CACHE_KEYS} from '../utils/constants';

function useContracts() {
  const {getContracts} = useChekinSDK();
  const {housing} = useReservation();

  const housingId = housing?.id;
  const isContractEnabled = housing?.is_contract_enabled;

  const {data, error, isLoading} = useQuery<Contract[], Error>(
    [QUERY_CACHE_KEYS.contracts, housingId],
    () => getContracts(`housing_sample_id=${housingId}`),
    {enabled: Boolean(isContractEnabled && housingId), refetchOnWindowFocus: false},
  );

  return {
    isLoadingContracts: Boolean(isContractEnabled && housingId) && isLoading,
    isContractsError: !!error,
    contracts: data,
  };
}

export {useContracts};
