import styled, {css} from 'styled-components';
import {device} from '../../styled/device';
import Webcam from '../Webcam';

export const wrapperCommonStyles = css`
  border-radius: 12px;
  background: ${({theme}) => theme.colors.$p6};
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-width: 420px;
  max-height: 262px;

  @media (min-width: ${device.tablet}) {
    width: 410px;
    height: 262px;
    max-width: 410px;
    max-height: 262px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WebcamStyled = styled(Webcam)`
  ${wrapperCommonStyles};
`;
