import React from 'react';
import {ModalGuestRegisteredNextStepContent} from 'components/ModalGuestRegistered/ModalGuestRegisteredNextStep';
import {StyledModalSheet} from './styled';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

function ContinueModal({onClose, isOpen}: Props) {
  return (
    <StyledModalSheet
      isOpenModalSheet={isOpen}
      handleCloseModal={onClose}
      withCloseButton
      closeButtonType="none"
    >
      <ModalGuestRegisteredNextStepContent />
    </StyledModalSheet>
  );
}

export {ContinueModal};
