import {useTranslation} from 'react-i18next';
import {ALLIANZ_DOCUMENT_TYPES} from '@guestapp/sdk/src/utils/allianz/constanst';
import {downloadAllianzDocuments} from '../utils';
import {useSummary} from 'context/summary';
import {useErrorModal, useFetchAllianzDocuments} from 'hooks';
import {FooterContainer} from './styled';

function Footer() {
  const {t} = useTranslation();
  const {data} = useSummary();
  const {displayError, ErrorModal} = useErrorModal();
  const {data: documents} = useFetchAllianzDocuments({
    params: `view=select&country=${data?.country_code}`,
    enabled: Boolean(data?.country_code),
    onError: displayError,
  });
  return (
    <FooterContainer className="allianz-footer">
      <div
        onClick={() =>
          downloadAllianzDocuments(
            ALLIANZ_DOCUMENT_TYPES.legalNoticeDataPrivacyPolicy,
            documents,
          )
        }
      >
        {t('legal_advice_and_data_privacy')}
      </div>
      <div
        onClick={() =>
          downloadAllianzDocuments(
            ALLIANZ_DOCUMENT_TYPES.informationNoteFromMediaton,
            documents,
          )
        }
      >
        {t('mediator_informative_note')}
      </div>
      <ErrorModal />
    </FooterContainer>
  );
}

export {Footer};
