import React from 'react';
import {HOURS_OPTIONS, TIME_OPTIONS, TIME_WITHOUT_MINUTES_OPTIONS} from './constants';
import {Select, SelectProps, SelectRefType} from '../Select';

enum TimePickerFormats {
  TIME = 'time',
  WITHOUT_MINUTES = 'withoutMinutes',
  HOURS = 'hours',
}

const Options = {
  [TimePickerFormats.TIME]: TIME_OPTIONS,
  [TimePickerFormats.WITHOUT_MINUTES]: TIME_WITHOUT_MINUTES_OPTIONS,
  [TimePickerFormats.HOURS]: HOURS_OPTIONS,
};

type TimePickerProps = SelectProps<undefined, string | number> & {
  format?: TimePickerFormats;
};

const TimePicker = React.forwardRef<
  SelectRefType<undefined, string | number>,
  TimePickerProps
>(({format = TimePickerFormats.TIME, ...props}, ref) => {
  return (
    <Select<undefined, string | number>
      {...props}
      ref={ref}
      options={Options[format]}
      placeholder={props.placeholder || '--'}
    />
  );
});

export {TimePicker};
