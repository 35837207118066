import {apiClient, FetcherSettings} from './apiClient';
import {
  MultiPayment,
  OrderHistory,
  PaymentsCart,
  PaymentsSettings,
  ReservationPayment,
} from '../utils/types';
import {dynamicRoute} from './utils';

type CreateMultiPaymentsPayload = {
  reservation: string;
  session_id?: string;
  requested_specification: {
    is_ready_to_pay_for_all?: boolean;
    requested_reservation_payment_ids?: string[];
    is_deposit_payment_requested?: boolean;
    is_damage_protection_payment_requested?: boolean;
    is_taxes_payment_requested?: boolean;
  };
};

type CheckExpiredReservationPaymentsPayload = {reservation_id: string};
type CheckExpiredReservationPaymentsResponse = {
  expired_payments: ReservationPayment[];
  has_expired_payments: boolean;
};

class PaymentsApi {
  readonly ENDPOINTS = {
    paymentsSettings: dynamicRoute({
      regular: (params = '') => `api/v3/payments-settings/?${params}`,
      sdk: (params = '') => `sdk/api/v1/payments-settings/?${params}`,
    }),
    checkExpiredReservationPayments: dynamicRoute({
      regular: () => `api/v3/reservation-payments/check-expired/`,
      sdk: () => `sdk/api/v1/reservation-payments/check-expired/`,
    }),
    multiPayments: dynamicRoute({
      regular: () => `api/v4/multi-payments/`,
      sdk: () => `sdk/api/v2/multi-payments/`,
    }),
    oneMultiPayment: dynamicRoute({
      regular: (id: string) => `api/v4/multi-payments/${id}/`,
      sdk: (id: string) => `sdk/api/v2/multi-payments/${id}/`,
    }),
    paymentsCart: dynamicRoute({
      regular: (params = '') => `api/v4/payments-cart/?${params}`,
      sdk: (params = '') => `sdk/api/v2/payments-cart/?${params}`,
    }),
    orderHistory: dynamicRoute({
      regular: (params = '') => `api/v4/order-history/?${params}`,
      sdk: (params = '') => `sdk/api/v2/order-history/?${params}`,
    }),
  };

  async getPaymentsSettings(params: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<PaymentsSettings>(
      this.ENDPOINTS.paymentsSettings(params),
      fetcherSettings,
    );
    return response.body;
  }

  async checkExpiredReservationPayments(
    payload: CheckExpiredReservationPaymentsPayload,
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient.post<CheckExpiredReservationPaymentsResponse>(
      this.ENDPOINTS.checkExpiredReservationPayments(),
      {json: payload, ...fetcherSettings},
    );
    return response.body;
  }

  async createMultiPayments(
    payload: CreateMultiPaymentsPayload,
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient.post<MultiPayment>(this.ENDPOINTS.multiPayments(), {
      json: payload,
      ...fetcherSettings,
    });

    return response.body;
  }

  async getMultiPayment(id: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<MultiPayment>(
      this.ENDPOINTS.oneMultiPayment(id),
      fetcherSettings,
    );
    return response.body;
  }

  async getPaymentsCart(params: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<PaymentsCart>(
      this.ENDPOINTS.paymentsCart(params),
      fetcherSettings,
    );
    return response.body;
  }

  async getOrderHistory(params: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<OrderHistory>(
      this.ENDPOINTS.orderHistory(params),
      fetcherSettings,
    );
    return response.body;
  }
}

export {PaymentsApi};
export type {CreateMultiPaymentsPayload};
