import React from 'react';
import dayjs from 'dayjs';
import i18n from 'i18next';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import {isMobile} from 'react-device-detect';
import {DatepickerDateType, ErrorType, InputEventType} from 'utils/types';
import {SelectOptionType} from '@guestapp/sdk';
import {SelectRefType} from '../Select';
import {device} from '../../../styled/device';
import {useEvent, useOutsideClick, useScreenResize} from '../../../hooks';
import {
  DateInput,
  DateSelect,
  DateSelectWrapper,
  ErrorMessage,
  FieldsWrapper,
  LabelWrapper,
  TooltipStyled,
  Wrapper,
  DayInputWrapper,
  FieldQuestionMarkButton,
  FieldsetWrapperStyled,
  YearInputWrapper,
} from './styled';
// import calendarIcon from 'assets/icons/calendar.svg';

const DAY_PATTERN = /^(([0]?[1-9])|([1-2][0-9])|(3[01]))$/;
const YEAR_PATTERN = /^(19|20)\d{2}$/;

const MONTHS = () => ({
  january: {
    label: i18n.t('january'),
    value: '1',
  },
  february: {
    label: i18n.t('february'),
    value: '2',
  },
  march: {
    label: i18n.t('march'),
    value: '3',
  },
  april: {
    label: i18n.t('april'),
    value: '4',
  },
  may: {
    label: i18n.t('may'),
    value: '5',
  },
  june: {
    label: i18n.t('june'),
    value: '6',
  },
  july: {
    label: i18n.t('july'),
    value: '7',
  },
  august: {
    label: i18n.t('august'),
    value: '8',
  },
  september: {
    label: i18n.t('september'),
    value: '9',
  },
  october: {
    label: i18n.t('october'),
    value: '10',
  },
  november: {
    label: i18n.t('november'),
    value: '11',
  },
  december: {
    label: i18n.t('december'),
    value: '12',
  },
});

const parseDate = (date: number | string | Date) => {
  if (date === null) return new Date(NaN);
  if (date instanceof Date) return new Date(date);
  if (typeof date === 'string') {
    try {
      const res = dayjs(date).toDate();
      if (dayjs(res).isValid()) {
        return res;
      }
    } catch (e) {
      console.error(e);
    }
  }

  return new Date(date);
};

function getNextMonth(monthIndex = 0) {
  const monthNumber = monthIndex + 1;
  return Object.values(MONTHS()).find(m => m.value === String(monthNumber));
}

type DatePickerValue = {
  day?: string | number | null;
  month?: SelectOptionType | null;
  year?: string | number | null;
};

type DatepickerProps = {
  label?: string;
  defaultValues?: DatePickerValue;
  onChange: (value: DatepickerDateType, name?: string) => void;
  onHandleKeyDown?: (event: React.KeyboardEvent) => void;
  invalid?: boolean;
  value?: DatepickerDateType;
  error?: ErrorType;
  disabled?: boolean;
  showDefaultMonth?: boolean;
  name?: string;
  withTooltip?: boolean;
  tooltipContent?: string | React.ReactNode;
  className?: string;
};

const Datepicker = React.forwardRef<HTMLButtonElement, DatepickerProps>(
  (
    {
      label,
      defaultValues,
      onChange,
      invalid,
      value,
      error,
      disabled,
      name,
      withTooltip,
      tooltipContent,
      showDefaultMonth,
      className,
      onHandleKeyDown,
    },
    ref,
  ) => {
    const {isMatch} = useScreenResize(device.tablet);
    const isMobileMode = isMatch || isMobile;
    const onChangeEvent = useEvent(onChange);
    const positionTooltip = isMobileMode ? 'left top' : 'top right';

    const getDefaultMonth = () => {
      if (defaultValues?.month) {
        return defaultValues.month;
      }
      if (showDefaultMonth) {
        return Object.values(MONTHS).find(option => {
          return option?.value === (today.getMonth() + 1).toString();
        });
      }
      return undefined;
    };
    const getDefaultYear = () => {
      if (defaultValues?.year) {
        return defaultValues.year;
      }
      if (showDefaultMonth) {
        return today.getFullYear();
      }
      return undefined;
    };

    const {t} = useTranslation();
    const today = new Date();
    const [day, setDay] = React.useState(defaultValues?.day);
    const [month, setMonth] = React.useState<SelectOptionType | undefined | null>(
      getDefaultMonth(),
    );
    const [year, setYear] = React.useState(getDefaultYear());
    const [isInvalidDate, setIsInvalidDate] = React.useState(false);
    const [isYearInvalid, setIsYearInvalid] = React.useState(false);
    const stringValue = value?.toString();
    // const {ipDetails} = useIpDetails();
    const isMMddYYYYFormat = /*ipDetails?.country_code === COUNTRY_CODES.usa;*/ false;
    const [isFocused, setIsFocused] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const usaSelectRef = React.useRef<SelectRefType>(null);
    const inputYearRef = React.useRef<HTMLInputElement>(null);
    const wrapperRef = React.useRef<HTMLDivElement>(null);

    useOutsideClick(wrapperRef, () => {
      setIsFocused(false);
    });

    React.useImperativeHandle(ref, () => inputRef.current as HTMLButtonElement);
    const isEmpty = React.useMemo(() => {
      return !day && !month && !year;
    }, [day, month, year]);

    React.useEffect(() => {
      const currentRef = !isMMddYYYYFormat ? inputRef : usaSelectRef;
      if (isFocused) {
        currentRef.current?.focus();
      }
    }, [isFocused, inputRef, usaSelectRef, isMMddYYYYFormat]);

    React.useEffect(() => {
      function setValue(date: Date) {
        const isValidValueDate = dayjs(date).isValid();

        if (isValidValueDate) {
          const day = dayjs(date).date();
          setDay(String(day));

          const month = dayjs(date).month();
          setMonth(getNextMonth(month));

          const year = dayjs(date).year();
          if (!YEAR_PATTERN.test(String(year))) {
            onChangeEvent(undefined, name);
          } else {
            setYear(String(year));
          }
        }
      }

      if (stringValue) {
        const date = parseDate(stringValue);
        setValue(date);
      }
    }, [name, onChangeEvent, stringValue]);

    React.useEffect(
      function resetDateFields() {
        if (value === null) {
          setDay(undefined);
          setMonth(null);
          setYear(undefined);
        }
      },
      [value],
    );

    React.useEffect(
      function updateMonthTranslationOnLanguageChange() {
        setMonth(prevMonth => {
          if (!prevMonth) {
            return prevMonth;
          }

          const updatedTranslationMonth = Object.values(MONTHS()).find(month => {
            return month.value === prevMonth.value;
          });

          return updatedTranslationMonth;
        });
      },
      [t],
    );

    const handleChange = React.useCallback(
      (params: DatePickerValue) => {
        const day = params?.day;
        const monthValue = params?.month?.value;
        const year = params?.year;

        if (!day || !monthValue || !year) {
          onChangeEvent(undefined, name);
          return;
        }

        if (!YEAR_PATTERN.test(String(year))) {
          setIsYearInvalid(true);
          onChangeEvent(undefined, name);
          return;
        } else {
          setIsYearInvalid(false);
        }

        const date = new Date(`${monthValue}/${day}/${year}`);
        if (dayjs(date).isValid()) {
          setIsInvalidDate(false);
          onChangeEvent(date, name);
        } else {
          setIsInvalidDate(true);
          onChangeEvent(undefined, name);
        }
      },
      [name, onChangeEvent],
    );

    const handleDayChange = React.useCallback(
      ({target}: InputEventType) => {
        const value = target.value;
        if (value !== '' && !DAY_PATTERN.test(value)) {
          return;
        }

        setDay(value);
        handleChange({
          day: value,
          month,
          year,
        });
      },
      [handleChange, month, year],
    );

    const handleYearChange = React.useCallback(
      ({target}: InputEventType) => {
        const value = target.value;
        if (value.length > 4) {
          return;
        }

        setYear(value);
        handleChange({
          year: value,
          day,
          month,
        });
      },
      [day, handleChange, month],
    );

    const handleMonthChange = React.useCallback(
      (option: SelectOptionType | null) => {
        setMonth(option);
        handleChange({
          month: option,
          day,
          year,
        });
      },
      [day, handleChange, year],
    );

    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (disabled) return;
      onHandleKeyDown?.(event);
    };

    const handleWrapperClick = React.useCallback(() => {
      if (isEmpty) {
        setIsFocused(true);
      }
    }, [setIsFocused, isEmpty]);

    return (
      <Wrapper
        aria-label={name}
        disabled={disabled}
        ref={wrapperRef}
        onClick={handleWrapperClick}
        className={clsx(className, 'datepicker')}
      >
        <LabelWrapper
          isFocused={isFocused}
          isEmpty={isEmpty}
          disabled={disabled}
          onClick={() => {
            setIsFocused(!isFocused);
          }}
          className="datepicker__label"
        >
          {/* <Icon className='calendar-icon-picker' src={calendarIcon} />  TEMPORALLY COMMENT, WAITING FORDESIGN DEPARTMENT...*/}
        </LabelWrapper>
        <FieldsetWrapperStyled
          invalid={!!error}
          isFocused={isFocused}
          isEmpty={isEmpty}
          isActivated={!isEmpty || isFocused}
          label={label}
        />
        {withTooltip && (
          <TooltipStyled position={positionTooltip} label={tooltipContent}>
            <FieldQuestionMarkButton ref={ref} />
          </TooltipStyled>
        )}
        <FieldsWrapper>
          {isMMddYYYYFormat ? (
            <>
              <DateSelectWrapper>
                <DateSelect
                  disabled={disabled}
                  options={Object.values(MONTHS())}
                  value={month}
                  onChange={handleMonthChange}
                  invalid={invalid || Boolean(error) || isInvalidDate}
                  name={`${name}-month`}
                  placeholder={t('month')}
                  isUSAFormat={true}
                  isFocused={isFocused}
                  ref={usaSelectRef}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      inputRef.current?.focus();
                    }
                  }}
                />
              </DateSelectWrapper>
              <DayInputWrapper isUSAFormat={true}>
                <DateInput
                  disabled={disabled}
                  onChange={handleDayChange}
                  placeholder={t('day')}
                  value={String(day || '')}
                  type="number"
                  name={`${name}-day`}
                  invalid={invalid || Boolean(error) || isInvalidDate}
                  ref={inputRef}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      inputYearRef.current?.focus();
                    }
                  }}
                />
              </DayInputWrapper>
            </>
          ) : (
            <>
              <DayInputWrapper isUSAFormat={isMMddYYYYFormat}>
                <DateInput
                  disabled={disabled}
                  onChange={handleDayChange}
                  placeholder={t('day')}
                  value={String(day || '')}
                  type="number"
                  name={`${name}-day`}
                  inputMode="numeric"
                  invalid={invalid || Boolean(error) || isInvalidDate}
                  ref={inputRef}
                  onFocus={() => setIsFocused(true)}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      setIsFocused(false);
                      usaSelectRef.current?.focus();
                    }
                  }}
                />
              </DayInputWrapper>
              <DateSelectWrapper>
                <DateSelect
                  ref={usaSelectRef}
                  disabled={disabled}
                  options={Object.values(MONTHS())}
                  value={month}
                  onChange={handleMonthChange}
                  invalid={invalid || Boolean(error) || isInvalidDate}
                  name={`${name}-month`}
                  placeholder={t('month')}
                  isFocused={isFocused}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      inputYearRef.current?.focus();
                    }
                  }}
                />
              </DateSelectWrapper>
            </>
          )}
          <YearInputWrapper>
            <DateInput
              ref={inputYearRef}
              disabled={disabled}
              onChange={handleYearChange}
              placeholder={t('year')}
              value={String(year || '')}
              type="number"
              inputMode="numeric"
              name={`${name}-year`}
              invalid={invalid || Boolean(error) || isInvalidDate || isYearInvalid}
              onBlur={() => setIsFocused(false)}
              onKeyDown={handleKeyDown}
            />
          </YearInputWrapper>
        </FieldsWrapper>
        {error && (
          <ErrorMessage disabled={disabled || false} data-testid={`${name}-error`}>
            {error as string}
          </ErrorMessage>
        )}
      </Wrapper>
    );
  },
);

export {Datepicker};
