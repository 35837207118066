import React from 'react';
import clsx from 'clsx';
import {Wrapper} from './styled';

type MainLoaderProps = {
  visible?: boolean;
  text?: string;
  width?: number | string;
  className?: string;
};
const MainLoader = React.memo(({visible, width, text, className}: MainLoaderProps) => {
  if (!visible) return null;
  return (
    <Wrapper className={clsx(className, 'main-loader')} $width={width}>
      <svg viewBox="25 25 50 50">
        <circle r="20" cy="50" cx="50" className="cover"></circle>
        <circle r="20" cy="50" cx="50" className="circle"></circle>
      </svg>
      {text && <span>{text}</span>}
    </Wrapper>
  );
});

export {MainLoader};
