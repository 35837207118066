import React, {PropsWithChildren} from 'react';
// import useResizeObserver from 'use-resize-observer';
import clsx from 'clsx';
import {Content} from './styled';

type ComputedBoxProps = PropsWithChildren & {
  expanded: boolean;
  className?: string;
  durationMs?: number;
};
function ComputedBox({
  expanded,
  durationMs = 300,
  className,
  children,
}: ComputedBoxProps) {
  // const {ref: contentRef, height} = useResizeObserver({box: 'border-box'});
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();
  const [isMounted, setIsMounted] = React.useState(false);
  const [isTransitionFinished, setIsTransitionFinished] = React.useState(false);

  React.useEffect(() => {
    if (expanded) {
      setIsMounted(true);
      timeoutRef.current = setTimeout(() => {
        setIsTransitionFinished(true);
      }, durationMs);
    } else {
      setIsTransitionFinished(false);
      timeoutRef.current = setTimeout(() => {
        setIsMounted(false);
      }, durationMs);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [expanded, durationMs]);

  if (!expanded && !isMounted) return null;
  return (
    <Content
      $expanded={expanded && isMounted}
      $transitionFinished={isTransitionFinished}
      $duration={durationMs}
      className={clsx(className, 'computed-box')}
    >
      <div>{children}</div>
    </Content>
    // <Content $expanded={expanded} height={height} className={className}>
    //   <div ref={contentRef}>{children}</div>
    // </Content>
  );
}

export {ComputedBox};
