import React from 'react';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {DisplayedPayment} from '../types';
import {RESERVATION_PAYMENT_TYPES} from '@guestapp/sdk';
import {MutateOptions} from '@tanstack/query-core';
import {PAYMENT_ITEMS_WITH_MORE_INFO_MODAL} from '../constants';
import {Dropdown} from '../../common/Dropdown/Dropdown';
import QuestionMarkAndText from 'components/common/QuestionMarkAndText';
import {
  Container,
  ContentEditButton,
  ContentPrice,
  ContentTitle,
  DotsButton,
  EditIcon,
  Fee,
  Label,
  PlusIcon,
  Price,
  SubContainerPrice,
  SubTitle,
  Title,
} from './styled';
import {usePaymentItemModals} from './usePaymentItemModals';

type PaymentItemProps = {
  payment: DisplayedPayment;
  onRemove?: (
    externalId?: string,
    options?: MutateOptions<unknown, unknown, unknown>,
  ) => void;
  onPayLater?: (paymentId: string) => void;
  currency?: string;
  className?: string;
};

function PaymentItem({
  currency,
  onRemove,
  onPayLater,
  payment: {
    id,
    externalId,
    isRemovable,
    isEditable,
    title,
    type,
    onEdit,
    price,
    fee,
    label,
    objType,
  },
}: PaymentItemProps) {
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const theme = useTheme();

  const {openModal, Modal} = usePaymentItemModals({
    paymentType: objType as RESERVATION_PAYMENT_TYPES,
  });

  const dropdownOptions = React.useMemo(
    () => [
      {
        id: 1,
        label: t('add_to_pay_later'),
        onClick: () => onPayLater?.(id),
      },
      {
        id: 2,
        label: t('remove_item'),
        onClick: () => {
          setIsLoading(true);
          onRemove?.(externalId, {
            onSettled: () => {
              setIsLoading(false);
            },
          });
        },
        hidden: !isRemovable,
      },
    ],
    [t, isRemovable, onPayLater, id, onRemove, externalId],
  );

  return (
    <Container isLoading={isLoading} className="payment-item">
      <ContentTitle>
        <Title className="payment-item__item">
          <span>{title}</span>
          {objType && PAYMENT_ITEMS_WITH_MORE_INFO_MODAL.includes(objType) && (
            <QuestionMarkAndText onClick={openModal} />
          )}
        </Title>
        {label && <Label className="payment-item__label">({label})</Label>}
        {type && type !== 'ONLINE_PAYMENT' && (
          <SubTitle className="payment-item__subtitle" blue={type === 'PRE_AUTH_PAYMENT'}>
            {t(`payment_types.${type.toLowerCase()}`)}
          </SubTitle>
        )}

        {isEditable && (
          <ContentEditButton onClick={onEdit} className="payment-item__edit-btn">
            {objType === RESERVATION_PAYMENT_TYPES.touristTaxes ? (
              <>
                <PlusIcon name={'plusBlueIcon'} size={16} custom />
                <p>{t('add_exemptions')}</p>
              </>
            ) : (
              <>
                <EditIcon
                  name={'editIcon'}
                  size={16}
                  color={theme.colors.branding.icons}
                  custom
                />
                <p>{t('edit_options')}</p>
              </>
            )}
          </ContentEditButton>
        )}
      </ContentTitle>
      <ContentPrice className="payment-item__price-content">
        <SubContainerPrice>
          <Price className="payment-item__price">
            {currency}
            {price}
          </Price>
          <Dropdown
            position="bottom right"
            options={dropdownOptions}
            disabled={isLoading}
            trigger={
              <div>
                <DotsButton name="dots" size={16} />
              </div>
            }
          />
        </SubContainerPrice>
        {fee && (
          <Fee>
            + {currency} {fee} {t('fee')}
          </Fee>
        )}
      </ContentPrice>
      {Modal && <Modal />}
    </Container>
  );
}

export {PaymentItem};
