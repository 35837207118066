import React from 'react';
import {Label, SwitchCheckbox, SwitchContainer, SwitchLabel} from './styled';

type SwitchProps = {
  checked: boolean;
  label?: string;
  handleToggle?: () => void;
};

const defaultProps: SwitchProps = {
  label: '',
  checked: false,
};

const Switch = React.forwardRef<HTMLDivElement, SwitchProps>(
  ({checked, handleToggle, label}, ref) => {
    return (
      <SwitchContainer ref={ref}>
        <SwitchCheckbox
          checked={checked}
          onChange={handleToggle}
          className="switcher"
          id="switch"
          type="checkbox"
        />
        <SwitchLabel checked={checked} className="switcher__label" htmlFor="switch">
          <span className="switcher__button" />
        </SwitchLabel>
        <Label className="switcher__label">{label}</Label>
      </SwitchContainer>
    );
  },
);

Switch.defaultProps = defaultProps;
export {Switch};
