import styled, {keyframes} from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const dotPulseBefore = keyframes`
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
`;

const dotPulse = keyframes`
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
`;

const dotPulseAfter = keyframes`
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  background: ${({theme}) => theme.colors?.splash?.backgroundColor};
  animation: ${fadeIn} 3s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  position: relative;
  z-index: 9999;
`;

export const Logo = styled.img`
  max-width: 150px;
  max-height: 100px;
  width: auto;
  height: auto;
  object-fit: contain;
`;

export const Loader = styled.div`
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${({theme}) => theme.colors.splash.animationColor};
  color: ${({theme}) => theme.colors.splash.animationColor};
  box-shadow: 9999px 0 0 -5px;
  animation: ${dotPulse} 2s infinite linear;
  animation-delay: 0.25s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${({theme}) => theme.colors.splash.animationColor};
    color: ${({theme}) => theme.colors.splash.animationColor};
  }

  &::before {
    box-shadow: 9984px 0 0 -5px;
    animation: ${dotPulseBefore} 2s infinite linear;
    animation-delay: 0s;
    left: -12px;
  }

  &::after {
    box-shadow: 10014px 0 0 -5px;
    animation: ${dotPulseAfter} 2s infinite linear;
    animation-delay: 0.5s;
    left: 12px;
  }
`;

export const Curtain = styled.div`
  background: ${({theme}) => theme.colors.splash.animationColor};
  height: 50%;
  width: 100%;
  position: absolute;
  z-index: 2;
`;

export const TopCurtain = styled(Curtain)`
  transform: translateY(-101%);
  top: 0;

  @keyframes closeTopCurtain {
    0% {
      transform: translateY(-101%);
    }
    72% {
      transform: translateY(-101%);
    }
    86% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }

  animation: closeTopCurtain infinite;
  animation-duration: 5s;
  animation-timing-function: cubic-bezier(0, 1.23);
`;

export const BottomCurtain = styled(Curtain)`
  transform: translateY(101%);
  bottom: 0;

  @keyframes closeBottomCurtain {
    0% {
      transform: translateY(101%);
    }
    72% {
      transform: translateY(101%);
    }
    86% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }

  animation: closeBottomCurtain infinite;
  animation-duration: 5s;
  animation-timing-function: cubic-bezier(0, 1.23);
`;
