import React from 'react';
import {useTranslation} from 'react-i18next';
import {PaymentOrderHistoryItem} from '@guestapp/sdk';
import {useScreenResize} from '../../hooks';
import {device} from '../../styled/device';
import info from 'assets/icons/information-gray.svg';
import receiptIcon from 'assets/icons/details-receipt-icon.svg';
import blueArrow from '../../assets/icons/blue-arrow.svg';
import {
  Header,
  Wrapper,
  StyledRollUpButton,
  Content,
  PaymentItem,
  SummaryColumn,
  SummaryRow,
  ContentWrapper,
  HelperText,
  DesktopHelperText,
} from './styled';

const getHelperTextContent = (helperText: string) => {
  return (
    <>
      <img width={18} height={18} src={info} alt="" />
      {helperText}
    </>
  );
};

interface OrderHistoryBoxProps {
  title: string;
  subtitle: string;
  orderHistoryItems: PaymentOrderHistoryItem[];
  helperText?: string;
  date?: string;
  totalAmount?: string;
  cardLastNumbers?: string;
  totalLabel?: string;
  dateLabel?: string;
  iconSrc?: string;
  className?: string;
  isExpandedByDefault?: boolean;
}

function OrderHistoryBox({
  date,
  title,
  subtitle,
  className,
  helperText,
  totalAmount,
  orderHistoryItems,
  cardLastNumbers,
  totalLabel,
  dateLabel,
  iconSrc = receiptIcon,
  isExpandedByDefault = false,
}: OrderHistoryBoxProps) {
  const {t} = useTranslation();
  const {isMatch, isInitialized} = useScreenResize(device.mobileXL);
  const [expanded, setExpanded] = React.useState(isExpandedByDefault);

  return (
    <Wrapper className="order-history-box">
      <Header className="order-history-box__header">
        <img src={iconSrc} width={48} height={48} alt="" />
        <div>
          <h1 className="order-history-box__title">{title}</h1>
          <div className="order-history-box__subtitle">{subtitle}</div>
        </div>
        {isMatch && (
          <StyledRollUpButton
            onClick={() => setExpanded(prevState => !prevState)}
            $expanded={expanded}
            icon={<img src={blueArrow} width={16} height={9} alt="expand button" />}
          />
        )}
      </Header>
      <ContentWrapper
        expanded={expanded || (!isMatch && isInitialized)}
        className="order-history-box__content"
      >
        {helperText && (
          <DesktopHelperText className="order-history-box__helper">
            {getHelperTextContent(helperText)}
          </DesktopHelperText>
        )}
        <Content>
          <SummaryColumn>
            {orderHistoryItems?.map(item => (
              <PaymentItem key={item?.id} className="order-history-box__payment-item">
                <div>{item?.name}</div>
                {item?.label_text && <div>({item?.label_text})</div>}
              </PaymentItem>
            ))}
          </SummaryColumn>
          {helperText && <HelperText>{getHelperTextContent(helperText)}</HelperText>}
          <SummaryColumn>
            <SummaryRow>
              <span>{totalLabel || t('total_retained')}:</span>
              <span>{totalAmount}</span>
            </SummaryRow>
            <SummaryRow>
              <span>{dateLabel || t('retention_date')}:</span>
              <span>{date || '----'}</span>
            </SummaryRow>
            <SummaryRow>
              <span>{t('card_ending')}:</span>
              <span>{cardLastNumbers || '****'}</span>
            </SummaryRow>
          </SummaryColumn>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
}

export {OrderHistoryBox};
