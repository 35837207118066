import React from 'react';
import {useTheme} from 'styled-components';

type LottieLayer = {
  nm: string;
  shapes: Shape[];
};
type Shape = {
  it: It[];
};

type It = {
  ty: string;
  c: {k: number[]};
};

function hexToRGBA(hex: string) {
  const r = parseInt(hex.slice(1, 3), 16) / 255;
  const g = parseInt(hex.slice(3, 5), 16) / 255;
  const b = parseInt(hex.slice(5, 7), 16) / 255;
  const a = hex.length > 7 ? parseInt(hex.slice(7, 9), 16) / 255 : 1;
  return [r, g, b, a];
}

const useCustomLotties = () => {
  const theme = useTheme();
  const [checkinCompletedAnimation, setCheckinCompletedAnimation] = React.useState<
    object | null
  >(null);
  const [successAnimationIcon, setSuccessAnimationIcon] = React.useState<object | null>(
    null,
  );

  React.useEffect(
    function lazyPreloadSuccessLottie() {
      import('assets/lotties/blue-checked.json').then(data => {
        const newSuccessIcon = JSON.parse(JSON.stringify(data?.default));
        newSuccessIcon.layers.forEach((layer: LottieLayer) => {
          if (layer.nm !== 'Tick') {
            layer.shapes.forEach((shape: Shape) => {
              shape.it?.forEach((it: It) => {
                if (
                  (it.ty === 'fl' || it.ty === 'st') &&
                  JSON.stringify(it.c.k) === JSON.stringify([0.1294, 0.2824, 1])
                ) {
                  it.c.k = hexToRGBA(theme.colors.branding.primary);
                }
              });
            });
          }
        });
        setSuccessAnimationIcon(newSuccessIcon);
      });
    },
    [theme.colors.branding.primary],
  );

  React.useEffect(
    function lazyPreloadCheckinCompleteLottie() {
      import('assets/lotties/checkin-online-complete.json').then(data => {
        const newData = JSON.parse(JSON.stringify(data.default));

        newData.layers.forEach((layer: LottieLayer) => {
          layer.shapes.forEach((shape: Shape) => {
            shape.it?.forEach((it: It) => {
              if (
                (it.ty === 'fl' || it.ty === 'st') &&
                JSON.stringify(it.c.k) === JSON.stringify([0.1294, 0.2824, 1])
              ) {
                it.c.k = hexToRGBA(theme.colors.branding.primary);
              }
            });
          });
        });
        setCheckinCompletedAnimation(newData);
      });
    },
    [theme.colors.branding.primary],
  );

  return {
    successAnimationIcon,
    checkinCompletedAnimation,
  };
};

export {useCustomLotties};
