import {TFunction} from 'i18next';

const getTimeErrorMessage = (hoursBefore: number, t: TFunction) => {
  if (hoursBefore === 168) {
    return t('one_week_before_checkin');
  } else if (hoursBefore > 72) {
    return t('last_days_before_checkin', {days: hoursBefore / 24});
  } else {
    return t('last_hours_before_checkin', {hours: hoursBefore});
  }
};

export {getTimeErrorMessage};
