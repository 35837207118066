import {useChekinSDK} from 'context/ChekinSDK';
import {useMutation} from '@tanstack/react-query';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {CreateOrUpdateBulkLockPayload} from '@guestapp/sdk';

type UseBulkLockProps = {
  onSuccessCreateOrUpdateBulkLock?: () => void;
  onErrorCreateOrUpdateBulkLock?: (e: Error) => void;
};
function useBulkLock({
  onSuccessCreateOrUpdateBulkLock,
  onErrorCreateOrUpdateBulkLock,
}: UseBulkLockProps = {}) {
  const {createOrUpdateBuildLock} = useChekinSDK();
  const {mutate: mutateBulkLock, isLoading} = useMutation<
    unknown,
    Error,
    CreateOrUpdateBulkLockPayload
  >([QUERY_CACHE_KEYS.createOrUpdateBulkLock], createOrUpdateBuildLock, {
    onSuccess: onSuccessCreateOrUpdateBulkLock,
    onError: e => onErrorCreateOrUpdateBulkLock?.(e),
  });

  return {mutateBulkLock, isLoading};
}

export {useBulkLock};
