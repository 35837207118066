import React, {PropsWithChildren, ReactElement, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import {Trans, useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import * as utc from 'dayjs/plugin/utc';
import {ShortReservation, Housing, ORIGINS} from '@guestapp/sdk';
import ChekinPro from '../ChekinPro';
import {useChekinSDK} from './ChekinSDK';
import {QUERY_CACHE_KEYS} from '../utils/constants';
import {environments} from '../configs';
import {useCustomTheme} from './customTheme';
import {useReservationFetchListener} from '../hooks/embedded';
import {useFetchHousing} from 'hooks';

const IS_EMBEDDED_MODE = environments.REACT_APP_IS_EMBEDDED;

type ContextProps = {
  data?: ShortReservation;
  housing?: Housing;
  error?: Error | null;
  reservationError?: string | ReactElement;
  isLoading?: boolean;
  isFetching?: boolean;
  isError?: boolean;
  housingId?: string;
  reservationId?: string;
  isBiometricMatchForAll?: boolean;
  isSuccessLoadedHousing?: boolean;
  countryCode: string;
  setCountryCode?: React.Dispatch<React.SetStateAction<string>>;
  housingIdPropertyLink: string;
  setHousingIdPropertyLink?: React.Dispatch<React.SetStateAction<string>>;
  hasAvantio?: boolean;
  allowEditingGuestNumber?: boolean;
};

enum GUESTAPP_V1_DOMAINS {
  staging = 'new-chekin.stg.chekin.com',
  production = 'guestapp.chekin.com',
}

const ReservationContext = React.createContext<ContextProps>({
  data: undefined,
  housing: undefined,
  isLoading: false,
  isFetching: false,
  countryCode: '',
  housingIdPropertyLink: '',
});

function ReservationProvider({children}: Readonly<PropsWithChildren>) {
  const {getReservation, foundReservationId, isSuccessSDK} = useChekinSDK();
  const [countryCode, setCountryCode] = useState('');
  const [housingIdPropertyLink, setHousingIdPropertyLink] = useState('');
  const externalId = ChekinPro.getSettings()?.externalId;
  const {t} = useTranslation();
  const {isTemplateInitialized} = useCustomTheme();
  dayjs.extend(utc.default);
  dayjs.extend(isBetween);
  const enabled =
    isTemplateInitialized && Boolean(foundReservationId || externalId) && isSuccessSDK;

  const {
    data: reservation,
    error,
    isLoading,
    isFetching,
    isError,
    status,
  } = useQuery<ShortReservation, Error & {status?: number}>(
    [QUERY_CACHE_KEYS.reservation],
    () => getReservation({id: foundReservationId || '', externalId: externalId}),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled,
      onSuccess: data => {
        if (
          !IS_EMBEDDED_MODE &&
          (data?.signup_form_link.includes(GUESTAPP_V1_DOMAINS.staging) ||
            data?.signup_form_link.includes(GUESTAPP_V1_DOMAINS.production))
        ) {
          window.location.href = data?.signup_form_link;
        }
      },
    },
  );
  useReservationFetchListener({status, reservation});

  const housingId = reservation?.housing_id;
  const reservationError = React.useMemo(() => {
    if (IS_EMBEDDED_MODE && error) {
      if (error?.message.includes('Found many active reservations')) {
        return (
          <Trans i18nKey="to_many_reservations_external_id">
            There are many reservations associated with the <strong>EXTERNAL ID</strong>{' '}
            provided in the implementation, make sure there is only one reservation
            associated with the <strong>EXTERNAL ID</strong>. <br /> If the problem
            persist, please contact support.
          </Trans>
        );
      } else if (error?.status === 404) {
        return t('reservation_not_found');
      } else {
        return error?.message;
      }
    }

    return error?.message;
  }, [error, t]);

  const {data: housing, isSuccess: isSuccessLoadedHousing} = useFetchHousing(housingId, {
    initialData: {} as Housing,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: Boolean(housingId),
  });

  const isBiometricMatchForAll = housing?.is_biometric_match_for_all_enabled;
  const hasAvantio =
    reservation?.origin === ORIGINS.avantio &&
    !reservation?.pms_client_data_is_completely_filled;
  const allowEditingGuestNumber = housing?.allow_guest_number_modification;
  const defaultProps = React.useMemo<ContextProps>(
    () => ({
      data: reservation,
      reservationId: reservation?.id || '',
      housing,
      housingId,
      error,
      isLoading,
      isFetching,
      isError,
      reservationError,
      isBiometricMatchForAll,
      isSuccessLoadedHousing,
      countryCode,
      setCountryCode,
      housingIdPropertyLink,
      setHousingIdPropertyLink,
      hasAvantio,
      allowEditingGuestNumber,
    }),
    [
      error,
      housing,
      housingId,
      reservationError,
      isBiometricMatchForAll,
      isFetching,
      isLoading,
      isError,
      reservation,
      isSuccessLoadedHousing,
      countryCode,
      setCountryCode,
      housingIdPropertyLink,
      hasAvantio,
      allowEditingGuestNumber,
    ],
  );

  return <ReservationContext.Provider value={defaultProps} children={children} />;
}

function useReservation() {
  const context = React.useContext(ReservationContext);
  if (context === undefined) {
    throw new Error('useReservation must be used within a ReservationProvider');
  }
  return context;
}

export {ReservationContext, ReservationProvider, useReservation};
