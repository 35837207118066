import styled, {css} from 'styled-components';

type FieldsetProps = {
  invalid?: boolean;
  isFocused?: boolean;
  active?: boolean;
};

type LabelProps = {
  isEmpty?: boolean;
  isFocused?: boolean;
  isInvalid?: boolean;
};

export const InternalFieldsetStyled = styled.fieldset<FieldsetProps>`
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  overflow: hidden;
  min-width: 0;
  border: 1px solid
    ${({theme}) =>
      theme.hasTemplate ? theme.colors.branding.fontSecondary : 'rgb(177, 181, 196)'};
  border-radius: 7px;
  transition: border-color 0.05s ease-in-out;

  ${props =>
    props.isFocused &&
    css`
      border-color: ${props =>
        props.theme.hasTemplate
          ? props.theme.colors.branding.primary
          : props.theme.colors.$p2};
    `}

  ${props =>
    props.invalid &&
    css`
      border-color: #f84b7a;
    `}

    ${props =>
    props.active &&
    css`
      border-color: ${props =>
        props.theme.hasTemplate
          ? props.theme.colors.branding.fontSecondary
          : props.theme.colors.$p2};
    `}
    

    ${props =>
    props.active &&
    props.isFocused &&
    css`
      border-color: ${props =>
        props.theme.hasTemplate
          ? props.theme.colors.branding.primary
          : props.theme.colors.$p0};
    `}

    ${props =>
    props.invalid &&
    props.isFocused &&
    css`
      border-color: #f84b7a;
    `}

    ${props =>
    props.invalid &&
    !props.isFocused &&
    css`
      border-color: #f84b7a;
    `}
`;

type LegendProps = {active?: boolean; isEmpty?: boolean};
export const Legend = styled.legend<LegendProps>`
  float: unset;
  width: auto;
  overflow: hidden;
  display: block;
  padding: 0;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 0.01px;
  -webkit-transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  white-space: nowrap;

  & > span {
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    visibility: visible;
    opacity: 0;
    font-size: 14px;
    font-weight: ${props => (props.isEmpty ? 400 : 600)};
  }

  ${props =>
    props.active &&
    css`
      max-width: 100%;
      -webkit-transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
      transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    `}
`;

export const Label = styled.label<LabelProps>`
  display: block;
  max-width: 100%;
  color: ${props => props.theme.colors.branding.fontSecondary};
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  position: absolute;
  z-index: 1;
  left: 10px;
  padding: 0 20px 0 3px;
  cursor: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props =>
    props.isEmpty &&
    css`
      top: 14px;
      transition: all 0.1s ease-in;
      background-color: transparent;
      font-weight: 400;
    `}

  ${props =>
    props.isFocused &&
    css`
      font-weight: 600;
      color: ${props =>
        props.theme.hasTemplate
          ? props.theme.colors.branding.primary
          : props.theme.colors.$p0};
    `}

  ${props =>
    (!props.isEmpty || props.isFocused) &&
    css`
      &&& {
        top: -10px;
        transition: all 0.1s ease-in;
        left: 10px;
      }

      ${props.isInvalid &&
      css`
        color: #f84b7a;
      `}
    `}
`;
