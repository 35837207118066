import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {INSURANCE_TYPES} from '@guestapp/sdk';
import {usePaymentSettings, useModalControls} from '../../../hooks';
import {DepositCard} from '../../../components/DepositCard/DepositCard';
import {DamageProtectionInfoModal} from './DamageProtectionInfoModal';

type DamageProtectionCardProps = {
  value: INSURANCE_TYPES | null;
  onChange: (value: INSURANCE_TYPES | null) => void;
  total?: string;
  amount?: string | number;
  activated?: boolean;
  checkbox?: boolean;
  disabled?: boolean;
  policyLink: () => void;
};
function DamageProtectionCard({
  value,
  amount,
  total,
  onChange,
  disabled,
  activated,
  checkbox,
  policyLink,
}: DamageProtectionCardProps) {
  const {t} = useTranslation();
  const {currencySymbol} = usePaymentSettings();
  const {
    isOpen: isInfoModalOpen,
    openModal: openInfoModal,
    closeModal: closeInfoModal,
  } = useModalControls();

  const handleOnChange = () => {
    if (!checkbox) return;
    const newValue =
      value === INSURANCE_TYPES.damageProtection
        ? null
        : INSURANCE_TYPES.damageProtection;

    onChange(newValue);
  };

  if (!activated) return null;
  return (
    <>
      <DepositCard
        title={t('waivo_damage_protection')}
        onMoreInfo={openInfoModal}
        selected={value === INSURANCE_TYPES.damageProtection}
        onChange={handleOnChange}
        checkbox={checkbox}
        disabled={disabled}
        children={
          <Trans
            i18nKey="damage_protection_box_text"
            values={{amount, currencySymbol, total}}
          />
        }
        className="damage-protection-card"
      />
      <DamageProtectionInfoModal
        isOpen={isInfoModalOpen}
        close={closeInfoModal}
        policyLink={policyLink}
      />
    </>
  );
}

export {DamageProtectionCard};
