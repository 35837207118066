import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {PATHS} from 'Routes';
import {useSummary} from 'context/summary';
import {useGuestGroup, usePath} from 'hooks';
import {IncompleteRegisteredGuestsStep} from './IncompleteRegisteredGuestsStep';
import {CompletedGuestsStep} from './CompletedGuestsStep';
import {NextStepRegisterContainer, NextStepTitle, StyledButton} from './styled';

function ModalGuestRegisteredNextStepContent() {
  const {allGuestsRegistered, guestGroup} = useGuestGroup();
  const {arePaymentsAvailable, areAllPaymentsCompleted} = useSummary();
  const {t} = useTranslation();
  const current = guestGroup?.added_number_of_guests;
  const total = guestGroup?.known_number_of_guests;
  const navigate = useNavigate();
  const {getFullPath} = usePath();

  const getNextStepTitleKey = React.useMemo(() => {
    if (allGuestsRegistered) {
      if (arePaymentsAvailable && !areAllPaymentsCompleted) {
        return 'continue_to_booking_payments';
      }
      return 'guest_registration_complete';
    }
    return 'do_you_want_to_keep_registering_guests';
  }, [allGuestsRegistered, arePaymentsAvailable, areAllPaymentsCompleted]);

  return (
    <NextStepRegisterContainer className="chekin-next-step-modal__content">
      <NextStepTitle className="chekin-next-step-modal__title">
        {t(getNextStepTitleKey)}
      </NextStepTitle>
      {!allGuestsRegistered ? (
        <IncompleteRegisteredGuestsStep current={current ?? 0} total={total ?? 0} />
      ) : (
        <CompletedGuestsStep />
      )}

      <StyledButton
        label={t('go_back_to_home')}
        link
        onClick={() => navigate(getFullPath(PATHS.home))}
      />
    </NextStepRegisterContainer>
  );
}

export {ModalGuestRegisteredNextStepContent};
