import React from 'react';
import {Label, StyledIcon} from './styled';

type ProtectedDataLabelProps = {
  text: string | React.ReactNode;
};
function ProtectedDataLabel({text}: ProtectedDataLabelProps) {
  return (
    <Label className="protected-data-label">
      <StyledIcon name={'lock'} size={16} />
      {text}
    </Label>
  );
}

export default ProtectedDataLabel;
