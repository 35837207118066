import styled from 'styled-components';
import {device} from '../../styled/device';
import {Button} from '@guestapp/ui';
import ModalSheet from 'components/common/ModalSheet';

export const StyledModalGuestRegistered = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 392px;
  margin: 0 auto;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 54px;
    height: 54px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  .registered-guest-modal__title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 30px;
    font-weight: 400;
    color: #23262f;
    padding: 20px;
  }

  @media (max-width: ${device.mobileL}) {
    & .registered-guest-modal__title {
      gap: 0;
    }
  }
`;

export const ModalTitleText = styled.div`
  margin: 0;
  white-space: nowrap;
  font-family: ${props => props.theme.fonts.fontFamily.titles.font_name};
  color: ${props => props.theme.colors.branding.fontPrimary};
  font-size: 18px;
  font-weight: bold;

  @media (max-width: ${device.mobileL}) {
    white-space: normal;
    /* padding-right: 15px; */
  }
`;

export const ModalSubtitle = styled.div`
  font-family: ${props => props.theme.fonts.fontFamily.body.font_name};
  color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.fontPrimary
      : props.theme.colors.$p10};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 258px;
  height: 48px;
  margin-top: 19px;

  b {
    color: ${props =>
      props.theme.hasTemplate
        ? props.theme.colors.$p10
        : props.theme.colors.branding.fontPrimary};
  }
`;

export const ModalBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
`;

export const ErrorMessage = styled.div`
  margin-bottom: 64px;
`;
export const CloseButton = styled(Button)`
  margin: 0 auto;
`;

export const NextStepRegisterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
export const NextStepTitle = styled.b`
  margin-bottom: 24px;
  max-width: 270px;
  text-align: center;
  margin-top: 28px;
  color: ${props => props.theme.colors.branding.fontPrimary};
  @media (min-width: ${device.mobileL}) {
    margin-top: 0;
  }
`;

export const IncompleteGuestText = styled.div`
  margin-bottom: 48px;
  color: ${props => props.theme.colors.branding.fontPrimary};
`;

export const CompletedGuestText = styled.div`
  margin-bottom: 24px;
  color: ${props => props.theme.colors.branding.fontPrimary};
`;

export const BookingPAymentRequiredText = styled.div`
  max-width: 327px;
  text-align: center;
  margin-bottom: 48px;
  color: ${props => props.theme.colors.branding.fontPrimary};
`;

export const StyledButton = styled(Button)`
  margin-bottom: 36px;
  width: 100%;
  max-width: 327px;
  box-shadow: none;
  border: none;
  &:last-child {
    margin-bottom: 36px;
    @media (min-width: ${device.tablet}) {
      margin-bottom: 48px;
    }
  }
`;

export const StyledModalSheet = styled(ModalSheet)`
  &-content {
    @media (min-width: ${device.mobileL}) {
      padding: 48px 20px 0;
      max-width: 375px;
    }
  }
`;
