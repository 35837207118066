import React from 'react';
import {useMatch, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {
  BiomatchResponse,
  CompareBiomatchResponse,
  Guest,
  GUEST_STATUSES,
  IMAGE_SERVICE_ORIGIN,
  OCRDetectedData,
} from '@guestapp/sdk';
import useBuildOCRDetectedData from './useBuildOCRDetectedData';
import {PATHS} from '../Routes';
import {IV_DOCUMENT_IMAGE_TYPES, STATUSES, STORAGE_KEYS} from '../utils/constants';
import {isObject, SessionStorage} from '@guestapp/core';
import {
  FieldsErrorResponse,
  SendSelfieAndDocumentToCompareOptions,
  useAppSelector,
  useBiomatch,
  useBuildIVPayload,
  useEvent,
  useGuest,
  useGuestMutation,
  useOCR,
  usePath,
} from './';
import {getDocumentType} from '../store/slices/identityVerification';
import {useSummary} from '../context/summary';
import {useErrorModal, useLockScrollBody, useModalControls} from './common';
import {useActions} from './redux';
import {useIVComputedDetails} from './useIVComputedDetails';
import IVRetryModal from '../components/IVRetryModal';
import IVProcessModal from '../components/IVProcessModal';
import Button from '../components/common/Button';
import {ModalTwoButtonsWrapper} from '../styled/common';

const IVSuccessTimeout = 2500;

type UseUpdateGuestIVData = {
  onSuccess: (data: Guest) => void;
  onError: (error: FieldsErrorResponse) => void;
};
function useUpdateGuestIVData(props: UseUpdateGuestIVData) {
  const onSuccess = useEvent(props.onSuccess);
  const onError = useEvent(props.onError);
  const mutate = useGuestMutation({
    onSuccess,
    onError,
  });
  const {getIVPayload} = useBuildIVPayload();

  const updateGuest = useEvent((id: string) => {
    const payload = getIVPayload();
    mutate.mutate({
      id,
      payload,
    });
  });

  return {updateGuest};
}

type UseDocumentSelfieDetection = {
  docType?: IV_DOCUMENT_IMAGE_TYPES;
  onError?: (error: Error | string) => void;
};
function useDocumentSelfieDetection({onError, docType}: UseDocumentSelfieDetection = {}) {
  const navigate = useNavigate();
  const {
    setDistanceBiomatchComparing,
    resetPhotoBoxUploadedImage,
    setOCRDocumentPassed,
    resetIVConfigState,
    setBiomatchPassed,
    setImageDetails,
  } = useActions();
  const {t} = useTranslation();
  const {uploadType, id} = useParams();
  const {guest} = useGuest({id});
  const needToUpdateGuest =
    id && guest?.guestapp_status === GUEST_STATUSES.verification_pending;

  const paths = React.useMemo(() => {
    const hasGuestId = Boolean(id);
    const addPersonalDataFormRoute = !hasGuestId
      ? `../../${PATHS.addPersonalDataForm}`
      : `../../${PATHS.addPersonalDataForm}/${id}`;

    return {
      selfie: `../${PATHS.verification.uploadingType}/${IV_DOCUMENT_IMAGE_TYPES.selfie}`,
      addPersonalDataForm: addPersonalDataFormRoute,
      guestsList: `../${PATHS.guestRegistration}`,
    };
  }, [id]);

  const {biomatch} = useIVComputedDetails();
  const {reservationId} = useSummary();
  const {buildFormData} = useBuildOCRDetectedData();
  const documentType = useAppSelector(getDocumentType);
  const frontSideImage = useAppSelector(
    state => state.identityVerification.imagesDetails.front,
  );
  const {getMainPath} = usePath();
  const {resetScroll} = useLockScrollBody();
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();
  const handlerRef = React.useRef<null | (() => void)>(null);
  const sendSelfieAndDocumentToCompareRef = React.useRef<
    null | ((args: SendSelfieAndDocumentToCompareOptions) => void)
  >(null);
  const {ErrorModal: DefaultErrorModal, closeErrorModal, displayError} = useErrorModal();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [processState, setProcessState] = React.useState<STATUSES>(STATUSES.loading);
  const isBiomatchAndPassport = biomatch && !documentType?.two_sides;
  const {
    isOpen: isProcessModalOpen,
    openModal: openProcessModal,
    closeModal: closeProcessModal,
  } = useModalControls();
  const {
    isOpen: isRetryModalOpen,
    openModal: openRetryModal,
    closeModal: closeRetryModal,
  } = useModalControls();

  const onBefore = React.useCallback(() => {
    setProcessState(STATUSES.loading);
    openProcessModal();
  }, [openProcessModal]);

  const fromMrzUpload = useMatch(`/:token/document-mrz/${uploadType}`);

  React.useEffect(() => {
    const timeout = timeoutRef.current;

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const closeAndCleanLoadingModal = React.useCallback(() => {
    closeProcessModal();
    setProcessState(STATUSES.idle);
  }, [closeProcessModal]);

  React.useEffect(() => {
    return () => {
      closeAndCleanLoadingModal();
      resetScroll();
    };
  }, [closeAndCleanLoadingModal, resetScroll]);

  const getNextPageLink = React.useCallback(() => {
    switch (docType) {
      case IV_DOCUMENT_IMAGE_TYPES.front:
        return documentType?.two_sides
          ? `../${PATHS.verification.uploadingType}/${IV_DOCUMENT_IMAGE_TYPES.back}`
          : biomatch
          ? `../${PATHS.verification.uploadingType}/${IV_DOCUMENT_IMAGE_TYPES.selfie}`
          : paths.addPersonalDataForm;
      case IV_DOCUMENT_IMAGE_TYPES.back:
        return !fromMrzUpload && biomatch ? paths.selfie : paths.addPersonalDataForm;

      case IV_DOCUMENT_IMAGE_TYPES.selfie:
        return paths.addPersonalDataForm;
      default:
        return paths.addPersonalDataForm;
    }
  }, [
    biomatch,
    docType,
    documentType?.two_sides,
    fromMrzUpload,
    paths.addPersonalDataForm,
    paths.selfie,
  ]);

  const handleError = React.useCallback(
    (error: Error | FieldsErrorResponse) => {
      closeAndCleanLoadingModal();
      setProcessState(STATUSES.idle);
      displayError(error);
      if (isObject(error) && 'errors' in error) {
        onError?.(error.errors?.[0]?.message);
      } else {
        onError?.(error as Error);
      }
    },
    [closeAndCleanLoadingModal, displayError, onError],
  );

  const handleFinish = React.useCallback(
    (to?: string) => {
      setProcessState(STATUSES.success);
      timeoutRef.current = setTimeout(() => {
        const link = to || getNextPageLink();
        if (link) navigate(link);
      }, IVSuccessTimeout);
    },
    [getNextPageLink, navigate],
  );

  const {updateGuest} = useUpdateGuestIVData({
    onError: handleError,
    onSuccess: data => {
      if (data.guestapp_status === GUEST_STATUSES.complete) {
        SessionStorage.remove(STORAGE_KEYS.personalFormData);
        resetIVConfigState();
        handleFinish(getMainPath(PATHS.guestRegistration));
      } else {
        handleFinish();
      }
    },
  });

  const handleOCRData = React.useCallback(
    (data: OCRDetectedData) => {
      if (!fromMrzUpload) {
        setOCRDocumentPassed(data?.mrz_detected);
      }
      const builtFormData = buildFormData(data);
      SessionStorage.set(STORAGE_KEYS.personalFormData, builtFormData);
    },
    [fromMrzUpload, buildFormData, setOCRDocumentPassed],
  );

  const handleOCRSuccess = React.useCallback(
    (image: string, data?: OCRDetectedData) => {
      const mrzDetected = data?.mrz_detected;
      if (data) handleOCRData(data);

      if (!isBiomatchAndPassport && !fromMrzUpload && docType) {
        setImageDetails({
          image,
          type: docType,
          mrzDetected,
          id: data?.id,
          service: IMAGE_SERVICE_ORIGIN.ocr,
        });
      }

      if (needToUpdateGuest) {
        setTimeout(() => updateGuest(id), 0);
        return;
      }

      handleFinish();
    },
    [
      handleOCRData,
      fromMrzUpload,
      docType,
      needToUpdateGuest,
      handleFinish,
      setImageDetails,
      updateGuest,
      id,
      isBiomatchAndPassport,
    ],
  );

  const handleFailed = React.useCallback(
    (errorMessage?: string) => {
      if (docType === IV_DOCUMENT_IMAGE_TYPES.front) {
        setImageDetails({type: docType, id: '', service: IMAGE_SERVICE_ORIGIN.biomatch});
      }
      if (docType === IV_DOCUMENT_IMAGE_TYPES.back) {
        setImageDetails({type: docType, id: ''});
      }
      setErrorMessage(errorMessage);
      setProcessState(STATUSES.error);
    },
    [docType, setImageDetails],
  );

  const handleMRZFailed = React.useCallback(
    (errorMessage: string, data: OCRDetectedData & {image: string}) => {
      if (documentType?.without_mrz) {
        handleOCRSuccess(data.image, {...(data || {}), mrz_detected: true});
        return;
      }
      handleFailed(errorMessage);
    },
    [documentType?.without_mrz, handleFailed, handleOCRSuccess],
  );

  const handleSelfieAndDocumentCompareSuccess = React.useCallback(
    (data: CompareBiomatchResponse) => {
      setBiomatchPassed(data.is_match);
      const distance = Number(data.distance?.toFixed(1) ?? 0.0);
      setDistanceBiomatchComparing(distance);
      if (needToUpdateGuest) {
        setTimeout(() => updateGuest(id), 0);
        return;
      }
      handleFinish();
    },
    [
      handleFinish,
      id,
      needToUpdateGuest,
      setBiomatchPassed,
      setDistanceBiomatchComparing,
      updateGuest,
    ],
  );

  const {sendAndVerifyImageForOCR} = useOCR({
    onRetriesFinish: openRetryModal,
    onDetectionFailed: handleMRZFailed,
    onFinish: handleOCRSuccess,
    onError: handleError,
    onBefore,
  });

  const handleBiomatchSuccess = React.useCallback(
    (image: string, data?: BiomatchResponse) => {
      if (docType === IV_DOCUMENT_IMAGE_TYPES.selfie) {
        setImageDetails({
          image,
          type: docType,
          faceDetected: data?.is_face_detected,
          id: data?.id,
          service: IMAGE_SERVICE_ORIGIN.biomatch,
        });
        sendSelfieAndDocumentToCompareRef.current?.({
          reservationId,
          documentPhotoId: frontSideImage.id || '',
          personPhotoId: data?.id || '',
        });
        return;
      }
      if (docType) {
        setImageDetails({
          image,
          type: docType,
          faceDetected: data?.is_face_detected,
          id: data?.id,
          service: IMAGE_SERVICE_ORIGIN.biomatch,
        });
      }
      if (isBiomatchAndPassport) {
        sendAndVerifyImageForOCR(image, 'ES', reservationId);
      } else {
        handleFinish();
      }
    },
    [
      docType,
      handleFinish,
      setImageDetails,
      reservationId,
      frontSideImage.id,
      sendAndVerifyImageForOCR,
      isBiomatchAndPassport,
    ],
  );

  const {
    sendAndVerifySelfieForFaceDetection,
    sendSelfieAndDocumentToCompare,
    sendAndVerifyDocumentForFaceDetection,
  } = useBiomatch({
    onSuccessComparing: handleSelfieAndDocumentCompareSuccess,
    onRetriesFinish: openRetryModal,
    onDetectionFailed: handleFailed,
    onFinish: handleBiomatchSuccess,
    onError: handleError,
    onBefore,
  });

  // const setSideToStateAndNext = React.useCallback(
  //   async (image: string) => {
  //     openProcessModal();

  //     if (docType) {
  //       setIVImageToState({image, docType});
  //     }

  //     handleFinish();
  //   },
  //   [docType, handleFinish, openProcessModal, setIVImageToState],
  // );

  const handleRetryAgain = React.useCallback(() => {
    handlerRef.current?.();
    closeRetryModal();
  }, [closeRetryModal]);

  const handleCancelRetryAgain = React.useCallback(() => {
    closeAndCleanLoadingModal();
    setProcessState(STATUSES.idle);
    resetPhotoBoxUploadedImage();
    closeRetryModal();
    handlerRef.current = null;
  }, [closeAndCleanLoadingModal, closeRetryModal, resetPhotoBoxUploadedImage]);

  const ProcessModal = React.useCallback(() => {
    const reasons =
      docType === IV_DOCUMENT_IMAGE_TYPES.selfie
        ? [
            t('iv_failed_possible_reasons.covered_accessory'),
            t('iv_failed_possible_reasons.poor_or_side_lighting'),
          ]
        : [
            t('iv_failed_possible_reasons.the_document_is_cropped'),
            t('iv_failed_possible_reasons.the_photo_is_of_poor_quality'),
            t('iv_failed_possible_reasons.there_are_reflections'),
          ];

    return (
      <IVProcessModal
        open={isProcessModalOpen}
        errorMessage={errorMessage}
        reasons={reasons}
        state={processState}
        onTryAgain={closeAndCleanLoadingModal}
        docType={docType}
      />
    );
  }, [
    closeAndCleanLoadingModal,
    docType,
    errorMessage,
    isProcessModalOpen,
    processState,
    t,
  ]);

  const RetryModal = React.useCallback(() => {
    return (
      <IVRetryModal
        open={isRetryModalOpen}
        onClickTryAgain={handleRetryAgain}
        onCancel={handleCancelRetryAgain}
      />
    );
  }, [handleRetryAgain, isRetryModalOpen, handleCancelRetryAgain]);

  const ErrorModal = React.useCallback(() => {
    // const closeModalButtonMessage = isOnline ? 'try_again' : 'ok';
    return (
      <DefaultErrorModal
        message={t('iv_error_message')}
        description={t('iv_error_description')}
      >
        <ModalTwoButtonsWrapper>
          <Button
            label={t('try_again')}
            // disabled={!handlerRef.current}
            onClick={() => {
              closeErrorModal();
              // handlerRef.current?.();
            }}
          />
          <Button
            label={t('continue_the_registration')}
            onClick={() => navigate(getMainPath(PATHS.addPersonalDataForm))}
            link
          />
        </ModalTwoButtonsWrapper>
      </DefaultErrorModal>
    );
  }, [DefaultErrorModal, getMainPath, navigate, closeErrorModal, t]);

  const sendAndVerifyDocumentToBiomatchAndOCR = React.useCallback(
    async (image: string, countryCode: string, reservationId: string) => {
      sendAndVerifyDocumentForFaceDetection(image, countryCode, reservationId);

      // const response = await sendToOCR(image, countryCode, reservationId);
      // if (response.id) {
      //   setImageDetails({type: IV_DOCUMENT_IMAGE_TYPES.front, extraID: response.id});
      // }
    },
    [sendAndVerifyDocumentForFaceDetection],
  );

  const sendSelfieAndDocumentToCompareAndGetOCRData = React.useCallback(
    async (data: SendSelfieAndDocumentToCompareOptions) => {
      //OCR WITH SELFIE HERE
      sendSelfieAndDocumentToCompare(data);
      // if (frontSideImage.extraID) {
      //   const result = await checkOCRResult(frontSideImage.extraID);
      //   if (result) handleOCRData(result);
      // }
    },
    [sendSelfieAndDocumentToCompare],
  );

  const sendFrontSideDocument =
    biomatch || (!biomatch && documentType?.two_sides)
      ? sendAndVerifyDocumentToBiomatchAndOCR
      : sendAndVerifyImageForOCR;

  const sendBackSideDocument = sendAndVerifyImageForOCR;

  const proxyWrapper = React.useCallback(
    <T extends (...args: Parameters<T>) => void>(target: T) => {
      return (...args: Parameters<T>) => {
        const handlerFunc = () => target(...args);
        handlerRef.current = handlerFunc;
        return handlerFunc();
      };
    },
    [],
  );

  const sendFrontSideDocumentWrapped = proxyWrapper(sendFrontSideDocument);
  const sendBackSideDocumentWrapped = proxyWrapper(sendBackSideDocument);
  const sendAndReadImageForOCRWrapped = proxyWrapper(sendAndVerifyImageForOCR);
  const sendAndVerifySelfieForFaceDetectionWrapped = proxyWrapper(
    sendAndVerifySelfieForFaceDetection,
  );
  const sendSelfieAndDocumentToCompareWrapped = proxyWrapper(
    sendSelfieAndDocumentToCompareAndGetOCRData,
  );

  React.useEffect(() => {
    if (!sendSelfieAndDocumentToCompareWrapped) return;
    sendSelfieAndDocumentToCompareRef.current = sendSelfieAndDocumentToCompareWrapped;
  }, [sendSelfieAndDocumentToCompareWrapped]);

  return {
    sendSelfieAndDocumentToCompare: sendSelfieAndDocumentToCompareWrapped,
    sendSelfieToDetectFace: sendAndVerifySelfieForFaceDetectionWrapped,
    sendAndReadImageForOCR: sendAndReadImageForOCRWrapped,
    sendFrontSideDocument: sendFrontSideDocumentWrapped,
    sendBackSideDocument: sendBackSideDocumentWrapped,
    isProcessing: isProcessModalOpen,
    ProcessModal,
    ErrorModal,
    RetryModal,
  };
}

export {useDocumentSelfieDetection};
