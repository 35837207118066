import {apiClient, FetcherSettings} from './apiClient';
import {Paginated} from '../utils/types';
import {
  CityType,
  DistrictType,
  LocationType,
  MunicipalityType,
  ProvincesType,
} from '../utils';

type LocationResponseData<T> = {
  count: number;
  results: T[];
};

type FetchLocationsParams = {
  key: string;
  page: number;
  searchQuery: string;
};

type ExtendedFetchLocationsParams = FetchLocationsParams & {
  countryCode: string;
  district?: string | number;
};

class LocationApi {
  readonly ENDPOINTS = {
    get: (params = '') => `api/v3/locations/?${params}`,
    search: (params = '') => `api/v4/locations-search/?${params}`,
    getByHousingId: (id = '') => `api/v4/locations/${id}/`,
    spanishResidenceCities: (params = '') => `api/v3/es-municipalities-names/?${params}`,
  };

  async get<T, WithoutPagination>(params = '', fetcherSettings?: FetcherSettings) {
    type Response = T extends false ? WithoutPagination : LocationResponseData<T>;

    const response = await apiClient.get<Response>(
      this.ENDPOINTS.get(params),
      fetcherSettings,
    );
    return response.body;
  }

  async getByHousingId<T>(id = '', fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<T>(
      this.ENDPOINTS.getByHousingId(id),
      fetcherSettings,
    );
    return response.body;
  }

  private async fetchPaginatedLocationsList<T>(params = '') {
    const response = await apiClient.get<Paginated<T>>(this.ENDPOINTS.search(params));
    return response.body;
  }

  fetchCountriesList({page, searchQuery = ''}: FetchLocationsParams) {
    return this.fetchPaginatedLocationsList<LocationType>(
      `phone_code=1&page=${page}&q=${encodeURIComponent(searchQuery)}`,
    );
  }

  fetchProvincesList({
    page,
    searchQuery = '',
    countryCode,
  }: ExtendedFetchLocationsParams) {
    return this.fetchPaginatedLocationsList<ProvincesType>(
      `country=${countryCode}&page=${page}&q=${encodeURIComponent(searchQuery)}`,
    );
  }

  fetchCitiesList({page, searchQuery = '', countryCode}: ExtendedFetchLocationsParams) {
    return this.fetchPaginatedLocationsList<CityType>(
      `country=${countryCode}&division_level=3&page=${page}&q=${encodeURIComponent(
        searchQuery,
      )}`,
    );
  }

  fetchDistrictsList({
    page,
    searchQuery = '',
    countryCode,
  }: ExtendedFetchLocationsParams) {
    return this.fetchPaginatedLocationsList<DistrictType>(
      `country=${countryCode}&division_level=1&page=${page}&q=${encodeURIComponent(
        searchQuery,
      )}`,
    );
  }

  fetchMunicipalitiesList({
    page,
    searchQuery = '',
    countryCode,
    district,
  }: ExtendedFetchLocationsParams) {
    return this.fetchPaginatedLocationsList<MunicipalityType>(
      `country=${countryCode}&division_level=2&department=${district}&page=${page}&q=${encodeURIComponent(
        searchQuery,
      )}`,
    );
  }

  fetchPhoneCodesList({page, searchQuery = ''}: FetchLocationsParams) {
    return this.fetchPaginatedLocationsList<LocationType>(
      `phone_code=1&page=${page}&q=${encodeURIComponent(searchQuery)}`,
    );
  }

  async fetchSpanishResidenceCitiesList(
    {page = 1, searchQuery = ''}: FetchLocationsParams,
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient.get<Paginated<{name: string; code: string}>>(
      this.ENDPOINTS.spanishResidenceCities(
        `page=${page}&q=${encodeURIComponent(searchQuery)}`,
      ),
      fetcherSettings,
    );

    return response.body;
  }
}

export {LocationApi};
export type {FetchLocationsParams, ExtendedFetchLocationsParams, LocationResponseData};
