import {Housing} from './types';

function getIsPoliceEnabled(housing?: Housing) {
  const isEnabled = housing?.is_auto_police_registration_enabled;
  return Boolean(isEnabled);
}

function getPoliceType(housing?: Housing): string {
  const type = housing?.police_account_type;

  if (!type) {
    return '';
  }
  return type;
}

function getPoliceTypeIfPoliceActive(housing?: Housing) {
  if (!getIsPoliceEnabled(housing)) {
    return '';
  }
  return getPoliceType(housing);
}

export {getPoliceTypeIfPoliceActive};
