import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {PATHS} from 'Routes';
import {useSummary} from 'context/summary';
import {useFetchLockConditions, usePath} from 'hooks';
import NavigationItem from 'components/NavigationItem';

function RemoteAccessNavigationItem() {
  const {data} = useSummary();
  const theme = useTheme();
  const {getFullPath} = usePath();
  const {t} = useTranslation();
  const {data: lockConditions} = useFetchLockConditions({
    enabled: Boolean(data?.is_remote_access_enabled),
  });

  if (!(data?.is_remote_access_enabled && lockConditions?.is_show_keys_enabled)) {
    return null;
  }

  return (
    <NavigationItem
      maxWidth="100%"
      title={t('virtual_keys')}
      path={getFullPath(PATHS.remoteAccess)}
      icon={{
        name: 'keyIconCircle',
        color: theme.hasTemplate ? theme.colors.branding.icons : '#777E91',
      }}
      isStatusBoxVisible={false}
      description={t('direct_access_you_virtual_keys')}
    />
  );
}

export {RemoteAccessNavigationItem};
