import {apiClient, FetcherSettings} from './apiClient';
import {GuestGroup, UpdateGuestGroup} from '../utils/types';
import {dynamicRoute} from './utils';

class GuestGroupApi {
  ENDPOINTS = {
    guestGroup: dynamicRoute({
      regular: (id = '') => `api/v4/guestapp/guest-group/${id}/`,
      sdk: (id = '') => `sdk/api/v1/guestapp/guest-group/${id}/`,
    }),
  };

  async getGuestGroup(id: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<GuestGroup>(
      this.ENDPOINTS.guestGroup(id),
      fetcherSettings,
    );
    return response.body;
  }

  async patchGuestGroup(
    id: string,
    payload: UpdateGuestGroup,
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient.patch<GuestGroup>(this.ENDPOINTS.guestGroup(id), {
      json: payload,
      ...fetcherSettings,
    });
    return response.body;
  }
}

export {GuestGroupApi};
