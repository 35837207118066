import {PaymentsApi} from '../api';
import {FetcherSettings} from '../api/apiClient';

class PaymentsService {
  paymentsApi: PaymentsApi;

  constructor() {
    this.paymentsApi = new PaymentsApi();
  }

  getPaymentsSettings(params = '', resolverSettings?: FetcherSettings) {
    return this.paymentsApi.getPaymentsSettings(params, resolverSettings);
  }

  checkExpiredReservationPayments(
    payload: Parameters<PaymentsApi['checkExpiredReservationPayments']>[0],
    resolverSettings?: FetcherSettings,
  ) {
    return this.paymentsApi.checkExpiredReservationPayments(payload, resolverSettings);
  }

  createMultiPayment(
    payload: Parameters<PaymentsApi['createMultiPayments']>[0],
    resolverSettings?: FetcherSettings,
  ) {
    return this.paymentsApi.createMultiPayments(payload, resolverSettings);
  }

  getMultiPayment(id: string, resolverSettings?: FetcherSettings) {
    return this.paymentsApi.getMultiPayment(id, resolverSettings);
  }

  getPaymentsCart(params: string, resolverSettings?: FetcherSettings) {
    return this.paymentsApi.getPaymentsCart(params, resolverSettings);
  }

  getOrderHistory(params: string, resolverSettings?: FetcherSettings) {
    return this.paymentsApi.getOrderHistory(params, resolverSettings);
  }
}

export {PaymentsService};
