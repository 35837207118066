import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useLocation, useMatch, useNavigate} from 'react-router-dom';
import {PATHS} from 'Routes';
import {ButtonProps} from 'components/common/Button/Button';
import StandardModalSheet from 'components/common/StandardModalSheet';
import {useActions, useAppSelector, usePath} from 'hooks';
import {getDocumentType} from 'store/slices/identityVerification';

type DiscardLastPhotoProps = {
  onClose: () => void;
};

function DiscardLastPhoto({onClose}: DiscardLastPhotoProps) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {getIVPath} = usePath();
  const {pathname} = useLocation();
  const documentType = useAppSelector(getDocumentType);
  const {resetPhotoBoxUploadedImage} = useActions();

  const isFrontSideView = useMatch('/:token/verification/front/take');
  const isBackSideView = useMatch('/:token/verification/back/take');
  const isFrontSideUploadTypeSelection = useMatch(
    '/:token/verification/uploading-type/front',
  );
  const isBackSideUploadTypeSelection = useMatch(
    '/:token/verification/uploading-type/back',
  );

  const verificationViewsToOpenDiscardModal = React.useMemo(() => {
    if (isFrontSideUploadTypeSelection) {
      return PATHS.verification.documentSelection;
    }

    if (isBackSideUploadTypeSelection || isBackSideView) {
      return `${PATHS.verification.uploadingType}/front`;
    }

    if (pathname.includes(PATHS.verification.selfie)) {
      return documentType?.two_sides
        ? `${PATHS.verification.uploadingType}/back`
        : `${PATHS.verification.uploadingType}/front`;
    }

    return isFrontSideView
      ? `${PATHS.verification.uploadingType}/front`
      : PATHS.verification.documentSelection;
  }, [
    isBackSideView,
    isFrontSideView,
    isFrontSideUploadTypeSelection,
    isBackSideUploadTypeSelection,
    pathname,
    documentType?.two_sides,
  ]);

  // const verificationViewsToOpenDiscardModal = React.useMemo(() => {
  //   if (isFrontSideView) {
  //     return `${PATHS.verification.uploadingType}/front`;
  //   }

  //   if (isBackSideView) {
  //     return `${PATHS.verification.uploadingType}/back`;
  //   }

  //   if (isFrontSideUploadTypeSelection) {
  //     return PATHS.verification.documentSelection;
  //   }

  //   if (isBackSideUploadTypeSelection) {
  //     return `${PATHS.verification.uploadingType}/front`;
  //   }

  //   if (pathname.includes(PATHS.verification.selfie)) {
  //     return documentType?.two_sides
  //       ? `${PATHS.verification.uploadingType}/back`
  //       : `${PATHS.verification.uploadingType}/front`;
  //   }

  //   return PATHS.verification.main;
  // }, [
  //   isBackSideView,
  //   isFrontSideView,
  //   isFrontSideUploadTypeSelection,
  //   isBackSideUploadTypeSelection,
  //   pathname,
  //   documentType?.two_sides,
  // ]);

  const discardLastPhotoAndReset = React.useCallback(() => {
    resetPhotoBoxUploadedImage();
    navigate(getIVPath(verificationViewsToOpenDiscardModal), {replace: true});
    onClose();
  }, [
    resetPhotoBoxUploadedImage,
    navigate,
    getIVPath,
    verificationViewsToOpenDiscardModal,
    onClose,
  ]);

  const discardLastPhotoButtons: ButtonProps[] = React.useMemo(() => {
    const primaryButton = {
      label: t('discard_and_go_back'),
      primary: true,
      onClick: discardLastPhotoAndReset,
    };
    const secondaryButton: ButtonProps = {
      link: true,
      label: t('continue_process'),
      onClick: onClose,
    };
    return [primaryButton, secondaryButton];
  }, [t, onClose, discardLastPhotoAndReset]);

  const discardLastPhotoText = React.useMemo(
    () => (
      <Trans i18nKey="discard_last_photo_text">
        Going back to the previous step will <b>discard the last photo taken</b>.
      </Trans>
    ),
    [],
  );

  return (
    <StandardModalSheet buttons={discardLastPhotoButtons} text={discardLastPhotoText} />
  );
}

export {DiscardLastPhoto};
