import {useQuery} from '@tanstack/react-query';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {useChekinSDK} from 'context/ChekinSDK';

function useFetchOffer({id}: {id: string}) {
  const {getOffer} = useChekinSDK();
  const result = useQuery([QUERY_CACHE_KEYS.offer, id], () => getOffer(id), {
    enabled: Boolean(id),
  });

  return result;
}

export {useFetchOffer};
