import React from 'react';
import {PAYMENT_METHOD_TYPES} from '@guestapp/sdk';
import {useActions, useAppSelector, usePropertyProtection} from '../../hooks';
import {
  DeferredPayment,
  getIsPropertyProtectionPaymentPreloaded,
} from '../../store/slices/payments';

type UsePreloadDeferredPaymentsProps = {
  addDeferredPayments: (cartPayment: DeferredPayment) => void;
  removeDeferredPayment: (id: string) => void;
  onError?: (error: Error) => void;
};
function usePreloadDeferredPayments({
  onError,
  addDeferredPayments,
  removeDeferredPayment,
}: UsePreloadDeferredPaymentsProps) {
  const [isPreloading, setIsPreloading] = React.useState(true);
  const isPropertyProtectionPaymentPreloaded = useAppSelector(
    getIsPropertyProtectionPaymentPreloaded,
  );
  const {setProtectionPaymentPreloaded} = useActions();
  const {insuranceSetup, isInsuranceSetupInit} = usePropertyProtection({onError});

  React.useLayoutEffect(() => {
    if (!isInsuranceSetupInit) return;
    if (!isPropertyProtectionPaymentPreloaded && insuranceSetup?.id) {
      if (insuranceSetup?.insurance_payment_type === PAYMENT_METHOD_TYPES.payOnArrival) {
        addDeferredPayments({id: insuranceSetup?.id});
      } else {
        removeDeferredPayment(insuranceSetup?.id);
      }
      setProtectionPaymentPreloaded(true);
    }
    setIsPreloading(false);
  }, [
    insuranceSetup?.id,
    addDeferredPayments,
    isInsuranceSetupInit,
    removeDeferredPayment,
    setProtectionPaymentPreloaded,
    isPropertyProtectionPaymentPreloaded,
    insuranceSetup?.insurance_payment_type,
  ]);

  return {isPreloading};
}

export default usePreloadDeferredPayments;
