import styled, {css} from 'styled-components';
import {device} from '../../utils/device';
import {getBackgroundColor} from '../../utils/common';
import QuestionMarkButton from '../QuestionMarkButton';
import {CustomIcon} from '../CustomIcon';
import {ErrorMessage as BaseErrorMessage} from '../../utils/styled';

type WrapperProps = {
  disabled?: boolean;
};

type StyledInputProps = {
  invalid?: boolean;
  disabled?: boolean;
  isEmpty?: boolean;
  isFocused?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: 288px;
  min-height: 44px;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `};

  @media (max-width: ${device.mobileS}) {
    width: 293px;
  }
`;

export const TooltipTrigger = styled.div`
  position: relative;
  padding-left: 8px;
`;

export const FieldQuestionMarkButton = styled(QuestionMarkButton)`
  height: 22px;
  width: 22px;

  & > img {
    width: 100%;
    height: 100%;
  }

  &:active {
    opacity: 0.8;
  }
`;

export const WrapperInput = styled.div`
  min-height: 44px;
  display: flex;
  flex-direction: column;
  position: relative;

  ${(props: WrapperProps) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

export const RelativeWrapper = styled.div`
  position: relative;
`;

export const StyledInput = styled.input<StyledInputProps>`
  width: 100%;
  outline: none;
  margin: 0;
  font-size: 16px;
  transition: background-color 0.25s ease-in-out;
  padding: 13px 16px 13px 9px;
  box-sizing: border-box;
  border-radius: 7px;
  border: none;
  text-overflow: ellipsis;
  height: 48px;
  font-family: inherit;
  font-weight: 400;
  line-height: 24px;
  color: ${props => props.theme.colors.branding.fontPrimary};
  background-color: ${({isEmpty, isFocused, theme}) =>
    getBackgroundColor(isEmpty, isFocused, theme)};

  &::placeholder {
    font-weight: 400;
    color: #9696b9;
    opacity: 1;
    text-overflow: ellipsis;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${props => props.theme.colors.branding.fontPrimary};
    transition: background-color 5000s ease-in-out 0s;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }
`;

export const StatusIcon = styled.img<{error: boolean}>`
  position: absolute;
  top: 12px;
  right: 16px;
  z-index: 2;
  cursor: ${props => (props.error ? 'pointer' : 'default')};
  outline: none;
`;

export const ContentStatusIcon = styled.div`
  position: absolute;
  top: 12px;
  right: 16px;
  z-index: 2;
  outline: none;
`;

export const StatusCustomIcon = styled(CustomIcon)`
  path,
  stop {
    stop-color: ${({theme}) => theme.colors.branding.primary};
  }
`;

export const InputErrorMessage = styled(BaseErrorMessage)``;
