import {PropertyProtectionApi} from '../api';
import {InsuranceSetup, PropertyProtections} from '../utils';
import {FetcherSettings} from '../api/apiClient';

class PropertyProtectionService {
  private readonly propertyPropertyApi: PropertyProtectionApi;

  constructor() {
    this.propertyPropertyApi = new PropertyProtectionApi();
  }

  getPropertyProtectionsByReservation<T = PropertyProtections>(
    reservationId = '',
    resolverSettings?: FetcherSettings,
  ) {
    return this.propertyPropertyApi.getPropertyProtectionsByReservation<T>(
      reservationId,
      resolverSettings,
    );
  }

  mutateInsuranceSetup<T = InsuranceSetup>(
    payload: Partial<InsuranceSetup>,
    resolverSettings?: FetcherSettings,
  ) {
    return this.propertyPropertyApi.mutateInsuranceSetup<T>(payload, resolverSettings);
  }

  getInsuranceSetup(id: string, resolverSettings?: FetcherSettings) {
    return this.propertyPropertyApi.getInsuranceSetup<InsuranceSetup>(
      id,
      resolverSettings,
    );
  }
}

export {PropertyProtectionService};
