import React from 'react';
import i18n from 'i18next';
import {useTheme} from 'styled-components';
import {Trans, useTranslation} from 'react-i18next';
import {useQueryClient} from '@tanstack/react-query';
import {Plan} from '@guestapp/sdk/src/utils/allianz';
import {ButtonProps} from 'components/common/Button/Button';
import {getTranslatedPlanName} from 'hooks/allianz/utils';
import {
  useAllianzPlans,
  useErrorModal,
  useModalControls,
  useThirdPartyDeals,
} from 'hooks';
import insuranceCwdSmall from 'assets/icons/insurance-cwd-small.svg';
import insuranceCwd from 'assets/icons/insurance-cwd.svg';
import {Checkbox, CustomIcon} from '@guestapp/ui';
import {PlanInfoBox} from './PlanInfoBox';
import {
  ConfirmInsuranceModalContent,
  GuestAndPlanName,
  GuestItem,
  StyledModalSheet,
  StyledStandardModalSheet,
  ThreeDotsIconContainer,
} from './styled';

type GuestSelectBoxProps = {
  name: string;
  planTitle: string;
  checked?: boolean;
  guestId?: string;
  addon?: Plan;
  paid: boolean;
  isPremium?: boolean;
  dealId?: string;
  onMutateGuest?: (isMutating: boolean) => void;
};

function GuestSelectBox({
  name,
  planTitle,
  checked,
  guestId,
  addon,
  paid,
  isPremium,
  dealId,
  onMutateGuest,
}: GuestSelectBoxProps) {
  const {t} = useTranslation();
  const theme = useTheme();
  const [isChecked, setIsChecked] = React.useState(checked);
  const [planAddon, setPlanAddon] = React.useState(addon);
  const {selectedPlan} = useAllianzPlans();
  const {displayError, ErrorModal} = useErrorModal();
  const queryClient = useQueryClient();
  const translatedAddonName = getTranslatedPlanName(addon?.name, i18n.language);

  const refetchMainQueries = () => {
    queryClient.refetchQueries(['allianzOrders']);
    queryClient.refetchQueries(['guestGroup']);
  };
  const handleThirdPartyDealMutationSuccess = () => {
    refetchMainQueries();
    onMutateGuest?.(false);
  };
  const handleDeleteDealSuccess = () => {
    setPlanAddon(prevState => {
      if (prevState) {
        return {...prevState, selected: false};
      }
    });
    refetchMainQueries();
    onMutateGuest?.(false);
  };

  const isMutating = () => {
    onMutateGuest?.(true);
  };

  const {mutateDeleteDeal, getThirdPartyDealPayload, mutateThirdPartyDeal} =
    useThirdPartyDeals({
      onSuccessDeal: handleThirdPartyDealMutationSuccess,
      onErrorDeal: displayError,
      onMutateDeal: isMutating,
      onSuccessDeleteDeal: handleDeleteDealSuccess,
      onErrorDeleteDeal: displayError,
      onMutateDeleteDeal: isMutating,
    });

  const {
    isOpen: isOpenConfirmInsuranceModal,
    closeModal: closeConfirmInsuranceModal,
    openModal: openConfirmInsuranceModal,
  } = useModalControls();

  const confirmAddGuest = React.useCallback(() => {
    // eslint-disable-next-line
    const plans = [selectedPlan?.id as string];
    if (Boolean(planAddon?.selected)) {
      plans.push(planAddon?.id as string);
    }
    const payload = !dealId
      ? getThirdPartyDealPayload(guestId as string)
      : {deal_id: dealId};
    mutateThirdPartyDeal({plans, ...payload});

    setIsChecked(true);
  }, [
    setIsChecked,
    planAddon,
    guestId,
    selectedPlan?.id,
    dealId,
    getThirdPartyDealPayload,
    mutateThirdPartyDeal,
  ]);

  const modalSheetButtons: ButtonProps[] = React.useMemo(() => {
    const primaryButton: ButtonProps = {
      label: t('confirm'),
      primary: true,
      onClick: () => {
        confirmAddGuest();
        closeConfirmInsuranceModal();
      },
    };
    const secondaryButton: ButtonProps = {
      link: true,
      label: t('cancel'),
      onClick: closeConfirmInsuranceModal,
    };
    return [primaryButton, secondaryButton];
  }, [closeConfirmInsuranceModal, t, confirmAddGuest]);

  const title = addon?.selected
    ? `${planTitle} + ${getTranslatedPlanName(addon?.name, i18n.language)}`
    : planTitle;
  const isSelectedAddon = planAddon?.selected;
  const CheckboxLabel = React.useMemo(() => {
    return (
      <GuestAndPlanName paid={paid}>
        <div className="guest-name">{name}</div>
        {isChecked && (
          <div className="plan-name">
            {!paid && (
              <>
                {isSelectedAddon ? (
                  <img src={insuranceCwdSmall} alt="insurance cwd" />
                ) : (
                  <CustomIcon
                    name="allianzShieldIcon"
                    color="#777E91"
                    className="plan-icon"
                    size={'16px'}
                  />
                )}
              </>
            )}
            <span>{title}</span>
          </div>
        )}
      </GuestAndPlanName>
    );
  }, [name, isChecked, title, isSelectedAddon, paid]);

  const handleChangeCheckbox = React.useCallback(
    (guestId: string, dealId?: string) => {
      if (isChecked) {
        setIsChecked(false);
        mutateDeleteDeal(dealId as string);
        return;
      }
      if (isPremium) {
        openConfirmInsuranceModal();
        return;
      }

      confirmAddGuest();
    },
    [
      isChecked,
      openConfirmInsuranceModal,
      setIsChecked,
      isPremium,
      confirmAddGuest,
      mutateDeleteDeal,
    ],
  );

  const guestHeaderTitle = (
    <Trans i18nKey="confirm_ensurance_guest_text" values={{name}}>
      <b>{name}</b> has been successfully updated.
    </Trans>
  );

  const onAddonChange = () => {
    setPlanAddon(prevState => {
      if (prevState) {
        return {
          ...prevState,
          selected: !prevState.selected,
        };
      }
    });
  };

  return (
    <>
      <GuestItem className="allianz-guest-select-box">
        {paid && (
          <div className="saved-guest-container">
            {isSelectedAddon ? (
              <img src={insuranceCwd} alt="insurance cwd" />
            ) : (
              <CustomIcon name="allianzShieldIcon" size={'32px'} color="#152946" />
            )}
            {CheckboxLabel}
          </div>
        )}
        {!paid && (
          <Checkbox
            value={isChecked}
            // eslint-disable-next-line
            onChange={() => handleChangeCheckbox(guestId!, dealId!)}
            label={CheckboxLabel}
          />
        )}

        {isPremium && !paid && (
          <ThreeDotsIconContainer onClick={openConfirmInsuranceModal}>
            <CustomIcon name="threeDots" color={theme.colors.branding.primary} />
          </ThreeDotsIconContainer>
        )}
      </GuestItem>
      {isPremium && (
        <StyledModalSheet
          isOpenModalSheet={isOpenConfirmInsuranceModal}
          handleCloseModal={closeConfirmInsuranceModal}
          title={guestHeaderTitle}
          withCloseButton
        >
          <StyledStandardModalSheet
            buttons={modalSheetButtons}
            content={
              <ConfirmInsuranceModalContent>
                <PlanInfoBox
                  title={getTranslatedPlanName(selectedPlan?.title, i18n.language)}
                  price={selectedPlan?.price}
                >
                  <PlanInfoBox.AddonBox
                    onChange={onAddonChange}
                    name={translatedAddonName}
                    checked={!!addon?.selected}
                    price={addon?.total_amount_to_pay}
                    show={isPremium}
                  />
                </PlanInfoBox>
              </ConfirmInsuranceModalContent>
            }
          />
        </StyledModalSheet>
      )}
      <ErrorModal />
    </>
  );
}

export {GuestSelectBox};
