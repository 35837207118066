import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  ExemptionAddedConfirmButton,
  ExemptionAddedModalSheet,
  WrapperText,
  TitleModal,
} from './styled';

type ExemptionAddedModalProps = {
  isOpen: boolean;
  onClose: () => void;
};
const ExemptionAddedModal = memo(({isOpen, onClose}: ExemptionAddedModalProps) => {
  const {t} = useTranslation();

  return (
    <ExemptionAddedModalSheet
      title={t('exemption_added')}
      isOpenModalSheet={isOpen}
      handleCloseModal={onClose}
      className="exemp-added-modal"
    >
      <WrapperText className="exemp-added-modal__content">
        <TitleModal className="exemp-added-modal__title">
          {t('you_have_to_complete_the_remaining_data')}
        </TitleModal>

        <ExemptionAddedConfirmButton
          label={t('ok')}
          onClick={onClose}
          className="exemp-added-modal__btn"
        />
      </WrapperText>
    </ExemptionAddedModalSheet>
  );
});

export {ExemptionAddedModal};
