import {useQuery} from '@tanstack/react-query';
import {Template} from '@guestapp/sdk';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {useChekinSDK} from 'context/ChekinSDK';

type UseFetchTemplateProps = {
  onSuccess?: (data: Template) => void;
  templateId?: string;
  enabled?: boolean;
};
function useFetchTemplate(
  {onSuccess, templateId, enabled}: UseFetchTemplateProps = {
    onSuccess: undefined,
  },
) {
  const {getCustomTheme} = useChekinSDK();

  return useQuery<Template, Error>(
    [QUERY_CACHE_KEYS.template],
    () => getCustomTheme(String(templateId)),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(templateId) && enabled,
      onSuccess: onSuccess,
    },
  );
}

export {useFetchTemplate};
