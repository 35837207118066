import {getTokenFromStorage} from '../utils';

const BASE_DOMAIN = process.env.REACT_APP_WEBSOCKET_BASE_URL;

class WebSocketService {
  connectWebSocket() {
    const token = getTokenFromStorage();

    const connectionURL = `wss://${BASE_DOMAIN}/?token=${token}`;

    return new WebSocket(connectionURL);
  }
}

export {WebSocketService};
