import React, {useCallback} from 'react';
import {useAppSelector} from './redux';
import {dataURIToBlob} from '../utils/common';
import {useIVComputedDetails} from './useIVComputedDetails';
import {IMAGE_SERVICE_ORIGIN, PAYLOAD_FIELDS_NAMES} from '@guestapp/sdk';
import {
  getImageDetails,
  getOCRPassed,
  getBiomatchResultData,
} from '../store/slices/identityVerification';

const clearValue = <T>(value: T) => {
  if (!value) {
    return;
  }

  return value;
};

type ExternalDocumentsData = {
  [PAYLOAD_FIELDS_NAMES.external_documents_front_side_scan]?: string;
  [PAYLOAD_FIELDS_NAMES.external_documents_front_side_scan_service]?: IMAGE_SERVICE_ORIGIN;
  [PAYLOAD_FIELDS_NAMES.external_documents_biomatch_doc]?: string;
  [PAYLOAD_FIELDS_NAMES.external_documents_biomatch_doc_service]?: IMAGE_SERVICE_ORIGIN;
  [PAYLOAD_FIELDS_NAMES.external_documents_back_side_scan]?: string;
  [PAYLOAD_FIELDS_NAMES.external_documents_back_side_scan_service]?: IMAGE_SERVICE_ORIGIN;
  [PAYLOAD_FIELDS_NAMES.external_documents_biomatch_selfie]?: string;
  [PAYLOAD_FIELDS_NAMES.external_documents_biomatch_selfie_service]?: IMAGE_SERVICE_ORIGIN;
};

type IdentityVerificationPayload = ExternalDocumentsData & {
  document_front_side_scan?: Blob;
  document_back_side_scan?: Blob;
  biomatch_selfie?: Blob;
  ocr_passed?: boolean;
  biomatch_doc?: Blob;
  biomatch_passed?: boolean;
  biomatch_percentage?: number | null;
};

type BuildIdentityVerificationArgs = {
  documentFrontSide: string | null;
  documentBackSide: string | null;
  selfieImage: string | null;
  isOCRVerification: boolean;
  isBiomatchVerification: boolean;
  ocrPassed: boolean;
  biomatchPassed: boolean;
  biomatchDistance: number | null;
  externalDocumentData?: ExternalDocumentsData;
};
const buildIdentityVerificationPayload = ({
  documentFrontSide,
  documentBackSide,
  selfieImage,
  ocrPassed,
  biomatchPassed,
  isBiomatchVerification,
  isOCRVerification,
  biomatchDistance,
  externalDocumentData,
}: BuildIdentityVerificationArgs): IdentityVerificationPayload => {
  const frontDocumentBlob = dataURIToBlob(documentFrontSide);
  const backDocumentBlob = dataURIToBlob(documentBackSide);
  const selfieBlob = dataURIToBlob(selfieImage);

  return {
    document_front_side_scan: frontDocumentBlob || undefined,
    document_back_side_scan: backDocumentBlob || undefined,
    biomatch_selfie: selfieBlob || undefined,
    ocr_passed: isOCRVerification ? ocrPassed : undefined,
    biomatch_doc: isBiomatchVerification ? frontDocumentBlob : undefined,
    biomatch_passed: isBiomatchVerification ? biomatchPassed : undefined,
    biomatch_percentage: isBiomatchVerification ? biomatchDistance : undefined,
    ...(externalDocumentData && {...externalDocumentData}),
  };
};

function useBuildIVPayload() {
  const {biomatch: isBiomatchEnabled, ocr: isOCREnabled} = useIVComputedDetails();
  const {
    front: frontSideImage,
    back: backSideImage,
    selfie: selfieImage,
  } = useAppSelector(getImageDetails);
  const {biomatchPassed, distance} = useAppSelector(getBiomatchResultData);
  const ocrPassed = useAppSelector(getOCRPassed);

  const externalDocuments = React.useMemo(() => {
    return {
      external_documents_front_side_scan_service: clearValue(frontSideImage.service),
      external_documents_front_side_scan: frontSideImage.id,
      external_documents_biomatch_doc: frontSideImage.id,
      external_documents_biomatch_doc_service: clearValue(frontSideImage.service),
      ...(backSideImage.id && {
        external_documents_back_side_scan: backSideImage.id,
        external_documents_back_side_scan_service: clearValue(backSideImage.service),
      }),
      ...(selfieImage.id && {
        external_documents_biomatch_selfie: selfieImage.id,
        external_documents_biomatch_selfie_service: clearValue(selfieImage.service),
      }),
    };
  }, [
    backSideImage.id,
    backSideImage.service,
    frontSideImage.id,
    frontSideImage.service,
    selfieImage.id,
    selfieImage.service,
  ]);

  const validateExternalDocuments =
    externalDocuments.external_documents_front_side_scan?.length ||
    externalDocuments.external_documents_back_side_scan?.length
      ? externalDocuments
      : undefined;

  const getIVPayload = useCallback(() => {
    if (!isOCREnabled && !isBiomatchEnabled) {
      return {};
    }

    return buildIdentityVerificationPayload({
      documentFrontSide: frontSideImage.image,
      documentBackSide: backSideImage.image,
      selfieImage: selfieImage.image,
      ocrPassed: ocrPassed,
      biomatchPassed: biomatchPassed,
      isOCRVerification: isOCREnabled,
      isBiomatchVerification: isBiomatchEnabled,
      biomatchDistance: distance,
      externalDocumentData: validateExternalDocuments,
    });
  }, [
    distance,
    backSideImage,
    frontSideImage,
    biomatchPassed,
    isBiomatchEnabled,
    isOCREnabled,
    selfieImage,
    ocrPassed,
    validateExternalDocuments,
  ]);

  return {getIVPayload};
}

export {useBuildIVPayload, type IdentityVerificationPayload};
