import React from 'react';
import {useScreenResize, useSubHeaderConfig} from 'hooks';
import {device} from '@guestapp/ui/src/utils/device';
import poweredBy from '../../../assets/icons/powered-by.svg';
import PageSideBar from 'components/PageSideBar';
import {CustomIcon} from '@guestapp/ui';
import {
  MenuContainer,
  MenuFooter,
  MenuItems,
  PoweredByImg,
  MenuIconButton,
  CloseIconButton,
} from './styled';

type MenuProps = {
  setActiveMenu?: (state: boolean) => void;
  hideMenu?: boolean;
  showLogo?: boolean;
};

const Menu = ({showLogo}: MenuProps) => {
  const [showMenu, setShowMenu] = React.useState(false);
  const {isMatch: isMobileMode} = useScreenResize(device.tabletSubHeader);
  const {
    config: {hideMenu},
  } = useSubHeaderConfig();

  const toggleMenu = React.useCallback(() => {
    const show = !showMenu;
    setShowMenu(show);
  }, [showMenu, setShowMenu]);

  return (
    <>
      {isMobileMode && !hideMenu && (
        <MenuContainer>
          {/* <MenuBurgerIcon src={menu} width={40} height={40} onClick={toggleMenu} alt="" /> */}
          <MenuIconButton className="burger-menu__button" onClick={toggleMenu}>
            <CustomIcon name="blackMenuIcon" size={35} />
          </MenuIconButton>

          <MenuItems show={showMenu} className="burger-menu__box">
            <CloseIconButton
              onClick={toggleMenu}
              className="burger-menu__close-btn"
              showLogo={showLogo}
            >
              <CustomIcon name="closeIcon" size="24px" />
            </CloseIconButton>

            <PageSideBar />

            <MenuFooter className="burger-menu__footer">
              <PoweredByImg src={poweredBy} />
            </MenuFooter>
          </MenuItems>
        </MenuContainer>
      )}
    </>
  );
};

export {Menu};
export type {MenuProps};
