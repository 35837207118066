import React from 'react';
import {useTranslation} from 'react-i18next';
import {UPLOAD_PHOTO_STATES} from '../PhotoBox';
import Button from '../../common/Button';
import {ButtonsWrapper} from './styled';

type ButtonsProps = {
  state: UPLOAD_PHOTO_STATES;
  onChange?: () => void;
  onCapture?: () => void;
  onFinishUploadPhoto?: () => void;
  disabled?: boolean;
};
const Buttons = ({
  state,
  onChange,
  onCapture,
  onFinishUploadPhoto,
  disabled,
}: ButtonsProps) => {
  const {t} = useTranslation();

  const getButtons = React.useCallback(() => {
    switch (state) {
      case UPLOAD_PHOTO_STATES.take:
        return <Button label={t('take_photo')} onClick={onCapture} outlined />;
      case UPLOAD_PHOTO_STATES.upload:
        return (
          <>
            <Button
              label={t('confirm')}
              onClick={onFinishUploadPhoto}
              disabled={disabled}
            />
            <Button
              label={t('change_photo')}
              disabled={disabled}
              onClick={onChange}
              link
            />
          </>
        );
    }
  }, [state, t, onCapture, onFinishUploadPhoto, disabled, onChange]);

  return <ButtonsWrapper className="photo-box__btns">{getButtons()}</ButtonsWrapper>;
};

export {Buttons};
