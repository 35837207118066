import React from 'react';
import {StyleSheetManager} from 'styled-components';
import {LocalStorage} from '@guestapp/core';
import {setupSentry} from './setupSentry';
import {
  CHEKIN_ROOT_IFRAME_ID,
  EMBEDDED_STYLES_LINK,
  EMBEDDED_STYLES,
} from './utils/embedded';
import App from './App';
import Frame from './components/Frame';
import {EmotionStyleSheetProvider} from './context/emotionStyleSheet';
import {InjectingCustomStylesProvider} from './context/injectingCustomStyles';
import {FrameContextConsumer} from './context/frame';

const IS_PRODUCTION_BUILD = process.env.NODE_ENV === 'production';
if (IS_PRODUCTION_BUILD) {
  setupSentry();
}

function EmbeddedApp() {
  const styles = LocalStorage.get(EMBEDDED_STYLES);

  // useFixReactjsPopupRoot();

  React.useEffect(() => {
    LocalStorage.remove(EMBEDDED_STYLES);
    LocalStorage.remove(EMBEDDED_STYLES_LINK);
  }, [styles]);

  return (
    <Frame
      title="Embedded Chekin"
      name="chekin-pro-frame"
      role="application"
      id={CHEKIN_ROOT_IFRAME_ID}
      style={{border: 'none'}}
    >
      <FrameContextConsumer>
        {({document}) => (
          <StyleSheetManager target={document?.head}>
            <EmotionStyleSheetProvider target={document?.head}>
              <InjectingCustomStylesProvider
                target={document.head}
                styles={window.ChekinProSettings.styles}
                stylesLink={window.ChekinProSettings.stylesLink}
              >
                <App />
              </InjectingCustomStylesProvider>
            </EmotionStyleSheetProvider>
          </StyleSheetManager>
        )}
      </FrameContextConsumer>
    </Frame>
  );
}

export default EmbeddedApp;
