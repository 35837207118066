import React, {PropsWithChildren} from 'react';
import {useChekinSDK} from './ChekinSDK';

const IS_DEV_MODE = process.env.NODE_ENV === 'development';
const RECONNECT_TIMEOUT_S = 0.5;

// TODO Any type. Should be fixed
// eslint-disable-next-line
export type ContextProps<M = any> = {
  message: M & {instance_id?: string};
  clearMessage: () => void;
  cleanup: () => void;
  connect: () => void;
  send?: (message: M & {instance_id?: string}) => void;
};

const WebsocketContext = React.createContext<ContextProps>({
  send: () => {},
  clearMessage: () => {},
  cleanup: () => {},
  connect: () => {},
  message: {event_type: null},
});

function WebsocketProvider({children}: PropsWithChildren) {
  const ws = React.useRef<WebSocket>();
  const {connectWebSocket, isTokenValid, mainToken: token} = useChekinSDK();
  const [message, setMessage] = React.useState<{event_type: null} | string>('');
  const reconnectRef = React.useRef<ReturnType<typeof setTimeout>>();
  const shouldReconnectRef = React.useRef(true);

  const clearMessage = React.useCallback(() => {
    setMessage('');
  }, []);

  const cleanup = React.useCallback(() => {
    shouldReconnectRef.current = false;
    clearTimeout(reconnectRef.current);
    ws?.current?.close();
  }, []);

  const handleWebSocketOpen = React.useCallback(() => {
    clearTimeout(reconnectRef.current);

    if (IS_DEV_MODE) {
      console.log('%c [WS opened]', 'color: #35E5BC');
    }
  }, []);

  const handleWebSocketError = React.useCallback(() => {
    const isWebSocketClosed = ws?.current?.readyState !== WebSocket.OPEN;

    if (isWebSocketClosed) {
      return;
    }

    ws.current?.close();
  }, []);

  const handleWebSocketMessage = React.useCallback((event: MessageEvent) => {
    const message = JSON.parse(event.data);
    if (message.pong) return;
    setMessage(message);
  }, []);

  const handleWebSocketClose = React.useCallback(
    (event: CloseEvent, reconnectionCallback: () => void) => {
      const canReconnect = shouldReconnectRef.current && isTokenValid;

      if (canReconnect) {
        reconnectRef.current = setTimeout(() => {
          reconnectionCallback();
        }, RECONNECT_TIMEOUT_S * 1000);

        if (IS_DEV_MODE) {
          console.log(
            `%c [WS closed] Reconnecting in ${RECONNECT_TIMEOUT_S} seconds.`,
            'color: #FF2467',
          );
        }
        return;
      }

      // Sentry.captureMessage(
      //   `Websocket closed. Code: ${event?.code}, reason: ${event?.reason}, wasClean: ${event?.wasClean}`,
      // );
      if (IS_DEV_MODE) {
        console.log(`%c [WS closed]`, 'color: #FF2467');
      }
    },
    [isTokenValid],
  );

  const connect = React.useCallback(() => {
    ws.current = connectWebSocket();
    shouldReconnectRef.current = true;

    ws.current.onopen = handleWebSocketOpen;
    ws.current.onerror = handleWebSocketError;
    ws.current.onmessage = handleWebSocketMessage;
    ws.current.onclose = event => {
      handleWebSocketClose(event, connect);
    };
  }, [
    handleWebSocketClose,
    handleWebSocketError,
    handleWebSocketMessage,
    handleWebSocketOpen,
    connectWebSocket,
  ]);

  React.useEffect(
    function setupConnection() {
      const unauthorized = !token || !isTokenValid;
      const reconnect = () => {
        const isWebSocketClosed = ws?.current?.readyState !== WebSocket.OPEN;
        if (isWebSocketClosed) {
          cleanup();
          connect();
        }
      };

      if (unauthorized) {
        cleanup();
      } else {
        connect();
        window.addEventListener('focus', reconnect);
      }

      return () => {
        cleanup();
        window.removeEventListener('focus', reconnect);
      };
    },
    [cleanup, connect, isTokenValid, token],
  );

  const defaultProps = React.useMemo<ContextProps>(
    () => ({message, clearMessage, send: ws?.current?.send, cleanup, connect}),
    [cleanup, clearMessage, connect, message],
  );

  return <WebsocketContext.Provider value={defaultProps} children={children} />;
}

function useWebsocket() {
  const context = React.useContext(WebsocketContext);

  if (context === undefined) {
    throw new Error(`useWebsocket must be used within a WebsocketProvider`);
  }
  return context;
}

export {WebsocketContext, WebsocketProvider, useWebsocket};
