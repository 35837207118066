import styled from 'styled-components';
import CustomIcon from '../CustomIcon';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const TextContainer = styled.span`
  font-style: normal;
  font-weight: ${props => props.theme.fonts.fontWeight.mediumL};
  font-size: ${props => props.theme.fonts.sizes.small};
  line-height: 24px;
  letter-spacing: -0.15px;
  color: ${props => props.theme.colors.buttons.textButton};
  margin-left: 5px;
`;

export const StyledIcon = styled(CustomIcon)``;
