import {LottieAnimation} from 'utils/types';

export enum IV_DOCUMENT_ANIMATIONS {
  fontside = 'fontside',
  backside = 'backside',
  passport = 'passport',
  selfie = 'selfie',
  ocr = 'ocr',
}

type IvLottie = {
  [IV_DOCUMENT_ANIMATIONS.fontside]: LottieAnimation;
  [IV_DOCUMENT_ANIMATIONS.backside]: LottieAnimation;
  [IV_DOCUMENT_ANIMATIONS.passport]: LottieAnimation;
  [IV_DOCUMENT_ANIMATIONS.selfie]: LottieAnimation;
  [IV_DOCUMENT_ANIMATIONS.ocr]: LottieAnimation;
};
export type IvLottiesByLanguate = {
  [key: string]: IvLottie;
};
