import {useTranslation} from 'react-i18next';
import dataIncompleteIcon from 'assets/icons/incomplete.svg';
import {
  ButtonModal,
  Title,
  Description,
  WarningIcon,
  ContentModal,
  StyledModal,
} from './styled';

type ReservationNotFoundModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onNext: () => void;
};

function ReservationNotFoundModal({
  isOpen,
  onClose,
  onNext,
}: ReservationNotFoundModalProps) {
  const {t} = useTranslation();
  return (
    <StyledModal
      isOpenModalSheet={isOpen}
      handleCloseModal={onClose}
      closeOnEscape
      withCloseButton
      closeButtonType="click"
    >
      <ContentModal>
        <WarningIcon src={dataIncompleteIcon} alt="Form with a red field" />
        <Title>{t('unable_to_find_booking')}</Title>
        <Description>{t('unable_to_find_booking_description')}</Description>
      </ContentModal>
      <ButtonModal onClick={onNext} label={t('ok')} />
    </StyledModal>
  );
}

export {ReservationNotFoundModal};
