import styled from 'styled-components';
import {device} from 'styled/device';
import {Box, Row} from '../../../../styled/common';

export const Wrapper = styled.div`
  display: block;
  padding: 0 20px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.15px;
  color: #152946;
  margin-bottom: 24px;
`;

export const RowItem = styled(Row)`
  grid-column-gap: 20px;
  font-weight: 700;
  font-family: ${props => props.theme.fonts.fontFamily.body.font_name};
  color: ${props =>
    props.theme.hasTemplate ? props.theme.colors.branding.fontSecondary : '#152946'};
`;

export const FixedRowItem = styled(RowItem)`
  padding-left: 8px;
  font-family: ${props => props.theme.fonts.fontFamily.body.font_name};
  color: ${props =>
    props.theme.hasTemplate ? props.theme.colors.branding.fontSecondary : '#152946'};
`;

export const InfoBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto 48px;

  & > div > svg {
    margin: 0 24px;
    position: relative;
    bottom: -5px;
    line {
      stroke: ${({theme}) => theme.colors.branding.primary};
    }
  }

  @media (min-width: ${device.tablet}) {
    /* width: 40%; */
    width: 340px;
    height: 237px;
  }

  @media (min-width: ${device.laptop}) {
    width: 340px;
    margin-bottom: 48px;
  }
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${props =>
    props.theme.hasTemplate ? props.theme.colors.branding.fontPrimary : '#152946'};
  max-width: 312px;
  margin: 0 auto 24px;
  @media (min-width: ${device.tablet}) {
    margin: 0 auto 48px;
    font-size: 16px;
    max-width: 550px;
  }
`;

export const ContentBoxInfo = styled.div`
  margin: 0 auto 24px;
  @media (min-width: ${device.tablet}) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
