import {StyledButton} from './styled';
import {useTranslation} from 'react-i18next';
import {OFFER_CONFIRMATION_TYPES} from '@guestapp/sdk';
import {useTheme} from 'styled-components';

type Props = {
  type: OFFER_CONFIRMATION_TYPES;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
};
const AddToCartButton = ({className, disabled, type, onClick}: Props) => {
  const {t} = useTranslation();
  const theme = useTheme();

  return (
    <StyledButton
      className={className}
      disabled={disabled}
      onClick={onClick}
      iconName={type === OFFER_CONFIRMATION_TYPES.manual ? 'calendar' : 'shoppingCart'}
      iconProps={{
        color: theme.colors.buttons.mainButtonTextBackground,
        position: 'left',
      }}
      label={
        type === OFFER_CONFIRMATION_TYPES.manual
          ? t('book_experience_view.request_deal_button')
          : t('book_experience_view.add_to_cart_button')
      }
    />
  );
};

export {AddToCartButton};
