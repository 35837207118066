const PATTERNS = {
  dniAndDl: /^[0-9]{8}[a-zA-Z]$/,
  dni: /^[0-9]{8}[a-zA-Z]$/,
  nie: /^[a-zA-Z][0-9]{7}[a-zA-Z]$/,
  name: /^[A-Za-zÀ-ÖØ-öø-ÿ]*( *[A-Za-zÀ-ÖØ-öø-ÿ *]+)*( *[а-яА-ЯёЁА́-Я́а́-я́*]+)*$/,
  nameWithoutAccents: /^[A-Za-z]*( *[A-Za-z äöüßéèòàùì']+)$/,
  fiscalCode: /^[A-Za-z0-9]+$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\])|(\[IPv6:(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))\])|([A-Za-z0-9]([A-Za-z0-9-]*[A-Za-z0-9])*(\.[A-Za-z]{2,})+))$/,
  emailWithSpecialChars:
    /^\s*([\wA-Za-z äöüßéèòàùì'.]+)@([\w-]+\.)?([\w]{2,}\.).?([\w]{2,})\s*$/,
  docNumber: /^\s*[a-zA-Z0-9\s]*$/,
  docNumberWithDots: /^\s*[a-zA-Z0-9 .:\s]*$/,
  time: /\d{2}:\d{2}/,
  numbers: /\D/g,
  onlyNumbers: /^[0-9]*$/,
};

enum LANGUAGE_CODES {
  en = 'en',
  es = 'es',
  it = 'it',
  de = 'de',
  fr = 'fr',
  hu = 'hu',
  ru = 'ru',
  cs = 'cs',
  bg = 'bg',
  pt = 'pt',
  ro = 'ro',
  et = 'et',
  pl = 'pl',
  ca = 'ca',
  el = 'el',
  uk = 'uk',
}

const LANGUAGES_ISO_3: {[key: string]: string} = {
  es: 'spa',
  it: 'ita',
  en: 'eng',
  fr: 'fra',
  ru: 'rus',
  pt: 'por',
  de: 'deu',
  hu: 'hun',
  cs: 'ces',
  ro: 'ron',
  fn: 'fin',
  bg: 'bul',
  et: 'est',
};

export {PATTERNS, LANGUAGE_CODES, LANGUAGES_ISO_3};
