import styled from 'styled-components';
import {device} from 'styled/device';

export const SideBarContainer = styled.div`
  height: 100%;
  position: relative;
  z-index: 1;
  background-color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.boxesModals : theme.colors.$p6};
  gap: 40px;
  display: none;

  @media (min-width: ${device.tablet}) {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const SideBarTitle = styled.span`
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.fontPrimary : theme.colors.$p10};
`;

export const SideBarLogo = styled.img`
  width: 240px;
  height: 88px;
  object-fit: contain;
`;

export const SideBarPropertyName = styled.span`
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0.36px;
  text-align: center;
  color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.fontPrimary : theme.colors.$p10};
`;
