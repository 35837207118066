import {useTranslation} from 'react-i18next';
import {Wrapper, Text} from './styled';
import {ReactElement} from 'react';

type EmbeddedErrorViewProps = {
  text?: string | ReactElement;
};
function EmbeddedErrorView({text}: Readonly<EmbeddedErrorViewProps>) {
  const {t} = useTranslation();

  return (
    <Wrapper className="embedded-error-view">
      <Text className="embedded-error-view__text">
        {text ?? t('not_found_has_missing')}
      </Text>
    </Wrapper>
  );
}

export {EmbeddedErrorView};
