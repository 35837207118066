import React, {PropsWithChildren} from 'react';
import {To, useLocation, useNavigate} from 'react-router-dom';
import {usePath} from 'hooks';
import {goToExternalLink} from 'pages/AddPersonalDataForm/utils';
import {PATHS} from 'Routes';
import {SessionStorage} from '@guestapp/core';

type ContextProps = {
  isOpenDiscardModal: boolean;
  setLinkToGo: React.Dispatch<React.SetStateAction<string>>;
  setIsFormTouched: React.Dispatch<React.SetStateAction<boolean>>;
  closeDiscardModal: () => void;
  setShouldOpenDiscardModal: React.Dispatch<React.SetStateAction<boolean>>;
  discardChangesAndRestart: () => void;
  linkToGo: string;
  isFormTouched: boolean;
  checkIfOpenDiscardModalAndRedirect: (link: string) => void;
};

function getDefLinkToGo(link: string) {
  return link === '-1' ? -1 : link;
}

const OpenModalsContext = React.createContext<ContextProps>({
  isOpenDiscardModal: false,
  setLinkToGo: () => {},
  setIsFormTouched: () => {},
  closeDiscardModal: () => {},
  setShouldOpenDiscardModal: () => {},
  discardChangesAndRestart: () => {},
  linkToGo: '',
  isFormTouched: false,
  checkIfOpenDiscardModalAndRedirect: () => {},
});

function OpenModalsProvider({children}: PropsWithChildren) {
  const [isOpenDiscardModal, setIsOpenDiscardModal] = React.useState(false);
  const [isFormTouched, setIsFormTouched] = React.useState(false);
  const [linkToGo, setLinkToGo] = React.useState('');
  const [shouldOpenDiscardModal, setShouldOpenDiscardModal] = React.useState(false);
  const {getFullPath} = usePath();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const closeDiscardModal = React.useCallback(() => {
    setIsOpenDiscardModal(false);
    setShouldOpenDiscardModal(false);
    setLinkToGo('');
  }, []);

  const discardChangesAndRestart = React.useCallback(() => {
    SessionStorage.clear();
    closeDiscardModal();
    const isExternalLink = linkToGo.includes('https');

    if (isExternalLink) {
      goToExternalLink(linkToGo);
    } else {
      navigate(getDefLinkToGo(linkToGo) as To);
    }
  }, [linkToGo, navigate, closeDiscardModal]);

  React.useEffect(() => {
    if (shouldOpenDiscardModal && isFormTouched) {
      setIsOpenDiscardModal(true);
    } else {
      if (Boolean(linkToGo) && !isFormTouched) {
        setShouldOpenDiscardModal(false);
        navigate(getDefLinkToGo(linkToGo) as To);
        setLinkToGo('');
      }
    }
  }, [linkToGo, shouldOpenDiscardModal, isFormTouched, navigate, setLinkToGo]);

  const checkIfOpenDiscardModalAndRedirect = React.useCallback(
    (link: string) => {
      const linkToGo = link.includes('https') || link === '-1' ? link : getFullPath(link);

      if (pathname.includes(PATHS.addPersonalDataForm)) {
        setLinkToGo(linkToGo);
        setShouldOpenDiscardModal(true);
        return;
      }
      setShouldOpenDiscardModal(false);

      navigate(getDefLinkToGo(linkToGo) as To);
    },
    [getFullPath, pathname, navigate, setLinkToGo, setShouldOpenDiscardModal],
  );

  const defaultProps = React.useMemo<ContextProps>(
    () => ({
      isOpenDiscardModal,
      closeDiscardModal,
      setIsFormTouched,
      isFormTouched,
      setLinkToGo,
      setShouldOpenDiscardModal,
      discardChangesAndRestart,
      linkToGo,
      checkIfOpenDiscardModalAndRedirect,
    }),
    [
      checkIfOpenDiscardModalAndRedirect,
      closeDiscardModal,
      discardChangesAndRestart,
      isFormTouched,
      isOpenDiscardModal,
      linkToGo,
    ],
  );

  return <OpenModalsContext.Provider value={defaultProps} children={children} />;
}

function useOpenModals() {
  const context = React.useContext(OpenModalsContext);
  if (context === undefined) {
    throw new Error('useOpenModals must be used within a OpenModalsProvider');
  }
  return context;
}

export {OpenModalsContext, OpenModalsProvider, useOpenModals};
