import styled, {css} from 'styled-components';

type RadioWrapperProps = {
  disabled?: boolean;
};

export const RadioWrapper = styled.div<RadioWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 10px;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `};
`;

type RadioOptionProps = {
  isCustomBox?: boolean;
  isChecked: boolean;
};

export const RadioOption = styled.div<RadioOptionProps>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${props =>
    props.isCustomBox &&
    css`
      width: 100%;
      padding: 16px;
      border-radius: 6.23px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: ${({theme}) =>
        theme.hasTemplate ? theme.colors.branding.boxesModals : theme.colors.$p6};
    `};

  ${props =>
    props.isChecked &&
    props.isCustomBox &&
    css`
      width: 100%;
      padding: 16px;
      border-radius: 6.23px;
      border: 1px solid
        ${({theme}) => (theme.hasTemplate ? theme.colors.branding.primary : '#385bf8')};
      background: ${({theme}) =>
        theme.hasTemplate
          ? theme.colors.navigationMenu.selectedItemBackground
          : '#ebebf8'};

      & .multi-radio__label {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0;
        text-align: left;
      }
    `};
`;

export const RadioInput = styled.input`
  outline: none;
  user-select: none;
  position: absolute;
  z-index: -1;
  opacity: 0;
`;

// export const RadioInput = styled.input`
//   appearance: none;
//   margin: 0;
//   width: 20px;
//   height: 20px;
//   border: 2px solid ${props => props.theme.colors.branding.primary};
//   border-radius: 50%;
//   transition: all 0.1s ease-in-out;

//   &::after {
//     content: '';
//     display: block;
//     border-radius: 50%;
//     width: 12px;
//     height: 12px;
//     margin: 2px;
//   }
//   &:checked::after {
//     background-color: ${props => props.theme.colors.branding.primary};
//   }
//   &:hover::after {
//     background-color: ${props => props.theme.colors.branding.primary};
//   }

// `;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.fonts.sizes.medium};
  font-family: ${props => props.theme.fonts.fontFamily.body.font_name};
  user-select: none;
  vertical-align: middle;
  cursor: pointer;
  color: ${props => props.theme.colors.branding.fontSecondary};

  ${RadioInput} + &::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid
      ${props =>
        props.theme.hasTemplate
          ? props.theme.colors.branding.primary
          : props.theme.colors.$p2};
    border-radius: 50%;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    background-size: 50% 50%;
    box-sizing: border-box;
    transition: background-color 0.1s ease-in-out;
    outline: none;
  }

  ${RadioInput}:not(:disabled):not(:checked) + &:hover::before {
    background: ${props => props.theme.colors.$p6};
  }

  ${RadioInput}:not(:disabled):active + &::before {
    background-color: ${props => props.theme.colors.$n10};
    border-color: ${props => props.theme.colors.$n10};
  }

  ${RadioInput}:focus + &::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  ${RadioInput}:focus:not(:checked) + &::before {
    border-color: ${props => props.theme.colors.$n11};
  }

  ${RadioInput}:checked + &::before {
    border: 3px solid ${props => props.theme.colors.branding.backgroundMain};
    background-color: ${props => props.theme.colors.branding.primary};
    padding: 2px;
    box-shadow: 0 0 0 2px ${props => props.theme.colors.branding.primary};
  }

  ${RadioInput}:disabled + &::before {
    background-color: #e9ecef;
  }
`;
