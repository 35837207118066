import {Paginated, SelectOptionType} from './types';
import {
  CityType,
  DistrictType,
  LocationType,
  MunicipalityType,
  ProvincesType,
} from './locations';

class LocationOptions {
  static checkEmptyArray<T>(results: T) {
    return !results || !Array.isArray(results);
  }

  static getSpanishCitiesAsOptions(locations: Paginated<{name: string; code: string}>) {
    const results = locations?.results;
    if (LocationOptions.checkEmptyArray(results)) {
      return [];
    }

    return results.map(option => {
      return {
        label: option.name,
        value: option.code,
      };
    });
  }

  static getCountriesAsOptions(
    locations: Paginated<LocationType>,
  ): SelectOptionType<LocationType>[] {
    const results = locations?.results;
    if (LocationOptions.checkEmptyArray(results)) {
      return [];
    }

    return results?.map(l => {
      return {
        label: l?.country?.name,
        value: l?.country?.code,
        data: l,
      };
    });
  }

  static getProvincesAsOptions(
    locations: Paginated<ProvincesType | MunicipalityType>,
  ): SelectOptionType[] {
    const results = locations?.results;
    if (LocationOptions.checkEmptyArray(results)) {
      return [];
    }

    return results?.map(l => {
      return {
        label: l?.division_level_2?.name,
        value: l?.division_level_2?.code,
      };
    });
  }

  static getCitiesAsOptions(locations: Paginated<CityType>): SelectOptionType[] {
    const results = locations?.results;
    if (LocationOptions.checkEmptyArray(results)) {
      return [];
    }

    return results?.map(l => {
      return {
        value: l?.division_level_3?.code,
        label: l?.division_level_3?.name,
      };
    });
  }

  static getDistrictsAsOptions(locations: Paginated<DistrictType>): SelectOptionType[] {
    const results = locations?.results;
    if (LocationOptions.checkEmptyArray(results)) {
      return [];
    }

    return results?.map(l => {
      return {
        label: l?.division_level_1?.name,
        value: l?.division_level_1?.code,
      };
    });
  }

  static getPhoneCodesAsOptions(locations: Paginated<LocationType>): SelectOptionType[] {
    const results = locations?.results;
    if (LocationOptions.checkEmptyArray(results)) {
      return [];
    }

    return results?.map(l => {
      const phoneCodeValue = `+${l.phone_code}`;

      return {
        value: phoneCodeValue,
        label: `${l?.country?.name} ${phoneCodeValue}`,
      };
    });
  }
}

export default LocationOptions;
