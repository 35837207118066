import {GuestFormAdditionalDataApi} from '../api';
import {FetcherSettings} from '../api/apiClient';

class GuestFormAdditionalDataService {
  private readonly purposeOfStayApi: GuestFormAdditionalDataApi;

  constructor() {
    this.purposeOfStayApi = new GuestFormAdditionalDataApi();
  }

  getPurposeOfStay(resolverSettings?: FetcherSettings) {
    return this.purposeOfStayApi.getPurposeOfStay(resolverSettings);
  }
}

export {GuestFormAdditionalDataService};
