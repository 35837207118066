import {InitParams} from './utils/types';
import {
  AuthService,
  CustomFormService,
  DocumentsService,
  GuestGroupService,
  GuestService,
  HousingService,
  IdentityVerificationService,
  LocationService,
  PaymentsService,
  PropertyProtectionService,
  GuestFormAdditionalDataService,
  ReservationService,
  TaxesService,
  UpsellingService,
  ValidatorService,
  WebSocketService,
  AllianzInsuranceService,
  LocksService,
  ThemeServices,
} from './services';

class ChekinAPI {
  static initParams: InitParams = {apikey: ''};
  static mode: 'regular' | 'sdk' = 'regular';

  readonly reservation;
  readonly guest;
  readonly validations;
  readonly locations;
  readonly identityVerification;
  readonly taxes;
  readonly guestFormAdditionalData;
  readonly auth;
  readonly housing;
  readonly documents;
  readonly customForm;
  readonly guestGroup;
  readonly payments;
  readonly websocket;
  readonly upselling;
  readonly propertyProtection;
  readonly allianzInsurance;
  readonly locks;
  readonly theme;

  constructor(data: InitParams) {
    if (!data.isChekinConsumer) {
      if (!data.apikey) {
        throw new Error('Chekin API key not found or not valid');
      }
    }

    ChekinAPI.initParams = data;
    ChekinAPI.mode = data.isChekinConsumer ? 'regular' : 'sdk';

    const validationsService = new ValidatorService();
    this.validations = {
      validateSex: validationsService.validateSex.bind(validationsService),
      validatePhoneNumber:
        validationsService.validatePhoneNumber.bind(validationsService),
      validatePurposeOfStay:
        validationsService.validatePurposeOfStay.bind(validationsService),
    };

    const locationsService = new LocationService();
    this.locations = {
      getLocations: locationsService.getLocations.bind(locationsService),
      getCountries: locationsService.getCountries.bind(locationsService),
      getDistricts: locationsService.getDistricts.bind(locationsService),
      getMunicipalities: locationsService.getMunicipalities.bind(locationsService),
      getCities: locationsService.getCities.bind(locationsService),
      getProvinces: locationsService.getProvinces.bind(locationsService),
      getHousingLocation: locationsService.getHousingLocation.bind(locationsService),
      getSpanishResidenceCitiesList:
        locationsService.getSpanishResidenceCitiesList.bind(locationsService),
      getCountriesList: locationsService.getCountriesList.bind(locationsService),
      getProvincesList: locationsService.getProvincesList.bind(locationsService),
      getCitiesList: locationsService.getCitiesList.bind(locationsService),
      getDistrictsList: locationsService.getDistrictsList.bind(locationsService),
      getMunicipalitiesList:
        locationsService.getMunicipalitiesList.bind(locationsService),
      getPhoneCodesList: locationsService.getPhoneCodesList.bind(locationsService),
      getPhoneCodes: locationsService.getPhoneCodes.bind(locationsService),
    };

    const ivService = new IdentityVerificationService();
    this.identityVerification = {
      sendDocumentForFaceDetection:
        ivService.sendDocumentForFaceDetection.bind(ivService),
      checkDocumentFaceDetectionStatus:
        ivService.checkDocumentFaceDetectionStatus.bind(ivService),
      sendSelfieForFaceDetection: ivService.sendSelfieForFaceDetection.bind(ivService),
      checkSelfieFaceDetection: ivService.checkSelfieFaceDetection.bind(ivService),
      compareSelfieAndDocumentFaces:
        ivService.compareSelfieAndDocumentFaces.bind(ivService),
      checkSelfieAndDocumentFacesComparingStatus:
        ivService.checkSelfieAndDocumentFacesComparingStatus.bind(ivService),
      sendImageForOCR: ivService.sendImageForOCR.bind(ivService),
      checkImageOCRStatus: ivService.checkImageOCRStatus.bind(ivService),
    };

    const taxesService = new TaxesService();
    this.taxes = {
      getAllExemptions: taxesService.getAllExemptions.bind(taxesService),
      getSeason: taxesService.getSeason.bind(taxesService),
      getSeasonGuests: taxesService.getSeasonGuests.bind(taxesService),
      calculateGuestTax: taxesService.calculateGuestTax.bind(taxesService),
      initializeGuestTaxes: taxesService.initGuestTaxes.bind(taxesService),
    };

    const guestFormAdditionalDataService = new GuestFormAdditionalDataService();
    this.guestFormAdditionalData = {
      getPurposeOfStay: guestFormAdditionalDataService.getPurposeOfStay.bind(
        guestFormAdditionalDataService,
      ),
    };

    const documentsService = new DocumentsService();
    this.documents = {
      getContracts: documentsService.getContracts.bind(documentsService),
      getPrivacyPolicy: documentsService.getPrivacyPolicy.bind(documentsService),
    };

    const customFormService = new CustomFormService();
    this.customForm = {
      getCustomFormById: customFormService.getCustomFormById.bind(customFormService),
      buildCustomFields: customFormService.buildCustomFields.bind(customFormService),
      saveToFields: customFormService.saveToFields.bind(customFormService),
      getCustomForm: customFormService.getCustomForm.bind(customFormService),
    };

    const guestGroupService = new GuestGroupService();
    this.guestGroup = {
      getGuestGroup: guestGroupService.getGuestGroup.bind(guestGroupService),
      patchGuestGroup: guestGroupService.patchGuestGroup.bind(guestGroupService),
    };

    const paymentsService = new PaymentsService();
    this.payments = {
      getPaymentsSettings: paymentsService.getPaymentsSettings.bind(paymentsService),
      checkExpiredReservationPayments:
        paymentsService.checkExpiredReservationPayments.bind(paymentsService),
      createMultiPayment: paymentsService.createMultiPayment.bind(paymentsService),
      getMultiPayment: paymentsService.getMultiPayment.bind(paymentsService),
      getPaymentsCart: paymentsService.getPaymentsCart.bind(paymentsService),
      getOrderHistory: paymentsService.getOrderHistory.bind(paymentsService),
    };

    const websocketService = new WebSocketService();
    this.websocket = {
      connectWebSocket: websocketService.connectWebSocket.bind(websocketService),
    };

    const guestService = new GuestService();
    this.guest = {
      getSchema: guestService.getSchema.bind(guestService),
      createOrUpdate: guestService.createOrUpdate.bind(guestService),
      createGuestWithMinData: guestService.createWithMinData.bind(guestService),
      deleteGuest: guestService.delete.bind(guestService),
      getGuest: guestService.getOne.bind(guestService),
    };

    const reservationService = new ReservationService();
    this.reservation = {
      getReservation: reservationService.getOne.bind(reservationService),
      getSummary: reservationService.getSummary.bind(reservationService),
      getBookingReference:
        reservationService.getBookingReference.bind(reservationService),
      createReservation: reservationService.createReservation.bind(reservationService),
      updateReservation: reservationService.updateReservation.bind(reservationService),
    };

    const authService = new AuthService();
    this.auth = {
      getAuthToken: authService.getAuthToken.bind(authService),
      init: authService.init.bind(authService, ChekinAPI.initParams),
      initChekin: authService.initChekin.bind(authService),
    };

    const housingService = new HousingService();
    this.housing = {
      getHousing: housingService.getOne.bind(housingService),
      searchReservation: housingService.searchReservation.bind(housingService),
      getLocation: housingService.getLocation.bind(housingService),
    };

    const upsellingService = new UpsellingService();
    this.upselling = {
      deleteDeal: upsellingService.deleteDeal.bind(upsellingService),
      getDeals: upsellingService.getDeals.bind(upsellingService),
      getOffers: upsellingService.getOffers.bind(upsellingService),
      getOffer: upsellingService.getOffer.bind(upsellingService),
      createOrUpdateThirdPartyDeal:
        upsellingService.createOrUpdateThirdPartyDeal.bind(upsellingService),
      deleteThirdPartyDeal: upsellingService.deleteDeal.bind(upsellingService),
      createOrUpdateBuildLock:
        upsellingService.createOrUpdateBuildLock.bind(upsellingService),
      createBulkDeal: upsellingService.createBulkDeal.bind(upsellingService),
      fetchCheckIfOffersExists:
        upsellingService.fetchCheckIfOffersExists.bind(upsellingService),
    };

    const propertyProtectionService = new PropertyProtectionService();
    this.propertyProtection = {
      getPropertyProtectionsByReservation:
        propertyProtectionService.getPropertyProtectionsByReservation.bind(
          propertyProtectionService,
        ),
      mutateInsuranceSetup: propertyProtectionService.mutateInsuranceSetup.bind(
        propertyProtectionService,
      ),
      getInsuranceSetup: propertyProtectionService.getInsuranceSetup.bind(
        propertyProtectionService,
      ),
    };

    const allianzInsuranceService = new AllianzInsuranceService();
    this.allianzInsurance = {
      getPlanByUser: allianzInsuranceService.getPlanByUser.bind(allianzInsuranceService),
      getOrders: allianzInsuranceService.getOrders.bind(allianzInsuranceService),
      calculateTotalPrice: allianzInsuranceService.calculateTotalPrice.bind(
        allianzInsuranceService,
      ),
      getAllianzDocuments: allianzInsuranceService.getDocuments.bind(
        allianzInsuranceService,
      ),
    };
    const locksService = new LocksService();
    this.locks = {
      getLocks: locksService.getLooks.bind(locksService),
      getLockConditions: locksService.getLockConditions.bind(locksService),
      unlockDoor: locksService.unlockDoor.bind(locksService),
    };

    const themeServices = new ThemeServices();
    this.theme = {
      getCustomTheme: themeServices.getCustomTheme.bind(themeServices),
    };
  }
}

export default ChekinAPI;
