import React, {ReactElement, useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {FrameContextProvider} from '../../context/frame';
import {StyledIFrame} from './styled';

const Content = ({children}: {children: ReactElement}) => {
  React.useEffect(() => {
    console.log('The Embedded Chekin is mounted');

    return () => {
      console.log('Embedded Chekin is unmounted');
    };
  }, []);

  return children;
};

interface FrameProps
  extends React.DetailedHTMLProps<
    React.IframeHTMLAttributes<HTMLIFrameElement>,
    HTMLIFrameElement
  > {
  style?: React.CSSProperties;
  head?: React.ReactNode;
  initialContent?: string;
  mountTarget?: string;
  children?: React.ReactNode | React.ReactNode[];
}

// Re-used from https://github.com/ryanseddon/react-frame-component v5.2.6
// Article https://medium.com/@ryanseddon/rendering-to-iframes-in-react-d1cb92274f86
const Frame = React.forwardRef<HTMLIFrameElement, FrameProps>((props, ref) => {
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const nodeRef = useRef<HTMLIFrameElement | null>(null);
  const handleLoadTimeoutRef = useRef<ReturnType<typeof setInterval>>();

  const handleLoad = React.useCallback(() => {
    clearInterval(handleLoadTimeoutRef.current);
    if (!iframeLoaded) {
      setIframeLoaded(true);
    }
  }, [iframeLoaded]);

  useEffect(() => {
    const doc = nodeRef.current?.contentDocument;

    if (doc) {
      nodeRef.current?.contentWindow?.addEventListener('DOMContentLoaded', handleLoad);
    }

    return () => {
      if (doc) {
        nodeRef.current?.contentWindow?.removeEventListener(
          'DOMContentLoaded',
          handleLoad,
        );
      }
    };
  }, [handleLoad, iframeLoaded]);

  useEffect(() => {
    handleLoadTimeoutRef.current = setInterval(() => {
      handleLoad();
    }, 500);

    return () => {
      clearInterval(handleLoadTimeoutRef.current);
    };
  }, [handleLoad]);

  const getDoc = () => (nodeRef.current ? nodeRef.current.contentDocument : null);

  const getMountTarget = () => {
    const doc = getDoc();
    if (props.mountTarget) {
      return doc?.querySelector(props.mountTarget);
    }
    return doc?.body;
  };

  const setRef = (node: HTMLIFrameElement | null) => {
    nodeRef.current = node;

    if (ref && typeof ref === 'function') {
      ref(node);
    } else if (ref) {
      ref.current = node;
    }
  };

  const renderFrameContents = () => {
    if (!nodeRef.current || !iframeLoaded) {
      return null;
    }

    const doc = getDoc();

    if (!doc) {
      return null;
    }

    // @ts-ignore
    const win = doc.defaultView || doc.parentView;

    const contents = (
      <Content>
        <FrameContextProvider
          value={{document: doc, window: win, frame: nodeRef.current}}
        >
          <div className="frame-content">{props.children}</div>
        </FrameContextProvider>
      </Content>
    );

    const mountTarget = getMountTarget();

    return [
      ReactDOM.createPortal(props.head, doc.head),
      // @ts-ignore
      ReactDOM.createPortal(contents, mountTarget),
    ];
  };

  const {head, initialContent, mountTarget, ...rest} = props;

  const iframeProps = {
    ...rest,
    srcDoc: initialContent,
    ref: setRef,
    onLoad: () => handleLoad(),
  };

  return (
    <StyledIFrame title="Frame" {...iframeProps}>
      {iframeLoaded && renderFrameContents()}
    </StyledIFrame>
  );
});

export default Frame;
