import {useTranslation} from 'react-i18next';
import {SideBarContainer, SideBarLogo, SideBarPropertyName, SideBarTitle} from './styled';
import usePropertyLinkInfo from 'components/PageSideBarUniversalLink/usePropertyLinkInfo';

function SideBarUniversalLink() {
  const {t} = useTranslation();
  const {logo, title, isLoaded} = usePropertyLinkInfo();

  return (
    <SideBarContainer>
      {isLoaded && (
        <>
          <SideBarTitle>{t('welcome_to')}</SideBarTitle>
          {logo && <SideBarLogo src={logo} alt="" width={240} height={88} />}
          {title && <SideBarPropertyName>{title}</SideBarPropertyName>}
        </>
      )}
    </SideBarContainer>
  );
}

export {SideBarUniversalLink};
