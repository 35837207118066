import React from 'react';
import clsx from 'clsx';
import {PopupActions, PopupProps} from '../Popup';
import {useScreenResize} from '../../../hooks';
import {device} from '../../../styled/device';
import {MobileDropdown} from './MobileDropdown';
import {Wrapper, Option, PopupStyled} from './styled';

type DropdownOption = {
  id: string | number;
  label: string;
  onClick: () => void;
  hidden?: boolean;
};

type DropdownProps = {
  trigger: PopupProps['trigger'];
  options?: DropdownOption[];
  children?: React.ReactNode | string | null;
  position?: PopupProps['position'];
  disabled?: boolean;
  className?: string;
};
function Dropdown({
  trigger,
  options,
  children,
  position,
  disabled,
  className,
}: DropdownProps) {
  const {isMatch: isMobileMode} = useScreenResize(device.mobileXL);
  const tooltipRef = React.useRef<PopupActions>(null);

  const handleClick = React.useCallback(
    (onClick: () => void) => () => {
      if (disabled) return;
      onClick();
      tooltipRef?.current?.close();
    },
    [disabled],
  );

  return (
    <Wrapper className={clsx(className, 'dropdown')}>
      {!isMobileMode && (
        <PopupStyled
          ref={tooltipRef}
          closeOnDocumentClick
          closeOnEscape
          arrow={false}
          trigger={trigger}
          position={position}
          disabled={disabled}
          on={['click']}
          className="dropdown__popup"
        >
          {options?.map(option => {
            if (option.hidden) return null;
            return (
              <Option key={option.label} onClick={handleClick(option.onClick)}>
                {option.label}
              </Option>
            );
          })}
          {children}
        </PopupStyled>
      )}
      {isMobileMode && (
        <MobileDropdown
          disabled={disabled}
          trigger={trigger}
          options={options}
          children={children}
        />
      )}
    </Wrapper>
  );
}

export {Dropdown};
export type {DropdownProps};
