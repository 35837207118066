import React, {InputHTMLAttributes} from 'react';
import {ErrorType} from '../../utils/types';
import {withEmptyDetection} from '../../hocs';
import redXIcon from 'assets/icons/credit-card-red-x.svg';
import Tooltip from '../Tooltip';
import {Fieldset} from '../Fieldset';
import {
  FieldQuestionMarkButton,
  RelativeWrapper,
  StyledInput,
  TooltipTrigger,
  WrapperInput,
  StatusIcon,
  InputErrorMessage,
  StatusCustomIcon,
  ContentStatusIcon,
} from './styled';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  invalid?: boolean;
  error?: ErrorType;
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  tooltipContent?: string;
  isMobileMode?: boolean;
  showStatusIcon?: boolean;
  empty?: boolean;
  onReset?: () => void;
};

const defaultProps: InputProps = {
  onChange: undefined,
  label: '',
  invalid: undefined,
  error: '',
  inputMode: undefined,
  autoComplete: 'on',
  isMobileMode: true,
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      empty,
      onChange,
      className,
      label,
      invalid,
      disabled,
      name,
      error,
      children,
      tooltipContent,
      onBlur,
      onFocus,
      isMobileMode,
      showStatusIcon,
      onReset,
      ...props
    },
    ref,
  ) => {
    const position = isMobileMode ? 'top center' : 'top right';
    const [isFocused, setIsFocused] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const touchedRef = React.useRef(false);
    const isCompleted = !empty && touchedRef.current;

    React.useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const handleLabelClick = React.useCallback(() => {
      inputRef.current?.focus();
      setIsFocused(true);
    }, [setIsFocused]);

    const handleFocus = React.useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        onFocus?.(event);
        setIsFocused(true);
      },
      [setIsFocused, onFocus],
    );

    const handleBlur = React.useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        onBlur?.(event);
        touchedRef.current = true;
        setIsFocused(false);
      },
      [onBlur, setIsFocused],
    );

    return (
      <WrapperInput disabled={disabled} className="input-wrapper">
        <RelativeWrapper className="input">
          {children || (
            <StyledInput
              className={'input__input'}
              ref={inputRef}
              data-testid="input"
              isFocused={isFocused}
              onChange={onChange}
              disabled={disabled}
              aria-label={name}
              name={name}
              invalid={!!error}
              isEmpty={empty}
              placeholder={''}
              onFocus={handleFocus}
              onBlur={handleBlur}
              {...props}
            />
          )}
          {Boolean(error && showStatusIcon) && (
            <StatusIcon
              src={redXIcon}
              alt=""
              width={24}
              height={24}
              error={!!error}
              className={'input__status-icon'}
              onClick={() => {
                if (error && onReset) onReset();
              }}
            />
          )}
          {Boolean(isCompleted && showStatusIcon) && (
            <ContentStatusIcon>
              <StatusCustomIcon
                name="blueTickIcon"
                size={24}
                className={'input__status-icon'}
              />
            </ContentStatusIcon>
          )}
          <Fieldset
            legend={label}
            onClick={handleLabelClick}
            invalid={!!error}
            isFocused={isFocused}
            isEmpty={empty}
            isActivated={!empty || isFocused}
            label={
              <>
                {label}
                {tooltipContent && (
                  <Tooltip position={position} label={tooltipContent}>
                    <TooltipTrigger>
                      <FieldQuestionMarkButton />
                    </TooltipTrigger>
                  </Tooltip>
                )}
              </>
            }
          />
        </RelativeWrapper>

        {error && <InputErrorMessage>{error as string}</InputErrorMessage>}
      </WrapperInput>
    );
  },
);

Input.defaultProps = defaultProps;
const InputController = withEmptyDetection<HTMLInputElement, InputProps>(
  React.memo(Input),
);
export {Input, InputController};
