import {Template} from '@guestapp/sdk';
import checkinLogoIcon from 'assets/icons/accept-icon.svg';
import {GUESTAPP_NAVIGATION_MODE} from 'utils/constants';

type GetLogoProps = {
  housingLogo?: string;
  template?: Partial<Template>;
  isSuccess?: boolean;
};

export const getLogo = ({housingLogo, template, isSuccess}: GetLogoProps) => {
  const templateLogo = template?.navigation_title_logo;
  const navigationTitleType = template?.navigation_title_type;

  if (!isSuccess || navigationTitleType === GUESTAPP_NAVIGATION_MODE.property_name) {
    return undefined;
  }
  if (templateLogo) {
    return templateLogo;
  }
  if (housingLogo) {
    return housingLogo;
  }
  return checkinLogoIcon;
};
