import ChekinAPI from '../ChekinAPI';
import {LocalStorage, MemoryStorage} from '@guestapp/core';
import {TOKEN_STORAGE_KEY} from './constants';

function getTokenFromStorage(): string | null {
  let token = null;
  const isChekinConsumer = ChekinAPI.initParams?.isChekinConsumer;

  if (!isChekinConsumer) {
    return MemoryStorage.get<string>(TOKEN_STORAGE_KEY);
  }

  try {
    const storageToken = LocalStorage.get<string>(TOKEN_STORAGE_KEY);
    if (storageToken !== null) {
      token = storageToken;
    }
  } catch (err) {
    console.error(err);
  }

  return token;
}

function persistTokenToLocalStorage(token: null | string = ''): void {
  const isChekinConsumer = ChekinAPI.initParams?.isChekinConsumer;

  if (isChekinConsumer) {
    LocalStorage.set(TOKEN_STORAGE_KEY, token);
  } else {
    MemoryStorage.set(TOKEN_STORAGE_KEY, token);
  }
}

function removeTokenFromLocalStorage(): void {
  const isChekinConsumer = ChekinAPI.initParams?.isChekinConsumer;

  if (isChekinConsumer) {
    LocalStorage.remove(TOKEN_STORAGE_KEY);
  } else {
    MemoryStorage.remove(TOKEN_STORAGE_KEY);
  }
}

export {getTokenFromStorage, persistTokenToLocalStorage, removeTokenFromLocalStorage};
