import {HTMLAttributes, memo, ReactNode, useCallback, useMemo} from 'react';
import {LinkProps} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {LocalStorage} from '@guestapp/core';
import {IS_LEADER_GUEST} from 'utils/constants';
import {PATHS} from '../../Routes';
import {useActions, useErrorModal, useGuestGroup, usePath} from 'hooks';
import {useDeleteGuest} from '../../hooks/useDeleteGuest';
import {GUEST_STATUSES, ShortGuest} from '@guestapp/sdk';
import deleteGuestIcon from '../../assets/icons/delete-guest-icon.svg';
import incompleteIcon from '../../assets/icons/incomplete-icon.svg';
import completeIcon from '../../assets/icons/complete-icon.svg';
import crownIcon from '../../assets/icons/crown-icon.svg';

import {
  AddPanelText,
  ButtonBox,
  DeleteGuestIcon,
  Item,
  LinkItem,
  NavigationStatusTextProps,
  NextArrow,
  StatusIcon,
  StatusText,
  Texts,
  Title,
  EmptyUserPanel,
  PanelGroupTitle,
  PanelsGroupWrapper,
  PanelsGroupContainer,
} from './styled';

type GuestPanelLinkItem = LinkProps;
type GuestPanelDivItem = HTMLAttributes<HTMLDivElement>;

function isLink(arg: GuestPanelLinkItem | GuestPanelDivItem): arg is GuestPanelLinkItem {
  return Boolean((arg as GuestPanelLinkItem).to);
}

type GuestPanelItemProps = {children: ReactNode} & NavigationStatusTextProps &
  (GuestPanelLinkItem | GuestPanelDivItem);

const GuestPanelItem = memo((props: GuestPanelItemProps) => {
  if (isLink(props)) {
    return <LinkItem {...props} />;
  } else {
    return <Item {...props} />;
  }
});

export const GuestItem = memo((guestMember: ShortGuest & {isLeader?: boolean}) => {
  const {getMainPath} = usePath();
  const {resetAllIVState} = useActions();
  const {ErrorModal, displayError} = useErrorModal();
  const {mutate: deleteGuestMutate} = useDeleteGuest({
    onError: displayError,
  });
  const {t} = useTranslation();
  const enableGuestsRemoval = window?.ChekinProSettings?.enableGuestsRemoval;
  const {full_name, guestapp_status, isLeader} = guestMember;
  const completed = guestapp_status === GUEST_STATUSES.complete;
  const statusIcon = completed ? completeIcon : incompleteIcon;
  const statusText = guestStatusText[guestapp_status] || '';
  const isEditable =
    (guestMember.is_pre_registered &&
      guestMember.guestapp_status === GUEST_STATUSES.incomplete) ||
    guestMember.guestapp_status === GUEST_STATUSES.verification_pending;

  const getLinkToUpdateGuestProcessView = useCallback(
    (guest?: ShortGuest) => {
      const guestId = guest?.id;
      const guestStatus = guest?.guestapp_status;
      const isVerificationPending = guestStatus === GUEST_STATUSES.verification_pending;
      const isPreRegisteredAndInCompleted =
        guest?.is_pre_registered && guestStatus === GUEST_STATUSES.incomplete;
      if (guestId) {
        if (isVerificationPending) {
          return getMainPath(`${PATHS.verification.main}/${guestId}`);
        }
        if (isPreRegisteredAndInCompleted) {
          if (isLeader) {
            LocalStorage.set(IS_LEADER_GUEST, JSON.stringify(isLeader));
          }
          return getMainPath(`${PATHS.addPersonalDataForm}/${guestId}`);
        }
      }
    },
    [getMainPath, isLeader],
  );
  const link = isEditable ? getLinkToUpdateGuestProcessView(guestMember) : undefined;
  const handleClick = () => isEditable && resetAllIVState();
  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (enableGuestsRemoval && guestMember.id) {
      deleteGuestMutate(guestMember.id);
    }
  };

  return (
    <GuestPanelItem
      $completed={completed}
      to={link}
      onClick={handleClick}
      className="guests-panel__item"
    >
      <Texts>
        <Title className="guests-panel__title">{full_name}</Title>
        <StatusText className="guests-panel__status" $completed={completed}>
          <StatusIcon src={statusIcon} width={14} />
          {t(statusText)}
        </StatusText>
      </Texts>
      {enableGuestsRemoval && (
        <DeleteGuestIcon
          className="guests-panel__delete-btn"
          iconSrc={deleteGuestIcon}
          onClick={handleDelete}
        />
      )}
      {isEditable && <NextArrow name="grayRightArrow" size={24} />}
      <ErrorModal />
    </GuestPanelItem>
  );
});

type GuestStatusesTexts = {
  [key in GUEST_STATUSES]?: string;
};

const guestStatusText: GuestStatusesTexts = {
  [GUEST_STATUSES.complete]: 'completed',
  [GUEST_STATUSES.verification_pending]: 'verification_pending',
  [GUEST_STATUSES.incomplete]: 'registration_pending',
};

type GuestPanelProps = {
  handleRegisterNewGuest?: (isLeader?: boolean) => void;
};

function GuestsPanel({handleRegisterNewGuest}: GuestPanelProps) {
  const {t} = useTranslation();
  const {guestGroup, allGuestsRegistered, missingGuests} = useGuestGroup();

  const members = guestGroup?.members;
  const acompanyingMembers = members?.filter(
    member => member.id !== guestGroup?.leader_id,
  );

  const leaderGuest = members?.find(member => member.id === guestGroup?.leader_id);
  const missingGuestArray = useMemo(() => {
    if (!guestGroup || allGuestsRegistered) {
      return [];
    }
    const totalUnregisteredGuests = !leaderGuest ? missingGuests - 1 : missingGuests;
    return new Array(totalUnregisteredGuests).fill(null);
  }, [guestGroup, allGuestsRegistered, missingGuests, leaderGuest]);

  const EmptyBox = useCallback(
    ({isLeader}: {isLeader?: boolean}) => {
      return (
        <EmptyUserPanel onClick={() => handleRegisterNewGuest?.(isLeader)}>
          <AddPanelText>{t('add_guest')}</AddPanelText>
          <ButtonBox name="addItemIcon" title="Add" size={24} />
        </EmptyUserPanel>
      );
    },
    [handleRegisterNewGuest, t],
  );

  return (
    <>
      <PanelsGroupWrapper>
        <PanelGroupTitle>
          {t('responsible_guest')}
          <img src={crownIcon} alt="Crown icon" width="18" height="18" />
        </PanelGroupTitle>
        <PanelsGroupContainer>
          {leaderGuest ? <GuestItem {...leaderGuest} isLeader /> : <EmptyBox isLeader />}
        </PanelsGroupContainer>
      </PanelsGroupWrapper>
      {leaderGuest && (
        <PanelsGroupWrapper>
          <PanelGroupTitle>{t('acompanying_guests')}</PanelGroupTitle>
          <PanelsGroupContainer>
            {acompanyingMembers?.map((guestMember, index: number) => {
              return <GuestItem key={index} {...guestMember} />;
            })}
            {missingGuestArray.map((_, index) => (
              <EmptyBox key={index} isLeader={false} />
            ))}
          </PanelsGroupContainer>
        </PanelsGroupWrapper>
      )}
    </>
  );
}

export {GuestsPanel};
