import React from 'react';
import {useTranslation} from 'react-i18next';
import {Content, ProtectionModalText, StyledModalSheet, WrapperText} from './styled';

type SecurityDepositInfoModalProps = {
  isOpen: boolean;
  close: () => void;
  isPreActivated?: boolean;
};
function SecurityDepositInfoModal({
  isPreActivated,
  isOpen,
  close,
}: SecurityDepositInfoModalProps) {
  const {t} = useTranslation();

  const securityPreAuthText = (
    <WrapperText>
      <ProtectionModalText>{t('security_pre_auth_summary')}</ProtectionModalText>
      <br />
      <h3>{t('how_it_works')}</h3>
      <ProtectionModalText>• {t('this_property_has_pre_auth_text')}</ProtectionModalText>
      <ProtectionModalText>• {t('add_card_and_confirm_text')}</ProtectionModalText>
      <ProtectionModalText>
        • {t('the_pre_auth_temp_store_one_time_text')}
      </ProtectionModalText>
      <ProtectionModalText>
        • {t('seven_days_after_check_out_pre_auth_expire')}
      </ProtectionModalText>
      <br />
      <h3>{t('what_if_damage_occurs')}</h3>
      <ProtectionModalText>
        • {t('your_host_has_7_days_after_reservation_review_damage')}
      </ProtectionModalText>
      <ProtectionModalText>
        • {t('during_that_period_host_could_charge_deposit')}
      </ProtectionModalText>
    </WrapperText>
  );

  const securityRetentionText = (
    <WrapperText>
      <ProtectionModalText>{t('security_retention_summary')}</ProtectionModalText>
      <br />
      <h3>{t('how_it_works')}</h3>
      <ProtectionModalText>
        • {t('paid_deposit_amount_with_card_or_debit')}
      </ProtectionModalText>
      <ProtectionModalText>
        • {t('every_seven_days_pre_auth_release_back')}
      </ProtectionModalText>
      <ProtectionModalText>
        • {t('after_seven_days_of_the_last_pre_authorization')}
      </ProtectionModalText>
      <br />
      <h3>{t('what_if_damage_occurs')}</h3>
      <ProtectionModalText>
        • {t('notify_host_damage_rental_property')}
      </ProtectionModalText>
      <ProtectionModalText>
        • {t('your_host_handles_comunitacions_with_damage_program')}
      </ProtectionModalText>
      <ProtectionModalText>
        • {t('your_host_has_up_to_seven_day_after_check_out')}
      </ProtectionModalText>
      <ProtectionModalText>
        • {t('during_that_period_if_the_accomodation_terms')}
      </ProtectionModalText>
    </WrapperText>
  );

  const securityModalContent = isPreActivated
    ? securityRetentionText
    : securityPreAuthText;

  return (
    <StyledModalSheet
      isOpenModalSheet={isOpen}
      handleCloseModal={close}
      title={t('security_deposit')}
      className="security-deposit-info-modal"
      nested
      withCloseButton
      closeButtonType="click"
    >
      <Content>{securityModalContent}</Content>
    </StyledModalSheet>
  );
}

export {SecurityDepositInfoModal};
