import styled, {css} from 'styled-components';
import {FixedSizeList} from 'react-window';
import {selectWidth} from '../../Select';

export const MenuMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: ${props => props.theme.colors.$p6};
  box-sizing: border-box;
  font-weight: 500;
  text-align: center;
  color: ${props => props.theme.colors.$n5};
  border-radius: 0 0 8px 8px;
`;

export const MenuItemLoadingMessage = styled(MenuMessage)`
  margin: 10px auto 0;
  padding: 10px 24px 15px;
`;

export const Menu = styled(FixedSizeList)<{mobileVersion?: boolean}>`
  padding: 16px 0 19px 0;
  border-radius: 0 0 8px 8px;
  box-sizing: border-box;
  width: ${selectWidth} !important;
  background: transparent;
  box-shadow: 0 10px 16px #0f0f0f0d;

  & > div {
    position: relative;
    grid-row-gap: 22px;
  }

  & .select__option:last-child {
    border-bottom-color: #f2f4f8 !important;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  ${props =>
    props.mobileVersion &&
    css`
      padding: 0;
      box-shadow: none;
      width: 100% !important;
      border-radius: 0;
    `}
`;

type MenuItemProps = {
  $isLastChild: boolean;
};

export const MenuItem = styled.div<MenuItemProps>`
  box-sizing: border-box;
  background-color: transparent;

  & .select__option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props =>
      props.theme.hasTemplate
        ? props.theme.colors.branding.fontPrimary
        : props.theme.colors.$p10};
  }

  & .select__option:last-child {
    border-bottom-color: ${props => props.$isLastChild && 'transparent !important'};
  }
`;
