import styled from 'styled-components';

type ContainerProps = {
  heightLimit?: number;
  collapsible?: boolean;
  open?: boolean;
};
export const CollapsableArea = styled.div<ContainerProps>`
  position: relative;
  height: ${({heightLimit, collapsible, open}) =>
    collapsible && !open ? `${heightLimit}px` : 'auto'};
  overflow: hidden;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
