import {apiClient, FetcherSettings} from './apiClient';
import {dynamicRoute} from './utils';

class GuestFormAdditionalDataApi {
  ENDPOINTS = {
    getPurposeOfStay: dynamicRoute({
      regular: (params = '') => `api/v3/purposes-of-stay/?${params}`,
      sdk: (params = '') => `sdk/api/v1/purposes-of-stay/?${params}`,
    }),
  };

  async getPurposeOfStay(fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<{id: string; name: string}[]>(
      this.ENDPOINTS.getPurposeOfStay(),
      fetcherSettings,
    );
    return response.body;
  }
}

export {GuestFormAdditionalDataApi};
