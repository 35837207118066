import {useQuery} from '@tanstack/react-query';
import {LockConditions} from '@guestapp/sdk';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {useChekinSDK} from 'context/ChekinSDK';
import {useReservation} from 'context/reservation';

type UseFetchLockConditionsProps = {
  enabled?: boolean;
};

function useFetchLockConditions({enabled = true}: UseFetchLockConditionsProps = {}) {
  const {data: reservation} = useReservation();
  const {getLockConditions} = useChekinSDK();
  const enabledFetching = Boolean(!!reservation?.id && enabled);

  return useQuery<LockConditions, Error>(
    [QUERY_CACHE_KEYS.lockConditions],
    () => getLockConditions(`reservation_id=${reservation?.id}`),
    {enabled: enabledFetching, refetchOnWindowFocus: false, staleTime: 3000},
  );
}

export {useFetchLockConditions};
