import styled from 'styled-components';
import {device} from '../../styled/device';
import {Button} from '@guestapp/ui';

export const Content = styled.div`
  max-width: 80%;
  width: 100%;

  @media (min-width: ${device.tablet}) {
    margin-top: 20px;
  }
  @media (min-width: ${device.laptopM}) {
    margin-top: 0;
  }
`;

export const StyledQuestionTitle = styled.div.attrs({className: 'fag__question'})`
  font-style: normal;
  color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.fontPrimary
      : props.theme.colors.$p10};
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;

  @media (min-width: ${device.mobileXL}) {
    font-weight: 500;
    font-size: 16px;
  }
`;

export const Answer = styled.p.attrs({className: 'fag__answer'})`
  font-size: 14px;
  letter-spacing: -0.16px;
  margin: 0 0 16px;
  color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.fontPrimary
      : props.theme.colors.$p10};

  a {
    font-weight: 700;
    color: ${props => props.theme.colors.buttons.textButton};
  }

  @media (min-width: ${device.mobileXL}) {
    margin: 0 0 24px;
  }
`;

export const BackButton = styled(Button)`
  display: none;
  position: absolute;
  font-size: 14px;
  font-weight: 700;

  & .button__left-icon {
    padding-right: 10px;
  }

  @media (min-width: ${device.tablet}) {
    /* width: 70px; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    left: 3%;
  }
  @media (min-width: ${device.laptopM}) {
    display: none;
  }
`;
