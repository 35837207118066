import React from 'react';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {bindActionCreators} from '@reduxjs/toolkit';

import type {AppDispatch, RootState} from '../store';
import {stepperActions} from 'store/slices/stepper/stepper.slice';
import {identityVerificationActions} from 'store/slices/identityVerification/identityVerification.slice';
import {photoBoxActions} from '../store/slices/photoBox/photoBox.slice';
import {paymentsActions} from '../store/slices/payments';
import {allianzInsuranceActions} from 'store/slices/allianzInsurance/allianzInsurance.slice';
import {bookExperienceActions} from 'store/slices/bookExperience';
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch<AppDispatch>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const actions = {
  ...stepperActions,
  ...identityVerificationActions,
  ...photoBoxActions,
  ...paymentsActions,
  ...allianzInsuranceActions,
  ...bookExperienceActions,
};

export const useActions = () => {
  const dispatch = useAppDispatch();

  return React.useMemo(() => bindActionCreators(actions, dispatch), [dispatch]);
};
