import {css} from 'styled-components';
import {Poppins} from '../assets/fonts';

const Fonts = css`
  @font-face {
    font-family: Poppins;
    font-display: swap;
    font-style: normal;
    font-weight: 900;
    src: url('${Poppins.PoppinsBlack}') format('truetype');
  }
  @font-face {
    font-family: Poppins;
    font-display: swap;
    font-style: italic;
    font-weight: 900;
    src: url('${Poppins.PoppinsBlackItalic}') format('truetype');
  }

  @font-face {
    font-family: Poppins;
    font-display: swap;
    font-style: normal;
    font-weight: 800;
    src: url('${Poppins.PoppinsExtraBold}') format('truetype');
  }

  @font-face {
    font-family: Poppins;
    font-display: swap;
    font-style: italic;
    font-weight: 800;
    src: url('${Poppins.PoppinsExtraBoldItalic}') format('truetype');
  }

  @font-face {
    font-family: Poppins;
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url('${Poppins.PoppinsBold}') format('truetype');
  }

  @font-face {
    font-family: Poppins;
    font-display: swap;
    font-style: italic;
    font-weight: 700;
    src: url('${Poppins.PoppinsBoldItalic}') format('truetype');
  }

  @font-face {
    font-family: Poppins;
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: url('${Poppins.PoppinsSemiBold}') format('truetype');
  }

  @font-face {
    font-family: Poppins;
    font-display: swap;
    font-style: italic;
    font-weight: 600;
    src: url('${Poppins.PoppinsSemiBoldItalic}') format('truetype');
  }

  @font-face {
    font-family: Poppins;
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    src: url('${Poppins.PoppinsMedium}') format('truetype');
  }

  @font-face {
    font-family: Poppins;
    font-display: swap;
    font-style: italic;
    font-weight: 500;
    src: url('${Poppins.PoppinsMediumItalic}') format('truetype');
  }

  @font-face {
    font-family: Poppins;
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url('${Poppins.PoppinsRegular}') format('truetype');
  }

  @font-face {
    font-family: Poppins;
    font-display: swap;
    font-style: normal;
    font-weight: 300;
    src: url('${Poppins.PoppinsLight}') format('truetype');
  }

  @font-face {
    font-family: Poppins;
    font-display: swap;
    font-style: italic;
    font-weight: 300;
    src: url('${Poppins.PoppinsLightItalic}') format('truetype');
  }

  @font-face {
    font-family: Poppins;
    font-display: swap;
    font-style: normal;
    font-weight: 200;
    src: url('${Poppins.PoppinsExtraLight}') format('truetype');
  }

  @font-face {
    font-family: Poppins;
    font-display: swap;
    font-style: italic;
    font-weight: 200;
    src: url('${Poppins.PoppinsExtraLightItalic}') format('truetype');
  }
`;

export default Fonts;
