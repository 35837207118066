import {useQuery} from '@tanstack/react-query';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {useChekinSDK} from 'context/ChekinSDK';

function useFetchDeals({reservationId}: {reservationId?: string}) {
  const {getDeals} = useChekinSDK();
  const result = useQuery(
    [QUERY_CACHE_KEYS.deals],
    () => getDeals(`reservation_id=${reservationId}&is_active=true`),
    {
      enabled: Boolean(reservationId),
    },
  );

  return result;
}

export {useFetchDeals};
