import dayjs from 'dayjs';
import {LANGUAGE_CODES} from './constants';

const dayjsLocales: Record<LANGUAGE_CODES, () => Promise<unknown>> = {
  [LANGUAGE_CODES.en]: () => import(`dayjs/locale/en`),
  [LANGUAGE_CODES.de]: () => import(`dayjs/locale/de`),
  [LANGUAGE_CODES.ru]: () => import(`dayjs/locale/ru`),
  [LANGUAGE_CODES.es]: () => import(`dayjs/locale/es`),
  [LANGUAGE_CODES.it]: () => import(`dayjs/locale/it`),
  [LANGUAGE_CODES.bg]: () => import(`dayjs/locale/bg`),
  [LANGUAGE_CODES.fr]: () => import(`dayjs/locale/fr`),
  [LANGUAGE_CODES.hu]: () => import(`dayjs/locale/hu`),
  [LANGUAGE_CODES.cs]: () => import(`dayjs/locale/cs`),
  [LANGUAGE_CODES.pt]: () => import(`dayjs/locale/pt`),
  [LANGUAGE_CODES.ro]: () => import(`dayjs/locale/ro`),
  [LANGUAGE_CODES.et]: () => import(`dayjs/locale/et`),
  [LANGUAGE_CODES.ca]: () => import(`dayjs/locale/ca`),
  [LANGUAGE_CODES.pl]: () => import(`dayjs/locale/pl`),
  [LANGUAGE_CODES.el]: () => import(`dayjs/locale/el`),
  [LANGUAGE_CODES.uk]: () => import(`dayjs/locale/uk`),
};

const preloadAndSetDayJsLocale = async (language: string) => {
  const localeFetcher = dayjsLocales[language as LANGUAGE_CODES];

  if (localeFetcher && typeof localeFetcher === 'function') {
    await localeFetcher();
    dayjs.locale(language);
  }
};

export {preloadAndSetDayJsLocale};
