import React from 'react';
import {useTranslation} from 'react-i18next';
import {device} from '@guestapp/ui/src/utils/device';
import {APP_FLOWS, APP_PAGES} from 'utils/pages';
import {IconNames} from '@guestapp/ui';
import {useSummary} from 'context/summary';
import {useNavigationCustom, useScreenResize, useSubHeaderConfig} from 'hooks';
import Menu from '../Menu';
import {ShoppingCartButton} from '../ShoppingCartButton';
import {
  DiscardChangesModal,
  DiscardChangesModalRef,
} from '../DiscardChangesModal/DiscardChangesModal';
import {
  Wrapper,
  Title,
  BackButton,
  StyledBackIcon,
  BackIconButton,
  StyledHeaderLogo,
} from './styled';

const PAGES_ICON_POSITION_ABSOLUTE = [
  `${APP_FLOWS.grOnly}_${APP_PAGES.allianzSelectPlan}`,
  `${APP_FLOWS.grIvOnly}_${APP_PAGES.allianzSelectPlan}`,
  `${APP_FLOWS.allFeatures}_${APP_PAGES.allianzSelectPlan}`,
  `${APP_FLOWS.grIvOnly}_${APP_PAGES.addPersonalData}`,
];

const PAGES_NO_ABSOLUTE_ALL_FEATURES = [
  APP_PAGES.summary,
  APP_PAGES.taxes,
  APP_PAGES.deposits,
  `${APP_PAGES.payments}_${APP_PAGES.home}`,
  `${APP_PAGES.payments}_${APP_PAGES.summary}`,
  `${APP_PAGES.paymentsForm}_${APP_PAGES.summary}`,
  `${APP_PAGES.paymentsForm}_${APP_PAGES.summary}`,
  APP_PAGES.payments,
  APP_PAGES.paymentsForm,
  APP_PAGES.taxesAddExemption,
  APP_PAGES.addPersonalData,
  APP_PAGES.verification,
  APP_PAGES.verificationChild,
  APP_PAGES.registerGuest,
  APP_PAGES.remoteAccess,
  APP_PAGES.faq,
  APP_PAGES.home,
  APP_PAGES.bookExperience,
  APP_PAGES.recommendationsConfirmEmail,
];

type SubHeaderProps = {
  title?: string;
  isBackButtonDisabled?: boolean;
  hideMenu?: boolean;
  onBackButtonClick?: () => void | (() => void);
  headerLogo?: string;
  mobileTitle?: string;
};

const SubHeader = React.memo(
  ({
    title,
    isBackButtonDisabled,
    hideMenu,
    onBackButtonClick,
    headerLogo,
    mobileTitle,
  }: SubHeaderProps) => {
    const {t} = useTranslation();
    const {arePaymentsAvailable} = useSummary();
    const discardChangesModalRef = React.useRef<DiscardChangesModalRef>(null);
    const {config: icon} = useSubHeaderConfig({discardChangesModalRef});
    const {isMatch: isMobile} = useScreenResize(device.tabletSubHeader);
    const {currentPage, currentFlow, currentFlowAndPage} = useNavigationCustom();
    const subheaderTitle = title || mobileTitle;
    const backIconPosition =
      (currentFlow === APP_FLOWS.allFeatures &&
        !PAGES_NO_ABSOLUTE_ALL_FEATURES.includes(currentPage as APP_PAGES)) ||
      PAGES_ICON_POSITION_ABSOLUTE.includes(currentFlowAndPage)
        ? 'absolute'
        : 'relative';

    const paymentPages = [APP_PAGES.payments, `${APP_PAGES.payments}_${APP_PAGES.home}`];
    const isPaymentPage = paymentPages.some(page => page === currentPage);

    return (
      <Wrapper
        onlyLogo={isBackButtonDisabled}
        className="subheader"
        isPaymentsView={isPaymentPage}
      >
        {!isBackButtonDisabled && (
          <BackButton
            label={t('back')}
            customIcon={<StyledBackIcon name="backArrow" size={15} />}
            iconProps={{position: 'left'}}
            onClick={onBackButtonClick}
            width="auto"
            link
          />
        )}
        <BackIconButton onClick={icon.onClick} position={backIconPosition}>
          <StyledBackIcon
            name={icon.icon as IconNames}
            size={icon.size}
            color="#462515"
          />
        </BackIconButton>
        {headerLogo && (
          <StyledHeaderLogo
            src={headerLogo}
            position={currentPage === APP_PAGES.allianzSelectGuest ? 'left' : 'center'}
            className="subheader__logo"
          />
        )}
        {subheaderTitle && (
          <Title
            isFaqView={hideMenu}
            hasPayments={arePaymentsAvailable}
            className="subheader__title"
          >
            {isMobile ? mobileTitle : title}
          </Title>
        )}
        <DiscardChangesModal ref={discardChangesModalRef} />
        <ShoppingCartButton />
        {!hideMenu && <Menu />}
      </Wrapper>
    );
  },
);

export {SubHeader};
export type {SubHeaderProps};
