import React, {ReactNode} from 'react';
import clsx from 'clsx';
import {useNavigate} from 'react-router-dom';
import {useOpenModals} from 'context/openModals';
import Navbar from 'components/common/Navbar';
import Loading from 'components/common/Loading';
import SubHeaderPanel from '../SubHeaderPanel';
import LanguageSDKSwitcher from '../LanguageSDKSwitcher';
import SubHeader, {SubHeaderProps} from 'components/common/SubHeader';
import {PageWrapper, PageBanner, Wrapper, BottomStickyPanel} from './styled';

type BannerProps = {
  src: string;
  onClick?: () => void;
};
export type PageProps = SubHeaderProps & {
  children: ReactNode;
  isSubHeaderVisible?: boolean;
  subtitle?: string;
  className?: string;
  isLoading?: boolean;
  mainHeader?: boolean;
  hideSubPanelBackButton?: boolean;
  onBackButtonSubHeaderClick?: () => void;
  headerLogo?: string;
  banner?: BannerProps;
  navBarTitle?: string;
  bottomStickyPanel?: ReactNode;
};

function Page({
  children = '',
  title = '',
  subtitle,
  isSubHeaderVisible = false,
  className,
  isLoading = false,
  mainHeader,
  isBackButtonDisabled = false,
  hideMenu = false,
  onBackButtonClick,
  hideSubPanelBackButton = false,
  onBackButtonSubHeaderClick,
  headerLogo,
  banner,
  navBarTitle,
  bottomStickyPanel,
}: PageProps) {
  const {checkIfOpenDiscardModalAndRedirect} = useOpenModals();
  const navigate = useNavigate();
  const handleBackButtonClick =
    onBackButtonSubHeaderClick || (() => checkIfOpenDiscardModalAndRedirect('-1'));
  const handleSubHeaderBackButtonClick = onBackButtonClick ?? (() => navigate(-1));

  return (
    <Loading isLoading={isLoading}>
      <PageWrapper className="page">
        <Navbar visible={mainHeader} title={navBarTitle} />
        {isSubHeaderVisible && (
          <SubHeader
            title={title}
            isBackButtonDisabled={isBackButtonDisabled}
            hideMenu={hideMenu}
            onBackButtonClick={handleSubHeaderBackButtonClick}
            headerLogo={headerLogo}
            mobileTitle={navBarTitle}
          />
        )}
        {subtitle && (
          <SubHeaderPanel
            title={subtitle}
            onBackClick={handleBackButtonClick}
            hideBackButton={hideSubPanelBackButton}
          />
        )}
        {banner && (
          <PageBanner
            src={banner?.src}
            onClick={() => banner.onClick?.()}
            className="page-banner"
          />
        )}
        <Wrapper className={clsx(className, 'page_content')}>{children}</Wrapper>
        {bottomStickyPanel && <BottomStickyPanel>{bottomStickyPanel}</BottomStickyPanel>}
        <LanguageSDKSwitcher />
      </PageWrapper>
    </Loading>
  );
}

export {Page};
