import React from 'react';
import {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {device} from '@guestapp/ui/src/utils/device';
import {CustomIcon} from '@guestapp/ui';
import {useBuildNavigationItems, useModalControls, useScreenResize} from 'hooks';
import {NavigationTasksItems} from './NavigationTasksItems';
import {ToggleTasksArrow} from './ToggleTasksArrow';
import ModalSheet from 'components/common/ModalSheet';
import ShareRegistrationLink from 'components/ModalSheets/ShareRegistrationLink';
import {getMainColor} from 'styled/common';
import {
  BoxTitle,
  ShareBtnContainer,
  ShareText,
  Description,
  TaskText,
  StyledSuccessIcon,
  TextAndArrowContainer,
} from './styled';

function CheckInInfoBox() {
  const {t} = useTranslation();
  const theme = useTheme();
  const {displayedNavigationItems} = useBuildNavigationItems();
  const {isMatch: isMobileMode} = useScreenResize(device.laptopM);
  const {
    isOpen: isOpenShareRegistrationLink,
    closeModal: closeShareRegistrationLink,
    openModal: openShareRegistrationLink,
  } = useModalControls();
  const [isOpenNavigationPanels, setIsOpenNavigationPanels] = React.useState(true);
  const isCompletedTasks = displayedNavigationItems.every(
    navigationItem => navigationItem.completed,
  );

  const totalTasks = displayedNavigationItems.length;
  const completedTask = displayedNavigationItems.filter(
    navigationItem => navigationItem.completed,
  ).length;

  const toggleNavigationItems = React.useCallback(() => {
    setIsOpenNavigationPanels(!isOpenNavigationPanels);
  }, [isOpenNavigationPanels]);
  const showNavigationPanels = isMobileMode ? isOpenNavigationPanels : true;

  const tasksDescriptionTextTrans = isCompletedTasks
    ? t('congrats_your_checkin_is_completed')
    : t('fullfill_pending_task_complete_check_in_before_arrival');

  const taxesTextsStatus = React.useMemo(() => {
    if (!isCompletedTasks) {
      return (
        <>
          <span>{completedTask}</span>/{totalTasks} {t('tasks_completed')}
        </>
      );
    }
    return (
      <>
        <StyledSuccessIcon
          name="statusSuccessIcon"
          size={16}
          custom
          color={theme.hasTemplate ? theme.colors.branding.primary : '#32D7BE'}
          className="chekin-boxes__tasks-success-icon"
        />
        {t('all_tasks_completed')}
      </>
    );
  }, [theme, completedTask, totalTasks, isCompletedTasks, t]);

  return (
    <>
      <BoxTitle className="chekin-boxes__tasks-title">
        {t('check_in_short')}
        <ShareBtnContainer
          onClick={openShareRegistrationLink}
          className="chekin-boxes__tasks-share-btn"
        >
          <CustomIcon name="shareArrowIcon" size={24} color={getMainColor(theme)} />
          <ShareText>{t('share')}</ShareText>
        </ShareBtnContainer>
      </BoxTitle>
      <Description className="chekin-boxes__tasks-description">
        {tasksDescriptionTextTrans}
      </Description>

      <TaskText marginBottom="8px" className="chekin-boxes__tasks-texts-container">
        <TextAndArrowContainer className="chekin-boxes__tasks-texts-content">
          <TaskText className="chekin-boxes__tasks-status">{taxesTextsStatus}</TaskText>
          {isCompletedTasks && (
            <ToggleTasksArrow
              onClick={toggleNavigationItems}
              isOpenNavigationPanels={isOpenNavigationPanels}
            />
          )}
        </TextAndArrowContainer>
      </TaskText>
      {showNavigationPanels && (
        <NavigationTasksItems showNavigationPanels={showNavigationPanels} />
      )}

      <ModalSheet
        isOpenModalSheet={isOpenShareRegistrationLink}
        handleCloseModal={closeShareRegistrationLink}
        maxWidth={'375px'}
        withCloseButton
      >
        <ShareRegistrationLink />
      </ModalSheet>
    </>
  );
}
export {CheckInInfoBox};
