import {COUNTRY_CODES} from './constants';

const DRIVING_LICENSES = {
  drivingLicense: 'DL',
  drivingLicenseES: 'ES_C',
  drivingLicenseCZ: 'CZ_DL',
  drivingLicenseAT: 'AT_DL',
  drivingLicenseGR: 'GR_DL',
  drivingLicenseGB: 'GB_DL',
  drivingLicenseIT: 'IT_PATEN',
  drivingLicenseRO: 'RO_DL',
};

const IDENTITY_CARDS = {
  identityCard: 'I',
  identityCardES: 'ES_I',
  identityCardIT: 'IT_IDELE',
  identityCardPaperIT: 'IT_IDENT',
  identityCardPT: 'PT_B',
  identityCardCZ: 'CZ_ID',
  identityCardAT: 'AT_ID',
  identityCardGR: 'GR_ID',
  identityCardGB: 'GB_ID',
  identityCardCO: 'CO_FID',
  identityCardUS: 'US_ID',
  identityCardRO: 'RO_ID',
};

const PASSPORTS = {
  passport: 'P',
  passportPT: 'PT_P',
  passportIT: 'IT_PASOR',
  passportCZ: 'CZ_P',
  passportAT: 'AT_P',
  passportGR: 'GR_P',
  passportGB: 'GB_P',
  passportES: 'ES_P',
  passportCO: 'CO_P',
  passportRO: 'RO_P',
  armyIdGR: 'GR_ARMY_ID',
};

const NIE_DOCUMENTS = {
  spanishResidencePermitNIE: 'ES_N',
};

const DNI_DOCUMENTS = {
  dniES: 'ES_D',
  dniOldGreek: 'GR_ID_OLD',
};

const RESIDENCE_PERMITS = {
  dubaiResidencePermit: 'AE_RP',
  spanishResidencePermitEU: 'ES_X',
};

const DOCUMENTS_TYPES = {
  diplomaticPassportIT: 'IT_PASDI',
  diplomaticIdentityIT: 'IT_CIDIP',
  diplomaticIdCO: 'CO_DIP',
  servicePassportIT: 'IT_PASSE',
  othersDocumentsPT: 'PT_O',
  othersDocumentsAT: 'AT_O',
  workPermitGR: 'GR_WORK_PERMIT',
  foreignDocumentCO: 'CO_FD',
  identityCertificateIT: 'IT_CERID',
  ...NIE_DOCUMENTS,
  ...DNI_DOCUMENTS,
  ...RESIDENCE_PERMITS,
  ...PASSPORTS,
  ...IDENTITY_CARDS,
  ...DRIVING_LICENSES,
};

function getRemappedDocType({
  docType,
  countryCode,
  nationality: _,
}: {
  docType?: string | null;
  countryCode?: string;
  nationality?: string;
}) {
  const ID_DOC_TYPE = 'ID';
  const RESIDENCE_PERMIT = 'IX';
  const DRIVING_LICENCE = 'DL';
  const REMAPPED_RESIDENCE_PERMIT = 'X';

  if (docType === ID_DOC_TYPE) {
    switch (countryCode) {
      case COUNTRY_CODES.spain:
        return DOCUMENTS_TYPES.identityCardES;
      case COUNTRY_CODES.italy:
        return DOCUMENTS_TYPES.identityCardIT;
      case COUNTRY_CODES.portugal:
        return DOCUMENTS_TYPES.identityCardPT;
      case COUNTRY_CODES.colombia:
        return DOCUMENTS_TYPES.identityCardCO;
      case COUNTRY_CODES.romania:
        return DOCUMENTS_TYPES.identityCardRO;
      default:
        return DOCUMENTS_TYPES.identityCard;
    }
  } else if (docType === RESIDENCE_PERMIT) {
    switch (countryCode) {
      case COUNTRY_CODES.spain:
        return DOCUMENTS_TYPES.spanishResidencePermitNIE;
      case COUNTRY_CODES.italy:
        return DOCUMENTS_TYPES.identityCardIT;
      case COUNTRY_CODES.portugal:
        return DOCUMENTS_TYPES.othersDocumentsPT;
      default:
        return REMAPPED_RESIDENCE_PERMIT;
    }
  } else if (docType === DRIVING_LICENCE) {
    switch (countryCode) {
      case COUNTRY_CODES.romania:
        return DOCUMENTS_TYPES.drivingLicenseRO;
      default:
        return DOCUMENTS_TYPES.drivingLicense;
    }
  } else if (docType === DOCUMENTS_TYPES.passport) {
    switch (countryCode) {
      case COUNTRY_CODES.spain:
        return DOCUMENTS_TYPES.passportES;
      case COUNTRY_CODES.italy:
        return DOCUMENTS_TYPES.passportIT;
      case COUNTRY_CODES.portugal:
        return DOCUMENTS_TYPES.passportPT;
      case COUNTRY_CODES.colombia:
        return DOCUMENTS_TYPES.passportCO;
      case COUNTRY_CODES.romania:
        return DOCUMENTS_TYPES.passportRO;
      default:
        return DOCUMENTS_TYPES.passport;
    }
  } else {
    return docType;
  }
}

type DocumentType = {
  label: string;
  two_sides: boolean;
  value: string;
};
function getDocumentsListAsOptions(documents?: DocumentType[]) {
  if (!documents?.length) {
    return [];
  }

  return documents.map(doc => {
    return {
      label: doc.label,
      value: doc.value,
      data: doc,
    };
  });
}

const dniValidation = [DNI_DOCUMENTS.dniES, DRIVING_LICENSES.drivingLicenseES];
const nieValidation = [NIE_DOCUMENTS.spanishResidencePermitNIE];

const defaultExport = {
  types: DOCUMENTS_TYPES,
  getRemappedDocType,
  getDocumentsListAsOptions,
};

export {
  DOCUMENTS_TYPES,
  getRemappedDocType,
  getDocumentsListAsOptions,
  dniValidation,
  nieValidation,
};
export default defaultExport;
