import {TFunction} from 'i18next';
import {useState, useCallback, useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {
  useGuestGroup,
  useMutateReservation,
  useReservationSettings,
  useTaxes,
} from 'hooks';
import {FORM_NAMES, MODAL_FORMS} from './constants';
import {GROUP_TYPES} from '@guestapp/sdk';
import {FormTypes} from './types';
import {getFields, getGroupType} from './utils';
import {NumberOfGuestsForm} from './forms/NumberOfGuestsForm';
import {ReservationTypeForm} from './forms/ReservationTypeForm';
import {ConfirmLeaderGuestForm} from './forms/ConfirmLeaderGuestForm';
import {
  Content,
  ErrorContainer,
  ErrorMessage,
  StyledLoader,
  StyledModalSheet,
} from './styled';

type ReservationSettingsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  availableForms: Record<MODAL_FORMS, boolean>;
  ageLimit?: number | null;
};

const getModalTitle = (t: TFunction) => ({
  [MODAL_FORMS.numberOfGuests]: t('number_of_guests'),
  [MODAL_FORMS.reservationType]: t('type_of_reservation'),
  [MODAL_FORMS.confirmLeaderGuest]: undefined,
});

function ReservationSettingsModal({
  isOpen,
  onClose,
  ageLimit,
  availableForms,
}: ReservationSettingsModalProps) {
  const {t} = useTranslation();
  const {seasonEntity, isFetching: isTaxesFetching} = useTaxes();
  const {checkCanEditReservationDetails} = useReservationSettings();
  const [isLoadingSaveGuest, setIsLoadingSaveGuest] = useState(false);
  const [currentModalForm, setCurrentModalForm] = useState(MODAL_FORMS.numberOfGuests);

  const {
    guestGroup,
    updateGuestGroup: patchGuestGroup,
    isLoading: isGuestGroupLoading,
    isFetching: isGuestGroupFetching,
    guestGroupMutation,
  } = useGuestGroup();
  const {mutate} = useMutateReservation();
  const canEdit = checkCanEditReservationDetails();
  const title = getModalTitle(t)[currentModalForm];

  const formMethods = useForm<FormTypes>();
  const {handleSubmit} = formMethods;

  const isLoading =
    isGuestGroupLoading || isGuestGroupFetching || isTaxesFetching || isLoadingSaveGuest;
  const disabled = isLoading;
  const groupType = guestGroup?.group_type ?? GROUP_TYPES.family;

  useEffect(
    function reinitializeCurrentModalSection() {
      if (!availableForms[MODAL_FORMS.numberOfGuests]) {
        availableForms[MODAL_FORMS.reservationType]
          ? setCurrentModalForm(MODAL_FORMS.reservationType)
          : setCurrentModalForm(MODAL_FORMS.confirmLeaderGuest);
      } else {
        setCurrentModalForm(MODAL_FORMS.numberOfGuests);
      }
    },
    [availableForms, guestGroupMutation.error],
  );

  const updateGuestGroup = useCallback(
    (formData: FormTypes) => {
      const visibleFields = getFields({
        lessThanRule: seasonEntity.lessThanRule,
        betweenRule: seasonEntity.betweenRule,
        moreThanRule: seasonEntity.moreThanRule,
      });

      const senior = visibleFields[FORM_NAMES.senior] ? formData[FORM_NAMES.senior] : 0;
      const middle = visibleFields[FORM_NAMES.middle] ? formData[FORM_NAMES.middle] : 0;
      const junior = visibleFields[FORM_NAMES.junior] ? formData[FORM_NAMES.junior] : 0;
      const numberOfGuests = visibleFields[FORM_NAMES.numberOfGuests]
        ? formData[FORM_NAMES.numberOfGuests]
        : 0;

      const payload = {
        seniors: senior,
        adults: middle || numberOfGuests,
        children: junior,
        group_type: getGroupType(numberOfGuests, formData[FORM_NAMES.group_type]?.value),
      };

      patchGuestGroup(payload, {
        onSettled: onClose,
        onSuccess: onClose,
      });
    },
    [onClose, patchGuestGroup, seasonEntity],
  );

  const onSubmit = (submitingForm: MODAL_FORMS) => (formData: FormTypes) => {
    const numberOfGuests =
      formData[FORM_NAMES.numberOfGuests] ?? guestGroup?.known_number_of_guests;

    const isNumberOfGuestForm = submitingForm === MODAL_FORMS.numberOfGuests;
    const isReservationTypeForm = submitingForm === MODAL_FORMS.reservationType;
    const shouldSwitchToReservationTypeForm =
      numberOfGuests > 1 &&
      availableForms[MODAL_FORMS.reservationType] &&
      !isReservationTypeForm;

    const shouldSwitchToConfirmLeaderGuestForm =
      isNumberOfGuestForm && availableForms[MODAL_FORMS.confirmLeaderGuest];

    if (shouldSwitchToConfirmLeaderGuestForm) {
      setCurrentModalForm(MODAL_FORMS.confirmLeaderGuest);
      return;
    }

    if (shouldSwitchToReservationTypeForm) {
      setCurrentModalForm(MODAL_FORMS.reservationType);
      return;
    }

    updateGuestGroup(formData);
  };

  const onConfirmLeaderGuest = async (showInvoiceFields?: boolean) => {
    await mutate({payload: {show_invoicing_fields: showInvoiceFields}});

    if (availableForms[MODAL_FORMS.reservationType]) {
      setCurrentModalForm(MODAL_FORMS.reservationType);
      return;
    }
    handleSubmit(onSubmit(MODAL_FORMS.confirmLeaderGuest))();
  };
  return (
    <StyledModalSheet
      isOpenModalSheet={isOpen}
      handleCloseModal={onClose}
      title={title}
      closeOnEscape
      className="who-coming-sheet"
      closeButtonType="click"
      withCloseButton={false}
      withModalMobileCloseButton={false}
      modalSection={currentModalForm}
      closeOnDocumentClick={false}
    >
      <Content isLoading={isLoading} className="who-coming-sheet__content">
        <StyledLoader isLoading={isLoading} loaderWidth={80}>
          <FormProvider {...formMethods}>
            {currentModalForm === MODAL_FORMS.numberOfGuests && (
              <NumberOfGuestsForm
                guestGroup={guestGroup}
                onSubmit={handleSubmit(onSubmit(MODAL_FORMS.numberOfGuests))}
                ageLimit={ageLimit}
                disabled={disabled}
                isLoading={isLoading}
                canEdit={canEdit}
              />
            )}
            {currentModalForm === MODAL_FORMS.confirmLeaderGuest && (
              <ConfirmLeaderGuestForm
                onSaveGuest={() => setIsLoadingSaveGuest(true)}
                onConfirm={onConfirmLeaderGuest}
                onFinish={() => setIsLoadingSaveGuest(false)}
              />
            )}
            {currentModalForm === MODAL_FORMS.reservationType && (
              <ReservationTypeForm
                onSubmit={handleSubmit(onSubmit(MODAL_FORMS.reservationType))}
                groupType={groupType}
                disabled={disabled}
              />
            )}

            {Boolean(guestGroupMutation?.error?.errors?.[0]?.message) && (
              <ErrorContainer className="who-coming-sheet__error">
                <ErrorMessage>{guestGroupMutation.error?.errors[0].message}</ErrorMessage>
              </ErrorContainer>
            )}
          </FormProvider>
        </StyledLoader>
      </Content>
    </StyledModalSheet>
  );
}
export {ReservationSettingsModal};
