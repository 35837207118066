import styled from 'styled-components';

export const StyledMenu = styled.div`
  position: absolute;
  width: 100%;
  padding: 15px 0;

  font-family: inherit;
  font-weight: ${props => props.theme.fonts.fontWeight.regular};
  color: ${props => props.theme.colors.$p2};
  font-size: ${props => props.theme.fonts.sizes.medium};
  height: auto;
  min-height: 40px;
  max-height: 309px;
  background: ${props => props.theme.colors.$p6};
  box-shadow: 0 10px 16px #0f0f0f0d;
  border-radius: 0 0 7px 7px;
  z-index: 5;
  margin-top: 0;

  &:after {
    content: '';
    display: block;
    height: 50px;
    width: 2px;
    position: absolute;
  }
`;

export const StyledDropdown = styled.div`
  position: relative;

  & .select__menu,
  .select__menu > div {
    position: static;
    box-shadow: none;
    border-radius: 0;
  }

  & .select__menu {
    &:after {
      content: none;
    }
  }

  & .infinite-scroll-select__menu {
    padding-top: 0;
  }

  & .select__control-wrapper {
    padding: 0 15px 15px;
    grid-template-rows: auto 40px auto;
  }

  & .select__control {
    max-height: 40px;
    cursor: text;
    border-radius: 32px;
  }

  & fieldset {
    border-radius: 32px;
  }

  & .select__input-container {
    margin: 0;
  }
`;
