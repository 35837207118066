import StyledIcon from '../common/StyledIcon';
import {BackButton, Wrapper, AutoFillText} from './styled';

type MobileSubHeaderPanelProps = {
  title?: string;
  onBackClick?: () => void;
  hideBackButton?: boolean;
};
function SubHeaderPanel({title, onBackClick, hideBackButton}: MobileSubHeaderPanelProps) {
  return (
    <Wrapper className="sub-header-panel">
      {!hideBackButton && (
        <BackButton
          onClick={onBackClick}
          icon={<StyledIcon name={'backBlueArrow'} size={'15px'} color={'#385bf8'} />}
          light
        />
      )}
      <AutoFillText>{title}</AutoFillText>
    </Wrapper>
  );
}

export {SubHeaderPanel};
