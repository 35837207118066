// https://www.npmjs.com/package/trim-canvas

export function trimCanvas(canvas: HTMLCanvasElement) {
  const copiedCanvas = copyCanvas(canvas, 0.4);
  const context = copiedCanvas.getContext('2d');

  const imgWidth = copiedCanvas.width;
  const imgHeight = copiedCanvas.height;

  const imgData = context?.getImageData?.(0, 0, imgWidth, imgHeight).data;

  const cropTop = scanY(true, imgWidth, imgHeight, imgData) ?? 0;
  const cropBottom = scanY(false, imgWidth, imgHeight, imgData) ?? 0;
  const cropLeft = scanX(true, imgWidth, imgHeight, imgData) ?? 0;
  const cropRight = scanX(false, imgWidth, imgHeight, imgData) ?? 0;

  const cropXDiff = cropRight - cropLeft + 1;
  const cropYDiff = cropBottom - cropTop + 1;

  const trimmedData = context?.getImageData?.(cropLeft, cropTop, cropXDiff, cropYDiff);

  copiedCanvas.width = cropXDiff;
  copiedCanvas.height = cropYDiff;

  context?.clearRect?.(0, 0, cropXDiff, cropYDiff);

  if (trimmedData) {
    context?.putImageData?.(trimmedData, 0, 0);
  }

  return copiedCanvas;
}

function copyCanvas(canvas: HTMLCanvasElement, scale = 1) {
  const copy = document.createElement('canvas');
  const context = copy.getContext('2d', {
    willReadFrequently: true,
  }) as CanvasRenderingContext2D;

  copy.width = canvas.width * scale;
  copy.height = canvas.height * scale;
  context.drawImage(canvas, 0, 0, copy.width, copy.height);

  return copy;
}

function getRGBA(x: number, y: number, imgWidth: number, imgData?: Uint8ClampedArray) {
  if (!imgData) {
    return {};
  }

  return {
    red: imgData[(imgWidth * y + x) * 4],
    green: imgData[(imgWidth * y + x) * 4 + 1],
    blue: imgData[(imgWidth * y + x) * 4 + 2],
    alpha: imgData[(imgWidth * y + x) * 4 + 3],
  };
}

function getAlpha(x: number, y: number, imgWidth: number, imgData?: Uint8ClampedArray) {
  return getRGBA(x, y, imgWidth, imgData).alpha;
}

function scanY(
  fromTop: boolean,
  imgWidth: number,
  imgHeight: number,
  imgData?: Uint8ClampedArray,
) {
  const offset = fromTop ? 1 : -1;
  const firstCol = fromTop ? 0 : imgHeight - 1;

  for (let y = firstCol; fromTop ? y < imgHeight : y > -1; y += offset) {
    for (let x = 0; x < imgWidth; x++) {
      if (getAlpha(x, y, imgWidth, imgData)) {
        return y;
      }
    }
  }

  return null;
}

function scanX(
  fromLeft: boolean,
  imgWidth: number,
  imgHeight: number,
  imgData?: Uint8ClampedArray,
) {
  const offset = fromLeft ? 1 : -1;
  const firstRow = fromLeft ? 0 : imgWidth - 1;

  for (let x = firstRow; fromLeft ? x < imgWidth : x > -1; x += offset) {
    for (let y = 0; y < imgHeight; y++) {
      if (getAlpha(x, y, imgWidth, imgData)) {
        return x;
      }
    }
  }

  return null;
}
