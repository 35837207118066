import React from 'react';
import {useMatch} from 'react-router-dom';
import Menu from '../Menu';
import {PATHS} from 'Routes';
import {GUESTAPP_NAVIGATION_MODE} from 'utils/constants';
import {useSummary} from 'context/summary';
import {useReservation} from 'context/reservation';
import {useCustomTheme} from 'context/customTheme';
import {useScreenResize} from 'hooks';
import {device} from '@guestapp/ui/src/utils/device';
import {ShoppingCartButton} from '../ShoppingCartButton';
import {
  DiscardChangesModal,
  DiscardChangesModalRef,
} from '../DiscardChangesModal/DiscardChangesModal';
import {
  HousingLongNameModal,
  HousingLongNameModalPropsRef,
} from '../HousingLongNameModal/HousingLongNameModal';
import {
  Container,
  HeaderWrapper,
  HeaderTitle,
  LogoContainer,
  Title,
  ContentIcons,
} from './styled';

type NavbarProps = {
  visible?: boolean;
  title?: string;
};

type AppHeaderSettings = {
  navigationTitleConfig: GUESTAPP_NAVIGATION_MODE;
  logo?: string | null;
};

function getHousingImgSrc(appSettingTemplate: AppHeaderSettings, housingLogo?: string) {
  const showLogo = [
    GUESTAPP_NAVIGATION_MODE.logo,
    GUESTAPP_NAVIGATION_MODE.property_name_and_logo,
  ].includes(appSettingTemplate.navigationTitleConfig);

  if (!showLogo && !housingLogo) {
    return;
  }

  return appSettingTemplate.logo || housingLogo;
}

const Navbar = ({visible, title}: NavbarProps) => {
  const {data} = useSummary();
  const {housing} = useReservation();
  const {data: templateData} = useCustomTheme();
  const discardChangesModalRef = React.useRef<DiscardChangesModalRef>(null);
  const housingLongNameModalRef = React.useRef<HousingLongNameModalPropsRef>(null);
  const isHomeView = useMatch('/:token');
  const name = title || data?.display_name || data?.property_name || '';
  const housingNameLength = name?.length || 0;
  const {isMatch: isMobileMode} = useScreenResize(device.tabletSubHeader);

  const handleClickOnTitle = React.useCallback(() => {
    if (!isMobileMode) return;
    if (isHomeView && housingNameLength > 20) {
      housingLongNameModalRef.current?.open();
      return;
    }
    discardChangesModalRef.current?.open(PATHS.home);
  }, [discardChangesModalRef, isHomeView, housingNameLength, isMobileMode]);

  const titleLogo = templateData?.navigation_title_logo;
  const titleLogoType =
    templateData?.navigation_title_type || GUESTAPP_NAVIGATION_MODE.property_name;

  const appSettingTemplate: AppHeaderSettings = {
    navigationTitleConfig: titleLogoType,
    logo: titleLogo,
  };
  const housingImg = getHousingImgSrc(appSettingTemplate, housing?.picture?.src);
  const showLogo =
    appSettingTemplate.navigationTitleConfig !== GUESTAPP_NAVIGATION_MODE.property_name &&
    titleLogoType;

  return (
    <>
      <HeaderWrapper visible={visible} className="navbar">
        <Container>
          {showLogo && (
            <LogoContainer className="navbar__logo">
              <img
                src={housingImg}
                width={35}
                height={35}
                alt="housing Logo"
                className="navbar__logo-img"
              />
            </LogoContainer>
          )}

          {titleLogoType !== GUESTAPP_NAVIGATION_MODE.logo ||
          (titleLogoType === GUESTAPP_NAVIGATION_MODE.logo && !isMobileMode) ? (
            <HeaderTitle onClick={handleClickOnTitle} className="navbar__title">
              <Title className="navbar__title-text">{name}</Title>
            </HeaderTitle>
          ) : null}
          <ContentIcons>
            <ShoppingCartButton />
            <Menu showLogo={Boolean(showLogo)} />
          </ContentIcons>
        </Container>
      </HeaderWrapper>
      <DiscardChangesModal ref={discardChangesModalRef} />
      <HousingLongNameModal ref={housingLongNameModalRef} name={name} />
    </>
  );
};

export {Navbar};
export type {NavbarProps};
