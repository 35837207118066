import React from 'react';
import * as Sentry from '@sentry/react';
import {useTranslation} from 'react-i18next';
import fetcher from '../utils/fetcher';
import {useChekinSDK} from '../context/ChekinSDK';
import {OCR_CHECKING_STATUSES} from '../utils/constants';
import {BiomatchResponse, CompareBiomatchResponse} from '@guestapp/sdk';

const MaxRetriesNumber = 10;
const RetryTimeoutMs = 4000;

enum FaceDetectionOrigin {
  document = 'DOCUMENT',
  selfie = 'selfie',
}
type Options = {
  retries?: number;
};
type checkOCRResponse =
  | {
      mrzDetected?: boolean;
      detectionFailed?: boolean;
    }
  | undefined;

export type SendSelfieAndDocumentToCompareOptions = {
  reservationId: string;
  personPhotoId: string;
  documentPhotoId: string;
};

type UseBiomatch = {
  onError?: (error: Error) => void;
  onFinish?: (image: string, data: BiomatchResponse) => void;
  onBefore?: () => void;
  onSuccessComparing?: (data: CompareBiomatchResponse) => void;
  onDetectionFailed?: (errorMessage?: string) => void;
  onRetriesFinish?: (message?: string) => void;
};

function useBiomatch({
  onError,
  onFinish,
  onBefore,
  onDetectionFailed,
  onSuccessComparing,
  onRetriesFinish,
}: UseBiomatch) {
  const {t} = useTranslation();
  const {
    sendDocumentForFaceDetection,
    checkDocumentFaceDetectionStatus,
    sendSelfieForFaceDetection,
    checkSelfieFaceDetection,
    compareSelfieAndDocumentFaces,
    checkSelfieAndDocumentFacesComparingStatus,
  } = useChekinSDK();

  const retryTimeoutRef = React.useRef<ReturnType<typeof setTimeout>>();
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();

  React.useEffect(() => {
    const retryTimeout = retryTimeoutRef.current;
    const timeout = timeoutRef.current;

    return () => {
      clearTimeout(retryTimeout);
      clearTimeout(timeout);
    };
  }, []);

  const handleSuccessBiomatch = React.useCallback(
    (image: string, data: BiomatchResponse) => {
      onFinish?.(image, data);
    },
    [onFinish],
  );

  const checkDetectionFaceStatus = React.useCallback(
    (
      callback: (id: string) => ReturnType<typeof checkDocumentFaceDetectionStatus>,
      origin: FaceDetectionOrigin,
    ) => {
      const handler = async (
        id: string,
        image: string,
        options?: Options,
      ): Promise<checkOCRResponse> => {
        try {
          const data = await fetcher(callback, id);
          const isInProgress =
            data?.status === OCR_CHECKING_STATUSES.processing ||
            data?.status === OCR_CHECKING_STATUSES.new;
          const isSuccess =
            data?.status === OCR_CHECKING_STATUSES.complete && data?.is_face_detected;
          const isDetectionFailed =
            data?.status === OCR_CHECKING_STATUSES.error ||
            (data?.status === OCR_CHECKING_STATUSES.complete && !data?.is_face_detected);

          if (isInProgress) {
            const retries = options?.retries || 0;
            if (retries >= MaxRetriesNumber) {
              onRetriesFinish?.(t('try_again_or_contact'));
              return;
            }

            retryTimeoutRef.current = setTimeout(async () => {
              await handler(id, image, {retries: retries + 1});
              return;
            }, RetryTimeoutMs);

            return;
          }
          if (isSuccess) {
            handleSuccessBiomatch(image, data);
            return;
          }
          if (isDetectionFailed) {
            const faceErrorMessageTransKey =
              origin === FaceDetectionOrigin.document
                ? 'your_face_document_could_not_be_detected'
                : 'your_face_could_not_be_detected';
            onDetectionFailed?.(t(faceErrorMessageTransKey));
          }
        } catch (error) {
          Sentry.captureException(error);
          onError?.(error as Error);
        }
      };

      return handler;
    },
    [handleSuccessBiomatch, onDetectionFailed, onError, onRetriesFinish, t],
  );

  const sendAndVerifyDocumentForFaceDetection = React.useCallback(
    async (image: string, countryCode: string, reservationId: string) => {
      try {
        onBefore?.();
        const data = await fetcher(sendDocumentForFaceDetection, {
          picture_file: image,
          for_country: countryCode,
          reservation_id: reservationId,
        });
        if (!data?.id) return;

        const checkDocumentFaceStatus = checkDetectionFaceStatus(
          checkDocumentFaceDetectionStatus,
          FaceDetectionOrigin.document,
        );
        await checkDocumentFaceStatus(data?.id, image);
      } catch (error) {
        Sentry.captureException(error);
        onError?.(error as Error);
      }
    },
    [
      onError,
      onBefore,
      checkDetectionFaceStatus,
      sendDocumentForFaceDetection,
      checkDocumentFaceDetectionStatus,
    ],
  );

  const sendAndVerifySelfieForFaceDetection = React.useCallback(
    async (image: string, countryCode: string, reservationId: string) => {
      try {
        onBefore?.();
        const data = await fetcher(sendSelfieForFaceDetection, {
          picture_file: image,
          for_country: countryCode,
          reservation_id: reservationId,
        });
        if (!data?.id) return;

        const checkSelfieStatus = checkDetectionFaceStatus(
          checkSelfieFaceDetection,
          FaceDetectionOrigin.selfie,
        );
        await checkSelfieStatus(data?.id, image);
      } catch (error) {
        Sentry.captureException(error);
        onError?.(error as Error);
      }
    },
    [
      checkDetectionFaceStatus,
      checkSelfieFaceDetection,
      onBefore,
      onError,
      sendSelfieForFaceDetection,
    ],
  );

  const handleSelfieAndDocumentCompareStatus = React.useCallback(
    (data: CompareBiomatchResponse) => {
      onSuccessComparing?.(data);
    },
    [onSuccessComparing],
  );

  const checkSelfieAndDocumentCompareStatus = React.useCallback(
    async (compareId: string, options?: Options) => {
      try {
        const data = await fetcher(checkSelfieAndDocumentFacesComparingStatus, compareId);
        const isInProgress =
          data?.status === OCR_CHECKING_STATUSES.processing ||
          data?.status === OCR_CHECKING_STATUSES.new;
        const isSuccess =
          data?.status === OCR_CHECKING_STATUSES.complete && data?.is_match;
        const isDetectionFailed =
          data?.status === OCR_CHECKING_STATUSES.error ||
          (data?.status === OCR_CHECKING_STATUSES.complete && !data?.is_match);

        if (isInProgress) {
          const retries = options?.retries || 0;
          if (retries >= MaxRetriesNumber) {
            onRetriesFinish?.(t('try_again_or_contact'));
            return;
          }

          retryTimeoutRef.current = setTimeout(async () => {
            await checkSelfieAndDocumentCompareStatus(compareId, {retries: retries + 1});
            return;
          }, RetryTimeoutMs);

          return;
        }
        if (isSuccess) {
          handleSelfieAndDocumentCompareStatus?.(data);
        }
        if (isDetectionFailed) {
          onDetectionFailed?.(t('matching_error'));
        }
      } catch (error) {
        Sentry.captureException(error);
        onError?.(error as Error);
      }
    },
    [
      checkSelfieAndDocumentFacesComparingStatus,
      handleSelfieAndDocumentCompareStatus,
      onDetectionFailed,
      onRetriesFinish,
      onError,
      t,
    ],
  );

  const sendSelfieAndDocumentToCompare = React.useCallback(
    async ({
      reservationId,
      personPhotoId,
      documentPhotoId,
    }: SendSelfieAndDocumentToCompareOptions) => {
      try {
        onBefore?.();
        const data = await fetcher(compareSelfieAndDocumentFaces, {
          person_picture: personPhotoId,
          identification_picture: documentPhotoId,
          reservation_id: reservationId,
        });
        if (!data?.id) return;

        await checkSelfieAndDocumentCompareStatus(data?.id);
      } catch (error) {
        Sentry.captureException(error);
        onError?.(error as Error);
      }
    },
    [
      onBefore,
      compareSelfieAndDocumentFaces,
      checkSelfieAndDocumentCompareStatus,
      onError,
    ],
  );

  return {
    sendAndVerifyDocumentForFaceDetection,
    sendAndVerifySelfieForFaceDetection,
    sendSelfieAndDocumentToCompare,
  };
}

export {useBiomatch};
