import React from 'react';
import {SelectProps, SelectRefType} from '../Select';
import {MenuList} from './components';
import {StyledSelect} from './styled';

export type InfiniteScrollSelectProps<T, V, L> = SelectProps<T, V, L> & {
  canLoadMore?: boolean;
  loadMoreItems?: () => void;
  listAutoSizer?: boolean;
  listSizes?: {
    itemSize?: number;
    width?: number | string;
    height?: number | string;
  };
};

const InfiniteScrollSelectInternal = <T, V, L>(
  {components, ...props}: InfiniteScrollSelectProps<T, V, L>,
  ref: React.ForwardedRef<SelectRefType<T, V, L>>,
) => {
  return <StyledSelect ref={ref} components={{MenuList, ...components}} {...props} />;
};

const InfiniteScrollSelect = React.forwardRef(InfiniteScrollSelectInternal) as <T, V, L>(
  props: InfiniteScrollSelectProps<T, V, L> & {
    ref?: React.ForwardedRef<SelectRefType<T, V, L>>;
  },
) => ReturnType<typeof InfiniteScrollSelectInternal>;

export {InfiniteScrollSelect};
