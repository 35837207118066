import IVStepper from 'components/common/IVStepper';
import Page from 'components/Page';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useLocation, useMatch, useNavigate} from 'react-router-dom';
import {device} from '@guestapp/ui/src/utils/device';
import {usePath, useScreenResize} from '../../hooks';
import {IdentityContainer} from './styled';
// import DiscardChangesModal from 'components/common/DiscardChangesModal/';
// import {DiscardChangesModalRef} from 'components/common/DiscardChangesModal/DiscardChangesModal';
import {PATHS} from 'Routes';
import {useOpenModals} from 'context/openModals';
import DiscardLastPhotoModal from 'components/common/DiscardLastPhotoModal';
import {DiscardLastPhotoModalRef} from 'components/common/DiscardLastPhotoModal/DiscardLastPhotoModal';

function IdentityVerification() {
  const {t} = useTranslation();
  const {isMatch: isMobileMode} = useScreenResize(device.tabletSubHeader);
  // const discardChangesModalRef = React.useRef<DiscardChangesModalRef>(null);
  const discardLastPhotoModalRef = React.useRef<DiscardLastPhotoModalRef>(null);
  const {checkIfOpenDiscardModalAndRedirect} = useOpenModals();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const {getFullPath} = usePath();

  // React.useEffect(() => {
  //   if (!isIVEnabled && isDataLoaded && !isGuestSchemaLoading) {
  //     navigate(getMainPath(), {replace: true});
  //   }
  // }, [getMainPath, isDataLoaded, isIVEnabled, navigate, isGuestSchemaLoading]);

  const match = useMatch('/:token/verification');
  const shouldShowStepper = Boolean(match);
  const isFrontSideView = useMatch('/:token/verification/front/take');
  const isBackSideView = useMatch('/:token/verification/back/take');
  const isBackSideUploadTypeSelection = useMatch(
    '/:token/verification/uploading-type/back',
  );
  const isSelfieView = useMatch('/:token/verification/selfie');
  const isBackOrSelfieView =
    pathname.includes(PATHS.verification.selfie) || isBackSideUploadTypeSelection;

  // const openDiscardIVChangesModal = () =>
  //   discardChangesModalRef.current?.open(PATHS.verification.main);

  const openDiscardIVLastPhotoModal = () =>
    discardLastPhotoModalRef.current?.open(PATHS.verification.main);

  const onBackButtonClick = () =>
    isBackOrSelfieView
      ? openDiscardIVLastPhotoModal()
      : Boolean(match)
      ? navigate(getFullPath(PATHS.home), {replace: true})
      : checkIfOpenDiscardModalAndRedirect('-1');

  const isTakeView =
    isMobileMode &&
    (Boolean(isFrontSideView) || Boolean(isBackSideView) || Boolean(isSelfieView));

  return (
    <IdentityContainer>
      <Page
        isSubHeaderVisible={!isTakeView}
        isBackButtonDisabled={isMobileMode}
        title={t('identity_verification')}
        onBackButtonClick={onBackButtonClick}
        navBarTitle={isMobileMode ? t('identity_verification') : t('guest_registration')}
        className="iv-flow-layout"
        mainHeader={!isMobileMode}
      >
        {!shouldShowStepper && <IVStepper onClickBackMobile={onBackButtonClick} />}
        {/* <DiscardChangesModal ref={discardChangesModalRef} /> */}
        <DiscardLastPhotoModal ref={discardLastPhotoModalRef} />
        <Outlet />
      </Page>
    </IdentityContainer>
  );
}

export {IdentityVerification};
