import styled from 'styled-components';
import {device} from '../../styled/device';

export const EmptyScreen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 285px;
  margin: 30px auto 0;
  & .custom-icon {
    margin-bottom: 40px;
  }
  & > img {
    margin-bottom: 24px;
    color: ${({theme}) => theme.colors.branding.primary};
  }

  & > h1 {
    margin: 0 0 8px 0;
    padding: 0;
    font-size: ${({theme}) => theme.fonts.sizes.large};
    font-weight: ${({theme}) => theme.fonts.fontWeight.mediumL};
    text-align: center;
    color: ${({theme}) => theme.colors.branding.fontPrimary};
  }

  & > p {
    font-size: ${({theme}) => theme.fonts.sizes.small};
    font-weight: ${({theme}) => theme.fonts.fontWeight.regular};
    padding: 0;
    margin: 0;
    text-align: center;
    color: ${({theme}) =>
      theme.hasTemplate
        ? theme.colors.branding.fontSecondary
        : theme.colors.branding.fontPrimary};
  }

  @media (min-width: ${device.laptop}) {
    margin-top: 8px;

    & > img {
      margin-bottom: 32px;
    }

    & > h1 {
      margin: 0 0 16px 0;
    }
  }
`;
