import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {CounterSize} from 'components/common/Counter';
import {PriceItem, OFFER_CONFIRMATION_TYPES} from '@guestapp/sdk';
import {usePaymentSettings} from 'hooks';
import CustomIcon from 'components/common/CustomIcon';

import {
  StyledCounter,
  Main,
  Title,
  Price,
  PricePerLabel,
  PerLabel,
  CounterWrapper,
  TotalPrice,
  Container,
  InfoContainer,
  TotalPriceWrapper,
} from './styled';

const getTitleKey = (unitType: PriceItem['unit_type']) =>
  `upselling_add_to_card_form.title.${unitType?.toLocaleLowerCase() || 'per_unit'}`;

const getPricePerKey = (unitType: PriceItem['unit_type']) =>
  `upselling_add_to_card_form.price.${unitType?.toLocaleLowerCase() || 'per_unit'}`;

const formatPrice = (currencySymbol: string, price: number) =>
  `${currencySymbol}${price.toFixed(2)}`;

type Props = {
  price: number;
  value: number;
  onChange?: (value: number) => void;
  unitType?: PriceItem['unit_type'];
  className?: string;
  confirmationType?: OFFER_CONFIRMATION_TYPES;
};
export const AddToCartForm = ({
  unitType,
  price,
  className,
  value,
  onChange,
  confirmationType,
}: Props) => {
  const {t} = useTranslation();
  const {currencySymbol} = usePaymentSettings();
  const theme = useTheme();

  return (
    <Container className={className}>
      <CounterWrapper>
        <Title>
          <CustomIcon
            name="experiencesUnits"
            color={theme.hasTemplate ? theme.colors.branding.icons : '#777E91'}
            size={24}
          />
          <span>{t(getTitleKey(unitType))}</span>
        </Title>
        <Main>
          <PricePerLabel>
            <Price>{formatPrice(currencySymbol, price)}</Price>
            <PerLabel>{`/${t(getPricePerKey(unitType))}`}</PerLabel>
          </PricePerLabel>
          <StyledCounter value={value} size={CounterSize.S} onChange={onChange} />
        </Main>
      </CounterWrapper>
      <TotalPriceWrapper>
        <TotalPrice>
          <span>{t('upselling_add_to_card_form.total_price')}</span>
          <span>{formatPrice(currencySymbol, price * value)}</span>
        </TotalPrice>
        {confirmationType === OFFER_CONFIRMATION_TYPES.manual && (
          <InfoContainer>
            <CustomIcon
              name="informationGray"
              size={24}
              custom
              color={theme.colors.branding.fontSecondary}
            />
            <span>{t('upselling_add_to_card_form.total_price_info')}</span>
          </InfoContainer>
        )}
      </TotalPriceWrapper>
    </Container>
  );
};
