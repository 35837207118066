import React from 'react';
import {CustomIcon} from '../CustomIcon';
import clsx from 'clsx';
import {
  Box,
  CheckboxWrapper,
  CheckedRoundCheckbox,
  Content,
  Label,
  ErrorMessage,
} from './styled';

type CheckboxProps = {
  onChange: (checked?: boolean) => void;
  label?: string | React.ReactNode | JSX.Element;
  disabled?: boolean;
  value?: boolean;
  round?: boolean;
  className?: string;
  error?: string;
  name?: string;
  content?: string | React.ReactNode | JSX.Element;
};

const defaultProps: CheckboxProps = {
  onChange: () => {},
  label: '',
  disabled: false,
  value: false,
  className: undefined,
  name: '',
};

export const Checkbox = React.forwardRef<HTMLDivElement, CheckboxProps>(
  ({onChange, label, disabled, value, className, name, error, round, content}, ref) => {
    const [isChecked, setIsChecked] = React.useState(value);
    const handleChange = () => {
      if (disabled) return;
      setIsChecked(!isChecked);
      onChange(!isChecked);
    };

    return (
      <>
        <CheckboxWrapper
          onClick={handleChange}
          disabled={disabled}
          role="checkbox"
          aria-label={name}
          ref={ref}
          className={clsx(className, 'checkbox')}
        >
          {round ? (
            <Box checked={value} round={round} className="checkbox__round-box">
              {value && (
                <CheckedRoundCheckbox
                  // src={checkIcon}
                  // alt="Check mark"
                  data-testid={`${name}-checkmark`}
                />
              )}
            </Box>
          ) : (
            <Box checked={value} className="checkbox__box">
              {value && (
                <CustomIcon name="checkFill" color="currentColor" size={20} />
                // <AcceptIconImg
                //   src={checkIcon}
                //   alt="Check mark"
                //   data-testid={`${name}-checkmark`}
                // />
              )}
            </Box>
          )}
          <div className="checkbox__content-wrapper">
            {label && <Label className="checkbox__label">{label}</Label>}
            <Content className="checkbox__content">{content}</Content>
          </div>
        </CheckboxWrapper>
        {error && (
          <ErrorMessage className="error-message" data-testid={`${name}-error`}>
            {error}
          </ErrorMessage>
        )}
      </>
    );
  },
);

Checkbox.defaultProps = defaultProps;
// export {Checkbox};
