import React from 'react';
import styled, {css} from 'styled-components';
import SVG, {Props as SVGProps} from 'react-inlinesvg';
import {transformSizePropToSize} from '../../../utils/common';
import {BaseIconContainer} from 'styled/common';
import questionCircle from '../../../assets/icons/icon-question-circle.svg';
import whiteArrowRight from '../../../assets/icons/arrowNext.svg';
import trash from '../../../assets/icons/rubbish-bin.svg';
import camera from '../../../assets/icons/camera.svg';
import picture from '../../../assets/icons/picture-icon.svg';
import close from '../../../assets/icons/close-icon.svg';
import padlock from '../../../assets/icons/padlock.svg';
import backArrow from '../../../assets/icons/back-arrow.svg';
import arrowRightBlue from 'assets/icons/arrow-right-icon-blue.svg';
import thunder from 'assets/icons/tunder_icon.svg';
import editIcon from 'assets/icons/edit-icon.svg';
import questionCircleBlue from 'assets/icons/question-icon.svg';
import warningSecondary from 'assets/icons/warning-secondary.svg';
import checkSecondary from 'assets/icons/check-secondary.svg';
import creditCardBlue from 'assets/icons/credit-card-blue.svg';
import checkOutCalendar from 'assets/icons/check-out-calendar.svg';
import checkInCalendar from 'assets/icons/check-in-calendar.svg';
import multiGuest from 'assets/icons/multi-guest.svg';
import backBlueArrow from 'assets/icons/back-blue-arrow.svg';
import plusBlueIcon from 'assets/icons/plus-blue-icon.svg';
import deleteItemBox from 'assets/icons/delete_item_box.svg';
import information from 'assets/icons/information.svg';
import grayRightArrow from 'assets/icons/gray-right-arrow.svg';
import allianzShieldIcon from 'assets/icons/allianz-shield-icon.svg';
import dots from 'assets/icons/dots.svg';
import emptyStateCart from 'assets/img/empty-state-cart.svg';
import emptyStateList from 'assets/img/empty-state-list.svg';
import questionMarkIcon from 'assets/icons/question-blue-circle.svg';
import emptyNoAccessKeyIcon from 'assets/img/remote-access-no-keys.svg';
import ticketCircle from 'assets/icons/ticket-circle.svg';
import tag from 'assets/icons/tag.svg';
import experiencesUnits from 'assets/icons/experiences-units.svg';
import shoppingCart from 'assets/icons/shopping-cart.svg';
import informationGray from 'assets/icons/information-gray.svg';
import calendar from 'assets/icons/calendar.svg';

export type IconNames =
  | 'questionCircle'
  | 'whiteArrowRight'
  | 'trash'
  | 'camera'
  | 'picture'
  | 'close'
  | 'padlock'
  | 'backArrow'
  | 'arrowRightBlue'
  | 'editIcon'
  | 'thunder'
  | 'questionCircleBlue'
  | 'warningSecondary'
  | 'checkSecondary'
  | 'creditCardBlue'
  | 'checkOutCalendar'
  | 'checkInCalendar'
  | 'multiGuest'
  | 'backBlueArrow'
  | 'plusBlueIcon'
  | 'deleteItemBox'
  | 'information'
  | 'grayRightArrow'
  | 'allianzShieldIcon'
  | 'dots'
  | 'emptyStateCart'
  | 'emptyStateList'
  | 'questionMarkIcon'
  | 'emptyNoAccessKeyIcon'
  | 'ticketCircle'
  | 'tag'
  | 'experiencesUnits'
  | 'shoppingCart'
  | 'informationGray'
  | 'calendar';

export type IconsHash = {
  [K in IconNames]: string;
};

const icons: IconsHash = {
  questionCircle,
  whiteArrowRight,
  trash,
  camera,
  picture,
  close,
  padlock,
  backArrow,
  arrowRightBlue,
  editIcon,
  thunder,
  questionCircleBlue,
  warningSecondary,
  checkSecondary,
  creditCardBlue,
  checkOutCalendar,
  checkInCalendar,
  multiGuest,
  backBlueArrow,
  plusBlueIcon,
  deleteItemBox,
  information,
  grayRightArrow,
  allianzShieldIcon,
  dots,
  emptyStateCart,
  emptyStateList,
  questionMarkIcon,
  emptyNoAccessKeyIcon,
  ticketCircle,
  tag,
  experiencesUnits,
  shoppingCart,
  informationGray,
  calendar,
};

export type IconProps = {
  name: IconNames;
  title?: string;
  className?: string;
  size?: string | number;
  transform?: string | number;
  color?: string;
  custom?: boolean;
} & React.HTMLAttributes<SVGElement> &
  React.HTMLAttributes<SVGProps>;

const StyledSVGIcon = styled(SVG)<{
  src: string;
  color?: string;
  size?: string | number;
  $custom?: boolean;
}>`
  width: ${props => transformSizePropToSize(props.size) || '24px'};
  height: ${props => transformSizePropToSize(props.size) || '24px'};
  fill: ${props => props.color || 'none'};
  color: ${props => props.color || 'none'};

  ${props => {
    // console.log('props', props.title, props.custom);
    return props.$custom
      ? css`
          --icon-color: ${props.color || props.theme.colors.branding.icons};

          .system-path {
            fill: ${props.color || props.theme.colors.branding.icons};
          }
          #path-group {
            path,
            rect {
              fill: ${props.color || props.theme.colors.branding.icons};
            }
          }
          // styles for No register Guest svg AND 3 dots menu icon svg
          #empty-state-register-guest-linear-1,
          #empty-state-register-guest-linear-2,
          #sigle-dot-icon-linear-1,
          #sigle-dot-icon-linear-2,
          #sigle-dot-icon-linear-3,
          .auto-fill-icon-linear,
          .linear-svg-group {
            .stop-one {
              stop-color: ${props.color || props.theme.colors.branding.icons};
            }

            .stop-two {
              stop-color: ${props.color || props.theme.colors.branding.icons};
              opacity: 0.3;
            }
          }
          // end styles for No register Guest svg
          // styles for Error modal svg
          .primary-svg-color {
            fill: ${props.color || props.theme.colors.branding.icons};
            stroke: ${props.color || props.theme.colors.branding.icons};
            opacity: 0.3;
          }
          .secondary-svg-color {
            fill: ${props.color || props.theme.colors.branding.icons};
            stroke: ${props.color || props.theme.colors.branding.icons};
          }
          //end styles for Error modal svg

          .vector_1 {
            stroke: #dcdcdc;
            fill: none;
          }
          .vector_2 {
            stroke: #dcdcdc;
            fill: none;
          }
        `
      : css`
          .system-path,
          path {
            fill: ${props.color || props.theme.colors.branding.icons};
          }

          .circle-background {
            fill: ${props.color || props.theme.colors.branding.backgroundSecondary};
          }
        `;
  }}
`;

const CustomIcon = (props: IconProps) => {
  return (
    <BaseIconContainer size={props.size} className="custom-icon">
      <StyledSVGIcon
        src={icons[props.name]}
        size={props.size}
        width={props.size}
        height={props.size}
        title={props.title || props.name}
        color={props.color || 'transparent'}
        className={'custom-icon__icon ' + props.className}
        $custom={props.custom}
      />
    </BaseIconContainer>
  );
};

export default CustomIcon;
