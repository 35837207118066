import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {useScreenResize, useBookDeal, useModalControls} from 'hooks';
import {device} from '@guestapp/ui/src/utils/device';
import Page from 'components/Page';
import {OfferView} from 'pages/OfferView';
import Input from 'components/common/Input';
import {MobileActionPanel, ConfirmForm, ConfirmButton} from './styled';
import {PATTERNS} from '@guestapp/core';
import {CreateDealCompleteModal} from 'components/upselling/CreateDealCompleteModal';

type FormTypes = {
  email: string;
};
function EnterContactEmailView() {
  const {id: offerId} = useParams();
  const {t} = useTranslation();
  const {isMatch: isMobileMode, isInitialized} = useScreenResize(device.tabletSubHeader);

  const {
    isOpen: isCreateDealCompleteModalOpen,
    openModal: openCreateDealCompleteModal,
    closeModal: closeCreateDealCompleteModal,
  } = useModalControls();

  const {
    createDeal,
    offer,
    isLoading,
    bookExperienceEmail,
    navigateAfterSuccess,
    navigateToBookExperience,
    quantity,
  } = useBookDeal({
    offerId,
    onSuccess: openCreateDealCompleteModal,
  });

  useEffect(() => {
    if (!quantity) {
      navigateToBookExperience({replace: true});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {register, handleSubmit, formState, watch} = useForm<FormTypes>({
    defaultValues: {
      email: bookExperienceEmail,
    },
  });
  const currentEmail = watch('email');

  const onSubmit = ({email}: FormTypes) => {
    createDeal(email);
  };

  const onCloseCompleteModal = () => {
    navigateAfterSuccess();
    closeCreateDealCompleteModal();
  };

  if (!isInitialized) {
    return null;
  }

  return isMobileMode ? (
    <Page
      isLoading={isLoading}
      isSubHeaderVisible
      title={t('book_experience_view.subtitle')}
      navBarTitle={t('book_experience_view.nav_bar_title')}
      bottomStickyPanel={
        isMobileMode &&
        offer && (
          <MobileActionPanel>
            <ConfirmButton
              disabled={!currentEmail}
              label={t('confirm')}
              onClick={handleSubmit(onSubmit)}
            />
          </MobileActionPanel>
        )
      }
    >
      <ConfirmForm onSubmit={handleSubmit(onSubmit)}>
        <div>{t('book_experience_confirm_email.info')}</div>
        <Input
          {...register('email', {
            required: t('required'),
            pattern: {
              value: PATTERNS.email,
              message: t('invalid_email'),
            },
          })}
          label={t('email')}
          inputMode="email"
          autoCorrect="off"
          spellCheck={false}
          autoCapitalize="none"
          error={formState.errors['email']?.message}
        />
      </ConfirmForm>
      {offer && (
        <CreateDealCompleteModal
          isOpen={isCreateDealCompleteModalOpen}
          onClose={onCloseCompleteModal}
          confirmationType={offer?.confirmation_type}
        />
      )}
    </Page>
  ) : (
    <OfferView />
  );
}

export {EnterContactEmailView};
