import React from 'react';
import clsx from 'clsx';
import {Popup} from '../Popup';
import {contentStyle, overlayStyle, Wrapper} from './styled';

type TooltipProps = {
  children?: React.ReactElement | ((isOpen: boolean) => React.ReactElement);
  position?:
    | 'center center'
    | 'top left'
    | 'top center'
    | 'top right'
    | 'right top'
    | 'right center'
    | 'right bottom'
    | 'bottom left'
    | 'bottom center'
    | 'bottom right'
    | 'left top'
    | 'left center'
    | 'left bottom';
  label?: React.ReactNode | JSX.Element | string | null;
  className?: string;
};

function getReadableTooltipPosition(readableTooltipPosition?: ClientRect) {
  if (!readableTooltipPosition) {
    return;
  }

  const left = readableTooltipPosition.left;
  const right = readableTooltipPosition.right;

  if (left >= 280) {
    return 'top right';
  }

  if (right >= 280) {
    return 'top left';
  }

  if (left >= 135 && right >= 135) {
    return 'top center';
  }
}

function Tooltip({
  position = 'center center',
  label = '',
  children = undefined,
  className = undefined,
}: TooltipProps) {
  const tooltipRef = React.useRef<HTMLDivElement>(null);
  const [currPosition, setCurrPosition] = React.useState(position);

  React.useEffect(() => {
    const currentCoordinates = tooltipRef?.current?.getBoundingClientRect();
    const readableTooltipPosition = getReadableTooltipPosition(currentCoordinates);
    setCurrPosition(readableTooltipPosition || position);
  }, [tooltipRef, position]);

  React.useEffect(() => {
    position && setCurrPosition(position);
  }, [position]);

  return (
    <Wrapper className={clsx(className, 'tooltip')} ref={tooltipRef}>
      <Popup
        closeOnDocumentClick
        closeOnEscape
        contentStyle={contentStyle}
        overlayStyle={overlayStyle}
        arrow={false}
        trigger={children}
        position={currPosition}
        className={'tooltip__popup'}
        on={['hover', 'click']}
      >
        <>{label}</>
      </Popup>
    </Wrapper>
  );
}
export {Tooltip};
