import {device} from '@guestapp/ui/src/utils/device';
import ModalSheet from 'components/common/ModalSheet';
import styled from 'styled-components';

export const AllianzGuestsInformationContainer = styled.div`
  margin-top: 24px;
  color: #777e91;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  display: flex;
  gap: 8px;
  cursor: pointer;
`;
export const AllianzIcon = styled.img`
  width: 24px;
`;

export const StyledModalSheet = styled(ModalSheet)`
  & .modal-sheet__title {
    margin-bottom: 32px;
    margin-top: 18px;
  }
  @media (min-width: ${device.tablet}) {
    &-content {
      max-width: 400px;
      border-radius: 5px;
      padding: 24px 16px;
    }
  }
`;

export const InsuracenPaidGuestBox = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 11fr;
  margin-bottom: 16px;
  gap: 8px;

  img {
    width: 24px;
  }
`;

export const InfoContainer = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & .guest-name {
    color: #152946;
    font-size: 16px;
  }

  & .plan-name {
    color: #777e91;
    text-align: left;
  }
`;

export const ContentInformationBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4px;
  margin: 0 auto 72px;

  & div:first-child {
    position: relative;
    top: 2px;
  }

  @media (min-width: ${device.tablet}) {
    max-width: 320px;
    margin: 0 auto 48px;
  }

  path,
  circle {
    stroke: ${props => props.theme.colors.branding.fontSecondary};
  }
`;

export const EditIcon = styled.div`
  cursor: pointer;
  path,
  rect {
    fill: ${props => props.theme.colors.branding.primary};
  }
`;

export const GuestListTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;
  text-align: center;
  display: flex;
  gap: 8px;
  font-family: ${props => props.theme.fonts.fontFamily.titles.font_name};
  color: ${props => props.theme.colors.branding.fontPrimary};

  @media (min-width: ${device.tablet}) {
    margin-bottom: 32px;
  }
`;

export const SubTitleInformation = styled.div`
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.colors.branding.fontSecondary};

  @media (min-width: ${device.tablet}) {
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;
