import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import {StatusModalStates} from 'components/StatusModal';
import errorInformationIcon from '../../assets/icons/error-information.svg';
import {OkButton, StyledStatusModal} from './styled';

type ProcessingModalProps = {
  isOpen: boolean;
  close: () => void;
  state: StatusModalStates;
  error?: string;
};
function ProcessingModal({isOpen, close, state, error}: ProcessingModalProps) {
  const {t} = useTranslation();

  const getTitle = () => {
    switch (state) {
      case StatusModalStates.SUCCESS:
        return t('transaction_successful');
      case StatusModalStates.ERROR:
        return t('payment_error_title');
      case StatusModalStates.LOADING:
        return t('processing_transaction');
    }
  };

  const text = state === StatusModalStates.ERROR ? error : '';

  const iconSrc = state === StatusModalStates.ERROR ? errorInformationIcon : undefined;

  const classes = clsx('payment-process-modal', {
    'payment-process-modal_success': state === StatusModalStates.SUCCESS,
    'payment-process-modal_error': state === StatusModalStates.ERROR,
    'payment-process-modal_loading': state === StatusModalStates.LOADING,
  });

  return (
    <StyledStatusModal
      open={isOpen}
      onClose={close}
      state={state}
      title={getTitle()}
      iconSrc={iconSrc}
      text={text}
      className={classes}
    >
      {state === StatusModalStates.ERROR && (
        <OkButton onClick={close} width={294} label={t('ok')} />
      )}
    </StyledStatusModal>
  );
}

export default ProcessingModal;
