import styled, {css} from 'styled-components';
import {device} from 'styled/device';
import ModalSheet from 'components/common/ModalSheet';
import StandardModalSheet from 'components/common/StandardModalSheet';
import {Button} from '@guestapp/ui';
import {FormFieldWrapper} from 'styled/common';

export const Wrapper = styled.div`
  & .add-cdw-box {
    margin-bottom: 24px;
  }
`;

export const PlanMainInfo = styled.div`
  display: flex;
  position: relative;
`;

export const PlanTitlePrice = styled.div`
  margin-left: 8px;

  @media (min-width: ${device.tablet}) {
    text-align: left;
  }
`;
export const Title = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export const Price = styled.div`
  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  & span:first-child {
    color: #385bf8;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`;

export const PlanFeaturesList = styled.div`
  margin-bottom: 24px;
  margin-top: 24px;
`;

export const Item = styled.div`
  display: flex;
  & div {
    margin: 5px 8px 0 0;
  }
`;

export const Text = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const Box = styled.div`
  padding: 16px;
  border-radius: 5px;
  border: 1px solid #e5e6ee;
  background: ${props => props.theme.colors.branding.backgroundSecondary};
  margin-bottom: 24px;

  @media (min-width: ${device.tablet}) {
    text-align: left;
    max-width: 400px;
  }
`;

export const HeaderText = styled.div`
  color: #152946;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const MoreInformationContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & .more-information-text {
    color: #777e91;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
    margin-left: 4px;
  }
`;

export const ModalWrapper = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 600px;
`;

export const StyledModal = styled(ModalSheet)<{isPremiumPlan?: boolean}>`
  &-content > .modal__title,
  & .modal-sheet__title {
    margin-bottom: ${props => (props.isPremiumPlan ? '33px' : '16px')};

    @media (min-width: ${device.tablet}) {
      margin-top: 30px;
    }
  }

  &-content {
    max-width: 525px;
    overflow-y: hidden;
    padding: 0 !important;
  }

  @media (max-width: ${device.tablet}) {
    > div {
      padding: 0 !important;
    }
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const ModalDetails = styled.div`
  padding: 0 24px;
`;

export const DetailTitle = styled.div.attrs({
  className: 'allianz-plan-modal__details-title',
})<{capitalize?: boolean}>`
  color: #152946;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
  ${props => (props.capitalize ? 'text-transform: capitalize;' : '')}
`;

export const DetailInfo = styled.div.attrs({
  className: 'allianz-plan-modal__details-info',
})<{withoutBottom?: boolean; fontWeight?: number}>`
  color: #777e91;
  font-size: 12px;
  font-style: normal;
  font-weight: ${props => props.fontWeight || 600};
  line-height: 16px;
  margin-bottom: ${props => (props.withoutBottom ? '0' : '8px')};

  & span {
    font-weight: 400;
  }
`;

export const DetailsItem = styled.div.attrs({
  className: 'allianz-plan-modal__details-item',
})`
  @media (min-width: ${device.tablet}) {
    text-align: left;
  }

  & ${DetailInfo} {
    &:last-child {
      padding-bottom: 24px;
      margin-bottom: 0;
    }
  }
`;

export const DetailListInfo = styled.ul.attrs({
  className: 'allianz-plan-modal__details-list',
})`
  list-style: none;
  margin-top: 0;
  padding-left: 7px;

  & li {
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    color: #777e91;
    display: flex;
    &:before {
      content: '•';
      margin-right: 7px;
    }
  }
`;

export const SubHeader = styled.div<{isTravel?: boolean}>`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;

  ${props =>
    props.isTravel &&
    css`
      padding: 0 24px;
    `}
`;

export const Footer = styled.p`
  line-height: 16px;

  @media (min-width: ${device.tablet}) {
    text-align: left;
  }

  & span {
    font-size: 12px;
    color: #777e91;
    font-style: normal;
    &.title {
      font-weight: 600;
    }
  }
`;

export const Hr = styled.div`
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 1px;
  margin-bottom: 16px;
`;

export const GuestItem = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  margin-bottom: 8px;

  & .saved-guest-container {
    display: grid;
    grid-template-columns: 1fr 11fr;
    gap: 6px;
    align-items: center;
  }

  @media (min-width: ${device.tablet}) {
    max-width: 415px;
    margin: 0 auto 8px;
  }
`;

export const GuestAndPlanName = styled.div<{paid: boolean}>`
  & .guest-name {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #152946;
    margin-bottom: 2px;
  }

  & .plan-name {
    display: flex;
    gap: 4px;
    span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: #777e91;
      margin-left: ${props => (!props.paid ? '5px' : '0')};
      max-width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      @media (min-width: ${device.mobileM}) {
        max-width: 210px;
      }
      @media (min-width: ${device.mobileL}) {
        max-width: 270px;
      }
      @media (min-width: ${device.mobileXL}) {
        max-width: 300px;
      }
      @media (min-width: ${device.tablet}) {
        max-width: 285px;
      }
    }
  }
`;

export const StyledStandardModalSheet = styled(StandardModalSheet)`
  width: 100%;
  padding: 0;

  & .plan-main-info {
    margin-bottom: 24px;
  }
`;

export const StyledModalSheet = styled(ModalSheet)`
  &-content {
    min-width: 440px;
    padding: 24px;
    .modal__title {
      @media (min-width: ${device.tablet}) {
        margin-top: 30px;
        width: 315px;
      }
    }
  }
`;

export const ConfirmInsuranceModalContent = styled.div`
  margin-bottom: 48px;
`;

export const FooterContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  padding: 16px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  left: 0;
  z-index: 1;
  background-color: rgb(244, 246, 249);
  & div {
    color: ${props => props.theme.colors.branding.fontSecondary};
    padding: 0 20px;
    width: 50%;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    @media (min-width: ${device.tablet}) {
      width: auto;
      font-weight: 400;
      font-size: 14px;
      font-style: normal;
    }
  }

  @media (min-width: ${device.tablet}) {
    width: 100%;
    justify-content: center;
    padding: 24px 0;
  }
`;

export const ConfirmationTitle = styled.div`
  cursor: pointer;
  color: #152946;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
  & span {
    color: #385bf8;
  }
`;

export const ConfirmationModalCheckBoxes = styled.div`
  border-radius: 16px;
  background: ${props => props.theme.colors.branding.backgroundSecondary};
  padding: 16px;
  margin-bottom: 32px;

  & .check-box {
    align-items: flex-start;
    & .blue-text {
      color: #385bf8;
    }

    & .checkbox__content {
      width: 100%;
    }
  }

  & .optional {
    color: #777e91;
    text-align: right;
    font-size: 10px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 24px;
  }
`;
export const CheckBoxContainer = styled.div`
  display: grid;
  grid-template-columns: 24px auto;
  gap: 10px;
  padding-bottom: 16px;
`;

export const CheckBoxLabelContainer = styled.div`
  text-align: left;
  & .blue-text {
    color: rgb(56, 91, 248);
    cursor: pointer;
  }
  & div {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  & ul {
    color: #152946;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0;
    padding-left: 30px;
  }
`;

export const AllianzPageWrapper = styled.div`
  position: relative;
  @media (min-width: ${device.tablet}) {
    min-height: 100vh;
  }
`;

export const SpainResidenceOnlyModalContent = styled.div`
  text-align: center;
  margin-bottom: 28px;
`;

export const ConfirmCheckboxAndText = styled.p`
  display: flex;
`;

export const ThreeDotsIconContainer = styled.div`
  cursor: pointer;
`;

export const TotalBoxAndButtonContainer = styled.div<{
  disabled?: boolean;
  isLoading?: boolean;
}>`
  margin-bottom: 40px;
  width: 100%;
  transition: opacity 0.3s ease-in-out;
  height: ${props => (!props.isLoading ? 'auto' : '90px')};

  & .confirm-plans-btn {
    max-width: 400px;
    margin: 0 auto;

    @media (min-width: ${device.tablet}) {
      width: 327px;
    }
  }

  @media (min-width: ${device.tablet}) {
    margin: 0 auto;
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const TotalAmountBox = styled.div`
  border-radius: 16px;
  width: 100%;
  background: ${props => props.theme.colors.branding.boxesModals};
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
  position: relative;
  height: inherit;
  min-height: 90px;

  @media (min-width: ${device.tablet}) {
    width: 415px;
    margin: 0 auto 40px;
  }

  & svg {
    width: 50px;
  }
`;

export const InsuranceApplyText = styled.span`
  margin-bottom: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #777e91;
`;

export const ItemPrice = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #152946;
  width: 100%;

  @media (min-width: ${device.tablet}) {
    & .item-price {
      max-width: 315px;
    }
  }
`;

export const StyledButton = styled(Button)`
  @media (min-width: ${device.tablet}) {
    width: 327px;
    margin: 0 auto;
  }
`;

export const EmailContainer = styled.div`
  text-align: left;
`;

export const EmailTextContainer = styled.div`
  margin-bottom: 16px;
  font-size: 12px;
`;

export const StyledFormFieldWrapper = styled(FormFieldWrapper)`
  width: 100%;
  @media (min-width: ${device.tablet}) {
    width: 65%;
  }
`;

export const CWDInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;

export const CWDTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`;
