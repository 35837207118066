import {apiClient, FetcherSettings} from './apiClient';
import {CreateFields, CustomForm} from '../services';

class CustomFormApi {
  readonly ENDPOINTS = {
    customForm: (params = '') => `api/v3/guest-custom-form/form/?${params}`,
    customFormById: (id: string, params = '') =>
      `api/v3/guest-custom-form/form/${id}/?${params}`,
    createFields: (params = '') => `api/v3/guest-custom-form/custom-values/?${params}`,
  };

  async getCustomForm(params: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<{id: string}[]>(
      this.ENDPOINTS.customForm(params),
      fetcherSettings,
    );
    return response.body;
  }

  async getCustomFormById(id: string, params: string, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<CustomForm>(
      this.ENDPOINTS.customFormById(id, params),
      fetcherSettings,
    );
    return response.body;
  }

  async createFields(
    payload: CreateFields,
    params = '',
    fetcherSettings?: FetcherSettings,
  ) {
    const response = await apiClient.post<CustomForm>(
      this.ENDPOINTS.createFields(params),
      {json: payload, ...fetcherSettings},
    );
    return response.body;
  }
}

export {CustomFormApi};
