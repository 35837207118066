import React from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {PATHS} from '../../Routes';
import {IV_DOCUMENT_IMAGE_TYPES} from 'utils/constants';
import {ModalProps} from '../Modal';
import {useFetchIVLottie, useIVComputedDetails} from 'hooks';
import notIncludedIcon from '../../assets/icons/not-included-icon.svg';
import warning from '../../assets/icons/warning-icon.svg';
import Button from '../common/Button';
import {
  Content,
  FailedModalButtonsContainer,
  ModalTwoButtons,
  ReasonItem,
  ReasonsBlock,
  StyledFailedModal,
  StyledLottie,
} from './styled';

const IconProps = {width: 50, height: 50};

type IVProgressModalProps = ModalProps & {
  onTryAgain: () => void;
  reasons?: string[];
  image?: string;
};
function FailedModal({reasons, onTryAgain, image, ...props}: IVProgressModalProps) {
  const {t} = useTranslation();
  const {required} = useIVComputedDetails();
  const {pathname} = useLocation();

  const lottieType = pathname.includes(PATHS.verification.selfie)
    ? IV_DOCUMENT_IMAGE_TYPES.selfie
    : IV_DOCUMENT_IMAGE_TYPES.front;
  const {animationData} = useFetchIVLottie({
    type: lottieType,
    isOrc: false,
  });

  return (
    <StyledFailedModal iconSrc={warning} iconProps={IconProps} {...props}>
      <Content>
        {/* {image && <FailedGifContainer src={image} />} */}
        <StyledLottie animationData={animationData} />
        <FailedModalButtonsContainer>
          <ReasonsBlock>
            <ReasonItem>{t('possible_reasons')}</ReasonItem>
            {reasons?.map(reason => (
              <ReasonItem key={reason}>
                <img src={notIncludedIcon} width={24} height={24} alt="" />
                <p>{reason}</p>
              </ReasonItem>
            ))}
          </ReasonsBlock>
          <ModalTwoButtons>
            <Button label={t('try_again')} onClick={onTryAgain} />
            {!required && (
              <Button
                label={t('verify_later')}
                to={`../../${PATHS.addPersonalDataForm}`}
                link
              />
            )}
          </ModalTwoButtons>
        </FailedModalButtonsContainer>
      </Content>
    </StyledFailedModal>
  );
}

export {FailedModal};
