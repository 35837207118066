import styled from 'styled-components';
import Page from 'components/Page';
import {device} from 'styled/device';

export const StyledPage = styled(Page)`
  padding-top: 74px;
  margin-top: 0;
  @media (min-width: ${device.tablet}) {
    padding: 0px 64px 100px;
    margin: 24px auto 0px;
  }
`;

export const Wrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

export const Separator = styled.div`
  width: 260px;
  height: 0;
  border: 1px solid rgba(0, 0, 0, 0.09);
  transform: rotate(-90deg);
`;
