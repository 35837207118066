import {isMobile, isTablet} from 'react-device-detect';
import {VideoConstraints} from './types';

const isMobileOrTablet = () => isMobile || isTablet;

type GetMobileOrDesktopVideoConstraintsProps = {
  deviceId?: string;
  facingMode?: string;
  additional?: VideoConstraints;
};
function getMobileOrDesktopVideoConstraints({
  deviceId,
  facingMode,
  additional,
}: GetMobileOrDesktopVideoConstraintsProps) {
  const additionalConstraints = typeof additional === 'object' ? additional : {};
  const initialConstraints = {
    deviceId: deviceId ? {exact: deviceId} : undefined,
    facingMode: facingMode || 'user',
    // width: {ideal: 1920},
    // height: {ideal: 1920},
    width: {ideal: 1280},
    height: {ideal: 720},
    ...additionalConstraints,
  };

  if (isMobileOrTablet()) {
    return {
      ...initialConstraints,
      // width: {ideal: 1920},
      // height: {ideal: 1920},
      width: {ideal: 1280},
      height: {ideal: 720},
      aspectRatio: 1,

      facingMode: {
        exact: 'environment',
      },
      ...additionalConstraints,
    };
  }

  return initialConstraints;
}

function isDOMException(error: string | DOMException): error is DOMException {
  return error instanceof DOMException && Boolean(error?.name);
}

type GetPhotoCanvasProps = {
  player?: HTMLVideoElement | null;
  container?: HTMLDivElement | null;
  canvas?: HTMLCanvasElement | null;
};
function getPhotoCanvas({player, container, canvas}: GetPhotoCanvasProps) {
  if (canvas) {
    const playerWidth = player?.videoWidth || 1280;
    const playerHeight = player?.videoHeight || 720;
    const playerAR = playerWidth / playerHeight;

    const canvasWidth = container?.offsetWidth || 1280;
    const canvasHeight = container?.offsetHeight || 1280;
    const canvasAR = canvasWidth / canvasHeight;

    let sX, sY, sW, sH;

    if (playerAR > canvasAR) {
      sH = playerHeight;
      sW = playerHeight * canvasAR;
      sX = (playerWidth - sW) / 2;
      sY = 0;
    } else {
      sW = playerWidth;
      sH = playerWidth / canvasAR;
      sX = 0;
      sY = (playerHeight - sH) / 2;
    }

    canvas.width = sW;
    canvas.height = sH;

    const context = canvas.getContext('2d');
    if (context && player) {
      context.drawImage(player, sX, sY, sW, sH, 0, 0, sW, sH);
    }

    return canvas;
  }
}

export {
  isDOMException,
  getPhotoCanvas,
  isMobileOrTablet,
  getMobileOrDesktopVideoConstraints,
};
