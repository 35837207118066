import React from 'react';
import {useQuery} from '@tanstack/react-query';
import {AllianzPlanFromDb} from '@guestapp/sdk';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {buildPlanData} from './utils';
import {useChekinSDK} from 'context/ChekinSDK';
import {useAllianzPlans} from './useAllianzPlans';
import {useReservation} from 'context/reservation';
import {usePaymentSettings} from 'hooks/usePaymentSettings';

type UseFetchAllianzPlansProps = {
  onSuccess?: (plnaId?: string, marketplaceItem?: string) => void;
  onError?: (e: Error) => void;
};
function useFetchAllianzPlans({onSuccess, onError}: UseFetchAllianzPlansProps = {}) {
  const {data: reservation} = useReservation();
  const {currencySymbol, currency} = usePaymentSettings();
  const {getPlanByUser} = useChekinSDK();
  const [guestId, setGuestId] = React.useState('');
  const {initAllianzPlans} = useAllianzPlans();
  const triggerFetchAllianzPlans = (guestId?: string) => {
    setGuestId(guestId as string);
  };

  const handleOnSuccess = (data: AllianzPlanFromDb) => {
    const initPlans = buildPlanData({plan: data, currencySymbol});
    initAllianzPlans(initPlans);
    onSuccess?.(data?.id, data?.marketplace_item);
    setGuestId('');
  };

  const queryData = useQuery<AllianzPlanFromDb, Error>(
    [QUERY_CACHE_KEYS.allianzPlans],
    () =>
      // getPlanByUser(`currency=${currency?.toUpperCase()}&reservation_id=${reservation?.id}&guest_id=${guestId}`),
      getPlanByUser(
        `currency=${currency?.toUpperCase()}&reservation_id=${
          reservation?.id
        }&guest_id=${guestId}`,
      ), // for dev
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(guestId) && Boolean(reservation?.id),
      refetchOnReconnect: false,
      onSuccess: handleOnSuccess,
      onError,
    },
  );

  return {...queryData, triggerFetchAllianzPlans};
}

export {useFetchAllianzPlans};
