import React from 'react';
import {CustomIcon} from '../CustomIcon';
import {Message, Wrapper} from './styled';

export enum NavigationStatus {
  success = 'success',
  warning = 'warning',
  information = 'info',
}

type BoxInfoProps = {
  text?: string;
  type: NavigationStatus;
};

export function BoxInfo({text = NavigationStatus.information, type}: BoxInfoProps) {
  const iconType = React.useMemo(() => {
    switch (type) {
      case NavigationStatus.information:
        return 'information';
      case NavigationStatus.warning:
        return 'warningSecondary';
      case NavigationStatus.success:
        return 'checkSecondary';
      default:
        return 'information';
    }
  }, [type]);

  return (
    <Wrapper status={type} className="box-info">
      <CustomIcon size={20} name={iconType} className="box-info__icon" />
      <Message className="box-info__text">{text}</Message>
    </Wrapper>
  );
}

// export {BoxInfo};
