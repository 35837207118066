import {useQuery} from '@tanstack/react-query';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {useChekinSDK} from 'context/ChekinSDK';

const CACHE_TIME = 60_000;
const STALE_TIME = 60_000;

function useFetchOffers({housingId}: {housingId?: string}) {
  const {getOffers} = useChekinSDK();
  const result = useQuery(
    [QUERY_CACHE_KEYS.offers, housingId],
    () => getOffers(`housing_id=${housingId}&is_active=true`),
    {
      enabled: Boolean(housingId),
      staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
    },
  );

  return result;
}

export {useFetchOffers};
