import React from 'react';
import fetcher from '../../utils/fetcher';
import {StripeError} from '@stripe/stripe-js';
import {useQueryClient} from '@tanstack/react-query';
import {ReservationPayment} from '@guestapp/sdk';
import {QUERY_CACHE_KEYS} from '../../utils/constants';
import {useIsMounted} from '../common';
import {useAppSelector} from '../redux';
import {getSessionIdSelector} from '../../store/slices/session';
import {useChekinSDK} from '../../context/ChekinSDK';
import {useReservation} from '../../context/reservation';

const getCheckExpiredPaymentsPayload = (reservation_id = '', session_id?: string) => ({
  reservation_id,
  session_id,
});

type UseCheckExpiredPaymentsProps = {
  onExpired?: (payments: ReservationPayment[]) => void;
  onError?: (error: StripeError) => void;
  onSettled?: () => void;
};

type CheckExpiredPaymentsOptions = UseCheckExpiredPaymentsProps;
function useCheckExpiredPayments({
  onExpired,
  onError,
  onSettled,
}: UseCheckExpiredPaymentsProps = {}) {
  const isMounted = useIsMounted();
  const {checkExpiredReservationPayments} = useChekinSDK();
  const {reservationId} = useReservation();
  const sessionId = useAppSelector(getSessionIdSelector);
  const queryClient = useQueryClient();

  const checkExpiredPayments = React.useCallback(
    async ({
      onExpired: onLocalExpired,
      onError: onLocalError,
      onSettled: onLocalSettled,
    }: CheckExpiredPaymentsOptions = {}) => {
      if (!reservationId) return;
      const payload = getCheckExpiredPaymentsPayload(reservationId, sessionId);

      try {
        const data = await fetcher(checkExpiredReservationPayments, payload);

        if (!isMounted.current) return;
        onLocalSettled?.();
        onSettled?.();
        if (data?.has_expired_payments) {
          await queryClient.refetchQueries([QUERY_CACHE_KEYS.paymentsCart]);
          onLocalExpired?.(data?.expired_payments);
          onExpired?.(data?.expired_payments);
          return true;
        }
      } catch (e) {
        const error = e as StripeError;
        if (error) {
          onLocalError?.(error);
          onError?.(error);
          return;
        }
      }

      return false;
    },
    [
      queryClient,
      reservationId,
      sessionId,
      checkExpiredReservationPayments,
      isMounted,
      onSettled,
      onExpired,
      onError,
    ],
  );

  return {checkExpiredPayments};
}

export {useCheckExpiredPayments};
