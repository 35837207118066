import styled, {css} from 'styled-components';
import {device} from 'styled/device';
import {BUTTONS_STYLES_OPTIONS} from 'styled/theme';

type LanguageItemProps = {
  selected: boolean;
};

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0 30px;
  @media (min-width: ${device.tablet}) {
    margin: -4px -2px 0;
    padding: 0;
  }
`;

export const LanguageItemButton = styled.button<LanguageItemProps>`
  border-radius: ${props =>
    props.theme.buttons.typeRadioButton === BUTTONS_STYLES_OPTIONS.round
      ? '50px'
      : '7px'};
  cursor: pointer;
  flex-basis: calc(50% - 4px);
  flex-grow: 0;
  flex-shrink: 0;
  margin: 4px 2px 0;
  padding: 12px 20px;
  transition: background 0.2s;
  width: calc(50% - 4px);

  div {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.colors.branding.fontPrimary};
  }
  ${props =>
    props.selected &&
    css`
      background: ${props => props.theme.colors.branding.backgroundSecondary};

      div {
        font-weight: 700;
      }
    `}
`;

export const Title = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  padding: 0 10px;
  color: ${props => props.theme.colors.branding.fontPrimary};
  cursor: default;
  margin: 0px auto 24px;
  margin-top: 0;
`;
