import {Season} from '@guestapp/sdk';

const AGES_EXEMPTIONS_IDS = {
  under14: 'UNDER14',
  under18: 'UNDER18',
  under7: '1',
  from7to18: '12',
};

class TaxExemptionsEntity {
  list: Season['exemptions'];

  constructor(list: Season['exemptions'] = []) {
    this.list = [...list];
  }

  get ageExemptions() {
    return this.list.filter(exemption =>
      Object.values(AGES_EXEMPTIONS_IDS).includes(exemption.exemption_type),
    );
  }

  public findOne(value?: string | null) {
    return this.list?.find(exemption => exemption.exemption_type === value);
  }

  public exec() {
    return this.list;
  }
}

export default TaxExemptionsEntity;
export {AGES_EXEMPTIONS_IDS};
