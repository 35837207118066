import React from 'react';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {ErrorType} from 'utils/types';
import removeIcon from 'assets/icons/remove.svg';
import paperClipIcon from 'assets/icons/paper-clip.svg';
import {ErrorMessage} from '@guestapp/ui';
import {
  FileInputLabel,
  HiddenFileUploader,
  FileInputBox,
  ClipContainer,
  FileName,
  FileNameContainer,
  ClearIcon,
  ClearButton,
  Placeholder,
} from './styled';

export type FileInputProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement> | string) => void;
  label?: string;
  value?: File | string;
  placeholder?: string;
  accept?: string;
  disabled?: boolean;
  multiple?: boolean;
  invalid?: boolean;
  className?: string;
  error?: ErrorType;
};

const defaultProps: Partial<FileInputProps> = {
  onChange: () => {},
  label: '',
  error: '',
  invalid: false,
  placeholder: '',
  accept: '',
  disabled: false,
  className: undefined,
  multiple: false,
};

const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(
  (
    {
      onChange,
      label,
      accept,
      disabled,
      className,
      multiple,
      placeholder,
      value,
      error,
      invalid,
    }: FileInputProps,
    ref,
  ) => {
    const {t} = useTranslation();
    const handleClear = () => {
      if (onChange) {
        onChange('');
      }
    };

    return (
      <FileInputLabel
        invalid={invalid || Boolean(error)}
        className={clsx(className, 'file-input')}
        disabled={disabled}
      >
        <HiddenFileUploader
          multiple={multiple}
          disabled={disabled}
          type="file"
          name="fileInput"
          accept={accept}
          onChange={onChange}
          ref={ref}
        />
        {/* <Label>{label}</Label> */}
        <FileInputBox isEmpty={!!value} className="file-input__box">
          {value instanceof File && (
            <FileNameContainer onClick={e => e.preventDefault()}>
              <FileName className="file-input__filename">{value.name}</FileName>
              <ClearButton
                disabled={disabled}
                onClick={handleClear}
                className="file-input__clear"
              >
                <ClearIcon src={removeIcon} alt="Remove" />
              </ClearButton>
            </FileNameContainer>
          )}
          {/* {!value && <Placeholder>{placeholder}</Placeholder>} */}
          {
            <Placeholder isEmpty={!!value} className="file-input__placeholder">
              {placeholder}
            </Placeholder>
          }
          <ClipContainer className="file-input__clip">
            <img src={paperClipIcon} alt={t('attach_file')} />
          </ClipContainer>
        </FileInputBox>
        {error && <ErrorMessage>{error as string}</ErrorMessage>}
      </FileInputLabel>
    );
  },
);

FileInput.defaultProps = defaultProps;
export {FileInput};
