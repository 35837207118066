import {useGuestGroup} from 'hooks';
import {useReservation} from 'context/reservation';
import {useSummary} from 'context/summary';
import {COUNTRY_CODES} from 'utils/constants';

export const useFirstGuestMustBeAdult = () => {
  const {guestGroup, isLoading: isLoadingGuestGroup} = useGuestGroup();
  const {housing, isLoading: isLoadingReservation} = useReservation();
  const {countryCode} = useSummary();

  const hasNoGuests = guestGroup?.added_number_of_guests === 0;

  return {
    isLoading: isLoadingReservation || isLoadingGuestGroup,
    firstGuestMustBeAdult:
      hasNoGuests &&
      countryCode === COUNTRY_CODES.uae &&
      housing?.is_auto_police_registration_enabled,
  };
};
