import styled from 'styled-components';
import {device} from '@guestapp/ui/src/utils/device';
import ModalSheet from '../../../components/common/ModalSheet';
import Button from 'components/common/Button/Button';

export const ProtectionModalText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  color: ${props => props.theme.colors.branding.fontPrimary};
`;

export const Content = styled.div`
  @media (max-width: ${device.mobileXL}) {
    text-align: left;
  }
`;

export const StyledModalSheet = styled(ModalSheet)`
  &-content {
    max-width: 750px;
    padding-left: 24px;
    padding-right: 24px;
  }

  &-overlay {
    padding-bottom: 0;
  }
`;

export const WrapperText = styled.div`
  h3 {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    color: ${props => props.theme.colors.branding.fontPrimary};
  }
  ul {
    margin: 5px 0 0;
    font-weight: 400;
    text-align: left;
    padding-left: 20px;
  }
`;

export const SubTitle = styled.div`
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.branding.fontSecondary};
  max-width: 554px;
  margin: 0 auto 16px;

  @media (max-width: ${device.mobileXL}) {
    font-size: 14px;
    line-height: 24px;
    width: auto;
  }

  @media (min-width: ${device.tablet}) {
    margin-bottom: 32px;
    width: auto;
  }
`;

export const ProtectionTermButton = styled(Button)`
  margin: 48px auto 0;
`;

export const StyledDepositModal = styled(ModalSheet)`
  &-content {
    max-width: 375px;
    padding-bottom: 10px;
  }

  &-overlay {
    padding-bottom: 0;
  }
`;
