import React, {PropsWithChildren, useState} from 'react';
import ChekinAPI, {
  AUTH_INIT_MODELS,
  type OldShortLink,
  type ShortLink,
} from '@guestapp/sdk';
import i18n from 'i18next';
import {useQuery} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import ChekinProSettings from '../ChekinProSettings';
import {LocalStorage} from '@guestapp/core';
import {useRedirectToUrlFromShortLink} from '../hooks/useRedirectToUrlFromShortLink';
import {environments} from '../configs';
import {
  MAIN_TOKEN_ID_KEY,
  QUERY_CACHE_KEYS,
  RESERVATION_ID_KEY,
  SHARE_LINK,
  SHORT_LINK_TYPE,
} from '../utils/constants';

// type AuthInitDataType = {
//   object_id: string;
//   link: string;
//   user: string;
//   url_path: string;
//   model_name?: AUTH_INIT_MODELS;
// };

const IS_EMBEDDED_MODE = environments.REACT_APP_IS_EMBEDDED;

const getReservationFromStorage = () => {
  if (IS_EMBEDDED_MODE) {
    const idFromStorage = LocalStorage.get(RESERVATION_ID_KEY) ?? '';
    return window.ChekinProSettings.reservationId ?? idFromStorage;
  } else {
    const currentReservationId = LocalStorage.get(RESERVATION_ID_KEY);
    const storedMainTokenIdKey = LocalStorage.get(MAIN_TOKEN_ID_KEY);
    const [, , , token] = window.location.href.split('/');
    const isIdRelevant = storedMainTokenIdKey === token;

    if (isIdRelevant) {
      return (
        currentReservationId?.toString() ?? process.env.REACT_APP_TEST_RESERVATION_ID
      );
    }
  }
};

function isNewShortLink(data?: OldShortLink | ShortLink): data is ShortLink {
  return Boolean((data as ShortLink)?.instance_id);
}

const checkIsShortLink = <T,>(data: string | T): data is T =>
  Boolean(data && typeof data !== 'string');

const getTemplateId = (data?: string | OldShortLink | ShortLink) => {
  if (checkIsShortLink(data)) {
    return data?.branding_template;
  }
};

type ContextProps = ChekinAPI['reservation'] &
  ChekinAPI['guest'] &
  ChekinAPI['identityVerification'] &
  ChekinAPI['customForm'] &
  ChekinAPI['validations'] &
  ChekinAPI['locations'] &
  ChekinAPI['guestFormAdditionalData'] &
  ChekinAPI['housing'] &
  ChekinAPI['guestGroup'] &
  ChekinAPI['documents'] &
  ChekinAPI['payments'] &
  ChekinAPI['websocket'] &
  ChekinAPI['upselling'] &
  ChekinAPI['propertyProtection'] &
  ChekinAPI['allianzInsurance'] &
  ChekinAPI['locks'] &
  ChekinAPI['theme'] &
  ChekinAPI['taxes'] & {
    isTokenValid: boolean;
    isAuthInitialization: boolean;
    isAuthInitializationError: boolean;
    authError?: string;
    isSuccessSDK: boolean;
    foundReservationId?: string;
    mainToken?: string | null;
    hasToken?: boolean;
    objectId?: string;
    modelName?: string;
    link?: string;
    redirectTo?: string;
    linkType?: SHORT_LINK_TYPE;
    templateId?: string;
  };

const ChekinSDKContext = React.createContext<ContextProps>({} as ContextProps);

function ChekinSDKProvider({children}: PropsWithChildren) {
  const {t} = useTranslation();
  const [foundReservationId, setFoundReservationId] = useState<string | undefined>(
    getReservationFromStorage,
  );

  // chekin-sdk =-------------------------------------------------------------------
  const chekinProApiKey =
    ChekinProSettings.apiKey || process.env.REACT_APP_TEST_API_KEY || '';
  const defaultLanguage = ChekinProSettings.defaultLanguage;

  React.useEffect(() => {
    if (IS_EMBEDDED_MODE) {
      i18n.changeLanguage(defaultLanguage);
    }
  }, [defaultLanguage]);
  // chekin-sdk =-------------------------------------------------------------------

  const [, , , tokenFromUrl] = window.location.href.split('/');
  const chekinSdk = React.useMemo(
    () =>
      new ChekinAPI({
        // For the Embedded mode
        apikey: IS_EMBEDDED_MODE ? chekinProApiKey : '',
        resourceId: ChekinProSettings.reservationId,
        externalResourceId: ChekinProSettings.externalReservationId,
        resourceType: IS_EMBEDDED_MODE ? 'RESERVATION' : undefined,
        // For the Main mode
        isChekinConsumer: !IS_EMBEDDED_MODE,
      }),
    [chekinProApiKey],
  );

  const {initChekin, init} = chekinSdk.auth;
  const {
    data,
    error,
    isLoading: isAuthInitialization,
    isLoadingError: isAuthInitializationError,
    isSuccess: isSuccessSDK,
  } = useQuery<(OldShortLink | ShortLink) | string, Error>(
    [QUERY_CACHE_KEYS.apiClient],
    () => (IS_EMBEDDED_MODE ? init() : initChekin(tokenFromUrl)),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: IS_EMBEDDED_MODE ? Boolean(chekinProApiKey) : Boolean(tokenFromUrl),
      refetchOnReconnect: false,
    },
  );
  const isTokenValid = Boolean(data);
  const templateId = getTemplateId(data);
  React.useEffect(() => {
    if (checkIsShortLink(data)) {
      let reservationId;
      if (isNewShortLink(data)) {
        reservationId = data?.instance_id;
      } else {
        const isHousingLink = data?.model_name === AUTH_INIT_MODELS.housing;
        reservationId = isHousingLink ? undefined : data?.object_id;
      }

      setFoundReservationId(reservationId);
      LocalStorage.set(RESERVATION_ID_KEY, reservationId);
    }
  }, [data]);

  const linkType = checkIsShortLink(data) ? data?.type : undefined;
  useRedirectToUrlFromShortLink({linkType, token: tokenFromUrl});

  const authError = React.useMemo<string | undefined>(() => {
    if (!isAuthInitializationError) return;
    if (IS_EMBEDDED_MODE) {
      if (!error) {
        return t('dns_is_forbidden_error_message');
      }
      return error?.message;
    }
  }, [error, isAuthInitializationError, t]);

  const mainToken = React.useMemo(() => {
    if (IS_EMBEDDED_MODE) {
      LocalStorage.set(SHARE_LINK, window.location.href);
    } else if (tokenFromUrl) {
      LocalStorage.set(MAIN_TOKEN_ID_KEY, tokenFromUrl);
      LocalStorage.set(SHARE_LINK, `${process.env.REACT_APP_PUBLIC_URL}/${tokenFromUrl}`);
    }

    return tokenFromUrl || LocalStorage.get(MAIN_TOKEN_ID_KEY);
  }, [tokenFromUrl]);

  const defaultProps = React.useMemo<ContextProps>(
    () => ({
      ...chekinSdk.identityVerification,
      ...chekinSdk.locations,
      ...chekinSdk.guest,
      ...chekinSdk.reservation,
      ...chekinSdk.taxes,
      ...chekinSdk.guestFormAdditionalData,
      ...chekinSdk.guestGroup,
      ...chekinSdk.customForm,
      ...chekinSdk.housing,
      ...chekinSdk.validations,
      ...chekinSdk.documents,
      ...chekinSdk.payments,
      ...chekinSdk.websocket,
      ...chekinSdk.upselling,
      ...chekinSdk.propertyProtection,
      ...chekinSdk.allianzInsurance,
      ...chekinSdk.locks,
      ...chekinSdk.theme,
      isTokenValid,
      isAuthInitialization,
      isAuthInitializationError,
      authError,
      foundReservationId,
      mainToken,
      isSuccessSDK,
      objectId: checkIsShortLink(data) && !isNewShortLink(data) ? data?.object_id : '',
      modelName: checkIsShortLink(data) && !isNewShortLink(data) ? data?.model_name : '',
      link: checkIsShortLink(data) && !isNewShortLink(data) ? data?.link : '',
      redirectTo: checkIsShortLink(data) && !isNewShortLink(data) ? data?.url_path : '',
      linkType,
      hasToken: Boolean(tokenFromUrl),
      templateId,
    }),
    [
      linkType,
      authError,
      chekinSdk,
      isAuthInitialization,
      isAuthInitializationError,
      isSuccessSDK,
      isTokenValid,
      mainToken,
      foundReservationId,
      data,
      tokenFromUrl,
      templateId,
    ],
  );

  return <ChekinSDKContext.Provider value={defaultProps} children={children} />;
}

function useChekinSDK() {
  const context = React.useContext(ChekinSDKContext);
  if (context === undefined) {
    throw new Error('useReservation must be used within a ChekinSDKProvider');
  }

  return context;
}

export {ChekinSDKContext, ChekinSDKProvider, useChekinSDK};
