import styled, {css} from 'styled-components';
import {device} from 'styled/device';
import {CustomIcon} from '@guestapp/ui';
import {getMainColor} from 'styled/common';

export const Container = styled.div<{isLoading?: boolean}>`
  display: flex;
  overflow: hidden;
  width: 327px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.07);
  padding: 16px 6px 16px 16px;
  background-color: ${props => props.theme.colors.branding.boxesModals};

  ${props =>
    props.isLoading &&
    css`
      cursor: progress;
      opacity: 0.6;
    `};

  @media (max-width: ${device.mobileXL}) {
    width: 100%;
  }
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 190px;
  padding: 0 8px 0 0;
  border-right: 1px solid rgba(0, 0, 0, 0.07);
`;

export const ContentEditButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  padding: 0;
  width: auto;
  height: 24px;
  gap: 4px;
  transition: opacity 0.1s ease-in-out;

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: ${({theme}) =>
      theme.hasTemplate
        ? theme.colors.buttons.textButton
        : theme.colors.buttons.mainButtonBackground};
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const ContentPrice = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 6px;
`;

export const SubContainerPrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  gap: 10px;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.branding.fontPrimary};

  & > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Label = styled.span`
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.branding.fontSecondary};
`;

export const SubTitle = styled.span<{blue: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.branding.backgroundSecondary};
  border-radius: 5px;
  width: max-content;
  height: 30px;
  padding: 8px;
  margin-top: 8px;

  font-weight: 600;
  font-size: 12px;
  color: ${props =>
    props.blue
      ? props.theme.colors.branding.fontSecondary
      : props.theme.colors.branding.icons};
`;

export const EditIcon = styled(CustomIcon)`
  path {
    fill: ${({theme}) =>
      theme.hasTemplate
        ? theme.colors.buttons.textButton
        : theme.colors.buttons.mainButtonBackground};
  }

  rect {
    fill: ${({theme}) =>
      theme.hasTemplate
        ? theme.colors.buttons.textButton
        : theme.colors.buttons.mainButtonBackground};
  }
`;

export const PlusIcon = styled(CustomIcon)`
  path {
    stroke: ${({theme}) => getMainColor(theme)};
  }
`;

export const DotsButton = styled(CustomIcon)`
  display: block;
  position: relative;
  transition: opacity 0.1s ease-in-out;
  cursor: pointer;

  circle {
    fill: ${({theme}) =>
      theme.hasTemplate
        ? theme.colors.branding.primary
        : theme.colors.buttons.mainButtonBackground};
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const Price = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: ${props => props.theme.colors.branding.fontPrimary};
  margin-right: 6px;
`;

export const Fee = styled.div`
  margin-right: 22px;
  display: flex;
  justify-content: end;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: ${props => props.theme.colors.branding.fontSecondary};
`;
