import React from 'react';
import {useTranslation} from 'react-i18next';
import useBuildDisplayedPayments from './useBuildDisplayedOrderHistory';
import {useOrderHistory} from '../../hooks';
import {useOnScreenChanged} from '../../hooks/embedded';
import {checkIsInternetConnectionFast} from '@guestapp/core';
// import emptyStateList from '../../assets/img/empty-state-list.svg';
import {EmptyState} from '../EmptyState/EmptyState';
import {StyledOrderHistoryBox, Wrapper, StyledLoader} from './styled';

function OrderHistory() {
  const {t} = useTranslation();
  const {orderHistory, isOrderHistoryLoading, isOrderHistoryFetching} = useOrderHistory();
  const {orderHistories} = useBuildDisplayedPayments(orderHistory);
  const isEmpty = !isOrderHistoryLoading && !orderHistories?.length;
  useOnScreenChanged({
    type: 'ORDER_HISTORY',
  });

  return (
    <Wrapper
      isLoading={!isOrderHistoryLoading && isOrderHistoryFetching}
      className="order-history"
    >
      <StyledLoader
        visible={isOrderHistoryLoading && !checkIsInternetConnectionFast()}
        width={80}
      />
      {isEmpty && (
        <EmptyState
          imageSrc={'emptyStateList'}
          title={t('no_orders_found')}
          description={t('no_orders_found_description')}
        />
      )}
      {orderHistories?.map(order => {
        return (
          <StyledOrderHistoryBox
            key={order.name}
            date={order.date}
            title={order.title}
            subtitle={order.subtitle}
            dateLabel={order.dateLabel}
            helperText={order.helperText}
            totalLabel={order.totalLabel}
            orderHistoryItems={order.items}
            totalAmount={order.totalAmount}
            cardLastNumbers={order.cardLastNumbers}
          />
        );
      })}
    </Wrapper>
  );
}

export {OrderHistory};
