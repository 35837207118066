import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import {ButtonProps} from 'components/common/Button/Button';
import {ButtonConfirm, Text, Wrapper, BodyImage, StyledLottie} from './styled';

type StandardModalSheetProps = {
  text?: string | JSX.Element | JSX.Element[];
  buttons?: ButtonProps[];
  img?: string;
  lottie?: object | null;
  className?: string;
  content?: JSX.Element | JSX.Element[];
};
function StandardModalSheet({
  text,
  img,
  lottie,
  buttons,
  className,
  content,
}: Readonly<StandardModalSheetProps>) {
  const {t} = useTranslation();

  return (
    <Wrapper className={clsx(className, 'standard-modal-sheet')}>
      {text && <Text className="standard-modal-sheet__text">{text}</Text>}
      {content && content}
      {img && <BodyImage className="standard-modal-sheet__image" src={img} />}
      {lottie && <StyledLottie animationData={lottie} />}
      {buttons
        ? buttons.map((buttonProps, index) => {
            const label =
              typeof buttonProps.label === 'string'
                ? t(buttonProps?.label as string)
                : buttonProps.label;

            return (
              <ButtonConfirm
                className="standard-modal-sheet__button"
                {...buttonProps}
                label={label}
                key={index}
              />
            );
          })
        : null}
    </Wrapper>
  );
}

export {StandardModalSheet};
