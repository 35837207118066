import {useTranslation} from 'react-i18next';
import {getTimeErrorMessage} from './utils';
import {useReservation} from 'context/reservation';
import {useFetchLockConditions} from './useFetchLockConditions';

function useAvailableLocks() {
  const {housing} = useReservation();
  const {t} = useTranslation();

  const {data: lockConditions, isLoading: areLockConditionsLoading} =
    useFetchLockConditions();

  const incompleteConditions =
    lockConditions?.incomplete_conditions.map(condition =>
      t(`remote_access_incomplete_conditions.${condition}`),
    ) || [];

  if (lockConditions?.timing_conditions) {
    incompleteConditions.push(getTimeErrorMessage(lockConditions.timing_conditions, t));
  }

  const areKeysAvailable = Boolean(
    incompleteConditions?.length === 0 && lockConditions && lockConditions?.is_available,
  );

  return {
    incompleteConditions,
    areKeysAvailable,
    areLockConditionsLoading: areLockConditionsLoading || !housing?.id,
    lockConditions,
  };
}

export {useAvailableLocks};
