const environments = {
  REACT_APP_STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY,
  REACT_APP_IS_EMBEDDED:
    process.env.REACT_APP_IS_EMBEDDED && JSON.parse(process.env.REACT_APP_IS_EMBEDDED),
  REACT_APP_DEFAULT_LANGUAGE: process.env.REACT_APP_DEFAULT_LANGUAGE,
};

type EnvsKeys = keyof typeof environments;

function getEnvVariable(key: EnvsKeys) {
  const variable = environments[key];

  if (!variable) {
    throw new Error(`${key} .env variable is missing.`);
  }

  return variable;
}

export {environments, getEnvVariable};
