import React, {ReactElement} from 'react';
import clsx from 'clsx';
import {useScreenResize} from 'hooks';
import {useFrame} from '../../../context/frame';
import {device} from 'styled/device';
import Modal, {ModalProps} from 'components/Modal';
import {Content, ContentSheet, StyledMobileSelectModal, Title} from './styled';

export type ModalSheetProps = ModalProps & {
  isOpenModalSheet: boolean;
  handleCloseModal?: () => void;
  title?: string | ReactElement;
  children?: React.ReactNode;
  padding?: boolean;
  nested?: boolean;
  pressToTheTop?: boolean;
  className?: string;
  modalMobileHeight?: string;
  withModalMobileCloseButton?: boolean;
  closeButtonType?: 'slide' | 'click' | 'none';
};

const ModalSheet = React.memo(
  ({
    isOpenModalSheet,
    handleCloseModal,
    title,
    children,
    maxWidth,
    width,
    nested,
    padding,
    className,
    pressToTheTop,
    modalMobileHeight,
    withModalMobileCloseButton = true,
    withCloseButton,
    closeButtonType = 'slide',
    closeOnDocumentClick,
    ...props
  }: ModalSheetProps) => {
    const {isMatch: isMobileMode} = useScreenResize(device.mobileXL);
    const {document} = useFrame();

    React.useEffect(() => {
      if (isMobileMode) {
        document.body.classList.toggle('disabled-swipe', isOpenModalSheet);
      }

      return () => {
        document.body.classList.remove('disabled-swipe');
      };
    }, [isOpenModalSheet, isMobileMode, document.body.classList]);

    return (
      <>
        {!isMobileMode ? (
          <Modal
            open={isOpenModalSheet}
            onClose={handleCloseModal}
            title={title}
            closeOnDocumentClick={closeOnDocumentClick}
            closeOnEscape
            withCloseButton={withCloseButton}
            maxWidth={maxWidth}
            width={width}
            padding={padding}
            className={className}
            nested={nested}
            {...props}
          >
            <Content className="modal-sheet__content">{children}</Content>
          </Modal>
        ) : (
          <>
            <StyledMobileSelectModal
              visible={isOpenModalSheet}
              onClose={handleCloseModal}
              mode="mobile-slide"
              pressToTheTop={pressToTheTop}
              height={modalMobileHeight}
              withCloseSlideButton={withModalMobileCloseButton}
              closeButtonType={closeButtonType}
              closeOnDocumentClick={closeOnDocumentClick}
              className={clsx(className, 'modal-sheet')}
              {...props}
            >
              <ContentSheet className="modal-sheet__content">
                {title && <Title className="modal-sheet__title">{title}</Title>}
                {children}
              </ContentSheet>
            </StyledMobileSelectModal>
          </>
        )}
      </>
    );
  },
);

export {ModalSheet};
