import {useCallback} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {displayError} from '@guestapp/sdk';
import {blurSelfiePhoto} from 'pages/IdentityVerification/utils';
import {useSummary} from '../../../../context/summary';
import {useAppSelector, useDocumentSelfieDetection} from '../../../../hooks';
import {PATHS} from '../../../../Routes';
import {IV_DOCUMENT_IMAGE_TYPES} from '../../../../utils/constants';
import PhotoBox from '../../../../components/PhotoBox';
import {HelperText} from '../styled';

const videoConstraints = {
  facingMode: 'user',
};

function SelfieScanView() {
  const {uploadType, id} = useParams();
  const {t} = useTranslation();
  const {reservationId, countryCode} = useSummary();

  const isUpload = uploadType === 'upload';
  const {sendSelfieToDetectFace, ProcessModal, RetryModal, ErrorModal, isProcessing} =
    useDocumentSelfieDetection({
      docType: IV_DOCUMENT_IMAGE_TYPES.selfie,
    });
  const frontPhoto = useAppSelector(
    state => state.identityVerification.imagesDetails.front,
  );

  const handleChangePhoto = useCallback(
    async (image: string) => {
      if (countryCode) {
        try {
          const blurredPhoto = await blurSelfiePhoto({
            photo: image,
          });
          if (blurredPhoto) {
            sendSelfieToDetectFace(blurredPhoto, countryCode, reservationId);
          }
        } catch (error) {
          displayError(error as string);
        }
      }
    },
    [countryCode, reservationId, sendSelfieToDetectFace],
  );

  if (!frontPhoto.uploaded) {
    return <Navigate to={`../../${PATHS.verification.main}/${id ?? ''}`} />;
  }

  return (
    <>
      <HelperText className="iv-selfie-view__helper">
        {t('keep_your_face_inside_oval_frame')}
      </HelperText>
      {!isProcessing && (
        <PhotoBox
          mobileHelperTitle={t('selfie')}
          mobileHelperText={t('keep_your_face_inside_oval_frame')}
          onUploadPhoto={handleChangePhoto}
          isUpload={isUpload}
          additionalConstraints={videoConstraints}
          roundedScanArea
        />
      )}
      <ProcessModal />
      <RetryModal />
      <ErrorModal />
    </>
  );
}

export {SelfieScanView};
