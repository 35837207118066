import React from 'react';
// import questionMarkIcon from '../../../assets/icons/question-blue-circle.svg';
import {StyledIcon, TextContainer, Wrapper} from './styled';
import {useTheme} from 'styled-components';
type QuestionMarkAndTextProps = {
  onClick: () => void;
  text?: string;
};

function QuestionMarkAndText({onClick, text}: QuestionMarkAndTextProps) {
  const theme = useTheme();
  return (
    <Wrapper onClick={onClick} className="question-mark-text">
      <StyledIcon
        name="questionMarkIcon"
        size={16}
        color={theme.colors.buttons.textButton}
      />
      {/* <img src={questionMarkIcon} alt="Question mark" /> */}
      <TextContainer>{text ?? ''}</TextContainer>
    </Wrapper>
  );
}

export {QuestionMarkAndText};
