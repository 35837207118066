import {identityVerificationReducer} from './slices/identityVerification/identityVerification.slice';
import {stepperReducer} from './slices/stepper/stepper.slice';
import {photoBoxReducer} from './slices/photoBox/photoBox.slice';
import {sessionReducer} from './slices/session/session.slice';
import {paymentsReducer} from './slices/payments/payments.slice';
import {allianzInsuranceReducer} from './slices/allianzInsurance/allianzInsurance.slice';
import {bookExperienceReducer} from './slices/bookExperience';

const rootReducer = {
  stepper: stepperReducer,
  photoBox: photoBoxReducer,
  identityVerification: identityVerificationReducer,
  session: sessionReducer,
  payments: paymentsReducer,
  alliaznInsurance: allianzInsuranceReducer,
  bookExperience: bookExperienceReducer,
  //   reservationDetails: reservationDetailsReducer,
};

export default rootReducer;
