import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {State} from './session.types';
import {SessionDetails} from '../../../utils/types';

const initialState: State = {
  sessionDetails: null,
  isSessionInitialized: false,
};

const sessionSlice = createSlice({
  name: 'sessionSlice',
  initialState,
  reducers: {
    setIsSessionInitialized(state, action: PayloadAction<boolean>) {
      state.isSessionInitialized = action.payload;
    },
    setSessionDetails(state, action: PayloadAction<SessionDetails | null>) {
      state.sessionDetails = action.payload;
    },
  },
});

export {sessionSlice};
export const sessionActions = sessionSlice.actions;
export const sessionReducer = sessionSlice.reducer;
