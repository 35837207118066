import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  color: ${({theme}) => theme.colors.buttons.mainButtonTextBackground};
  background-color: ${({theme}) => theme.colors.$s2};
  gap: 8px;
  border-radius: 5px;
  align-items: center;
  padding-right: 8px;
  padding-left: 6px;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
`;

export const Text = styled.div`
  text-transform: uppercase;
`;
