import React from 'react';

enum STATUSES {
  loading = 'loading',
  error = 'error',
  success = 'success',
  idle = 'idle',
}

type StatusProps = {
  initStatus?: STATUSES;
  statusTimeout?: number;
  autoReset?: boolean;
};

function useStatus({
  initStatus = STATUSES.idle,
  statusTimeout = 1500,
  autoReset = false,
}: StatusProps = {}) {
  const [status, setStatus] = React.useState<STATUSES>(initStatus);
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();
  const isLoading = status === STATUSES.loading;
  const isIdle = status === STATUSES.idle;
  const isSuccess = status === STATUSES.success;
  const isError = status === STATUSES.error;

  const resetStatus = React.useCallback(() => {
    setStatus(STATUSES.idle);
  }, []);

  const resetStatusAfterTimeout = React.useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      resetStatus();
    }, statusTimeout);
  }, [resetStatus, statusTimeout]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  React.useEffect(() => {
    if (!isIdle && autoReset && !isLoading) {
      resetStatusAfterTimeout();
    }
  }, [autoReset, isIdle, isLoading, resetStatusAfterTimeout, status]);

  return {
    status,
    setStatus,
    isLoading,
    resetStatus,
    isIdle,
    isSuccess,
    isError,
    resetStatusAfterTimeout,
  };
}

export {useStatus, STATUSES};
