import {DefaultTheme} from 'styled-components';

const transformSizePropToSize = (size?: string | number) => {
  if (!size) {
    return;
  } else if (typeof size === 'number') {
    return `${size}px`;
  } else if (size.slice(-2) === 'px') {
    return size;
  } else {
    return `${size}px`;
  }
};

const getBackgroundColor = (
  isEmpty?: boolean,
  isFocused?: boolean,
  theme?: DefaultTheme,
) => {
  if (isEmpty && !isFocused) {
    return theme?.colors?.branding?.backgroundSecondary;
  } else {
    return theme?.colors?.branding?.boxesModals;
  }
};

export {transformSizePropToSize, getBackgroundColor};
