import i18next from 'i18next';
import {LocalStorage} from '@guestapp/core';
import {
  EMAIL_SHARE_LINK,
  SHARE_LINK,
  SMS_SHARE_LINK,
  WHATSAPP_SHARE_LINK,
} from 'utils/constants';

function getEmailSubjectAndBody(housingName = '', shareLink: string | null = '') {
  return {
    subject: i18next.t('complete_online_checkin_email_subject'),
    body: i18next.t(`complete_online_checkin_email_body`, {
      housingName,
      link: shareLink,
    }),
  };
}

function getShareMessage(housingName = '', shareLink: null | string = '') {
  return encodeURI(
    i18next.t('complete_iden_verif_whatsapp', {
      housingName,
      link: shareLink,
    }),
  );
}

const shareByWhatsapp = (housingName: string) => {
  const link = LocalStorage.get<string>(SHARE_LINK);
  window.open(`${WHATSAPP_SHARE_LINK}?text=${getShareMessage(housingName, link)}`);
};

const shareBySMS = (housingName: string) => {
  const link = LocalStorage.get<string>(SHARE_LINK);
  window.open(`${SMS_SHARE_LINK};?&body=${getShareMessage(housingName, link)}`);
};

const shareByEmail = (housingName: string) => {
  const link = LocalStorage.get<string>(SHARE_LINK);
  const email = getEmailSubjectAndBody(housingName, link);
  window.open(`${EMAIL_SHARE_LINK}?subject=${email.subject}&body=${email.body}`);
};

export {shareByWhatsapp, shareBySMS, shareByEmail};
