import {useTranslation} from 'react-i18next';
import {Controller, useFormContext} from 'react-hook-form';
import {GROUP_TYPES} from '@guestapp/sdk';
import {FORM_NAMES} from '../constants';
import {getReservationTypeOptions} from '../utils';
import MultiRadio from 'components/common/MultiRadio';
import {ButtonConfirm, RadiosContainer} from '../styled';
import {FormTypes} from '../types';

type ReservationTypeSectionProps = {
  groupType: GROUP_TYPES;
  onSubmit: () => void;
  disabled?: boolean;
};

function ReservationTypeForm({
  groupType,
  onSubmit,
  disabled,
}: ReservationTypeSectionProps) {
  const {t} = useTranslation();
  const {control, handleSubmit} = useFormContext<FormTypes>();
  const reservationTypeOptions = getReservationTypeOptions(t);
  const defaultGroupType = reservationTypeOptions.find(
    option => option.value === groupType,
  );
  const defaultOption =
    groupType !== GROUP_TYPES.single ? defaultGroupType : reservationTypeOptions[0];

  return (
    <>
      <RadiosContainer>
        <Controller
          name={FORM_NAMES.group_type}
          control={control}
          defaultValue={defaultOption}
          render={({field}) => (
            <MultiRadio disabled={false} options={reservationTypeOptions} {...field} />
          )}
        />
      </RadiosContainer>
      <ButtonConfirm
        primary
        label={t('confirm')}
        disabled={disabled}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
}

export {ReservationTypeForm};
