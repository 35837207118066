import clsx from 'clsx';
import Page, {PageProps} from 'components/Page';
import {Footer} from './Footer';
import {AllianzPageWrapper} from './styled';

function AllianzPage({className, ...props}: PageProps) {
  return (
    <AllianzPageWrapper className="allianz-page">
      <Page {...props} className={clsx(className, 'allianz-page__content')}>
        {props.children}
      </Page>
      <Footer />
    </AllianzPageWrapper>
  );
}

export {AllianzPage};
