import {CreateGuestPayload, PAYLOAD_FIELDS_NAMES} from '../services';

function getHasSomeCustomFields(formData: CreateGuestPayload) {
  const formNames = Object.values(PAYLOAD_FIELDS_NAMES);
  const formDataNames = Object.keys(formData);

  return formDataNames
    .filter(name => {
      return !formNames.includes(name as PAYLOAD_FIELDS_NAMES);
    })
    .some(name => {
      return Boolean(formData[name as PAYLOAD_FIELDS_NAMES]);
    });
}

export {getHasSomeCustomFields};
