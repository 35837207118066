import styled from 'styled-components';
import {device} from '@guestapp/ui/src/utils/device';

type WrapperProps = {
  hasSubheader?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 24px auto 0;
  position: relative;
  padding: 0 20px 65px;
  min-height: ${props =>
    props.hasSubheader ? 'calc(100vh - 166px)' : 'calc(100vh - 64px)'};
  z-index: 1;
  align-items: center;

  @media (min-width: ${device.tablet}) {
    padding: 0 64px 100px;
    height: auto;
    min-height: auto;
  }
`;

export const PageWrapper = styled.div`
  width: 100%;
  height: 100dvh;
  overflow-y: auto;
  position: relative;
`;

export const PageBanner = styled.img`
  width: 100%;
  margin-top: -24px;
  cursor: pointer;

  @media (min-width: ${device.tablet}) {
    width: 416px;
    display: block;
    margin: 0 auto 9px;
  }
`;

export const BottomStickyPanel = styled.div`
  position: sticky;
  z-index: 1;
  bottom: 0;
  width: 100%;
`;
