const TOKEN_STORAGE_KEY = 'chekin-token';

const NONE_EXEMPTION = 'NONE';
const LS_I18NEXT_LNG_KEY = 'i18nextLng';

/**
 * @internal
 */
enum GUEST_STATUSES {
  incomplete = 'INCOMPLETE',
  error = 'ERROR',
  new = 'NEW',
  complete = 'COMPLETED',
  verification_pending = 'VERIFICATION_PENDING',
}

/**
 * @internal
 */
const enum FIELD_TYPES {
  text = 'TEXT',
  date = 'DATE',
  file = 'FILE',
  time = 'TIME',
  select = 'SELECT',
  phone = 'PHONE',
}

const DATE_FORMAT = 'YYYY-MM-DD';

const ORIGINS = {
  guestApp: 'GUEST_APP',
  guestAppV2: 'GUEST_APP_V2',
  embeddedGuestApp: 'EMBEDDED_GUEST_APP',
  avantio: 'AVANTIO',
};

const enum STANDARD_FIELD_TYPES {
  text = 'Text',
  date = 'Date',
  file = 'File',
  time = 'Time',
  select = 'Select',
  phone = 'Phone',
  datePicker = 'DatePicker',
  signature = 'Signature',
  number = 'Number',
  hidden = 'hidden',
}

enum PAYMENT_PROVIDERS {
  paycomet = 'PAYCOMET',
  stripe = 'STRIPE',
}

enum PAYMENTS_SETTINGS_STATUSES {
  new = 'NEW',
  validating = 'VALIDATING',
  valid = 'VALID',
  invalid = 'INVALID',
}

enum CURRENCIES {
  eur = 'eur',
  usd = 'usd',
  gbp = 'gbp',
  aed = 'aed',
  czk = 'czk',
  chf = 'chf',
}

enum RESERVATION_PAYMENT_TYPES {
  upselling = 'UPSELLING',
  touristTaxes = 'TOURIST_TAXES',
  booking = 'BOOKING',
  pmsIntegration = 'PMS_INTEGRATION',
  extraService = 'EXTRA_SERVICE',
  damageProtection = 'DAMAGE_PROTECTION',
  securityDeposit = 'SECURITY_DEPOSIT',
  upsellingThirdParty = 'UPSELLING_3D_PARTY',
}

enum MULTI_PAYMENT_STEP_TYPES {
  paymentSetupConfirmation = 'PAYMENT_SETUP_CONFIRMATION',
  paymentSetupInit = 'PAYMENT_SETUP_INIT',
  taxesSetup = 'TAXES_SETUP',
}

enum MULTI_PAYMENT_STEP_STATUSES {
  inProgress = 'IN_PROGRESS',
  dataRequired = 'DATA_REQUIRED',
  error = 'ERROR',
  complete = 'COMPLETE',
  skipped = 'SKIPPED',
}

enum MULTI_PAYMENT_STATUSES {
  new = 'NEW',
  inProgress = 'IN_PROGRESS',
  failed = 'FAILED',
  complete = 'COMPLETE',
}

const WS_EVENT_TYPES = {
  multiPaymentUpdated: 'MULTI_PAYMENT_UPDATED',
};

enum DAY_NAMES {
  monday = 'MON',
  tuesday = 'TUE',
  wednesday = 'WED',
  thursday = 'THU',
  friday = 'FRI',
  saturday = 'SAT',
  sunday = 'SUN',
}

enum PAYMENT_METHOD_TYPES {
  payOnline = 'PAY_ONLINE',
  payLater = 'PAY_LATER',
  payOnArrival = 'PAY_ON_ARRIVAL',
}

enum SUMMARY_PAYMENTS_STATUSES {
  configurationPending = 'CONFIGURATION_PENDING',
  readyForPayment = 'READY_FOR_PAYMENT',
  payOnArrival = 'PAY_ON_ARRIVAL',
  complete = 'COMPLETE',
  inactive = 'INACTIVE',
}

const POLICE_CODES = {
  nationalPolice: 'POL',
  civilGuard: 'NAT',
  ert: 'ERT',
  mos: 'MOS',
  uhh: 'UHH', // Disabled police
  uhh2: 'UHH2',
  feratel: 'FER',
  fake: 'FAKE',
  hospedajes: 'HOS',
};

enum SHORT_LINK_INSTANCE {
  unlockLink = 'UNLOCKLINK',
  reservation = 'RESERVATION',
  housing = 'HOUSING',
}

enum SHORT_LINK_TYPE {
  main = 'RESERVATION_SIGN_UP_FORM',
  payment = 'RESERVATION_PAYMENT',
  upselling = 'RESERVATION_UPSELLING',
  lock = 'RESERVATION_LOCK',
}

enum GROUP_TYPES {
  single = 'S',
  group = 'G',
  family = 'F',
  tourist = 'T',
}

enum LOCK_ACCESS_TYPES {
  private = 'PRIVATE',
  common = 'COMMON',
}

enum AUTH_INIT_MODELS {
  housing = 'Housing',
  reservation = 'Reservation',
}

enum INCOMPLETE_CONDITIONS {
  afterAllPaymentsComplete = 'is_smart_lock_email_after_all_payments_complete',
  afterAllGuestsPassedBiomatchEnabled = 'is_smart_lock_email_after_all_guests_passed_biomatch_enabled',
  afterLeadGuestPassedBiomatchEnabled = 'is_smart_lock_email_after_lead_guest_passed_biomatch_enabled',
  afterLeadGuestRegisteredEnabled = 'is_smart_lock_email_after_lead_guest_registered_enabled',
  allPaymentAndAllGuestsRegistered = 'is_smart_lock_email_all_payment_and_all_guests_registered',
  allPaymentAndAllGuestsIdentified = 'is_smart_lock_email_all_payment_and_all_guests_identified',
  allPaymentAndLeadGuestsIdentified = 'is_smart_lock_email_all_payment_and_lead_guests_identified',
  afterAllGuestsRegisteredEnabled = 'is_smart_lock_email_after_all_guests_registered_enabled',
}

export {
  ORIGINS,
  TOKEN_STORAGE_KEY,
  LS_I18NEXT_LNG_KEY,
  DATE_FORMAT,
  GUEST_STATUSES,
  FIELD_TYPES,
  STANDARD_FIELD_TYPES,
  PAYMENT_PROVIDERS,
  PAYMENTS_SETTINGS_STATUSES,
  CURRENCIES,
  RESERVATION_PAYMENT_TYPES,
  MULTI_PAYMENT_STEP_TYPES,
  MULTI_PAYMENT_STEP_STATUSES,
  WS_EVENT_TYPES,
  MULTI_PAYMENT_STATUSES,
  DAY_NAMES,
  PAYMENT_METHOD_TYPES,
  SUMMARY_PAYMENTS_STATUSES,
  NONE_EXEMPTION,
  POLICE_CODES,
  SHORT_LINK_INSTANCE,
  SHORT_LINK_TYPE,
  GROUP_TYPES,
  LOCK_ACCESS_TYPES,
  AUTH_INIT_MODELS,
  INCOMPLETE_CONDITIONS,
};
