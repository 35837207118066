import styled, {css} from 'styled-components';
import {Icon} from '../../Icon';
import {selectWidth} from '../styled';

type WrapperProps = {
  disabled?: boolean;
  invalid?: boolean;
  hasValue?: boolean;
  isFocused?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  box-sizing: border-box;
  grid-template-rows: auto 50px auto;
  cursor: pointer;
  width: 100%;

  & .select__control {
    grid-row: 2 / 3;
    min-height: 20px;
    cursor: pointer;
    ${props => {
      if (!props.hasValue && props.isFocused) {
        return css`
          background: ${props.theme.colors.branding.boxesModals};
        `;
      }
      if (props.hasValue || props.isFocused) {
        return css`
          background: ${props.theme.hasTemplate
            ? props.theme.colors.branding.boxesModals
            : props.theme.colors.$p6};
        `;
      } else {
        return css`
          background: ${props => props.theme.colors.branding.backgroundSecondary};
        `;
      }
    }}
    box-sizing: border-box;
    box-shadow: none;
    border-radius: 7px;
    transition: background-color 0.5s ease-in-out, border-color 0.05s ease-in-out;
    height: 48px;
    border: none;
  }

  & .select__value-container {
    padding: 0 9px;
  }

  & .select__placeholder {
    font-weight: 400;
    white-space: nowrap;
  }

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `};
`;

type DisplayIconProps = {
  shouldRotate: boolean;
};

export const DisplayIcon = styled.div<DisplayIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 9px;
  pointer-events: none;
  position: relative;
  user-select: none;
  min-width: 32px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: transform 0.2s;

  & svg {
    transition: transform 0.2s;
  }

  ${props =>
    props.shouldRotate &&
    css`
      & svg {
        transform: rotate(180deg);
      }
    `};
`;

export const CustomIcon = styled(DisplayIcon)`
  box-shadow: initial;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  width: auto;
  top: 15px;
  right: 14px;
`;

export const Error = styled.div<{textAlign?: 'left' | 'right'}>`
  font-family: inherit;
  text-align: ${props => props.textAlign || 'right'};
  color: ${({theme}) => theme.colors.$s2};
  font-size: ${props => props.theme.fonts.sizes.smallX};

  position: absolute;
  right: 0;
  bottom: -20px;
  grid-row: 3 / 4;
`;

export const MenuItemLoadingMessage = styled.div`
  display: flex;
  align-items: center;
  width: ${selectWidth};
  padding: 28px;
  color: ${({theme}) => theme.colors.$n1};
`;

export const SearchIcon = styled(Icon)`
  margin-left: 8px;
`;
