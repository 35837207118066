import styled, {css} from 'styled-components';

export const Content = styled.div<{
  $expanded: boolean;
  $duration: number;
  $transitionFinished: boolean;
  height?: number;
}>`
  //height: 0;
  display: grid;
  overflow: hidden;
  opacity: 0;
  grid-template-rows: 0fr;
  transition: grid-template-rows ${props => props.$duration}ms,
    opacity ${props => props.$duration + 150}ms,
    margin-top ${props => props.$duration + 100}ms,
    padding-top ${props => props.$duration + 100}ms;
  will-change: grid-template-rows, margin-top, padding-top;

  & > div {
    min-height: 0;
  }

  ${props =>
    props.$expanded &&
    css`
      grid-template-rows: 1fr;
      //height: ${props.height}px;
      opacity: 1;
    `};

  ${props =>
    props.$transitionFinished &&
    css`
      overflow: visible;
    `};

  ${props =>
    !props.$expanded &&
    css`
      margin-top: 0 !important;
      padding-top: 0 !important;
    `};
`;
