import {device} from '@guestapp/ui/src/utils/device';
import styled from 'styled-components';

export const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export const LoadingText = styled.span`
  font-style: normal;
  font-weight: ${props => props.theme.fonts.fontWeight.mediumL};
  font-size: ${props => props.theme.fonts.sizes.large};
  line-height: 24px;
  text-align: center;
  -webkit-letter-spacing: 0.36px;
  -moz-letter-spacing: 0.36px;
  -ms-letter-spacing: 0.36px;
  letter-spacing: 0.36px;
  color: #152946;
`;

export const LoadingWrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  @media (min-width: ${device.mobileXL}) {
    height: 100%;
  }
`;
