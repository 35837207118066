import styled, {css} from 'styled-components';
import Lottie from 'lottie-react';
import {device} from '../../styled/device';
import {EMBEDDED_MODE} from '../../utils/embedded';
import {transformSizePropToSize} from '../../utils/common';
import Popup from '../common/Popup';

type ModalProps = {
  zIndex?: number | string;
  maxWidth?: number | string;
  width?: number | string;
};

export const ModalStyled = styled(Popup)<ModalProps>`
  @keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }

  @keyframes overlay {
    0% {
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }

  &-overlay {
    z-index: ${props => props.zIndex || 999};
    animation: overlay 0.3s forwards;
    -webkit-animation: overlay 0.3s forwards;
    background: rgba(16, 16, 16, 0.3);

    @media only screen and (min-width: ${device.mobileXL}) and (max-height: 601px) {
      overflow: scroll;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    overflow-y: auto;
    min-width: auto;
    width: 100%;
    height: 100%;

    padding: 40px 24px;
    border: none;
    text-align: center;
    background: ${props =>
      props.theme.hasTemplate
        ? props.theme.colors.branding.boxesModals
        : props.theme.colors.$p5};
    animation: anvil 0.3s forwards;
    -webkit-animation: anvil 0.3s forwards;

    & .modal__close-btn {
      & path {
        stroke: ${props => props.theme.colors.branding.fontPrimary};
      }
    }
    @media (min-width: ${device.mobileXL}) {
      height: auto;
      width: ${props => transformSizePropToSize(props.width) || 'auto'};
      max-width: ${props => transformSizePropToSize(props.maxWidth) || '500px'};
      min-width: 306px;
      border-radius: 16px;
      padding: 48px 24px;
    }

    ${() => {
      if (window.ChekinProSettings?.mode !== EMBEDDED_MODE.onlyGuestForm) {
        return css`
          @media (min-width: ${device.tablet}) {
            left: 125px;
          }
        `;
      }
    }};

    @media only screen and (min-width: ${device.mobileXL}) and (max-height: 601px) {
      top: 6%;
      max-width: ${props => props.maxWidth || '530px'};
    }
  }
`;

export const IconWrapper = styled.div`
  text-align: center;
  user-select: none;
  display: flex;
  margin: 0 auto;
`;

export const Title = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  padding: 0 10px;
  color: ${props => props.theme.colors.branding.fontPrimary};
  cursor: default;
  margin: 0 auto 0;
  font-family: ${props => props.theme.fonts.fontFamily.titles.font_name};
`;

export const Text = styled.div`
  font-weight: 400;
  color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.fontPrimary
      : props.theme.colors.$p10};
  font-size: 16px;
  word-break: break-word;
  margin: 0 auto 0;
  box-sizing: border-box;
  text-align: center;
  cursor: default;

  & > b {
    font-weight: 400;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  top: 16px;
  right: 16px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    opacity: 0.7;
  }
`;

export const StyledLottie = styled(Lottie)`
  width: 149px;
  height: 149px;
  /* margin-bottom: -20px; */
`;
