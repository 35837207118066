import styled from 'styled-components';
import {device} from '../../../styled/device';

export const BarLineContainer = styled.div`
  display: block;
  max-width: 295px;
  margin: 16px 24px 32px;

  @media (min-width: ${device.mobileL}) {
    margin: 24px auto 45px;
    max-width: initial;
  }
`;

export const StepsText = styled.div`
  font-style: normal;
  font-weight: ${props => props.theme.fonts.fontWeight.mediumL};
  font-size: ${props => props.theme.fonts.sizes.small};
  line-height: 24px;
  color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.fontPrimary
      : props.theme.colors.$p10};
  position: absolute;
  transform: translateY(-100%);
`;

export const SelectDocumentText = styled.div`
  font-style: normal;
  font-weight: ${props => props.theme.fonts.fontWeight.bold};
  font-size: ${props => props.theme.fonts.sizes.small};
  line-height: 24px;
  color: ${props => props.theme.colors.$p10};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  position: relative;
  img {
    position: absolute;
    left: -32px;
    top: -5px;
    cursor: pointer;
    padding: 10px;
  }

  @media (min-width: ${device.tablet}) {
    font-size: ${props => props.theme.fonts.sizes.medium};
  }
`;

export const ContentIcon = styled.div`
  position: absolute;
  left: -24px;
  top: 5px;
`;

export const BarLine = styled.div`
  display: flex;
  margin: 10px auto 0;
`;

export const StepperHeader = styled.div`
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;

export const SkipText = styled.div`
  color: ${props =>
    props.theme.hasTemplate ? props.theme.colors.buttons.textButton : '#385BF8'};
  font-size: ${props => props.theme.fonts.sizes.small};
  font-weight: ${props => props.theme.fonts.fontWeight.mediumL};
  cursor: pointer;
`;
