import React from 'react';
import {useModalControls} from '../../../hooks';
import {DropdownProps} from './Dropdown';
import ModalSheet from '../ModalSheet';
import {Option} from './styled';

type MobileDropdownProps = DropdownProps;
function MobileDropdown({trigger, options, disabled, children}: MobileDropdownProps) {
  const {isOpen, openModal, closeModal} = useModalControls();

  const renderTrigger = () => {
    if (typeof trigger === 'function') {
      const comp = trigger(isOpen);
      return !!trigger && React.cloneElement(comp);
    }

    return !!trigger && React.cloneElement(trigger);
  };

  const handleClick = React.useCallback(
    (onClick: () => void) => () => {
      if (disabled) return;
      onClick();
      closeModal();
    },
    [closeModal, disabled],
  );

  const handleClickOpen = React.useCallback(() => {
    if (disabled) return;
    openModal();
  }, [openModal, disabled]);

  return (
    <>
      <div onClick={handleClickOpen} className="dropdown__mobile-trigger">
        {renderTrigger()}
      </div>
      <ModalSheet isOpenModalSheet={isOpen} handleCloseModal={closeModal}>
        {options?.map(option => {
          if (option.hidden) return null;
          return (
            <Option key={option.id} onClick={handleClick(option.onClick)}>
              {option.label}
            </Option>
          );
        })}
        {children}
      </ModalSheet>
    </>
  );
}

export {MobileDropdown};
