import React from 'react';
import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {device} from 'styled/device';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAppSelector, useIVComputedDetails, useScreenResize} from '../../../hooks';
import {getDocumentType} from '../../../store/slices/identityVerification';
import {IVSteps} from 'utils/types';
import {PATHS} from 'Routes';
import CustomIcon from '../CustomIcon';
import {SkipIVModal, SkipIVModalRef} from '../SkipIVModal/SkipIVModal';
import {
  BarLine,
  BarLineContainer,
  ContentIcon,
  SelectDocumentText,
  SkipText,
  StepperHeader,
  StepsText,
} from './styled';

export type IVStepperProps = {
  onClickBackMobile?: () => void;
};

function IVStepper({onClickBackMobile}: IVStepperProps) {
  const {t} = useTranslation();
  const [step, setStep] = React.useState(1);
  const {isMatch: isMobileMode} = useScreenResize(device.mobileXL);
  const location = useLocation();
  const {required, biomatch, ocr} = useIVComputedDetails();
  const theme = useTheme();

  const navigate = useNavigate();

  const documentType = useAppSelector(getDocumentType);

  const [currentStep, setCurrentStep] = React.useState(IVSteps.select);

  const steps = React.useMemo(() => {
    const isTwoSidesDocument = !!documentType?.two_sides;
    const orcSteps = !isTwoSidesDocument ? 1 : 2;
    const biomatchSteps = !isTwoSidesDocument ? 2 : 3;
    if (ocr) {
      return orcSteps;
    }
    if (biomatch) {
      return biomatchSteps;
    }
    return 2; //This is a fallback, in fact, we should never reach this point since if we are in iv we have to have either ocr or biomatch enabled;
  }, [biomatch, ocr, documentType?.two_sides]);

  React.useEffect(() => {
    const isSelfie = location.pathname.includes('selfie');
    const isFront = location.pathname.includes('front');
    const isBack = location.pathname.includes('back');
    if (isFront || isBack) {
      setCurrentStep(IVSteps.upload);
    } else if (isSelfie) {
      setCurrentStep(IVSteps.selfie);
    } else {
      setCurrentStep(IVSteps.select);
    }
  }, [location]);

  const isFrontSide = React.useMemo(() => {
    return location.pathname.includes(PATHS.verification.front);
  }, [location.pathname]);

  const isBackSide = React.useMemo(() => {
    return location.pathname.includes(PATHS.verification.back);
  }, [location.pathname]);

  const isDocumentSelection = React.useMemo(() => {
    return location.pathname.includes(PATHS.verification.documentSelection);
  }, [location.pathname]);

  const isSelfie = React.useMemo(() => {
    return location.pathname.includes(PATHS.verification.selfie);
  }, [location.pathname]);

  React.useEffect(() => {
    const step = isFrontSide ? 1 : isBackSide ? 2 : isSelfie ? steps : 0;
    setStep(step);
  }, [isFrontSide, isBackSide, setStep, isSelfie, steps]);

  const skipIVModalRef = React.useRef<SkipIVModalRef>(null);

  const currentStepText = React.useMemo(() => {
    switch (currentStep) {
      case IVSteps.selfie:
        return t('take_selfie');
      case IVSteps.upload:
        return t('scan_your_document');
      case IVSteps.select:
      default:
        return t('select_your_document');
    }
  }, [t, currentStep]);

  const showSteps = currentStep === IVSteps.upload || currentStep === IVSteps.selfie;
  const openSkipModal = () => skipIVModalRef.current?.open();

  const bar = React.useMemo(() => {
    const width = isMobileMode ? 100 : 520;
    const unit = isMobileMode ? '%' : 'px';
    const height = isMobileMode ? 3 : 8;
    // From 0% to 100%
    const selectedWidth = Number(width) / steps;
    const widthWithUnit = `${width}${unit}`;
    const selectWidthWithUnit = `${selectedWidth * step}${unit}`;

    return (
      <svg width={`${widthWithUnit}`} height={height} fill="none">
        <g width={`${widthWithUnit}`} clipPath="url(#clip0_6787_913)">
          <rect
            width={`${widthWithUnit}`}
            height={height}
            rx="8"
            fill={'rgba(0, 0, 0, 0.10)'}
          />
          <rect
            width={`${selectWidthWithUnit}`}
            height={height}
            rx="8"
            fill={
              theme.hasTemplate
                ? theme.colors.branding.primary
                : theme.colors.buttons.mainButtonBackground
            }
          />
        </g>
        <defs>
          <clipPath id="clip0_6787_913">
            <rect
              width={`${widthWithUnit}`}
              height={`${height}px`}
              rx="1.5"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }, [steps, isMobileMode, step, theme]);

  const handleGoBack = () => {
    return isDocumentSelection ? onClickBackMobile : navigate(-1);
  };
  return (
    <>
      <BarLineContainer className="iv-stepper">
        {showSteps && (
          <StepsText className="iv-stepper__step-text">
            {t('step_of_steps', {step, steps})}
          </StepsText>
        )}

        <StepperHeader className="iv-stepper__header">
          <SelectDocumentText onClick={onClickBackMobile}>
            {isMobileMode && (
              <ContentIcon onClick={handleGoBack}>
                <CustomIcon
                  name="backBlueArrow"
                  color={theme.colors.branding.primary}
                  size={16}
                />
              </ContentIcon>
            )}
            {currentStepText}
          </SelectDocumentText>
          <SkipText onClick={openSkipModal} className="iv-stepper__skip-btn">{`(${t(
            'skip',
          )})`}</SkipText>
        </StepperHeader>
        <BarLine className="iv-stepper__bar">{bar}</BarLine>
      </BarLineContainer>
      <SkipIVModal ref={skipIVModalRef} required={required} />
    </>
  );
}

export {IVStepper};
