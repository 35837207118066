import {useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Controller, useFormContext} from 'react-hook-form';
import {FormTypes} from '../types';
import {FORM_NAMES} from '../constants';
import {GuestGroup} from '@guestapp/sdk';
import {useReservation} from 'context/reservation';
import {getFields, getLabels} from '../utils';
import {useTaxes} from '../../../hooks';
import CounterStepper from 'components/common/CounterStepper';
import {ButtonConfirm} from '../styled';

type NumberOfGuestsFormProps = {
  isLoading: boolean;
  ageLimit?: number | null;
  guestGroup?: GuestGroup;
  disabled?: boolean;
  onSubmit: () => void;
  canEdit?: boolean | null;
};

function NumberOfGuestsForm({
  ageLimit,
  guestGroup,
  isLoading,
  disabled,
  onSubmit,
  canEdit,
}: NumberOfGuestsFormProps) {
  const {t} = useTranslation();
  const {seasonEntity, isFetching: isTaxesFetching} = useTaxes();
  const {control, handleSubmit, watch, reset} = useFormContext<FormTypes>();
  const {allowEditingGuestNumber} = useReservation();
  const [numberOfGuests, setNumberOfGuests] = useState(0);
  const totalJuniors = watch(FORM_NAMES.junior);
  const totalMiddle = watch(FORM_NAMES.middle);
  const totalSeniors = watch(FORM_NAMES.senior);
  const visibleFields = getFields({
    lessThanRule: seasonEntity.lessThanRule,
    betweenRule: seasonEntity.betweenRule,
    moreThanRule: seasonEntity.moreThanRule,
  });

  const labels = getLabels({
    lessThanRule: seasonEntity.lessThanRule,
    betweenRule: seasonEntity.betweenRule,
    moreThanRule: seasonEntity.moreThanRule,
    visibleFields,
    t,
  });

  useEffect(
    function preload() {
      if (typeof guestGroup?.known_number_of_guests !== 'number') return;
      const isNotDefinedAnyGroup =
        !guestGroup?.children && !guestGroup?.adults && !guestGroup?.seniors;

      if (isNotDefinedAnyGroup) {
        reset({
          [FORM_NAMES.numberOfGuests]: guestGroup?.known_number_of_guests ?? 0,
          [FORM_NAMES.junior]: 0,
          [FORM_NAMES.middle]: guestGroup?.known_number_of_guests ?? 0,
          [FORM_NAMES.senior]: 0,
        });
        return;
      }

      reset({
        [FORM_NAMES.numberOfGuests]: guestGroup?.known_number_of_guests ?? 0,
        [FORM_NAMES.junior]: guestGroup?.children ?? 0,
        [FORM_NAMES.middle]: guestGroup?.adults ?? 0,
        [FORM_NAMES.senior]: guestGroup?.seniors ?? 0,
      });
    },
    [
      guestGroup?.adults,
      guestGroup?.seniors,
      guestGroup?.children,
      guestGroup?.known_number_of_guests,
      reset,
    ],
  );

  useEffect(
    function updateNumberOfGuest() {
      setNumberOfGuests(totalJuniors + totalMiddle + totalSeniors);
    },
    [totalJuniors, totalMiddle, totalSeniors],
  );

  const isDisabled = disabled || isLoading;

  const isLimitOfGuestsExceeded =
    !allowEditingGuestNumber &&
    !!seasonEntity &&
    Boolean((guestGroup?.known_number_of_guests ?? 0) < numberOfGuests);

  return (
    <>
      {visibleFields[FORM_NAMES.numberOfGuests] && (
        <Controller
          name={FORM_NAMES.numberOfGuests}
          control={control}
          render={({field, fieldState: {error}}) => {
            return (
              <CounterStepper
                title={t('number_of_guests')}
                subtitle={
                  ageLimit && (
                    <Trans i18nKey="age_limit_years_old" values={{ageLimit}}>
                      (+{ageLimit} years old)
                    </Trans>
                  )
                }
                error={error?.message}
                disabled={isDisabled}
                readOnly={!canEdit}
                {...field}
              />
            );
          }}
        />
      )}
      {visibleFields[FORM_NAMES.junior] && (
        <Controller
          name={FORM_NAMES.junior}
          control={control}
          render={({field, fieldState: {error}}) => {
            return (
              <CounterStepper
                title={labels.junior}
                disabled={isDisabled}
                readOnly={!canEdit}
                error={error?.message}
                {...field}
              />
            );
          }}
        />
      )}
      {visibleFields[FORM_NAMES.middle] && (
        <Controller
          name={FORM_NAMES.middle}
          control={control}
          render={({field, fieldState: {error}}) => {
            return (
              <CounterStepper
                title={labels.middle}
                disabled={isDisabled}
                readOnly={!canEdit}
                error={error?.message}
                {...field}
              />
            );
          }}
        />
      )}
      {visibleFields[FORM_NAMES.senior] && (
        <Controller
          name={FORM_NAMES.senior}
          control={control}
          render={({field, fieldState: {error}}) => {
            return (
              <CounterStepper
                title={labels.senior}
                error={error?.message}
                readOnly={!canEdit}
                disabled={isDisabled}
                {...field}
              />
            );
          }}
        />
      )}
      <ButtonConfirm
        primary
        label={t('confirm')}
        disabled={disabled || isTaxesFetching || isLimitOfGuestsExceeded}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
}

export {NumberOfGuestsForm};
