import styled, {css} from 'styled-components';
import {device} from '../../styled/device';
import {MainLoader} from '../common/loaders';

export const Form = styled.form`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
`;

type FieldsProps = {
  rowsCount: number;
  hasSignature?: boolean;
};

export const Fields = styled.div<FieldsProps>`
  display: grid;
  /* grid-auto-flow: column; */
  grid-column-gap: 50px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(${props => props.rowsCount}, auto);
  text-align: center;
  max-width: 778px;
  position: relative;
  ${props =>
    !props.hasSignature &&
    css`
      margin: 0 auto;
    `};

  @media (max-width: ${device.tabletG}) {
    grid-template-columns: 1fr;
  }
  @media (max-width: ${device.laptop}) {
    grid-column-gap: 10px;
  }

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    display: flex;
  }
`;
export const FormWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 40px 40px;

  @media only screen and (max-width: ${device.laptopL}) {
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
  }

  @media only screen and (max-width: ${device.tablet}) {
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    padding: 0 10px 130px;
  }
`;

export const MainStyledLoader = styled(MainLoader)`
  margin-top: 60px;
  margin-bottom: 24px;
`;

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`;

export const LoadingText = styled.span`
  font-style: normal;
  font-weight: ${props => props.theme.fonts.fontWeight.mediumL};
  font-size: ${props => props.theme.fonts.sizes.large};
  line-height: 24px;
  text-align: center;
  -webkit-letter-spacing: 0.36px;
  -moz-letter-spacing: 0.36px;
  -ms-letter-spacing: 0.36px;
  letter-spacing: 0.36px;
  color: ${props => props.theme.colors.$p10};
`;
