import React from 'react';
import {ActionMeta} from 'react-select';
import {useTranslation} from 'react-i18next';
import {
  useHandlerDelay,
  useLockScrollBody,
  useModalWithHistoryControls,
} from '../../../hooks';
import {SelectOptionType} from '@guestapp/sdk';
import StyledIcon from '../StyledIcon';
import {Select, SelectProps, SelectRefType} from './Select';
import {IconWrapper, MobileSelectModal} from './styled';

type MobileSelectProps<T, V, L> = SelectProps<T, V, L>;

function MobileSelectInternal<T, V, L>(
  props: MobileSelectProps<T, V, L>,
  ref: React.ForwardedRef<SelectRefType<T, V, L>>,
) {
  const {onMenuOpen, onChange} = props;
  const {t} = useTranslation();
  const isSearchableEnabled = (props.options?.length || 0) > 15;
  const {isOpen, openModal, closeModal} = useModalWithHistoryControls({
    key: props.name || '',
  });
  useLockScrollBody(isOpen);

  const handleMenuOpen = React.useCallback(() => {
    openModal();
    onMenuOpen?.();
  }, [onMenuOpen, openModal]);

  const handleChangeHandler = (
    option: SelectOptionType<T, V, L> | null,
    info: ActionMeta<SelectOptionType<T, V, L>>,
  ) => {
    closeModal();
    onChange?.(option, info);
  };

  const handleChange = useHandlerDelay(handleChangeHandler, isOpen, 300);

  return (
    <>
      <Select
        {...props}
        isMenuOpen={isOpen}
        onMenuOpen={handleMenuOpen}
        isSearchable={false}
        menuIsOpen={false}
        ref={ref}
      />
      <MobileSelectModal
        visible={isOpen}
        onClose={closeModal}
        mode="mobile-slide"
        pressToTheTop={isSearchableEnabled}
      >
        <Select
          ref={ref}
          {...props}
          menuIsOpen
          isMenuOpen
          mobileVersion
          defaultMenuIsOpen
          isSearchable
          hideControl={!isSearchableEnabled}
          noOptionsMessage={() => t('no_matches_found')}
          onChange={handleChange}
          label={null}
          components={{LoadingIndicator: undefined, DropdownIndicator: null}}
          closeMenuOnSelect={false}
          controlShouldRenderValue={false}
          forcedSearchMode
          leftIcon={
            <IconWrapper>
              <StyledIcon size={16} color={'#9F9FBF'} name={'magnifierGray'} />
            </IconWrapper>
          }
        />
      </MobileSelectModal>
    </>
  );
}

const MobileSelect = React.forwardRef(MobileSelectInternal) as <T, V, L>(
  props: SelectProps<T, V, L> & {ref?: React.ForwardedRef<SelectRefType<T, V, L>>},
) => ReturnType<typeof MobileSelectInternal>;

export {MobileSelect};
