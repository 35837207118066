type Options = {
  trailing: boolean;
};
const throttle = <T, F extends (...args: T[]) => unknown>(
  fn: F,
  delay: number,
  options?: Options,
) => {
  let timeoutId: unknown = null;

  return (...args: T[]) => {
    if (timeoutId === null) {
      if (!options?.trailing) {
        fn(...args);
      }
      timeoutId = setTimeout(() => {
        timeoutId = null;
        if (options?.trailing) {
          fn(...args);
        }
      }, delay);
    }
  };
};

export {throttle};
