import styled from 'styled-components';
import Popup from '../Popup';

export const Wrapper = styled.div``;

export const PopupStyled = styled(Popup)`
  &-overlay {
    background: transparent;
  }

  &-content {
    color: #161643;
    padding: 8px 0 16px;
    z-index: 99999;
    min-width: 295px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 0 0 12px 12px;
    background: ${props => props.theme.colors.$p6};
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const Option = styled.div.attrs({className: 'dropdown__option'})`
  cursor: pointer;
  font-size: ${props => props.theme.fonts.sizes.medium};
  font-weight: ${props => props.theme.fonts.fontWeight.$medium};
  line-height: 24px;
  padding: 4px 16px;
  width: 100%;
  background: ${props => props.theme.colors.$p6};

  &:hover {
    color: ${props => props.theme.colors.$p6};
    background: ${props => props.theme.colors.$p9};
    transition: all ease-in-out 0.1s;
  }
`;
