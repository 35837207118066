enum PLANS_TYPES {
  premium = 'PREMIUM',
  travel = 'TRAVEL',
}
enum ALLIANZ_DOCUMENT_TYPES {
  termsAndConditions = 'TERMS_AND_CONDITIONS',
  informationNoteFromMediaton = 'INFORMATION_NOTE_FROM_THE_MEDIATOR',
  legalNoticeDataPrivacyPolicy = 'LEGAL_NOTICE_AND_DATA_PRIVACY_POLICY',
  productInformationDocument = 'PRODUCT_INFORMATION_DOCUMENT',
}

enum ALLIANZ_STATUSES {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
}

export {PLANS_TYPES, ALLIANZ_DOCUMENT_TYPES, ALLIANZ_STATUSES};
