import styled, {css} from 'styled-components';

type IconButtonStyledProps = {
  $light?: boolean;
};

export const Shadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: ${props => props.theme.colors.$p6};
  filter: blur(5px);
  z-index: -1;
  transition: all 0.2s;
`;

export const Button = styled.button<IconButtonStyledProps>`
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 50%;
  background: ${props => props.theme.colors.$p6};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    ${Shadow} {
      opacity: 0;
    }

    opacity: 0.8;
  }

  ${props =>
    props.$light &&
    css`
      background: transparent;
      width: auto;
      height: auto;

      ${Shadow} {
        opacity: 0;
      }
    `}

  ${props =>
    props.disabled &&
    css`
      &,
      &:hover {
        opacity: 0.6;
        cursor: default;
      }
    `}
`;
