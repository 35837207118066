import React from 'react';
import {useTranslation} from 'react-i18next';
import CustomIcon from 'components/common/CustomIcon';
import {DEAL_STATUSES} from '@guestapp/sdk';
import {useTheme} from 'styled-components';

import {Container, Text} from './styled';

type Props = {
  status: DEAL_STATUSES;
};
const OfferCardMark = ({status}: Props) => {
  const {t} = useTranslation();
  const theme = useTheme();

  if (![DEAL_STATUSES.requested, DEAL_STATUSES.approved].includes(status)) {
    return null;
  }
  const isRequested = status === DEAL_STATUSES.requested;

  return (
    <Container>
      <CustomIcon
        name={isRequested ? 'calendar' : 'shoppingCart'}
        size={16}
        color={theme.colors.buttons.mainButtonTextBackground}
      />
      <Text>
        {isRequested ? t('offer_list_view.requested') : t('offer_list_view.added')}
      </Text>
    </Container>
  );
};

export {OfferCardMark};
