import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter, HashRouter} from 'react-router-dom';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {I18nextProvider} from 'react-i18next';
import {i18n} from '../configs/i18n/i18n';
import QUERY_CLIENT_CONFIG from '../configs/query.config';
import store from 'store';
import {environments} from '../configs';
import {QueryClientProvider} from '@tanstack/react-query';
import {ChekinSDKProvider} from './ChekinSDK';
import {SummaryProvider} from './summary';
import {GuestProvider} from './guest';
import {ReservationProvider} from './reservation';
import {StripeProvider} from './stripe';
import {WebsocketProvider} from './websocket';
import {ThemeProvider} from './theme';
import {CustomThemeProvider} from './customTheme';

type AppProvidersProps = {
  children: React.ReactNode | JSX.Element;
};

const BaseRouter = environments.REACT_APP_IS_EMBEDDED ? HashRouter : BrowserRouter;

function AppProviders({children = null}: AppProvidersProps) {
  return (
    <Provider store={store}>
      <QueryClientProvider client={QUERY_CLIENT_CONFIG}>
        <BaseRouter>
          <ChekinSDKProvider>
            <I18nextProvider i18n={i18n}>
              <WebsocketProvider>
                <CustomThemeProvider>
                  <ReservationProvider>
                    <SummaryProvider>
                      <StripeProvider>
                        <ThemeProvider>
                          <GuestProvider>{children}</GuestProvider>
                        </ThemeProvider>
                      </StripeProvider>
                    </SummaryProvider>
                  </ReservationProvider>
                </CustomThemeProvider>
              </WebsocketProvider>
            </I18nextProvider>
          </ChekinSDKProvider>
        </BaseRouter>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Provider>
  );
}
export default AppProviders;
