import styled, {css} from 'styled-components';
import {device} from '../../styled/device';
import Webcam from '../Webcam';
import IconButton from '../common/IconButton';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledWebcam = styled(Webcam)<{roundedScanArea?: boolean}>`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 6;
  left: 0;
  top: 0;
  border-radius: 0;

  & .webcam__scan-area {
    height: 100%;
    max-height: 209px;

    @media (orientation: landscape) {
      max-height: 100%;
      height: 80%;
    }

    ${props =>
      props.roundedScanArea &&
      css`
        top: -60px;
        max-height: 55%;
        max-width: 80%;
        height: 100%;
        width: 100%;

        @media (max-width: ${device.mobileM}) {
          max-height: 369px;
          max-width: 261px;
          height: 100%;
          width: 100%;
        }

        @media (max-width: ${device.mobileS}) {
          max-height: 318px;
          max-width: 220px;
          height: 100%;
          width: 100%;
        }

        @media (orientation: landscape) {
          max-height: 90%;
          max-width: 261px;
          height: 100%;
          width: 100%;
        }
      `}
  }
`;

export const TakePhotoButton = styled(IconButton)`
  position: fixed;
  bottom: 40px;
  align-self: center;
  width: 75px;
  height: 75px;
  z-index: 7;
  box-shadow: 0 0 10px #ffffff;

  & > img {
    width: 26px;
    height: 26px;
  }

  @media (orientation: landscape) {
    right: 40px;
    bottom: 0;
    top: 0;
    margin: auto;
  }
`;

export const Title = styled.div`
  position: fixed;
  top: 32px;
  left: 50%;
  white-space: nowrap;
  transform: translate(-50%, 0);
  z-index: 7;
  color: white;
  font-size: 18px;
  font-weight: 700;

  @media (orientation: landscape) {
    top: 5px;
  }
`;

export const BackIconButton = styled.div`
  position: fixed;
  top: 36px;
  left: 28px;
  z-index: 7;

  @media (orientation: landscape) {
    top: 70px;
    left: 50px;
  }
`;

export const Subtitle = styled.p`
  position: fixed;
  top: 76px;
  color: white;
  z-index: 7;
  margin: 0 auto;
  padding: 0 24px;
  text-align: center;

  @media (orientation: landscape) {
    display: none;
  }

  @media (min-height: 838px) {
    top: 112px;
  }
`;

export const HelperText = styled.p`
  position: fixed;
  font-size: 12px;
  bottom: 134px;
  color: white;
  z-index: 7;
  margin: 0 auto;
  padding: 0 24px;
  text-align: center;

  @media (orientation: landscape) {
    display: none;
  }

  @media (min-height: 700px) {
    bottom: 130px;
    font-size: inherit;
  }

  @media (min-height: 850px) {
    bottom: 150px;
  }
`;
