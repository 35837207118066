import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useChekinSDK} from '../context/ChekinSDK';
import {useIsMounted} from './common';
import {QUERY_CACHE_KEYS} from '../utils/constants';

type useMutateDealsProps = {
  onError?: (error: Error) => void;
};
function useMutateDeals({onError}: useMutateDealsProps = {}) {
  const {deleteDeal} = useChekinSDK();
  const isMounted = useIsMounted();
  const queryClient = useQueryClient();

  const {mutate: deleteDealMutation, mutateAsync: deleteDealAsyncMutation} = useMutation<
    unknown,
    Error,
    string
  >(deleteDeal, {
    onSuccess: () => {
      void queryClient.refetchQueries([QUERY_CACHE_KEYS.paymentsCart]);
    },
    onError: error => {
      if (!isMounted.current) {
        return;
      }
      onError?.(error);
    },
  });

  return {deleteDealMutation, deleteDealAsyncMutation};
}

export {useMutateDeals};
