import {useCallback} from 'react';
import {useReservation} from 'context/reservation';
import {environments} from 'configs';
import ChekinPro from 'ChekinPro';
import {useTaxes} from './taxes';
import {useGuestGroup} from './useGuestGroup';

function useReservationSettings() {
  const {seasonEntity} = useTaxes();
  const {allowEditingGuestNumber} = useReservation();
  const {allGuestsRegistered} = useGuestGroup();

  const checkCanEditReservationDetails = useCallback(() => {
    const isEmbeddedMode = environments.REACT_APP_IS_EMBEDDED;
    const thereAreTaxRules = Boolean(seasonEntity?.rules && seasonEntity.rules.length);

    if (thereAreTaxRules) {
      return true;
    }

    if (
      !isEmbeddedMode ||
      ChekinPro.getSettings()?.canEditReservationDetails === undefined
    ) {
      return allowEditingGuestNumber;
    }

    return ChekinPro.getSettings()?.canEditReservationDetails;
  }, [allowEditingGuestNumber, seasonEntity]);

  const checkCanShowRegisterButtton = useCallback(() => {
    const isEmbeddedMode = environments.REACT_APP_IS_EMBEDDED;

    if (isEmbeddedMode) {
      return ChekinPro.getSettings()?.canEditReservationDetails || !allGuestsRegistered;
    }

    return allowEditingGuestNumber || !allGuestsRegistered;
  }, [allGuestsRegistered, allowEditingGuestNumber]);

  return {checkCanEditReservationDetails, checkCanShowRegisterButtton};
}

export {useReservationSettings};
