import {apiClient, FetcherSettings} from './apiClient';
import {OldShortLink, ShortLink} from '../utils/types';

class AuthApi {
  static ENDPOINTS = {
    refresh: () => `api/v3/checkin-online-token/refresh/`,
    apikeyAuth: () => `sdk/api/v1/apikey-auth`,
    shortLink: (token: string) => `api/short-link/${token}/`,
  };

  async getAuthToken(payload: {apikey: string}, fetcherSettings?: FetcherSettings) {
    const response = await apiClient.post<{
      [key: string]: string;
    }>(AuthApi.ENDPOINTS.apikeyAuth(), {
      json: payload,
      publicAccess: true,
      ...fetcherSettings,
    });
    return response.body;
  }

  getChekinToken({token}: {token: string}, fetcherSettings?: FetcherSettings) {
    return apiClient.get<OldShortLink | ShortLink>(AuthApi.ENDPOINTS.shortLink(token), {
      publicAccess: true,
      ...fetcherSettings,
    });
  }
}

export {AuthApi};
