import {AllianzInsuranceApi} from '../api';
import {FetcherSettings} from '../api/apiClient';

class AllianzInsuranceService {
  allianzInsuranceApi: AllianzInsuranceApi;

  constructor() {
    this.allianzInsuranceApi = new AllianzInsuranceApi();
  }

  getPlanByUser(params = '', resolverSettings?: FetcherSettings) {
    return this.allianzInsuranceApi.getPlanByUser(params, resolverSettings);
  }

  getOrders(params = '', resolverSettings?: FetcherSettings) {
    return this.allianzInsuranceApi.getOrders(params, resolverSettings);
  }

  calculateTotalPrice(params = '', resolverSettings?: FetcherSettings) {
    return this.allianzInsuranceApi.calculateTotalPrice(params, resolverSettings);
  }
  getDocuments(params = '', resolverSettings?: FetcherSettings) {
    return this.allianzInsuranceApi.getDocuments(params, resolverSettings);
  }
}

export {AllianzInsuranceService};
