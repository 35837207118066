import React from 'react';
import {Options} from 'react-select';
import {SelectedCountryCodeContainer, StyledSearchingSelect} from './styled';
import {LocationType, SelectOptionType} from '@guestapp/sdk';
import {isNumeric} from '@guestapp/core';
import {useChekinSDK} from '../../../../context/ChekinSDK';
import {QUERY_CACHE_KEYS} from '../../../../utils/constants';
import {SelectRefType} from '../../Select';

export type CountryCodeOption = {
  name: string;
  code: string;
};

export type PhoneCodesSelectProps = {
  value?: CountryCodeOption | null;
  onChange: (option: CountryCodeOption) => void;
  onError?: (error: Error) => void;
  defaultCode?: string;
  readOnly?: boolean;
  disabled?: boolean;
  error?: string;
};

type PhoneCodeOption = SelectOptionType<LocationType | undefined>;

const getPhoneOption = (option: PhoneCodeOption) => {
  if (option.data && !isNumeric(option?.value)) {
    const phoneCodeValue = `+${option.data.phone_code}`;

    return {
      label: `${option.label} ${phoneCodeValue}`,
      value: phoneCodeValue,
    };
  } else {
    return {label: option.label, value: option.value};
  }
};

const getPhoneOptionLabel = (option: Parameters<typeof getPhoneOption>[0]) => {
  const phoneOption = getPhoneOption(option);
  return phoneOption.label;
};

const checkIfOptionSelected = (
  option: PhoneCodeOption,
  selectValue: Options<PhoneCodeOption>,
) => {
  const selectedValue = selectValue?.[0];

  return option?.label === selectedValue?.label && option?.value === selectedValue?.value;
};

const PhoneCodesSelect = React.forwardRef<
  SelectRefType<LocationType | undefined>,
  PhoneCodesSelectProps
>(({onChange, disabled, value, onError, readOnly, defaultCode, error}, ref) => {
  const {getCountriesList} = useChekinSDK();
  const transformedValue = React.useMemo(() => {
    if (!value?.code) {
      return;
    }

    return {label: value?.name, value: value?.code};
  }, [value?.code, value?.name]);

  const handleCountryCodeChange = React.useCallback(
    (option: PhoneCodeOption | null) => {
      if (!option) return;
      const phoneOption = getPhoneOption(option);
      onChange({name: phoneOption.label, code: phoneOption.value});
    },
    [onChange],
  );

  const fetcher = React.useCallback(
    (key: string, page: number, searchQuery: string) => {
      return getCountriesList({
        key,
        page,
        searchQuery,
      });
    },
    [getCountriesList],
  );

  return (
    <StyledSearchingSelect<LocationType | undefined>
      ref={ref}
      queryKey={QUERY_CACHE_KEYS.paginatedCountriesList}
      fetcher={fetcher}
      formatOptionLabel={getPhoneOptionLabel}
      isOptionSelected={checkIfOptionSelected}
      onChange={handleCountryCodeChange}
      value={transformedValue}
      disabled={disabled}
      onError={onError}
      className="phone-field__code-select"
      trigger={(_, trigger) => (
        <SelectedCountryCodeContainer
          onClick={() => !disabled && !readOnly && trigger()}
          disabled={disabled}
          readOnly={readOnly}
          isEmpty={!value?.code}
          error={error}
          type="button"
          className="code-select"
        >
          {value?.code ?? '+00'}
        </SelectedCountryCodeContainer>
      )}
    />
  );
});

export {PhoneCodesSelect};
