import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {COUNTRY_CODES} from '../../utils/constants';
import {useSummary} from 'context/summary';
import {useIVComputedDetails} from 'hooks';
import Link from '../../components/common/Link';
import {Answer, StyledQuestionTitle} from './styled';
import {useChekinSDK} from 'context/ChekinSDK';
import {useReservation} from 'context/reservation';
import fetcher from 'utils/fetcher';
import {getDocumentDownloadByLanguage} from 'utils/common';
import {getPoliceTypeIfPoliceActive, POLICE_CODES} from '@guestapp/sdk';

const Question = ({text}: {text: string}) => {
  return <StyledQuestionTitle>{text}</StyledQuestionTitle>;
};
const Questions = () => {
  const {t, i18n} = useTranslation();
  const {countryCode, data, arePaymentsAvailable} = useSummary();
  const {isIVEnabled} = useIVComputedDetails();
  const {housing, housingId} = useReservation();
  const {getPrivacyPolicy} = useChekinSDK();
  const [hasCustomPrivacyPolicy, setHasCustomPrivacyPolicy] =
    React.useState<boolean>(false);
  const [documentDownload, setDocumentDownload] = React.useState<string>('');

  const isNotSESReservation =
    getPoliceTypeIfPoliceActive(housing) !== POLICE_CODES.hospedajes;
  const isSpainReservation = countryCode === COUNTRY_CODES.spain && isNotSESReservation;

  const fetchSettingsCustomPrivacyPolicy = React.useCallback(async () => {
    if (!housingId) {
      return;
    }

    try {
      const data = await fetcher(getPrivacyPolicy, housingId);

      if (data) {
        const result = getDocumentDownloadByLanguage(data, i18n.language);
        setDocumentDownload(result);
        setHasCustomPrivacyPolicy(data.has_custom_privacy_policy);
      }
    } catch (e) {}
  }, [getPrivacyPolicy, housingId, i18n.language]);

  React.useEffect(() => {
    if (!housing) {
      return;
    }
    fetchSettingsCustomPrivacyPolicy();
  }, [housing, fetchSettingsCustomPrivacyPolicy]);

  const generalQuestions = [
    {
      title: <Question text={t('faq_list.personal_data_question')} />,
      content: (
        <Answer>
          <Trans i18nKey="faq_list.personal_data_answer">
            According to the local law, the authorities require from the owners/hosts of
            tourist accommodations to have a record of all guests visiting their
            properties. The authorities have a minimum of mandatory personal data that you
            must provide.
            <br />
            <br /> You can complete the registration for for your data in advance in this
            online check-in form before your arrival.
            <br />
            <br /> All data processed according to the General Data Protection Regulation.
            Learn more in our{' '}
            {hasCustomPrivacyPolicy ? (
              <Link download href={documentDownload}>
                {t('privacy_policy')}
              </Link>
            ) : (
              <Link href={t('privacy_policy_link')} target="_blank" densed>
                {t('privacy_policy')}
              </Link>
            )}
            .
          </Trans>
        </Answer>
      ),
    },
    {
      title: <Question text={t('faq_list.personal_data_used_question')} />,
      content: (
        <Answer>
          <Trans i18nKey={'faq_list.personal_data_used_answer'}>
            We send that information to the local authorities that require this
            information, which could be the Police and/or the National Statistics
            Institute.
            <br />
            <br /> Learn more about in our{' '}
            {hasCustomPrivacyPolicy ? (
              <Link download href={documentDownload}>
                {t('privacy_policy')}
              </Link>
            ) : (
              <Link href={t('privacy_policy_link')} target="_blank" densed>
                {t('privacy_policy')}
              </Link>
            )}
            .
          </Trans>
        </Answer>
      ),
    },
    {
      title: <Question text={t('faq_list.how_long_keep_personal_data_question')} />,
      content: (
        <Answer>
          <Trans i18nKey={'faq_list.how_long_keep_personal_data_answer'} />
        </Answer>
      ),
    },
    {
      title: (
        <Question
          text={t('faq_list.possible_edit_guest_data_after_registration_question')}
        />
      ),
      content: (
        <Answer>
          <Trans
            i18nKey={'faq_list.possible_edit_guest_data_after_registration_answer'}
          />
        </Answer>
      ),
    },
    {
      title: (
        <Question text={t('faq_list.complete_data_fo_all_guests_in_group_question')} />
      ),
      content: (
        <Answer>
          <Trans i18nKey={'faq_list.complete_data_fo_all_guests_in_group_answer'} />
        </Answer>
      ),
    },
    {
      title: <Question text={t('faq_list.do_minors_need_to_complete_registration')} />,
      content: (
        <Answer>
          {isSpainReservation
            ? t('faq_list.do_minors_need_to_complete_registration_spain_answer')
            : t('faq_list.do_minors_need_to_complete_registration_answer')}
        </Answer>
      ),
    },
  ];

  const conditionalQuestions = [
    {
      title: <Question text={t('faq_list.why_iv_required_question')} />,
      content: <Answer>{t('faq_list.why_iv_required_answer')}</Answer>,
      hidden: !isIVEnabled,
    },
    {
      title: <Question text={t('faq_list.do_minors_need_to_complete_iv')} />,
      content: <Answer>{t('faq_list.do_minors_need_to_complete_iv_answer')}</Answer>,
      hidden: !isIVEnabled,
    },
    {
      title: <Question text={t('faq_list.tourist_taxes_payment_mandatory_question')} />,
      content: <Answer>{t('faq_list.tourist_taxes_payment_mandatory_answer')}</Answer>,
      hidden: !data?.taxes?.active,
    },
    {
      title: <Question text={t('faq_list.complete_payment_later_question')} />,
      content: <Answer>{t('faq_list.complete_payment_later_answer')}</Answer>,
      hidden: !arePaymentsAvailable,
    },
  ];

  const getAllDisplayedQuestion = () => {
    const filteredConditionalQuestions = conditionalQuestions.filter(
      item => !item.hidden,
    );

    return [...generalQuestions, ...filteredConditionalQuestions];
  };

  return getAllDisplayedQuestion();
};

export default Questions;
