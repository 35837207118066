import React from 'react';
import i18n from 'i18next';
import {useTranslation} from 'react-i18next';
import {Plan} from '@guestapp/sdk/src/utils/allianz';
import {getTranslatedPlanName} from 'hooks/allianz/utils';
import {useFetchAllianzOrders, useGuestGroup, useModalControls} from 'hooks';
import allianzLogoMini from '../../../assets/icons/allianz-logo-mini.svg';
import insuranceCwdIcon from 'assets/icons/insurance-cwd.svg';
import {CustomIcon} from '@guestapp/ui';
import {AllianzAddedDealsToCartToast} from 'pages/Allianz/components';
import {
  AllianzGuestsInformationContainer,
  AllianzIcon,
  InfoContainer,
  InsuracenPaidGuestBox,
  StyledModalSheet,
} from './styled';

type InsuredGuest = {
  fullName: string | undefined;
  planName: Record<string, string>;
  addonName: string | undefined;
}[];

function getAddonName(addons: Plan[]): string {
  if (!Boolean(addons.length)) {
    return '';
  }
  return getTranslatedPlanName(addons[0].name, i18n.language) || '';
}

function AllianzInsurancesInCarButton() {
  const {t} = useTranslation();
  const {guestGroup} = useGuestGroup();
  const {data: orders} = useFetchAllianzOrders({
    enabled: Boolean(guestGroup?.members),
  });
  const currentLanguage = i18n.language;
  const insuredGuests: InsuredGuest = React.useMemo(() => {
    const insuredGuests = (orders ?? []).filter(order => order.paid);
    const members = guestGroup?.members ?? [];
    return insuredGuests.map(insuredGuest => {
      const guest = members.find(member => member.id === insuredGuest.guest_id);
      return {
        fullName: guest?.full_name,
        planName: insuredGuest?.plan_order.name,
        addonName: getAddonName(insuredGuest.plan_order.addons),
      };
    });
  }, [orders, guestGroup?.members]);

  const {
    isOpen: isOpenInsuranceInCarModal,
    closeModal: closeInsuranceInCarModal,
    openModal: openInsuranceInCarModal,
  } = useModalControls();

  if (!Boolean(insuredGuests.length)) return null;

  return (
    <>
      <AllianzGuestsInformationContainer
        onClick={openInsuranceInCarModal}
        className="allianz-guests-info"
      >
        <AllianzIcon src={allianzLogoMini} alt="Allianz logo mini" />
        <span>
          {t('allianz_insurances_guests_count', {guestsCount: insuredGuests.length})}
        </span>
      </AllianzGuestsInformationContainer>
      <StyledModalSheet
        isOpenModalSheet={isOpenInsuranceInCarModal}
        handleCloseModal={closeInsuranceInCarModal}
        maxWidth={'375px'}
        withCloseButton={true}
        closeOnDocumentClick
        withModalMobileCloseButton={true}
        title={t('allianz_insured_guest')}
        className="allianz-guests-info__modal"
      >
        {insuredGuests.map((insuredGuest, index) => {
          const translatedPlanName = getTranslatedPlanName(
            insuredGuest.planName,
            currentLanguage,
          );

          const planName = Boolean(insuredGuest.addonName)
            ? `${translatedPlanName} + ${insuredGuest.addonName}`
            : translatedPlanName;

          return (
            <InsuracenPaidGuestBox key={index} className="allianz-guests-info__guest-box">
              {Boolean(insuredGuest.addonName) ? (
                <img src={insuranceCwdIcon} alt="insurance cwd" />
              ) : (
                <CustomIcon name="allianzShieldIcon" color="#152946" />
              )}

              <InfoContainer className="allianz-guests-info__guest-info">
                <span className="guest-name">{insuredGuest?.fullName}</span>
                <span className="plan-name">{planName}</span>
              </InfoContainer>
            </InsuracenPaidGuestBox>
          );
        })}
      </StyledModalSheet>
      <AllianzAddedDealsToCartToast />
    </>
  );
}

export {AllianzInsurancesInCarButton};
