import styled from 'styled-components';
import {device} from '../../../../styled/device';
import Button from '../../../../components/common/Button';

export const HelperText = styled.p`
  font-size: 14px;
  text-align: center;
  margin: 0 auto 16px;
  color: ${props => props.theme.colors.branding.fontSecondary};

  @media (min-width: ${device.tablet}) {
    font-size: 16px;
  }
`;

export const QuestionButton = styled(Button)`
  align-self: center;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 600;
`;

export const ModalContent = styled.div`
  display: block;

  div {
    &:first-child {
      margin-bottom: 24px;
    }
  }
`;
