import {ErrorEvent} from '@sentry/react';

const SentryErrorLevel = 'error';

const filterSentryLogging = (event: ErrorEvent) => {
  if (event.level !== SentryErrorLevel) return null;
  else return event;
};

export {filterSentryLogging};
