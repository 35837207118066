export const device = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  mobileXL: '479px',
  tabletSubheader: '767px',
  tablet: '768px',
  tabletM: '910px',
  tabletG: '991px',
  laptop: '1024px',
  laptopM: '1126px',
  laptopL: '1440px',
  laptopXL: '1640px',
  desktop: '2560px',
};
