import styled, {css} from 'styled-components';
import {device} from '../../styled/device';
import {STATUSES} from '../../utils/constants';
import Modal from '../Modal';

export const StyledModal = styled(Modal)<{status?: STATUSES}>`
  &-content {
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    height: 100vh;
    ${props =>
      props.status === STATUSES.success &&
      css`
        & .modal__title {
          margin-top: 10px;
        }
      `};
  }

  @media (max-width: ${device.mobileXL}) {
    &-content,
    &-overlay {
      animation: none;
    }
  }

  @media (min-width: ${device.mobileXL}) {
    &-content {
      animation: none;
      padding: 50px;

      min-width: 362px;
      min-height: 322px;
      height: auto;
      & .modal__title {
        font-size: 18px;
        font-weight: 600;
        margin: 10px 0 0;
      }

      & .modal__description {
        font-size: 16px;
        font-weight: 400;
        //margin: 24px 0 0;
        margin-top: 24px;
      }
    }
  }
`;
