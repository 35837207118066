import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IVSteps} from 'utils/types';

export type StepperEventParams = {
  steps: number;
  currentNumberStep: number;
  currentStep: IVSteps;
};

const initialState: StepperEventParams = {
  steps: 0,
  currentNumberStep: 0,
  currentStep: IVSteps.select,
};

const StepperSlice = createSlice({
  name: 'StepperSlice',
  initialState,
  reducers: {
    setSteps(state, action: PayloadAction<number>) {
      state.steps = action.payload;
    },
    setCurrentStep(state, action: PayloadAction<IVSteps>) {
      state.currentStep = action.payload;
      if (state.currentStep === IVSteps.selfie) {
        state.currentNumberStep = state.steps;
      }
      if (state.currentStep === IVSteps.upload) {
        state.currentNumberStep++;
      }
    },
    resetIVProcess: () => initialState,
  },
});

export {StepperSlice};
export const stepperActions = StepperSlice.actions;
export const stepperReducer = StepperSlice.reducer;
