import React, {memo} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Season} from '@guestapp/sdk';
import StandardModalSheet from '../../../../components/common/StandardModalSheet';
import {ModalSheet} from '../../../../components/common/ModalSheet/ModalSheet';
import {ListExemption} from './styled';

type ExemptionsListModalProps = {
  isOpen: boolean;
  close: () => void;
  exemptions?: Season['exemptions'];
};
const ExemptionsListModal = memo(
  ({isOpen, close, exemptions}: ExemptionsListModalProps) => {
    const {t} = useTranslation();

    return (
      <ModalSheet
        isOpenModalSheet={isOpen}
        handleCloseModal={close}
        title={t('exemptions_list')}
        withCloseButton
      >
        <StandardModalSheet
          text={
            <>
              <Trans i18nKey="exemptions_list_text">
                This city may exempt certain categories of travelers from paying tourist
                taxes. Please note that exemptions needs to be <b>verified</b> upon
                arrival.
              </Trans>
              <ListExemption>
                <ul>
                  {exemptions?.map(exemption => {
                    return <li key={exemption.id}>{exemption.exemption_name}</li>;
                  })}
                </ul>
              </ListExemption>
            </>
          }
        />
      </ModalSheet>
    );
  },
);

export {ExemptionsListModal};
