import styled, {css} from 'styled-components';
import {wrapperCommonStyles} from '../styled';
import UploadFile from '../../UploadFile';
import {Row} from '../../../styled/common';

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row-gap: 36px;
  margin: 43px auto 0;
  width: 100%;
`;

export const StyledUploadPhoto = styled(UploadFile)`
  ${wrapperCommonStyles};
  /* padding: 19px; */
  margin-bottom: 20px;
`;

export const Photo = styled.img`
  ${wrapperCommonStyles};
  object-fit: cover;
  margin-bottom: 20px;
`;

export const PlaceholderImage = styled.img.attrs({className: 'photo-box__placeholder'})<{
  $visible?: boolean;
}>`
  width: 90%;
  height: 90%;
  opacity: 0;
  transition: all ease-in-out 0.25s;

  ${props =>
    props.$visible &&
    css`
      opacity: 1;
    `}
`;

export const IncludedRow = styled(Row).attrs({className: 'upload-box__row'})`
  margin-top: 8px;
  font-size: 12px;
  color: ${props => props.theme.colors.branding.fontSecondary};

  & > p {
    margin: 0 0 0 10px;
  }
`;
