import React from 'react';
import {useTranslation} from 'react-i18next';
import {TFunction} from 'i18next';
import {loadStripe} from '@stripe/stripe-js/pure';
import {Elements} from '@stripe/react-stripe-js';
import {CHEKIN_ROOT_IFRAME_ID} from '../utils/embedded';
import {Stripe, StripeElementLocale} from '@stripe/stripe-js';
import {getEnvVariable} from '../configs';
import {usePaymentSettings} from '../hooks';
import {useFrame} from './frame';

type StripeProviderProps = {
  children: React.ReactNode | JSX.Element;
};

const loadStripeToIframe = (
  stripeKey: string,
  {stripeAccount, t}: {stripeAccount?: string; t: TFunction},
) => {
  return new Promise<Stripe | null>((resolve, reject) => {
    const rawFrame = document.getElementById(CHEKIN_ROOT_IFRAME_ID) as HTMLIFrameElement;
    const iframeWindow = rawFrame?.contentWindow;
    const frameDoc = iframeWindow?.document;
    const existingScript = frameDoc?.getElementById('stripe-script');
    if (existingScript) return resolve(null);

    const stripeJs = document.createElement('script');

    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.id = 'stripe-script';

    stripeJs.onload = () => {
      if (iframeWindow?.Stripe) {
        const stripe = iframeWindow.Stripe(stripeKey, {stripeAccount});
        resolve(stripe);
      } else {
        reject(t('stripe_object_is_not_found'));
      }
    };

    frameDoc?.head.appendChild(stripeJs);
  });
};

function StripeProvider({children}: StripeProviderProps) {
  const {i18n, t} = useTranslation();
  const {frame} = useFrame();
  const {paymentSettings, isStripePaymentProvider} = usePaymentSettings();
  const stripeAccount = paymentSettings?.external_account_id;

  const options = React.useMemo(
    () => ({locale: i18n.language as StripeElementLocale}),
    [i18n.language],
  );

  const stripePromise = React.useMemo(() => {
    const needLoadStripe = isStripePaymentProvider && stripeAccount;
    const stripeKey = getEnvVariable('REACT_APP_STRIPE_API_KEY');

    if (!needLoadStripe) {
      return null;
    }

    if (Boolean(frame)) {
      return loadStripeToIframe(stripeKey, {stripeAccount, t});
    }

    return loadStripe(stripeKey, {stripeAccount});
  }, [frame, isStripePaymentProvider, stripeAccount, t]);

  return (
    <Elements
      children={children}
      stripe={stripePromise}
      key={stripeAccount}
      options={options}
    />
  );
}

export {StripeProvider};
