import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useOpenModals} from 'context/openModals';
import {usePath} from 'hooks';
import {useDiscardIVModal} from 'hooks/useDiscardIVModal';
import {PATHS} from 'Routes';
import ModalSheet from '../ModalSheet';
import DiscardIVChanges from 'components/ModalSheets/DiscardIVChanges';

export type DiscardChangesModalRef = {
  open: (link?: string) => void;
};

const DiscardChangesModal = React.forwardRef<DiscardChangesModalRef>((_, ref) => {
  const {t} = useTranslation();
  const {isOpenDiscardIVModal, closeDiscardIVModal, openDiscardIVModal, resetIVProcess} =
    useDiscardIVModal();
  const {checkIfOpenDiscardModalAndRedirect} = useOpenModals();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const {getFullPath} = usePath();

  const discardFunction = React.useCallback(
    (link?: string) => {
      const verificationViewsToOpenDiscardModal = [
        PATHS.verification.front,
        PATHS.verification.back,
        PATHS.verification.selfie,
        PATHS.verification.uploadingType,
        PATHS.verification.documentSelection,
        'upload',
        'take',
      ];
      const isAddPersonalDataView = pathname.includes(PATHS.addPersonalDataForm);
      const [currentVerificationView] = pathname.split('/').slice(-1);
      const isVerificationViewToOpenDiscardModal =
        verificationViewsToOpenDiscardModal.includes(currentVerificationView);

      if (isAddPersonalDataView) {
        checkIfOpenDiscardModalAndRedirect(link as string);
        return;
      }

      if (isVerificationViewToOpenDiscardModal) {
        openDiscardIVModal();
        return;
      }
      navigate(getFullPath(link ?? PATHS.home));
    },
    [
      pathname,
      openDiscardIVModal,
      checkIfOpenDiscardModalAndRedirect,
      getFullPath,
      navigate,
    ],
  );

  React.useImperativeHandle(
    ref,
    () => {
      return {
        open: discardFunction,
      };
    },
    [discardFunction],
  );

  return (
    <ModalSheet
      isOpenModalSheet={isOpenDiscardIVModal}
      handleCloseModal={closeDiscardIVModal}
      title={t('discard_changes')}
      closeOnDocumentClick={false}
      closeButtonType="click"
    >
      <DiscardIVChanges onClose={closeDiscardIVModal} resetIVProcess={resetIVProcess} />
    </ModalSheet>
  );
});

export {DiscardChangesModal};
