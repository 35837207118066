import React from 'react';
import {device} from '../../../styled/device';
import {useScreenResize} from '../../../hooks';
import {SelectRefType} from '../Select';
import {SearchingSelect, SearchingSelectProps} from './SearchingSelect';
import {MobileSearchingSelect} from './MobileSearchingSelect';

type AdaptiveSearchingSelectProps<T, V, L> = SearchingSelectProps<T, V, L> & {
  target?: (isOpen: boolean, toggle: () => void) => React.ReactNode;
};

const AdaptiveSearchingSelectInternal = <T, V, L>(
  {...props}: AdaptiveSearchingSelectProps<T, V, L>,
  ref: React.ForwardedRef<SelectRefType<T, V, L>>,
) => {
  const {isMatch: isMobileMode} = useScreenResize(device.mobileXL);

  if (isMobileMode) {
    return <MobileSearchingSelect ref={ref} {...props} />;
  }

  return <SearchingSelect ref={ref} {...props} />;
};

const AdaptiveSearchingSelect = React.forwardRef(AdaptiveSearchingSelectInternal) as <
  T,
  V = string,
  L = string,
>(
  props: AdaptiveSearchingSelectProps<T, V, L> & {
    ref?: React.ForwardedRef<SelectRefType<T, V, L>>;
  },
) => ReturnType<typeof AdaptiveSearchingSelectInternal>;

export {AdaptiveSearchingSelect};
