import {PaymentsCart} from '@guestapp/sdk';

function checkIfTotalAmountToPayZero(paymentsCart?: PaymentsCart) {
  const isTotalAmountNotZero = Boolean(
    parseFloat(paymentsCart?.total_amount_to_pay || '0'),
  );
  const isRetentionAmountNotZero = Boolean(
    parseFloat(paymentsCart?.retention_payments_total || '0'),
  );
  const isPreAuthAmountNotZero = Boolean(
    parseFloat(paymentsCart?.pre_auth_payments_total || '0'),
  );

  return !isTotalAmountNotZero && !isRetentionAmountNotZero && !isPreAuthAmountNotZero;
}

export {checkIfTotalAmountToPayZero};
