import React from 'react';
import {components, DropdownIndicatorProps, GroupBase} from 'react-select';
import {SelectOptionType} from '@guestapp/sdk';
import StyledIcon from '../../StyledIcon';
import {CustomIcon, DisplayIcon} from './styled';
import {useTheme} from 'styled-components';

const DropdownIndicator = <T, V, L>(
  props: DropdownIndicatorProps<
    SelectOptionType<T, V, L>,
    false,
    GroupBase<SelectOptionType<T, V, L>>
  >,
) => {
  const theme = useTheme();
  const selectProps = props?.selectProps;
  const customIndicator =
    selectProps?.isMenuOpen && selectProps?.indicatorOpenState
      ? selectProps?.indicatorOpenState
      : selectProps?.indicator;
  const color = props.isFocused ? theme.colors.branding.primary : '#6B6B95';
  return (
    <>
      {!selectProps.isLoading ? (
        <components.DropdownIndicator {...props}>
          {props?.selectProps?.indicator && customIndicator ? (
            <CustomIcon
              shouldRotate={Boolean(
                selectProps.isMenuOpen && !selectProps?.indicatorOpenState,
              )}
            >
              <StyledIcon
                color="#6B6B95"
                name={customIndicator}
                size={selectProps?.indicatorSize}
              />
            </CustomIcon>
          ) : (
            <DisplayIcon shouldRotate={Boolean(selectProps.isMenuOpen)}>
              <StyledIcon color={color} name="arrowBottom" size="18px" />
            </DisplayIcon>
          )}
        </components.DropdownIndicator>
      ) : null}
    </>
  );
};

export {DropdownIndicator};
