import {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {CustomIcon} from '@guestapp/ui';
import {useReservationSettings} from 'hooks';
import {EditIcon, GuestListTitle} from './styled';

type ModalTitleProps = {
  currentTotalGuest: number;
  totalGuest: number;
  onEditClick: () => void;
};
const ModalTitle = memo(
  ({currentTotalGuest, totalGuest, onEditClick}: ModalTitleProps) => {
    const {t} = useTranslation();
    const {checkCanEditReservationDetails} = useReservationSettings();
    const canEditReservationDetails = checkCanEditReservationDetails();
    return (
      <GuestListTitle className="guest-register-view__title">
        {t('registered_guests')} ({currentTotalGuest} {t('of')} {totalGuest})
        {canEditReservationDetails && (
          <EditIcon onClick={onEditClick}>
            <CustomIcon name="editIcon" title="Edit" size={24} color="rgb(57, 58, 189)" />
          </EditIcon>
        )}
      </GuestListTitle>
    );
  },
);

export {ModalTitle};
