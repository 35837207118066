import {useEffect} from 'react';
import {useGuest, useGuestGroup, usePreloadGuestData} from '../../hooks';
import {FORM_NAMES} from '../../utils/guestForm';
import {COUNTRY_CODES} from '../../utils/constants';

const COUNTRIES_WITH_PRELOADING_BY_LEADER = [COUNTRY_CODES.france];

type UsePreloadLeaderDataProps = {
  countryCode?: string;
};
export function usePreloadLeaderData({countryCode}: UsePreloadLeaderDataProps) {
  const {guestGroup} = useGuestGroup();
  const leaderGuest = guestGroup?.leader_id;
  const {guest, isGuestLoaded} = useGuest({id: leaderGuest});
  const isPreloadingAllowed =
    !!countryCode && COUNTRIES_WITH_PRELOADING_BY_LEADER.includes(countryCode);

  const {preloadPersistedFormData} = usePreloadGuestData({
    guestData: {
      [FORM_NAMES.residence_country]: guest?.residence_country,
      [FORM_NAMES.residence_city]: guest?.residence_city,
      [FORM_NAMES.residence_address]: guest?.residence_address,
    },
  });

  useEffect(() => {
    if (leaderGuest && isGuestLoaded && isPreloadingAllowed) {
      preloadPersistedFormData(leaderGuest);
    }
  }, [leaderGuest, preloadPersistedFormData, isGuestLoaded, isPreloadingAllowed]);
}
