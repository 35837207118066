import store from 'store2';
import AbstractStorage from './AbstractStorage';
import {jsonParse} from '@guestapp/sdk';

class SessionStorage extends AbstractStorage {
  static get<T>(key: string): T | null {
    const data = store.session(key);
    return jsonParse(data);
  }

  static set<T>(key: string, value: T): void {
    if (value) {
      store.session(key, value);
    }
  }

  static update<T>(key: string, field: string, value: T): void {
    const data = SessionStorage.get<Record<string, T>>(key);

    if (data) {
      data[field] = value;
      SessionStorage.set(key, data);
    } else {
      SessionStorage.set(key, {[field]: value});
    }
  }

  static remove(key: string): void {
    store.session.remove(key);
  }

  static clear(): void {
    store.session.clear();
  }
}

export {SessionStorage};
