import React from 'react';
import {useTranslation} from 'react-i18next';
import {useFormContext} from 'react-hook-form';
import {InputController as Input, InputProps} from '@guestapp/ui';

type GuestEmailFieldProps = Pick<
  InputProps,
  'label' | 'disabled' | 'onKeyDown' | 'className' | 'defaultValue'
> & {
  name: string;
  format: RegExp;
  required?: boolean | string;
};

const GuestEmailField = React.memo(
  ({name, format, label, required, disabled, ...props}: GuestEmailFieldProps) => {
    const {t} = useTranslation();
    const {
      register,
      control,
      formState: {errors},
    } = useFormContext();

    return (
      <Input
        label={label}
        {...register(name, {
          required,
          pattern: {
            value: format,
            message: t('invalid_email'),
          },
        })}
        control={control}
        inputMode="email"
        autoCorrect="off"
        spellCheck={false}
        autoCapitalize="none"
        error={errors[name]?.message as string}
        disabled={disabled}
        {...props}
      />
    );
  },
);

export {GuestEmailField};
