import styled, {css} from 'styled-components';

type WrapperProps = {
  error?: string;
  children: React.ReactNode;
};

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: 100%;
  text-align: center;
  border-radius: 6px;

  & .fieldset__label {
    left: 58px;
    top: 13.5px;
  }

  & .input__input {
    height: 48px;
    color: ${props => props.theme.colors.branding.fontPrimary};
    padding: 0 10px 0 60px;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ${props =>
      props.error &&
      css`
        border-color: ${props => props.theme.colors.$s1};
      `}
  }
`;
