import React from 'react';
import {useTheme} from 'styled-components';
import CustomIcon from '../CustomIcon/CustomIcon';
import {Message, Wrapper} from './styled';

type Props = {
  children: React.ReactNode;
};
function Tag({children}: Props) {
  const theme = useTheme();
  return (
    <Wrapper>
      <CustomIcon size={24} name="tag" color={theme.colors.branding.fontSecondary} />
      <Message>{children}</Message>
    </Wrapper>
  );
}

export {Tag};
