import React from 'react';
import {OrderHistory, PaymentOrderHistory} from '@guestapp/sdk';
import dayjs from 'dayjs';
import {usePaymentSettings} from '../../hooks';
import {useTranslation} from 'react-i18next';

const Names: Record<PaymentOrderHistory['name'], string> = {
  'Payment details': 'payment_details',
  'Pre-authorizations': 'pre_authorizations',
  Retentions: 'retentions',
  Refunds: 'refunds',
};

const HelperText: {[Key in PaymentOrderHistory['name']]?: string} = {
  'Pre-authorizations': 'pre_authorization_helper_text',
  Retentions: 'retention_helper_text',
  Refunds: 'refunds_helper_text',
};

const TotalLabels: {[Key in PaymentOrderHistory['name']]?: string} = {
  Refunds: 'total_refunded',
};

const DateLabels: {[Key in PaymentOrderHistory['name']]?: string} = {
  'Pre-authorizations': 'request_date',
  Refunds: 'refund_date',
};

function useBuildDisplayedPayments(orderHistory?: OrderHistory) {
  const {t} = useTranslation();
  const {currencySymbol} = usePaymentSettings();
  const cartInfo = orderHistory?.cards_info?.[0];

  const orderHistories = React.useMemo(() => {
    if (!orderHistory) return [];
    const {cards_info, ...orderHistoriesWithoutCardInfo} = orderHistory;
    return Object.values(orderHistoriesWithoutCardInfo)
      .filter(item => item.items?.length)
      .map(order => {
        const date = dayjs(order.date);
        const totalAmount = `${order?.total_amount_with_commission}${currencySymbol}`;
        const subtitle = `${totalAmount} - ${date.format('DD/MM/YYYY')}`;
        const title = t(Names[order.name]);
        const helperText = t(HelperText[order.name] || '');
        const totalLabel = t(TotalLabels[order.name] || '');
        const dateLabel = t(DateLabels[order.name] || '');

        return {
          ...order,
          title,
          subtitle,
          helperText,
          totalLabel,
          dateLabel,
          date: date.format('MMM D, YYYY'),
          cardLastNumbers: cartInfo?.last4,
          totalAmount,
        };
      });
  }, [cartInfo?.last4, currencySymbol, orderHistory, t]);

  return {orderHistories, cartInfo: orderHistory?.cards_info?.[0]};
}

export default useBuildDisplayedPayments;
