import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {PATHS} from 'Routes';
import Page from '../../../components/Page';
import PaymentForm from '../../../components/PaymentForm';
import {usePath} from '../../../hooks';

function PaymentFormView() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {getFullPath} = usePath();

  return (
    <Page
      isSubHeaderVisible
      onBackButtonClick={() => {
        navigate(getFullPath(PATHS.payments.main));
      }}
      hideSubPanelBackButton
      title={t('payment')}
      subtitle={t('enter_your_card_details')}
      navBarTitle={t('booking_payments')}
      className="payment-form-view"
    >
      <PaymentForm />
    </Page>
  );
}

export {PaymentFormView};
