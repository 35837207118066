import styled, {css} from 'styled-components';
import {device} from 'styled/device';

export const ClipContainer = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  background-color: ${props => props.theme.colors.$p0};
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 4px #0002032b;

  & > img {
    width: 22px;
    height: 22px;
  }
`;

export const ClearButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin-left: 15px;
`;

export const ClearIcon = styled.img`
  height: 15px;
  width: 15px;
  border-radius: 3px;
  cursor: pointer;
  vertical-align: middle;

  &:active {
    opacity: 0.95;
  }

  &:hover {
    box-shadow: 0 3px 3px #0f477734;
    transition: box-shadow 0.15s ease-in-out;
  }
`;

export const HiddenFileUploader = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  position: absolute;
`;

type PlaceholderProps = {
  isEmpty: boolean;
};
export const Placeholder = styled.span<PlaceholderProps>`
  font-size: ${props => props.theme.fonts.sizes.medium};
  opacity: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  top: ${props => (!props.isEmpty ? 'none' : '-13px')};
  color: rgb(129, 129, 163);
  position: ${props => (!props.isEmpty ? 'relative' : 'absolute')};
  ${props =>
    props.isEmpty &&
    css`
      background-color: ${props => props.theme.colors.$p6};
    `};

  @media (max-width: ${device.mobileL}) {
    font-size: ${props => props.theme.fonts.sizes.largeL};
  }
`;

export const inputStyles = css`
  width: 100%;
  outline: none;
  margin: 0;
  font-family: inherit;
  font-size: ${props => props.theme.fonts.sizes.medium};
  transition: background-color 0.25s ease-in-out, border-color 0.05s ease-in-out;
  color: ${props => props.theme.colors.$n3};
  padding: 13px 16px 13px 7px;
  box-sizing: border-box;
  border-radius: 7px;
  border: 1px solid ${props => props.theme.colors.$gray};
  height: 50px;
  &.invalid {
    border-color: ${props => props.theme.colors.$s1};
  }

  &::placeholder {
    font-family: inherit;
    color: ${props => props.theme.colors.$p3};
    opacity: 1;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }
`;

export const FileInputBox = styled.div<PlaceholderProps>`
  ${inputStyles};
  background-color: ${props =>
    !props.isEmpty ? props.theme.colors.$b1 : props.theme.colors.$p6};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FileNameContainer = styled.div`
  height: 24px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  max-width: 85%;
  background-color: ${props => props.theme.colors.$n8};
  padding-left: 8px;
  padding-right: 4px;
  border: 1px solid ${props => props.theme.colors.$n9};
  border-radius: 4px;

  &:hover {
    cursor: default;

    &&& + ${ClipContainer} {
      opacity: 1;
    }
  }
`;

export const FileName = styled.span`
  font-size: ${props => props.theme.fonts.sizes.small};
  color: ${props => props.theme.colors.$n1};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 190px;
`;

type FileInputLabelProps = {
  disabled?: boolean;
  invalid?: boolean;
};

export const FileInputLabel = styled.label<FileInputLabelProps>`
  outline: none;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  font-size: ${props => props.theme.fonts.sizes.small};
  box-sizing: border-box;
  transition: all 0.07s ease-in-out;
  text-align: left;
  position: relative;
  background-color: ${props => props.theme.colors.$b1};

  &:hover ${ClipContainer} {
    opacity: 0.88;
  }

  &:active {
    opacity: 1;
  }

  ${props =>
    props.invalid &&
    css`
      & ${FileInputBox} {
        border-color: ${props => props.theme.colors.$s1};
      }
    `};

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;

      &:hover,
      &:active {
        opacity: 0.5;

        & ${ClearIcon} {
          cursor: not-allowed;
          opacity: 1;
          box-shadow: none;
        }

        & ${FileNameContainer} {
          cursor: not-allowed;
        }
      }
    `};

  @media (max-width: ${device.mobileS}) {
    width: 293px;
  }
`;
