enum FORM_NAMES {
  junior = 'junior',
  middle = 'middle',
  senior = 'senior',
  others = 'others',
  numberOfGuests = 'number_of_guests',
  group_type = 'group_type',
}

enum MODAL_FORMS {
  numberOfGuests = 'number_of_guests',
  reservationType = 'reservation_type',
  confirmLeaderGuest = 'confirm_leader_guest',
}

export {FORM_NAMES, MODAL_FORMS};
