import React from 'react';
import {createPortal} from 'react-dom';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useFrame} from '../../context/frame';
import {WebcamProps, WebcamRefTypes} from '../Webcam';
import cameraIcon from '../../assets/icons/camera-icon.svg';
import CustomIcon from 'components/common/CustomIcon';
import {
  BackIconButton,
  HelperText,
  StyledWebcam,
  Subtitle,
  TakePhotoButton,
  Title,
  Wrapper,
} from './styled';

export type MobileCameraProps = WebcamProps & {
  onScreenshot: () => void;
  text?: React.ReactNode | string;
  title?: React.ReactNode | string;
};
const MobileWebcam = React.forwardRef<WebcamRefTypes, MobileCameraProps>(
  ({onScreenshot, title, text, ...props}, ref) => {
    const navigate = useNavigate();
    const {document: frameDocument} = useFrame();
    const rootElement = frameDocument?.body || document.body;

    const {t} = useTranslation();

    const onBack = () => navigate(-1);

    return createPortal(
      <Wrapper className="mobile-camera">
        <BackIconButton onClick={onBack} className="mobile-camera__back-btn">
          <CustomIcon size={20} name={'backBlueArrow'} color="white" />
        </BackIconButton>
        {title && <Title className="mobile-camera__title">{title}</Title>}
        <Subtitle className="mobile-camera__subtitle">{text}</Subtitle>
        <HelperText className="mobile-camera__helper">
          {t('place_document_inside_frame')}
        </HelperText>
        <StyledWebcam ref={ref} {...props} />
        <TakePhotoButton
          onClick={onScreenshot}
          iconSrc={cameraIcon}
          iconAlt="Take photo"
          className="mobile-camera__take-btn"
        />
      </Wrapper>,
      rootElement,
    );
  },
);

export {MobileWebcam};
