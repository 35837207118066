import styled, {css} from 'styled-components';
import {CustomIcon} from '@guestapp/ui';

const disabledOpacity = 0.4;
const checkBoxSize = {
  width: '24px',
  height: '24px',
};

type CheckboxWrapperProps = {
  disabled?: boolean;
};

export const ErrorMessage = styled.div`
  font-family: inherit;
  text-align: right;
  color: #f84b7a;
  font-size: 11px;
  position: absolute;
  right: 0;
  bottom: -20px;
`;

export const Content = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #152946;
`;
export const CheckboxWrapper = styled.div<CheckboxWrapperProps>`
  cursor: pointer;
  column-gap: 12px;
  text-align: left;
  min-width: 225px;
  display: flex;
  color: #161643;
  align-items: center;
  font-weight: 400;

  & div {
    width: '${checkBoxSize.width}';
    /* height: ${checkBoxSize.height}; */
  }

  ${props =>
    props.disabled &&
    css`
      opacity: ${disabledOpacity};
      cursor: not-allowed;
    `};
`;

export const AcceptIconImg = styled(CustomIcon)`
  position: absolute;
  user-select: none;
  right: 0;
`;

export const CheckedRoundCheckbox = styled.div`
  background-color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.primary
      : props.theme.colors.buttons.mainButtonBackground};
  border-radius: 50%;
  width: 14px;
  height: 14px;
`;

type BoxProps = {
  disabled?: boolean;
  checked?: boolean;
  round?: boolean;
};

export const Box = styled.div<BoxProps>`
  min-width: ${checkBoxSize.width};
  min-height: ${checkBoxSize.height};
  box-sizing: border-box;
  position: relative;
  text-align: center;
  transition: all 0.1s ease-in-out;
  border: ${props =>
    props.checked
      ? `2px solid ${props.theme.colors.branding.primary};` // there are the place to change the selected color
      : '2px solid #B1B5C4'}; // is the checkbox no selected
  /* border: ${props => (props.round ? '2px solid #e6e8ec;' : '2px solid #B1B5C4')}; */
  background-color: ${props =>
    props.checked
      ? props.theme.colors.branding.primary
      : '#F4F6F9'}; // there are the place to change the selected color, here, the else, is the checkbox no selected
  border-radius: ${props => (props.round ? '50%' : '6px')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  & rect {
    fill: ${props =>
      props.theme.colors.branding
        .primary}; // there are the place to change the selected color
  }
`;

export const Label = styled.span`
  user-select: none;
  color: ${props => props.theme.colors.branding.fontPrimary};
  font-weight: bold;
`;
