import styled, {css} from 'styled-components';
import OrderHistoryBox from '../OrderHistoryBox';
import {MainLoader} from '../common/loaders';

export const Wrapper = styled.div<{isLoading?: boolean}>`
  width: 100%;
  max-width: 672px;
  transition: opacity 0.3s ease-in-out;

  ${props =>
    props.isLoading &&
    css`
      opacity: 0.5;
    `}
`;

export const StyledOrderHistoryBox = styled(OrderHistoryBox)`
  margin-bottom: 24px;
`;

export const StyledLoader = styled(MainLoader)`
  margin: 100px auto 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;
