import styled from 'styled-components';
import Button from '../Button';
import {MobileSelectModal} from '../Select/styled';

export const ButtonConfirm = styled(Button)`
  /* margin-top: 30px; */
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledMobileSelectModal = styled(MobileSelectModal)`
  & > div {
    height: auto;
  }
`;

export const ContentSheet = styled(Content)`
  /* padding-top: 35px; */
`;

export const Text = styled.p`
  width: 300px;
  text-align: center;
  margin-bottom: 24px;
`;

export const Overlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: #000;
  opacity: 0.2;
  left: 0;
  /* pointer-events: none; */
  z-index: 5;
`;

export const Title = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  margin: auto 0 16px;
  color: ${props => props.theme.colors.branding.fontPrimary};
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 600;
  font-family: ${props => props.theme.fonts.fontFamily.titles.font_name};
`;
