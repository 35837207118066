import styled from 'styled-components';
import {Button} from '@guestapp/ui';
import {device} from 'styled/device';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  width: 100%;
  gap: 16px;

  @media (min-width: ${device.tablet}) {
    margin-top: 48px;
  }
`;

export const TitleWrapper = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.36px;
  text-align: left;
  color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.fontPrimary : theme.colors.$p10};

  @media (min-width: ${device.tablet}) {
    font-size: 30px;
    font-weight: 500;
  }
`;

export const SubTitleWrapper = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.fontSecondary : theme.colors.$p10};

  @media (min-width: ${device.tablet}) {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const CustomBackButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 24px;

  & .button__left-icon {
    padding-right: 10px;
  }
`;

export const ContentForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: ${device.tablet}) {
    flex-direction: row;
    gap: 24px;
  }
`;

type FieldsProps = {
  rowsCount: number;
};

export const Fields = styled.div<FieldsProps>`
  display: grid;
  grid-column-gap: 50px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(${props => props.rowsCount}, auto);
  text-align: center;
  max-width: 778px;
  position: relative;

  @media (max-width: ${device.tabletG}) {
    grid-template-columns: 1fr;
  }
  @media (max-width: ${device.laptop}) {
    grid-column-gap: 10px;
  }

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    display: flex;
  }
`;
export const FormWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  @media only screen and (max-width: ${device.laptopL}) {
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
  }

  @media only screen and (max-width: ${device.tablet}) {
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
  }
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;

  @media (min-width: ${device.tablet}) {
    justify-content: start;
  }
`;

export const SubmitButton = styled.div`
  margin: 0 auto;
  width: 100%;

  & .submit-btn {
    width: 100%;
    margin: 0 auto;
  }
`;

export const StatusTitleModal = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.36px;
  text-align: center;
  color: #152946;
`;
