import {environments} from './configs';
import {LANGUAGE_CODES} from '@guestapp/core';

const IS_EMBEDDED_MODE = environments.REACT_APP_IS_EMBEDDED;

class ChekinProSettings {
  static get settings() {
    return window.ChekinProSettings || {};
  }

  static get isEmbedded() {
    return IS_EMBEDDED_MODE ?? false;
  }

  static get apiKey() {
    return ChekinProSettings.settings.apiKey ?? '';
  }

  static get reservationId() {
    return ChekinProSettings.settings.reservationId;
  }

  static get externalReservationId() {
    return ChekinProSettings.settings.externalId;
  }

  static get defaultLanguage() {
    if (IS_EMBEDDED_MODE) {
      return (
        ChekinProSettings.settings.defaultLanguage ||
        environments.REACT_APP_DEFAULT_LANGUAGE ||
        LANGUAGE_CODES.en
      );
    }

    return undefined;
  }

  static get styles() {
    return ChekinProSettings.settings.styles;
  }

  static get stylesLink() {
    return ChekinProSettings.settings.stylesLink;
  }

  static get externalId() {
    return ChekinProSettings.settings.externalId;
  }

  static get canShareRegistrationLink() {
    if (IS_EMBEDDED_MODE) {
      return ChekinProSettings.settings.canShareRegistrationLink;
    }
    return true;
  }

  static get canEditReservationDetails() {
    if (IS_EMBEDDED_MODE) {
      return ChekinProSettings.settings.canEditReservationDetails;
    }
    return true;
  }
}

export default ChekinProSettings;
