import {BackendModule, Services, InitOptions} from 'i18next';

interface ChunkedLocalePluginOptions {
  basePath?: string;
}

class LazyImport implements BackendModule {
  type: 'backend';
  private options: ChunkedLocalePluginOptions;

  constructor(
    private services: Services,
    options: ChunkedLocalePluginOptions,
    private i18nextOptions: InitOptions,
  ) {
    this.options = {basePath: 'locales', ...options};
  }

  init(
    services: Services,
    options: ChunkedLocalePluginOptions,
    i18nextOptions: InitOptions,
  ): void {
    this.services = services;
    this.options = options;
    this.i18nextOptions = i18nextOptions;
  }

  read(
    language: string,
    namespace: string,
    callback: (error: Error | null, data?: never) => void,
  ): void {
    import(`../../../../public/locales/${language}/${namespace}.json`)
      .then(data => callback(null, data))
      .catch(error => callback(error));
  }
}

// @ts-ignore
LazyImport.type = 'backend';

export {LazyImport};
