import React from 'react';
import {Path, useFormContext} from 'react-hook-form';
import {SelectOptionType, STANDARD_FIELD_TYPES} from '@guestapp/sdk';

type UseResetFieldIfTypeChangedProps<T> = {
  fieldName: T;
  type: STANDARD_FIELD_TYPES;
  active?: boolean;
};
function useResetFieldIfTypeChanged<T extends Record<string, string | SelectOptionType>>({
  fieldName,
  type,
}: UseResetFieldIfTypeChangedProps<Path<T>>) {
  const {getValues, resetField} = useFormContext<T>();
  const isSelectType = type === STANDARD_FIELD_TYPES.select;

  React.useEffect(
    function resetFieldIfValueIsNotCompatibleToType() {
      const currentValue = getValues()[fieldName];

      const isValueStringAndFieldSelectType = Boolean(
        typeof currentValue === 'string' && isSelectType,
      );

      const isValueOptionAndFieldTextType = Boolean(
        currentValue &&
          typeof currentValue === 'object' &&
          'value' in currentValue &&
          !isSelectType,
      );

      if (isValueStringAndFieldSelectType || isValueOptionAndFieldTextType) {
        resetField(fieldName);
      }
    },
    [resetField, isSelectType, getValues, fieldName],
  );
}

export {useResetFieldIfTypeChanged};
