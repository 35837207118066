import clsx from 'clsx';
import {Wrapper} from './styled';

type IntermittentDotProps = {
  size?: string | number;
  className?: string;
};
function IntermittentDot({size, className}: IntermittentDotProps) {
  return (
    <Wrapper size={size} className={clsx(className, 'intermittent-dot-loader')}>
      <div />
    </Wrapper>
  );
}

export {IntermittentDot};
