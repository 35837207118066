import React, {ComponentType} from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer, {VerticalSize} from 'react-virtualized-auto-sizer';
import {ListChildComponentProps} from 'react-window';
import {SelectProps} from '../../Select';
import {Menu} from './styled';

const INIT_OFFSET = 0;
const THRESHOLD = 5;

const plugLoadMoreFunction = () => null;

type VirtualizedListProps<T, V, L> = {
  children: ComponentType<ListChildComponentProps>;
  isMoreItemsLoading: boolean;
  itemSize: number;
  height: number | string;
  width: number | string;
  options: SelectProps<T, V, L>['options'];
  itemCount: number;
  loadMoreItems?: () => void;
  listAutoSizer?: boolean;
  mobileVersion?: boolean;
  canLoadMore?: boolean;
};
function VirtualizedList<T, V, L>({
  children,
  canLoadMore,
  isMoreItemsLoading,
  loadMoreItems,
  listAutoSizer,
  mobileVersion,
  itemSize,
  height,
  width,
  options,
  itemCount,
}: VirtualizedListProps<T, V, L>) {
  const scrollOffsetRef = React.useRef(0);
  const loadMore =
    isMoreItemsLoading || !canLoadMore
      ? plugLoadMoreFunction
      : loadMoreItems || plugLoadMoreFunction;
  const isItemLoaded = (index: number) => Boolean(options?.[index]);

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMore}
      threshold={THRESHOLD}
    >
      {({onItemsRendered, ref}) => {
        return listAutoSizer ? (
          <AutoSizer ref={ref} disableWidth>
            {({height}: VerticalSize) => (
              <Menu
                onScroll={({scrollOffset}) => {
                  scrollOffsetRef.current = scrollOffset;
                }}
                onItemsRendered={onItemsRendered}
                initialScrollOffset={
                  isMoreItemsLoading ? INIT_OFFSET : scrollOffsetRef.current
                }
                itemCount={itemCount}
                itemSize={itemSize}
                height={height}
                width={width}
                mobileVersion={mobileVersion}
                className="infinite-scroll-select__menu"
              >
                {children}
              </Menu>
            )}
          </AutoSizer>
        ) : (
          <Menu
            ref={ref}
            onScroll={({scrollOffset}) => {
              scrollOffsetRef.current = scrollOffset;
            }}
            onItemsRendered={onItemsRendered}
            initialScrollOffset={
              isMoreItemsLoading ? INIT_OFFSET : scrollOffsetRef.current
            }
            itemCount={itemCount}
            width={width}
            itemSize={itemSize}
            height={height}
            mobileVersion={mobileVersion}
            className="infinite-scroll-select__menu"
          >
            {children}
          </Menu>
        );
      }}
    </InfiniteLoader>
  );
}

export {VirtualizedList};
