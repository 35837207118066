import {useMutation, useQueryClient} from '@tanstack/react-query';
import {FieldsErrorResponse, ReservationPayload, ShortReservation} from '@guestapp/sdk';
import {QUERY_CACHE_KEYS} from '../utils/constants';
import {useChekinSDK} from '../context/ChekinSDK';
import {useReservation} from 'context/reservation';

type useMutateReservationProps = {
  onSuccess?: (data: ShortReservation) => void;
  onError?: (error: FieldsErrorResponse) => void;
  onSettled?: () => void;
};
function useMutateReservation({
  onSuccess,
  onError,
  onSettled,
}: useMutateReservationProps = {}) {
  const queryClient = useQueryClient();
  const {updateReservation} = useChekinSDK();
  const {data} = useReservation();
  const reservationId = data?.id;
  return useMutation<
    ShortReservation,
    FieldsErrorResponse,
    {
      payload: ReservationPayload;
    }
  >(({payload}) => updateReservation(reservationId as string, payload), {
    onMutate: async () => {
      await queryClient.cancelQueries([QUERY_CACHE_KEYS.reservation]);
      await queryClient.cancelQueries([QUERY_CACHE_KEYS.guestGroup]);
    },
    onSuccess: data => {
      onSuccess?.(data);
      queryClient.setQueryData([QUERY_CACHE_KEYS.reservation], data);
      queryClient.refetchQueries([QUERY_CACHE_KEYS.guestGroup]);
    },
    onError,
    onSettled,
  });
}

export {useMutateReservation};
