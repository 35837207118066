// fetchJSON is bundled wrapper around fetch which simplifies working
// with JSON API:
//   * Automatically adds Content-Type: application/json to request headers
//   * Parses response as JSON when Content-Type: application/json header is
//     present in response headers
//   * Converts non-ok responses to errors
import {configureRefreshFetch, fetchJSON} from 'refresh-fetch';
import ChekinAPI from '../../ChekinAPI';
import customFetch from './customFetch';
import {
  getTokenFromStorage,
  persistTokenToLocalStorage,
  removeTokenFromLocalStorage,
} from '../../utils';
import {buildRequestHeaders, CORS_ERRORS, getURL} from '../apiClient/utils';
import {AuthApi} from '../AuthApi';
import {RequestOptions} from '../apiClient/types';

// Add token to the request headers
const fetchJSONWithToken = (
  url: string,
  {publicAccess, headers, ...options}: RequestOptions = {},
) => {
  const builtHeaders = buildRequestHeaders(headers, publicAccess);

  return customFetch(url, {...options, headers: builtHeaders});
};
// Decide whether this error returned from API means that we want
// to try refreshing the token. error.response contains the fetch Response
// object, error.body contains the parsed JSON response body
const shouldRefreshToken = (error: {response: Response; message?: string}) => {
  if (error.message && CORS_ERRORS.includes(error.message)) {
    return true;
  }
  return error.response && error.response.status === 401;
};

const getNewTokenFromApiKey = async () => {
  const params = ChekinAPI.initParams;
  const body = JSON.stringify({
    apikey: params.apikey,
    resource_id: params.resourceId,
    external_resource_id: params.externalResourceId,
    resource_type: params.resourceType,
  });

  const result = await fetchJSON<{access_token: string}>(
    getURL(AuthApi.ENDPOINTS.apikeyAuth()),
    {
      method: 'POST',
      headers: buildRequestHeaders(null, true),
      body,
    },
  );

  return result.body?.access_token;
};

const getNewTokenFromOld = async () => {
  const storedToken = getTokenFromStorage();
  const body = JSON.stringify({
    token: storedToken,
  });
  const result = await fetchJSON(getURL(AuthApi.ENDPOINTS.refresh()), {
    body,
    method: 'POST',
  });

  return (result.body as {token: string})?.token;
};

const refreshToken = async () => {
  const isChekinConsumer = ChekinAPI.initParams?.isChekinConsumer;

  try {
    const updatedToken = isChekinConsumer
      ? await getNewTokenFromOld()
      : await getNewTokenFromApiKey();

    persistTokenToLocalStorage(updatedToken);
  } catch (err) {
    removeTokenFromLocalStorage();
  }
};

const refreshFetch = configureRefreshFetch({
  shouldRefreshToken,
  refreshToken,
  fetch: fetchJSONWithToken,
});

export {refreshFetch};
