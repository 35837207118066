import styled from 'styled-components';
import RollUpButton from '../common/RollUpButton';
import {device} from '../../styled/device';
import {ComputedBox} from '@guestapp/ui';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 672px;
  padding: 16px 16px 24px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: ${props => props.theme.colors.branding.boxesModals};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  margin-bottom: 24px;

  @media (min-width: ${device.mobileXL}) {
    padding: 24px 24px 32px 24px;
  }
`;

export const Header = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
  align-self: stretch;

  & h1 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: initial;
  }

  & > div > div {
    color: #777e91;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const StyledRollUpButton = styled(RollUpButton)`
  margin-left: auto;

  @media (min-width: ${device.mobileXL}) {
    display: none;
  }
`;

export const SummaryColumnTitle = styled.h2`
  display: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
`;

export const SummaryColumn = styled.div.attrs({className: 'order-history-box__sum-col'})`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 0;

  @media (min-width: ${device.mobileXL}) {
    gap: 16px;

    & ${SummaryColumnTitle} {
      display: block;
    }
  }
`;

export const HelperText = styled.div`
  display: flex;
  font-size: 12px;
  color: #777e91;

  img {
    margin-right: 11px;
    top: 2px;
    position: relative;
  }

  @media (min-width: ${device.mobileXL}) {
    display: none;
  }
`;

export const DesktopHelperText = styled(HelperText)`
  display: none;
  padding-top: 20px;

  img {
    top: 0;
  }

  @media (min-width: ${device.mobileXL}) {
    display: flex;
  }
`;

export const ContentWrapper = styled(ComputedBox)`
  width: 100%;

  & > div {
    min-width: 0;
  }

  @media (min-width: ${device.mobileXL}) {
    transition: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;

  &:before {
    content: '';
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    align-self: stretch;
  }

  & ${SummaryColumn}:first-child {
    order: -1;
    flex: 1;
  }

  @media (min-width: ${device.mobileXL}) {
    flex-direction: row;
    padding-top: 24px;
  }
`;

export const PaymentItem = styled.div`
  & div:nth-child(1) {
    font-size: 14px;
    font-weight: 400;
    word-wrap: break-word;

    @media (min-width: ${device.mobileXL}) {
      font-size: 16px;
    }
  }

  & div:nth-child(2) {
    color: #777e91;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const SummaryRow = styled.div.attrs({className: 'order-history-box__sum-row'})`
  display: flex;
  justify-content: space-between;
  gap: 24px;

  & span:nth-child(1) {
    font-size: 16px;
    font-weight: 500;

    @media (min-width: ${device.mobileXL}) {
      font-weight: 400;
    }
  }

  & span:nth-child(2) {
    font-size: 16px;
    font-weight: 700;
  }
`;
