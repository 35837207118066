import styled from 'styled-components';
import {device} from '@guestapp/ui/src/utils/device';
import {ReactComponent as LanguageIcon} from 'assets/icons/language-icon.svg';
import {ReactComponent as LockIcon} from 'assets/icons/lock-icon.svg';

type WrapperProps = {
  hasSubheader?: boolean;
};

export const PageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
`;

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  /* height: 100%; */
  padding: 0 20px 65px;
  min-height: ${props =>
    props.hasSubheader ? 'calc(100vh - 166px)' : 'calc(100vh - 64px)'};
  z-index: 1;
  align-items: start;

  @media (min-width: ${device.tablet}) {
    padding: 0px 80px;
    height: auto;
    min-height: auto;
  }
`;

export const HeaderWrapper = styled.div<{visible?: boolean}>`
  width: 100%;
  z-index: 5;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: ${props => (props.visible ? 'sticky' : 'initial')};
  top: 0;
  height: 64px;
  background: ${({theme}) =>
    theme.hasTemplate ? theme.colors.navigationMenu.backgroundMenu : theme.colors.$p6};
  box-shadow: 0px 2px 10px 0px rgba(147, 150, 174, 0.25);

  @media (min-width: ${device.tablet}) {
    display: none;
    margin-bottom: 20px;
  }
`;

export const Container = styled.div`
  margin: auto;
  display: flex;
  width: 100%;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  background: ${({theme}) =>
    theme.hasTemplate ? theme.colors.navigationMenu.backgroundMenu : theme.colors.$p6};
  @media (min-width: ${device.tablet}) {
    padding: 0 64px;
  }
`;

export const HeaderTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.36px;
  text-align: left;
  width: 100%;
  color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.navigationMenu.menuItemsText : theme.colors.$p10};

  @media (min-width: ${device.tabletSubHeader}) {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  display: flex;

  & img {
    margin-right: 16px;
    width: 48px;
    height: 48px;
    object-fit: contain;
    border-radius: 50%;
  }

  @media (min-width: ${device.tablet}) {
    display: none;
  }
`;

export const Title = styled.div`
  width: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (min-width: ${device.tablet}) {
    width: 100%;
  }
`;

export const ContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  height: 64px;
  border-top: 1px solid rgba(21, 41, 70, 0.2);
  padding: 24px;
  gap: 24px;
  z-index: 5;

  @media (min-width: ${device.tablet}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 24px 64px;
    gap: 48px;
    z-index: initial;
  }
`;

export const ContentButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  gap: 5px;
  font-size: 16px;
  color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.fontSecondary : theme.colors.$p2};
  cursor: pointer;
  @media (min-width: ${device.tablet}) {
    gap: 12px;
  }
`;

export const StyledLanguageIcon = styled(LanguageIcon)`
  path {
    fill: ${props =>
      props.theme.hasTemplate ? props.theme.colors.branding.fontSecondary : props.color};
  }
`;

export const StyledLockIcon = styled(LockIcon)`
  path {
    fill: ${props =>
      props.theme.hasTemplate ? props.theme.colors.branding.fontSecondary : props.color};
  }
`;
