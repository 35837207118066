import React from 'react';
import {useTranslation} from 'react-i18next';
import {SelectRefType} from '../Select';
import {useLockScrollBody, useModalWithHistoryControls} from '../../../hooks';
import StyledIcon from '../StyledIcon';
import {
  QueriedInfiniteScrollSelect,
  QueriedInfiniteScrollSelectProps,
} from './QueriedInfiniteScrollSelect';
import {IconWrapper, MobileSelectModal} from '../Select/styled';

const ListSizes = {
  height: 400,
  itemSize: 46,
};

type MobileQueriedInfiniteScrollSelectProps<T, V, L> = QueriedInfiniteScrollSelectProps<
  T,
  V,
  L
>;

const MobileQueriedInfiniteScrollSelectInternal = <T, V, L>(
  props: MobileQueriedInfiniteScrollSelectProps<T, V, L>,
  ref: React.ForwardedRef<SelectRefType<T, V, L>>,
) => {
  const {onMenuOpen, onChange} = props;
  const {t} = useTranslation();
  const {isOpen, openModal, closeModal} = useModalWithHistoryControls({
    key: props.name || '',
  });

  useLockScrollBody(isOpen);

  const handleMenuOpen = () => {
    openModal();
    onMenuOpen?.();
  };

  const handleChange: QueriedInfiniteScrollSelectProps<T, V, L>['onChange'] = (
    option,
    info,
  ) => {
    closeModal();
    onChange?.(option, info);
  };

  return (
    <>
      <QueriedInfiniteScrollSelect
        ref={ref}
        {...props}
        onMenuOpen={handleMenuOpen}
        onFocus={handleMenuOpen}
        isSearchable={false}
        menuIsOpen={false}
        blockQuery
      />
      <MobileSelectModal
        visible={isOpen}
        onClose={closeModal}
        mode="mobile-slide"
        pressToTheTop
      >
        {isOpen && (
          <QueriedInfiniteScrollSelect
            ref={ref}
            {...props}
            menuIsOpen
            isMenuOpen
            isSearchable
            mobileVersion
            listAutoSizer
            defaultMenuIsOpen
            forcedSearchMode
            label={null}
            components={{LoadingIndicator: undefined, DropdownIndicator: null}}
            closeMenuOnSelect={false}
            controlShouldRenderValue={false}
            noOptionsMessage={() => t('no_matches_found')}
            listSizes={ListSizes}
            onChange={handleChange}
            minSearchQueryLength={0}
            leftIcon={
              <IconWrapper>
                <StyledIcon size={16} color={'#9F9FBF'} name={'magnifierGray'} />
              </IconWrapper>
            }
          />
        )}
      </MobileSelectModal>
    </>
  );
};

const MobileQueriedInfiniteScrollSelect = React.forwardRef(
  MobileQueriedInfiniteScrollSelectInternal,
) as <T, V, L>(
  props: QueriedInfiniteScrollSelectProps<T, V, L> & {
    ref?: React.ForwardedRef<SelectRefType<T, V, L>>;
  },
) => ReturnType<typeof MobileQueriedInfiniteScrollSelectInternal>;

export {MobileQueriedInfiniteScrollSelect};
