import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {PATHS} from 'Routes';
import {usePath} from 'hooks';
import {IncompleteGuestText, StyledButton} from './styled';

type IncompleteRegisteredGuestsStepProps = {
  current: number;
  total: number;
};
function IncompleteRegisteredGuestsStep({
  current,
  total,
}: IncompleteRegisteredGuestsStepProps) {
  const navigate = useNavigate();
  const {getFullPath} = usePath();
  const {t} = useTranslation();
  return (
    <>
      <IncompleteGuestText className="chekin-next-step-modal__sub-title chekin-missing-guests">
        <Trans
          i18nKey="you_have_registered_number_guest"
          values={{current: current, total: total}}
        >
          You have registered
          <b>
            {current} of {total} guests
          </b>
        </Trans>
      </IncompleteGuestText>
      <StyledButton
        label={t('register_next_guest')}
        primary
        onClick={() => navigate(getFullPath(PATHS.guestRegistration))}
      />
    </>
  );
}

export {IncompleteRegisteredGuestsStep};
