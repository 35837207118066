import React from 'react';
import {useTranslation} from 'react-i18next';
import {goToExternalLink} from 'pages/AddPersonalDataForm/utils';
import LanguageSelection from 'components/ModalSheets/LanguageSelection';
import {LanguageSelectionRef} from 'components/ModalSheets/LanguageSelection/LanguageSelection';
import {
  ContainerFooter,
  ContentButton,
  StyledLanguageIcon,
  StyledLockIcon,
} from '../styled';

function Footer() {
  const {t} = useTranslation();
  const languageSelectionModalRef = React.useRef<LanguageSelectionRef>(null);

  return (
    <ContainerFooter>
      <ContentButton
        onClick={() =>
          goToExternalLink(
            'https://chekin.com/en/generic-privacy-policy-of-accommodations/',
          )
        }
      >
        <StyledLockIcon color={'#777E91'} width="16" height="16" viewBox="0 0 16 16" />
        {t('privacy_policy')}
      </ContentButton>
      <ContentButton onClick={() => languageSelectionModalRef?.current?.open()}>
        <StyledLanguageIcon
          color={'#777E91'}
          width="16"
          height="16"
          viewBox="0 0 16 16"
        />
        {t('language')}
      </ContentButton>
      <LanguageSelection ref={languageSelectionModalRef} />
    </ContainerFooter>
  );
}

export {Footer};
