import {Template} from '@guestapp/sdk';
import {FontsData, guestappTheme, GuestappThemeProps} from 'styled/theme';

function buildCustomTheme(templateData: Template) {
  return {
    ...guestappTheme,
    hasTemplate: true,
    colors: {
      ...guestappTheme.colors,
      navigationMenu: {
        ...guestappTheme.colors.navigationMenu,
        backgroundMenu: templateData.navigation_background_color,
        selectedItemBackground: templateData.navigation_selected_item_background_color,
        menuItemsText: templateData.navigation_menu_items_text_color,
        selectedItemText: templateData.navigation_selected_item_text_color,
      },
      gradients: {
        ...guestappTheme.colors.gradients,
      },
      branding: {
        ...guestappTheme.colors.branding,
        primary: templateData.brand_primary_color,
        icons: templateData.brand_icons_color,
        fontPrimary: templateData.font_primary_color,
        fontSecondary: templateData.font_secondary_color,
        backgroundMain: templateData.background_border_main_color,
        backgroundSecondary: templateData.background_border_secondary_color,
        boxesModals: templateData.background_border_boxes_and_modals_color,
      },
      buttons: {
        ...guestappTheme.colors.buttons,
        mainButtonBackground: templateData.main_button_background_color,
        mainButtonTextBackground: templateData.main_button_text_color,
        secondaryButtonTextAndBorder:
          templateData.secondary_button_text_and_borders_color,
        textButton: templateData.text_button_text_color,
      },
      splash: {
        ...guestappTheme.colors.splash,
        animationColor: templateData.splash_screen_animation_color,
        backgroundColor: templateData.splash_screen_background_color,
      },
    },
    buttons: {
      ...guestappTheme.buttons,
      typeRadioButton: templateData.buttons_style,
    },
    fonts: {
      sizes: {
        ...guestappTheme.fonts.sizes,
      },
      fontFamily: {
        ...guestappTheme.fonts.fontFamily,
      },
      fontWeight: {
        ...guestappTheme.fonts.fontWeight,
      },
    },
  };
}

function getTemplateFonts(
  prev: GuestappThemeProps | null,
  customFonts: {[key: string]: FontsData},
) {
  const newFonts = {
    ...prev?.fonts,
    fontFamily: {
      ...(prev?.fonts?.fontFamily || {}),
    },
  };

  if (customFonts.titles_font && customFonts.titles_font.font_name) {
    newFonts.fontFamily.titles = {
      ...(customFonts.titles_font || {}),
      name: customFonts.titles_font.font_name,
    };
  }

  if (customFonts.body_font && customFonts.body_font.font_name) {
    newFonts.fontFamily.body = {
      ...(customFonts.body_font || {}),
      name: customFonts.body_font.font_name,
    };
  }

  const newData = {...prev, fonts: newFonts} as GuestappThemeProps;

  return newData;
}

export {buildCustomTheme, getTemplateFonts};
