import i18n, {InitOptions} from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import {preloadAndSetDayJsLocale, LANGUAGE_CODES} from '@guestapp/core';
import {LazyImport} from './plugins/lazyImportPlugin';
import ChekinProSettings from '../../ChekinProSettings';

const defaultLanguages = {
  es: [LANGUAGE_CODES.ca],
};

i18n.on('languageChanged', function (nextLanguage) {
  const fallbackLanguage = Object.keys(defaultLanguages).find(key => {
    return defaultLanguages[key as keyof typeof defaultLanguages].includes(
      nextLanguage as LANGUAGE_CODES,
    );
  });

  if (fallbackLanguage) {
    i18n.changeLanguage(fallbackLanguage);
    return;
  }
});

const initParams: InitOptions = {
  lng: ChekinProSettings.defaultLanguage,
  fallbackLng: LANGUAGE_CODES.en,
  detection: {
    order: ['querystring', 'localStorage', 'navigator'],
  },
  load: 'languageOnly',
  interpolation: {
    escapeValue: false,
  },
  preload: [LANGUAGE_CODES.it, LANGUAGE_CODES.es, LANGUAGE_CODES.de],
  returnNull: false,
  returnEmptyString: false,
};

i18n
  .use(LazyImport)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(initParams, () => {
    preloadAndSetDayJsLocale(i18n.language);
  });

export {i18n};
