import styled from 'styled-components';
import {device} from '../../../styled/device';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    margin-left: 13px;
    margin-top: -4px;
    color: ${props => props.theme.colors.$n5};
    font-weight: ${props => props.theme.fonts.fontWeight.bold};
    font-size: ${props => props.theme.fonts.sizes.small};
    text-transform: uppercase;
    opacity: 0.5;

    @media (max-width: ${device.tablet}) {
      font-size: ${props => props.theme.fonts.sizes.large};
    }
  }
`;
