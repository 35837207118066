import styled, {css} from 'styled-components';
import {device} from 'styled/device';

export enum CounterSize {
  S,
  L,
}

export const getIconSize = (size?: CounterSize) => {
  if (size === CounterSize.S) {
    return 16;
  }
  return 24;
};

const getCustomProperties = (size?: CounterSize) => {
  if (size === CounterSize.S) {
    return css`
      --counter-icon-size: 16px;
      --counter-button-size: 24px;
      --counter-border-radius: 8px;
      --counter-container-gap: 16px;
      --counter-font-size: 14px;
      --counter-line-height: 24px;
    `;
  }
  return css`
    --counter-icon-size: 24px;
    --counter-button-size: 40px;
    --counter-border-radius: 10px;
    --counter-container-gap: 24px;
    --counter-font-size: 20px;
  `;
};

type ContainerProps = {
  size?: CounterSize;
};
export const Container = styled.div<ContainerProps>`
  ${props => getCustomProperties(props.size)};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  border-radius: 20px;
  width: 150px;
  height: 100%;
  position: relative;
  gap: var(--counter-container-gap);
`;

type ButtonProps = {
  hasError?: boolean;
  disabled?: boolean;
  size?: CounterSize;
};
export const ButtonStepper = styled.button<ButtonProps>`
  padding: 0;
  position: relative;
  flex-shrink: 0;
  width: var(--counter-button-size);
  height: var(--counter-button-size);
  border-radius: var(--counter-border-radius);
  border: 1px solid
    ${props => (props.hasError ? '#f84b7a' : props.theme.colors.branding.primary)};
  background-color: transparent;
  color: ${props => props.theme.colors.branding.primary};

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${props => props.theme.colors.$p0};
    transition: fill 0.2s;

    @media (max-width: ${device.tablet}) {
      width: 27px;
      height: 27px;
    }
  }

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.7;
    `}
`;

export const Number = styled.span<ButtonProps>`
  flex-grow: 1;
  text-align: center;
  font-weight: 700;
  line-height: var(--counter-line-height);
  font-size: ${props =>
    props.theme.hasTemplate ? 'inherit' : 'var(--counter-font-size)'};
  color: ${props =>
    props.hasError
      ? '#f84b7a'
      : props.theme.hasTemplate
      ? props.theme.colors.branding.fontPrimary
      : '#152946'};
  user-select: none;

  ${({disabled}) =>
    disabled &&
    css`
      opacity: 0.7;
    `}
`;
