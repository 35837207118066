import React from 'react';
import {FontsData} from 'styled/theme';
import {Template} from '@guestapp/sdk';
import {useTimeoutRef} from '@guestapp/core';

async function getFontUrl(font: FontsData) {
  const downloadLink = font?.font_file_download_link as string;
  const response = await fetch(downloadLink);
  const blob = await response.blob();

  return {
    src: URL.createObjectURL(blob),
    name: font?.font_name,
    format: font?.font_format,
  };
}

async function processFonts(fontsData: FontsData[]) {
  const fontBlobUrls = await Promise.all(fontsData.map(getFontUrl));
  let fontFaces = '';
  fontBlobUrls.forEach(fontBlobUrl => {
    const src = fontsData.find(
      font => font?.font_name === fontBlobUrl.name,
    )?.font_file_download_link;
    fontFaces += `
          @font-face {
            font-family: ${fontBlobUrl.name};
            src: url(${src}); format('${fontBlobUrl.format}');
          }
        `;
  });
  return fontFaces;
}

type useCustomFontsProps = {
  onSuccess?: (data: {[key: string]: FontsData}) => void;
};

const defaultProps = {
  onSuccess: undefined,
};
function useCustomFonts({onSuccess}: useCustomFontsProps = defaultProps) {
  const [fontFaces, setFontFaces] = React.useState('');
  const timeoutRef = useTimeoutRef();

  const [fontsStatus, setFontsStatus] = React.useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle');

  const fetchFonts = React.useCallback(
    async (templateData: Template) => {
      const customFonts = [templateData?.titles_font, templateData?.body_font];
      setFontsStatus('loading');
      const builtFontFaces = await processFonts(customFonts);
      setFontFaces(builtFontFaces ?? '');
      onSuccess?.({
        titles_font: templateData?.titles_font,
        body_font: templateData?.body_font,
      });
      timeoutRef.current = setTimeout(() => {
        setFontsStatus('success');
      }, 600);
    },
    [onSuccess, timeoutRef],
  );

  return {fetchFonts, fontsStatus, fontFaces};
}

export {useCustomFonts};
