import React from 'react';
import {NavLinks} from './useNavigationLinks';
import {PATHS} from 'Routes';
import {useSummary} from 'context/summary';
import {useSchema} from 'context/guest';
import {usePaymentsCart} from './payments';
import {usePath} from './common';
import {useLocation, useMatch, useNavigate} from 'react-router-dom';
import {useNavigationCustom} from './useNavigationCustom';
import {IconNames} from '@guestapp/ui';
import {useFetchLockConditions} from './remoteAccess';
import {useGuestGroup} from './useGuestGroup';
import {useBuildNavigationItems} from './useBuildNavigationItems';

type SideBarLinks = {
  [key: string]: {
    label: NavLinks;
    selected: boolean;
    disabled: boolean;
    iconName: IconNames;
    onClick: () => void;
    extraData?: string;
  };
};

function useSideBarLinks() {
  const {arePaymentsAvailable, data} = useSummary();
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {getFullPath, getMainPath} = usePath();
  const {currentPage} = useNavigationCustom();
  const {data: locksData} = useFetchLockConditions({
    enabled: Boolean(data?.is_remote_access_enabled),
  });
  const {
    identityVerificationDetails: {enabled: ivEnabled},
  } = useSchema();
  const {guestGroup} = useGuestGroup();
  const {getPaymentPath} = useBuildNavigationItems();
  const isRemoteAccessEnabled =
    data?.is_remote_access_enabled &&
    locksData?.is_show_keys_enabled &&
    locksData?.is_feature_active;
  const {paymentsCart} = usePaymentsCart({refetchOnMount: false});
  const isHomeView = useMatch(getMainPath(PATHS.home));
  const isPaymentsView = pathname.includes(PATHS.payments.main);
  const isRemoteAccessView = useMatch(getMainPath(PATHS.remoteAccess));
  const hasNoGuests = guestGroup?.added_number_of_guests === 0;

  const getRegistrationPath = React.useCallback(() => {
    return ivEnabled
      ? hasNoGuests
        ? PATHS.verification.main
        : PATHS.guestRegistration
      : hasNoGuests
      ? PATHS.addPersonalDataForm
      : PATHS.guestRegistration;
  }, [ivEnabled, hasNoGuests]);

  const mainLinks = React.useMemo(() => {
    const links: SideBarLinks = {
      [NavLinks.home]: {
        label: NavLinks.home,
        selected: Boolean(isHomeView),
        disabled: false,
        iconName: 'homeSidebar',
        onClick: () => navigate(getFullPath(PATHS.home)),
      },
      [NavLinks.onlineChekin]: {
        label: NavLinks.onlineChekin,
        selected: !isHomeView && !isPaymentsView && !isRemoteAccessView,
        disabled: false,
        iconName: 'onlineChekinSidebar',
        onClick: () => navigate(getFullPath(getRegistrationPath())),
      },
      // [NavLinks.homebook]: {
      //   label: NavLinks.homebook,
      //   selected: false,
      //   disabled: false,
      //   iconName: 'locationSidebar',
      //   path: 'adsfasfas',
      // },
      // [NavLinks.paymentCart]: {
      //   label: NavLinks.paymentCart,
      //   selected: false,
      //   disabled: false,
      //   iconName: 'shoppingCart',
      //   path: getFullPath(PATHS.payments.main),
      // },
    };
    if (isRemoteAccessEnabled) {
      links[NavLinks.virtualKeys] = {
        label: NavLinks.virtualKeys,
        selected: Boolean(isRemoteAccessView),
        disabled: false,
        iconName: 'keySidebar',
        onClick: () => navigate(getFullPath(PATHS.remoteAccess)),
      };
    }
    if (arePaymentsAvailable && paymentsCart?.pay_now_payments) {
      links[NavLinks.paymentCart] = {
        label: NavLinks.paymentCart,
        selected: isPaymentsView,
        disabled: false,
        iconName: 'shoppingCart',
        onClick: () =>
          navigate(getFullPath(getPaymentPath()), {state: {from: currentPage}}),
      };

      if (paymentsCart?.pay_now_payments?.length) {
        links[NavLinks.paymentCart]['extraData'] =
          paymentsCart?.pay_now_payments?.length.toString();
      }
    }

    return links;
  }, [
    isHomeView,
    isPaymentsView,
    isRemoteAccessView,
    isRemoteAccessEnabled,
    arePaymentsAvailable,
    paymentsCart?.pay_now_payments,
    navigate,
    getFullPath,
    currentPage,
    getRegistrationPath,
    getPaymentPath,
  ]);

  const navItems = React.useMemo(() => {
    return Object.values(mainLinks);
  }, [mainLinks]);

  return {navItems};
}

export {useSideBarLinks};
