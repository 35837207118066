import {imageDataRGBA} from 'stackblur-canvas';
import {IV_DOCUMENT_IMAGE_TYPES} from 'utils/constants';

const getHelperTextKey = (type?: IV_DOCUMENT_IMAGE_TYPES, hasTwoSides?: boolean) => {
  switch (type) {
    case IV_DOCUMENT_IMAGE_TYPES.front:
      return hasTwoSides
        ? 'choose_upload_type_front_side_text'
        : 'choose_upload_type_data_page_text';
    case IV_DOCUMENT_IMAGE_TYPES.back:
      return 'choose_upload_type_back_side_text';
    default:
      return null;
  }
};

function getOvalRatio(canvasWidth: number) {
  return canvasWidth > 1000 ? 4.5 : 2.5;
}

type BlurPhotoProps = {
  photo: string;
};

type DrawCanvasProps = {
  ctx: CanvasRenderingContext2D;
  img: HTMLImageElement;
  canvasWidth: number;
  canvasHeight: number;
};

const applyBlurEffect = ({ctx, img, canvasWidth, canvasHeight}: DrawCanvasProps) => {
  ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
  const imageData = ctx.getImageData(0, 0, canvasWidth, canvasHeight);
  imageDataRGBA(imageData, 0, 0, canvasWidth, canvasHeight, 10);
  ctx.putImageData(imageData, 0, 0);
};

const setCanvasElementOnDom = (width: number, height: number) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  document.body.appendChild(canvas);
  return canvas;
};

type CreateOvalShapeProps = Omit<DrawCanvasProps, 'img'>;
const createOvalShape = ({ctx, canvasWidth, canvasHeight}: CreateOvalShapeProps) => {
  ctx.save();
  ctx.beginPath();
  const centerX = canvasWidth / 2;
  const centerY = canvasHeight / 2;
  const radiusX = canvasWidth / getOvalRatio(canvasWidth);
  const radiusY = canvasHeight / 2.5;
  ctx.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);
  ctx.clip();
  return {centerX, centerY, radiusX, radiusY};
};

const drawImageInOval = ({ctx, img, canvasWidth, canvasHeight}: DrawCanvasProps) => {
  ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
  ctx.restore();
};

type CreateDashBorderProps = Pick<DrawCanvasProps, 'ctx'> & {
  centerX: number;
  centerY: number;
  radiusX: number;
  radiusY: number;
};
const createDashBorder = ({
  ctx,
  centerX,
  centerY,
  radiusX,
  radiusY,
}: CreateDashBorderProps) => {
  ctx.beginPath();
  ctx.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);
  ctx.strokeStyle = 'rgb(50, 215, 190)';
  ctx.lineWidth = 5;
  ctx.setLineDash([10, 10]);
  ctx.stroke();
  ctx.setLineDash([]);
};

const blurSelfiePhoto = async ({photo}: BlurPhotoProps): Promise<string | undefined> => {
  if (photo) {
    const img = new Image();

    return new Promise((resolve, reject) => {
      img.onload = () => {
        const canvasWidth = img.naturalWidth;
        const canvasHeight = img.naturalHeight;
        const canvas = setCanvasElementOnDom(canvasWidth, canvasHeight);
        const ctx = canvas.getContext('2d');

        if (ctx && canvas) {
          applyBlurEffect({ctx, img, canvasWidth, canvasHeight});
          const {centerX, centerY, radiusX, radiusY} = createOvalShape({
            ctx,
            canvasWidth,
            canvasHeight,
          });
          drawImageInOval({ctx, img, canvasWidth, canvasHeight});
          createDashBorder({ctx, centerX, centerY, radiusX, radiusY});
          const dataUrl = canvas.toDataURL('image/jpeg');
          document.body.removeChild(canvas);
          resolve(dataUrl);
        } else {
          document.body.removeChild(canvas);
          reject('Canvas or context is not available');
        }
      };

      img.onerror = () => {
        reject('Generating canvas or context is not available');
      };

      img.src = photo;
    });
  }
  return undefined;
};

export {getHelperTextKey, blurSelfiePhoto};
