function resetTimeInDate(date?: string | Date) {
  if (!date) {
    return;
  }

  if (date && typeof (date === 'string' || date instanceof Date)) {
    const updatedDate = date instanceof Date ? date : new Date(date);
    updatedDate.setHours(0, 0, 0, 0);
    return updatedDate;
  }

  return new Date(date);
}

export {resetTimeInDate};
