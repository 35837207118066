import React from 'react';
import {useTranslation} from 'react-i18next';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {PATHS} from 'Routes';
import {usePath} from '../../../hooks';
import Tabs, {TAB_TYPES} from 'components/common/Tabs';
import {MyCart} from 'components/MyCart/MyCart';
import {OrderHistory} from 'components/OrderHistory/OrderHistory';
import {AllianzAddedDealsToCartToast} from 'pages/Allianz/components';
import {StyledPage} from './styled';

function PaymentsView() {
  const {t} = useTranslation();
  const {getFullPath} = usePath();
  const navigate = useNavigate();

  const items = React.useMemo(
    () => [
      {
        id: 'cart',
        type: TAB_TYPES.link,
        path: getFullPath(PATHS.payments.main),
        label: t('my_cart'),
        selected: true,
        end: true,
      },
      {
        id: 'order_history',
        type: TAB_TYPES.link,
        path: getFullPath(PATHS.payments.ordersHistory),
        label: t('order_history'),
        selected: false,
      },
    ],
    [getFullPath, t],
  );

  return (
    <StyledPage
      isSubHeaderVisible
      onBackButtonClick={() => navigate(getFullPath(PATHS.home))}
      title={t('payment')}
      navBarTitle={t('booking_payments')}
      className="payments-view"
    >
      <Tabs items={items} />
      <Routes>
        <Route index element={<MyCart />} />
        <Route path={PATHS.payments.ordersHistory} element={<OrderHistory />} />
      </Routes>
      <AllianzAddedDealsToCartToast />
    </StyledPage>
  );
}

export {PaymentsView};
