import React from 'react';
import {StripeError} from '@stripe/stripe-js';
import {CardNumberElement, useElements, useStripe} from '@stripe/react-stripe-js';

const PaymentErrorMessage = 'Unable to confirm card payment. ';

function useStripeCardElement(onError: (error: StripeError | string) => void) {
  const elements = useElements();
  const stripe = useStripe();

  const confirmPaymentIntent = React.useCallback(
    async (clientSecret: string, billingDetails?: Record<string, unknown>) => {
      if (!stripe) {
        const errorMsg = PaymentErrorMessage + 'Stripe instance is missing.';
        onError(errorMsg);
        // Sentry.captureMessage(errorMsg);
        return;
      }

      const cardElement = elements?.getElement(CardNumberElement);

      if (!cardElement) {
        const errorMsg = PaymentErrorMessage + 'Card element is missing.';
        onError(errorMsg);
        // Sentry.captureMessage(errorMsg);
        return;
      }

      const response = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: billingDetails,
        },
      });

      if (response.error) {
        onError(response.error);
      }

      return response;
    },
    [elements, onError, stripe],
  );

  return {
    confirmPaymentIntent,
  };
}

export {useStripeCardElement};
