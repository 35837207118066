import React from 'react';
import {components, ControlProps, GroupBase} from 'react-select';
import {SelectOptionType} from '@guestapp/sdk';
import {ErrorMessage, Fieldset} from '@guestapp/ui';
import {SearchIcon, Wrapper} from './styled';

const Control = <T, V, L>(
  props: ControlProps<
    SelectOptionType<T, V, L>,
    false,
    GroupBase<SelectOptionType<T, V, L>>
  >,
) => {
  const {
    label,
    name,
    error,
    invalid,
    isDisabled,
    clearValue,
    forcedSearchMode,
    hideControl,
  } = props.selectProps;
  const isSearchableMode =
    (props.isFocused && props.selectProps.isSearchable) || forcedSearchMode;

  const leftIconElement = isSearchableMode ? (
    <SearchIcon name="search" color="#9F9FBF" className="select__search-icon" />
  ) : (
    props.selectProps.leftIcon
  );

  const hasValue = props.getValue().length > 0;

  if (hideControl) return null;

  return (
    <Wrapper
      tabIndex={0}
      className="select__control-wrapper"
      invalid={invalid || Boolean(error)}
      disabled={isDisabled}
      hasValue={hasValue}
      isFocused={props.isFocused}
      onClick={() => {
        if (props.getValue().length > 0) {
          if (clearValue && name) {
            clearValue(name);
          }
        }
      }}
    >
      <components.Control {...props}>
        {leftIconElement}
        {props.children}
        <Fieldset
          label={label}
          invalid={!!error}
          isFocused={props.isFocused}
          isEmpty={props.selectProps?.empty}
          isActivated={!props.selectProps?.empty || props.isFocused}
        />
      </components.Control>
      {error && (
        <ErrorMessage data-testid={`${name}-error`}>{error as string}</ErrorMessage>
      )}
    </Wrapper>
  );
};

export {Control};
