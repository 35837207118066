import React from 'react';
import {InternalFieldsetStyled, Label, Legend} from './styled';

type FieldsetProps = {
  isActivated: boolean;
  isFocused: boolean;
  label?: string | React.ReactNode | null;
  legend?: string;
  isEmpty?: boolean;
  invalid?: boolean;
  htmlFor?: string;
  onClick?: () => void;
  classNames?: string;
};
function Fieldset({
  isActivated,
  isFocused,
  isEmpty,
  invalid,
  label,
  legend,
  onClick,
  htmlFor,
  classNames,
  ...props
}: FieldsetProps) {
  return (
    <>
      <Label
        htmlFor={htmlFor}
        isEmpty={isEmpty}
        isFocused={isFocused}
        onClick={onClick}
        isInvalid={invalid}
        className="fieldset__label"
      >
        {label}
      </Label>
      <InternalFieldsetStyled
        invalid={invalid}
        isFocused={isFocused}
        className="fieldset"
        active={isActivated}
        {...props}
      >
        <Legend
          hidden={!label}
          active={isActivated}
          isEmpty={isEmpty}
          className="fieldset__legend"
        >
          {Boolean(legend || typeof label === 'string') && <span>{legend || label}</span>}
        </Legend>
      </InternalFieldsetStyled>
    </>
  );
}

export {Fieldset};
