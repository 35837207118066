import React from 'react';
import {useTranslation, Trans} from 'react-i18next';
import clsx from 'clsx';
import {AllianzPlansTypes} from '../constants';
import {PlanInfoBoxProps} from '../types';
import {AllianzPlanDetailsModal} from './';
import {useModalControls, usePaymentSettings} from 'hooks';
import Switch from 'components/common/Switch';
import {CustomIcon} from '@guestapp/ui';
import {
  Item,
  PlanFeaturesList,
  PlanMainInfo,
  PlanTitlePrice,
  Price,
  Title,
  Text,
  Wrapper,
  MoreInformationContainer,
  Box,
  HeaderText,
} from './styled';

function PlanInfoBox({title, price, isNotPlan, perPerson, children}: PlanInfoBoxProps) {
  const {t} = useTranslation();
  const {currencySymbol} = usePaymentSettings();
  const iconName = isNotPlan ? 'allianzBlockShieldIcon' : 'allianzShieldIcon';

  return (
    <Wrapper className="allianz-plan-info">
      <PlanMainInfo className="plan-main-info">
        <CustomIcon name={iconName} color="#152946" className="chield-icon" />
        <PlanTitlePrice>
          <Title>{title}</Title>
          {!isNotPlan && (
            <Price>
              <span>{`${parseFloat(price ?? '')?.toFixed(2)}${currencySymbol}`}</span>
              <span>{perPerson && `/${t('person')}`}</span>
            </Price>
          )}
        </PlanTitlePrice>
      </PlanMainInfo>

      {children &&
        React.Children.map(children, child => {
          return React.cloneElement(child);
        })}
    </Wrapper>
  );
}

type FeatureListProps = {
  list?: string[];
};
const FeatureLists = ({list}: FeatureListProps) => {
  const {t} = useTranslation();
  if (!list) return null;
  return (
    <PlanFeaturesList className="allianz-plan-info__feature-list">
      {list.map((item, index) => {
        return (
          <Item key={index}>
            <CustomIcon name="checkedBlack" size={'16px'} />
            <Text>{t(item)}</Text>
          </Item>
        );
      })}
    </PlanFeaturesList>
  );
};

type MoreInfoModalProps = {
  type: string;
};
const MoreInfoModal = ({type}: MoreInfoModalProps) => {
  const {t} = useTranslation();
  const {isOpen, openModal, closeModal} = useModalControls();

  const handleOpenModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    openModal();
  };

  if (type === AllianzPlansTypes.noPlan) return null;

  return (
    <MoreInformationContainer
      onClick={handleOpenModal}
      className="allianz-plan-info__more-info"
    >
      <CustomIcon name="informationGray" size={'16px'} />
      <div className="more-information-text">{t('more_information')}</div>
      <AllianzPlanDetailsModal
        isOpenModalSheet={isOpen}
        handleCloseModal={closeModal}
        type={type}
      />
    </MoreInformationContainer>
  );
};

type AddonBoxProps = {
  name?: string;
  price?: string;
  checked: boolean;
  show: boolean;
  className?: string;
  onChange?: (checked?: boolean) => void;
};
const AddonBox = ({name, price, className, onChange, checked, show}: AddonBoxProps) => {
  const {currencySymbol} = usePaymentSettings();
  const [isChecked, setIsChecked] = React.useState(checked);

  if (!show) return null;
  const handleChange = () => {
    setIsChecked(!isChecked);
    onChange?.(!isChecked);
  };
  return (
    <Box
      className={clsx(className, 'allianz-plan-info')}
      onClick={e => e.stopPropagation()}
    >
      <HeaderText>
        <Trans
          i18nKey="add_cwd_per"
          values={{price: price, currencySymbol: currencySymbol}}
        />
      </HeaderText>
      <Switch checked={isChecked} handleToggle={handleChange} label={name} />
    </Box>
  );
};

PlanInfoBox.FeatureLists = FeatureLists;
PlanInfoBox.AddonBox = AddonBox;
PlanInfoBox.MoreInfoModal = MoreInfoModal;

export {PlanInfoBox};
