import React from 'react';
import {useLocation, useMatch} from 'react-router-dom';
import {PATHS} from 'Routes';
import {environments} from '../configs';
import {APP_FLOWS, APP_PAGES} from 'utils/pages';
import {usePath} from 'hooks';
import {useSummary} from 'context/summary';
import {useSchema} from 'context/guest';

const IS_EMBEDDED_MODE = environments.REACT_APP_IS_EMBEDDED;

function useNavigationCustom() {
  const location = useLocation();
  const {getMainPath} = usePath();
  const {isGuestRegistrationOnly} = useSummary();
  const {
    identityVerificationDetails: {enabled: ivEnabled},
  } = useSchema();

  const rootPath = IS_EMBEDDED_MODE ? '' : '/:token';

  const isHomeView = useMatch(rootPath || '/');
  const isGuestRegistrationView = useMatch(getMainPath(PATHS.guestRegistration));
  const isVerificationView = useMatch(`${rootPath}/verification`);
  const isIdentityVerificationView = location.pathname.includes(PATHS.verification.main);
  const isTaxesView = useMatch(`${rootPath}/taxes`);
  const isTaxesExemptionsView = useMatch(`${rootPath}/taxes/exemptions`);
  const isPaymentsFormsViews = useMatch(`${rootPath}/payments/form`);
  const isPaymentsView =
    location.pathname.includes(PATHS.payments.main) && !isPaymentsFormsViews;
  const isDepositView = useMatch(getMainPath(PATHS.deposits));
  const isRemoteAccessView = useMatch(getMainPath(PATHS.remoteAccess));
  const isAddGuestFormView = useMatch(getMainPath(PATHS.addPersonalDataForm));
  const isSummaryView = useMatch(getMainPath(PATHS.summary));
  const isAutoFillView = useMatch(getMainPath(PATHS.documentMRZ));
  const isAllianzSelectPlanView = useMatch(
    `${rootPath}/travel-insurances/allianz/select-plan`,
  );
  const isAllianzSelectGuestView = useMatch(
    `${rootPath}/travel-insurances/allianz/select-guests`,
  );
  const isBookExperience = useMatch(`${rootPath}/recommendations/:id/book-experience`);
  const isRecommendationsConfirmEmail = useMatch(
    `${rootPath}/recommendations/:id/confirm-email`,
  );

  const isFAQFormView = useMatch(getMainPath(PATHS.faq));

  const isAutoFillUploadView =
    !isAutoFillView && location.pathname.includes(PATHS.documentMRZ);

  const currentPage = React.useMemo(() => {
    if (isGuestRegistrationView) {
      return APP_PAGES.registerGuest;
    }
    if (isVerificationView) {
      return APP_PAGES.verification;
    }
    if (isIdentityVerificationView) {
      return APP_PAGES.verificationChild;
    }
    if (isTaxesView) {
      return location.state?.from
        ? `${APP_PAGES.taxes}_${location.state?.from}`
        : APP_PAGES.taxes;
    }
    if (isTaxesExemptionsView) {
      return APP_PAGES.taxesAddExemption;
    }
    if (isPaymentsFormsViews) {
      return APP_PAGES.paymentsForm;
    }
    if (isPaymentsView) {
      return location.state?.from
        ? `${APP_PAGES.payments}_${location.state?.from}`
        : APP_PAGES.payments;
    }
    if (isDepositView) {
      return APP_PAGES.deposits;
    }
    if (isAddGuestFormView) {
      return APP_PAGES.addPersonalData;
    }
    if (isSummaryView) {
      return APP_PAGES.summary;
    }
    if (isAutoFillView) {
      return APP_PAGES.autoFill;
    }
    if (isAutoFillUploadView) {
      return APP_PAGES.autoFillUpload;
    }
    if (isAllianzSelectPlanView) {
      return APP_PAGES.allianzSelectPlan;
    }
    if (isAllianzSelectGuestView) {
      return APP_PAGES.allianzSelectGuest;
    }
    if (isHomeView) {
      return APP_PAGES.home;
    }
    if (isRemoteAccessView) {
      return APP_PAGES.remoteAccess;
    }
    if (isFAQFormView) {
      return APP_PAGES.faq;
    }
    if (isBookExperience) {
      return APP_PAGES.bookExperience;
    }
    if (isRecommendationsConfirmEmail) {
      return APP_PAGES.recommendationsConfirmEmail;
    }
    return APP_PAGES.home;
  }, [
    isHomeView,
    isGuestRegistrationView,
    isVerificationView,
    isIdentityVerificationView,
    isTaxesView,
    isTaxesExemptionsView,
    isPaymentsFormsViews,
    isPaymentsView,
    isDepositView,
    isAddGuestFormView,
    isSummaryView,
    isAutoFillView,
    isAutoFillUploadView,
    location.state?.from,
    isAllianzSelectPlanView,
    isAllianzSelectGuestView,
    isRemoteAccessView,
    isFAQFormView,
    isBookExperience,
    isRecommendationsConfirmEmail,
  ]);

  const currentFlow = React.useMemo(() => {
    if (!isGuestRegistrationOnly) {
      return APP_FLOWS.allFeatures;
    }

    return ivEnabled ? APP_FLOWS.grIvOnly : APP_FLOWS.grOnly;
  }, [isGuestRegistrationOnly, ivEnabled]);

  const currentFlowAndPage = React.useMemo(() => {
    return `${currentFlow}_${currentPage}`;
  }, [currentFlow, currentPage]);
  return {currentPage, currentFlowAndPage, isPaymentsView, currentFlow};
}

export {useNavigationCustom};
