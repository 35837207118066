function buildTimeOptions(minutesRange = 0) {
  const timeOptions: {value: string; label: string}[] = [];

  let hours = minutesRange ? 0 : 1;
  let minutes = minutesRange;
  const seconds = 0;

  for (let i = 0; i < 24 * (60 / (minutesRange || 60)); i++) {
    const result = `${hours >= 10 ? hours : `0${hours}`}:${
      minutes >= 10 ? minutes : `0${minutes}`
    }`;
    timeOptions.push({value: result + `:0${seconds}`, label: result});

    if ((minutesRange === 0 || minutes === 60 - minutesRange) && hours !== 24) {
      hours = hours === 23 ? 0 : hours + 1;
      minutes = 0;
    } else {
      minutes += minutesRange;
    }
  }

  return timeOptions;
}

function buildHoursOptions() {
  const timeOptions: {value: number; label: string}[] = [];

  let hours = 1;
  const minutes = 0;

  for (let i = 0; i < 24; i++) {
    const label = `${hours >= 10 ? hours : `0${hours}`}:0${minutes}`;
    const value = hours;
    timeOptions.push({value, label});

    if (hours !== 24) {
      hours = hours === 23 ? 0 : hours + 1;
    }
  }

  return timeOptions;
}

const TIME_OPTIONS = buildTimeOptions(30);

const TIME_WITHOUT_MINUTES_OPTIONS = buildTimeOptions();

const HOURS_OPTIONS = buildHoursOptions();

export {TIME_OPTIONS, TIME_WITHOUT_MINUTES_OPTIONS, HOURS_OPTIONS};
