export type ErrorType =
  | {
      message: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errors: any;
      details: string;
    }
  | string;
export enum BUTTONS_STYLES_OPTIONS {
  round = 'ROUND',
  square = 'SQUARE',
}
