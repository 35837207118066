import React from 'react';
import {NONE_EXEMPTION, Season} from '@guestapp/sdk';
import SeasonEntity from 'entities/taxes/season.entity';
import TaxExemptionsEntity from 'entities/taxes/tax-exemptions.entity';
import {useGuestGroup} from '../useGuestGroup';
import {useGuestTaxesList} from './useGuestTaxesList';
import {useFetchGuestsTaxes, useFetchSeason, useMutateGuestTax} from './common';

function getTaxExemptionsAsOptions(data?: Season['exemptions']) {
  if (!data || !Array.isArray(data)) {
    return [];
  }

  return data.map(exemption => {
    return {
      value: exemption.exemption_type,
      label: exemption.exemption_name,
    };
  });
}

type UseTaxesProps = {
  onError?: (error: Error) => void;
};

function useTaxes({onError}: UseTaxesProps = {}) {
  const {guestGroup} = useGuestGroup();

  const {
    queryData: {data: highSeason},
    isLoadingSeason,
  } = useFetchSeason();
  const taxExemptions = highSeason?.exemptions;
  const taxExemptionsOptions = React.useMemo(
    () => getTaxExemptionsAsOptions(taxExemptions),
    [taxExemptions],
  );
  const seasonEntity = React.useMemo(() => new SeasonEntity(highSeason), [highSeason]);

  const {
    data: guestsTaxes,
    isLoading: isLoadingGuestsTaxes,
    isFetching: isFetchingGuestsTaxes,
  } = useFetchGuestsTaxes({onError});

  const {
    mutateGuestTax,
    confirmTaxesSetup,
    isLoading: IsMutation,
  } = useMutateGuestTax({guestsTaxesId: guestsTaxes?.id, onError});

  const {checkIfGuestExempted, removeExemption, addExemption} = useGuestTaxesList({
    mutate: mutateGuestTax,
    guestsTaxesList: guestsTaxes?.guest_list,
  });

  const displayedGuestsExemptions = React.useMemo(() => {
    if (!guestsTaxes) {
      return [];
    }

    const taxExemptionsEntity = new TaxExemptionsEntity(taxExemptions);

    return guestsTaxes.guest_list
      .filter(({exemption}) => !!exemption && exemption !== NONE_EXEMPTION)
      .map(guestTax => {
        const exemption = taxExemptionsEntity?.findOne(guestTax.exemption);

        const guest = guestGroup?.members?.find(
          singleGuest => singleGuest.id === guestTax.guest_id,
        );

        return {
          full_name: guest?.full_name,
          id: guestTax.id,
          exemption: exemption?.exemption_name,
          isRemovable: !!guestTax?.guest_id,
          isEditable: !guestTax?.guest_id,
        };
      });
  }, [guestsTaxes, taxExemptions, guestGroup?.members]);

  return {
    guestsTaxes,
    taxExemptions,
    taxExemptionsOptions,
    displayedGuestsExemptions,
    seasonEntity,
    confirmTaxesSetup,
    addExemption,
    removeExemption,
    checkIfGuestExempted,
    disabledAddingExemptions: Boolean(
      (guestsTaxes?.guest_list?.length ?? 0) <= displayedGuestsExemptions?.length,
    ),
    isLoading: isLoadingSeason || isLoadingGuestsTaxes,
    isFetching: isLoadingSeason || isFetchingGuestsTaxes || IsMutation,
    hasExemptions: Boolean(taxExemptions?.length),
  };
}

export {useTaxes};
