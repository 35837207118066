import styled from 'styled-components';
import {device} from '@guestapp/ui/src/utils/device';
//DEPECRATED import NavigationItem from 'components/NavigationItem';
import Page from 'components/Page';
import {Box} from 'styled/common';

export const Wrapper = styled.div`
  padding: 16px 0;
  width: 100%;
  @media (min-width: ${device.tablet}) {
    padding-top: 40px;
  }
`;

export const WelcomeText = styled.div`
  text-align: left;
  width: 100%;
  font-size: ${props => props.theme.fonts.sizes.largeL};
  font-style: normal;
  font-weight: ${props => props.theme.fonts.fontWeight.medium};
  line-height: 24px; /* 120% */
  letter-spacing: 0.36px;
  margin-bottom: 16px;
  font-family: ${props => props.theme.fonts.fontFamily.titles.font_name};
  color: ${props => props.theme.colors.branding.fontPrimary};
`;

export const StyledBox = styled(Box)`
  width: 100%;
  height: auto;
  padding: 24px 16px;
  position: relative;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  @media (min-width: ${device.tablet}) {
    padding: 24px;
  }

  @media (min-width: ${device.laptopML}) {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
`;

export const BookingDatesBoxContainer = styled.div`
  order: 1;
  width: 100%;
`;

export const CheckinInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${StyledBox} {
    order: 2;
  }

  @media (min-width: ${device.laptopML}) {
    display: grid;
    grid-template-columns: 1fr 65%;
    gap: 24px;
  }
`;

export const StyledPage = styled(Page)`
  @media (min-width: ${device.laptopM}) {
    padding: 0 32px 100px;
  }
`;

export const ContentItemElement = styled.div`
  width: 100%;

  .nav-item__description {
    text-align: justify;
    max-width: 207px;
  }

  @media (min-width: ${device.laptop}) {
    .nav-item__description {
      max-width: 100%;
    }
  }
`;
