import {memo} from 'react';
import {Trans} from 'react-i18next';
import {CustomIcon} from '@guestapp/ui';
import {ContentInformationBox, SubTitleInformation} from './styled';

export const InformationText = memo(({ageLimit}: {ageLimit: null | number}) => {
  return (
    <ContentInformationBox>
      <CustomIcon size={16} name="information" />
      <SubTitleInformation>
        <Trans i18nKey="only_guest_over" values={{ageLimit}}>
          Only guests over {ageLimit} years old need to be registered
        </Trans>
      </SubTitleInformation>
    </ContentInformationBox>
  );
});
