import {useMutation, useQueryClient} from '@tanstack/react-query';
import {CreateGuestPayload, FieldsErrorResponse, Guest} from '@guestapp/sdk';
import {QUERY_CACHE_KEYS} from '../utils/constants';
import {useSchema} from '../context/guest';
import {useChekinSDK} from '../context/ChekinSDK';
import {useReservation} from '../context/reservation';

type UseGuestMutation = {
  onSuccess: (data: Guest) => void;
  onError: (error: FieldsErrorResponse) => void;
  onSettled?: () => void;
};
function useGuestMutation({onSuccess, onError, onSettled}: UseGuestMutation) {
  const queryClient = useQueryClient();
  const {customForm} = useSchema();
  const {createOrUpdate} = useChekinSDK();
  const {reservationId} = useReservation();

  return useMutation<
    Guest,
    FieldsErrorResponse,
    {
      id?: string;
      payload: Omit<CreateGuestPayload, 'reservation_id'>;
    }
  >(
    ({id, payload}) =>
      createOrUpdate({
        data: {id, reservation_id: reservationId || '', ...payload},
        customFormId: customForm?.id,
      }),
    {
      onSuccess: async data => {
        await queryClient.refetchQueries([QUERY_CACHE_KEYS.summary]);
        onSuccess(data);
        void queryClient.refetchQueries([QUERY_CACHE_KEYS.reservation]);
        void queryClient.refetchQueries([QUERY_CACHE_KEYS.summary]);
        void queryClient.resetQueries([QUERY_CACHE_KEYS.formFields]);
        void queryClient.refetchQueries([QUERY_CACHE_KEYS.guestGroup]);
        queryClient.setQueryData([QUERY_CACHE_KEYS.guest, data.id], data);
      },
      onError,
      onSettled,
    },
  );
}

export {useGuestMutation, type FieldsErrorResponse};
