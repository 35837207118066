import React from 'react';
import {createPortal} from 'react-dom';
import Draggable from 'react-draggable';
import {device} from '../../../styled/device';
import {useFrame} from '../../../context/frame';
import {
  useHandlerDelay,
  useKeyDownHandler,
  useModalControls,
  useOutsideClick,
  useScreenResize,
  useTimeoutRef,
} from '../../../hooks';
import Icon from 'components/common/Icon';
import {WarningCloseModal} from './WarningCloseModal/WarningCloseModal';
import {
  CloseButton,
  CloseMobileButton,
  CloseSlideButton,
  Content,
  ContentCloseMobileButton,
  ModalWrapper,
  Outer,
} from './styled';

export type ModalProps = {
  visible: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  warningCloseModal?: boolean;
  densed?: boolean;
  mode?: 'mobile-slide';
  className?: string;
  withCloseSlideButton?: boolean;
  closeOnDocumentClick?: boolean;
  closeButtonType?: 'slide' | 'click' | 'none';
};

const Modal = React.memo(
  ({
    visible,
    onClose,
    children,
    densed,
    mode,
    warningCloseModal,
    className,
    withCloseSlideButton = true,
    closeOnDocumentClick = true,
    closeButtonType = 'slide',
  }: ModalProps) => {
    const {
      isOpen: isWarningModalOpen,
      openModal: openWarningModal,
      closeModal: closeWarningModal,
    } = useModalControls();
    const contentRef = React.useRef<HTMLDivElement | null>(null);
    const timeoutRef = useTimeoutRef();
    const [unmounted, setUnmounted] = React.useState(false);
    const {isMatch: isMobileMode} = useScreenResize(device.mobileXL);
    const {document: frameDocument} = useFrame();
    const rootElement = frameDocument?.body || document.body;

    const isMobileSlideMode = mode === 'mobile-slide' && isMobileMode;

    const closeModalHandler = React.useCallback(() => {
      if (unmounted) return;
      if (isMobileSlideMode) {
        setUnmounted(true);
        timeoutRef.current = setTimeout(() => {
          onClose?.();
          setUnmounted(false);
        }, 300);
        return;
      }
      onClose?.();
    }, [isMobileSlideMode, onClose, timeoutRef, unmounted]);

    const closeModal = useHandlerDelay(closeModalHandler, visible, 300);

    const handleClickEsc = React.useCallback(() => {
      warningCloseModal ? openWarningModal() : closeModal();
    }, [closeModal, openWarningModal, warningCloseModal]);

    const handleCloseWarningModal = React.useCallback(() => {
      closeWarningModal();
      closeModal();
    }, [closeModal, closeWarningModal]);

    const handleContinueWarningModal = React.useCallback(() => {
      closeWarningModal();
    }, [closeWarningModal]);

    const handleOutsideClick = React.useCallback(() => {
      if (!isWarningModalOpen && visible && closeOnDocumentClick) {
        closeModal();
      }
    }, [closeModal, isWarningModalOpen, visible, closeOnDocumentClick]);

    useKeyDownHandler({keyCode: 27, onKeyDown: handleClickEsc, enabled: visible});
    useOutsideClick(contentRef, handleOutsideClick, '#popup-root');

    function CloseButtonComponent() {
      if (!withCloseSlideButton) {
        return null;
      }
      if (!isMobileSlideMode) {
        return (
          <CloseButton onClick={closeModal}>
            <Icon name="close" size="24" />
          </CloseButton>
        );
      }

      return closeButtonType === 'slide' && withCloseSlideButton ? (
        <CloseSlideButton id="closeModalMobileButton" />
      ) : (
        <ContentCloseMobileButton>
          <CloseMobileButton onClick={closeModal}>
            <Icon name="close" size="24" />
          </CloseMobileButton>
        </ContentCloseMobileButton>
      );
    }

    return createPortal(
      visible && (
        <>
          <WarningCloseModal
            visible={isWarningModalOpen}
            onClose={handleCloseWarningModal}
            onContinue={handleContinueWarningModal}
          />
          <ModalWrapper
            $slideMode={isMobileSlideMode}
            $unmounted={unmounted}
            className="custom-modal"
          >
            <Draggable
              axis="y"
              handle="#closeModalMobileButton"
              onStop={closeModal}
              disabled={!isMobileSlideMode}
            >
              <Outer
                ref={contentRef}
                $densed={densed}
                $slideMode={isMobileSlideMode}
                $unmounted={unmounted}
                className={className}
              >
                <CloseButtonComponent />
                <Content>{children}</Content>
              </Outer>
            </Draggable>
          </ModalWrapper>
        </>
      ),
      rootElement,
    );
  },
);

export {Modal};
