import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {useReservation} from 'context/reservation';
import {usePaymentSettings, useFetchOffers} from 'hooks';
import {formatPrice} from '../price';
import CustomIcon from 'components/common/CustomIcon';
import {
  Container,
  List,
  StyledOfferCard,
  HeaderContainer,
  HederText,
  Title,
  Description,
} from './styled';

export const HomeUpsellingList = () => {
  const {t} = useTranslation();
  const theme = useTheme();
  const {data: reservation} = useReservation();
  const {data: paginatedOfferList, isLoading} = useFetchOffers({
    housingId: reservation?.housing_id,
  });
  const {currencySymbol} = usePaymentSettings();

  if (paginatedOfferList?.count === 0 || isLoading) {
    return null;
  }

  return (
    <Container>
      <HeaderContainer>
        <CustomIcon
          name={'ticketCircle'}
          size={40}
          color={theme.hasTemplate ? theme.colors.branding.icons : '#777E91'}
        />
        <HederText>
          <Title>{t('home_upselling_list.title')}</Title>
          <Description>{t('home_upselling_list.description')}</Description>
        </HederText>
      </HeaderContainer>
      <List>
        {paginatedOfferList?.results.map(
          ({title, id, price_type, items, highlight, image_url}) => (
            <StyledOfferCard
              key={id}
              id={id}
              title={title}
              price={formatPrice(currencySymbol)({priceType: price_type, items})}
              imageUrl={image_url}
              highlight={highlight}
            />
          ),
        )}
      </List>
    </Container>
  );
};
