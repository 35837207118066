import React, {JSX} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../common/Button';
import {
  ModalHeader,
  Row,
  ModalTitleText,
  ButtonsWrapper,
  ModalWrapper,
  ModalSubTitle,
} from './styled';

type ModalTypes = {
  onClose: () => void;
  onClear: () => void;
  onChange: () => void;
  signatureArea: JSX.Element;
  hasSignature?: boolean;
};

function SignatureModal({
  onClose,
  onClear,
  onChange,
  signatureArea,
  hasSignature,
}: ModalTypes) {
  const {t} = useTranslation();

  const handleNextButton = () => {
    onChange();
    onClose();
  };

  const handleCancelModal = () => {
    onClear();
    onClose();
  };

  return (
    <div className="signature-modal">
      <ModalHeader className="signature-modal__header">
        <Row className="signature-modal__title">
          <ModalTitleText>{t('draw_your_signature')}</ModalTitleText>
        </Row>
        <ModalSubTitle className="signature-modal__subtitle">
          {t('signature_subtitle')}
        </ModalSubTitle>
      </ModalHeader>
      <ModalWrapper className="signature-modal__content">
        {signatureArea}
        <ButtonsWrapper>
          <Button
            primary
            label={t('confirm_signature')}
            onClick={handleNextButton}
            disabled={!hasSignature}
          />
          <Button link label={t('cancel')} onClick={handleCancelModal} />
        </ButtonsWrapper>
      </ModalWrapper>
    </div>
  );
}

export {SignatureModal};
