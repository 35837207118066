import React from 'react';
import {useLocation, useMatch, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useReservation} from 'context/reservation';
import {useCustomTheme} from 'context/customTheme';
import {GUESTAPP_NAVIGATION_MODE} from 'utils/constants';
import {PATHS} from 'Routes';
import {NavLinks, useNavigationLinks, usePath, useSideBarLinks} from 'hooks';
import {SideBar} from 'components/SideBar';
import {
  LanguageSelection,
  LanguageSelectionRef,
} from 'components/ModalSheets/LanguageSelection/LanguageSelection';
import {
  DiscardChangesModal,
  DiscardChangesModalRef,
} from 'components/common/DiscardChangesModal/DiscardChangesModal';
import {useSummary} from 'context/summary';
import {Label, StyledItem} from './styled';

// import {HeaderTooltipOverlay} from 'components/SideBar/styled';

type PageSideBarProps = {
  header?: boolean;
};

type AppHeaderSettings = {
  navigationTitleConfig: GUESTAPP_NAVIGATION_MODE;
  logo?: string;
};

function getHousingImgSrc(appSettingTemplate: AppHeaderSettings, housingLogo?: string) {
  const isLogoConfigured = [
    GUESTAPP_NAVIGATION_MODE.logo,
    GUESTAPP_NAVIGATION_MODE.property_name_and_logo,
  ].includes(appSettingTemplate.navigationTitleConfig);

  const showLogo = !appSettingTemplate?.logo || isLogoConfigured;

  if (!showLogo) {
    return;
  }

  return appSettingTemplate.logo || housingLogo;
}

const getRedirectPatch = (label: NavLinks) => {
  switch (label) {
    case NavLinks.paymentCart:
      return PATHS.payments.main;
    case NavLinks.virtualKeys:
      return PATHS.remoteAccess;
    default:
      return PATHS.home;
  }
};

function PageSideBar({header}: PageSideBarProps) {
  const {t} = useTranslation();
  const {housing} = useReservation();
  const {data: template} = useCustomTheme();
  const discardChangesModalRef = React.useRef<DiscardChangesModalRef>(null);
  const languageSelectionModalRef = React.useRef<LanguageSelectionRef>(null);
  const {navigatorLinksArray} = useNavigationLinks({
    languageClick: () => languageSelectionModalRef?.current?.open(),
  });

  const titleLogo = template?.navigation_title_logo;
  const titleLogoType =
    template?.navigation_title_type || GUESTAPP_NAVIGATION_MODE.property_name;

  const {navItems} = useSideBarLinks();
  const {data} = useSummary();

  const {getMainPath, getFullPath} = usePath();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const isAddPersonalDataForm = useMatch(getMainPath(PATHS.addPersonalDataForm));
  const isMainIvView = useMatch('/:token/verification');
  const isIdentityVerificationView = pathname.includes(PATHS.verification.main);
  const isAddFormOrIvView =
    isAddPersonalDataForm || (!isMainIvView && isIdentityVerificationView);
  const showHeader = header ?? true;
  const name = data?.display_name ?? data?.property_name ?? '';
  const handleNavItemClick = React.useCallback(
    (onClick: () => void, label: NavLinks) => {
      if (isAddFormOrIvView) {
        discardChangesModalRef.current?.open(getRedirectPatch(label));
        return;
      }
      onClick();
    },
    [isAddFormOrIvView, discardChangesModalRef],
  );

  const appSettingTemplate: AppHeaderSettings = {
    navigationTitleConfig: titleLogoType,
    logo: titleLogo ? String(titleLogo) : undefined,
  };

  const housingImg = getHousingImgSrc(appSettingTemplate, housing?.picture?.src);

  const showLogoAndPropertyName = [
    GUESTAPP_NAVIGATION_MODE.property_name,
    GUESTAPP_NAVIGATION_MODE.property_name_and_logo,
  ].includes(titleLogoType);

  const sideBarTitle = showLogoAndPropertyName || !titleLogoType ? name : '';

  const handleClickOnTitle = React.useCallback(() => {
    return navigate(getFullPath(PATHS.home));
  }, [getFullPath, navigate]);

  return (
    <>
      <SideBar>
        <SideBar.Header
          onlyLogo={template?.navigation_title_type === GUESTAPP_NAVIGATION_MODE.logo}
          imgSrc={housingImg}
          show={showHeader}
          isLoading={false}
        >
          <div onClick={() => handleClickOnTitle()}>{sideBarTitle}</div>
        </SideBar.Header>

        <SideBar.Body>
          <SideBar.Items>
            {navItems.map((navItem, index) => {
              return (
                <SideBar.Item
                  isLoading={false}
                  isActive={navItem.selected}
                  icon={navItem.iconName}
                  onClick={() => handleNavItemClick(navItem.onClick, navItem.label)}
                  key={index}
                  bagdeData={navItem.extraData}
                >
                  <StyledItem>{t(navItem.label)}</StyledItem>
                </SideBar.Item>
              );
            })}
          </SideBar.Items>
          <SideBar.Items footer>
            {navigatorLinksArray.map((navItem, index) => (
              <SideBar.Item
                key={index}
                isActive={false}
                icon={navItem.iconName}
                onClick={navItem.onclick}
                isLoading={false}
              >
                <Label>{t(navItem.label)}</Label>
              </SideBar.Item>
            ))}
          </SideBar.Items>
        </SideBar.Body>
      </SideBar>
      <DiscardChangesModal ref={discardChangesModalRef} />
      <LanguageSelection ref={languageSelectionModalRef} />
      {/* <HeaderTooltipOverlay /> */}
    </>
  );
}

export {PageSideBar};
