import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  gap: 30px;
  background: white;
`;

export const Description = styled.p`
  margin: 0;
  padding: 0;
  font-size: 20px;
`;
