import React from 'react';
import {useQuery} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {QUERY_CACHE_KEYS} from '../utils/constants';
import {LocationType} from '../utils/types';
import {useChekinSDK} from '../context/ChekinSDK';
import {getLocationsAsOptions} from '../pages/AddPersonalDataForm/utils';

type UseLocationsOptions = {
  enabled?: boolean;
  params?: string;
};

function useFetchLocations(props?: UseLocationsOptions) {
  const {i18n} = useTranslation();
  const {getLocations} = useChekinSDK();
  const {params = 'ordering=name'} = props || {};

  const {
    data: locations,
    isError,
    error,
    isLoading: isLoadingLocations,
  } = useQuery(
    [QUERY_CACHE_KEYS.locations, i18n.language],
    () => getLocations<LocationType>(params),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const locationsAsOptions = React.useMemo(() => {
    if (locations) {
      return getLocationsAsOptions(locations);
    }

    return [];
  }, [locations]);

  return {
    locations,
    isError,
    isLoadingLocations,
    error,
    locationsAsOptions,
  };
}

export {useFetchLocations};
