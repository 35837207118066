import React from 'react';
import {ActionMeta, GroupBase, Props, SelectInstance} from 'react-select';
import {SelectOptionType} from '@guestapp/sdk';
import {ErrorType} from '../../../utils/types';
import {IconNames} from '../StyledIcon';
import {ReactSelect} from './styled';
import {customFilter} from './utils';
import {useTimeoutRef} from 'hooks/common';
import {
  Control,
  DropdownIndicator,
  Input,
  LoadingIndicator,
  Menu,
  MenuList,
  Placeholder,
} from './components';

type SelectRefType<T = undefined, V = string, L = string> = SelectInstance<
  SelectOptionType<T, V, L>,
  false,
  GroupBase<SelectOptionType<T, V, L>>
>;

type SelectCustomProps<T, V, L> = {
  onChange?: (
    option: SelectOptionType<T, V, L> | null,
    info: ActionMeta<SelectOptionType<T, V, L>>,
  ) => void;
  onHandleKeyDown?: (event: React.KeyboardEvent) => void;
  label?: string | null;
  invalid?: boolean;
  disabled?: boolean;
  trimFilterBy?: number;
  error?: ErrorType;
  clearValue?: (name: string) => void;
  leftIcon?: React.ReactNode;
  empty?: boolean;
  indicator?: IconNames;
  indicatorSize?: number;
  loading?: boolean;
  mobileVersion?: boolean;
  tooltip?: string;
  placeholder?: string;
  forcedSearchMode?: boolean;
  isUSAFormat?: boolean;
  isFocused?: boolean;
};

export type SelectProps<T = undefined, V = string, L = string> = Props<
  SelectOptionType<T, V, L>,
  false,
  GroupBase<SelectOptionType<T, V, L>>
> &
  SelectCustomProps<T, V, L>;

function SelectInner<T, V, L>(
  {
    onChange,
    label,
    invalid,
    error,
    className,
    disabled,
    loading,
    tooltip,
    components,
    onMenuOpen,
    onMenuClose,
    empty,
    onHandleKeyDown,
    ...props
  }: SelectProps<T, V, L>,
  ref: React.ForwardedRef<SelectRefType<T, V, L>>,
) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const isSearchableEnabled = (props.options?.length || 0) > 15;
  const needShowScrollbar = (props.options?.length || 0) > 8;
  const {mobileVersion} = props;
  const focusTimeoutRef = useTimeoutRef();
  const selectRef = React.useRef<SelectRefType<T, V, L>>(null);
  const handleChange = (
    option: SelectOptionType<T, V, L> | null,
    info: ActionMeta<SelectOptionType<T, V, L>>,
  ) => {
    if (disabled) return;
    onChange?.(option, info);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (disabled) return;
    onHandleKeyDown?.(event);
  };

  const handleMenuOpen = React.useCallback(() => {
    setIsMenuOpen(true);
    onMenuOpen?.();
  }, [onMenuOpen]);

  const handleMenuClose = React.useCallback(() => {
    setIsMenuOpen(false);
    onMenuClose?.();
  }, [onMenuClose]);

  React.useImperativeHandle(ref, () => selectRef.current as SelectRefType<T, V, L>, []);

  React.useEffect(
    function autoFocusAfterTimeout() {
      if (!mobileVersion) return;
      focusTimeoutRef.current = setTimeout(() => {
        selectRef.current?.focus();
        clearTimeout(focusTimeoutRef.current);
      }, 400);
    },
    [focusTimeoutRef, mobileVersion],
  );

  return (
    <ReactSelect
      ref={selectRef}
      empty={empty || !props.value}
      className={`${className} select`}
      classNamePrefix="select"
      error={error}
      label={label}
      invalid={invalid}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      isDisabled={disabled}
      isLoading={loading}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      isMenuOpen={isMenuOpen}
      filterOption={customFilter}
      showScrollbar={needShowScrollbar}
      isSearchable={isSearchableEnabled}
      components={{
        Menu,
        Input,
        Control,
        MenuList,
        Placeholder,
        LoadingIndicator,
        DropdownIndicator,
        IndicatorSeparator: null,
        ...components,
      }}
      {...props}
    />
  );
}

type SelectForwardType = <T = undefined, V = string, L = string>(
  props: SelectProps<T, V, L> & {ref?: React.ForwardedRef<SelectRefType<T, V, L>>},
  // @ts-ignore
) => ReturnType<typeof Select>;

const Select = React.forwardRef(SelectInner) as SelectForwardType;

export {Select};
export type {SelectRefType, SelectForwardType};
