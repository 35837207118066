import {ReservationApi} from '../api';
import {
  ConfirmReservationDetails,
  FetchBookingReferenceParams,
  FetchReservationParams,
  ReservationPayload,
} from '../utils/types';
import {FetcherSettings} from '../api/apiClient';

class ReservationService {
  reservationApi: ReservationApi;

  constructor() {
    this.reservationApi = new ReservationApi();
  }

  getOne(params: FetchReservationParams, resolverSettings?: FetcherSettings) {
    return this.reservationApi.getOne(params, resolverSettings);
  }

  getConfirmReservationPayload(data: ConfirmReservationDetails) {
    return {
      guest_group: {
        number_of_guests: data.numberOfGuests,
        adults: data.adults,
        children: data.children,
      },
    };
  }

  getSummary(id: string, resolverSettings?: FetcherSettings) {
    return this.reservationApi.summary(id, resolverSettings);
  }

  getBookingReference(
    params: FetchBookingReferenceParams,
    resolverSettings?: FetcherSettings,
  ) {
    return this.reservationApi.getBookingReference(params, resolverSettings);
  }

  createReservation(payload: ReservationPayload, resolverSettings?: FetcherSettings) {
    return this.reservationApi.createReservation(payload, resolverSettings);
  }

  updateReservation(
    id: string,
    payload: ReservationPayload,
    resolverSettings?: FetcherSettings,
  ) {
    return this.reservationApi.updateReservation(id, payload, resolverSettings);
  }
}

export {ReservationService};
