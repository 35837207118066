import React from 'react';
import TaxGuestListEntity from '../../entities/taxes/tax-guest-list.entity';
import {GuestTaxExemption} from '@guestapp/sdk';
import {useMutateGuestTax} from './common';

type UseGuestTaxesListProps = {
  mutate: ReturnType<typeof useMutateGuestTax>['mutateGuestTax'];
  guestsTaxesList?: GuestTaxExemption[];
};
function useGuestTaxesList({mutate, guestsTaxesList}: UseGuestTaxesListProps) {
  const removeExemption = React.useCallback(
    (id: string, options: {onSuccess?: () => void; successDelay?: number}) => {
      if (!guestsTaxesList) return;
      const taxGuestListEntity = new TaxGuestListEntity(guestsTaxesList);
      taxGuestListEntity.removeExemption(id);

      mutate(taxGuestListEntity.exec(), options);
    },
    [guestsTaxesList, mutate],
  );

  const addExemption = React.useCallback(
    (
      guestExemption: Partial<GuestTaxExemption> &
        Pick<GuestTaxExemption, 'id' | 'age' | 'guest_id'>,
      onSuccess?: () => void,
    ) => {
      const taxGuestListEntity = new TaxGuestListEntity(guestsTaxesList);
      const payload = taxGuestListEntity.addExemption(guestExemption).exec();

      mutate(payload, {
        onSuccess,
      });
    },
    [guestsTaxesList, mutate],
  );

  const checkIfGuestExempted = React.useCallback(
    (id: string) => {
      return new TaxGuestListEntity(guestsTaxesList).checkIfExemptionExistsByGuestId(id);
    },
    [guestsTaxesList],
  );

  return {
    addExemption,
    removeExemption,
    checkIfGuestExempted,
  };
}

export {useGuestTaxesList};
