import {Trans, useTranslation} from 'react-i18next';
import {Title, Wrapper, Text, UnderstoodButton, HelperText} from './styled';
import {useTheme} from 'styled-components';
// import autoFillIcon from 'assets/icons/auto-fill-icon.svg';
import {CustomIcon} from '@guestapp/ui';

type AutoFillProps = {
  onClose: () => void;
};

function AutoFillModalSheet({onClose}: AutoFillProps) {
  const {t} = useTranslation();
  const theme = useTheme();
  return (
    <Wrapper className="autofill-modal-sheet">
      {/* <AutoFillIcon width={195} height={188} src={autoFillIcon} /> */}
      <CustomIcon
        name="autoFillIcon"
        custom
        size={200}
        color={theme.colors.branding.primary}
        className="autofill-modal-sheet__icon"
      />
      <Title className="autofill-modal-sheet__title">{t('form_auto_filled')}</Title>
      <Text className="autofill-modal-sheet__text">
        {t('chekin_form_has_been_auto_filled')}
      </Text>
      <HelperText className="autofill-modal-sheet__helper">
        <Trans i18nKey="please_check_personal_data" />
      </HelperText>
      <UnderstoodButton
        label={t('understood')}
        onClick={onClose}
        className="autofill-modal-sheet__confirm-btn"
      />
    </Wrapper>
  );
}

export {AutoFillModalSheet};
