import React from 'react';
import {useTranslation} from 'react-i18next';
import {getSearchParamFromUrl, useDebounce, useTimeoutRef} from '@guestapp/core';
import {InfiniteScrollSelectProps} from '../InfiniteScrollSelect/InfiniteScrollSelect';
import {InfiniteData, useInfiniteQuery, useQueryClient} from '@tanstack/react-query';
import InfiniteScrollSelect from '../InfiniteScrollSelect';
import {SelectRefType} from '../Select';
import {Paginated, SelectOptionType} from '@guestapp/sdk';

const SEARCH_QUERY_DEBOUNCE_MS = 700;

function getConsumableOptions<T, V, L>(
  data: InfiniteData<Paginated<SelectOptionType<T, V, L>>>,
) {
  return data?.pages
    .map(data => {
      return data?.results || [];
    })
    .flat();
}

type QueriedInfiniteScrollSelectProps<T, V, L> = InfiniteScrollSelectProps<T, V, L> & {
  fetcher: (
    key: string,
    page: number,
    searchQuery: string,
  ) => Promise<Paginated<SelectOptionType<T, V, L>>>;
  queryKey: string | (string | undefined)[];
  optionsFilter?: (
    options: SelectOptionType<T, V, L>[],
    params: {searchQuery: string},
  ) => SelectOptionType<T, V, L>[];
  onOptionsChange?: (options: SelectOptionType<T, V, L>[]) => void;
  blockQuery?: boolean;
  minSearchQueryLength?: number;
  listAutoSizer?: boolean;
  mobileVersion?: boolean;
  onError?: (error: Error) => void;
};

const QueriedInfiniteScrollSelectInternal = <T, V, L>(
  {
    fetcher,
    queryKey,
    optionsFilter,
    onOptionsChange,
    blockQuery,
    onInputChange,
    minSearchQueryLength = 0,
    onError,
    ...props
  }: QueriedInfiniteScrollSelectProps<T, V, L>,
  ref: React.ForwardedRef<SelectRefType<T, V, L>>,
) => {
  const {i18n} = useTranslation();
  const {mobileVersion} = props;
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = React.useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useDebounce(
    searchQuery,
    SEARCH_QUERY_DEBOUNCE_MS,
  );
  const isStartedTypingRef = React.useRef(false);
  const focusTimeoutRef = useTimeoutRef();
  const selectRef = React.useRef<SelectRefType<T, V, L>>(null);
  const mainQueryKey = Array.isArray(queryKey) ? queryKey[0] || '' : queryKey;

  const isBlockedFetcher =
    blockQuery ||
    (minSearchQueryLength && debouncedSearchQuery.length < minSearchQueryLength);

  const fetchWithSearchQuery = React.useCallback(
    ({pageParam = 1}) => {
      return fetcher(mainQueryKey, pageParam, debouncedSearchQuery);
    },
    [fetcher, mainQueryKey, debouncedSearchQuery],
  );

  const additionalDependencies = Array.isArray(queryKey) ? queryKey.slice(1) : [];
  const {data, status, hasNextPage, fetchNextPage, isFetchingNextPage} = useInfiniteQuery<
    Paginated<SelectOptionType<T, V, L>>,
    Error
  >(
    [mainQueryKey, ...additionalDependencies, debouncedSearchQuery, i18n.language],
    fetchWithSearchQuery,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: !isBlockedFetcher,
      getNextPageParam: lastGroup => {
        if (lastGroup?.next) {
          return Number(getSearchParamFromUrl('page', lastGroup.next));
        }
        return false;
      },
      onSuccess: () => {
        isStartedTypingRef.current = false;
      },
      onError,
    },
  );

  React.useLayoutEffect(() => {
    if (isStartedTypingRef.current) {
      isStartedTypingRef.current = false;
    }
  }, [debouncedSearchQuery]);

  const options = React.useMemo(() => {
    if (!data) {
      return [];
    }

    let newOptions = getConsumableOptions(data);

    if (optionsFilter) {
      newOptions = optionsFilter(newOptions, {searchQuery});
    }

    onOptionsChange?.(newOptions);

    return newOptions;
  }, [data, onOptionsChange, optionsFilter, searchQuery]);

  React.useImperativeHandle(ref, () => selectRef.current as SelectRefType<T, V, L>);

  React.useEffect(
    function autoFocusAfterTimeout() {
      if (!mobileVersion) return;
      focusTimeoutRef.current = setTimeout(() => {
        selectRef.current?.focus();
        clearTimeout(focusTimeoutRef.current);
      }, 400);
    },
    [focusTimeoutRef, mobileVersion],
  );

  const handleLoadMore = () => {
    if (!isFetchingNextPage) return fetchNextPage();
  };

  const checkIfThereIsInCache = React.useCallback(
    (value: string) => {
      return !!queryClient.getQueryData([queryKey, value, i18n.language]);
    },
    [queryClient, queryKey, i18n.language],
  );

  const handleInputChange: QueriedInfiniteScrollSelectProps<T, V, L>['onInputChange'] = (
    value,
    meta,
  ) => {
    if (isFetchingNextPage || status === 'loading') return;
    if (searchQuery !== value) {
      onOptionsChange?.([]);
      onInputChange?.(value, meta);

      if (checkIfThereIsInCache(value)) {
        setDebouncedSearchQuery(value);
        isStartedTypingRef.current = false;
      } else {
        const isValueNotEqualDebounced = value !== debouncedSearchQuery;
        isStartedTypingRef.current = Boolean(value) && isValueNotEqualDebounced;
      }

      setSearchQuery(value);
    }
  };

  return (
    <InfiniteScrollSelect
      ref={selectRef}
      loading={isStartedTypingRef.current || (status === 'loading' && !options?.length)}
      onInputChange={handleInputChange}
      inputValue={searchQuery}
      canLoadMore={hasNextPage}
      loadMoreItems={handleLoadMore}
      isSearchable
      options={options}
      filterOption={null}
      {...props}
    />
  );
};

const QueriedInfiniteScrollSelect = React.forwardRef(
  QueriedInfiniteScrollSelectInternal,
) as <T, V, L>(
  props: QueriedInfiniteScrollSelectProps<T, V, L> & {
    ref?: React.ForwardedRef<SelectRefType<T, V, L>>;
  },
) => ReturnType<typeof QueriedInfiniteScrollSelectInternal>;

export {QueriedInfiniteScrollSelect};
export type {QueriedInfiniteScrollSelectProps};
