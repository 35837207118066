import React from 'react';
import {useMutation} from '@tanstack/react-query';
import {ThirdPartyDealPayload} from '@guestapp/sdk';
import {useChekinSDK} from 'context/ChekinSDK';
import {useSummary} from 'context/summary';
import {useReservation} from 'context/reservation';
import {useAppSelector} from 'hooks';
import {QUERY_CACHE_KEYS} from 'utils/constants';
type UseThirdPartyDealsProps = {
  onSuccessDeal?: () => void;
  onErrorDeal?: (e: Error) => void;
  onMutateDeal?: () => void;
  onSuccessDeleteDeal?: () => void;
  onErrorDeleteDeal?: (e: Error) => void;
  onMutateDeleteDeal?: () => void;
};
function useThirdPartyDeals({
  onSuccessDeal,
  onErrorDeal,
  onMutateDeal,
  onSuccessDeleteDeal,
  onErrorDeleteDeal,
  onMutateDeleteDeal,
}: UseThirdPartyDealsProps = {}) {
  const {createOrUpdateThirdPartyDeal, deleteDeal} = useChekinSDK();
  const {data} = useSummary();
  const {data: reservation} = useReservation();

  const plans = useAppSelector(state => state.alliaznInsurance.plans);
  const selectedPlan = plans.find(plan => plan.selected);

  const {mutate: mutateThirdPartyDeal} = useMutation<
    unknown,
    Error,
    ThirdPartyDealPayload
  >(createOrUpdateThirdPartyDeal, {
    onError: e => onErrorDeal?.(e),
    onSuccess: onSuccessDeal,
    onMutate: onMutateDeal,
  });

  const {mutate: mutateDeleteDeal} = useMutation<void, Error, string>(
    [QUERY_CACHE_KEYS.deleteDeal],
    deleteDeal,
    {
      onError: e => onErrorDeleteDeal?.(e),
      onSuccess: onSuccessDeleteDeal,
      onMutate: onMutateDeleteDeal,
    },
  );

  const getThirdPartyDealPayload = React.useCallback(
    (guestId: string, marketplaceItem?: string) => {
      return {
        marketplace_item: marketplaceItem ?? (selectedPlan?.marketplace_item as string),
        reservation_id: reservation?.id as string,
        housing_id: reservation?.housing_id as string,
        city_code: 'Default',
        guest_id: guestId,
        nights_of_stay: data?.nights_of_stay as number,
      };
    },
    [
      reservation?.id,
      reservation?.housing_id,
      data?.nights_of_stay,
      selectedPlan?.marketplace_item,
    ],
  );

  return {
    mutateThirdPartyDeal,
    getThirdPartyDealPayload,
    mutateDeleteDeal,
  };
}

export {useThirdPartyDeals};
