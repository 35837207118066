import React from 'react';
import {EMBEDDED_MODE} from '../../utils/embedded';
import languageIcon from '../../assets/icons/language-icon.svg';
import LanguageSelection, {LanguageSelectionRef} from '../ModalSheets/LanguageSelection';
import {Wrapper} from './styled';

function LanguageSDKSwitcher() {
  const languageSelectionModalRef = React.useRef<LanguageSelectionRef>(null);

  if (window.ChekinProSettings?.mode !== EMBEDDED_MODE.onlyGuestForm) {
    return null;
  }

  return (
    <Wrapper onClick={languageSelectionModalRef.current?.open}>
      <img src={languageIcon} width={20} height={20} alt="Change language" />
      <LanguageSelection ref={languageSelectionModalRef} />
    </Wrapper>
  );
}

export {LanguageSDKSwitcher};
