import React from 'react';
import {STATUSES} from '@guestapp/core';
import {useSummary} from 'context/summary';
import {useChekinSDK} from 'context/ChekinSDK';
import {useCustomTheme} from 'context/customTheme';
import defaultLogo from 'assets/icons/chekin-default-splash-logo.svg';
import {BottomCurtain, ContentWrapper, Loader, Logo, TopCurtain} from './styled';

const SHOW_MODAL_AFTER_SPLASH_TIMEOUT = 5000;

function SplashScreen() {
  const {isAuthInitializationError, isSuccessSDK, hasToken} = useChekinSDK();
  const {summaryStatus} = useSummary();
  const [isLoadingSplash, setIsLoadingSplash] = React.useState(true);
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();
  const {data} = useCustomTheme();
  const logo = data?.splash_screen_logo;
  const isSplashScreenEnabled = data?.splash_screen_enabled;
  const hideSplashScreen =
    summaryStatus === STATUSES.success ||
    !hasToken ||
    isAuthInitializationError ||
    isSuccessSDK;

  React.useEffect(() => {
    if (hideSplashScreen) {
      timeoutRef.current = setTimeout(() => {
        setIsLoadingSplash(false);
      }, SHOW_MODAL_AFTER_SPLASH_TIMEOUT);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [hideSplashScreen]);

  if (!isLoadingSplash || !isSplashScreenEnabled) {
    return null;
  }

  return (
    <ContentWrapper>
      <TopCurtain />
      <Logo src={logo ? logo : defaultLogo} width={150} height={100} alt="" />
      <Loader />
      <BottomCurtain />
    </ContentWrapper>
  );
}

export {SplashScreen};
