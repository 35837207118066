import styled, {css} from 'styled-components';
import {Link as ReactRouterLink} from 'react-router-dom';

type BaseLinkStylesProps = {
  disabled?: boolean;
  $dark?: boolean;
  $densed?: boolean;
};
export const baseLinkStyles = ({disabled, $dark, $densed}: BaseLinkStylesProps) => css`
  color: ${({theme}) => theme.colors.buttons.textButton};
  text-decoration: none;
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.07s ease-in-out;
  font-weight: ${props => props.theme.fonts.fontWeight.mediumL};
  line-height: 20px;
  font-size: ${props => props.theme.fonts.sizes.small};
  margin-left: 4px;
  ${$densed &&
  css`
    font-weight: ${props => props.theme.fonts.fontWeight.medium};
  `}

  ${!disabled &&
  css`
    &:hover {
      opacity: 0.75;
    }

    &:active {
      opacity: 1;
    }
  `}

  ${disabled &&
  css`
    opacity: 0.75;
  `}

  ${$dark &&
  css`
    color: ${({theme}) => theme.colors.$n3};
  `}
`;

export const ExternalLink = styled.a<BaseLinkStylesProps>`
  outline: none;
  ${props => baseLinkStyles(props)}
`;

export const RouterLink = styled(ReactRouterLink)<BaseLinkStylesProps>`
  ${props => baseLinkStyles(props)}
`;
