import {useCallback, useEffect, useRef, useState} from 'react';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';
import {useMutation} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {Controller, useForm} from 'react-hook-form';
import {useChekinSDK} from 'context/ChekinSDK';
import {useReservation} from 'context/reservation';
import {
  DATE_FORMAT,
  FormTypes,
  OPTION_VALUES,
  SEARCH_RESERVATION_FORM_NAMES,
  formatOneDate,
  getMinCheckInDate,
  getNewToken,
  isAfter,
  isEqual,
} from 'pages/SearchReservation/utils';
import {RESERVATION_ID_KEY, SHARE_LINK, STATUSES} from 'utils/constants';
import {PATHS} from 'Routes';
import {LocalStorage, useStatus} from '@guestapp/core';
import {useErrorModal, useModalControls, usePath, useTimeoutRef} from 'hooks';
import PageUniversalLink from 'components/PageUniversalLink';
import Datepicker from 'components/common/Datepicker';
import {InputController as Input} from '@guestapp/ui';
import {ReservationNotFoundModal} from 'pages/SearchReservation/Components';
import StatusModal, {StatusModalStates} from 'components/StatusModal';
import {
  ContentButton,
  CustomBackButton,
  Fields,
  FormWrapper,
  StatusTitleModal,
  StyledBackIcon,
  StyledButton,
  StyledFormFieldWrapper,
  SubTitleWrapper,
  TitleWrapper,
  Wrapper,
} from './styled';

function CheckInDateView() {
  const {t} = useTranslation();
  const {searchReservation} = useChekinSDK();
  const navigate = useNavigate();
  const {getFullPath} = usePath();
  const {isLoading, setStatus} = useStatus();
  const {ErrorModal, displayError} = useErrorModal();
  const timeoutRef = useTimeoutRef();
  const {countryCode, housingIdPropertyLink} = useReservation();
  const payloadFormRef = useRef<
    Partial<
      Omit<FormTypes, SEARCH_RESERVATION_FORM_NAMES.check_in_date> & {
        [SEARCH_RESERVATION_FORM_NAMES.check_in_date]: string;
      }
    >
  >();

  const [modalRegisterGuestStatus, setModalRegisterGuestStatus] = useState(
    StatusModalStates.IDLE,
  );

  const {
    register,
    control,
    handleSubmit,
    trigger,
    formState: {errors, isSubmitted},
    watch,
  } = useForm<FormTypes>();
  const checkInDate = watch(SEARCH_RESERVATION_FORM_NAMES.check_in_date);
  const leadGuestEmail = watch(SEARCH_RESERVATION_FORM_NAMES.lead_email);

  const {
    isOpen: isOpenReservationNotFoundModal,
    openModal: openReservationNotFoundModal,
    closeModal: closeReservationNotFoundModal,
  } = useModalControls();

  const {
    isOpen: isOpenReservationStatusModal,
    openModal: openReservationStatusModal,
    closeModal: closeReservationStatusModal,
  } = useModalControls();

  const {mutate} = useMutation(
    (formData: FormTypes) => {
      const checkInDate = dayjs(
        formData[SEARCH_RESERVATION_FORM_NAMES.check_in_date],
      ).format(DATE_FORMAT);

      const payload = {
        [SEARCH_RESERVATION_FORM_NAMES.check_in_date]: checkInDate,
        [SEARCH_RESERVATION_FORM_NAMES.lead_email]:
          formData[SEARCH_RESERVATION_FORM_NAMES.lead_email],
      };
      return searchReservation(housingIdPropertyLink, payload);
    },
    {
      onSuccess: (data, formData) => {
        const reservationId = data.reservation_id;
        const reservationSignupFormLink = data.signup_form_link;

        if (reservationId) {
          LocalStorage.set(RESERVATION_ID_KEY, reservationId);
          LocalStorage.set(SHARE_LINK, reservationSignupFormLink);
          const tokenFromUrl = getNewToken(reservationSignupFormLink);
          window.location.href = `${process.env.REACT_APP_PUBLIC_URL}/${tokenFromUrl}`;
          setModalRegisterGuestStatus(StatusModalStates.SUCCESS);
        } else {
          const checkInDate = dayjs(
            formData[SEARCH_RESERVATION_FORM_NAMES.check_in_date],
          ).format(DATE_FORMAT);

          const payload = {
            [SEARCH_RESERVATION_FORM_NAMES.check_in_date]: checkInDate,
            [SEARCH_RESERVATION_FORM_NAMES.lead_email]:
              formData[SEARCH_RESERVATION_FORM_NAMES.lead_email],
          };

          payloadFormRef.current = payload;
          setModalRegisterGuestStatus(StatusModalStates.IDLE);
          closeReservationStatusModal();
          openReservationNotFoundModal();
          return setStatus(STATUSES.idle);
        }
      },
      onError: error => {
        setStatus(STATUSES.error);
        closeReservationStatusModal();
        displayError(error);
      },
    },
  );

  const onSubmit = (formData: FormTypes) => {
    setStatus(STATUSES.loading);
    openReservationStatusModal();
    setModalRegisterGuestStatus(StatusModalStates.LOADING);
    mutate(formData);
  };

  const validateCheckInDate = useCallback(
    (value?: Date) => {
      if (!value) return value;

      const minCheckInDate = getMinCheckInDate();
      const isAfterMinCheckInDate =
        isEqual(value, minCheckInDate) || isAfter(value, minCheckInDate);
      if (isAfterMinCheckInDate) return true;
      const formatMinCheckInDate = formatOneDate({
        date: minCheckInDate,
        housingCountry: countryCode,
      });
      return t(`minimum_check_in_date_is`, {date: formatMinCheckInDate});
    },
    [countryCode, t],
  );

  const handleNextFromNotFoundModal = () => {
    const payload = payloadFormRef.current;

    const checkInDate = payload?.[SEARCH_RESERVATION_FORM_NAMES.check_in_date];

    navigate(getFullPath(PATHS.searchReservation.newReservation), {
      replace: true,
      state: {
        startDate: checkInDate,
        email: payload?.[SEARCH_RESERVATION_FORM_NAMES.lead_email],
        housingId: housingIdPropertyLink,
        country: countryCode,
      },
    });
  };

  useEffect(() => {
    if (modalRegisterGuestStatus === StatusModalStates.SUCCESS) {
      timeoutRef.current = setTimeout(() => {
        closeReservationStatusModal();
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [closeReservationStatusModal, modalRegisterGuestStatus, timeoutRef]);

  useEffect(
    function changeTranslationErrorMessages() {
      if (isSubmitted) {
        trigger();
      }
    },
    [isSubmitted, trigger],
  );

  return (
    <PageUniversalLink className="check-in-date-view">
      <Wrapper>
        <TitleWrapper>{t('find_your_booking')}</TitleWrapper>
        <SubTitleWrapper>
          {t('enter_the_check_in_date_and_the_lead_guest_email')}
        </SubTitleWrapper>

        <CustomBackButton
          label={t('back')}
          customIcon={<StyledBackIcon name="backArrow" size={15} />}
          iconProps={{position: 'left'}}
          onClick={() =>
            navigate(getFullPath(PATHS.searchReservation.main), {
              state: OPTION_VALUES.check_in_date_and_email,
            })
          }
          width="auto"
          className="summary-view__back-btn"
          link
        />

        <FormWrapper>
          <Fields className="guest-form-fields" rowsCount={1}>
            <StyledFormFieldWrapper>
              <Controller
                name={SEARCH_RESERVATION_FORM_NAMES.check_in_date}
                rules={{required: t('required'), validate: validateCheckInDate}}
                control={control}
                render={({field}) => {
                  return (
                    <Datepicker
                      error={errors[SEARCH_RESERVATION_FORM_NAMES.check_in_date]?.message}
                      label={t('check_in_date')}
                      {...field}
                    />
                  );
                }}
              />
            </StyledFormFieldWrapper>

            <StyledFormFieldWrapper>
              <Input
                {...register(SEARCH_RESERVATION_FORM_NAMES.lead_email, {
                  required: t('required'),
                  pattern: {
                    value: new RegExp('^([\\w\\.\\-_]+)?\\w+@[\\w-_]+(\\.\\w+){1,}$'),
                    message: t('invalid_email'),
                  },
                })}
                type="email"
                label={t('lead_guest_email')}
                control={control}
                error={errors[SEARCH_RESERVATION_FORM_NAMES.lead_email]?.message}
                inputMode="email"
                autoCorrect="off"
                spellCheck={false}
                autoCapitalize="none"
              />
            </StyledFormFieldWrapper>
          </Fields>
        </FormWrapper>

        <ContentButton>
          <StyledButton
            label={t('confirm')}
            disabled={isLoading || !checkInDate || !leadGuestEmail}
            onClick={handleSubmit(onSubmit)}
            className="submit-btn"
          />
        </ContentButton>
      </Wrapper>
      <StatusModal
        open={isOpenReservationStatusModal}
        state={modalRegisterGuestStatus}
        maxWidth="375px"
      >
        {modalRegisterGuestStatus === StatusModalStates.LOADING && (
          <StatusTitleModal>{t('searching_booking')}</StatusTitleModal>
        )}
        {modalRegisterGuestStatus === StatusModalStates.SUCCESS && (
          <StatusTitleModal>{t('booking_found')}</StatusTitleModal>
        )}
      </StatusModal>
      <ReservationNotFoundModal
        isOpen={isOpenReservationNotFoundModal}
        onClose={closeReservationNotFoundModal}
        onNext={handleNextFromNotFoundModal}
      />
      <ErrorModal />
    </PageUniversalLink>
  );
}
export {CheckInDateView};
