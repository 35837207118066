export type PlanInfoBoxProps = {
  title: string;
  price?: string;
  isNotPlan?: boolean;
  perPerson?: boolean;
  children?: JSX.Element[] | JSX.Element;
};

export enum FORM_NAMES_CONFIRM_DATA {
  email = 'email',
}

export type ConfirmFormDataType = {
  email: FORM_NAMES_CONFIRM_DATA.email;
};
