import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type PhotoBoxParams = {
  uploadedImage: string;
};

const initialState: PhotoBoxParams = {
  uploadedImage: '',
};

const PhotoBoxSlice = createSlice({
  name: 'PhotoBoxSlice',
  initialState,
  reducers: {
    setPhotoBoxUploadedImage(state, action: PayloadAction<string>) {
      state.uploadedImage = action.payload;
    },
    resetPhotoBoxUploadedImage(state) {
      state.uploadedImage = '';
    },
  },
});

export {PhotoBoxSlice};
export const photoBoxActions = PhotoBoxSlice.actions;
export const photoBoxReducer = PhotoBoxSlice.reducer;
