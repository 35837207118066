import React from 'react';
import {useScreenResize} from '../../../hooks';
import {device} from '../../../styled/device';
import {SelectRefType} from '../Select';
import {
  QueriedInfiniteScrollSelect,
  QueriedInfiniteScrollSelectProps,
} from './QueriedInfiniteScrollSelect';
import {MobileQueriedInfiniteScrollSelect} from './MobileQueriedInfiniteScrollSelect';

const AdaptiveQueriedInfiniteScrollSelectInternal = <T, V, L>(
  props: QueriedInfiniteScrollSelectProps<T, V, L>,
  ref: React.ForwardedRef<SelectRefType<T, V, L>>,
) => {
  const {isMatch: isMobileMode} = useScreenResize(device.mobileXL);

  if (isMobileMode) {
    return <MobileQueriedInfiniteScrollSelect ref={ref} {...props} />;
  }

  return <QueriedInfiniteScrollSelect ref={ref} {...props} />;
};

const AdaptiveQueriedInfiniteScrollSelect = React.forwardRef(
  AdaptiveQueriedInfiniteScrollSelectInternal,
) as <T, V, L>(
  props: QueriedInfiniteScrollSelectProps<T, V, L> & {
    ref?: React.ForwardedRef<SelectRefType<T, V, L>>;
  },
) => ReturnType<typeof AdaptiveQueriedInfiniteScrollSelectInternal>;

export {AdaptiveQueriedInfiniteScrollSelect};
