import {useChekinSDK} from 'context/ChekinSDK';
import {getLogo} from './utils';
import {useFetchHousing} from 'hooks';
import {useFetchTemplate} from 'hooks/customTheme';
import {GUESTAPP_NAVIGATION_MODE} from 'utils/constants';

function usePropertyLinkInfo() {
  const {templateId, objectId: housingId} = useChekinSDK();
  const {data: housing, isSuccess: isSuccessHousing} = useFetchHousing(housingId, {
    refetchOnWindowFocus: false,
  });
  const {data: template, isSuccess: isSuccessTemplate} = useFetchTemplate({
    enabled: Boolean(templateId),
  });
  const hasTitle = template?.navigation_title_type !== GUESTAPP_NAVIGATION_MODE.logo;
  const title = hasTitle ? housing?.display_name ?? housing?.name : undefined;
  const isHousingLoaded = Boolean(isSuccessHousing && housing?.id);
  const isLoaded = templateId ? isHousingLoaded && isSuccessTemplate : isHousingLoaded;
  const logo = getLogo({
    housingLogo: housing?.picture?.src,
    template,
    isSuccess: isLoaded,
  });

  return {logo, title, isLoaded};
}

export default usePropertyLinkInfo;
