import styled from 'styled-components';
import {device} from '../../styled/device';
import IconButton from '../common/IconButton';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  margin: 16px auto 24px;
  column-gap: 10px;
`;

export const BackButton = styled(IconButton)`
  position: absolute;
  top: -4px;
  margin-left: 12px;

  @media (min-width: ${device.tablet}) {
    display: none;
  }
`;

export const AutoFillText = styled.h2`
  margin: 0 auto;
  padding: 0;
  text-align: center;
  max-width: 430px;
  font-size: 18px;
  font-weight: 600;
  color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.fontSecondary
      : props.theme.colors.$p10};

  @media (min-width: ${device.tablet}) {
    font-size: 20px;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;
