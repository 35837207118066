import React from 'react';
import styled from 'styled-components';
import {device} from '../../../styled/device';

export const overlayStyle: React.CSSProperties = {
  background: 'transparent',
};

export const contentStyle: React.CSSProperties = {
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
  fontSize: '15px',
  color: '#161643',
  padding: '20px',
  border: 'none',
  borderRadius: 6,
  boxShadow: '0 15px 15px 0 rgba(38, 153, 251, 0.1)',
  backgroundColor: '#ffffff',
  zIndex: 99999,
  width: 250,
  textAlign: 'center',
};

export const Wrapper = styled.span`
  & .popup-content :first-child {
    border: none !important;
    box-shadow: 0 15px 15px 0 rgba(38, 153, 251, 0.1) !important;
  }

  & b {
    font-weight: 400;
  }

  @media (max-width: ${device.mobileL}) {
    & .popup-content {
      font-size: 12px !important;
      width: 180px !important;
      padding: 12px !important;
    }
  }
`;

export const Label = styled.div`
  font-family: ${props => props.theme.fonts.fontFamily.body.font_name};
  color: ${props => props.theme.colors.branding.fontSecondary};
`;
