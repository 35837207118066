import {useScreenResize} from 'hooks';
import {useTranslation} from 'react-i18next';
import {useMatch} from 'react-router-dom';
import {device} from 'styled/device';
import Page, {PageProps} from '../../components/Page';
import {AutoFillText} from './views/MRZStartView/styled';

type MRZPageLayoutProps = PageProps;
function MRZPageLayout({children, ...props}: MRZPageLayoutProps) {
  const {isMatch: isMobileMode} = useScreenResize(device.tablet);
  const isMrzTakeView = useMatch('/:token/document-mrz/take');
  const {t} = useTranslation();
  const isUploadDocumentView = useMatch('/:token/document-mrz/upload');
  const pageTitle =
    Boolean(isUploadDocumentView) && isMobileMode
      ? 'scan_your_document'
      : 'auto_fill_form';
  const isSubHeaderVisible =
    (Boolean(isMrzTakeView) && !isMobileMode) || !Boolean(isMrzTakeView);

  return (
    <Page
      isSubHeaderVisible={isSubHeaderVisible}
      isBackButtonDisabled={isMobileMode}
      className="mrz-layout"
      {...props}
    >
      {/* {isMobileMode && (
        <BackIcon onClick={() => navigate(-1)}>
          <StyledIcon name={'backBlueArrow'} size={'15px'} color={'#393ABD'} />
        </BackIcon>
      )} */}
      <AutoFillText className="mrz-layout__autofill-text">{t(pageTitle)}</AutoFillText>
      {children}
    </Page>
  );
}

export default MRZPageLayout;
