import React from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonStyled, Info, InfoDescription, InfoTitle, StyledCheckbox} from './styled';

interface DepositCardProps {
  title: string;
  onChange: () => void;
  selected: boolean;
  onMoreInfo: () => void;
  checkbox?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
}

function DepositCard({
  children,
  title,
  checkbox,
  disabled,
  onMoreInfo,
  selected,
  onChange,
}: DepositCardProps) {
  const {t} = useTranslation();
  return (
    <Info selected={Boolean(checkbox && selected)} className="deposit-card">
      {checkbox ? (
        <StyledCheckbox
          label={title}
          disabled={disabled}
          checked={selected}
          onChange={onChange}
        />
      ) : (
        <InfoTitle className="deposit-card__title">{title}</InfoTitle>
      )}
      <InfoDescription className="deposit-card__description">{children}</InfoDescription>
      <ButtonStyled onClick={onMoreInfo}>{t('more_information')}</ButtonStyled>
    </Info>
  );
}

export {DepositCard};
