import {Trans, useTranslation} from 'react-i18next';
import {Controller, useForm} from 'react-hook-form';
import {TFunction} from 'i18next';
import {SelectOptionType} from '@guestapp/sdk';
import {COUNTRY_CODES} from 'utils/constants';
import {useReservation} from 'context/reservation';
import {useErrorModal, useMutateShortGuest} from 'hooks';
import MultiRadio from 'components/common/MultiRadio';
import {ButtonConfirm, ConfirmGuestText, RadiosContent} from '../styled';

enum FORM_NAMES {
  name = 'name',
  surname = 'surname',
  residence_country = 'residence_country',
  is_confirmed_leader_guest = 'is_confirmed_leader_guest',
}
type formTypes = {
  [FORM_NAMES.is_confirmed_leader_guest]: SelectOptionType<undefined, string>;
};

const confirmGuestOptions = (t: TFunction) => {
  return [
    {label: t('yes'), value: 'yes'},
    {label: t('no'), value: 'no'},
  ];
};

function getLeaderGuestNameAndSurname(leaderGuestFullName: string) {
  const firstSpaceIndex = leaderGuestFullName.indexOf(' ');

  if (firstSpaceIndex === -1) {
    return {name: leaderGuestFullName, surname: ''};
  }

  const name = leaderGuestFullName.slice(0, firstSpaceIndex);
  const surname = leaderGuestFullName.slice(firstSpaceIndex + 1);

  return {name, surname};
}

const buildPayload = ({name, surname}: Record<'name' | 'surname', string>) => {
  return {
    [FORM_NAMES.name]: name,
    [FORM_NAMES.surname]: surname,
    [FORM_NAMES.residence_country]: COUNTRY_CODES.spain,
  };
};
type ConfirmLeaderGuestFormProps = {
  onConfirm: (showInvoiceFields?: boolean) => void;
  onSaveGuest: () => void;
  onFinish: () => void;
};
function ConfirmLeaderGuestForm({
  onConfirm,
  onSaveGuest,
  onFinish,
}: ConfirmLeaderGuestFormProps) {
  const {data} = useReservation();
  const leaderGuestFullName = data?.default_leader_full_name;
  const {t} = useTranslation();
  const {handleSubmit, control, watch} = useForm<formTypes>();
  const options = confirmGuestOptions(t);
  const isConfirmedLeaderGuest = watch(FORM_NAMES.is_confirmed_leader_guest);
  const {displayError, ErrorModal} = useErrorModal();
  const {mutate: createGuestMutation} = useMutateShortGuest({
    onError: () => displayError(),
    onMutate: onSaveGuest,
    onSettled: onFinish,
    onSuccess: () => onConfirm(true),
  });

  const onSubmit = (data: formTypes) => {
    const isLeaderGuestConfirmed =
      data[FORM_NAMES.is_confirmed_leader_guest].value === 'yes';
    if (isLeaderGuestConfirmed && leaderGuestFullName) {
      const {name, surname} = getLeaderGuestNameAndSurname(leaderGuestFullName);
      const payload = buildPayload({name, surname});
      createGuestMutation(payload);
      return;
    }

    onConfirm(false);
  };

  return (
    <div>
      <ConfirmGuestText>
        <Trans
          i18nKey="confirm_leaguer_guest_text"
          values={{name: leaderGuestFullName}}
        />
      </ConfirmGuestText>
      <RadiosContent>
        <Controller
          name={FORM_NAMES.is_confirmed_leader_guest}
          control={control}
          render={({field}) => (
            <MultiRadio disabled={false} options={options} {...field} />
          )}
        />
      </RadiosContent>
      <ButtonConfirm
        primary
        label={t('confirm')}
        disabled={!isConfirmedLeaderGuest}
        onClick={handleSubmit(onSubmit)}
      />
      <ErrorModal />
    </div>
  );
}
export {ConfirmLeaderGuestForm};
