import {FetcherSettings} from '../api/apiClient';
import {DocumentsApi} from '../api';

class DocumentsService {
  documentsApi: DocumentsApi;

  constructor() {
    this.documentsApi = new DocumentsApi();
  }

  getContracts(params = '', resolverSettings?: FetcherSettings) {
    return this.documentsApi.getContracts(params, resolverSettings);
  }

  getPrivacyPolicy(id: string, resolverSettings?: FetcherSettings) {
    return this.documentsApi.getPrivacyPolicy(id, resolverSettings);
  }
}

export {DocumentsService};
