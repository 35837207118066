import styled from 'styled-components';
// const activeCheckboxColor = 'linear-gradient(180deg, #385BF8 0%, #2148FF 100%)';
// const inactiveCheckboxColo =
// `radial-gradient(50% 50.00% at 50% 50.00%, ${props.theme.colors.branding.fontSecondary} 0%, ${props=>props.theme.colors.branding.fontSecondary} 100%)`;

export const SwitchLabel = styled.label<{checked: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 40px;
  height: 20px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  background: ${props =>
    props.checked
      ? `linear-gradient(180deg, ${props.theme.colors.branding.primary} 0%, ${props.theme.colors.branding.primary} 100%)`
      : `radial-gradient(50% 50.00% at 50% 50.00%, ${props.theme.colors.branding.fontSecondary} 0%, ${props.theme.colors.branding.fontSecondary} 100%)`};

  & .switcher__button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 45px;
    transition: 0.2s;
    background: ${props => props.theme.colors.branding.boxesModals};
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  &:active {
    .switcher__button {
      width: 18px;
    }
  }
`;

export const SwitchCheckbox = styled.input`
  height: 0;
  width: 0;
  display: none;
  &:checked {
    & + .switcher__label .switcher__button {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }
`;

export const SwitchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Label = styled.div`
  width: calc(100% - 48px);
  font-size: ${props => props.theme.fonts.sizes.small};
  font-style: normal;
  font-weight: ${props => props.theme.fonts.fontWeight.regular};
  line-height: normal;
  letter-spacing: -0.15px;
`;
