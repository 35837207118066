import {useQuery} from '@tanstack/react-query';
import {Housing} from '@guestapp/sdk';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {useChekinSDK} from 'context/ChekinSDK';

type OptionsProps = {
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
  refetchOnReconnect?: boolean;
  initialData?: Housing;
};

function useFetchHousing(housingId?: string, options?: OptionsProps) {
  const {getHousing} = useChekinSDK();
  const query = useQuery<Housing, Error>(
    [QUERY_CACHE_KEYS.housing],
    () => getHousing(housingId || ''),
    options,
  );

  return query;
}

export {useFetchHousing};
