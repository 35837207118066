import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useActions, useModalControls, usePath} from 'hooks';
import {PATHS} from 'Routes';
import {ButtonProps} from '../Button/Button';
import ModalSheet from '../ModalSheet';
import StandardModalSheet from '../StandardModalSheet';

export type SkipIVModalProps = {
  required?: boolean;
};

const defaultProps: Partial<SkipIVModalProps> = {
  required: false,
};

export type SkipIVModalRef = {
  open: (link?: string) => void;
};

const SkipIVModal = React.forwardRef<SkipIVModalRef, SkipIVModalProps>(
  ({required}, ref) => {
    const {t} = useTranslation();
    const {
      isOpen: isOpenSkipVerificationModal,
      closeModal: closeSkipModal,
      openModal: openSkipModal,
    } = useModalControls();
    const {id: guestId} = useParams<{id: string}>();
    const navigate = useNavigate();
    const {resetAllIVState} = useActions();

    const {getMainPath} = usePath();

    const onSkippedModal = React.useCallback(() => {
      const pathIdOrEmptyString = guestId || '';
      resetAllIVState();
      navigate(`${getMainPath(PATHS.addPersonalDataForm)}/${pathIdOrEmptyString}`);
    }, [resetAllIVState, navigate, getMainPath, guestId]);

    const modalSheetButtons: ButtonProps[] = React.useMemo(() => {
      const primaryButton: ButtonProps = {
        label: 'skip_by_now',
        primary: true,
        onClick: onSkippedModal,
      };
      const secondaryButton: ButtonProps = {
        link: true,
        label: 'continue_verification',
        onClick: closeSkipModal,
      };
      return [primaryButton, secondaryButton];
    }, [closeSkipModal, onSkippedModal]);

    React.useImperativeHandle(
      ref,
      () => {
        return {
          open: openSkipModal,
        };
      },
      [openSkipModal],
    );

    return (
      <ModalSheet
        isOpenModalSheet={isOpenSkipVerificationModal}
        handleCloseModal={closeSkipModal}
        title={t('skip_verification_question')}
        withCloseButton
        closeButtonType="click"
      >
        <StandardModalSheet
          buttons={modalSheetButtons}
          text={t('skip_iv_process_text')}
        />
      </ModalSheet>
    );
  },
);

SkipIVModal.defaultProps = defaultProps;
export {SkipIVModal};
