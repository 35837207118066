import React from 'react';
import toast, {Toaster} from 'react-hot-toast';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {PATHS} from 'Routes';
import {useAppSelector} from 'hooks';
import {CustomIcon} from '@guestapp/ui';

function AllianzAddedDealsToCartToast() {
  const {t} = useTranslation();
  const dealIds = useAppSelector(state => state.alliaznInsurance.dealIds);
  const {state} = useLocation();

  React.useEffect(() => {
    const insuredGuestsNumber = dealIds.length;
    if (
      insuredGuestsNumber > 0 &&
      state?.from === PATHS.travelInsurances.allianz.selectGuests
    ) {
      toast.success(`x${insuredGuestsNumber} ${t('allianz_insurance_added_to_cart')} `, {
        icon: <CustomIcon name="checkedBlack" />,
        duration: 4000,
      });
    }
  }, [t, dealIds, state]);

  return <Toaster position="bottom-right" />;
}

export {AllianzAddedDealsToCartToast};
