import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useTheme} from 'styled-components';
import {PATHS} from 'Routes';
import {useNavigationCustom, usePath, usePaymentsCart, useSubHeaderConfig} from 'hooks';
import {useSummary} from 'context/summary';
import {CustomIcon} from '@guestapp/ui';
import {ShoppingCartIconContainer, PaymentsBage} from './styled';

function ShoppingCartButton() {
  const {arePaymentsAvailable} = useSummary();
  const {paymentsCart} = usePaymentsCart({refetchOnMount: false});
  const payNowItems = paymentsCart?.pay_now_payments?.length;
  const {
    config: {hideMenu},
  } = useSubHeaderConfig();
  const navigate = useNavigate();
  const {getFullPath} = usePath();
  const {currentPage} = useNavigationCustom();
  const theme = useTheme();

  const goToPayments = React.useCallback(() => {
    navigate(getFullPath(PATHS.payments.main), {state: {from: currentPage}});
  }, [navigate, getFullPath, currentPage]);

  return (
    <>
      {arePaymentsAvailable && !hideMenu && (
        <ShoppingCartIconContainer onClick={goToPayments} className="shopping-btn">
          <CustomIcon
            name="shoppingCart"
            size={24}
            color={theme.hasTemplate ? theme.colors.branding.icons : '#152946'}
          />
          {Boolean(payNowItems) && (
            <PaymentsBage color="rgb(248, 75, 122)">{payNowItems}</PaymentsBage>
          )}
        </ShoppingCartIconContainer>
      )}
    </>
  );
}

export {ShoppingCartButton};
