import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {DefaultTheme, useTheme} from 'styled-components';
import {BookingSummary, ShortReservation} from '@guestapp/sdk';
import {device} from '@guestapp/ui/src/utils/device';
import {useReservation} from 'context/reservation';
import {useSummary} from 'context/summary';
import {useCustomTheme} from 'context/customTheme';
import {useScreenResize} from 'hooks';
import {CustomIcon} from '@guestapp/ui';
import {
  Content,
  DateInfo as StyledDateInfo,
  DatesArrow,
  FullDate,
  Header,
  MainDate,
  Title,
  Wrapper,
} from './styled';

function getShortTime(time?: string) {
  return time?.substring(0, 5);
}

function getFullDate(date: string) {
  return dayjs(date).utc().format('DD MMM YYYY').toUpperCase();
}

const getIconColor = (hasTemplate: boolean, isMobile: boolean, theme: DefaultTheme) => {
  if (!hasTemplate && !isMobile) {
    return '#152946';
  }
  if (isMobile) {
    return theme.colors.branding.boxesModals;
  }
  return theme.colors.branding.fontPrimary;
};

const getDatesInfo = (reservation?: ShortReservation, summary?: BookingSummary) => {
  if (!reservation) return {};

  return {
    checkIn: {
      mainDate: summary?.is_remote_access_enabled
        ? getShortTime(reservation.access_start_time)
        : undefined,
      fullDate: getFullDate(reservation?.check_in_date),
    },
    checkOut: {
      mainDate: summary?.is_remote_access_enabled
        ? getShortTime(reservation.access_end_time)
        : undefined,
      fullDate: getFullDate(reservation?.check_out_date),
    },
  };
};

function BookingDatesBox() {
  const {t} = useTranslation();
  const {data: reservation} = useReservation();
  const {data: summary} = useSummary();
  const {hasTemplate} = useCustomTheme();
  const theme = useTheme();
  const {isMatch: isMobile} = useScreenResize(device.laptopM);
  const datesInfo = getDatesInfo(reservation, summary);

  const iconColor = getIconColor(hasTemplate, isMobile, theme);

  return (
    <Wrapper $hasTemplate={hasTemplate} className="chekin-booking-box__container">
      <Header $hasTemplate={hasTemplate} className="chekin-booking-box__header">
        {t('booking_dates')}
      </Header>
      <Content className="chekin-booking-box__content">
        <StyledDateInfo className="chekin-booking-box__info-chekin">
          <Title className="chekin-booking-box__info-title">{t('check_in_short')}</Title>
          <MainDate
            className="chekin-booking-box__info-hours"
            isNoHours={Boolean(summary?.is_remote_access_enabled)}
          >
            {datesInfo.checkIn?.mainDate}
          </MainDate>
          <FullDate
            className="chekin-booking-box__info-date"
            isNoHours={Boolean(!summary?.is_remote_access_enabled)}
          >
            {datesInfo.checkIn?.fullDate}
          </FullDate>
        </StyledDateInfo>
        <DatesArrow>
          <CustomIcon
            name="arrowDates"
            custom
            color={iconColor}
            className="chekin-booking-box__content-icon"
          />
        </DatesArrow>
        <StyledDateInfo className="chekin-booking-box__info-chekout">
          <Title className="chekin-booking-box__info-title">{t('check_out_short')}</Title>
          <MainDate
            className="chekin-booking-box__info-hours"
            isNoHours={Boolean(summary?.is_remote_access_enabled)}
          >
            {datesInfo.checkOut?.mainDate}
          </MainDate>
          <FullDate
            className="chekin-booking-box__info-date"
            isNoHours={Boolean(!summary?.is_remote_access_enabled)}
          >
            {datesInfo.checkOut?.fullDate}
          </FullDate>
        </StyledDateInfo>
      </Content>
    </Wrapper>
  );
}

export {BookingDatesBox};
