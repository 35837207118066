import {ReactNode} from 'react';
import {useScreenResize} from 'hooks';
import {device} from 'styled/device';
import {Navbar as MobileNavbar} from './Components/Navbar';
import {Footer} from './Components/Footer';
import {PageWrapper, Wrapper} from './styled';

type PageUniversalLinkProps = {
  children: ReactNode;
  className?: string;
};

const defaultProps: PageUniversalLinkProps = {
  children: '',
};

function PageUniversalLink({children, className}: PageUniversalLinkProps) {
  const {isMatch: isMobile} = useScreenResize(device.tablet);
  return (
    <PageWrapper>
      {isMobile && <MobileNavbar />}
      {/* TODO refactor this whole implementation implementing this component on a higher level */}
      <Wrapper className={'page_content ' + className}>{children}</Wrapper>
      <Footer />
    </PageWrapper>
  );
}

PageUniversalLink.defaultProps = defaultProps;

export {PageUniversalLink};
