import React from 'react';
import {Counter} from '../Counter';
import {Box, Boxes, Details, Subtitle, Title} from './styled';

type ConfirmCounterProps = {
  title?: string;
  subtitle?: string | React.ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
  value: number;
  onChange: (value: number) => void;
  error?: string;
};
const CounterStepper = React.forwardRef(
  (
    {title, subtitle, value, onChange, error, disabled, readOnly}: ConfirmCounterProps,
    _,
  ) => {
    return (
      <Box $readOnly={readOnly} className="counter-stepper">
        <Details className="counter-stepper__details">
          {Boolean(title) && (
            <Title className="counter-stepper__title">
              {title}
              {readOnly ? ':' : ''}
            </Title>
          )}
          <Subtitle className="counter-stepper__subtitle">{subtitle}</Subtitle>
        </Details>
        <Boxes>
          <Counter
            value={value}
            onChange={onChange}
            disabled={disabled}
            readOnly={readOnly}
            error={error}
          />
        </Boxes>
      </Box>
    );
  },
);

export {CounterStepper};
