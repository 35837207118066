import {apiClient, FetcherSettings} from './apiClient';
import {
  AllianzDocument,
  AllianzOrder,
  TotalPrice,
  AllianzPlanFromDb,
} from '../utils/allianz';

class AllianzInsuranceApi {
  ENDPOINTS = {
    getPlanByUser: (params = '') =>
      `api/v3/upselling/plans/allianz/personalised?${params}`,
    orders: (params = '') => `api/v3/upselling/plans/allianz/orders/?${params}`,
    calculateTotalPrice: (params = '') =>
      `api/v3/upselling/plans/allianz/total?${params}`,
    documents: (params = '') => `api/v3/upselling/allianz-documents/?${params}`,
  };

  async getPlanByUser(params = '', fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<AllianzPlanFromDb>(
      this.ENDPOINTS.getPlanByUser(params),
      fetcherSettings,
    );
    return response.body;
  }
  async getOrders(params = '', fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<AllianzOrder[]>(
      this.ENDPOINTS.orders(params),
      fetcherSettings,
    );
    return response.body;
  }
  async calculateTotalPrice(params = '', fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<TotalPrice[]>(
      this.ENDPOINTS.calculateTotalPrice(params),
      fetcherSettings,
    );
    return response.body;
  }

  async getDocuments(params = '', fetcherSettings?: FetcherSettings) {
    const response = await apiClient.get<AllianzDocument[]>(
      this.ENDPOINTS.documents(params),
      fetcherSettings,
    );
    return response.body;
  }
}

export {AllianzInsuranceApi};
