import CustomIcon from 'components/common/CustomIcon';
import {EmptyScreen} from './styled';
import {useTheme} from 'styled-components';
import {IconNames} from 'components/common/CustomIcon/CustomIcon';

type EmptyStateProps = {
  imageSrc: IconNames;
  title: string;
  description: string;
};
function EmptyState({imageSrc, title, description}: EmptyStateProps) {
  const theme = useTheme();
  return (
    <EmptyScreen className="chekin-empty-screen__container">
      <CustomIcon
        name={imageSrc}
        color={
          theme.hasTemplate
            ? theme.colors.branding.primary
            : theme.colors.buttons.mainButtonBackground
        }
        size={250}
        custom
        className="chekin-empty-state__icon"
      />
      {/* <img src={imageSrc} width={200} height={200} alt="" /> */}
      <h1 className="chekin-empty-screen__title">{title}</h1>
      <p className="chekin-empty-screen__description">{description}</p>
    </EmptyScreen>
  );
}

export {EmptyState};
