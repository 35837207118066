import React from 'react';
import {useNavigate, useMatch} from 'react-router-dom';
import {AUTH_INIT_MODELS} from '@guestapp/sdk';
import {PATHS} from 'Routes';
import {HOUSING_ID_KEY, SHARE_LINK} from 'utils/constants';
import {useChekinSDK} from 'context/ChekinSDK';
import {usePath} from 'hooks';
import {LocalStorage} from '@guestapp/core';

function useHousingUniversalLink() {
  const {objectId, link, mainToken, modelName} = useChekinSDK();

  const navigate = useNavigate();
  const {getFullPath} = usePath();
  const isHomeView = useMatch('/:token');
  React.useEffect(
    function handleIsUniversalLinkForSearchReservations() {
      const housingId = LocalStorage.get(HOUSING_ID_KEY) || objectId;
      const l = LocalStorage.get(SHARE_LINK) || link;

      if (modelName === AUTH_INIT_MODELS.housing && housingId && isHomeView) {
        LocalStorage.set(HOUSING_ID_KEY, housingId);
        navigate(getFullPath(PATHS.searchReservation.main), {
          replace: true,
          state: {housingId, l, mainToken},
        });
      }
    },
    [getFullPath, link, mainToken, modelName, objectId, navigate, isHomeView],
  );
}

export {useHousingUniversalLink};
