import React from 'react';
import {useScreenResize} from '../../../hooks';
import {device} from '../../../styled/device';
import {Select, SelectProps, SelectRefType} from './Select';
import {MobileSelect} from './MobileSelect';

function AdaptiveSelectInternal<T, V, L>(
  props: SelectProps<T, V, L>,
  ref: React.ForwardedRef<SelectRefType<T, V, L>>,
) {
  const {isMatch: isMobileMode} = useScreenResize(device.mobileXL);

  if (isMobileMode) {
    return <MobileSelect ref={ref} {...props} />;
  }

  return <Select ref={ref} {...props} />;
}

const AdaptiveSelect = React.forwardRef(AdaptiveSelectInternal) as <T, V, L>(
  props: SelectProps<T, V, L> & {ref?: React.ForwardedRef<SelectRefType<T, V, L>>},
) => ReturnType<typeof AdaptiveSelectInternal>;

export {AdaptiveSelect};
