import React from 'react';
import {useNavigate} from 'react-router-dom';
import {AllianzPlan} from './types';
import {COUNTRY_CODES} from 'utils/constants';
import {ALLIANZ_STATUSES} from '@guestapp/sdk/src/utils/allianz/constanst';
import {PATHS} from 'Routes';
import {isAllianzFeatureActivated} from 'utils/common';
import {
  useActions,
  useAppSelector,
  useModalControls,
  usePath,
  useTimeoutRef,
} from 'hooks';
import AllianzLoadingModal from 'components/AllianzLoadingModal';
import {useSummary} from 'context/summary';

const userValidCountries = [COUNTRY_CODES.spain];

function useAllianzPlans() {
  const {setLegals, setPlans, setSelectedPlan} = useActions();
  const [redirect, setRedirect] = React.useState(false);
  const [guestId, setGuestId] = React.useState('');
  const allianzModalTimeoutRef = useTimeoutRef();
  const navigate = useNavigate();
  const {getFullPath} = usePath();
  const {data: summary} = useSummary();
  const {
    isOpen: isAllianzModalOpen,
    openModal: openAllianzModal,
    closeModal: closeAllianzModal,
  } = useModalControls();

  const plans = useAppSelector(state => state.alliaznInsurance.plans);

  const selectedPlan = React.useMemo(() => {
    return plans.find(p => p.selected);
  }, [plans]);

  const initAllianzPlans = React.useCallback(
    (data: AllianzPlan[]) => {
      setPlans(data);
    },
    [setPlans],
  );

  const checkAllianzFlow = React.useCallback(
    ({
      residence_country,
      guestId,
      chekinDate,
      allianzStatus,
      housingCountry,
      nights,
      hasAllianzActive,
      isAllianzUser,
    }: {
      residence_country: string;
      guestId: string;
      chekinDate?: string;
      allianzStatus?: ALLIANZ_STATUSES;
      housingCountry?: string;
      nights?: number;
      hasAllianzActive?: boolean;
      isAllianzUser?: boolean;
    }) => {
      const isAllianzAvailable =
        !isAllianzUser &&
        !hasAllianzActive &&
        userValidCountries.includes(residence_country) &&
        isAllianzFeatureActivated(chekinDate, allianzStatus, housingCountry, nights);
      if (isAllianzAvailable) {
        setGuestId(guestId);
        setRedirect(true);
      }
    },
    [],
  );

  const LoadingModal = React.useCallback(() => {
    return (
      <AllianzLoadingModal
        isOpen={isAllianzModalOpen}
        housingName={summary?.display_name}
      />
    );
  }, [isAllianzModalOpen, summary?.display_name]);

  const openAllianzModalWithTimeout = React.useCallback(() => {
    openAllianzModal();
    allianzModalTimeoutRef.current = setTimeout(() => {
      const allianzSelectPlanPath = `${PATHS.travelInsurances.main}/${PATHS.travelInsurances.allianz.main}/${PATHS.travelInsurances.allianz.selectPlan}`;
      closeAllianzModal();
      navigate(getFullPath(allianzSelectPlanPath), {state: {guestId}});
    }, 4000);
  }, [
    closeAllianzModal,
    getFullPath,
    allianzModalTimeoutRef,
    guestId,
    navigate,
    openAllianzModal,
  ]);

  return {
    plans,
    selectedPlan,
    setLegals,
    initAllianzPlans,
    setSelectedPlan,
    checkAllianzFlow,
    LoadingModal,
    openAllianzModalWithTimeout,
    redirect,
  };
}

export {useAllianzPlans};
