import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {PATHS} from 'Routes';
import {usePath} from 'hooks';
import {ButtonProps} from 'components/common/Button/Button';
import StandardModalSheet from 'components/common/StandardModalSheet';

type DiscardIVChangesProps = {
  onClose: () => void;
  resetIVProcess: () => void;
};

function DiscardIVChanges({onClose, resetIVProcess}: DiscardIVChangesProps) {
  const navigate = useNavigate();
  const {getMainPath} = usePath();

  const {t} = useTranslation();
  const discardIVChangesAndReset = React.useCallback(() => {
    resetIVProcess();
    navigate(getMainPath(PATHS.home));
  }, [resetIVProcess, navigate, getMainPath]);

  const discardChangeButtons: ButtonProps[] = React.useMemo(() => {
    const primaryButton = {
      label: t('discard_changes_leaving'),
      primary: true,
      onClick: discardIVChangesAndReset,
    };
    const secondaryButton: ButtonProps = {
      link: true,
      label: t('continue_process'),
      onClick: onClose,
    };
    return [primaryButton, secondaryButton];
  }, [t, onClose, discardIVChangesAndReset]);

  const discardIVChangesText = React.useMemo(
    () => (
      <Trans i18nKey="discard_iv_changes_text">
        Leaving the process before completing the registration will
        <b>discard the photos</b> uploaded <b>or the data</b> completed so far and you
        will have to start over.
      </Trans>
    ),
    [],
  );

  return (
    <StandardModalSheet
      buttons={discardChangeButtons}
      text={discardIVChangesText}
      className="discard-iv-changes-modal"
    />
  );
}

export {DiscardIVChanges};
