import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import styled, {css} from 'styled-components';
import {device} from '../../../styled/device';
import Modal from '../Modal';

export const selectWidth = '100%';
// TODO Any type. Should be fixed
// eslint-disable-next-line
export const applySelectStyles = <T>(select: any): T => styled(select)`
  .select__control {
    transition: 0.1s;
    border: 1px solid ${props => props.theme.colors.$gray};
  }

  & .select__value-container {
    text-align: initial;
    padding: 0;
    text-overflow: ellipsis;
  }

  & .select__input-container {
    font-weight: ${props => props.theme.fonts.fontWeight.regular};
    margin: 0;
    color: ${props => props.theme.colors.branding.fontPrimary};
  }

  & .select__dropdown-indicator {
    padding: 0;
  }

  & .select__single-value,
  & .select__placeholder {
    font-family: inherit;
    font-weight: ${props => props.theme.fonts.fontWeight.regular};
    font-size: ${props => props.theme.fonts.sizes.small};
    margin-left: 0;
    padding-left: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & .select__single-value {
    color: ${props => props.theme.colors.branding.fontPrimary};
    user-select: none;
    font-size: ${props => props.theme.fonts.sizes.medium};
  }

  & .select__placeholder {
    color: ${props => props.theme.colors.$p2};
    user-select: none;
  }

  & .select__input input {
    margin: 0;
    padding: 0;
    font-family: inherit;
    font-size: ${props => props.theme.fonts.sizes.medium};
    color: ${props => props.theme.colors.$n1};
    user-select: none;
  }

  & .select__option.select__option--is-selected {
    background-color: ${props =>
      props.theme.hasTemplate
        ? props.theme.colors.branding.backgroundSecondary
        : props.theme.colors.buttons.mainButtonBackground};
    color: ${props =>
      props.theme.hasTemplate ? props.theme.colors.fontPrimary : props.theme.colors.$p6};
    font-weight: ${props => props.theme.fonts.fontWeight.medium};
  }

  & .select__option.select__option--is-focused.select__option--is-selected {
    cursor: default;
    background-color: ${props =>
      props.theme.hasTemplate
        ? props.theme.colors.branding.backgroundSecondary
        : props.theme.colors.buttons.mainButtonBackground};
    color: ${props =>
      props.theme.hasTemplate ? props.theme.colors.fontPrimary : props.theme.colors.$p6};
    font-weight: ${props => props.theme.fonts.fontWeight.medium};
  }

  & .select__option.select__option--is-focused {
    cursor: pointer;
    background-color: ${props =>
      props.theme.hasTemplate
        ? props.theme.colors.branding.backgroundSecondary
        : props.theme.colors.buttons.mainButtonBackground};
    color: ${props =>
      props.theme.hasTemplate ? props.theme.colors.fontPrimary : props.theme.colors.$p6};
    font-weight: ${props => props.theme.fonts.fontWeight.medium};
  }

  & .select__option.select__option--is-disabled.select__option,
  .select__option--is-disabled.select__option.select__option--is-focused {
    color: ${props =>
      props.theme.hasTemplate ? props.theme.colors.fontPrimary : props.theme.colors.$p6};
    background: transparent;
    cursor: default;
  }

  & .select__menu {
    font-family: inherit;
    font-weight: ${props => props.theme.fonts.fontWeight.regular};
    font-size: ${props => props.theme.fonts.sizes.medium};
    width: ${selectWidth};
    height: auto;
    min-height: 40px;
    max-height: 246px;
    box-shadow: 0 10px 16px #0f0f0f0d;
    border-radius: 0 0 7px 7px;
    z-index: 5;
    margin-top: 0;
    color: ${props => props.theme.colors.branding.fontPrimary};
    background-color: ${props => props.theme.colors.branding.boxesModals};

    @media (max-width: ${device.mobileM}) {
      width: 250px;
    }

    &:after {
      content: '';
      display: block;
      height: 50px;
      width: 2px;
      position: absolute;
    }
  }

  & .select__menu-notice {
    /* color: ${props => props.theme.colors.$n1}; */
    text-align: left;
    margin-top: 10px;
    font-size: ${props => props.theme.fonts.sizes.medium};
  }

  & .select__menu-list {
    padding: 16px 0 18px;
    box-shadow: 0 30px 30px #2148ff1a;
    width: ${selectWidth};
    height: auto;
    min-height: 50px;
    max-height: 246px;
    border-radius: 0 0 7px 7px;

    ${props =>
      props.showScrollbar &&
      css`
        ::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
        }
        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
          -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
      `};

    @media (max-width: ${device.mobileM}) {
      width: 250px;
    }
  }

  & .select__option {
    font-weight: ${props => props.theme.fonts.fontWeight.medium};
    text-align: left;
    color: ${props =>
      props.theme.hasTemplate
        ? props.theme.colors.branding.fontPrimary
        : props.theme.colors.$p10};
    padding: 2px 24px;
    box-sizing: border-box;
    background-color: transparent;
    user-select: none;

    &:active {
      background-color: ${props =>
        props.theme.hasTemplate
          ? props.theme.colors.branding.primary
          : props.theme.colors.buttons.mainButtonBackground};
      color: ${props =>
        props.theme.hasTemplate
          ? props.theme.colors.branding.fontPrimary
          : props.theme.colors.$p6};
    }
  }

  & .select__option:last-child {
    border-color: transparent;
  }

  & .select__menu-notice {
    font-weight: ${props => props.theme.fonts.fontWeight.medium};
    font-size: ${props => props.theme.fonts.sizes.medium};
    text-align: center;
    color: ${props => props.theme.colors.$n5};
    padding: 0 12px;
    margin: 0;
  }

  ${props =>
    props.mobileVersion &&
    css`
      width: 100% !important;
      height: 100%;

      & .select__control-wrapper {
        padding: 0 24px 24px;
      }

      &&& .select__menu {
        position: relative;
        width: 100% !important;
        min-height: initial;
        height: auto;
        max-height: calc(100vh - 140px);
        box-shadow: none;
        border-radius: 0;
        margin-top: 0;
        top: 0;
        overflow: auto;
        margin-bottom: 0;

        ${props.isSearchable &&
        css`
          max-height: calc(100vh - 204px);
          height: 100%;
        `}

        &:after {
          content: none;
        }
      }

      &&& .select__menu-list {
        display: flex;
        flex-direction: column;
        grid-row-gap: 20px;
        padding: 0;
        box-shadow: none;
        width: 100% !important;
        height: auto;
        min-height: initial;
        max-height: initial;
        border-radius: 0;
        color: ${props => props.theme.colors.branding.fontPrimary};
      }
    `}
`;

export const MobileSelectModal = styled(Modal)<{
  pressToTheTop?: boolean;
  height?: string;
}>`
  max-width: 869px;
  margin: 60px auto auto;
  background: ${props => props.theme.colors.branding.boxesModals};
  height: ${props =>
    props.height
      ? props.height
      : props.pressToTheTop
      ? 'calc(100% - 40px)'
      : 'auto'} !important;
  overflow: initial;

  &.allianz-loading-modal {
    display: grid;
    place-content: center;
  }

  & > div {
    min-height: auto;
    padding: 50px 0;
    height: ${props => (props.pressToTheTop ? '100%' : 'auto')};
  }

  @media (max-width: ${device.mobileXL}) {
    margin: 0;

    & > div {
      padding: 20px 24px;
    }
  }
`;

export const IconWrapper = styled.div`
  border-right: 1px solid ${({theme}) => theme.colors.$n7};
  padding-right: 10px;
  margin-left: 10px;
`;

export const ReactSelect = applySelectStyles<Select>(Select);
export const AsyncReactSelect = applySelectStyles<AsyncSelect>(AsyncSelect);
export const CreatableReactSelect = applySelectStyles<CreatableSelect>(CreatableSelect);
