import styled from 'styled-components';
import ModalSheet from 'components/common/ModalSheet';
import {Button} from '@guestapp/ui';
import {device} from 'styled/device';

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.36px;
  text-align: center;
  color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.fontPrimary : theme.colors.$p10};
`;

export const Description = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.fontSecondary : theme.colors.$p10};
`;

export const ButtonModal = styled(Button)`
  /* margin: 48px auto; */
  justify-content: center;
  margin-top: 48px;
  width: 295px;
  text-transform: uppercase;

  @media (max-width: ${device.mobileL}) {
    height: 48px;
    min-width: 211px;
  }
`;

export const WarningIcon = styled.img`
  width: 50px;
  height: 50px;
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const StyledModal = styled(ModalSheet)`
  &-content {
    max-width: 375px;
  }
`;
