import React from 'react';
import {useQuery} from '@tanstack/react-query';
import {CURRENCIES_SIGNS, QUERY_CACHE_KEYS} from '../utils/constants';
import {useChekinSDK} from '../context/ChekinSDK';
import {PAYMENT_PROVIDERS} from '@guestapp/sdk';

const CACHE_TIME = 60;
const STALE_TIME = 30;
const DEFAULT_PROVIDER = PAYMENT_PROVIDERS.stripe;

function usePaymentSettings() {
  const {isTokenValid, getPaymentsSettings} = useChekinSDK();

  const {data: paymentSettings, isLoading} = useQuery(
    [QUERY_CACHE_KEYS],
    () => getPaymentsSettings(),
    {
      enabled: isTokenValid,
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * CACHE_TIME,
      staleTime: 1000 * 60 * STALE_TIME,
    },
  );

  const paymentProvider = paymentSettings?.provider || DEFAULT_PROVIDER;

  const isStripePaymentProvider = paymentProvider === PAYMENT_PROVIDERS.stripe;
  const isPaycometPaymentProvider = paymentProvider === PAYMENT_PROVIDERS.paycomet;

  const hasPaymentSettings = Boolean(paymentSettings?.id);

  const getArePaymentProviderValid = React.useCallback(() => {
    if (isStripePaymentProvider) {
      return paymentSettings?.status === 'VALID';
    }

    return true;
  }, [isStripePaymentProvider, paymentSettings?.status]);

  const arePaymentSettingsValid = getArePaymentProviderValid();

  const currency = paymentSettings?.currency;
  const currencySymbol = currency ? CURRENCIES_SIGNS[currency] : '';

  return {
    isPaycometPaymentProvider,
    currencySymbol,
    currency,
    arePaymentSettingsValid,
    isStripePaymentProvider,
    hasPaymentSettings,
    paymentSettings,
    paymentProvider,
    isLoading,
  };
}

export {usePaymentSettings};
