import {useEffect} from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import {filterSentryLogging} from 'utils/sentry';

function setupSentry() {
  const dsn = process.env.REACT_APP_SENTRY_DSN;
  const environment = process.env.REACT_APP_ENV;

  if (dsn) {
    Sentry.init({
      dsn,
      environment,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.captureConsoleIntegration({
          levels: ['error'],
        }),
      ],
      beforeSend: filterSentryLogging,
      tracesSampleRate: 0.5,
      maxValueLength: 10000,
    });
  }
}

export {setupSentry};
