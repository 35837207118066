import store from 'store2';
import AbstractStorage from './AbstractStorage';

class LocalStorage extends AbstractStorage {
  static get<T = string>(key: string): T | null {
    const data = store.get(key);
    if (!data) return null;
    return data;
  }

  static set<T>(key: string, value: T): void {
    if (value) {
      store.set(key, value);
    }
  }

  static remove(key: string): void {
    store.remove(key);
  }

  static clear(): void {
    store.clearAll();
  }
}

export {LocalStorage};
