import {Template} from '../utils/types';
import {apiClient, FetcherSettings} from './apiClient';

class ThemeApi {
  ENDPOINTS = {
    getCustomTheme: (id = '') => `api/v4/branding/templates/${id}/`,
  };

  getCustomTheme(id: string, fetcherSettings?: FetcherSettings) {
    return apiClient.get<Template>(this.ENDPOINTS.getCustomTheme(id), fetcherSettings);
  }
}

export {ThemeApi};
