import React from 'react';
import {FieldError} from 'react-hook-form';
import {SelectOptionType} from '@guestapp/sdk';
import clsx from 'clsx';
import {RadioInput, RadioLabel, RadioOption, RadioWrapper} from './styled';

type RadioOptionType<V = string> = SelectOptionType<unknown, V>;

export type MultiRadioProps = {
  onChange: (opt: RadioOptionType) => void;
  options: RadioOptionType[];
  value?: RadioOptionType;
  defaultValue?: string | number;
  multiple?: boolean;
  disabled?: boolean;
  className?: string;
  error?: FieldError;
  name?: string;
  fieldSetId?: string;
  isCustomBox?: boolean;
};

const MultiRadio = React.forwardRef<HTMLDivElement, MultiRadioProps>(
  (
    {
      value,
      onChange,
      options,
      disabled,
      multiple,
      className,
      defaultValue,
      fieldSetId,
      isCustomBox,
    },
    ref,
  ) => {
    React.useEffect(
      function setDefaultValue() {
        const option = options.find(opt => opt.value === defaultValue);
        if (defaultValue && option && !value) {
          onChange(option);
        }
      },
      [defaultValue, onChange, options, value],
    );
    const RadioOptions = React.useMemo(() => {
      return options.map(opt => {
        const isChecked = opt.value === value?.value;

        const classes = clsx(className, 'multi-radio__option', {
          'multi-radio-option_checked': isChecked,
        });
        const handleChange = () => {
          if (!disabled) onChange(opt);
        };

        return (
          <RadioOption
            className={classes}
            key={opt.value}
            isCustomBox={isCustomBox}
            isChecked={isChecked}
          >
            <RadioInput
              multiple={multiple}
              type="radio"
              id={opt.value}
              name={fieldSetId ? fieldSetId : 'drone'}
              value={opt.value}
              onChange={handleChange}
              checked={isChecked}
            />
            <RadioLabel htmlFor={opt.value} className="multi-radio__label">
              {opt.label}
            </RadioLabel>
          </RadioOption>
        );
      });
    }, [
      options,
      value?.value,
      multiple,
      disabled,
      onChange,
      fieldSetId,
      isCustomBox,
      className,
    ]);

    return (
      <RadioWrapper ref={ref} className={clsx(className, 'multi-radio')}>
        {RadioOptions}
      </RadioWrapper>
    );
  },
);

export {MultiRadio};
export type {RadioOptionType};
