import {Trans, useTranslation} from 'react-i18next';
import {PLANS_TYPES} from '@guestapp/sdk/src/utils/allianz/constanst';
import {AllianzPlansTypes} from '../constants';
import {ModalSheetProps} from 'components/common/ModalSheet/ModalSheet';
import {CustomIcon} from '@guestapp/ui';
import {
  CWDInfoContainer,
  CWDTitle,
  DetailInfo,
  DetailListInfo,
  DetailTitle,
  DetailsItem,
  Footer,
  Hr,
  ModalDetails,
  ModalHeader,
  ModalWrapper,
  StyledModal,
  SubHeader,
} from './styled';

function AllianzPlanDetailsModal({
  isOpenModalSheet,
  handleCloseModal,
  type = AllianzPlansTypes.premium,
}: ModalSheetProps & {type: string}) {
  const {t} = useTranslation();

  const premiumModalContent = (
    <ModalWrapper className="allianz-plan-modal__content">
      <ModalHeader className="allianz-plan-modal__header">
        <CustomIcon name="allianzShieldIcon" color="#152946" />
        <div className="title">{t('premium_easy_living')}</div>
      </ModalHeader>
      <ModalDetails className="allianz-plan-modal__body">
        <DetailsItem>
          <DetailTitle>{t('deceasing_during_trip')}</DetailTitle>
          <DetailInfo>• {t('until_3000_eurs')}</DetailInfo>
        </DetailsItem>
        <Hr />
        <DetailsItem>
          <DetailTitle>{t('medical_assitance')}</DetailTitle>
          <DetailInfo>{t('sanitary_movements_legal_baggage_assistance')}</DetailInfo>
          <DetailInfo>
            {t('dental_medical_assistance')}
            <span> {t('until_300_euros')}</span>
          </DetailInfo>
        </DetailsItem>
        <Hr />
        <DetailsItem>
          <DetailTitle capitalize>{t('services')}</DetailTitle>
          <DetailInfo withoutBottom>{t('care_services_sick_injured')}</DetailInfo>
          <DetailListInfo>
            <li>{t('cleaning_and_household_tasks')}</li>
            <li>{t('food_delivery_and_grocery_shopping')}</li>
            <li>{t('child_care')}</li>
            <li>{t('medication_delivery')}</li>
            <li>{t('care_for_dependents')}</li>
            <li>{t('emergency_home_closure')}</li>
            <li>{t('unusual_hospital_expenses')}</li>
            <li>{t('pet_residence')}</li>
            <li>{t('unlimited_network_access')}</li>
            <li>{t('referral_to_doctors_nurses_hospitals')}</li>
          </DetailListInfo>
        </DetailsItem>
        <Footer className="allianz-plan-modal__footer">
          <span className="title">{t('transportation_services')}</span>,
          <span>{t('transportation_services_text')}</span>
        </Footer>
        <Hr />
        <DetailsItem>
          <DetailTitle>{t('juridical_assistance')}</DetailTitle>
          <DetailInfo>{t('phone_juridical_assisance')}</DetailInfo>
        </DetailsItem>
        <Hr />
        <DetailsItem>
          <DetailTitle>{t('medical_assitance')}</DetailTitle>
          <DetailInfo>{t('remote_telefonic_medical_assistance')}</DetailInfo>
        </DetailsItem>
        <Hr />
        <DetailsItem>
          <DetailTitle>{t('assistance_in_spain_robbery')}</DetailTitle>
          <DetailInfo>{t('assistance_in_spain_robbery')}</DetailInfo>
          <DetailInfo fontWeight={400}>
            <Trans i18nKey="payment_methods_protection">
              <strong>Protección de medios de pago</strong> dando asistencia en caso de
              robo o daño de la tarjeta y protección por uso fraudulento de mis medios de
              pago robados.
            </Trans>
          </DetailInfo>
          <DetailInfo fontWeight={400}>
            <Trans i18nKey="documents_keys_protection">
              <strong>Protección de documentos y llaves</strong> en caso de robo.
            </Trans>
          </DetailInfo>
        </DetailsItem>
        <Hr />
        <CWDInfoContainer className="allianz-plan-modal__cdw-info">
          <CustomIcon
            name="insuranceCwdIcon"
            color="rgb(21, 41, 70)"
            className="plan-icon"
            size={'24px'}
          />
          <CWDTitle className="allianz-plan-modal__cdw-title">{t('cwd_title')}</CWDTitle>
        </CWDInfoContainer>
        <DetailsItem>
          <DetailTitle>{t('cwd_sub_title')}</DetailTitle>
          <DetailInfo fontWeight={400}>
            <Trans i18nKey="cwd_description">
              <strong>Si roban o dañan tu coche de alquiler</strong> (durante tu viaje y
              el período de alquiler programado) se pagará hast la indemnización máxima
              indicada para esta cobertura.
            </Trans>
          </DetailInfo>
        </DetailsItem>
      </ModalDetails>
    </ModalWrapper>
  );

  const travelModalContent = (
    <ModalWrapper>
      <SubHeader isTravel>{t('coverage_guarantees_travel_easy_living')}</SubHeader>
      <ModalDetails>
        <DetailsItem>
          <DetailTitle>{t('deceasing_during_trip')}</DetailTitle>
          <DetailInfo>• {t('until_3000_eurs')}</DetailInfo>
          <DetailInfo fontWeight={400}>• {t('repatriation_in_case_of_death')}</DetailInfo>
        </DetailsItem>
        <Hr />
        <DetailsItem>
          <DetailTitle>{t('emergency_medical_attention_abroad')}</DetailTitle>
          <DetailInfo>
            {t('medical_attention')}
            <span> {t('until_3000_eurs')}</span>
          </DetailInfo>
          <DetailInfo>
            {t('dental_assistance')}
            <span> {t('until_300_euros')}</span>
          </DetailInfo>
          <DetailInfo fontWeight={400}>{t('emergency_transfer')}</DetailInfo>
        </DetailsItem>
        <Hr />
        <DetailsItem>
          <DetailTitle>{t('assistance_abroad')}</DetailTitle>
          <DetailInfo>
            <span> {t('assistance_in_case_of')}</span> {t('loss_of_travel_documents')}
          </DetailInfo>
          <DetailInfo fontWeight={400}>{t('emergency_financial_assistance')}</DetailInfo>
          <DetailInfo fontWeight={400}>{t('legal_assistance')}</DetailInfo>
        </DetailsItem>
      </ModalDetails>
    </ModalWrapper>
  );
  const modalContent = {
    [PLANS_TYPES.premium as string]: {
      title: 'allianz_travel_insurance',
      content: premiumModalContent,
    },
    [PLANS_TYPES.travel as string]: {
      title: 'travel_insurance_travel_easy_living',
      content: travelModalContent,
    },
  };

  return (
    <StyledModal
      isOpenModalSheet={isOpenModalSheet}
      handleCloseModal={handleCloseModal}
      isPremiumPlan={type === PLANS_TYPES.premium}
      title={t(modalContent[type]?.title)}
      className="allianz-plan-modal"
      padding
      withCloseButton
      withModalMobileCloseButton
    >
      {modalContent[type]?.content}
    </StyledModal>
  );
}

export {AllianzPlanDetailsModal};
