import LocationOptions from '../utils/LocationOptions';
import {ExtendedFetchLocationsParams, FetchLocationsParams, LocationApi} from '../api';
import {HousingLocation, Paginated, SelectOptionType} from '../utils/types';
import {
  CitiesLocation,
  DistrictsLocation,
  LocationType,
  MunicipalitiesLocation,
  ProvinceLocation,
} from '../utils';
import {FetcherSettings} from '../api/apiClient';

class LocationService {
  private readonly locationApi: LocationApi;

  constructor() {
    this.locationApi = new LocationApi();
  }

  getLocations<T = Location, WithoutPagination = []>(
    params = '',
    resolverSettings?: FetcherSettings,
  ) {
    return this.locationApi.get<T, WithoutPagination>(params, resolverSettings);
  }

  getPhoneCodes(resolverSettings?: FetcherSettings) {
    return this.getLocations<false, LocationType[]>(
      `ordering=name&old_mode=1&phone_code=1`,
      resolverSettings,
    );
  }

  getCountries(resolverSettings?: FetcherSettings) {
    return this.getLocations(`ordering=name`, resolverSettings);
  }

  getProvinces(countryCode: string, resolverSettings?: FetcherSettings) {
    return this.getLocations<ProvinceLocation>(
      `country=${countryCode}&ordering=name`,
      resolverSettings,
    );
  }

  getDistricts(countryCode: string, resolverSettings?: FetcherSettings) {
    return this.getLocations<DistrictsLocation>(
      `country=${countryCode}&division_level=1&ordering=name`,
      resolverSettings,
    );
  }

  getMunicipalities(
    countryCode: string,
    district: string | number = '',
    resolverSettings?: FetcherSettings,
  ) {
    return this.getLocations<MunicipalitiesLocation>(
      `country=${countryCode}&division_level=2&department=${district}&ordering=name`,
      resolverSettings,
    );
  }

  getCities(countryCode: string, resolverSettings?: FetcherSettings) {
    return this.getLocations<CitiesLocation>(
      `country=${countryCode}&division_level=3&ordering=name`,
      resolverSettings,
    );
  }

  getHousingLocation(id: string, resolverSettings?: FetcherSettings) {
    return this.locationApi.getByHousingId<HousingLocation>(id, resolverSettings);
  }

  private transformPaginatedListToOptions<T, D = undefined>(
    data: Promise<Paginated<T>>,
    optionsTransformer: (data: Paginated<T>) => SelectOptionType<D>[],
  ) {
    return data.then((res: Paginated<T>) => {
      return {
        ...res,
        results: optionsTransformer(res),
      };
    });
  }

  getCountriesList({
    phoneCodesOptions,
    ...params
  }: FetchLocationsParams & {phoneCodesOptions?: boolean}) {
    const result = this.locationApi.fetchCountriesList(params);
    return this.transformPaginatedListToOptions(
      result,
      phoneCodesOptions
        ? LocationOptions.getPhoneCodesAsOptions
        : LocationOptions.getCountriesAsOptions,
    );
  }

  getProvincesList(params: ExtendedFetchLocationsParams) {
    const result = this.locationApi.fetchProvincesList(params);
    return this.transformPaginatedListToOptions(
      result,
      LocationOptions.getProvincesAsOptions,
    );
  }

  getCitiesList(params: ExtendedFetchLocationsParams) {
    const result = this.locationApi.fetchCitiesList(params);
    return this.transformPaginatedListToOptions(
      result,
      LocationOptions.getCitiesAsOptions,
    );
  }

  getDistrictsList(params: ExtendedFetchLocationsParams) {
    const result = this.locationApi.fetchDistrictsList(params);
    return this.transformPaginatedListToOptions(
      result,
      LocationOptions.getDistrictsAsOptions,
    );
  }

  getMunicipalitiesList(params: ExtendedFetchLocationsParams) {
    const result = this.locationApi.fetchMunicipalitiesList(params);
    return this.transformPaginatedListToOptions(
      result,
      LocationOptions.getProvincesAsOptions,
    );
  }

  getPhoneCodesList(params: FetchLocationsParams) {
    const result = this.locationApi.fetchPhoneCodesList(params);
    return this.transformPaginatedListToOptions(
      result,
      LocationOptions.getPhoneCodesAsOptions,
    );
  }

  getSpanishResidenceCitiesList(
    params: FetchLocationsParams,
    resolverSettings?: FetcherSettings,
  ) {
    const result = this.locationApi.fetchSpanishResidenceCitiesList(
      params,
      resolverSettings,
    );
    return this.transformPaginatedListToOptions(
      result,
      LocationOptions.getSpanishCitiesAsOptions,
    );
  }
}

export {LocationService};
