enum PAYLOAD_FIELDS_NAMES {
  id = 'id',
  reservation_id = 'reservation_id',
  name = 'name',
  surname = 'surname',
  second_surname = 'second_surname',
  birth_date = 'birth_date',
  gender = 'gender',
  nationality = 'nationality',
  citizenship = 'citizenship',
  document_type = 'document_type',
  document_number = 'document_number',
  document_issue_date = 'document_issue_date',
  document_expiration_date = 'document_expiration_date',
  document_expedition_country = 'document_expedition_country',
  document_expedition_city = 'document_expedition_city',
  document_expedition_municipality = 'document_expedition_municipality',
  visa_number = 'visa_number',
  purpose_of_stay = 'purpose_of_stay',
  tax_exemption = 'tax_exemption',
  full_tourist_tax = 'full_tourist_tax',
  birth_place_country = 'birth_place_country',
  birth_place_city = 'birth_place_city',
  birth_place_municipality = 'birth_place_municipality',
  birth_place_address = 'birth_place_address',
  residence_country = 'residence_country',
  residence_city = 'residence_city',
  residence_community = 'residence_community',
  residence_municipality = 'residence_municipality',
  residence_province = 'residence_province',
  residence_address = 'residence_address',
  residence_postal_code = 'residence_postal_code',
  arrived_from_country = 'arrived_from_country',
  arrived_from_district = 'arrived_from_district',
  arrived_from_municipality = 'arrived_from_municipality',
  arrived_from_province = 'arrived_from_province',
  next_destination_country = 'next_destination_country',
  next_destination_district = 'next_destination_district',
  next_destination_province = 'next_destination_province',
  next_destination_address = 'next_destination_address',
  next_destination_municipality = 'next_destination_municipality',
  fiscal_code = 'fiscal_code',
  email = 'email',
  phone = 'phone',
  second_phone = 'second_phone',
  document_front_side_scan = 'document_front_side_scan',
  document_back_side_scan = 'document_back_side_scan',
  biomatch_doc = 'biomatch_doc',
  biomatch_passed = 'biomatch_passed',
  biomatch_selfie = 'biomatch_selfie',
  ocr_passed = 'ocr_passed',
  signature = 'signature',
  biomatch_doc_id = 'biomatch_doc_id',
  document_support_number = 'document_support_number',
  kinship_relationship = 'kinship_relationship',
  is_leader = 'is_leader',
  external_category_id = 'external_category_id',
  external_documents_biomatch_doc = 'external_documents_biomatch_doc',
  external_documents_biomatch_doc_service = 'external_documents_biomatch_doc_service',
  external_documents_biomatch_selfie = 'external_documents_biomatch_selfie',
  external_documents_biomatch_selfie_service = 'external_documents_biomatch_selfie_service',
  external_documents_front_side_scan = 'external_documents_front_side_scan',
  external_documents_front_side_scan_service = 'external_documents_front_side_scan_service',
  external_documents_back_side_scan = 'external_documents_back_side_scan',
  external_documents_back_side_scan_service = 'external_documents_back_side_scan_service',
}

const ImagesFields = [
  PAYLOAD_FIELDS_NAMES.document_front_side_scan,
  PAYLOAD_FIELDS_NAMES.document_back_side_scan,
  PAYLOAD_FIELDS_NAMES.signature,
  PAYLOAD_FIELDS_NAMES.biomatch_doc,
  PAYLOAD_FIELDS_NAMES.biomatch_selfie,
];

enum IMAGE_SERVICE_ORIGIN {
  ocr = 'ocr',
  biomatch = 'biomatch',
}

export {PAYLOAD_FIELDS_NAMES, IMAGE_SERVICE_ORIGIN, ImagesFields};
