import {JSX, useEffect} from 'react';
import ChekinPro from '../../ChekinPro';
import {environments} from '../../configs';
import {useChekinSDK} from '../../context/ChekinSDK';
import {useReservation} from '../../context/reservation';
import ErrorView from '../../pages/ErrorView';
import {EmbeddedErrorView} from '../../pages/EmbeddedErrorView/EmbeddedErrorView';

const IS_EMBEDDED_MODE = environments.REACT_APP_IS_EMBEDDED;

type ConnectionErrorsHandlerProps = Readonly<{children: JSX.Element}>;
function ConnectionErrorsHandler({children}: ConnectionErrorsHandlerProps) {
  const {isAuthInitializationError, hasToken: hasTokenInUrl, authError} = useChekinSDK();
  const {reservationError, isError: isReservationError} = useReservation();

  const hasAnyLoadingError = isAuthInitializationError || isReservationError;

  useEffect(() => {
    if (IS_EMBEDDED_MODE && hasAnyLoadingError) {
      ChekinPro.events.onConnectionError(authError || reservationError?.toString());
    }
  }, [
    authError,
    hasAnyLoadingError,
    isAuthInitializationError,
    isReservationError,
    reservationError,
  ]);

  if (IS_EMBEDDED_MODE && hasAnyLoadingError) {
    return <EmbeddedErrorView text={authError || reservationError} />;
  }

  if (!IS_EMBEDDED_MODE && (!hasTokenInUrl || isAuthInitializationError)) {
    return <ErrorView />;
  }

  return children;
}

export {ConnectionErrorsHandler};
