import React, {ImgHTMLAttributes, ReactElement} from 'react';
import clsx from 'clsx';
import {PopupProps} from '../common/Popup';
import {guestappTheme} from '../../styled/theme';
import {useLockScrollBody} from 'hooks';
import {ReactComponent as ErrorIcon} from 'assets/icons/error.svg';
import CustomIcon from '../common/CustomIcon';
import {IconWrapper, Title, Text, CloseButton, ModalStyled, StyledLottie} from './styled';

export type ModalProps = Omit<PopupProps, 'children'> & {
  children?: React.ReactNode;
  zIndex?: number;
  iconSrc?: string;
  iconAlt?: string;
  iconElement?: React.ReactNode | ReactElement;
  title?: string | React.ReactNode | ReactElement;
  text?: string | React.ReactNode | ReactElement;
  iconProps?: ImgHTMLAttributes<HTMLImageElement>;
  withCloseButton?: boolean;
  maxWidth?: string | number;
  width?: string | number;
  isConnectionIssue?: boolean;
  padding?: boolean;
  iconLottie?: object | null;
};

function Modal({
  open,
  onClose,
  children,
  closeOnDocumentClick = false,
  closeOnEscape = false,
  zIndex = 100,
  maxWidth = 500,
  iconAlt,
  iconSrc,
  title,
  text,
  lockScroll = true,
  iconProps = {},
  withCloseButton,
  width = 'auto',
  isConnectionIssue,
  iconElement,
  iconLottie,
  className,
  ...props
}: ModalProps) {
  const {lockScroll: lockScrollOnClose} = useLockScrollBody(true);
  const onCloseModal = () => {
    lockScrollOnClose();
    onClose?.();
  };
  return (
    <ModalStyled
      open={open}
      onClose={onCloseModal}
      closeOnDocumentClick={closeOnDocumentClick}
      closeOnEscape={closeOnEscape}
      lockScroll={lockScroll}
      maxWidth={maxWidth}
      width={width}
      className={clsx(className, 'modal')}
      {...props}
    >
      <>
        {withCloseButton && (
          <CloseButton onClick={onClose} className="modal__close-btn">
            <CustomIcon
              size={12}
              color={guestappTheme.colors.branding.fontPrimary}
              name={'close'}
            />
          </CloseButton>
        )}
        {(iconElement || iconSrc || iconProps?.src || iconLottie) && (
          <IconWrapper className="modal__icon">
            {isConnectionIssue && (
              <ErrorIcon width="180" height="180" viewBox="0 0 230 229" />
            )}
            {iconElement}
            {!isConnectionIssue && (
              <>
                {!iconLottie ? (
                  <img src={iconSrc} alt={iconAlt} {...iconProps} />
                ) : (
                  <StyledLottie animationData={iconLottie} />
                )}
              </>
            )}
          </IconWrapper>
        )}
        {title && <Title className="modal__title">{title}</Title>}
        {text && <Text className="modal__description">{text}</Text>}
        {children}
      </>
    </ModalStyled>
  );
}
export {Modal};
