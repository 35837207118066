import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import StatusModal, {StatusModalStates} from 'components/StatusModal';
import {useTimeoutRef} from 'hooks';
import {type Offer, OFFER_CONFIRMATION_TYPES} from '@guestapp/sdk';
import {
  ConfirmButton,
  Container,
  ContainerButton,
  ContainerInformation,
  Subtitle,
  Title,
} from './styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  confirmationType: Offer['confirmation_type'];
};
function CreateDealCompleteModal({isOpen, onClose, confirmationType}: Props) {
  const {t} = useTranslation();
  const timeoutRef = useTimeoutRef();

  useEffect(() => {
    if (isOpen && confirmationType === OFFER_CONFIRMATION_TYPES.auto) {
      timeoutRef.current = setTimeout(() => {
        onClose?.();
      }, 3000);
    }
  }, [isOpen, timeoutRef, onClose, confirmationType]);

  return confirmationType === OFFER_CONFIRMATION_TYPES.manual ? (
    <StatusModal
      state={StatusModalStates.IDLE}
      open={isOpen}
      onClose={onClose}
      withCloseButton
    >
      <Container>
        <ContainerInformation>
          <Title>{t('create_deal_complete_modal.manual_confirmation_type.title')}</Title>
          <Subtitle>
            {t('create_deal_complete_modal.manual_confirmation_type.message')}
          </Subtitle>
        </ContainerInformation>
        <ContainerButton>
          <ConfirmButton label={t('ok')} onClick={onClose} />
        </ContainerButton>
      </Container>
    </StatusModal>
  ) : (
    <StatusModal
      state={StatusModalStates.SUCCESS}
      open={isOpen}
      onClose={onClose}
      withCloseButton
    >
      <Title>{t('create_deal_complete_modal.auto_confirmation_type.message')}</Title>
    </StatusModal>
  );
}

export {CreateDealCompleteModal};
