import React from 'react';

const useCustomizeHeadContent = (logo?: string | null, title?: string | null) => {
  React.useEffect(() => {
    if (logo) {
      const faviconLink = document.querySelector('link[rel="icon"]');
      if (faviconLink) {
        faviconLink.setAttribute('href', logo);
      }

      const appleTouchIconLink = document.querySelector('link[rel="apple-touch-icon"]');
      if (appleTouchIconLink) {
        appleTouchIconLink.setAttribute('href', logo);
      }
    }
    if (title) {
      const ogTitleMeta = document.querySelector('meta[property="og:title"]');
      if (ogTitleMeta) {
        ogTitleMeta.setAttribute('content', title);
      }

      const twitterTitleMeta = document.querySelector('meta[property="twitter:title"]');
      if (twitterTitleMeta) {
        twitterTitleMeta.setAttribute('content', title);
      }

      document.title = title;
    }
  }, [logo, title]);
};

export {useCustomizeHeadContent};
