import styled, {css, keyframes} from 'styled-components';
import {device} from '@guestapp/ui/src/utils/device';

const activeHoveredHeaderItemOpacity = 0.9;
const loading = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;

export const Skeleton = styled.div`
  height: 36px;
  border-radius: 5px;
  width: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #f8f8f8 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${loading} 1.5s infinite ease-in-out;
`;

export const SideBarContainer = styled.div`
  background-color: ${props => props.theme.colors.navigationMenu.backgroundMenu};
  height: 100%;
  position: relative;
  z-index: 1;

  @media (min-width: ${device.tablet}) {
    height: 100vh;
    display: block;
    border-right: 1px solid rgba(21, 41, 70, 0.2);
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  padding: 0 10px 0 0;
  margin-bottom: 24px;
  align-items: center;
  margin-top: 45px;
  gap: 20px;
  justify-content: space-between;
  ${Skeleton} {
    margin-left: -8px;
  }
  @media (min-width: ${device.tablet}) {
    padding: 10px 10px 10px 24px;
    border-bottom: none;
    flex-direction: row;
    gap: 0;
    min-height: 70px;
    margin-top: 0;
  }
`;

export const HeaderImg = styled.img<{big?: boolean}>`
  object-fit: contain;
  background-size: contain;
  max-width: ${({big}) => (big ? `247px` : `48px`)};
  max-height: 48px;

  width: auto;
  height: auto;
`;

export const HeaderText = styled.div<{isImageHeader?: boolean}>`
  font-size: ${props => props.theme.fonts.sizes.large};
  font-style: normal;
  font-weight: ${props => props.theme.fonts.fontWeight.bold};
  line-height: 24px;
  letter-spacing: 0.36px;
  width: ${props => (props.isImageHeader ? 'calc(100% - 60px)' : '100%')};
  color: ${props => props.theme.colors.navigationMenu.menuItemsText};
  cursor: pointer;
  word-break: break-word;

  font-family: ${props => props.theme.fonts.fontFamily.titles.font_name};

  @media (min-width: ${device.tablet}) {
    display: block;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(
    100% - 132px
  ); // this is heigh less menu header padding top, leess paddings
  padding: 0 24px;
  background-color: ${props => props.theme.colors.navigationMenu.backgroundMenu};
  @media (min-width: ${device.tablet}) {
    height: calc(100% - 95px);
  }

  & .sidebar__items-list {
    height: 100%;
  }

  & .sidebar__footer {
    padding: 8px 0 24px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    @media (min-width: ${device.tablet}) {
      padding: 24px 0;
    }
  }
`;

export const ItemContainer = styled.div<{isActive?: boolean; isLoading?: boolean}>`
  ${props =>
    !props.isLoading &&
    css`
      padding: 8px 12px;
    `}

  display: flex;
  font-size: ${props => props.theme.fonts.sizes.medium};
  font-style: normal;
  font-weight: ${props => props.theme.fonts.fontWeight.medium};
  border-radius: 6px;
  align-items: center;
  color: ${props => props.theme.colors.navigationMenu.menuItemsText};
  cursor: pointer;
  text-transform: capitalize;
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  & .sidebar__icon {
    position: relative;
    margin-right: 12px;
    path {
      fill: ${props => props.theme.colors.navigationMenu.menuItemsText};
    }

    ${props =>
      props.isActive &&
      css`
        path {
          fill: ${props => props.theme.colors.navigationMenu.selectedItemText};
        }
      `}
  }

  ${props =>
    props.isActive &&
    css`
      color: ${props => props.theme.colors.navigationMenu.selectedItemText};
      background-color: ${props =>
        props.theme.colors.navigationMenu.selectedItemBackground};
    `};

  ${props =>
    !props.isActive &&
    css`
      &:hover {
        color: ${props => props.theme.colors.navigationMenu.selectedItemText};
        opacity: ${activeHoveredHeaderItemOpacity};
        path {
          fill: ${props => props.theme.colors.navigationMenu.selectedItemText};
        }
      }
    `};
`;

export const IconBadge = styled.div`
  width: 13px;
  height: 13px;
  background: rgb(248, 75, 122);
  border-radius: 11px;
  color: rgb(255, 255, 255);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  top: -2px;
  right: -2px;
  font-size: 11px;
`;
