import React, {PropsWithChildren} from 'react';
import {useQuery} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {useChekinSDK} from './ChekinSDK';
import {IDENTITY_VERIFICATION_TYPES, QUERY_CACHE_KEYS} from '../utils/constants';
import {
  CustomForm,
  FormField as BaseFormField,
  GuestSchemaResponse,
  PAYLOAD_FIELDS_NAMES,
} from '@guestapp/sdk';
import {useSummary} from './summary';
import {useCustomTheme} from './customTheme';

const StaleTime = 60_000;
const CacheTime = 60_000;

type FormField = BaseFormField & {
  format: string;
};
type DocumentChoice = {
  label: string;
  value: string;
  two_sides: boolean;
};

type IdentityVerificationDetails = {
  enabled: boolean;
  required: boolean;
  ocr: boolean;
  biomatch: boolean;
  documents: DocumentChoice[];
};

export type AddFormType = {
  [key: string]: FormField;
};
type ContextProps = {
  identityVerificationDetails: IdentityVerificationDetails;
  isGuestSchemaLoaded: boolean;
  isGuestSchemaLoading: boolean;
  isGuestSchemaFetching: boolean;
  formFields: AddFormType;
  reservationDocumentsList?: DocumentChoice[];
  setBirthCountry?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setArrivalCountry?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setArrivalDistrict?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setNextDestinationCountry?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setNationality?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setDocumentType?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setDocumentExpeditionCountry?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setResidenceCountry?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCitizenship?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setBirthDate?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setHasPhone?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowInvoiceField?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  customForm?: CustomForm;
};

const GuestContext = React.createContext<ContextProps>({
  formFields: {},
  isGuestSchemaLoaded: false,
  isGuestSchemaLoading: false,
  isGuestSchemaFetching: false,
  identityVerificationDetails: {
    enabled: false,
    required: false,
    ocr: false,
    biomatch: false,
    documents: [],
  },
});

function GuestProvider({children}: PropsWithChildren) {
  const {getSchema, isSuccessSDK} = useChekinSDK();
  const {isTemplateInitialized} = useCustomTheme();
  const {reservationId, isSuccess: isSuccessSummary} = useSummary();
  const [isGuestSchemaLoaded, setIsGuestSchemaLoaded] = React.useState(false);
  const [birthCountry, setBirthCountry] = React.useState<string>();
  const [nationality, setNationality] = React.useState<string>();
  const [documentType, setDocumentType] = React.useState<string>();
  const {t, i18n} = useTranslation();
  const [documentExpeditionCountry, setDocumentExpeditionCountry] =
    React.useState<string>();
  const [arrivalCountry, setArrivalCountry] = React.useState<string>();
  const [arrivalDistrict, setArrivalDistrict] = React.useState<string>();
  const [nextDestinationCountry, setNextDestinationCountry] = React.useState<string>();
  const [residenceCountry, setResidenceCountry] = React.useState<string>();
  const [birthDate, setBirthDate] = React.useState<string>();
  const [hasPhone, setHasPhone] = React.useState<boolean>(false);
  const [citizenship, setCitizenship] = React.useState<string>();
  const [showInvoiceField, setShowInvoiceField] = React.useState<boolean | undefined>(
    undefined,
  );

  const {
    data: guestSchema,
    isLoading: isGuestSchemaLoading,
    isFetching: isGuestSchemaFetching,
  } = useQuery<GuestSchemaResponse>(
    [
      QUERY_CACHE_KEYS.formFields,
      nationality,
      citizenship,
      birthCountry,
      arrivalCountry,
      arrivalDistrict,
      residenceCountry,
      nextDestinationCountry,
      documentExpeditionCountry,
      documentType,
      birthDate,
      hasPhone,
      showInvoiceField,
      i18n.language,
    ],
    () =>
      getSchema({
        [PAYLOAD_FIELDS_NAMES.reservation_id]: reservationId as string,
        [PAYLOAD_FIELDS_NAMES.birth_place_country]: birthCountry,
        [PAYLOAD_FIELDS_NAMES.nationality]: nationality,
        [PAYLOAD_FIELDS_NAMES.document_expedition_country]: documentExpeditionCountry,
        [PAYLOAD_FIELDS_NAMES.next_destination_country]: nextDestinationCountry,
        [PAYLOAD_FIELDS_NAMES.arrived_from_country]: arrivalCountry,
        [PAYLOAD_FIELDS_NAMES.arrived_from_district]: arrivalDistrict,
        [PAYLOAD_FIELDS_NAMES.residence_country]: residenceCountry,
        [PAYLOAD_FIELDS_NAMES.document_type]: documentType,
        [PAYLOAD_FIELDS_NAMES.citizenship]: citizenship,
        [PAYLOAD_FIELDS_NAMES.birth_date]: birthDate,
        [PAYLOAD_FIELDS_NAMES.phone]: hasPhone,
        [PAYLOAD_FIELDS_NAMES.is_leader]: showInvoiceField,
      }),
    {
      onSettled: () => {
        if (!isGuestSchemaLoaded) {
          setIsGuestSchemaLoaded(true);
        }
      },
      enabled:
        Boolean(isSuccessSDK) && Boolean(isSuccessSummary) && isTemplateInitialized,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: StaleTime,
      cacheTime: CacheTime,
      keepPreviousData: true,
    },
  );

  const formFieldsData = guestSchema?.default;
  const customForm = Boolean(
    guestSchema?.custom?.id && guestSchema?.custom?.fields_set?.length,
  )
    ? guestSchema?.custom
    : undefined;

  const reservationDocumentsList = React.useMemo(() => {
    return guestSchema?.iv?.document_choices.map(dc => ({
      ...dc,
      label: t(`${dc.label}`),
    }));
  }, [t, guestSchema?.iv?.document_choices]);

  const identityVerificationDetails = React.useMemo<IdentityVerificationDetails>(() => {
    const ivDetails = guestSchema?.iv;
    const isOCR =
      ivDetails?.verification_type === IDENTITY_VERIFICATION_TYPES.ocr ||
      ivDetails?.verification_type === IDENTITY_VERIFICATION_TYPES.document;
    const isBiomatch =
      ivDetails?.verification_type === IDENTITY_VERIFICATION_TYPES.biomatch;
    return {
      enabled: Boolean(isOCR || isBiomatch),
      required: Boolean(ivDetails?.required),
      documents: ivDetails?.document_choices || [],
      biomatch: isBiomatch,
      ocr: isOCR,
    };
  }, [guestSchema?.iv]);

  const transformFormFieldsArrayToObject = React.useCallback((): AddFormType => {
    if (!formFieldsData) return {};
    return formFieldsData?.reduce((accumulator, field) => {
      return {...accumulator, [field.name]: field};
    }, {});
  }, [formFieldsData]);

  const formFields = React.useMemo<AddFormType>(
    () => transformFormFieldsArrayToObject(),
    [transformFormFieldsArrayToObject],
  );

  const defaultProps = React.useMemo<ContextProps>(
    () => ({
      formFields,
      identityVerificationDetails,
      setBirthCountry,
      setArrivalCountry,
      setArrivalDistrict,
      setNextDestinationCountry,
      setNationality,
      setDocumentType,
      setDocumentExpeditionCountry,
      setResidenceCountry,
      setCitizenship,
      setBirthDate,
      setHasPhone,
      setShowInvoiceField,
      isGuestSchemaLoaded,
      isGuestSchemaLoading,
      isGuestSchemaFetching,
      reservationDocumentsList,
      customForm,
    }),
    [
      customForm,
      formFields,
      identityVerificationDetails,
      isGuestSchemaLoaded,
      isGuestSchemaLoading,
      isGuestSchemaFetching,
      reservationDocumentsList,
    ],
  );

  return <GuestContext.Provider value={defaultProps} children={children} />;
}

function useSchema() {
  const context = React.useContext(GuestContext);
  if (context === undefined) {
    throw new Error('useReservation must be used within a GuestProvider');
  }
  return context;
}

export {GuestContext, GuestProvider, useSchema};
