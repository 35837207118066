import React from 'react';
import {RESERVATION_PAYMENT_TYPES} from '@guestapp/sdk';
import {useModalControls, usePropertyProtection} from 'hooks';
import {
  DamageProtectionInfoModal,
  SecurityDepositInfoModal,
} from 'pages/DepositView/components';
import {TaxesInformationModal} from 'pages/Taxes/TaxesTouristView/modals';

function usePaymentItemModals({paymentType}: {paymentType: RESERVATION_PAYMENT_TYPES}) {
  const {data, downloadDocumentsPrivacyPolicy} = usePropertyProtection();

  const {
    isOpen: isTaxesInfoModalOpen,
    closeModal: closeTaxesInfoModal,
    openModal: openTaxesInfoModal,
  } = useModalControls();

  const {
    isOpen: isWaivoInfoModalOpen,
    closeModal: closeWaivoInfoModal,
    openModal: openWaivoInfoModal,
  } = useModalControls();

  const {
    isOpen: isSecurityDepositInfoModalOpen,
    closeModal: closeSecurityDepositInfoModal,
    openModal: openSecurityDepositInfoModal,
  } = useModalControls();

  const modalConfig = React.useMemo(() => {
    switch (paymentType) {
      case RESERVATION_PAYMENT_TYPES.touristTaxes:
        return {
          openModal: openTaxesInfoModal,
          Modal: () => (
            <TaxesInformationModal
              isOpen={isTaxesInfoModalOpen}
              close={closeTaxesInfoModal}
            />
          ),
        };
      case RESERVATION_PAYMENT_TYPES.damageProtection:
        return {
          openModal: openWaivoInfoModal,
          Modal: () => (
            <DamageProtectionInfoModal
              isOpen={isWaivoInfoModalOpen}
              close={closeWaivoInfoModal}
              policyLink={downloadDocumentsPrivacyPolicy}
            />
          ),
        };
      case RESERVATION_PAYMENT_TYPES.securityDeposit:
        return {
          openModal: openSecurityDepositInfoModal,
          Modal: () => (
            <SecurityDepositInfoModal
              isOpen={isSecurityDepositInfoModalOpen}
              close={closeSecurityDepositInfoModal}
              isPreActivated={data?.security_deposit?.is_pre_auth_active}
            />
          ),
        };
      default:
        return {
          openModal: () => {},
          Modal: () => <></>,
        };
    }
  }, [
    closeSecurityDepositInfoModal,
    closeTaxesInfoModal,
    closeWaivoInfoModal,
    data,
    downloadDocumentsPrivacyPolicy,
    isSecurityDepositInfoModalOpen,
    isTaxesInfoModalOpen,
    isWaivoInfoModalOpen,
    paymentType,
    openSecurityDepositInfoModal,
    openTaxesInfoModal,
    openWaivoInfoModal,
  ]);

  return {openModal: modalConfig?.openModal, Modal: modalConfig?.Modal};
}

export {usePaymentItemModals};
