import React from 'react';
import clsx from 'clsx';
import {StyledDropdown, StyledMenu} from './styled';

type MenuProps = {
  children: React.ReactNode;
};
const Menu = ({children}: MenuProps) => {
  return <StyledMenu children={children} className="dropdown-select__menu" />;
};

type DropdownProps = {
  readonly isOpen: boolean;
  readonly target: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
};
const Dropdown = React.forwardRef<HTMLDivElement, DropdownProps>(
  ({children, isOpen, target, className}, ref) => {
    return (
      <StyledDropdown ref={ref} className={clsx(className, 'dropdown-select__dropdown')}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
      </StyledDropdown>
    );
  },
);

export {Menu, Dropdown};
