import React from 'react';
import {usePropertyProtection} from 'hooks';
import {INSURANCE_TYPES, PAYMENT_METHOD_TYPES} from '@guestapp/sdk';

const getInsuranceType = (isSecurityDeposit: boolean, isWaivoProtection: boolean) => {
  if (isSecurityDeposit) {
    return INSURANCE_TYPES.securityDeposit;
  }
  if (isWaivoProtection) {
    return INSURANCE_TYPES.damageProtection;
  }
  return INSURANCE_TYPES.securityDeposit;
};

function useInitPropertyProtection() {
  const {
    data: insuranceConfig,
    insuranceSetup,
    createOrUpdateInsuranceSetup,
    isInsuranceSetupInit,
    isSecurityDepositOptional,
    isWaivoProtectionMandatory,
    isWaivoProtection,
    isSecurityDeposit,
    isMultipleDeposits,
  } = usePropertyProtection();

  const hasReservationInsurance = insuranceConfig
    ? Object.values(insuranceConfig).length > 0
    : false;

  const shouldCreateDepositSetup = isSecurityDeposit && !isSecurityDepositOptional;
  const shouldCreateWaivoSetup = isWaivoProtection && isWaivoProtectionMandatory;
  const hasInsuranceAndIsInitialized =
    hasReservationInsurance && isInsuranceSetupInit && !insuranceSetup?.id;

  const shouldCreateInsuranceSetup =
    !isMultipleDeposits &&
    hasInsuranceAndIsInitialized &&
    (shouldCreateDepositSetup || shouldCreateWaivoSetup);

  React.useEffect(
    function createInsuranceSetupOnInit() {
      if (shouldCreateInsuranceSetup) {
        createOrUpdateInsuranceSetup(
          {
            insurance_payment_type: PAYMENT_METHOD_TYPES.payOnline,
            insurance_type: getInsuranceType(isSecurityDeposit, isWaivoProtection),
          },
          {},
        );
      }
    },
    [
      shouldCreateInsuranceSetup,
      createOrUpdateInsuranceSetup,
      isSecurityDeposit,
      isWaivoProtection,
    ],
  );
}
export {useInitPropertyProtection};
