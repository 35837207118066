import {useQuery} from '@tanstack/react-query';
import {UnlockResponse} from '@guestapp/sdk';
import {QUERY_CACHE_KEYS} from 'utils/constants';
import {useChekinSDK} from 'context/ChekinSDK';

type UseUnlockDoorProps = {
  lockToken: string | null;
  enabled?: boolean;
  onSuccess?: (data: UnlockResponse) => void;
  onError?: (error: Error) => void;
};

function useUnlockDoor({
  lockToken,
  enabled = true,
  onSuccess,
  onError,
}: UseUnlockDoorProps) {
  const {unlockDoor} = useChekinSDK();

  return useQuery<UnlockResponse, Error>(
    [QUERY_CACHE_KEYS.unlockDoor, lockToken],
    () => unlockDoor(`token=${lockToken}`),
    {
      enabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
    },
  );
}

export {useUnlockDoor};
