import dayjs from 'dayjs';
import i18n, {TFunction} from 'i18next';
import {PhoneDetails, POLICE_CODES, SelectOptionType, GuestGroup} from '@guestapp/sdk';
import {getCurrentLocale} from 'utils/common';
import {LocationType} from 'utils/types';
import {CustomField} from './types';
import {COUNTRY_CODES} from 'utils/constants';
import {PATTERNS} from '@guestapp/core';
import {resetTimeInDate} from '../../utils/dates';
import {dniValidation, nieValidation} from 'utils/docTypes';

function goToExternalLink(url: string) {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.target = '_blank';
  anchor.click();
}

function getDocNumberPattern(country?: string, isPoliceActivated?: boolean) {
  if (country === COUNTRY_CODES.italy && !isPoliceActivated) {
    return PATTERNS.docNumberWithDots;
  }

  return PATTERNS.docNumber;
}

function validateDNI(number = '', type: string | number = '') {
  const DNI_STRING = 'TRWAGMYFPDXBNJZSQVHLCKE';
  const DNI_DIVISION_NUMBER = 23;

  if (!PATTERNS.dniAndDl.test(number)) {
    return i18n.t('doc_number_dni_format_error');
  }

  const extractedNumbers = Number(number.slice(0, -1));
  const extractedChar = number.substr(number.length - 1).toUpperCase();
  const expectedChar = DNI_STRING[extractedNumbers % DNI_DIVISION_NUMBER];

  if (expectedChar !== extractedChar) {
    return i18n.t('doc_number_dni_letter_error');
  }

  return true;
}

function validateNIE(number = '') {
  const NIE_STRING = 'TRWAGMYFPDXBNJZSQVHLCKE';
  const NIE_DIVISION_NUMBER = 23;
  const firstLetterValue: {[key: string]: string} = {
    X: '0',
    Y: '1',
    Z: '2',
  };

  if (!PATTERNS.nie.test(number)) {
    return i18n.t('doc_number_nie_format_error');
  }

  const withoutLetters = number.slice(1, -1);
  const lastSymbol = number[number.length - 1].toUpperCase();

  const firstLetter: string = number[0].toUpperCase();
  const replacedFirstLetter = firstLetterValue[firstLetter];

  if (replacedFirstLetter === undefined) {
    return i18n.t('doc_number_nie_letter_error');
  }

  const numberWithReplacedFirstLetter = Number(replacedFirstLetter + withoutLetters);
  const expectedChar = NIE_STRING[numberWithReplacedFirstLetter % NIE_DIVISION_NUMBER];

  if (lastSymbol !== expectedChar) {
    return i18n.t('doc_number_nie_letter_error');
  }

  return true;
}

function validateDocNumberByDocumentType(
  number: string | null,
  docType: string | undefined,
) {
  if (docType && dniValidation.includes(docType)) {
    return validateDNI(String(number), docType);
  }

  if (docType && nieValidation.includes(String(docType))) {
    return validateNIE(String(number));
  }

  return true;
}

function validateDocNumberUniqueness(number: string | null, guestGroup?: GuestGroup) {
  if (guestGroup && number && guestGroup.guest_documents?.length) {
    return (
      guestGroup.guest_documents?.every(docNumber => docNumber !== number) ||
      i18n.t('document_number_already_registered')
    );
  }
  return true;
}

const maxNamesInputLength = 30;
const maxBirthPlaceAddressLength = 100;
const maxLength = 20;
const minLength = 2;

function getCustomFieldData(field: CustomField) {
  const language = getCurrentLocale().toUpperCase();
  const defaultLabel = Object.values(field.names?.[0])?.[0] || '';
  const localeLabel = field.names.find(name => {
    return name[language];
  })?.[language];
  const defaultPlaceholder = field.placeholders?.length
    ? Object.values(field.placeholders?.[0])?.[0]
    : '';
  const localePlaceholder = field?.placeholders?.length
    ? field?.placeholders?.find(placeholder => {
        return placeholder[language];
      })?.[language]
    : '';

  const label = localeLabel || defaultLabel;
  const placeholder = localePlaceholder || defaultPlaceholder;
  const name = field.name;

  return {label, placeholder, name};
}

function getLocationsAsOptions(locations: {
  count: number;
  results: Array<LocationType>;
}): SelectOptionType[] {
  const results = Array.isArray(locations) ? locations : locations?.results;
  if (!results) {
    return [];
  }

  return results?.map(l => {
    return {
      label: l?.country?.name,
      value: l?.country?.code,
    };
  });
}

function getIsFirstDateBeforeSecondDate(firstDate: Date | null, secondDate: Date | null) {
  if (firstDate && secondDate) {
    return dayjs(new Date(firstDate)).isBefore(new Date(secondDate));
  }
  return true;
}

function getIsFirstDateBeforeOrEqualSecondDate(
  firstDate?: Date | null,
  secondDate?: Date | null,
) {
  if (firstDate && secondDate) {
    return dayjs(new Date(secondDate)).isSameOrAfter(dayjs(new Date(firstDate)));
  }
  return true;
}

function getIsAfterMinDate(date: Date, minDate: Date) {
  return dayjs(date).isAfter(minDate);
}

function getIsDateBeforeToday(date: Date) {
  return dayjs(new Date()).isBefore(date);
}

function validateGuestMustBeAdult(birthDate: Date | null) {
  if (!birthDate) {
    return true;
  }
  return (
    dayjs(new Date()).diff(dayjs(birthDate), 'year') > 17 ||
    i18n.t('first_guest_needs_to_be_18_years_old')
  );
}

function validateBirthDate(birthDate: Date, issueDate: Date | null) {
  let error = '';
  const minBirthValidDate = new Date('1920-01-01');
  const minBirthDateFormat = 'DD/MM/YYYY';
  const isBeforeIssueDate = getIsFirstDateBeforeSecondDate(birthDate, issueDate);
  const isBeforeToday = getIsDateBeforeToday(birthDate);
  const isAfterMinDate = getIsAfterMinDate(birthDate, minBirthValidDate);

  if (!isAfterMinDate) {
    error = i18n.t('cant_be_before_than_min_date', {
      date: dayjs(minBirthValidDate).format(minBirthDateFormat),
    });
  }

  if (!isBeforeIssueDate) {
    error = i18n.t('cant_be_equal_or_after_than_issue_date');
  }
  if (isBeforeToday) {
    error = i18n.t('cant_be_equal_or_greater_than_today');
  }

  return error || true;
}

function validateIssueDate(
  issueDate: Date,
  birthDate: Date | null,
  expirationDate: Date | null,
) {
  let error = '';

  if (issueDate) {
    const isBeforeExpirationDate = getIsFirstDateBeforeSecondDate(
      issueDate,
      expirationDate,
    );
    const isBeforeIssueDate = getIsFirstDateBeforeSecondDate(birthDate, issueDate);
    const isBeforeToday = getIsDateBeforeToday(issueDate);

    if (!isBeforeExpirationDate) {
      error = i18n.t('cant_be_equal_or_after_than_expiration_date');
    }
    if (!isBeforeIssueDate) {
      error = i18n.t('cant_be_equal_or_smaller_than_birth_date');
    }
    if (isBeforeToday) {
      error = i18n.t('cant_be_equal_or_greater_than_today');
    }
  }

  return error || true;
}

type ValidateExpirationDateArgs = {
  expirationDate: Date;
  birthDate: Date | null;
  issueDate: Date | null;
  checkInDate?: string;
};
function validateExpirationDate({
  expirationDate,
  birthDate,
  issueDate,
  checkInDate,
}: ValidateExpirationDateArgs) {
  if (!expirationDate) {
    return true;
  }

  let error = '';
  const isAfterIssueDate = getIsFirstDateBeforeSecondDate(issueDate, expirationDate);
  const isBirthDateBefore = getIsFirstDateBeforeSecondDate(birthDate, expirationDate);
  const isCheckInDateBefore = getIsFirstDateBeforeOrEqualSecondDate(
    resetTimeInDate(checkInDate),
    expirationDate,
  );

  if (!isBirthDateBefore) {
    error = i18n.t('cant_be_equal_or_smaller_than_birth_date');
  }

  if (!isAfterIssueDate) {
    error = i18n.t('cant_be_equal_or_before_than_issue_date');
  }

  if (!isCheckInDateBefore) {
    error = i18n.t('cant_be_earlier_than_check_in_date');
  }

  return error || true;
}

function validateResidencePhoneNumber(t: TFunction, policeType?: string) {
  return (value?: PhoneDetails | null) => {
    if (!value?.number || !policeType) {
      return true;
    }

    if ([POLICE_CODES.uhh, POLICE_CODES.uhh2].includes(policeType)) {
      const isBetweenAllowedRange =
        value.number?.length >= 8 && value.number?.length <= 12;

      return isBetweenAllowedRange || t('invalid_phone_number');
    }

    return true;
  };
}

function validatePostalCode(t: TFunction, country?: string) {
  return (value?: string) => {
    if (!value) return true;
    switch (country) {
      case COUNTRY_CODES.germany: {
        const postalCode = value.trim();
        if (!isValidPostalCodeFormat(postalCode, 5)) {
          return i18n.t('invalid_postal_code.germany');
        }
        if (!isValidPostalCodeForGermany(postalCode)) {
          return i18n.t('invalid_postal_code.invalid');
        }
        return true;
      }
      case COUNTRY_CODES.austria: {
        const postalCode = value.trim();
        if (!isValidPostalCodeFormat(postalCode, 4)) {
          return i18n.t('invalid_postal_code.austria');
        }
        if (!isValidPostalCodeForAustria(postalCode)) {
          return i18n.t('invalid_postal_code.invalid');
        }
        return true;
      }
      default:
        return true;
    }
  };
}

/**
 * Checks if an Austrian postal code is valid.
 *
 * Rules:
 * 1. It must be a 4-digit number.
 * 2. It cannot be 1000 or 9999.
 *
 * @param {string} postalCode - The postal code to check.
 * @returns {boolean} - `true` if the postal code is valid, `false` otherwise.
 */
function isValidPostalCodeForAustria(postalCode: string) {
  const postalCodeRegex = /^(?!1000$)(?!9999$)[1-9]\d{3}$/;
  return postalCodeRegex.test(postalCode);
}

/**
 * Checks if a postal code has the specified number of digits.
 *
 * @param {string} postalCode - The postal code to check.
 * @param {number} numberAmmount - The exact number of digits the postal code should have.
 * @returns {boolean} - `true` if the postal code has the specified number of digits, `false` otherwise.
 */
function isValidPostalCodeFormat(postalCode: string, numberAmmount: number) {
  const postalCodeRegex = new RegExp(`^\\d{${numberAmmount}}$`);
  return postalCodeRegex.test(postalCode);
}
/**
 * Checks if a German postal code is valid.
 *
 * Rules:
 * 1. If it starts with 0, it must have exactly 5 digits and no more than one 0 at the beginning.
 * 2. If it does not start with 0, it must be within the following ranges:
 *    - 10001 to 10998
 *    - 12001 to 42998
 *    - 44001 to 61998
 *    - 63001 to 99998
 *
 * @param {string} postalCode - The postal code to check.
 * @returns {boolean} - `true` if the postal code is valid, `false` otherwise.
 */
function isValidPostalCodeForGermany(postalCode: string) {
  const regex = /^(0[1-9]\d{3}|[1-9]\d{4})$/;
  if (!regex.test(postalCode)) {
    return false;
  }

  if (postalCode.startsWith('0')) {
    return true;
  }

  const value = parseInt(postalCode, 10);
  const ranges = [
    {min: 10001, max: 10998},
    {min: 12001, max: 42998},
    {min: 44001, max: 61998},
    {min: 63001, max: 99998},
  ];

  return ranges.some(range => value >= range.min && value <= range.max);
}
export {
  getDocNumberPattern,
  maxNamesInputLength,
  maxBirthPlaceAddressLength,
  maxLength,
  minLength,
  getLocationsAsOptions,
  getCustomFieldData,
  validateBirthDate,
  validateIssueDate,
  validateExpirationDate,
  goToExternalLink,
  validateDocNumberByDocumentType,
  validateResidencePhoneNumber,
  validateGuestMustBeAdult,
  validatePostalCode,
  isValidPostalCodeForAustria,
  isValidPostalCodeForGermany,
  isValidPostalCodeFormat,
  validateDocNumberUniqueness,
};
