import styled from 'styled-components';

export const ErrorMessage = styled.div.attrs({className: 'error-message'})`
  font-family: inherit;
  text-align: left;
  color: #f84b7a;
  white-space: nowrap;
  font-size: 11px;
  position: absolute;
  left: 0;
  bottom: -18px;
`;
