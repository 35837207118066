import styled from 'styled-components';
import Link from 'components/common/Link';
import {device} from 'styled/device';
import CustomIcon from 'components/common/CustomIcon';

type WhyDataRequiredTextProps = {
  marginBottom?: string;
};

export const FormWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  padding: 0 10px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const WhyDataRequired = styled.div`
  margin-bottom: 24px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.36px;
  font-family: ${props => props.theme.fonts.fontFamily.titles.font_name};
  color: ${props => props.theme.colors.branding.fontPrimary};
`;

export const ModalSheetContainer = styled.div`
  padding: 0 24px;

  @media (min-width: ${device.mobileXL}) {
    padding: 0;
  }
`;
export const WhyDataRequiredText = styled.span<WhyDataRequiredTextProps>`
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '15px')};
  color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.fontPrimary
      : props.theme.colors.$p10};
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
`;

export const ViewPrivacyPolicyLink = styled(Link)`
  display: inline-block;
  margin: 48px auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.15px;
  text-align: center;
  color: ${props => props.theme.colors.buttons.textButton};
`;

export const AutoFill = styled.div`
  position: relative;
  padding: 10px 8px 10px 16px;
  gap: 16px;
  width: 100%;
  background: ${({theme}) =>
    theme.hasTemplate ? theme.colors.branding.boxesModals : theme.colors.$p6};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  margin-bottom: 32px;
  max-width: 380px;
  cursor: pointer;
  @media (min-width: ${device.tablet}) {
    max-width: 420px;
  }
`;

export const AutoFillText = styled.div``;

export const AutoFillTitle = styled.div`
  display: flex;
  & span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.branding.primary};
    max-width: 270px;
    @media (min-width: ${device.tablet}) {
      max-width: 320px;
    }
  }
`;

export const AutoFillSubTitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.fontPrimary
      : props.theme.colors.$p10};
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
`;

export const StyledIcon = styled(CustomIcon)`
  path {
    stroke: ${({theme}) => theme.colors.branding.primary};
    fill: ${({theme}) => theme.colors.branding.primary};
  }
`;

export const StyledArrowIcon = styled(CustomIcon)`
  path {
    stroke: ${({theme}) => theme.colors.branding.primary};
  }
`;
