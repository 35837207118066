import styled from 'styled-components';
import {device} from 'styled/device';
import Button from 'components/common/Button/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

export const ContainerInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin: 48px 24px;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @media (min-width: ${device.tablet}) {
    margin: 16px 24px;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: ${props => props.theme.fonts.fontWeight.bold};
  font-size: ${props => props.theme.fonts.sizes.largeL};
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.36px;
  color: ${props => props.theme.colors.branding.fontPrimary};
`;

export const Subtitle = styled.div`
  font-style: normal;
  font-weight: ${props => props.theme.fonts.fontWeight.regular};
  font-size: ${props => props.theme.fonts.sizes.small};
  line-height: 24px;
  color: ${props =>
    props.theme.hasTemplate
      ? props.theme.colors.branding.fontPrimary
      : props.theme.colors.$p10};
  text-align: center;
`;

export const ContainerButton = styled.div`
  @media (min-width: ${device.tablet}) {
    margin-top: 48px;
  }
`;

export const ConfirmButton = styled(Button)`
  margin: 0 auto;
  width: 205px;

  @media (min-width: ${device.mobileM}) {
    width: 327px;
  }
`;
