import styled, {css} from 'styled-components';
import {device} from '@guestapp/ui/src/utils/device';
import Button from '../Button';
import {CustomIcon} from '@guestapp/ui';

type TitleProps = {
  isFaqView?: boolean;
  hasPayments?: boolean;
};

type WrapperProps = {
  onlyLogo?: boolean;
  isPaymentsView?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  z-index: 5;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 64px;
  padding: 8px 24px;
  background: ${props => props.theme.colors.navigationMenu.backgroundMenu};
  box-shadow: ${props =>
    props.isPaymentsView ? 'none' : '0 2px 10px rgb(147 150 174 / 25%)'};
  /* grid-template-columns: 15px auto 40px; */
  @media (min-width: ${device.tablet}) {
    // This is a border case, unique for this view based the figma requirements
    margin-top: 48px;
    margin-bottom: 32px;
    min-height: auto;
    box-shadow: none;
    background: transparent;
    padding: 0 64px;
    display: grid;
    grid-template-columns: ${props => (props.onlyLogo ? '1fr' : '1fr  1fr 1fr')};
  }
`;

export const Title = styled.h1<TitleProps>`
  text-align: ${props => (props.hasPayments ? 'left' : 'center')};
  font-style: normal;
  font-weight: ${props => props.theme.fonts.fontWeight.mediumL};
  font-size: ${props => props.theme.fonts.sizes.large};
  line-height: 24px;
  width: 100%;
  margin-left: ${props => (props.hasPayments ? '15px' : '0')};
  color: ${props => props.theme.colors.branding.fontPrimary};
  font-family: ${props => props.theme.fonts.fontFamily.titles.font_name};

  ${props =>
    props.isFaqView &&
    css`
      @media (max-width: ${device.tablet}) {
        margin-right: 24px;
        text-align: right;
      }
    `};

  @media (min-width: ${device.tablet}) {
    font-weight: ${props => props.theme.fonts.fontWeight.medium};
    font-size: 24px;
    text-align: center;
    margin: 0;
  }
`;

export const BackButton = styled(Button)`
  display: none;
  position: absolute;
  font-size: ${({theme}) => theme.fonts.sizes.small};
  font-weight: ${({theme}) => theme.fonts.fontWeight.bold};

  color: ${({theme}) =>
    theme.hasTemplate
      ? theme.colors.branding.primary
      : theme.colors.navigationMenu.selectedItemText};

  & .button__left-icon {
    padding-right: 15px;
  }

  @media (min-width: ${device.tablet}) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
`;

export const HomeIcon = styled.div`
  width: 24px;
  position: absolute;
  height: 24px;
  left: 15px;
  cursor: pointer;

  .svg {
    width: 100%;
  }

  @media (min-width: ${device.tablet}) {
    display: none;
  }
`;
export const StyledBackIcon = styled(CustomIcon)`
  display: flex;
  justify-content: center;
  align-items: center;

  path {
    fill: ${({theme}) =>
      theme.hasTemplate
        ? theme.colors.branding.primary
        : theme.colors.navigationMenu.selectedItemText};
  }
`;

export const BackIconButton = styled.button<{position?: string}>`
  all: unset;
  position: ${props => props.position};
  cursor: pointer;
  transition: opacity 0.1s;

  &:hover {
    opacity: 0.8;
  }

  @media (min-width: ${device.tablet}) {
    display: none;
  }
`;

export const StyledHeaderLogo = styled.img<{position?: string}>`
  display: block;
  margin: ${props => (props.position === 'center' ? '0 auto' : '0px auto 0 0')};
`;
