import styled from 'styled-components';
import {device} from '../../styled/device';

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  max-width: 400px;
  line-height: 24px;
  text-align: center;

  .guest-error-modal__title {
    font-weight: ${props => props.theme.fonts.fontWeight.mediumL};
    font-size: ${props => props.theme.fonts.sizes.large};
    color: ${props => props.theme.colors.$p10};
  }

  .guest-error-modal__text {
    font-weight: ${props => props.theme.fonts.fontWeight.regular};
    font-size: ${props => props.theme.fonts.sizes.medium};
    color: ${props => props.theme.colors.$p10};
  }

  .guest-error-modal__buttons {
    margin-top: 20px;

    @media (max-width: ${device.mobileXL}) {
      margin-bottom: 30px;
    }
  }
`;
