import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {ConfirmFormDataType, FORM_NAMES_CONFIRM_DATA} from '../types';
import {ALLIANZ_DOCUMENT_TYPES} from '@guestapp/sdk/src/utils/allianz/constanst';
import {ModalSheetProps} from 'components/common/ModalSheet/ModalSheet';
import {ButtonProps} from 'components/common/Button/Button';
import {PATHS} from 'Routes';
import {downloadAllianzDocuments} from '../utils';
import {getCreateOrUpdateBulkLockPayload} from 'hooks/allianz/utils';
import {useSummary} from 'context/summary';
import {useReservation} from 'context/reservation';
import {useAppSelector, useErrorModal, usePath, useTimeoutRef} from 'hooks';
import {useBulkLock, useFetchAllianzDocuments} from 'hooks/allianz/';
import {useAllianzPlans} from 'hooks/allianz/useAllianzPlans';
import ModalSheet from 'components/common/ModalSheet';
import {InputController as Input, Checkbox} from '@guestapp/ui';
import {
  CheckBoxContainer,
  CheckBoxLabelContainer,
  ConfirmationModalCheckBoxes,
  ConfirmationTitle,
  EmailContainer,
  EmailTextContainer,
  Hr,
  StyledFormFieldWrapper,
  StyledStandardModalSheet,
} from './styled';

function ConfirmLegalModal({
  isOpenModalSheet,
  handleCloseModal,
  onLoadingConfirm,
  isOneAddonSelected,
}: ModalSheetProps & {
  onLoadingConfirm?: (isLoading: boolean) => void;
  isOneAddonSelected?: boolean;
}) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {getMainPath} = usePath();
  const {setLegals} = useAllianzPlans();
  const {displayError, ErrorModal} = useErrorModal();
  const dealIds = useAppSelector(state => state.alliaznInsurance.dealIds);
  const confirmEmail = useAppSelector(state => state.alliaznInsurance.confirmEmail);
  const {data} = useSummary();
  const {data: reservation} = useReservation();
  const closeToastTimeoutRef = useTimeoutRef();
  const {
    register,
    formState: {errors, isValid},
    control,
    getValues,
  } = useForm<ConfirmFormDataType>();
  const {data: documents} = useFetchAllianzDocuments({
    params: `view=select&country=${data?.country_code}&reservation=${
      reservation?.id
    }&cdw_enabled=${Boolean(isOneAddonSelected)}`,
    enabled: Boolean(data?.country_code),
  });
  const [allowUsePersonalData, setAllowUsePersonalData] = React.useState(false);
  const [prevAccessToInsuranceDocuments, setPrevAccessToInsuranceDocuments] =
    React.useState(false);
  const [redirectToAfterMutation, setRedirectToAfterMutation] = React.useState(
    getMainPath(PATHS.payments.main),
  );

  const handleClose = React.useCallback(() => {
    setLegals({
      allowUsePersonalData: allowUsePersonalData,
      prevAccessToInsuranceDocuments: prevAccessToInsuranceDocuments,
    });
    handleCloseModal?.();
  }, [setLegals, handleCloseModal, allowUsePersonalData, prevAccessToInsuranceDocuments]);

  const handleOnSuccess = () => {
    handleClose();
    closeToastTimeoutRef.current = setTimeout(() => {
      onLoadingConfirm?.(false);
      navigate(redirectToAfterMutation, {
        state: {from: PATHS.travelInsurances.allianz.selectGuests},
      });
    }, 2000);
  };

  const handleError = (e: Error) => {
    displayError(e);
    handleCloseModal?.();
  };

  const {mutateBulkLock, isLoading: isLoadingMutationBulkLock} = useBulkLock({
    onSuccessCreateOrUpdateBulkLock: handleOnSuccess,
    onErrorCreateOrUpdateBulkLock: handleError,
  });

  React.useEffect(() => {
    if (isLoadingMutationBulkLock) {
      onLoadingConfirm?.(isLoadingMutationBulkLock);
    }
  }, [isLoadingMutationBulkLock, onLoadingConfirm]);

  const handlePay = React.useCallback(
    (path: string) => {
      setRedirectToAfterMutation(getMainPath(path));
      const email = Boolean(confirmEmail)
        ? confirmEmail
        : getValues(FORM_NAMES_CONFIRM_DATA.email);

      const payload = getCreateOrUpdateBulkLockPayload(dealIds, {
        personal_data_accepted: allowUsePersonalData,
        email,
      });
      mutateBulkLock(payload);
    },
    [
      mutateBulkLock,
      setRedirectToAfterMutation,
      getMainPath,
      dealIds,
      allowUsePersonalData,
      confirmEmail,
      getValues,
    ],
  );

  const modalSheetButtons: ButtonProps[] = React.useMemo(() => {
    const isInvalidForm =
      !prevAccessToInsuranceDocuments || (!Boolean(confirmEmail) && !isValid);

    const primaryButton: ButtonProps = {
      label: t('pay_now'),
      primary: true,
      onClick: () => handlePay(PATHS.payments.main),
      disabled: isInvalidForm,
    };
    const secondaryButton: ButtonProps = {
      link: true,
      label: t('pay_later'),
      onClick: () => handlePay(PATHS.guestRegistration),
      disabled: isInvalidForm,
    };

    return [primaryButton, secondaryButton];
  }, [prevAccessToInsuranceDocuments, confirmEmail, isValid, t, handlePay]);

  const openProductInformationDocument = () => {
    downloadAllianzDocuments(
      ALLIANZ_DOCUMENT_TYPES.productInformationDocument,
      documents,
    );
  };

  const openPrivacyPolicy = () => {
    downloadAllianzDocuments(
      ALLIANZ_DOCUMENT_TYPES.legalNoticeDataPrivacyPolicy,
      documents,
    );
  };

  const goToPreviousProductInformationNote = () => {
    window.open(
      `${PATHS.travelInsurances.allianz.previousProductInformationNote}`,
      '_blank',
    );
  };

  return (
    <ModalSheet
      isOpenModalSheet={isOpenModalSheet}
      handleCloseModal={handleCloseModal}
      title={t('legal_confirmation')}
      padding
      withCloseButton
    >
      <StyledStandardModalSheet
        buttons={modalSheetButtons}
        content={
          <>
            <ConfirmationTitle>
              {t('confirm_legal_before_continue_title')} “
              <span onClick={openPrivacyPolicy}>
                {t('confirm_legal_before_continue_title_link')}
              </span>
              ”
            </ConfirmationTitle>
            <ConfirmationModalCheckBoxes>
              <Checkbox
                value={allowUsePersonalData}
                label={
                  <>
                    <CheckBoxLabelContainer>
                      <div className="text">{t('authorize_give_personal_data')}</div>
                      <ul>
                        <li>{t('publicitary_pruporses')}</li>
                        <li>{t('to_allianz_spain_companies')}</li>
                        <li>{t('profile_elaboration')}</li>
                      </ul>
                      <div className="optional">{t('optional')}</div>
                    </CheckBoxLabelContainer>
                  </>
                }
                className="check-box"
                onChange={() => setAllowUsePersonalData(!allowUsePersonalData)}
              />
              <Hr />
              <CheckBoxContainer>
                <Checkbox
                  value={prevAccessToInsuranceDocuments}
                  className="check-box"
                  onChange={() =>
                    setPrevAccessToInsuranceDocuments(!prevAccessToInsuranceDocuments)
                  }
                />
                <CheckBoxLabelContainer>
                  <div className="text">
                    <Trans i18nKey="confirm_user_had_previous_access_policy">
                      <strong>Confirmo</strong> que he tenido acceso previo a contratación
                      de la póliza al{' '}
                    </Trans>
                    <span className="blue-text" onClick={openProductInformationDocument}>
                      {' ' + t('product_information_document')}
                    </span>{' '}
                    {t('ensured_as_a_guest')}{' '}
                    <span
                      className="blue-text"
                      onClick={goToPreviousProductInformationNote}
                    >
                      {t('informative_product_note')}
                    </span>
                  </div>
                </CheckBoxLabelContainer>
              </CheckBoxContainer>

              {!Boolean(confirmEmail) && (
                <>
                  <Hr />
                  <EmailContainer>
                    <EmailTextContainer>
                      <Trans i18nKey="confirm_email_text">
                        Introduce un email para recibir las confirmaciones de compra y
                        comunicaciones de tu <strong>Seguro de Allianz</strong>
                      </Trans>
                    </EmailTextContainer>
                    <StyledFormFieldWrapper>
                      <Input
                        label={t('email')}
                        {...register(FORM_NAMES_CONFIRM_DATA.email, {
                          required: true,
                          pattern: {
                            value: new RegExp(
                              '^([\\w\\.\\-_]+)?\\w+@[\\w-_]+(\\.\\w+){1,}$',
                            ),
                            message: t('invalid_email'),
                          },
                        })}
                        control={control}
                        inputMode="email"
                        autoCorrect="off"
                        spellCheck={false}
                        autoCapitalize="none"
                        error={errors[FORM_NAMES_CONFIRM_DATA.email]?.message}
                      />
                    </StyledFormFieldWrapper>
                  </EmailContainer>
                </>
              )}
            </ConfirmationModalCheckBoxes>
            <ErrorModal />
          </>
        }
      />
    </ModalSheet>
  );
}

export {ConfirmLegalModal};
